<template>
    <div>
        <div v-for="(message, index) in sortedMessages" :key="index">
            <Message :message="message" :large="large" :bold="false" />
        </div>
    </div>
</template>

<script>
    import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants";
    import Message from "@/nucleus-modules/dd-nucleus-ui/components/Message";

    export default {
        name: 'MessageList',
        components: { Message },
        props: {
            messages: {
                type: Array,
                required: true
            },
            // If set, this will use larger presentation (e.g. Bootstrap alert boxes). Otherwise, just an icon and text.
            large: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            sortedMessages: function () {
                function compare(a, b) {
                    if (a.messageType === MessageType.ERROR) { return -1; }
                    else if (b.messageType === MessageType.ERROR) { return 1; }
                    else if (a.messageType === MessageType.WARNING) { return -1; }
                    else if (b.messageType === MessageType.WARNING) { return 1; }
                    else if (a.messageType === MessageType.INFO) { return -1; }
                    else if (b.messageType === MessageType.INFO) { return 1; }
                    return 0;
                }

                let names = [...this.messages];
                let sortedArr = names.sort(compare);
                return sortedArr;
            }
        }
    }
</script>