<template>
  <b-form-select
    v-model="selected"
    :options="perPageOptions"
    @change="onPerPageChanged"
    id="multiple"
  ></b-form-select>
</template>
<script>
export default {
  name: "PerPageList",
  data() {
    return {
      selectedPage: "",
    };
  },
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.selectedPage;
      },
      set(value) {
        this.selectedPage = value;
      },
    },
  },
  methods: {
    onPerPageChanged() {
      this.$emit("changed", { value: this.selected });
    },
  },
  async mounted() {
    this.selectedPage = this.perPage;
  },
};
</script>
<style scoped lang="scss"></style>
