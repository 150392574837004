<template>
    <PageTemplate>
        <div class="home">
            <div class="row mx-1">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <BannerImage image="/images/Regeneron_BrowseBanner_1300x275.jpg?1"></BannerImage>
                </div>
            </div>
            <div class="row mx-1 my-5">
                <div class="col-sm-12 col-md-12 col-lg-9">
                    <div><Announcements :title="announcementTitle" :message="announcementMessage"/></div>
                    <div class="my-5" v-if="featuredProduct">
                        <FeaturedProductsPanel v-if="showFeaturedProducts"
                                               title="Featured Items"
                                               :products="limitedFeatureProducts"
                                               :isLoading="featuredProduct.isLoading"
                                               :showAllLink="featuredProduct.showAllLink"
                                               :noImageAvailableUrl="noImageAvailableUrl" />
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3">
                    <div>
                        <NewProductsPanel :products="newProduct.products"
                                          :isLoading="newProduct.isLoading"
                                          :showAllLink="newProduct.showAllLink" />
                    </div>
                    <div class="my-5">
                        <FavoritesPanel :products="limitedFavoriteProducts"
                                        :isLoading="favoriteProduct.isLoading"
                                        :showAllLink="showFavoriteAllLink" />
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

    import {
        GET_SITE_SETUP,
        SEARCH_PRODUCTS,
        GET_FAVORITES,
        GET_FEATURED_PRODUCTS
    } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
    
    import {
        SEARCH_PRODUCTS_RESULTS_GETTER,
        FAVORITES_GETTER,
        FEATURED_PRODUCTS_GETTER,
        FEATURED_PRODUCTS_ERRORS_GETTER
    } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

    import { GET_USER_ANNOUNCEMENT } from "@/store/storefront/actions.type.js";
    import { USER_ANNOUNCEMENT_GETTER } from "@/store/storefront/getters.type.js";

    import {
        TagNames,
        StorefrontEvents,
    } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
    import PageTemplate from "@/pages/templates/PageTemplate.vue";
    import BannerImage from "@/components/home/Banners.vue";
    import Announcements from "@/components/home/Announcements.vue";
    import NewProductsPanel from "@/components/home/NewProductsPanel.vue";
    import FavoritesPanel from "@/components/home/FavoritesPanel.vue";
    import FeaturedProductsPanel from "@/components/product/ProductHomePanel.vue";
    import {
        totalFeatureProductsToShow,
        totalFavoriteProductsToShow,
        totalNewProductsToShow,
        noImageAvailableUrl,
    } from "@/companyAppConfig";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
    
    

    export default {
        name: "Home",
        components: {
            PageTemplate,
            BannerImage,
            Announcements,
            NewProductsPanel,
            FeaturedProductsPanel,
            FavoritesPanel,
        },
        data() {
            return {
                featuredProduct: {
                    products: [],
                    isLoading: true,
                    showAllLink: false,
                },
                favoriteProduct: {
                    isLoading: true,
                },
                newProduct: {
                    products: [],
                    isLoading: true,
                    showAllLink: false,
                },
            };
        },
        computed: {
            ...mapGetters({
                favoriteProducts: FAVORITES_GETTER,
                searchResults: SEARCH_PRODUCTS_RESULTS_GETTER,
                userInfo: USER_INFO_GETTER,
                featuredProducts: FEATURED_PRODUCTS_GETTER,
                featuredProductErrors: FEATURED_PRODUCTS_ERRORS_GETTER,
                announcement:USER_ANNOUNCEMENT_GETTER
            }),
            noImageAvailableUrl() {
                return noImageAvailableUrl;
            },
            isSSOAuthenticatedUser() {
                return false;
            },
            showFeaturedProducts() {
                return (
                    this.featuredProduct.products && this.featuredProduct.products.length
                );
            },
            showFavoriteAllLink() {
                if (this.favoriteProducts && this.favoriteProducts.length) {
                    return (
                        totalFavoriteProductsToShow > 0 &&
                        this.favoriteProducts.length > totalFavoriteProductsToShow
                    );
                }
                return false;
            },
            limitedFavoriteProducts() {
                if (!this.$nucleus.isEmpty(this.favoriteProducts)) {
                    var len =
                        totalFavoriteProductsToShow > this.favoriteProducts.length
                            ? this.favoriteProducts.length
                            : totalFavoriteProductsToShow;
                    return this.favoriteProducts.slice(0, len);
                }
                return [];
            },
            limitedFeatureProducts() {
                if (this.featuredProduct && this.featuredProduct.products) {
                    let featuredProducts = this.featuredProduct.products;
                    const favoriteProducts = this.favoriteProducts;
                    featuredProducts = featuredProducts.map(fp => {
                        const isFavoriteProduct = !!favoriteProducts.find(fap => fap.id == fp.id);
                        fp.isFavorite = isFavoriteProduct;
                        return fp;
                    });
                    return featuredProducts;
                }
                else {
                    return [];
                }
            },
            announcementTitle() {
                if(this.announcement && this.announcement.name) {
                    return this.announcement.name
                }
                return "";
            },
            announcementMessage() {
                if(this.announcement && this.announcement.description) {
                    return this.announcement.description
                }
                return "";
            },
        },
        beforeDestroy() {
            this.removeListeners();
        },
        methods: {
            ...mapActions({
                getSiteSetup: GET_SITE_SETUP,
                searchProducts: SEARCH_PRODUCTS,
                getFavorites: GET_FAVORITES,
                getAllFeaturedProducts: GET_FEATURED_PRODUCTS,
                getUserAnnouncement: GET_USER_ANNOUNCEMENT
            }),
            async getFeaturedProducts() {
                let vm = this;
                await vm.getAllFeaturedProducts({
                    paging: {
                        pageNumber: 0,
                        pageSize: 6,
                        includeTotalCount: false,
                    },
                });
                if (!vm.$nucleus.isEmpty(vm.featuredProductErrors) &&
                    !vm.featuredProductErrors.succeeded) {
                    if (vm.$nucleus.isEmpty(vm.featuredProductErrors.messages)) {
                        ToastService.showErrorToast("Unable to fetch the featured items. Please contact system administrator");
                    } else {
                        let messages = "";
                        vm.featuredProductErrors.messages.forEach(message => {
                            messages = messages + message.debugMessage;
                        });
                        ToastService.showErrorToast(messages);
                    }
                } else {
                    let featuredProducts = this.featuredProducts;
                    if (featuredProducts && featuredProducts.length > totalFeatureProductsToShow) {
                        this.featuredProduct.products = featuredProducts.slice(0, totalFeatureProductsToShow);
                        this.featuredProduct.showAllLink = true;
                    }
                    else {
                        this.featuredProduct.products = featuredProducts;
                    }
                }
                this.featuredProduct.isLoading = false;
            },
            async getFavoriteProducts() {
                await this.getFavorites();
                this.favoriteProduct.isLoading = false;
            },
            async getNewProducts() {
                this.newProduct = await this.getProducts(
                    TagNames.NEW,
                    totalNewProductsToShow
                );
                this.newProduct.isLoading = false;
            },
            async getProducts(tagName, numberOfProducts) {
                var product = { showAllLink: false, products: [] };
                var searchFilters = [];
                if (!this.$nucleus.isEmpty(tagName)) {
                    searchFilters.push({
                        filterId: "tags",
                        values: [tagName],
                    });
                }
                await this.searchProducts({
                    filters: searchFilters,
                    paging: {
                        pageNumber: 0,
                        pageSize: numberOfProducts > 0 ? numberOfProducts + 1 : 10,
                    }
                });
                if (
                    this.searchResults &&
                    this.searchResults.count > 0
                ) {
                    if (
                        numberOfProducts > 0 &&
                        this.searchResults.count > numberOfProducts
                    ) {
                        product.products = this.searchResults.items.slice(
                            0,
                            numberOfProducts
                        );
                        product.showAllLink = true;
                    } else {
                        product.products = this.searchResults.items;
                    }
                    product.products = product.products.map(p => {
                        p.quantity = 1;
                        return p;
                    });
                }
                return product;
            },
            initListeners() {
                const vm = this;

                this.$eventBus.$on(StorefrontEvents.NAVIGATE_TO_PRODUCTS, (payload) => {
                    vm.$router.push({ name: "ProductSearchResults", query: payload });
                });
            },
            removeListeners() {
                this.$eventBus.$off(StorefrontEvents.NAVIGATE_TO_PRODUCTS);
            }
        },
        async mounted() {
            this.initListeners();

            if (!this.userInfo || !this.userInfo.id) {
                await this.getSiteSetup();
            }

            if (this.userInfo && this.userInfo.id) {
                await this.getFavoriteProducts();
                await this.getNewProducts();
                await this.getFeaturedProducts();
                await this.getUserAnnouncement();
            }
        },
    };
</script>

<style lang="scss" scoped>
    .n-heart-icon {
        font-size: 20px;
    }
</style>
