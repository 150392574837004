<template>
  <div>
    <div class="row n-user-info-section">
      <div class="col text-right">
        <div
          class="n-drop-down dropdown-toggle float-right"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="n-user-icon">
            <font-awesome-icon class="pr-1" :icon="['far', 'user-circle']" />
          </span>
          <strong>{{name}}</strong>
        </div>
        <div class="n-height"></div>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <a href="#" class="dropdown-item" @click="signOut()">Sign out</a>
        </div>
        <div>{{ userInfo.primaryRoleName }}</div>
        <div>
          <b-badge
            class="nav-pill"
            href="#"
            variant="primary"
            @click.prevent="redirectToMain"
            >Go to Storefront</b-badge
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SKIP_ADMIN_REDIRECT_KEY } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { INDEX_ENTRY_PATH } from "@/router/index.js";

export default {
  name: "User",
  props: {
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {
      userAccess: [],
    };
  },
  computed: {
    name() {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
    },
  },
  methods: {
    redirectToMain() {
      this.$nucleus.setStorageItem(SKIP_ADMIN_REDIRECT_KEY, true, true);
      window.location.href = INDEX_ENTRY_PATH;
    },
    signOut() {
      this.$eventBus.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-fonts {
  font-size: 18px;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background: transparent;
  border-color: transparent;
  color: $admin-tertiary-dark-color;
}
.admin-user {
  color: $admin-tertiary-dark-color;
}

.dropdown-menu {
  font-size: 14px;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  outline: 0;
}

.btn {
  padding-top: 10px;
}

.n-user-info-section {
  color: $admin-tertiary-color;
}

.n-user-icon {
  position: absolute;
  font-size: 18px;
  margin-left: -18px;
}

.n-drop-down {
  cursor: pointer;
}
.n-height
{
  height: 1.81rem;
}
</style>
