<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-tabs content-class="mt-3" id="userRoleTabs">
          <b-tab active>
            <template v-slot:title>
              <span class="mt-2">Roles</span>
            </template>
            <template v-if="!isEditable">
              <UserRolesViewOnly :user="user" :roles="roles" />
            </template>
            <template v-if="isEditable">
              <UserRoles :user="user" :roles="roles" />
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              Sales       
              Structure
            </template>
            <!-- 
              The sales structure is determined by the roster import,
              only showing the view component for Regeneron.
            -->
            <UserSalesStructureViewOnly :user="user" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import UserRoles from "@/nucleus-modules/dd-nucleus-admin/components/user/UserRoles.vue";
import UserRolesViewOnly from "@/nucleus-modules/dd-nucleus-admin/components/user/UserRolesViewOnly.vue";
import UserSalesStructureViewOnly from "@/nucleus-modules/dd-nucleus-admin/components/user/UserSalesStructureViewOnly.vue";

export default {
  name: "UserTabs",
  props: {
    user: { type: Object },
    isEditable: { type: Boolean },
    roles: { type: Array },
    regions: { type: Array },
    districts: { type: Array },
    territories: { type: Array },
  },
  components: {
    UserRoles,
    UserRolesViewOnly,
    UserSalesStructureViewOnly,
  },
};
</script>

<style lang="scss">
.nav-link {
  padding: 0.5rem 2rem 0.5rem 2rem;
}
.n-product-status {
  color: $green;
  margin-top: 12px;
}
.modal-footer {
  justify-content: center;
}
.modal-title {
  color: $tertiary-dark-color;
}
.modal-body {
  padding-top: 16px;
}
#confirmExit {
  left: unset !important;
}
</style>
