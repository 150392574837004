<template>
  <div>
    <PageSubHeader title="Contact Information" :hasThematicBreak="true" />
    <form name="add-user-contact-info">
      <div class="row">
        <div class="col-5">
          <div
            class="form-group"
            :class="{
              'form-group--error': $v.email.$error,
            }"
          >
            <label class="n-form-labels">Email Address</label>
            <input
              type="text"
              class="form-control n-text-field-highlight"
              v-model.trim="email"
              @input="$v.email.$touch()"
            />
            <div v-if="$v.email.$invalid && email != null && email.length == 0">
              <div class="n-error" v-if="!$v.email.required">
                Email Address is required
              </div>
            </div>
            <div v-if="email && email.length > 0">
              <div class="n-error" v-if="!$v.email">
                Email Address must be a valid one.
              </div>
            </div>
          </div>
          <div
            class="form-group"
            :class="{ 'form-group--error': $v.phoneNumber }"
          >
            <label class="n-form-labels">Phone</label>
            <input
              type="text"
              class="form-control n-text-field-highlight"
              maxlength="12"
              v-model.trim="phoneNumber"
              @keyup="$v.phoneNumber.$touch()"
            />
            <div
              v-if="
                $v.phoneNumber.$invalid &&
                  phoneNumber != null &&
                  phoneNumber.length == 0
              "
            >
              <div class="n-error" v-if="!$v.phoneNumber.required">
                Phone Number is required
              </div>
            </div>
            <div v-if="phoneNumber && phoneNumber.length > 0">
              <div class="n-error" v-if="!$v.phoneNumber.minLength">
                Phone Number must be a valid one.
              </div>
            </div>
          </div>
        </div>
      </div>
      <span v-if="isContactInfoValid"></span>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import { formatting } from "@/nucleus-modules/dd-nucleus-ui/mixins/formattingMixin.js";

import {
  USER_FOR_EDIT_GETTER,
  USER_PROPERTY_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_USER_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
import { UserEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "UserContactInfo",
  mixins: [formatting],
  components: {
    PageSubHeader,
  },
  computed: {
    ...mapGetters({
      getUserProperty: USER_PROPERTY_GETTER,
      user: USER_FOR_EDIT_GETTER,
    }),
    email: {
      get() {
        return this.user.email;
      },
      set(value) {
        this.setUserProperty({ name: "email", value: value });
      },
    },
    phoneNumber: {
        get() {
            let phoneNumber = this.getUserProperty("defaultPhoneNumber");
            if (phoneNumber) {
                return this.formatPhoneNumber(
                    phoneNumber
                );
            }
            else {
                return null;
            }
      },
      set(value) {
        this.setUserProperty({
          name: "defaultPhoneNumber",
          value: this.clearPhoneNumberFormatting(value),
        });
      },
    },
    isContactInfoValid() {
      let result = !(this.$v.email.$invalid || this.$v.phoneNumber.$invalid);

      this.updateValidity(result);
      return result;
    },
  },
  methods: {
    ...mapMutations({
      setUserProperty: SET_USER_PROPERTY,
    }),
    updateValidity(isValid) {
      this.$eventBus.$emit(UserEvents.USER_CONTACT_INFO_UPDATED, isValid);
    },
    isNotValid(fieldName) {
      return fieldName != null && fieldName.length == 0;
    },
  },
  mounted() {
    this.setUserProperty = this.$nucleus.debounce(this.setUserProperty, 100);
  },
  validations: {
    email: {
      required,
      email,
    },
    phoneNumber: {
      required,
      minLength: minLength(12),
    },
  },
};
</script>
