<template>
  <div>
    <div class="n-filter-search p-2">
      <div class="row pt-3">
        <div class="col-7">
          <div class="n-searchbox">
            <div class="input-group search">
              <input
                type="text"
                ref="searchAddressBook"
                v-model="searchTerm"
                class="form-control n-search-input"
                placeholder="Search All Users"
                aria-label="input-group mb-3"
                v-on:keyup.enter="filterUsers"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-search"
                  type="button"
                  @click="filterUsers"
                >
                  <font-awesome-icon
                    icon="search"
                    class="icon-height"
                    fixed-width
                  ></font-awesome-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <!-- TODO: Placeholder for the Saved Search box -->
        </div>
      </div>
      <div class="row pb-2 pt-2 mt-3">
        <div class="col-5">
          <div class="n-button-filters d-inline">
            <div><strong>User Access</strong></div>
            <ul class="quickFilters">
              <li v-for="filter in quickFilters" :key="filter.id">
                <button
                  class="btn btn-white"
                  :class="{ active: quickFiltersSelected.includes(filter) }"
                  @click="toggleQuickFilter(filter)"
                >
                  {{ filter }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-4">
          <div class="d-inline">
            <div><strong>Primary Roles</strong></div>
            <b-dropdown
              id="dropdown-form"
              :text="primaryRoleLabel"
              ref="dropdown"
              variant="white n-custom-dropdown"
            >
              <b-dropdown-form>
                <b-dropdown-item @click="selectAllRoles()" class=""
                  >Select All</b-dropdown-item
                >
                <b-dropdown-item @click="deselectAllRoles()" class=""
                  >Deselect All</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-form-checkbox-group
                  id="userRoleFilter"
                  v-model="selectedRoles"
                  :options="allRoles"
                  value-field="id"
                  text-field="name"
                  name="roleSearch"
                  :stacked="true"
                  @change="roleSearch"
                />
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserFilters",
  data() {
    return {
      quickFiltersSelected: ["all"],
      searchTerm: "",
      selectedRoles: [],
    };
  },
  props: {
    allRoles: {
      type: Array,
    },
    primaryRoleLabel: {
      type: String,
    },
    quickFilters: {
      type: Array,
    },
  },
  methods: {
    clearFilters() {
      this.searchTerm = "";
      this.selectedRoles = [];
      this.quickFiltersSelected = ["all"];
    },
    filterUsers() {
      this.$emit("filterUsers", this.searchTerm);
    },
    roleSearch() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("roleSearch", this.selectedRoles);
      }, 1000);
    },
    selectAllRoles() {
      this.selectedRoles = this.allRoles.map((ar) => ar.id);
      this.roleSearch();
    },
    deselectAllRoles() {
      this.selectedRoles = [];
      this.roleSearch();
    },
    toggleQuickFilter(filter) {
      this.quickFiltersSelected = [];
      this.quickFiltersSelected.push(filter);

      if (this.$nucleus.isEmpty(this.quickFiltersSelected)) {
        this.quickFiltersSelected.push("all");
      }

      this.$emit("toggleQuickFilter", this.quickFiltersSelected);
    },
  },
  created() {
    this.$eventBus.$on("clearFilters", () => {
      this.clearFilters();
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dropdown-menu { 
    min-width: 22rem !important;
    max-height: 25rem;
    overflow-x: overlay !important;
    white-space: nowrap !important;
    background: $admin-white-color !important;
  }
  /* width */
  .dropdown-menu::-webkit-scrollbar {
    height: .75rem;
    width:15px;
  }

  /* Track */
  .dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(233, 231, 231);
    border-radius: 10px;

  }

  /* Handle */
  .dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d4d3d3;
  }
}
 
.icon-height {
  height: 1.15rem;
  margin-bottom: 0.063rem;
  margin-top: -0.313rem;
}

.b-dropdown-form {
  padding: 0.25rem 0rem 0rem 1.75rem;
}

.n-filter-search {
  background: $admin-primary-lightest-color;
}

.n-search-input {
  border: 0.75px solid $admin-neutral-mid-light;
  border-right: 0px;
}

.btn-search {
  background: $admin-white-color;
  font-size: 0.7rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 0.75px solid $admin-neutral-mid-light;
  border-left: 0px;
  padding-bottom: 0px;
}

.btn-search:active {
  border: 1px solid $admin-primary-color;
  background: $admin-white-color;
}

.n-button-filters .btn {
  border-radius: 0;
  text-transform: capitalize;
}

.n-button-filters .active {
  background: $admin-primary-light-color;
  color: $admin-white-color;
  border: 1px solid $admin-primary-light-color;
}

.btn-group {
  border: 1px solid $admin-neutral-mid-light;
}
</style>
