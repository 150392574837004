<template>
    <div>
        <PageSubHeader title="Additional Information" :hasThematicBreak="true" />
        <form name="addlInfoForm">
            <div class="row">
                <div class="col-5">
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.employeeId }">
                        <label class="n-form-labels">Employee ID</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model.trim="employeeId"
                               @input="$v.employeeId.$touch()" />
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.employeeStatus }">
                        <label class="n-form-labels">Employee Status</label>
                        <b-form-select v-model="employeeStatus"
                                       :options="availableStatuses"
                                       value-field="id"
                                       text-field="name"
                                       class="n-text-field-highlight">
                            <template #first>
                                <b-form-select-option value="">Select Employee Status</b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label class="n-form-labels">Business Unit</label>
                        <b-form-select v-model="businessUnit"
                                       :options="availableBusinessUnits"
                                       value-field="id"
                                       text-field="name"
                                       class="n-text-field-highlight">
                            <template #first>
                                <b-form-select-option value="">Select Business Unit</b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.organization }">
                        <label class="n-form-labels">Organization</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model.trim="organization"
                               @input="$v.organization.$touch()" />
                    </div>                    
                    <div class="form-group">
                        <label class="n-form-labels">Source</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               disabled
                               v-model="attributes.UserSource" />
                    </div>
                </div>
                <div class="col-5 offset-2">
                    <div class="form-group" :class="{ 'form-group--error': $v.geoId }">
                        <label class="n-form-labels">GEO ID</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model.trim="geoId"
                               @input="$v.geoId.$touch()" />
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.reportsTo }">
                        <label class="n-form-labels">Reports To</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model.trim="reportsTo"
                               @input="$v.reportsTo.$touch()" />
                    </div>
                    <div class="form-group">
                        <label class="n-form-labels">Product</label>
                        <b-form-select v-model="product"
                                       :options="availableProducts"
                                       value-field="id"
                                       text-field="name"
                                       class="n-text-field-highlight">
                            <template #first>
                                <b-form-select-option value="">Select Product</b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.territoryLevel }">
                        <label class="n-form-labels">Territory Level</label>
                        <b-form-select v-model="territoryLevel"
                                       :options="availableTerritories"
                                       value-field="id"
                                       text-field="name"
                                       class="n-text-field-highlight">
                            <template #first>
                                <b-form-select-option value="">Select Territory Level</b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.territoryName }">
                        <label class="n-form-labels">Territory Name</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model.trim="territoryName"
                               @input="$v.territoryName.$touch()" />
                    </div>                   
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import {  mapMutations } from "vuex";
    import { maxLength } from "vuelidate/lib/validators";

    import { SET_USER_ATTRIBUTE_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

    import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

    export default {
        name: "UserProperties",
        components: {
            PageSubHeader,
        },
        props: {
            user: {
                type: Object,
                required:true
            },
            attributes: {
                type: Object,
                required: true,
            },
            availableBusinessUnits: {
                type: Array,
                required: true,
            },
            availableTerritories: {
                type: Array,
                required: true,
            },
            availableProducts: {
                type: Array,
                required: true,
            },
            availableStatuses: {
                type: Array,
                required: true,
            },
        },
        computed: {
            employeeId: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["EmployeeId"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "EmployeeId", value: value });
                },
            },
            employeeStatus: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["EmployeeStatus"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "EmployeeStatus", value: value });
                },
            },
            businessUnit: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["BusinessUnit"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "BusinessUnit", value: value });
                },
            },
            organization: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["Organization"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "Organization", value: value });
                },
            },
            geoId: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["GeoId"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "GeoId", value: value });
                },
            },
            reportsTo: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["ReportsToManager"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({
                        name: "ReportsToManager",
                        value: value,
                    });
                },
            },
            product: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["Product"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "Product", value: value });
                },
            },
            territoryLevel: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["TerritoryLevel"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "TerritoryLevel", value: value });
                },
            },            
            territoryName: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["TerritoryName"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "TerritoryName", value: value });
                },
            },
            userSource: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["UserSource"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "UserSource", value: value });
                },
            },
        },
        methods: {
            ...mapMutations({
                setUserAttributeProperty: SET_USER_ATTRIBUTE_PROPERTY,
            }),
        },
        mounted() {
            this.setUserAttributeProperty = this.$nucleus.debounce(
                this.setUserAttributeProperty,
                500
            );
        },
        validations: {
            employeeId: {
                maxLength: maxLength(100),
            },
            employeeStatus: {
                maxLength: maxLength(100),
            },
            organization: {
                maxLength: maxLength(100),
            },
            geoId: {
                maxLength: maxLength(100),
            },
            reportsTo: {
                maxLength: maxLength(100),
            },
            territoryLevel: {
                maxLength: maxLength(100),
            },
            territoryName: {
                maxLength: maxLength(100),
            },
        },
    };
</script>

<style lang="scss" scoped>
    dt {
        margin-top: 1.5rem !important;
    }
</style>
