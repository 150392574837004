<template>
    <PageTemplate :showHeader="true">
        <div class="row px-4">
            <div class="col-md-12">
                <h3 class="n-back-link">
                    <router-link to="/product-results">
                        <font-awesome-icon icon="chevron-left" />
                        {{ $t("shoppingCart.continueShopping") }}
                    </router-link>
                </h3>
            </div>
        </div>
        <div v-if="hasCartErrors && !hasMixProductTypes">
            <b-alert variant="danger" show>
                Please review the product(s) highlighted below. The issue(s) indicated
                MUST be addressed before placing your order.
            </b-alert>
        </div>
        <div v-else-if="hasCartWarnings && hasItemWarnings">
            <b-alert variant="warning" show>
                This order will require an administrator's approval. Review the
                product(s) highlighted below before proceeding.
            </b-alert>
        </div>
        <div class="row px-4">
            <div class="col-sm-12">
                <h1>
                    {{ $t("shoppingCart.shoppingCartText") }}
                    <span class="n-total-items">({{ totalCartItems }} {{ $t("shoppingCart.itemsText") }})</span>
                </h1>
                <div v-if="hasItems">
                    <div v-for="item in cart.items" :key="item.id">
                        <CartItem :cartId="cart.id"
                                  :item="item"
                                  :isPricingEnabled="isPricingEnabled"
                                  :destinationUrl="getNavigationUrl(item)"
                                  :noImageAvailableUrl="noImageAvailableUrl"
                                  :showSeparator="false"
                                  :favorites="favoriteProducts">
                            <template v-slot:limits>
                                <div v-if="item.messages && item.messages.length > 0"
                                     class="mt-2 mb-2">
                                    <div v-for="(message, index) in item.messages" :key="index">
                                        <template v-if="message.messageType === errorMessageType">
                                            <div class="d-flex alert alert-danger">
                                                <font-awesome-icon class="text-danger mr-2 my-1"
                                                                   icon="times-circle" />
                                                <div v-html="getWarningByMessage(message)"></div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="d-flex alert alert-warning">
                                                <font-awesome-icon class="text-warning mr-2 my-1"
                                                                   icon="exclamation-circle" />
                                                <span v-html="getWarningByMessage(message)"></span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </CartItem>
                        <div class="col-12 mt-2 mb-2">
                            <hr />
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        <p>{{ $t("shoppingCart.noItemsMessage") }}</p>
                        <router-link to="/product-results" class="btn btn-primary mb-3">
                            {{
              $t("shoppingCart.startShoppingButtonText")
                            }}
                        </router-link>
                    </div>
                </div>
                <ShoppingCartAddOn :cart="cart"></ShoppingCartAddOn>
            </div>
        </div>
        <div class="row mb-5 px-4" v-if="hasItems">
            <div class="col-12">
                <button type="button" class="btn btn-primary" :disabled="hasItemErrors" @click="navToCheckout">
                    Proceed to Checkout
                </button>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

    import {
        REMOVE_FROM_CART,
        UPDATE_CART_ITEM,
        GET_FAVORITES,
    } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

    import {
        CART_GETTER,
        SITE_SETUP_GETTER,
        TOTAL_CART_ITEMS_GETTER,
        FAVORITES_GETTER
    } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

    import { OrdersMixin, ERROR_MESSAGE_TYPE } from "@/nucleus-modules/dd-nucleus-storefront/mixins/OrdersMixin.js";

    import PageTemplate from "@/pages/templates/PageTemplate.vue";
    import CartItem from "@/components/cart/CartItem.vue";
    import { noImageAvailableUrl } from "@/companyAppConfig.js";
    import ShoppingCartAddOn from "@/components/ShoppingCartAddOn.vue";

    export default {
        name: "ShoppingCart",
        mixins: [OrdersMixin],
        components: {
            PageTemplate,
            CartItem,
            ShoppingCartAddOn,
        },
        data() {
            return {
                noImageAvailableUrl: noImageAvailableUrl,
                errorMessageType: ERROR_MESSAGE_TYPE
            };
        },
        computed: {
            ...mapGetters({
                cart: CART_GETTER,
                siteSetup: SITE_SETUP_GETTER,
                totalCartItems: TOTAL_CART_ITEMS_GETTER,
                favoriteProducts: FAVORITES_GETTER
            }),
            hasItems() {
                return this.cart && this.cart.items && this.cart.items.length > 0;
            },
            isPricingEnabled() {
                return this.siteSetup.isPricingEnabled;
            },
            hasMixProductTypes() {
                var flag = false;
                this.cartErrors.forEach(message => {
                    message.parameters.forEach(p => {
                        if(p == "MixProductTypes") {
                            flag = true;
                        }
                    });                
                });            
                return flag;
            }
        },
        methods: {
            ...mapActions({
                removeFromCart: REMOVE_FROM_CART,
                updateCartItem: UPDATE_CART_ITEM,
                getFavorites: GET_FAVORITES,
            }),
            getNavigationUrl(item) {
                return `/product-detail/${item.product.id}`;
            },
            initListeners() {
                this.$eventBus.$on(UiEvents.REMOVE_CART_ITEM_CLICKED, (eventData) => {
                    this.removeFromCart({
                        cartId: eventData.cartId,
                        itemId: eventData.item.id,
                    });
                });

                this.$eventBus.$on(UiEvents.QUANTITY_INPUT_CHANGED, (eventData) => {
                    this.updateCartItem({
                        itemId: eventData.item.id,
                        quantity: eventData.item.quantity,
                        showLoader: false,
                    });
                });

            },
            navToCheckout() {
                this.$router.push(`/checkout?id=${this.userInfo.contactId}`);
            },
            removeListeners() {
                this.$eventBus.$off(UiEvents.REMOVE_CART_ITEM_CLICKED);
                this.$eventBus.$off(UiEvents.QUANTITY_INPUT_CHANGED);
            },
        },
        mounted() {
            this.initListeners();
            this.getFavorites();
        },
        beforeDestroy() {
            this.removeListeners();
        },
    };
</script>

<style lang="scss" scoped>
    h1 {
        padding-bottom: 30px;
        font-size: 1.5rem;
    }

    h1 .n-total-items {
        color: $gray-3;
        font-size: 1rem;
        font-family: $body-font;
    }
</style>
