<template>
    <div class="n-sign-in">
        <div class="row">
            <div class="n-logo d-lg-block mt-3 mx-2 flex-fill" v-if="logo">
                <HeaderBranding :image-url="logo" alt="Regeneron" />
            </div>
            <div class="col-12">
                <h2 class="text-center n-sign-in-header">
                    {{ $t("signIn.signInHeadline") }}
                </h2>
            </div>
        </div>
        <div class="row ">
            <div class="col-12">
                <div class="n-sign-in-container text-left">
                    <div v-if="errorMessage" class="my-3 alert alert-danger">
                        {{errorMessage}}
                    </div>
                    <ValidationObserver ref="loginForm" v-slot="{ invalid }">
                        <form>
                            <ValidationProvider
                                rules="required"
                                name="Username"
                                v-slot="{ errors }"
                            >
                                <div class="form-group mt-2">
                                    <label for="username">{{ $t("shared.userInfo.username") }}</label>
                                    <input 
                                        v-model="signIn.username" 
                                        type="email" 
                                        id="username"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors && errors.length }"
                                        :placeholder="$t('shared.placeholders.email')">
                                    <div class="invalid-feedback" v-if="errors && errors.length">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider
                                rules="required"
                                name="Password"
                                v-slot="{ errors }"
                            >
                                <div class="form-group mt-2">
                                    <label for="password">{{ $t("shared.userInfo.password") }}</label>
                                    <input 
                                        v-model="signIn.password" 
                                        type="password" 
                                        id="password" 
                                        class="form-control" 
                                        :class="{ 'is-invalid': errors && errors.length }"
                                        :placeholder="$t('shared.placeholders.password')">
                                    <div class="invalid-feedback" v-if="errors && errors.length">
                                        {{ errors[0] }}
                                    </div>
                                    <small id="forgotPassword" class="form-text">
                                        <a @click="forgotPassword">
                                        {{ $t("shared.password.forgotPassword") }}?
                                        </a>
                                    </small>
                                    
                                </div>
                            </ValidationProvider>
                            <div class="text-center mt-4 mb-2">
                                <button type="submit" @click.prevent="login()" :disabled="invalid" class="btn btn-primary w-100">{{ $t("shared.buttons.signInText") }}</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { AUTHENTICATE_USER } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type";
import { uiValidator } from "@/nucleus-modules/dd-nucleus-ui/mixins/uiValidator.js";

import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";

export default {
    name: 'SignIn',
    mixins: [uiValidator],
    components: {
        HeaderBranding
    },
    props: {
        isModal: {
            type: Boolean,
            default: false
        },
        logo: {
            type:String,
            required: false
        }
    },
    data() {
        return {
            signIn:{
                username: "",
                password: ""
            },
            errorMessage:null,
        }
        },
        mounted() {
            
        },
    methods: {
        ...mapActions({
            authenticateUser: AUTHENTICATE_USER
        }),
        async login() {
            this.$refs.loginForm.validate().then(async (success) => {
                if (success) {
                    let response = await this.authenticateUser(this.signIn);

                    if (response.succeeded) {
                        if(this.isModal){
                            this.$emit('closeModal');
                        }

                        this.$eventBus.$emit(UiEvents.SIGN_IN_SUCCEEDED);

                        this.$router.push({name: 'Home'});
                    } else {
                        this.errorMessage = this.$t(response.messageCode);
                    }
                } else  {
                    this.errorMessage = this.$t("signIn.correctErrorsTryAgain");
                }
            });
        },
        forgotPassword(){
            if(this.isModal){
                this.$emit('forgotPassword');
            }else{
                this.$router.push({name: 'Forgot Password'});
            }
        }
    }
}
</script>

<style lang="scss">
.n-sign-in-container{
    border-radius: 10px;
    padding: 0px 0px ;
}
.n-sign-in { 
    h2{
        font-size: 1.25rem;
        text-transform: uppercase;
        color: $primary-dark-color;
    }
}
</style>