<template>
    <div class="sidebar-container pt-2">
        <div class="text-center">
            <router-link to="/admin">
                <img id="logo" :src="logo" class="img-fluid" />
            </router-link>
        </div>
        <div class="sidebar-menu-container">
            <SidebarMenu :menu="menu"
                         :relative="true"
                         :hide-toggle="true"
                         :theme="'white-theme'"></SidebarMenu>
        </div>
    </div>
</template>

<script>
    import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
    import '@fortawesome/fontawesome-pro/css/all.css'
    import "@/admin/scss/admin-sidebar.scss";
    import { SidebarMenu } from "vue-sidebar-menu";

    export default {
        name: "Sidebar",
        components: {
            SidebarMenu,
        },
        props: {
            menu: Array
        },
        data() {
            return {
                isLoading: true,
                logo: require("@/assets/Nucleus_Logo.png"),
            };
        },
    };
</script>

<style lang="scss" scoped>  
    .v-sidebar-menu .vsm--item {
        background-color: $admin-primary-lightest-color !important;
    }
    #logo {
        margin: 10px 0 25px 0;
        padding: 0 15px;
    }
</style>
