import $http from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { ImpersonateService as BaseImpersonateService } from "@/nucleus-modules/dd-nucleus-ui/services/impersonate.service.js";

class ImpersonateService extends BaseImpersonateService {
    async impersonate(user, route) {
        return await $http.post(`api/v1/admin/user/${user.id}/impersonate`, {

        }).then((response) => {
            this.endImpersonation();

            sessionStorage.setItem(this.getImpersonationKey(), JSON.stringify({
                id: response.token,
                firstName: user.firstName,
                lastName: user.lastName
            }));

            if (route) {
                // new browser tab with impersonation.
                window.open(route, '_blank');

                // end current tab's impersonation.
                this.endImpersonation();
            }
        }, () => {
            ToastService.showErrorToast("Unable to impersonate user. Please try again.");
        });
    }
}

export default new ImpersonateService();
