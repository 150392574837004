import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import {
    SEARCH_ADDRESSES
} from "@/store/storefront/actions.type.js";

import {
    ADDRESS_FILTERS_GETTER,
    ADDRESS_PER_PAGE_CONFIG_GETTER,
    ADDRESS_SEARCH_RESULTS_GETTER,
    ADDRESS_SORTING_CONFIG_GETTER
} from "@/store/storefront/getters.type.js";

import {
    SET_ADDRESS_SEARCH_RESULTS
} from "@/store/storefront/mutations.type.js";

const MODULE_ID = "address";

const initialState = {
    filters: [],
    searchResults: [],
    sortingConfig: {
        defaultSort: "addressee",
        options: [
            {
                id: "addressee",
                name: "Sort By Addressee"
            },
            {
                id: 'city',
                name: 'Sort By City',
            },
            {
                id: 'state',
                name: 'Sort By State',
            },
            {
                id: 'sort_by_postalcode',
                name: 'Sort By Postal Code',
            },
        ]
    },
    perPageConfig: {
        defaultPerPage: 100,
        options: [
            {
                id: 100,
                name: "100 per page"
            },
            {
                id: 200,
                name: "200 per page"
            },
            {
                id: 300,
                name: "300 per page"
            },
            {
                id: 400,
                name: "400 per page"
            },
            {
                id: 500,
                name: "500 per page"
            },
            {
                id: 1000,
                name: "1000 per page"
            },

        ]
    }
};

export const state = { ...initialState };

export const actions = {
    async [SEARCH_ADDRESSES]({ commit }, payload) {
        const response = await ApiService.post("ui/contact-address/search", payload);

        if (response.succeeded) {
            commit(SET_ADDRESS_SEARCH_RESULTS, response.results);
        }
    }
};

export const getters = {
    [ADDRESS_FILTERS_GETTER]: state => {
        return state.filters;
    },
    [ADDRESS_SORTING_CONFIG_GETTER]: state => {
        return state.sortingConfig;
    },
    [ADDRESS_PER_PAGE_CONFIG_GETTER]: state => {
        return state.perPageConfig;
    },
    [ADDRESS_SEARCH_RESULTS_GETTER]: state => {
        return state.searchResults;
    },
};

export const mutations = {
    [SET_ADDRESS_SEARCH_RESULTS]: (state, results) => {
        state.searchResults = results;
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
