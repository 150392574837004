<template>
    <AdminTemplate>
        <div v-show="currentView === viewTypes.HOME" class="row">
            <div class="col">
                <AllOrderApprovals :isLoading="isLoading"></AllOrderApprovals>
            </div>
        </div>        
    </AdminTemplate>
</template>

<script>
import AllOrderApprovals from "@/components/admin/order/AllOrderApprovals.vue";

import { OrderApprovalsMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrderApprovalsMixin.js";

import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import AdminTemplate from "@/pages/templates/AdminTemplate.vue";

export default {
  name: "OrderApprovals",
  mixins: [OrderApprovalsMixin],
  components: {
    AllOrderApprovals,
    AdminTemplate,
  },
  data() {
    return {
      availableStatuses: [
        { id: -1, name: "All" },
        { id: StatusCodes.ACTIVE, name: "Active" },
        { id: StatusCodes.INACTIVE, name: "Inactive" },
      ],
      currentView: null,
      isLoading: true,
      viewTypes: {
        HOME: "Home",
        CREATE: "Create",
        CONFIRMATION: "Confirmation",
      },
      // TODO: retrieve the ship methods from backend
      allShipMethods: [
        { id: "U11", name: "UPS Ground" },
        { id: "U12", name: "Next Day" },
      ],
    };
  },
  computed: {    
  },
  methods: {    
    showConfirmation() {
      this.currentView = this.viewTypes.CONFIRMATION;
    },
   
  },
  async mounted() {
    await this.getAllRoles();   
    await this.searchOrders();

    this.currentView = this.viewTypes.HOME;
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped></style>
