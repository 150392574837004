<template>
  <div>
    <h5 class="n-form-section-header">Project Number</h5>
    <div class="form-group mt-4">
        <label class="n-form-labels">Project Number</label>
        <input type="text"
               class="form-control n-text-field-highlight"
               :class="{ 'is-invalid': $v.projectNumber.$invalid }"
               v-model.trim="projectNumber"
               @input="$v.projectNumber.$touch()" />
    </div>
    <div v-if="$v.projectNumber.$invalid" class="n-error">
      <span v-if="!$v.projectNumber.required"
        >A project number is required.</span
      >
    </div>
    <div class="form-group mt-4">
        <label class="n-form-labels">Sub Project Number (optional)</label>
        <input type="text"
               class="form-control n-text-field-highlight"
               :class="{ 'is-invalid': $v.subProjectNumber.$invalid }"
               v-model.trim="subProjectNumber"
               @input="$v.subProjectNumber.$touch()" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  PRODUCT_FOR_EDIT_GETTER,
  PRODUCT_PROPERTY_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

const ATTRIBUTE_NAME = "BlsProperties";

export default {
  name: "ProjectDetails",
  props: {
    defaultBlsProjectNumber: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      product: PRODUCT_FOR_EDIT_GETTER,
      getProductProperty: PRODUCT_PROPERTY_GETTER,
    }),
    projectNumber: {
      get() {
        const value = this.getProductProperty("projectNumber", ATTRIBUTE_NAME);

        if (value && value !== "") {
          return value;
        } else {
          return this.defaultBlsProjectNumber;
        }
      },
      set(value) {
        this.setProductProperty({
          attribute: ATTRIBUTE_NAME,
          name: "projectNumber",
          value: value,
        });
      },
    },
    subProjectNumber: {
      get() {
        return this.getProductProperty("subProjectNumber", ATTRIBUTE_NAME);
      },
      set(value) {
        this.setProductProperty({
          attribute: ATTRIBUTE_NAME,
          name: "subProjectNumber",
          value: value,
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
  },
  mounted() {
    this.setProductProperty = this.$nucleus.debounce(
      this.setProductProperty,
      200
    );
  },
  validations: {
    projectNumber: {
      required,
    },
    subProjectNumber: {},
  },
};
</script>

<style lang="scss" scoped></style>
