<template>
  <div class="d-inline" v-if="isDisabled">
    <slot></slot>
  </div>
  <div class="d-inline" v-else>
    <div v-if="isAuthenticated">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthenticationWrapper",
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
    },
    isAuthenticated: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
