<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-8">
        <div class="n-title-text">
          <h4 class="n-order-date">{{order.orderDate | moment("MMMM DD, YYYY")}}
          | <span>Order # {{ order.orderNumber }}</span>
          </h4>
        </div>
        <div class="n-order-status my-1">
          <OrderStatusComponent :status="orderStatus" />
          <OrderShipDate :shipDate="shipDate" />
        </div>
        <div class="row n-order-additional-messages">
          <div class="col-sm-12 col-md-12 mt-2">
            <OrderAdditionalMessages
              :messages="messages"
            ></OrderAdditionalMessages>
          </div>
        </div>
      </div>
      <div class="col-lg-4 text-right n-number-items">
        <div v-if="isPricingEnabled">
          <h4>
            ${{ order.total }} ({{ totalItems }} {{ itemsText }}) |
            <router-link :to="''">View Order</router-link>
          </h4>
        </div>
        <div v-else>
          <h4>
            {{ totalItems }} {{ itemsText }} |
            <router-link
              :title="order.orderNumber"
              :to="'/order-detail/' + order.id"
            >
              View Order
            </router-link>
          </h4>
        </div>
        <template v-if="hasShiments">
          <div v-if="totalTrackingNumbers > 1">
            <router-link
              :title="order.orderNumber"
              :to="'/order-detail/' + order.id"
            >
              Track Your Order
            </router-link>
          </div>
          <div
            v-else
            v-for="shipment in shipments"
            :key="shipment.trackingNumber"
          >
            <a :href="shipment.trackingUrl" target="_blank">Track Your Order</a>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import OrderStatusComponent from "@/nucleus-modules/dd-nucleus-storefront/components/OrderStatus.vue";
import OrderShipDate from "@/nucleus-modules/dd-nucleus-storefront/components/OrderShipDate.vue";
import OrderAdditionalMessages from "@/nucleus-modules/dd-nucleus-storefront/components/OrderAdditionalMessages.vue";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants";
import { OrderStatus, ShippingStatus, PaymentStatus, ItemShippedText } from "@/nucleus-modules/dd-nucleus-storefront/constants";

export default {
  name: "Order-History-Item",
  components: {
    OrderStatusComponent,
    OrderShipDate,
    OrderAdditionalMessages,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    isPricingEnabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
    OrderStatus: OrderStatus,
    ShippingStatus: ShippingStatus,
    PaymentStatus: PaymentStatus,
    }
  },
  computed: {
    messages() {
      var additionalMessages = [];
      if (this.order) {
        if (this.order.shippingStatus == ShippingStatus.PARTIAL.code) 
        {
          additionalMessages.push({
            type: MessageType.WARNING,
            text: ItemShippedText,
            link: {
              internal: true,
              to: "/order-detail/" + this.order.id,
              text: "Shipping Details",
            },
          });
        }
      }
      return additionalMessages;
    },
    hasShiments() {
      return this.order && this.order.attributes && this.order.attributes.shipment;
    },
    totalTrackingNumbers() {
      if (this.hasShiments && this.order.attributes.shipment.trackingNumbers && this.order.attributes.shipment.trackingNumbers.length) {
        return this.order.attributes.shipment.trackingNumbers.length;
      }
      return 0;
    },
    shipments() {
      if(this.hasShiments) {
        return this.order.attributes.shipment.trackingNumbers;
      }
      return [];
    },
    itemsText() {
      return this.totalItems > 1 ? "Items" : "Item";
    },
    orderStatus() {
      if (this.order) {
        let orderStatus = this.order.orderStatus;
        let shipStatus = this.order.shippingStatus;
        let paymentStatus = this.order.paymentStatus;

        if(orderStatus == OrderStatus.COMPLETED.code && paymentStatus == PaymentStatus.COMPLETED.code){
          return PaymentStatus.COMPLETED.text;
        }
        else if((orderStatus == OrderStatus.COMPLETED.code || orderStatus == OrderStatus.PENDING.code) && shipStatus == ShippingStatus.COMPLETED.code){
          return ShippingStatus.COMPLETED.text;
        }
        else if((orderStatus == OrderStatus.PENDING.code) && paymentStatus == PaymentStatus.PARTIAL.code){
          return PaymentStatus.PARTIAL.text;
        }
        else if((orderStatus == OrderStatus.PENDING.code) && shipStatus == ShippingStatus.PARTIAL.code){
          return ShippingStatus.PARTIAL.text;
        }
        else if(orderStatus == OrderStatus.CANCELLED.code){
          return OrderStatus.CANCELLED.text;
        }
        else if(orderStatus == OrderStatus.PENDING_APPROVAL.code){
          return OrderStatus.PENDING_APPROVAL.text;
        }
        else if(orderStatus == OrderStatus.PENDING.code){
          return OrderStatus.PENDING.text;
        }

        return "No Status Available";
      }

      return "No Status Available"
    },
    totalItems() {
      if(this.order && this.order.items && this.order.items.length){
        return this.order.items.length;
      }
      return 0;
    },
    shipDate() {
      if(this.hasShiments){
        return this.order.attributes.shipment.shipDate;
      }
      return "";
    }
  },
};
</script>

<style lang="scss">
.n-border-bottom {
  border-bottom: 1px solid #000;
  margin-top: 10px;
}


.n-order-additional-messages,
.n-order-date,
.n-order-status,
.n-status {
  padding-left: 20px;
}

@media (max-width: 576px) {
  .n-page-header,
  .n-order-additional-messages,
  .n-order-date,
  .n-order-status,
  .n-status {
    padding-left: 15px;
  }
}
</style>
