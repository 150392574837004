<template>
  <div>
    <button
      :disabled="isFirstStep"
      :class="buttonClasses"
      class="mr-3"
      @click="stepPrevious()"
    >
      Previous
    </button>
    <button :disabled="isLastStep" :class="buttonClasses" @click="stepNext()">
      Next
    </button>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: {
    stepperConfig: {
      type: Object,
      required: true,
    },
    buttonClasses: {
      type: String,
      required: false,
      default: "btn btn-primary btn-sm",
    },
  },
  computed: {
    isFirstStep() {
      return this.stepperConfig.currentStep === 0;
    },
    isLastStep() {
      return this.stepperConfig.currentStep === this.stepperConfig.totalSteps;
    },
  },
  methods: {
    stepNext() {
      this.$eventBus.$emit(this.stepperConfig.stepNextEventName);
    },
    stepPrevious() {
      this.$eventBus.$emit(this.stepperConfig.stepPreviousEventName);
    },
  },
};
</script>

<style lang="scss">
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
