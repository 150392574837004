<template>
  <div>
    <div class="row">
      <div class="form-group col-4">
        <label class="n-form-labels mb-0">Announcement Name</label>
        <input
          type="text"
          class="form-control n-text-field-highlight mb-0"
          :class="{ 'is-invalid': isNotValid($v.name.$model) }"
          v-model="name"
        />
        <div
          v-if="$v.name.$invalid && isNotValid($v.name.$model)"
          class="n-error position-absolute"
        >
          <span v-if="!$v.name.required && isNotValid($v.name.$model)"
            >An announcement name is required.</span
          >
        </div>
      </div>
      <div class="form-group col-3"></div>
      <div class="form-group col">
        <label class="n-form-labels">Date Last Modified</label>
        <br />
        <label class="n-form-labels font-weight-normal pb-3">{{
          updated | moment("MM/DD/YYYY hh:mm a")
        }}</label>
        <br />
        <label class="n-form-labels">Created Date</label>
        <br />
        <label class="n-form-labels font-weight-normal pb-3">{{
          created | moment("MM/DD/YYYY hh:mm a")
        }}</label>
        <br />
        <label class="n-form-labels">Created By</label>
        <br />
        <label class="n-form-labels font-weight-normal">{{ createdBy }}</label>
      </div>
    </div>

    <div
      :class="{
        'n-mt-n7': isAddMode,
        'n-mt-n9': !isAddMode,
      }"
    >
      <div class="form-group mb-3 pl-0 col-3">
        <label for="startDate" class="mb-1 n-form-labels"> Start Date </label>
        <b-form-datepicker
          id="startDate"
          v-model="startDate"
          today-button
          reset-button
          locale="en"
          :date-format-options="dateFormatOptions"
        >
        </b-form-datepicker>
      </div>
      <div
        v-if="$v.startDate.$invalid && isNotValid($v.startDate.$model)"
        class="n-error"
      >
        <span>Start date is required.</span>
      </div>
      <div class="form-group mb-4 pl-0 col-3">
        <label for="endDate" class="mb-1 n-form-labels"> End Date </label>
        <b-form-datepicker
          id="endDate"
          v-model="endDate"
          today-button
          reset-button
          locale="en"
          :date-format-options="dateFormatOptions"
        ></b-form-datepicker>
      </div>
      <div
        v-if="$v.endDate.$invalid && isNotValid($v.endDate.$model)"
        class="n-error"
      >
        <span>End date is required.</span>
      </div>

      <div class="form-group mt-4 pl-0 col-6">
        <label class="n-form-labels"
          >Announcement Description (Displayed on Storefront)</label
        >
        <Editor
          :api-key="apiKey"
          :init="editorConfig"
          class="form-control n-text-field-highlight"
          v-model.trim="description"
        >
        </Editor>
      </div>
      <div
        v-if="$v.description.$invalid && isNotValid($v.description.$model)"
        class="n-error"
      >
        <span>A description is required.</span>
      </div>
      <template>
        <div class="form-group">
          <b-form-checkbox v-model="isDefault" name="isDefault">
            <span id="boot-icon" class="bi bi-star-fill n-default-star"></span>
            <label class="n-form-labels pl-1">Default Announcement</label>
          </b-form-checkbox>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import Editor from "@tinymce/tinymce-vue";
import { SET_ANNOUNCEMENT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { ANNOUNCEMENT_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_ANNOUNCEMENT_FOR_EDIT } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { editorApiKey } from "@/companyAppConfig";

export default {
  name: "AnnouncementInfo",
  components: {
    Editor,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      editorConfig: {
        height: 200,
        menubar: false,
        content_style: "p { margin: 0; }",
        browser_spellcheck: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
          "lists",
          "link",
          "code",
        ],
        toolbar:
          "undo redo | bold | italic | charmap | underline | p | forecolor | bullist | numlist | link | code",
      },
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      isEditable: true,
      apiKey: editorApiKey,
    };
  },
  computed: {
    ...mapGetters({
      announcementForEdit: ANNOUNCEMENT_FOR_EDIT_GETTER,
    }),
    isAddMode: {
      get() {
        if (this.$nucleus.isEmpty(this.announcementForEdit.id)) {
          return true;
        }
        return false;
      },
    },
    name: {
      get() {
        return this.announcementForEdit.name;
      },
      set(value) {
        this.setAnnouncementProperty({ name: "name", value: value });
      },
    },
    startDate: {
      get() {
        return this.announcementForEdit.startDate;
      },
      set(value) {
        this.setAnnouncementProperty({ name: "startDate", value: value });
      },
    },
    endDate: {
      get() {
        return this.announcementForEdit.endDate;
      },
      set(value) {
        this.setAnnouncementProperty({ name: "endDate", value: value });
      },
    },
    description: {
      get() {
        return this.announcementForEdit.description;
      },
      set(value) {
        this.setAnnouncementProperty({ name: "description", value: value });
      },
    },
    updated: {
      get() {
        return this.announcementForEdit.updated;
      },
    },
    created: {
      get() {
        return this.announcementForEdit.created;
      },
    },
    createdBy: {
      get() {
        return this.announcementForEdit.createdBy;
      },
    },
    isDefault: {
      get() {
        return this.announcementForEdit.isDefault;
      },
      set(value) {
        this.setAnnouncementProperty({ name: "isDefault", value: value });
      },
    },
  },

  methods: {
    ...mapMutations({
      setAnnouncementForEdit: SET_ANNOUNCEMENT_FOR_EDIT,
      setAnnouncementProperty: SET_ANNOUNCEMENT_PROPERTY,
    }),
    isNotValid(fieldName) {
      return fieldName != null && fieldName.length == 0;
    },
  },

  edit() {
    this.isEditable = true;
  },
  validations: {
    name: {
      required,
    },
    startDate: {
      required,
    },
    endDate: {
      required,
    },
    description: {
      required,
    },
  },
  async mounted() {
    this.setAnnouncementProperty = this.$nucleus.debounce(
      this.setAnnouncementProperty,
      100
    );
  },
};
</script>

<style lang="scss" scoped>
.n-mt-n9 {
  margin-top: -9.6rem !important;
}
.n-mt-n7 {
  margin-top: -7rem !important;
}
.n-default-star {
  font-size: 23px;
  color: $secondary-color;
}
:deep(.b-calendar-grid-body) {
  min-height: 14.25rem !important;
}
</style>

