<template>
  <span class="n-order-status-display">
    <strong>
      <div v-if="isPendingApproval">
        <span class="badge badge-warning">On Hold - Pending Approval</span>
      </div>
      <div v-else>
        <font-awesome-icon :class="iconClass" :icon="icon" />
        <span v-if="isCancelled" class="text-danger"> {{ status }} </span>
        <span v-else> {{ status }} </span>
      </div>
      <span v-if="shipDate">on {{ shipDate | moment("MMMM DD, YYYY") }}</span>
    </strong>
  </span>
</template>

<script>
import {
  OrderStatus,
  ShippingStatus,
} from "@/nucleus-modules/dd-nucleus-storefront/constants";

export default {
  name: "Order-Status",
  props: ["status", "shipDate", "messages"],
  data() {
    return {
      ShippingStatus: ShippingStatus,
    };
  },
  computed: {
    // Compute the icon to be used. It can be supplied in the icon property, or if one is not set, it will use the message type.
    icon() {
      switch (this.status) {
        case OrderStatus.CANCELLED.text:
          return "times-circle";
        case ShippingStatus.PARTIAL.text:
          return "check-circle";
        case OrderStatus.PENDING.text:
          return "check-circle";
        case ShippingStatus.COMPLETED.text:
        case OrderStatus.COMPLETED.text:
          return "check-circle";
        default:
          return "check-circle";
      }
    },
    isCancelled() {
      return this.status === OrderStatus.CANCELLED.text;
    },
    isPendingApproval() {
      return this.status === OrderStatus.PENDING_APPROVAL.text;
    },
    // Compute the CSS class to use based on the message type
    iconClass() {
      switch (this.status) {
        case OrderStatus.CANCELLED.text:
          return "text-danger";
        case ShippingStatus.PARTIAL.text:
          return "n-partial-success";
        case OrderStatus.PENDING.text:
          return "n-partial-success";
        case ShippingStatus.COMPLETED.text:
        case OrderStatus.COMPLETED.text:
          return "n-success";
        default:
          return "n-partial-success";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
