import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue);

export const mainApp = new Vue();

const defaults = {
    size: "sm",
    buttonSize: "sm",
    okVariant: "danger",
    okTitle: "Yes",
    cancelVariant: "primary",
    cancelTitle: "No",
    hideHeaderClose: false
};

class ConfirmationBoxService {
    constructor(options) {
        this.options = { ...defaults, ...options };
    }

    async showConfirm(confirmText) {
        
        return mainApp.$bvModal.msgBoxConfirm(confirmText, this.options);
    }
}

export default ConfirmationBoxService;