export default {
    props: {
        close: {
            type: Function,
            required: true
        },
        dismiss: {
            type: Function,
            required: true
        },
    }
}