<template>
    <div v-if="!$nucleus.isEmpty(report)" class="report-item pb-4">
        <div class="row">
            <div 
                class="col-md-7 reportName pointed-cursor text-nowrap"
                @click="displayReport(report.externalId, report.id)">
                {{ report.name }} 
                <span class="px-2">
                    <font-awesome-icon class="n-image-icons"
                                       :icon="['fas', !$nucleus.isEmpty(report.userId) || report.name==='User List' || report.name==='Orders Placed Detailed Report' || report.name==='Orders Placed Report'?   ['user']: 'users']" />
                </span>
            </div>
            <div class="col-md-1 px-2 text-nowrap">
                <router-link
                    :id="report.id"
                    :title="report.name"
                    :to="'add-edit-report?id=' + report.id"
                    class="mr-2"
                  >
                    <font-awesome-icon
                        class="icon pencil"
                        :icon="['fas', 'pencil']"
                    />
                </router-link>
                <font-awesome-icon
                :class="['n-image-icons', 'pointed-cursor','delete-icon']"
                :icon="['fas', 'trash-alt']"
                @click="deleteReport(report.id)"
                />
            </div>
            <div class="col-md-1 pointed-cursor" @click="displayDescription">
                <template v-if="!$nucleus.isEmpty(report.description)" >
                <font-awesome-icon
                :class="['n-image-icons']"
                :icon="['fa', arrowIcon]"
                />
                </template>
            </div>
        </div>        
        <transition name="fade">
            <div class="row mt-1" v-show="!$nucleus.isEmpty(report.description) && showDescription">
                <div class="col-md-12 n-report-description">
                    {{ report.description }} 
                </div>
            </div>    
      </transition>
    </div>
</template>

<script>

export default {
    data() {
        return {
            showDescription: false,
            arrowIcon:'angle-down'
        }
    },
    components: {
        
    },
    props: {
        report:Object
    },
    methods: {
        displayDescription() {
            this.showDescription = !this.showDescription;
            
            if(this.arrowIcon == 'angle-down') {
                this.arrowIcon = 'angle-up'
            } else {
                this.arrowIcon = 'angle-down'
            }
        },
        displayReport(externalId, reportId) {
                window.open('/admin/report-detail?reportId=' + reportId +'&externalId='+externalId, '_blank');
        },
        deleteReport(id){
            this.$emit('deleteReport', id);
        }
    }
}
</script>

<style lang="scss" scoped>
.reportName {
    font-size: 0.85rem;
    color: $admin-link-light-color;
}
.delete-icon {
    color:$admin-primary-color;
}
.pointed-cursor {
    cursor:pointer
}
.fade-leave-active {
  transition: opacity .1s;
}
.fade-enter-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.n-report-description {
    font-size: 0.85rem;
}

.icon {
  font-size: 15px;
}

.pencil {
  color: $admin-complete;
}
</style>