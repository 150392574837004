<template>
  <div>
    <PageSubHeader title="Account Addresses" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-5">
        <UserAddressViewOnly :address="defaultAddress" :title="defaultAddress.title"></UserAddressViewOnly>
      </div>
      <div class="col-5 offset-2">
        <UserAddressViewOnly :address="shippingAddress" :title="shippingAddress.title"></UserAddressViewOnly>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
import UserAddressViewOnly from "@/nucleus-modules/dd-nucleus-admin/components/user/UserAddressViewOnly.vue";

export default {
  name: "UserAddressInfoViewOnly",
  props: {
    defaultAddress: {
      type: Object,
    },
    shippingAddress: {
      type: Object,
    },
  },
  components: {
    PageSubHeader,
    UserAddressViewOnly,
  },
};
</script>

<style></style>
