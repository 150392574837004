<template>
  <div v-if="hasImageUrl" class="text-center">
    <router-link to="/">
      <img
        id="logo"
        :src="imageUrl"
        v-bind:alt="alt"
        class="branding-logo img-fluid"
      />
    </router-link>
  </div>
  <div class="n-text-logo" v-else>
    <router-link to="/">
      {{ text }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HeaderBranding",
  props: {
    imageUrl: {
      type: String,
      required: false,
    },
    text: {
      type: String ,
      required: false,
    },
    alt: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasImageUrl() {
      return this.imageUrl && this.imageUrl.length > 0;
    },
  }
};
</script>

<style lang="scss" scoped>
.branding-logo {
  max-height: 100px;
  max-width: 80%;
}
.n-text-logo {
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .branding-logo {
    max-height: 100px;
    padding: 0 10px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .branding-logo {
    max-height: auto;
    max-width:200px
  }
}
</style>
