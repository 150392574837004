import Vue from 'vue'
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin);

export const mainApp = new Vue();

import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

class ToastService {
    communicationErrorToast(message) {
        // TODO: i18n the title
        mainApp.$bvToast.toast(message, {
            title: "Communication Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 10 * 1000
        }); 
    }
    showToast(message, type) {
        let toastTitle = type.charAt(0).toUpperCase() + type.slice(1);
        if (type == MessageType.ERROR) {
            type = MessageType.DANGER;
        }

        mainApp.$bvToast.toast(message, {
            title: toastTitle,
            variant: type,
            solid: true,
            autoHideDelay: 5 * 1000
        }); 
    }
    showSuccessToast(message, title) {
        let toastTitle = title || 'Success';
        mainApp.$bvToast.toast(message, {
            title: toastTitle,
            variant: 'success',
            solid: true,
            autoHideDelay: 5 * 1000
        }); 
    }
    showInfoToast(message, title) {
        let toastTitle = title || 'Info';
        mainApp.$bvToast.toast(message, {
            title: toastTitle,
            variant: 'info',
            solid: true,
            autoHideDelay: 5 * 1000
        }); 
    }
    showWarningToast(message, title) {
        let toastTitle = title || 'Warning';

        mainApp.$bvToast.toast(message, {
            title: toastTitle,
            variant: 'warning',
            solid: true,
            autoHideDelay: 5 * 1000
        }); 
    }
    showErrorToast(message, title) {
        let toastTitle = title || 'Error';

        mainApp.$bvToast.toast(message, {
            title: toastTitle,
            variant: 'danger',
            solid: true,
            autoHideDelay: 5 * 1000
        }); 
    }
}

export default new ToastService();
