<template>
<div class="n-cart-icon n-header-nav">
  <router-link to="/shopping-cart">
    <div class="row d-lg-none">
      <div class="col text-center">
        <div class="n-cart-sm">
          <div class="text-center n-cart-total">
            {{ totalCartItems }}
          </div>
          <font-awesome-icon class="mr-3 n-cart" :icon="['fa', 'shopping-cart']" />
        </div>
      </div>
    </div>
    <div class="row d-none d-lg-block">
      <div class="d-flex">
        <div class="">
          <font-awesome-icon class="mr-3 n-cart" :icon="['fa', 'shopping-cart']" />
        </div>
        <div class="text-right">
          <div class="n-cart-total">
            {{ totalCartItems }}
            <div>Cart</div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
 </div> 
</template>

<script>
export default {
  name: "HeaderCart",
  props: {
    totalCartItems: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
a:hover{
  text-decoration: none;
}
.n-cart-icon .fa-cart-arrow-down {
  font-size: 40px;
}
.n-cart-total{
  padding-right: 15px;
  color:$primary-dark-color;
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  margin-top: 2px;
}
.n-cart {
  color:$secondary-color;
  font-size: 35px;
  margin-top: 1px;
}
.n-cart-sm {
  margin-top: -15px;
}


@media only screen and (max-width: 991px) {
  .n-cart-total{
    font-size: 1rem;
    z-index: 10;
  }
}

@media only screen and (max-width: 767px) {
  .n-cart{
    font-size: 40px;
  }
}

@media only screen and (max-width: 300px) {
  .n-cart-total{
    right: 9px;
  }
}
</style>
