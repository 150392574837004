import { mapActions, mapGetters, mapMutations } from "vuex";

import {
    ADD_BULK_ORDER_RECIPIENT,
    GET_ALL_ROLES,
    GET_BULK_ORDER_DETAIL,
    GET_COMPANY_SEGMENTS,
    INIT_BULK_ORDER,
    PLACE_BULK_ORDER,
    REMOVE_BULK_ORDER_RECIPIENT,
    RESET_BULK_ORDER_ERRORS,
    SEARCH_BULK_ORDER_RECIPIENTS,
    SEARCH_BULK_ORDERS,
    SEARCH_PRODUCTS,
    UPDATE_BULK_ORDER_PRODUCTS,
    UPDATE_BULK_ORDER_SHIPPING_METHOD
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";

import {
    ALL_ROLES_GETTER,
    BULK_ORDER_DETAIL_GETTER,
    BULK_ORDER_USERS_GETTER,
    COMPANY_SEGMENTS_GETTER,
    GET_BULK_ORDER_DETAIL_RESPONSE_GETTER,
    PLACE_BULK_ORDER_ERRORS_GETTER,
    PLACE_BULK_ORDER_RESPONSE_GETTER,
    RECIPIENTS_SEARCH_RESPONSE_GETTER,
    SEARCH_BULK_ORDERS_RESPONSE_GETTER,
    SEARCH_PRODUCTS_RESULTS_GETTER,
    SITE_SETUP_GETTER,
    STATES_LIST_GETTER
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_BULK_ORDER_DETAIL, SET_BULK_ORDER_PRODUCTS } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { AdminEvents, BulkOrderFilters } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import {
    OrderStatus,
    ShippingStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export const BulkOrdersMixin = {
    computed: {
        ...mapGetters({
            allRoles: ALL_ROLES_GETTER,
            bulkOrderDetail: BULK_ORDER_DETAIL_GETTER,
            bulkOrdersSearchResponse: SEARCH_BULK_ORDERS_RESPONSE_GETTER,
            getBulkOrderDetailResponse: GET_BULK_ORDER_DETAIL_RESPONSE_GETTER,
            companySegments: COMPANY_SEGMENTS_GETTER,
            placeBulkOrderErrors: PLACE_BULK_ORDER_ERRORS_GETTER,
            placeBulkOrderResponse: PLACE_BULK_ORDER_RESPONSE_GETTER,
            searchProductsResults: SEARCH_PRODUCTS_RESULTS_GETTER,
            searchRecipientsResponse: RECIPIENTS_SEARCH_RESPONSE_GETTER,
            selectedRecipients: BULK_ORDER_USERS_GETTER,
            statesList: STATES_LIST_GETTER,
            siteSetup: SITE_SETUP_GETTER,
        }),
        hasSelectedProducts() {
            let result = false;

            if (this.bulkOrderDetail && this.bulkOrderDetail.products && this.bulkOrderDetail.products.length > 0) {
                const products = this.bulkOrderDetail.products;
                result = !products.some(p => p.quantityPerRecipient <= 0)
             }

            return result;
        },
        selectedRecipientsForExport() {
            return this.selectedRecipients.map((item) => {
                const keys = Object.keys(item);
                keys.forEach(key => {
                  if(!item[key]){
                    item[key] = ""
                  }
                });
                if (item.addressTypeName === "Billing") {
                    let newItem = { ...item, addressTypeName: "Primary" }
                    return newItem;
                } else {
                    return item;
                }
            });
        },
        shippingMethods() {
            const result = [];
            if(this.siteSetup && this.siteSetup.shipMethods && this.siteSetup.shipMethods.length > 0) {
                this.siteSetup.shipMethods[0].values.forEach(sm => {
                    result.push({
                        code: sm.code,
                        name: sm.name,
                        minDaysInTransit: sm.minDaysInTransit,
                    });
                });
            }
            return result;
        }
    },
    methods: {
        ...mapActions({
            addRecipientAction: ADD_BULK_ORDER_RECIPIENT,
            getAllRoles: GET_ALL_ROLES,
            getBulkOrderDetail: GET_BULK_ORDER_DETAIL,
            getCompanySegments: GET_COMPANY_SEGMENTS,
            initBulkOrderAction: INIT_BULK_ORDER,
            placeBulkOrderAction: PLACE_BULK_ORDER,
            removeRecipientAction: REMOVE_BULK_ORDER_RECIPIENT,
            searchBulkOrdersAction: SEARCH_BULK_ORDERS,
            searchProductsAction: SEARCH_PRODUCTS,
            searchRecipientsAction: SEARCH_BULK_ORDER_RECIPIENTS,
            updateProductsAction: UPDATE_BULK_ORDER_PRODUCTS,
            updateShippingMethodAction: UPDATE_BULK_ORDER_SHIPPING_METHOD,
            resetBulkOrderErrorsAction: RESET_BULK_ORDER_ERRORS
        }),
        ...mapMutations({
            setBulkOrderDetail: SET_BULK_ORDER_DETAIL,
            setBulkOrderProducts: SET_BULK_ORDER_PRODUCTS
        }),
        addRecipient(recipient) {
            this.addRecipientAction(recipient);
            this.recalculateProductTotals();
        },
        addUsersToBulkOrder(users) {
            users = users.filter(u => !this.selectedRecipients.find(r => r.recipientId === u.recipientId));

            const usersToAdd = users.filter(u => u.selected);
            const usersList = this.bulkOrderDetail.users.concat(usersToAdd);
            const updatedDetail = this.bulkOrderDetail;

            updatedDetail.users = usersList.map(u => ({ ...u, selected: true }));

            this.setBulkOrderDetail(updatedDetail);
            this.recalculateProductTotals();

            return usersToAdd.length;
        },
        getOrderStatusBadgeInfo(orderStatus) {
            let result;

            switch (orderStatus) {
                case OrderStatus.NONE:
                    result = { className: "badge-pending", statusName: "None" };
                    break;
                case OrderStatus.PENDING:
                    result = { className: "badge-pending", statusName: "Pending" };
                    break;
                case OrderStatus.PENDING_APPROVAL:
                    result = {
                        className: "badge-pending-approval",
                        statusName: "Pending Approval",
                    };
                    break;
                case OrderStatus.COMPLETED:
                    result = { className: "badge-complete", statusName: "Completed" };
                    break;
                case OrderStatus.CANCELLED:
                    result = { className: "badge-cancelled", statusName: "Cancelled" };
                    break;
                default:
                    result = { className: "badge-cancelled", statusName: "Unknown" };
                    break;
            }

            return result;
        },
        getShippingStatusBadgeInfo(shippingStatus) {
            let result;

            switch (shippingStatus) {
                case ShippingStatus.NONE:
                case ShippingStatus.PENDING:
                    result = { className: "badge-not-shipped", statusName: "Not Shipped" };
                    break;
                case ShippingStatus.PARTIAL:
                    result = { className: "badge-pending", statusName: "Partially Shipped" };
                    break;
                case ShippingStatus.COMPLETED:
                    result = { className: "badge-shipped", statusName: "Shipped" };
                    break;
                default:
                    result = { className: "badge-cancelled", statusName: "Unknown" };
                    break;
            }

            return result;
        },
        async initBulkOrder(shipMethod) {
            await this.initBulkOrderAction();

            this.updateShippingMethod(shipMethod);
            this.$eventBus.$emit(AdminEvents.BULK_ORDER_CANCELLED);
        },
        async placeBulkOrder(internalNotes) {
            const products = this.bulkOrderDetail.products.map(p => ({
                id: p.id,
                quantity: p.quantityPerRecipient
            }));

            const request = {
                bulkOrder: {
                    shipMethodCode: this.bulkOrderDetail.shippingMethod.code,
                    internalNotes: internalNotes,
                    recipients: this.selectedRecipients.map(r => ({
                        recipientId: r.recipientId,
                        isUser: r.isUser,
                        addressType: r.addressType,
                        products: products
                    }))
                }
            };

            await this.placeBulkOrderAction(request);
        },
        recalculateProductTotals() {
            this.resetBulkOrderErrors();
            const updatedproducts = this.bulkOrderDetail.products.map(p => ({ ...this.bulkOrderDetail.products, quantityTotal: p.quantityPerRecipient * p.quantityPerUnit * this.selectedRecipients.length }));
            this.setBulkOrderProducts = updatedproducts;
        },
        removeRecipient(recipient) {
            this.removeRecipientAction(recipient);
            this.recalculateProductTotals();
        },
        removeUsersFromBulkOrder(ids) {
            const usersList = this.bulkOrderDetail.users;
            const updatedDetail = this.bulkOrderDetail;

            updatedDetail.users = usersList.filter(u => !ids.includes(u.recipientId));

            this.setBulkOrderDetail(updatedDetail);
            this.recalculateProductTotals();
        },
        async searchBulkOrders(term) {
            const request = { 
                filters: [], 
                paging: {
                    pageNumber: this.paging ? this.paging.pageNumber : 0,
                    pageSize: this.paging ? this.paging.pageSize : 5,
                    includeTotalCount: true,
                }, 
            };

            if (term && term.length > 0) {
                request.filters.push({ filterId: BulkOrderFilters.BULK_ORDER_NUMBER.id, values: [term] })
            }

            await this.searchBulkOrdersAction(request);
        },
        async searchProducts(term) {
            if (term && term.length > 0) {
                const request = {
                    filters: [
                        { filterId: BulkOrderFilters.SEARCH_TERM.id, values: [term] }
                    ],
                    paging: {
                        pageNumber: 0,
                        pageSize: 200,
                        includeTotalCount: true,
                    },
                };

                await this.searchProductsAction(request);
            }
        },
        async searchRecipients(request) {
            const searchRequest = {
                filters: request.filters.map(f => ({ filterId: f.filter.id, values: f.values })),
                page: request.page,
                paging: request.paging,
                sort: request.sort
            };

            await this.searchRecipientsAction(searchRequest);
        },
        async updateShippingMethod(shippingMethod) {
            this.updateShippingMethodAction({ shippingMethod: shippingMethod });
        },
        resetBulkOrderErrors() {
            this.resetBulkOrderErrorsAction();
        },
    }
};
