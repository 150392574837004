<template>
    <div>
        <SignInOut title="Regeneron Promotional Ordering Portal" 
        styleClass="n-signin-h"/>
    </div>
</template>

<script>
    import SignInOut from "@/components/auth/SignInOut.vue"
    export default {
        name: "SignInMethod",
        components: {
            SignInOut
        },
    };
</script>

<style lang="scss" scoped>
</style>
