<template>
	<PageTemplate :showHeader="true">
		<div v-if="!showOverlay">
			<div class="row mt-2">
				<div class="col-md-3 d-none d-lg-block">
					<AccountMenu :title="title" menu-id="account"></AccountMenu>
				</div>
				<div class="col-md-12 col-lg-9">
					<h3 class="n-page-header">My Password</h3>
					<div class="row">
						<div v-if="!edit" class="col mb-135 mb-sm-0 mt-sm-5">
							<button class="btn btn-primary" @click="toggleEdit">{{ $t("myPassword.buttons.edit")
							}}</button>
						</div>
						<div v-else class="col-sm-6">
							<ValidationObserver ref="changePasswordForm" v-slot="{ invalid }">
								<form id="changePasswordForm">
									<div class="form-group">
										<div v-if="errorMessage" class="my-3 alert alert-danger">
											<span v-html="errorMessage"></span>
										</div>
									</div>
									<ValidationProvider rules="required" name="Current Password" v-slot="{ errors }">
										<div class="form-group">
											<label for="currentPassword">{{ $t("myPassword.labels.currentPassword")
											}}</label>
											<input v-model="currentPassword" type="password"
												class="form-control n-text-field-highlight"
												:class="{ 'is-invalid': errors && errors.length }"
												name="current_password" id="currentPassword" />
											<div class="invalid-feedback" v-if="errors && errors.length">
												{{ errors[0] }}
											</div>
										</div>
									</ValidationProvider>
									<PasswordConfirmation :model="passwordConfirmationModel"
										@update="validatePassword" />
									<div class="text-center">
										<button type="button" @click="toggleEdit" class="btn btn-outline-primary mr-3">
											{{ $t("myPassword.buttons.cancel") }}
										</button>
										<button type="submit" @click.prevent="confirm" class="btn btn-primary my-3"
											:disabled="invalid">
											{{ $t("myPassword.buttons.submit") }}
										</button>
									</div>
								</form>
							</ValidationObserver>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<ConfirmOverlay>
				<template v-slot:message>
					{{ $t("myPassword.messages.confirmPasswordChangeBody") }}
				</template>

				<template v-slot:buttons>
					<div class="btn-group">
						<button class="btn btn-outline-primary mr-5" @click="close">{{ $t("myPassword.buttons.cancel")
						}}</button>
						<button class="btn btn-outline-primary" @click="submit">{{ $t("myPassword.buttons.submit")
						}}</button>
					</div>
				</template>
			</ConfirmOverlay>
		</div>
	</PageTemplate>
</template>

<script>
import { isMobileOnly } from "mobile-device-detect";
import { mapActions, mapGetters } from "vuex";
import { uiValidator } from "@/nucleus-modules/dd-nucleus-ui/mixins/uiValidator.js";
import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { UPDATE_PASSWORD, VALIDATE_PASSWORD } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service";
import ConfirmOverlay from "@/nucleus-modules/dd-nucleus-admin/components/confirm-overlay";
import PageTemplate from "@/pages/templates/PageTemplate";
import PasswordConfirmation from "@/nucleus-modules/dd-nucleus-ui/components/PasswordConfirmation";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import AccountMenu from "@/components/AccountMenu.vue";
import { MyPasswordMessageIDs } from "@/constants.js";

export default {
	name: "MyPassword",
	components: {
		ConfirmOverlay,
		PageTemplate,
		PasswordConfirmation,
		AccountMenu,

	},
	mixins: [uiValidator],
	computed: {
		...mapGetters({
			userInfo: USER_INFO_GETTER
		})
	},
	data() {
		return {
			currentPassword: "",
			passwordConfirmationModel: {
				password: "",
				confirmPassword: "",
				isValid: false,
				rules: []
			},
			edit: false,
			errorMessage: "",
			showOverlay: false,
			rules: [],
			title: "My Account",
			myPasswordMessageIds: MyPasswordMessageIDs,
		}
	},
	methods: {
		...mapActions({
			updatePassword: UPDATE_PASSWORD,
			validate: VALIDATE_PASSWORD
		}),
		async validatePassword() {
			const response = await this.validate({
				"userName": this.userInfo.userName,
				"email": this.userInfo.userName,
				"password": this.passwordConfirmationModel.password,
				"confirmPassword": this.passwordConfirmationModel.confirmPassword
			});

			this.passwordConfirmationModel.rules = response.messages;
			this.passwordConfirmationModel.isValid = response.isValid;
		},
		async toggleEdit() {
			this.edit = !this.edit;
			this.currentPassword = "";
			this.passwordConfirmationModel.password = "";
			this.passwordConfirmationModel.confirmPassword = "";
			this.passwordConfirmationModel.isValid = false;
			this.passwordConfirmationModel.rules = [];
			if (this.edit) {
				await this.validatePassword();
			} else {
				this.rules = [];
				this.errors = [];
				this.errorMessages = [];
			}
			this.errorMessage = "";
		},
		close() {
			this.showOverlay = false;
		},
		confirm() {
			this.$refs.changePasswordForm.validate().then(async (success) => {
				if (success) {
					// Check to make sure the passwords match
					if (this.passwordConfirmationModel.password !== this.passwordConfirmationModel.confirmPassword) {
						this.errorMessage = this.$t("myPassword.messages.confirmPasswordMatch");
						return;
					}

					// Check to make sure that the password passes validation
					if (!this.passwordConfirmationModel.isValid) {
						this.errorMessage = this.$t("myPassword.messages.confirmPasswordMatchRequirments");
						return;
					}

					if (isMobileOnly) {
						this.showOverlay = true;
					} else {
						const confirmation = new ConfirmationBoxService({
							cancelTitle: this.$t("myPassword.buttons.cancel"),
							cancelVariant: "outline-primary",
							okTitle: this.$t("myPassword.buttons.submit"),
							okVariant: "primary",
							size: "md",
							title: this.$t("myPassword.messages.confirmPasswordChangeTitle"),
						});

						const isConfirmed = await confirmation.showConfirm(this.$t("myPassword.messages.confirmPasswordChangeBody"));
						if (isConfirmed) {
							await this.submit();
						}
					}
				} else {
					// If vee-validate is not valid show an error message.
					this.errorMessage = this.$t("myPassword.correctErrorsTryAgain");
					this.$scrollTo('body', 500, { easing: 'ease-in-out' });
				}
			});
		},
		async submit() {
			const response = await this.updatePassword({
				currentPassword: this.currentPassword,
				newPassword: this.passwordConfirmationModel.password
			});

			if (response.succeeded) {
				// Show a toast that you have created an account and we logged you in
				ToastService.showSuccessToast(this.$t("myPassword.messages.passwordUpdatedSuccessfully"));
				this.toggleEdit();
			} else if (response && !this.$nucleus.isEmpty(response.messages) && response.messages.length > 0) {

				if (response.messages[0].messageId == this.myPasswordMessageIds.UPDATE_PASSWORD_FAILED)
					this.errorMessage = this.$t("myPassword.messages.updatePasswordFailed");
				else
					this.errorMessage = response.messages[0].debugMessage;

				this.$scrollTo('body', 500, { easing: 'ease-in-out' });
			} else {
				// Generic error incase it gets a response without a message ID
				this.errorMessage = this.$t("myPassword.somethingIsWrong");
				this.$scrollTo('body', 500, { easing: 'ease-in-out' });
			}

			this.close();
		}
	},
	async mounted() {
		this.validatePassword = this.$nucleus.debounce(this.validatePassword, 200);
	}
}
</script>

<style lang="scss" scoped>
h1 {
	padding: 30px 0;
}

.mb-135 {
	margin-bottom: 135px;
}

::v-deep .n-password-validation-container {
	position: absolute;
	right: 20px;
	top: 95px;
}
</style>
