<template>
    <PageTemplate :showHeader="true">
        <div class="row">
            <div class="col-12 text-center">
                <h1>
                    Site Map Page
                </h1>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import PageTemplate from "@/pages/templates/PageTemplate";

    export default {
        name: "SiteMap",
        components: {
            PageTemplate
        }
    }
</script>

<style lang="scss" scoped>
    h1 {
        padding: 30px 0;
    }
</style>