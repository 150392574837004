<template>
  <AdminTemplate>
    <div v-if="isLoading" class="row">
      <div class="col">
        <p>Loading...</p>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-6">
          <h4>Users</h4>
        </div>
        <div v-if="hasUsers" class="col-6 text-right">
          Showing {{ paging.pageStartNumber }}-{{ paging.pageEndNumber }} of
          {{ paging.totalCount }}
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-6 col-md-5">
          <router-link :to="{ name: 'AddUser' }"
            ><button class="btn btn-outline-primary btn-sm">Add User</button>
          </router-link>
        </div>
        <div class="col-6 col-md-7 text-right">
          <div class="d-inline mr-3">
            <b-form-select
              class="col-2"
              v-model="paging.pageSize"
              :options="perPageOptions"
              @change="updatePage()"
            ></b-form-select>
            per page
          </div>
          <div class="d-inline">
            <b-pagination
              class="float-right"
              v-model="paging.pageNumber"
              :total-rows="paging.totalCount"
              :per-page="paging.pageSize"
              @change="updatePage"
              aria-controls="usersTable"
            />
          </div>
        </div>
      </div>   
      <div>
        <UserFilters
          :allRoles="allRoles"
          :quickFilters="quickFilters"
          :primaryRoleLabel="primaryRoleLabel"
          @filterUsers="filterUsers"
          @roleSearch="roleSearch"
          @toggleQuickFilter="toggleQuickFilter"
        />
      </div>
      <div class="row">
        <div class="col mt-3" v-if="hasUsers">
          <a @click="getAllUsers">View All Users</a>
        </div>
        <div class="col mt-5 text-center" v-else-if="!hasUsers && !isLoading">
          <h2 class="n-no-users">No users found. Try Modifying your search.</h2>
          <a @click="getAllUsers">View All Users</a>
        </div>
      </div>

      <div class="row" v-if="hasUsers">
        <div class="col-md-12">
          <div class="mt-3">
            <VueGoodTable
              mode="remote"
              class="table table-striped"
              :columns="columns"
              :rows="users"
              :totalRows="paging.totalCount"
              :search-options="searchConfig"
              :sort-options="sortConfig"
              :pagination-options="pagingConfig"
              @on-sort-change="onSortChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'actions'">
                  <div class="d-inline ml-2">
                    <router-link
                      :to="{ name: 'EditUser', params: { id: props.row.id, user: props.row } }"
                    >
                      <font-awesome-icon :icon="['fas', 'pencil']" />
                    </router-link>
                  </div>
                  <div
                    v-if="props.row.canBeImpersonated"
                    class="d-inline ml-2 clickable"
                    @click="impersonate(props.row)"
                  >
                    <font-awesome-icon :icon="['fas', 'user-friends']" />
                  </div>
                </span>
                <span v-else-if="props.column.field === 'userName'">
                  <router-link
                    :to="{ name: 'ViewUser', params: { id: props.row.id, user: props.row } }"
                    >{{ props.row.userName }}</router-link
                  >
                  <UserBadges
                    :roles="props.row.roles"
                    :showActive="
                      props.row.status === statusCodes.ACTIVE ||
                      props.row.status === statusCodes.LOCKED
                        ? true
                        : false
                    "
                    class="mb-2"
                  ></UserBadges>
                </span>
                <span v-else-if="props.column.field === 'created'">
                  <span>{{ props.row.created | moment(dateFormat) }}</span>
                  <br />
                  <span>{{ props.row.created | moment(timeFormat) }}</span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </VueGoodTable>
          </div>
        </div>
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import {
  GET_ALL_ROLES,
  SEARCH_USERS,
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
  ALL_ROLES_GETTER,
  USER_SEARCH_RESULTS_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import UserBadges from "@/nucleus-modules/dd-nucleus-admin/components/user/UserBadges.vue";
import UserFilters from "@/nucleus-modules/dd-nucleus-admin/components/user/UserFilters";
import ImpersonateService from "@/nucleus-modules/dd-nucleus-admin/services/impersonate.service.js";

import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import { REGENERON_GET_USERS } from "@/store/admin/actions.type.js";

import AdminTemplate from "@/pages/templates/AdminTemplate.vue";

export default {
  name: "AllUsers",
  components: {
    VueGoodTable,
    UserBadges,
    UserFilters,
    AdminTemplate,
  },
  data() {
    return {
      areAllUsersSelected: false,
      dateFormat: "MM/DD/YYYY",
      isBusy: false,
      isLoading: true,
      pagingConfig: {},
      quickFiltersSelected: [],
      searchConfig: {},
      selectedUsers: [],
      selectedRoles: [],
      searchTerm: "",
      sortConfig: {},
      statusCodes: StatusCodes,
      timeFormat: "hh:mm:ss a",
      users: [],
      sortBy: "created",
      sortDesc: true,
      columns: [
        { label: "", field: "actions", sortable: false },
        { label: "User ID", field: "userName", type: "string" },
        { label: "First Name", field: "firstName", type: "string" },
        { label: "Last Name", field: "lastName", type: "string" },
        { label: "Primary Role", field: "primaryRole", type: "string" },
        {
          label: "Title",
          field: "attributes.ExtendedUserAccountProperties.title",
          type: "string",
        },
        {
          label: "Geo ID",
          field: "attributes.ExtendedUserAccountProperties.geoId",
          type: "string",
        },
        {
          label: "Created Date",
          field: "created",
          type: "date",
          formatFn: function (value) {
            return value == null ? "" : value;
          },
        },
      ],
      paging: {
        includeTotal: true,
        pageCount: 0,
        pageNumber: 1,
        pageSize: 200,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
      },
      perPageOptions: [
        { value: 200, text: "200" },
        { value: 400, text: "400" },
        { value: 600, text: "600" },
        { value: 800, text: "800" },
        { value: 1000, text: "1000" },
      ],
      quickFilters: ["all", "active", "inactive", "admin", "storefront"],
    };
  },
  computed: {
    ...mapGetters({
      allRoles: ALL_ROLES_GETTER,
      usersResponse: USER_SEARCH_RESULTS_GETTER,
    }),
    hasUsers() {
      return this.users && this.users.length > 0;
    },
    totalPages() {
      return this.paging.totalCount / this.paging.pageSize + 1;
    },
    totalUsersSelected() {
      return this.selectedUsers.length;
    },
    primaryRoleLabel() {
      if (this.selectedRoles.length == 1) {
        return this.allRoles.find((x) => x.id === this.selectedRoles[0]).name;
      }

      if (this.selectedRoles.length > 1) {
        return this.selectedRoles.length + " Selected";
      }

      return "All";
    },
  },
  methods: {
    ...mapActions({
      getRoles: GET_ALL_ROLES,
      getUsers: REGENERON_GET_USERS,
      searchUsers: SEARCH_USERS,
    }),
    buildRequest() {
      const request = {
        filters: [
          {
            filterId: "user-search-term",
            values: [this.searchTerm],
          },
          {
            filterId: "user-access",
            values: this.quickFiltersSelected,
          },
          {
            filterId: "user-primary-roles",
            values: this.selectedRoles,
          },
        ],
        sort: this.mapSortBy(),
        paging: {
          pageNumber: this.paging.pageNumber - 1,
          pageSize: this.paging.pageSize,
          includeTotalCount: true,
        },
      };

      return request;
    },
    filterUsers(searchTerm) {
      this.searchTerm = searchTerm;
      this.currentPage = 1;
      var request = this.buildRequest();
      this.sendRequest(request);
    },
    async getAllUsers() {
      this.$eventBus.$emit("clearFilters");
      this.paging.pageNumber = 1;
      this.searchTerm = "";
      this.quickFiltersSelected = ["all"];
      this.selectedRoles = [];
      await this.updatePage();
    },
    async impersonate(user) {
      let routeData = this.$router.resolve({ name: "Home" });
      await ImpersonateService.impersonate(user, routeData.href);
    },
    mapSortBy() {
      let sort = this.sortBy.toString();

      switch (sort) {
        case "userName":
          sort = "username";
          break;
        case "firstName":
          sort = "first_name";
          break;
        case "lastName":
          sort = "last_name";
          break;
        case "primaryRole":
          sort = "primary_role";
          break;
        case "attributes.ExtendedUserAccountProperties.geoId":
          sort = "geo_id";
          break;
        case "attributes.ExtendedUserAccountProperties.title":
          sort = "title";
          break;
        case "created":
          sort = "created";
      }

      if (this.sortDesc === true) {
        sort = `${sort}_desc`;
      }

      return sort;
    },
    onSortChange(params) {
      this.sortBy = params[0].field;
      this.sortDesc = params[0].type === "desc" ? true : false;

      const request = this.buildRequest();
      this.sendRequest(request);
    },
    roleSearch(selectedRoles) {
      this.selectedRoles = selectedRoles;
      this.paging.pageNumber = 1;
      const request = this.buildRequest();
      this.sendRequest(request);
    },
    async sendRequest(request) {
      this.isBusy = true;

      if (request == undefined) {
        request = this.buildRequest();
      }

      await this.searchUsers([request, "ui/admin/users/search"]);
      this.users = this.usersResponse.results;

      this.setPaging();
      this.isBusy = false;
    },
    setPaging() {
      this.paging.pageCount = this.usersResponse.count;
      this.paging.pageNumber = this.usersResponse.pageNumber + 1;
      this.paging.pageSize = this.usersResponse.pageSize;
      this.paging.totalCount = this.usersResponse.totalCount;

      this.paging.pageStartNumber =
        this.usersResponse.pageNumber === 0
          ? 1
          : this.usersResponse.pageNumber * this.usersResponse.pageSize + 1;
      this.paging.pageEndNumber =
        Math.floor(this.totalPages) === this.usersResponse.pageNumber + 1
          ? this.usersResponse.totalCount
          : this.usersResponse.pageSize * (this.usersResponse.pageNumber + 1);
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;

      var request = this.buildRequest();
      this.sendRequest(request);
    },
    toggleQuickFilter(filters) {
      this.quickFiltersSelected = filters;
      this.currentPage = 1;

      var request = this.buildRequest();
      this.sendRequest(request);
    },
    updatePage(page) {
      if (page !== undefined) {
        this.paging.pageNumber = page;
      }

      var request = this.buildRequest();
      this.sendRequest(request);
    },
  },
  async mounted() {
    await this.getRoles();
    await this.getAllUsers();

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
a {
  color: $admin-primary-color;
  cursor: pointer;
}

h2.n-no-users {
  color: $gray-3;
}

.dropdown-menu {  
  background: $admin-neutral-lightest; 
  min-width: 22rem !important;
	max-height: 25rem;
	overflow-x: auto;
	background: $admin-white-color !important;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.fa-user-friends {
  color: $primary-color;
}

.fa-user-friends:hover {
  color: $primary-dark-color;
  cursor: pointer;
}

.n-disable-user-friends-icon {
  color: $gray-2;
}

.n-disable-user-friends-icon:hover {
  color: $gray-2;
  cursor: default;
}

.n-selected-filters {
  background: $primary-color;
  color: $admin-white-color;
  font-size: 0.875rem;
  padding: 5px 10px;
}

.n-remove-filters {
  color: $admin-white-color;
}

.n-clear-all {
  padding-top: 5px;
}

hr {
  border-top: 1px solid $gray-2;
}

.n-delete-confirm-overlay {
  background: $primary-color;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100000;
}

.n-delete-confirm-text {
  left: 50%;
  display: block;
  position: fixed;
  top: 40%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.25rem;
}

.thead {
  background: $admin-primary-color;
}
</style>
