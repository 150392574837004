<template>
  <PageTemplate>
    <div class="page-container">
      <div class="row mt-2">
        <div class="col-md-3 d-none d-lg-block">
          <AccountMenu :title="title" menu-id="account"> </AccountMenu>
        </div>
        <div class="col-md-12 col-lg-9 n-crm-address-pagelist-container">
          <CRMAddressBook :isCheckout="isCheckout"></CRMAddressBook>
        </div>
      </div>
    </div>
  </PageTemplate>
</template>

<script>
import PageTemplate from "@/pages/templates/PageTemplate";
import AccountMenu from "@/components/AccountMenu.vue";
import CRMAddressBook from "@/components/crm-addresses/CRMAddressBook.vue";

export default {
  name: "CRMAddresses",
  components: {
    PageTemplate,
    AccountMenu,
    CRMAddressBook,
  },
  data() {
    return {
      isCheckout: false,
      title: "My Account",      
    };
  },
};
</script>
<style scoped>
.page-container {
  width: 100%;
  font-size: 0.85rem;
}
</style>
