<template>
  <div
    v-if="text && text.length > 0"
    class="col-md-12 alert alert-dismissible fade show n-header-promotion"
    role="alert"
  >
    {{ text }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "HeaderPromotion",
  props: {
    text: String,
  },
};
</script>

<style scoped lang="scss">
.alert {
  background-color: $tertiary-color;
  border: 0px;
  border-radius: 0px;
  color: #fff;
  padding-left: 30px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0px;
}

@media (max-width: 576px) {
  .alert {
    margin-bottom: 4px;
  }
}
@media (max-width: 768px) {
  .alert {
    margin-bottom: 4px;
  }
  .alert-dismissible .close {
    right: 3;
  }
}
</style>
