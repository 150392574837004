<template>
  <div class="card h-100 n-hide-border">
    <div class="card-body n-hide-body">
      <AddressDetails :address="addressList"></AddressDetails>
      <div v-if="addressList.isDefault" class="d-flex n-warning font-weight-bold">
        <div class="flex-shrink-1 pr-1">
          <font-awesome-icon mr-2 class="n-warning" :icon="['fas', 'star']" />
        </div>
        <div class="w-100">
          <span>Default Shipping Address</span>
        </div>
      </div>
    </div>

    <div class="card-footer mt-2">
      <div v-if="showFavorite" class="col-3 float-left p-0">
        <FavoriteAddressIcon :id="addressList.id" :isFavorite="addressList.isFavorite" :showText="false"
          :showIconToAuthUsersOnly="true" />
      </div>

      <button v-if="isCheckout && selectedAddressId !== addressList.id" @click="useThisAddress(addressList)"
        class="btn btn-sm btn-outline-primary float-right p-1 select-icon">{{ selectText }}</button>
      <button v-if="selectedAddressId == addressList.id && isCheckout" class="btn btn-sm b float-right p-0">
        <font-awesome-icon class="n-secondary-color" :icon="['fa', 'check']" />
      </button>
    </div>
  </div>
</template>

<script>
import AddressDetails from "@/nucleus-modules/dd-nucleus-ui/components/address-book/AddressDetails.vue";
import FavoriteAddressIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteAddressIcon.vue";
export default {
  name: "CRMAddressTile",
  components: {
    AddressDetails,
    FavoriteAddressIcon,
  },
  props: {
    addressList: {
      type: Object,
      required: true,
    },
    isCheckout: {
      type: Boolean,
      required: false,
    },
    selectedAddressId: {
      type: String,
      required: false,
    },
    showFavorite: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectText: {
      type: String,
      required: false,
      default: "Select"
    }
  },
  methods: {
    isThisSelected(address) {
      return address.id != this.selectedAddressId;
    },
    useThisAddress(address) {
      this.$emit("selected", {
        address: address,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-subtitle {
  line-height: 1.8;
}

.card {
  border-radius: 0.5rem;
  border: 1px solid $neutral-mid-light;
}

.card-body {
  padding: 0.75rem 1.25rem;
}

.select-icon {
  font-size: 1rem;
  border-radius: 0.5rem;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  border-top: none;
  background: none;
}
</style>
