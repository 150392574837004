<template>
  <div class="">
    <div class="d-lg-none n-view-search-results d-flex">
        <div class="flex-fill text-left n-total-results mt-2">{{totalResultsCount}} results</div>
        <div class="flex-fill text-right">
          <div class="clear-all" v-if="showClearAll" @click="removeAll">
            <span class="mt-2">
            Clear All
            </span>
          </div>
        </div>
    </div>
    <div class="n-filter-tags d-flex" >
      <div class="">
      <template v-for="filterTag in displayTags">
        <template v-if="hasFilterTag(filterTag)">
          <template v-for="tag in filterTag.values">
          <div
            v-if="!$nucleus.isEmpty(tag.id) && tag.show"
            :key="tag.id"
            class="n-outer-tag"
          >
            <div class="tag">
              {{ tag.name }}
              <span
                class="close-tag"
                @click="
                  removeFilter(tag.id, filterTag)
                "
                >&times;</span
              >
            </div>
          </div>
          </template>
        </template>
      </template>
      </div>
      <div class="">
        <div class="clear-all d-none d-lg-block" v-if="showClearAll" @click="removeAll">
          <span class="mt-2">
          Clear All
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterUtilities } from "@/nucleus-modules/dd-nucleus-ui/mixins/FilterUtilities.js";
import { FILTER_TAGS_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

export default {
  name: "TagFilter",
  mixins: [FilterUtilities],
  props: {
    totalResultsCount: {
      required: false,
      type: Number      
    }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      filterTags: [FILTER_TAGS_GETTER],
    }),
    displayTags() {
      return this.filterTags;
    },
    showClearAll() {
      let alltags = this.filterTags;
      let vm = this;
      for (var i in alltags) {
        if (alltags[i] 
            && alltags[i].values 
            && alltags[i].values.filter(f => !vm.$nucleus.isEmpty(f.id) && f.show).length) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    removeFilter(tagId, filterTag ) {
      this.$eventBus.$emit(UiEvents.FILTER_TAG_REMOVED, {
        filterId : filterTag.id,
        values: filterTag.values.map(f => {
            return tagId==f.id ? "": f.show ? f.id: ""
        })
      });
    },
    removeAll() {
       this.$eventBus.$emit(UiEvents.ALL_FILTER_TAGS_REMOVED);
    },
    hasFilterTag(ft) {
      let vm = this;
      return !vm.$nucleus.isEmpty(ft) 
          && !vm.$nucleus.isEmpty(ft.values)
          && ft.values.filter(f => !vm.$nucleus.isEmpty(f.id)).length
    }, 
  },
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding-left: 4px;
  font-size: 0.85rem;
  white-space: nowrap;
  font-weight:700;
}
.n-outer-tag {
  display: inline-block;
  border-radius: 0.25rem;
  margin: 0px 10px 10px 0px;
  border: solid 1px #ffffff;
  padding: 6px 10px 8px 10px;
  color: #ffff;
  background-color: $filter-tag-color;
}
.n-filter-tags {
  display: block;
  width: 100%;
}

.close-tag {
  cursor: pointer;
  padding-left: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

@media (max-width: 576px) {
  .close-tag {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .close-tag {
    font-size: 14px;
  }
}
.n-total-results {
  font-size: 0.85rem;
  color: $gray-3;
  line-height: 1.5;
}
</style>
