import { render, staticRenderFns } from "./OrderHistoryPanel.vue?vue&type=template&id=58134410&scoped=true&"
import script from "./OrderHistoryPanel.vue?vue&type=script&lang=js&"
export * from "./OrderHistoryPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58134410",
  null
  
)

export default component.exports