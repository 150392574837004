<template>
  <div>
    <div v-if="totalProducts > 0">
      <div class="row">
        <div class="col">
          <h2>Review Bulk Order</h2>
        </div>
        <div class="col d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-primary mr-2"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!canSubmitBulkOrder"
            @click="submitBulkOrder()"
          >
            Place Order
          </button>
        </div>
      </div>
      <hr />
      <div class="row mt-3">
        <div class="col d-inline-flex">
          <h3 class="p-2">Selected Product(s)</h3>
          <strong class="p-2">({{ totalProducts }})</strong>
          <a class="p-2" href="#" @click="backToSelectProducts()"
            ><font-awesome-icon :icon="['fas', 'pencil']"
          /></a>
        </div>
      </div>
      <div v-if="hasProductAvailabilityErrors">
        <b-alert
          v-for="error in productAvailabilityErrors"
          :key="error.productId"
          v-model="error.showAlert"
          variant="danger"
        >
          {{ error.message }}
        </b-alert>
      </div>
      <div class="row mt-3">
        <div class="col">
          <VueGoodTable
            class="table table-striped"
            :rows="products"
            :columns="productColumns"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.type === 'date'">
                {{
                  props.formattedRow[props.column.field] | moment(dateFormat)
                }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </VueGoodTable>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col d-inline-flex">
          <h3 class="p-2">Ship Method</h3>
          <a class="p-2" href="#" @click="backToShipMethod()">
            <font-awesome-icon :icon="['fas', 'pencil']" />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="p-2">{{ selectedShippingMethod }}</label>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col d-inline-flex align-items-baseline">
          <h3 class="p-2">Internal Notes</h3>
          <span class="p-2 small">(optional)</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <textarea
            v-model="internalNotes"
            class="form-control"
            rows="3"
          ></textarea>
        </div>
      </div>
      <hr />
      <div class="row mt-3">
        <div class="col d-flex justify-content-end">
          Showing {{ pageStartNumber }} - {{ pageEndNumber }} of
          {{ totalRecipients }}
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-inline-flex">
          <h3 class="p-2">Recipient List</h3>
          <a class="p-2" href="#" @click="backToRecipients()">
            <font-awesome-icon :icon="['fas', 'pencil']" />
          </a>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-end align-items-baseline">
            <VueExcelExport
              class="mr-2 n-action-button"
              :data="selectedRecipientsForExport"
              :columns="excelColumns"
              :file-name="'recipients'"
              :file-type="'xlsx'"
              :sheet-name="'Recipients'"
            >
              <font-awesome-icon
                :icon="['fa', 'file-excel']"
                class="fa-2x n-excel-export-button"
              ></font-awesome-icon>
            </VueExcelExport>
            <VueJsonToCsv
              class="mr-2 n-action-button"
              csv-title="recipients"
              :json-data="selectedRecipientsForExport"
              :labels="{
                userName: { title: 'User ID' },
                companyName: { title: 'Business Name' },
                firstName: { title: 'First Name' },
                lastName: { title: 'Last Name' },
                title: { title: 'Title' },
                primaryUserRoleName: { title: 'Primary Role' },
                addressTypeName: { title: 'Address Type' },
                addressee: { title: 'Addressee' },
                addressLine1: { title: 'Address 1' },
                addressLine2: { title: 'Address 2' },
                city: { title: 'City' },
                state: { title: 'State' },
                postalCode: { title: 'Postal Code' },
                geoId: { title: 'GEO ID' },
                territoryName: { title: 'Territory Name' },
              }"
            >
              <font-awesome-icon
                :icon="['fa', 'file-csv']"
                class="fa-2x n-csv-export-button"
              ></font-awesome-icon>
            </VueJsonToCsv>
            <div class="mr-2">
              <b-form-select
                v-model="paging.pageSize"
                :options="paging.perPageOptions"
              ></b-form-select>
            </div>
            <span class="small mr-2">per page</span>
            <div>
              <b-pagination
                v-model="pagerPageNumber"
                :total-rows="totalRecipients"
                :per-page="paging.pageSize"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <VueGoodTable
            class="table table-striped"
            :rows="recipients"
            :columns="reviewColumns"
          >
            <div slot="emptystate">
              <p>There are no recipients selected.</p>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'userName'">
                <div class="row">
                  <div class="col">
                    {{ props.formattedRow[props.column.field] }}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <UserBadges
                      :roles="props.row.roles"
                      :showActive="
                        props.row.status === statusCodes.ACTIVE ||
                        props.row.status === statusCodes.LOCKED
                          ? true
                          : false
                      "
                      class="mb-2"
                    ></UserBadges>
                  </div>
                </div>
              </span>
            </template>
          </VueGoodTable>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-primary mr-2"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!canSubmitBulkOrder"
            @click="submitBulkOrder()"
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <b-alert show variant="danger">
        <p>
          Please choose a list of recipients, product(s) and a shipping method
          to create the bulk order.
        </p>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueExcelExport from "@/nucleus-modules/dd-nucleus-ui/components/VueExcelExport.vue";
import VueJsonToCsv from "vue-json-to-csv";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";
import { PagingMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/PagingMixin.js";

import UserBadges from "@/nucleus-modules/dd-nucleus-admin/components/user/UserBadges.vue";
import { AddressType, StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";

export default {
  name: "ReviewBulkOrder",
  mixins: [BulkOrdersMixin, PagingMixin],
  components: {
    VueGoodTable,
    VueExcelExport,
    VueJsonToCsv,
    UserBadges,
  },
  data() {
    return {
      AddressType: AddressType,
      dateFormat: "MM/DD/YYYY",
      internalNotes: null,
      pagerPageNumber: 1,
      statusCodes: StatusCodes,
      excelColumns: [
        { label: "User ID", field: "userName" },
        { label: "Business Name", field: "companyName" },
        { label: "First Name", field: "firstName" },
        { label: "Last Name", field: "lastName" },
        { label: "Title", field: "title" },
        { label: "Primary Role", field: "primaryUserRoleName" },
        { label: "Address Type", field: "addressTypeName" },
        { label: "Addressee", field: "addressee" },
        { label: "Address 1", field: "addressLine1" },
        { label: "Address 2", field: "addressLine2" },
        { label: "City", field: "city" },
        { label: "State", field: "state" },
        { label: "Postal Code", field: "postalCode", type: "string"},
        { label: "Geo Id", field: "geoId" },
        { label: "Territory Name", field: "territoryName" },
      ],
      paging: {
        includeTotalCount: true,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 200,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
        perPageOptions: [
          { value: 200, text: "200" },
          { value: 400, text: "400" },
          { value: 600, text: "600" },
          { value: 800, text: "800" },
          { value: 1000, text: "1000" },
        ],
      },
      productColumns: [
        {
          field: "itemNumber",
          label: "Item Number",
          sortable: false,
          type: "string",
        },
        {
          field: "name",
          label: "Product Name",
          sortable: false,
          type: "string",
        },
        {
          field: "dateOfFirstUse",
          label: "Date of First Use",
          sortable: false,
          type: "date",
          formatFn: function (value) {
            return value == null ? "" : value;
          },
        },
        {
          field: "unitOfMeasure",
          label: "Unit of Measure",
          sortable: false,
          type: "string",
        },
        {
          field: "quantityPerRecipient",
          label: "Quantity per Recipient",
          sortable: false,
          type: "number",
        },
        {
          field: "quantityTotal",
          label: "Quantity Total",
          sortable: false,
          type: "number",
        },
      ],
      reviewColumns: [
        {
          field: "userName",
          label: "User ID",
          sortable: true,
          width: '250px'
        },
        {
          field: "companyName",
          label: "Business Name",
          sortable: true,
          width: '200px'
        },
        {
          field: "firstName",
          label: "First Name",
          sortable: true,
          width: '150px'
        },
        {
          field: "lastName",
          label: "Last Name",
          sortable: true,
          width: '150px'
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          width: '180px'
        },
        {
          field: "primaryUserRoleName",
          label: "Primary Role",
          sortable: true,
          width: '230px'
        },
        {
          field: "addressTypeName",
          label: "Address Type",
          sortable: true,
          width: '130px'
        },
        { 
          field: "addressee", 
          label: "Addressee", 
          sortable: true, 
          width: '200px'  
        },
        {
          field: "addressLine1",
          label: "Address 1",
          sortable: true,
          width: '220px'
        },
        {
          field: "addressLine2",
          label: "Address 2",
          sortable: true,
          width: '150px'
        },
        {
          field: "city",
          label: "City",
          sortable: true,
          width: '230px'
        },
        {
          field: "state",
          label: "State",
          sortable: true,
          width: '100px'
        },
        {
          field: "postalCode",
          label: "Postal Code",
          sortable: true,
           width: '150px'
        },
        {
          field: "countryCode",
          label: "Country",
          sortable: true,
          hidden:true
        },
        {
          field: "geoId",
          label: "Geo ID",
          sortable: true,
          width: '150px'
        },
        {
          field: "territoryName",
          label: "Territory",
          sortable: true,
           width: '180px' 
        },
      ],
    };
  },
  computed: {
    canSubmitBulkOrder() {
      if (this.$nucleus.isEmpty(this.bulkOrderDetail)) {
        return false;
      } else if (this.$nucleus.isEmpty(this.bulkOrderDetail.products)) {
        return false;
      } else if (this.$nucleus.isEmpty(this.bulkOrderDetail.shippingMethod)) {
        return false;
      } else if (this.$nucleus.isEmpty(this.bulkOrderDetail.users)) {
        return false;
      }

      return true;
    },
    hasProductAvailabilityErrors() {
      return !this.$nucleus.isEmpty(this.productAvailabilityErrors);
    },
    pageStartNumber() {
      return this.pagerPageNumber - 1 === 0
        ? 1
        : (this.pagerPageNumber - 1) * this.paging.pageSize + 1;
    },
    pageEndNumber() {
      const totalPages = this.totalRecipients / this.paging.pageSize + 1;

      return Math.floor(totalPages) === this.pagerPageNumber
        ? this.totalRecipients
        : this.paging.pageSize * this.pagerPageNumber;
    },
    productAvailabilityErrors() {
      if (
        this.$nucleus.isEmpty(this.placeBulkOrderResponse) ||
        this.$nucleus.isEmpty(this.placeBulkOrderResponse.productAvailability)
      ) {
        return [];
      }

      const errors = this.placeBulkOrderResponse.productAvailability.filter(
        (pa) => pa.quantityStillNeeded > 0
      );

      const errorMessages = [];

      if (errors) {
        for (let i = 0; i < errors.length; i++) {
          const requested = this.$options.filters.number(
            errors[i].quantityRequested
          );
          const available = this.$options.filters.number(
            errors[i].quantityAvailable
          );
          const itemNumber = errors[i].productItemNumber;

          errorMessages.push({
            productId: errors[i].productId,
            message: `The requested quantity (${requested}) for ${itemNumber} exceeds the available quantity ${available}.`,
            showAlert: true,
          });
        }
      }

      return errorMessages;
    },
    products() {
      return this.bulkOrderDetail.products;
    },
    recipients() {
      if (!this.selectedRecipients) return [];

      const result = [];
      this.selectedRecipients.forEach((sr) => {
        if (sr.addressType === AddressType.BILLING) {
          sr.addressTypeName = "Primary";
        }
        result.push(sr);
      });

      return result;
    },
    selectedShippingMethod() {
      return this.bulkOrderDetail.shippingMethod.name;
    },
    totalProducts() {
      return this.products.length;
    },
    totalRecipients() {
      return this.recipients.length;
    },
  },
  methods: {
    backToRecipients() {
      this.$emit("nav-to-recipients");
    },
    backToSelectProducts() {
      this.$emit("nav-to-products");
    },
    backToShipMethod() {
      this.$emit("nav-to-ship-method");
    },
    async cancel() {
      const confirm = new ConfirmationBoxService({
        title: "Cancel Bulk Order",
        cancelTitle: "No",
        cancelVariant: "primary",
        okTitle: "Yes",
        okVariant: "danger",
        size: "md",
      });

      const confirmed = await confirm.showConfirm(
        "Are you sure you want to abandon this bulk order request?"
      );

      if (confirmed) {
        this.$emit("cancel-bulk-order");
      }
    },
    async submitBulkOrder() {
      const confirm = new ConfirmationBoxService({
        title: "Create Bulk Order",
        cancelTitle: "No",
        cancelVariant: "outline-primary",
        okTitle: "Place Order",
        okVariant: "primary",
        size: "md",
      });

      const confirmed = await confirm.showConfirm(
        `Are you sure you want to place an order for ${this.totalRecipients} recipients?`
      );

      if (confirmed) {
        await this.placeBulkOrder(this.internalNotes);
        this.$scrollTo("body", 500, { easing: "ease-in-out" });

        if (this.placeBulkOrderResponse.succeeded) {
          ToastService.showSuccessToast(
            `Bulk Order ${this.placeBulkOrderResponse.bulkOrderNumber} was placed successfully.`
          );

          await this.getBulkOrderDetail({
            id: this.placeBulkOrderResponse.bulkOrderId,
          });

          this.$emit("show-confirmation", {
            showBackButton: true,
            showBorder: false,
            showClose: false,
          });
        } else {
          if (
            !this.$nucleus.isEmpty(
              this.placeBulkOrderResponse.productAvailability
            )
          ) {
            ToastService.showErrorToast("The bulk order could not be placed.");
          } else {
            let errorMessage;

            if (!this.$nucleus.isEmpty(this.placeBulkOrderResponse.messages)) {
              errorMessage = this.placeBulkOrderResponse.messages[0];
            } else {
              errorMessage =
                "An unexpected error has occurred, the bulk order was not placed.";
            }

            ToastService.showErrorToast(errorMessage);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
