<template>
  <div>
    <h4 class="mb-3">Images</h4>
    <div class="row justify-content-center">
      <div class="col-8">
        <ImageDragDrop :imageConfig="imageConfig"></ImageDragDrop>
      </div>
    </div>
    <AssociatedMedia></AssociatedMedia>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET_PRODUCT_MEDIA } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import ImageDragDrop from "@/components/admin/product/edit-product/ImageDragDrop.vue";
import AssociatedMedia from "@/components/admin/product/edit-product/AssociatedMedia.vue";

export default {
  name: "ProductMedia",
  components: {
    ImageDragDrop,
    AssociatedMedia,
  },
  props: {
    imageConfig: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      getProductMedia: GET_PRODUCT_MEDIA,
    }),
  },
};
</script>

<style lang="scss"></style>
