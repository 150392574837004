import Vue from "vue";
import Vuex from "vuex";

import systemModule from "@/nucleus-modules/dd-nucleus-ui/store/system.module.js";
import errorModule from "@/nucleus-modules/dd-nucleus-ui/store/error.module.js";
import authModule from "@/nucleus-modules/dd-nucleus-ui/store/auth.module.js";
import filteringModule from "@/nucleus-modules/dd-nucleus-ui/store/filtering.module.js";
import cartModule from "@/nucleus-modules/dd-nucleus-storefront/store/cart.module.js";
import categoryModule from "@/nucleus-modules/dd-nucleus-storefront/store/category.module.js";
import helpModule from "@/nucleus-modules/dd-nucleus-storefront/store/help.module.js";
import omniSearchModule from "@/nucleus-modules/dd-nucleus-storefront/store/omni-search.module.js";
import ordersModule from "@/nucleus-modules/dd-nucleus-storefront/store/orders.module.js";
import productModule from "@/nucleus-modules/dd-nucleus-storefront/store/product.module.js";
import storeModule from "@/nucleus-modules/dd-nucleus-storefront/store/storefront.module.js";
import adminModule from "@/nucleus-modules/dd-nucleus-admin/store/admin.module.js";

// Client specific storefront modules
import addressModule from "@/store/storefront/address.module.js";
import storefrontModule from '@/store/storefront/storefront.module.js';
import regeneronAdminModule from "@/store/admin/regeneron-admin.module";
import crmAddressModule from "@/store/storefront/crm-address.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        systemModule,
        errorModule,
        authModule,
        filteringModule,
        addressModule,
        cartModule,
        categoryModule,
        helpModule,
        omniSearchModule,
        ordersModule,
        productModule,
        storeModule,
        adminModule,
        storefrontModule,
        regeneronAdminModule,
        crmAddressModule
    }
});
