<template>
  <div class="n-product-tile-grid">
    <div class="row">
      <div
        v-show="isLoading"
        v-for="x in 6"
        :key="x"
        class="skeleton-container col-sm-3"
      >
        <Skeleton width="227px" height="207px"></Skeleton>
        <Skeleton v-for="y in 6" :key="y" :min-width="200"></Skeleton>
      </div>
      <div
        v-show="!isLoading"
        v-for="product in products"
        :key="product.id"
        :class="`col-sm-${columnWidth}`"
      >
        <div class="row">
          <div class="col-12 text-center">
            <router-link
              class="n-product-name"
              :id="product.id"
              :title="product.name"
              :to="`/product-detail/${product.id}`"
            >
              <!-- <img v-if="product.thumbnailImageUrl" :src="product.thumbnailImageUrl" class="img-fluid n-product-thumb" />
                            <img v-else src="/images/no-image-available-th.png" class="img-fluid n-product-thumb" />
                             -->
              <img
                :src="getImageUrl(product.thumbnailImageUrl)"
                class="img-fluid n-product-thumb"
                :alt="product.name"
              />
            </router-link>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-9">
            <div
              class="badges"
              v-for="badge in product.tags"
              :key="badge.id"
            >
              <span
                class="badge badge-primary"
                >{{ badge.name }}</span
              >
            </div>
          </div>
          <div class="col-3 text-right">
            <FavoriteProductIcon
              :id="product.id"
              :isFavorite="product.isFavorite"
              :showText="false"
              :showIconToAuthUsersOnly="true"
              addFavoriteTextId="shared.favoriteIcon.addText"
              removeFavoriteTextId="shared.favoriteIcon.removeText"
              />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h2 class="n-title-text" :title="product.name">
              <router-link
                :id="product.id"
                :title="product.name"
                :to="`/product-detail/${product.id}`"
                class="n-product-name"
              >
                <VClamp autoresize :max-lines="1" ellipsis="">
                  {{ product.name }}
                  <template #after="{ toggle, expanded, clamped }">
                    <button
                      v-if="clamped"
                      class="n-read-more btn"
                      @click.prevent="toggle"
                    >
                      <font-awesome-icon icon="chevron-down" />
                    </button>
                    <button
                      v-if="expanded"
                      class="n-read-more btn"
                      @click.prevent="toggle"
                    >
                      <font-awesome-icon icon="chevron-up" />
                    </button>
                  </template>
                </VClamp>
              </router-link>
            </h2>
            <h4 class="n-product-sku">
              <router-link
                :id="product.id"
                :title="product.name"
                :to="`/product-detail/${product.id}`"
                class="n-product-sku"
                >{{ product.itemNumber }}</router-link
              >
            </h4>            
          </div>
        </div>
        <template v-if="storeSetup.ratingsEnabled">
          <div v-if="product.rating !== null" class="row">
            <div class="col-6">
              <star-rating
                v-model="product.rating.value"
                v-bind:increment="0.5"
                v-bind:max-rating="5"
                v-bind:show-rating="false"
                v-bind:star-size="15"
                v-bind:border-width="2"
                v-bind:padding="3"
                inactive-color="#fff"
                active-color="#ffb500"
                border-color="#ba9143"
              >
              </star-rating>
            </div>
            <div class="col-6">
              <div class="n-total-ratings">
                <router-link
                  :id="product.id"
                  :title="product.name"
                  :to="`/product-detail/${product.id}`"
                  >{{ product.rating.totalRatings }} Ratings</router-link
                >
              </div>
            </div>
          </div>
          <div v-if="storeSetup.ratingsEnabled && product.rating === null">
            <div class="col-12 n-total-ratings">
              <router-link
                :id="product.id"
                :title="product.name"
                :to="`/product-detail/${product.id}`"
                >Add the first rating</router-link
              >
            </div>
          </div>
        </template>
        <div class="row">
            <div class="col-12">
                <template v-if="storeSetup.isPricingEnabled">
                    <div v-if="product.isOnSale" class="n-price-grid-view">
                        {{ product.salePrice | currency }}
                        <span class="n-price-on-sale">
                            {{ $t("shared.pricing.salePriceWas") }}
                            {{ product.unitPrice | currency }}
                        </span>
                    </div>
                    <div v-else class="n-price">
                        {{ product.unitPrice | currency }}
                    </div>
                </template>
                <template v-if="product.messages && product.messages.length">
                  <div class="n-message-list">
                    <MessageList :messages="product.messages" />
                  </div>
                </template>
            </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form class="form-inline">
              <div
                v-if="product.canAddToCart && product.attributes.Inventory.quantityAvailable > 0"
                class="form-group n-in-stock"
              >
                <div class="row">
                  <div class="col-sm-6 pr-1">
                    <QuantityInput
                     :item="product"
                     :min="1"
                     :eventName="UiEvents.QUANTITY_INPUT_CHANGED"
                     @quantityChanged="quantityChanged">
                     </QuantityInput>
                  </div>
                  <div class="col-sm-6 pl-1" v-if="isUnitOfMeasureAvailable(product)">
                    <label>
                      <Uom
                        :uom="getInventory(product).unitOfMeasure"
                        :unitQuantity="getInventory(product).quantityPerUnit"
                      ></Uom>
                    </label>
                  </div>
                </div>
              </div>
              <div v-else class="n-inventory-message n-out-of-stock">
                {{ storeSetup.constants.defaultOutOfStockMessageText }}
              </div>
              <button
                v-if="product.canAddToCart && product.attributes.Inventory.quantityAvailable > 0"
                :id="`button-${product.id}`" :disabled="!(product.quantity > 0)"
                @click.prevent="addItemToCart(product)"
                class="btn btn-primary btn-block"
              >
                <span
                  v-if="
                    buttonLoading &&
                      buttonLoading.length &&
                      buttonLoading[index]
                  "
                >
                  <b-spinner small></b-spinner>
                  Loading...
                </span>
                <span v-else>
                  Add to Cart
                </span>
              </button>
            </form>
            <div
              v-if="product.isPreviewable && product.isDownloadable"
              class="row"
            >
              <div class="col-6 pr-1">
                <button
                  v-if="product.isPreviewable"
                  class="btn btn-outline-primary btn-block"
                >
                  Preview
                </button>
              </div>
              <div class="col-6 pl-1">
                <button
                  v-if="product.isDownloadable"
                  class="btn btn-outline-primary btn-block"
                >
                  Download
                </button>
              </div>
            </div>
            <div v-else>
              <button
                v-if="product.isPreviewable"
                class="btn btn-outline-primary btn-block"
              >
                Preview
              </button>
              <button
                v-if="product.isDownloadable"
                class="btn btn-outline-primary btn-block"
              >
                Download
              </button>
            </div>
            <div class="n-product-row"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import Skeleton from "@/nucleus-modules/dd-nucleus-ui/components/Skeleton.vue";
import QuantityInput from "@/nucleus-modules/dd-nucleus-ui/components/QuantityInput.vue";
import Uom from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";
import { FavoriteIconType } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import MessageList from '@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue';
import FavoriteProductIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteProductIcon.vue";
import {SITE_SETUP_GETTER} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { mapGetters } from 'vuex';
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { productMixins } from "@/mixins/productMixin.js";


export default {
  name: "ProductTileGridMode",
  mixins:[productMixins],
  components: {
    Skeleton,
    FavoriteProductIcon,
    MessageList,
    QuantityInput,
    Uom,
    VClamp,
  },
  props: {
    products: {
      type: Array,
    },
    columnWidth: {
      type: Number,
    },
    buttonLoading: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    addItemToCart: {
      type: Function,
    },
    noImageAvailableUrl: {
      type: String,
      required: false,
    }
  },
  data(){
    return {
      UiEvents: Object.freeze(UiEvents),
    }
  },
  computed: {
    ...mapGetters({
      storeSetup:[SITE_SETUP_GETTER]
    }),
    favoriteIconType() {
      return FavoriteIconType;
    }
  },
  methods: {
    getImageUrl(url) {
      if (url) return url;
      return this.noImageAvailableUrl;
    },
    quantityChanged() {
        this.$emit("quantityChanged");
    }
  },
};
</script>

<style lang="scss" scoped>
.popover-body {
  color: #fff;
  background: $red;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  border-bottom-color: $red;
}

.badges {
  margin-right: 5px;
  display: inline;
}

.form-inline .form-group {
  margin-bottom: 10px;
}

.form-group {
  margin-top: 12px;
}

.form-control {
  width: 100%;
}

.form-inline label {
  justify-content: left;
  padding-top: 6px;
}

.btn {
  margin-bottom: 10px;
}

.img-fluid {
  background-color: $gray-2;
}

.n-product-row {
  padding-top: 28px;
  padding-bottom: 28px;
}

.n-total-ratings {
  padding-top: 4px;
}

.btn-outline-primary {
  padding-left: unset;
  padding-right: unset;
  text-align: center;
}

.n-product-thumb {
  margin-top: 10px;
  max-height: 250px;
}

.n-in-stock {
  height: 45px;
}

.n-out-of-stock {
  height: 66px;
  padding-top: 18px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.n-favorite {
  margin-right: 24px;
  margin-bottom: 4px;
}

.skeleton-container {
  margin: 0 25px 25px 25px;
  text-align: left;
}

.n-product-tile-grid .n-product-name{
  font-size: 1rem;
}

.n-product-tile-grid .n-read-more{
  right: 16px;
}

.n-product-tile-grid .n-title-text{
  width: 90%;
}

</style>