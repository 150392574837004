<template>
  <div>
    <div class="row mt-3 ml-0 mr-0">
      <div class="col-md-4 pt-md-0 float-left pr-0" v-if="totalNumberOfAddresses > 0">
        <h4 class="d-inline float-left n-search-results-title">
          {{ resultsTitle }}
        </h4>
        <span class="d-md-inline d-block mb-2 n-search-results-pagination" v-if="showPagination">
          ({{ paginationStart }}-{{ paginationEnd }} of
          {{ totalNumberOfAddresses }} results)</span>
      </div>
      <div class="col-md-8 pt-md-0 pt-0 pt-sm-2 n-crm-address-container" v-if="totalNumberOfAddresses > 0">
        <div style="flex:auto;"></div>
        <CRMAddressPerPageList class="n-page-list ml-md-2 ml-sm-0" :perPage="perPage" :perPageOptions="perPageOptions"
          @changed="perPageChanged($event)"></CRMAddressPerPageList>  
        <CRMAddressPagingControls class="n-crm-address-paging-control" :currentPageNumber="currentPage" :totalResults="totalNumberOfAddresses"
          :perPage="perPage" @changed="pageNumberChanged($event)"></CRMAddressPagingControls>
      </div>
    </div>

    <div id="addressRecords" class="row modal-body" v-if="totalNumberOfAddresses > 0">
      <div class="col-lg-4 col-md-6 col-12 mb-4" v-for="address in crmAddresses.items" :key="address.id">
        <CRMAddressTile :addressList="address" :isDefault="address.setAsDefault" :isCheckout="isCheckout"
          :selectedAddressId="selectedAddressId" @selected="addressSelected"></CRMAddressTile>
      </div>
    </div>

    <div v-if="totalNumberOfAddresses > 0" id="addressBookBottomPagination" class="row pt-4">
      <div class="col text-right">
        <CRMAddressPagingControls class="col-md-12" :currentPageNumber="currentPage" :totalResults="totalNumberOfAddresses"
          :perPage="perPage" @changed="pageNumberChanged($event)"></CRMAddressPagingControls>
      </div>
    </div>
  </div>
</template>
<script>
import CRMAddressPerPageList from "@/nucleus-modules/dd-nucleus-ui/components/address-book/PerPageList.vue";
import CRMAddressPagingControls from "@/nucleus-modules/dd-nucleus-ui/components/address-book/PagingControls.vue";
import { mapGetters, mapActions } from "vuex";
import CRMAddressTile from "@/nucleus-modules/dd-nucleus-storefront/components/AddressTile.vue";
import { SHIP_ADDRESS_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
  CRM_ADDRESS_PER_PAGE_CONFIG_GETTER,
  CRM_ADDRESS_SEARCH_RESULTS_GETTER,
} from "@/store/storefront/getters.type.js";
import { UPDATE_SHIP_ADDRESS } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

export default {
  name: "CRMAddressBookSearchResults",
  components: {
    CRMAddressPerPageList,
    CRMAddressPagingControls,
    CRMAddressTile,
  },
  props: {
    isCheckout: {
      type: Boolean,
      default: false,
    },
    searchOptions: {
      type: Object,
      required: true,
    },
    resultsTitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      perPageConfig: [CRM_ADDRESS_PER_PAGE_CONFIG_GETTER],
      crmAddresses: [CRM_ADDRESS_SEARCH_RESULTS_GETTER],
      shipAddress: SHIP_ADDRESS_GETTER,
    }),

    selectedAddressId() {
      if (this.shipAddress && this.shipAddress.id) return this.shipAddress.id;
      else return "";
    },

    currentPage() {
      return this.searchOptions.page;
    },

    perPage() {
      if (this.searchOptions.perPage) return this.searchOptions.perPage;
      else return this.perPageConfig.defaultPerPage;
    },
    perPageOptions() {
      if (
        this.perPageConfig &&
        this.perPageConfig.options &&
        this.perPageConfig.options.length
      ) {
        return this.perPageConfig.options.map((x) => {
          return {
            value: x.id,
            text: x.name,
          };
        });
      }
      return [];
    },

    totalNumberOfAddresses() {
      if (this.crmAddresses && this.crmAddresses.totalCount) {
        return this.crmAddresses.totalCount;
      }
      return 0;
    },
    paginationStart() {
      return (this.currentPage - 1) * this.searchOptions.perPage + 1;
    },
    paginationEnd() {
      var paginationEnd = this.currentPage * this.searchOptions.perPage;

      if (paginationEnd > this.totalNumberOfAddresses) {
        return this.totalNumberOfAddresses;
      } else {
        return paginationEnd;
      }
    },
    showPagination() {
      return this.totalNumberOfAddresses > this.searchOptions.perPage;
    },
    showPagingControls() {
      return this.crmAddresses.totalCount > this.searchOptions.perPage;
    },
  },
  methods: {
    ...mapActions({
      updateShipAddress: UPDATE_SHIP_ADDRESS,
    }),
    addressSelected(eventData) {
      this.updateShipAddress(eventData.address);
      this.$emit("crm-address-selected", eventData.address);
    },

    perPageChanged(eventData) {
      this.$emit("perPageChanged", eventData);
    },
    pageNumberChanged(eventData) {
      this.$emit("pageNumberChanged", eventData);
    },
    initializeSearchOptions() {
      if (!this.searchOptions.perPage) {
        this.searchOptions.perPage = this.perPageConfig.defaultPerPage;
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .n-search-results-title {
    text-transform: none;
    letter-spacing: normal;
    line-height: 35px;
  }

  .n-search-results-pagination {
    margin-left:10px; 
    font-size: 14px; 
    line-height: 35px;  
  }

  .n-button-row {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .n-add-to-favorite {
    font-size: 0.875rem;
  }

  .n-page-list {
    width: 15%;
    margin-right: 20px;
  }

  .n-pagination {
    color: #6c757d;
  }

  .n-crm-address-container {
    display: flex;
  }

  @media (max-width: 767px) {
    .n-company-address-btn {
      width: 100%;
      display: block;
    }
  }

  @media (max-width: 576px) {
    .n-search-results-title {
      width: 100%;
    }

    .n-crm-address-container {
      display: block;
    }

    .n-page-list {
      width: 100%;
    }

    .n-crm-address-paging-control {
      margin-top: 10px;
    }    
  }

  @media (min-width:577px) AND (max-width: 950px) {
    .n-search-results-title {
      width: 100%;
    }
    .n-page-list {
      width: 25%;
    }    
  }
</style>