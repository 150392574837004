import { IMPERSONATED_USER_KEY } from "@/nucleus-modules/dd-nucleus-ui/constants";

export class ImpersonateService {
    endImpersonation() {
        sessionStorage.removeItem(IMPERSONATED_USER_KEY);
    }

    getImpersonatedUser() {
        return JSON.parse(sessionStorage.getItem(IMPERSONATED_USER_KEY));
    }

    getImpersonationKey() {
        return IMPERSONATED_USER_KEY;
    }
}

export default new ImpersonateService();
