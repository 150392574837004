<template>
  <div class="n-product-tile-list" v-if="products && products.length">
    <div class="row" v-for="(product, index) in products" :key="product.id">
      <div class="col-sm-2">
        <router-link
          :id="product.id"
          :title="product.name"
          :to="'/product-detail/' + product.id"
        >
          <img
            class="img-fluid"
            :src="product.thumbnailImageUrl"
            v-if="product.thumbnailImageUrl !== null"
          />
          <img v-else class="img-fluid" :src="noImageAvailableUrl" />
        </router-link>
      </div>
      <div class="col-sm-5">
        <div
          class="badges"
          v-for="badge in product.tags"
          :key="badge.id"
        >
          <span
            class="badge badge-primary"
            >{{ badge.name }}</span
          >
          
        </div>
        <h2>
          <router-link
            class="n-product-name"
            :id="product.id"
            :title="product.name"
            :to="'/product-detail/' + product.id"
          >
            {{ product.name }}
          </router-link>
        </h2>
        <h4 class="n-product-sku">
          <router-link
            :id="product.id"
            :title="product.name"
            :to="'/product-detail/' + product.id"
          >
            {{ product.itemNumber }}
          </router-link>
        </h4>        
        <template v-if="storeSetup.ratingsEnabled">
          <div v-if="product.rating != null">
            <star-rating
              v-model="product.rating.value"
              v-bind:increment="0.5"
              v-bind:max-rating="5"
              v-bind:star-size="15"
              v-bind:border-width="2"
              v-bind:padding="3"
              inactive-color="#fff"
              active-color="#ffb500"
              border-color="#ba9143"
            >
            </star-rating>
            <div class="n-total-ratings">
              <router-link
                :id="product.id"
                :title="product.name"
                :to="'/product-detail/' + product.id"
              >
                {{ product.rating.totalRatings }} Ratings
              </router-link>
            </div>
          </div>
          <div v-else>
            <router-link
              :id="product.id"
              :title="product.name"
              :to="'/product-detail/' + product.id"
            >
              Add the first rating
            </router-link>
          </div>
        </template>
        <template v-if="product.messages && product.messages.length">
          <div class="n-message-list">
          <MessageList :messages="product.messages" />
          </div>
        </template>
        <FavoriteProductIcon :id="product.id"
                             :isFavorite="product.isFavorite"
                             :showText="false"
                             :showIconToAuthUsersOnly="true"
                             addFavoriteTextId="shared.favoriteIcon.addText"
                             removeFavoriteTextId="shared.favoriteIcon.removeText"></FavoriteProductIcon>
      </div>
      <div class="col-sm-2">
        <template v-if="storeSetup.isPricingEnabled">
          <div v-if="product.isOnSale" class="n-price">
            {{ product.salePrice | currency }}
            <div>
              <span class="n-price-on-sale">
                {{ $t("shared.pricing.salePriceWas") }}
                {{ product.unitPrice | currency }}
              </span>
            </div>
          </div>
          <div class="n-price" v-else>
            {{ product.unitPrice | currency }}
          </div>
        </template>
      </div>
      <div class="col-sm-3">
        <form class="form">
          <div
            class="form-group"
            v-if="product.canAddToCart && product.attributes.Inventory.quantityAvailable > 0"
          >
            <div class="row">
              <div class="col-sm-6">
                <QuantityInput
                  :eventName="UiEvents.QUANTITY_INPUT_CHANGED"
                  :item="product"
                  :min="1"
                  @quantityChanged="quantityChanged"
                />
              </div>
              <div class="col-sm-6 pl-1 mt-2 n-uom" v-if="isUnitOfMeasureAvailable(product)">
                <label
                  ><Uom
                    :uom="getInventory(product).unitOfMeasure"
                    :unitQuantity="getInventory(product).quantityPerUnit"
                /></label>
              </div>
            </div>
          </div>
          <div class="n-inventory-message" v-else>
              {{outOfStockMessageText}}
          </div>
          <button
            class="btn btn-primary btn-block"
            v-if="product.canAddToCart && product.attributes.Inventory.quantityAvailable > 0"
            :id="'button-' + product.id" :disabled="!(product.quantity > 0)" 
            @click.prevent="addItemToCart(product)"
          >
            <span
              v-if="
                buttonLoading && buttonLoading.length && buttonLoading[index]
              "
            >
              <b-spinner small></b-spinner>
              Loading...
            </span>
            <span v-else>
              Add to Cart
            </span>
          </button>
          <button
            class="btn btn-outline-primary btn-block"
            v-if="product.isPreviewable"
          >
            Preview
          </button>
          <button
            class="btn btn-outline-primary btn-block"
            v-if="product.isDownloadable"
          >
            Download
          </button>
          <span :id="'message-' + product.id">{{ addToCartMessage }}</span>
        </form>
      </div>
      <div class="col-sm-12 n-product-row">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuantityInput from "@/nucleus-modules/dd-nucleus-ui/components/QuantityInput.vue";
import Uom from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";
import { FLATTENED_CATEGORIES_GETTER, SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import MessageList from '@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue';
import FavoriteProductIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteProductIcon.vue";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { productMixins } from "@/mixins/productMixin.js";
export default {
  name: "ProductListMode",
  mixins:[productMixins],
  props: {
    addItemToCart: {
      type: Function,
    },
    buttonLoading: {
      type: Function,
    },
    noImageAvailableUrl: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      flattenedCategories: [FLATTENED_CATEGORIES_GETTER],
      storeSetup: [SITE_SETUP_GETTER],
    }),
    outOfStockMessageText() {
      if(this.storeSetup && this.storeSetup.constants) {
       return this.storeSetup.constants.defaultOutOfStockMessageText;
      }
      return "";
    },
    
  },
  components: {
    FavoriteProductIcon,
    MessageList,
    QuantityInput,
    Uom,
  },
  data() {
    return {
      errorMessage: "",
      productList: [],
      addToCartMessage: "",
      UiEvents: Object.freeze(UiEvents),
    };
  },
  beforeMount() {
    //this.setDefaultQuantities();
  },
  methods: {
    disableButton(isDisable) {
      if (isDisable == false) {
        return false;
      } else {
        return true;
      }
    },
    setDefaultQuantities() {
      var i;
      for (i = 0; i < this.products.length; i++) {
        this.products[i].quantity = 1;
      }
    },
    favoriteChanged(value, productId) {
      // TODO: handle the favorite toggle
      //   this.products = ProductService.favoriteChanged(
      //     value,
      //     productId,
      //     this.products
      //   );
      console.log("favoriteChanged invoked from ProductListMode", {
        value: value,
        productId: productId,
      });
    },
    quantityChanged() {
        this.$emit("quantityChanged");
    }
  },
};
</script>

<style lang="scss" scoped>
.n-product-tile-list {
  .popover-body {
    background: $red !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
  }

  .svg-inline--fa {
    vertical-align: -0.2em;
  }

  .n-price {
    color: $red;
    font-size: 16px;
    font-weight: bold;
  }

  .n-price span.n-price-on-sale {
    font-size: 14px;
    color: $gray-3;
    font-weight: normal;
    text-decoration: line-through;
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    border-bottom-color: $red;
  }

  .form-inline .form-group {
    margin-bottom: 10px;
  }

  .n-total-ratings {
    font-size: 12px;
    display: inline;
  }

  .img-fluid {
    background-color: $gray-2;
  }

  .n-favorite {
    padding-top: 6px;
  }

  .n-product-row {
    padding-top: 16px;
    padding-bottom: 16px;
  }  
}

@media (max-width: 768px) {
  .n-product-tile-list .n-order-limits {
    font-size: 12px;
  }
}
</style>
