<template>
  <AdminTemplate>
      <p v-if="isLoading">Loading...</p>
      <div v-else>
        <div class="n-order-detail" v-if="!$nucleus.isEmpty(orderDetails)">
          <OrderDetailHeader @approve-order="approve" @reject-order="reject"/>
          <PageSubHeader title="Customer Order Information" :hasThematicBreak="true"/>
          <PrimaryOrderInformation />
          <PageSubHeader title="Order Detail" :hasThematicBreak="true"/>
          <SalesInformation />
          <PageSubHeader title="Internal Notes" :hasThematicBreak="true" :editable="true" eventName="editNotes"/>
          <InternalNotes />
          <OrderTabs />
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <p>No order found with the id of <strong>{{$route.params.id}}</strong> try <router-link :to="{ name: 'OrderApprovals'}">going back</router-link> and trying again. </p>
            </div>
          </div>
        </div>
      </div>
  </AdminTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { GET_ORDER_DETAILS } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import { ORDER_DETAIL_GETTER, ORDER_PROPERTY_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

import AdminTemplate from "@/pages/templates/AdminTemplate.vue";
import OrderDetailHeader from "@/components/admin/order/OrderDetailHeader.vue";
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
import PrimaryOrderInformation from "@/components/admin/order/PrimaryOrderInformation.vue";
import SalesInformation from "@/components/admin/order/SalesInformation.vue";
import InternalNotes from "@/components/admin/order/InternalNotes.vue";
import OrderTabs from "@/components/admin/order/OrderTabs.vue";
import { APPROVE_ORDER, REJECT_ORDER } from "../../../nucleus-modules/dd-nucleus-admin/store/actions.type";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";


export default {
  name: "OrderDetail",
  data() {
    return {
      isLoading: true,
    }
  },
  components: {
    AdminTemplate,
    OrderDetailHeader,
    PageSubHeader,
    PrimaryOrderInformation,
    SalesInformation,
    InternalNotes,
    OrderTabs
  },
  computed: {
    ...mapGetters({
      orderDetails: ORDER_DETAIL_GETTER,
      getOrderProperty: ORDER_PROPERTY_GETTER
    }),
    internalNote() {
      return this.getOrderProperty("internalNote");
    },
  },
  methods: {
    ...mapActions({
        getOrderDetails: GET_ORDER_DETAILS,
        approveOrder: APPROVE_ORDER,
        rejectOrder: REJECT_ORDER
    }),
      async approve(orderId) {
          var approveOrderResponse = await this.approveOrder(orderId);
          
          if (approveOrderResponse && approveOrderResponse.succeeded) {
              ToastService.showToast(
                  "The order is approved successfully.",
                  MessageType.SUCCESS
              );
          }
          else {
              ToastService.showToast(
                  "The order is not approved successfully..  Please try again.",
                  MessageType.ERROR
              );
          }
      },
      async reject(orderId) {
          var rejectOrderResponse = await this.rejectOrder(orderId);

          if (rejectOrderResponse && rejectOrderResponse.succeeded) {
              ToastService.showToast(
                  "The order reject is successful.",
                  MessageType.SUCCESS
              );
          }
          else {
              ToastService.showToast(
                  "The order reject is not successful..  Please try again.",
                  MessageType.ERROR
              );
          }
      },
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getOrderDetails(this.$route.params.id);
    } else {
      this.$router.push({ name: 'orders'});
    }

    this.isLoading = false;
  },
};
</script>
