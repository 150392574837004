<template>
  <PageTemplate>
    <div class="page-container" v-if="!loading">
      <div class="row mt-2">
        <div class="col-lg-3 d-none d-lg-block">
          <AccountMenu :title="title" menu-id="account"></AccountMenu>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
          <div class="row">
            <div class="col-6">
              <h3 class="n-page-header">Order Details</h3>
            </div>
            <div class="col-6 text-right n-view-orders">
              <div>
                <router-link :to="'/orders/'">
                  &lt; View All Orders
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="!$nucleus.isEmpty(order)">
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12">
                <h4>
                  <span class="n-order-date">
                    {{ order.orderDate | moment("MMMM DD, YYYY") }}
                  </span>
                  |
                  <span>Order # {{ order.orderNumber }}</span>
                </h4>
                <div class="n-order-status my-2">
                  <OrderStatusComponent :status="orderStatus" />
                  <OrderShipDate :shipDate="orderShipDate" />
                </div>
                <h4 class="pt-3 pb-2 n-order-date">
                  Ordered By {{ orderCreatedBy }}
                </h4>
              </div>
              <div
                v-if="!hasMoreTrackingNumbers"
                class="col-lg-4 col-md-4 text-center mb-2"
              >
                <div
                  v-for="(shipments, index) in distinctShipments"
                  :key="index"
                >
                  <span v-if="!$nucleus.isEmpty(shipments.trackingUrl)">
                    <a
                      :href="shipments.trackingUrl"
                      class="btn btn-primary mb-1"
                      target="_blank"
                      >Track Your Order</a
                    >
                  </span>
                  <div class="text-center my-2">
                    <span
                      >Your tracking number is
                      <a :href="shipments.trackingUrl" target="_blank">{{
                        shipments.trackingNumber
                      }}</a></span
                    >
                  </div>
                </div>
              </div>
              <div v-else class="col-lg-4 col-md-4 text-left mb-2">
                <div v-if="hasMoreTrackingNumbers" class="n-order-tracking">
                  Tracking:
                </div>
                <div
                  v-for="(shipments, index) in distinctShipments"
                  :key="index"
                >
                  <span v-if="!$nucleus.isEmpty(shipments.trackingUrl)">
                    <a :href="shipments.trackingUrl" target="_blank">{{
                      shipments.trackingNumber
                    }}</a>
                  </span>
                  <span v-else>
                    {{ shipments.trackingNumber }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <Headline title="Shipping Details" />
              </div>
            </div>
            <div class="row pl-4 mb-4">
              <div class="col-lg-6 col-sm-12 col-md-12">
                <div class="n-order-detail-subheader">Shipping to</div>
                <div class="n-order-shipping" :class="isShipToAddressChanged ? 'n-order-history-warning' : ''">
                  <AddressView
                    :address="shippingTo"
                    v-if="!$nucleus.isEmpty(shippingTo)"
                  />
                  <div v-if="isShipToAddressChanged" class="n-order-history-warning">
                      Shipping address has been modified.
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-md-12 mt-2 mt-lg-0">
                <div class="n-order-detail-subheader">Shipping Method</div>
                <div class="n-order-shipping">
                  <ShippingMethod
                    :shipMethod="orderShippingMethod"
                    v-if="!$nucleus.isEmpty(orderShippingMethod)"
                  />
                </div>
                <div v-if="isShipMethodNameChanged" class="n-order-history-warning">
                  Original selection was {{previousShipMethodName}}
                </div>

                <div
                  v-if="isActualShipMethodDifferent"
                  class="n-order-shipping"
                >
                  <font-awesome-icon
                    class="n-warning"
                    :icon="['fas', 'info-circle']"
                  />
                  Note the shipping method has been changed from
                  {{ orderShippingMethod.name }} to Other Carrier
                </div>
              </div>
            </div>
            <template v-if="isPricingEnabled">
              <div class="row">
                <div class="col">
                  <Headline title="Payment Information" />
                </div>
              </div>
              <div class="row pl-4 mb-4">
                <div class="col-lg-6 col-sm-12 col-md-12">
                  <div class="n-checkout-subhead">Payment Method</div>
                  <!--TODO Payment Method info needs to be displayed-->
                </div>
                <div class="col-lg-6 col-sm-12 col-md-12 mt-2 mt-lg-0">
                  <div class="n-checkout-subhead">Billing Address</div>
                  <AddressView
                    :address="order.billing"
                    v-if="!$nucleus.isEmpty(order.billing)"
                  />
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col">
                <Headline title="Order Summary" />
              </div>
            </div>
            <div v-if="hasOrderWarnings" class="row mb-3">
              <div class="col">
                <b-alert variant="warning" show>
                  <strong
                    >Please review the important messages below for details
                    regarding your order.</strong
                  >
                  <ul class="mb-0">
                    <li v-for="(warning, index) in orderWarnings" :key="index">
                      <span v-html="warning"></span>
                    </li>
                  </ul>
                </b-alert>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <div v-if="hasItems">
                  <div v-for="(item, index) in order.items" :key="index">
                    <OrderItem
                      :item="item"
                      :canEditQuantity="false"
                      :canRemoveItems="false"
                      :noImageAvailableUrl="noImageAvailableUrl"
                      :isShipQuantityChanged="itemQuantityChanged[item.product['itemNumber'].toLowerCase() + ' quantity']"
                      :previousItemQuantity="previousItemQuantity[item.product['itemNumber'].toLowerCase() + ' quantity']"
                    >
                      <template v-slot:limits>
                        <div v-if="item.messages && item.messages.length > 0">
                          <div
                            v-for="(message, index) in item.messages"
                            :key="index"
                            class="mt-2 mb-2"
                          >
                            <font-awesome-icon
                              v-if="message.messageType === errorMessageType"
                              class="text-danger mr-2"
                              icon="times-circle"
                            />
                            <font-awesome-icon
                              v-else
                              class="text-warning mr-2"
                              icon="exclamation-circle"
                            />
                            <span v-html="getWarningByMessage(message)"></span>
                          </div>
                        </div>
                      </template>
                      
                    </OrderItem>
                    <div class="col-md-12" v-if="index < order.items.length - 1">
                      <hr class="my-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>No order details found for the given order number.</p>
          </div>
        </div>
        <div class="col-lg-1 col-md-0 col-sm-0"></div>
      </div>
    </div>
  </PageTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  OrderApprovalIds,
  OrdersMixin,
  ERROR_MESSAGE_TYPE
} from "@/nucleus-modules/dd-nucleus-storefront/mixins/OrdersMixin.js";

import PageTemplate from "@/pages/templates/PageTemplate";
import Headline from "@/nucleus-modules/dd-nucleus-ui/components/Headline";
import OrderStatusComponent from "@/nucleus-modules/dd-nucleus-storefront/components/OrderStatus";
import {
  OrderStatus,
  ShippingStatus,
  PaymentStatus,
} from "@/nucleus-modules/dd-nucleus-storefront/constants";
import AddressView from "@/components/review-order/AddressView";
import ShippingMethod from "@/components/review-order/ShippingMethodView";
import OrderItem from "@/nucleus-modules/dd-nucleus-storefront/components/CartItem";
import OrderShipDate from "@/nucleus-modules/dd-nucleus-storefront/components/OrderShipDate";
import { GET_ORDER_DETAILS, GET_ORDER_HISTORY } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { ORDER_DETAILS_GETTER, ORDER_HISTORY_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import AccountMenu from "@/components/AccountMenu.vue";
import { noImageAvailableUrl } from "@/companyAppConfig.js";
export default {
  name: "Order-Details",
  mixins: [OrdersMixin],
  components: {
    PageTemplate,
    Headline,
    OrderStatusComponent,
    AddressView,
    ShippingMethod,
    OrderItem,
    OrderShipDate,
    AccountMenu,
  },
  data() {
    return {
      title: "My Account",
      orderShippingMethods: [],
      styleClass: "btn btn-primary mb-1",
      loading: true,
      noImageAvailableUrl: noImageAvailableUrl,
      errorMessageType: ERROR_MESSAGE_TYPE,
      previousShipMethodName:'',
      previousItemQuantity:{},
      itemQuantityChanged:{}
    };
  },
  computed: {
    ...mapGetters({
      order: ORDER_DETAILS_GETTER,
      orderHistory: ORDER_HISTORY_GETTER
    }),
    isShipToAddressChanged(){
          let fieldName = [`Ship To Addressee`, `Ship To Address Line 2`,`Ship To City`, `Ship To State`,`Ship To Postal Code`,`Ship To Phone Number`];
          let allHistory = this.orderHistory;
          let isFound = false;
          let orderStatusToShowWarning = [OrderStatus.CANCELLED.code, OrderStatus.COMPLETED.code, OrderStatus.PENDING.code];
          let isShowWarning = orderStatusToShowWarning.indexOf(allHistory.orderStatus)  > -1;

          if(allHistory && isShowWarning)
          {
            for(let i = 0; i < fieldName.length; i++)
            {
              let field = fieldName[i];
              let oldValues = allHistory.activity.filter(function(item) { return item.field.toLowerCase() === field.toLowerCase()});
              if(oldValues.length > 0)
              {
                  isFound = true;
                  break;
              }
            }
          }

          return isFound;
    },
    isShipMethodNameChanged()
    {
      if(this.orderHistory)
      {
        let fieldName = `Ship Method Name`;
        let previousMethodNames = this.orderHistory.activity.filter(function(item) { return item.field.toLowerCase() === fieldName.toLowerCase()});
        let orderStatusToShowWarning = [OrderStatus.CANCELLED.code, OrderStatus.COMPLETED.code, OrderStatus.PENDING.code];
        let isShowWarning = orderStatusToShowWarning.indexOf(this.orderHistory.orderStatus)  > -1;

        if(previousMethodNames.length > 0 && isShowWarning){
          previousMethodNames = previousMethodNames.sort((a, b) => new Date(b.created) - new Date(a.created))
          this.getPreviousShipMethodName(previousMethodNames);
          return true;
        }
      }

      return false;
    },
    hasMoreTrackingNumbers() {
      return this.distinctShipments.length > 1;
    },
    distinctShipments() {
      if (this.hasShipments) {
        let shipments = this.order.shipments;
        return [
          ...new Map(shipments.map((s) => [s["trackingNumber"], s])).values(),
        ];
      }
      return [];
    },
    isPricingEnabled() {
      //TODO: Place holder, we need to revisit when the pricing is enabled.
      return false;
    },
    hasItemWarnings() {
      let result = false;

      this.order.items.forEach((i) => {
        if (i.messages && i.messages.length > 0) {
          result = true;
        }
      });

      return result;
    },
    hasItems() {
      return this.order && this.order.items && this.order.items.length > 0;
    },
    itemWarnings() {
      let messageIds = [];
      const result = [];

      this.order.items.forEach((i) => {
        if (i.messages && i.messages.length > 0) {
          i.messages.forEach((m) => {
            messageIds.push(m.messageId);
          });
        }
      });

      messageIds = Array.from(new Set(messageIds));

      messageIds.forEach((m) => {
        switch (m) {
          case OrderApprovalIds.CRM_ADDRESS_USED:
            result.push({
              title: "CRM Address Selected",
              text:
                "This order will require approval. You have selected a ship to address from your CRM address book.",
            });
            break;
          case OrderApprovalIds.EXPEDITED_SHIP_METHOD:
            result.push({
              title: "Expedited Shipping",
              text:
                "This order will require approval. You have selected an expedited ship method. ",
            });
            break;
          case OrderApprovalIds.LIMIT_EXCEEDED:
            result.push({
              title: "Monthly Limit Exceeded",
              text:
                "This order requires approval. You have exceeded the monthly limit for one or more product(s).",
            });
            break;
          case OrderApprovalIds.NOT_DEFAULT_ADDRESS:
            result.push({
              title: "Shipping Address",
              text:
                "This order requires approval. You are shipping to an address other than your default shipping address.",
            });
            break;
        }
      });

      return result;
    },
    orderStatus() {
      if (this.order) {
        let orderStatus = this.order.orderStatus;
        let shipStatus = this.order.shippingStatus;
        let paymentStatus = this.order.paymentStatus;
        if (
          orderStatus == OrderStatus.COMPLETED.code &&
          paymentStatus == PaymentStatus.COMPLETED.code
        ) {
          return PaymentStatus.COMPLETED.text;
        } else if (
          (orderStatus == OrderStatus.COMPLETED.code ||
            orderStatus == OrderStatus.PENDING.code) &&
          shipStatus == ShippingStatus.COMPLETED.code
        ) {
          return ShippingStatus.COMPLETED.text;
        } else if (
          orderStatus == OrderStatus.PENDING.code &&
          paymentStatus == PaymentStatus.PARTIAL.code
        ) {
          return PaymentStatus.PARTIAL.text;
        } else if (
          orderStatus == OrderStatus.PENDING.code &&
          shipStatus == ShippingStatus.PARTIAL.code
        ) {
          return ShippingStatus.PARTIAL.text;
        } else if (orderStatus == OrderStatus.CANCELLED.code) {
          return OrderStatus.CANCELLED.text;
        } else if (orderStatus == OrderStatus.PENDING_APPROVAL.code) {
          return OrderStatus.PENDING_APPROVAL.text;
        } else if (orderStatus == OrderStatus.PENDING.code) {
          return OrderStatus.PENDING.text;
        }
        return "No Status Available";
      }
      return "No Status Available";
    },
    orderCreatedBy() {
      if (this.order && this.order.user) {
        return this.order.user.firstName + " " + this.order.user.lastName;
      }
      return "";
    },
    hasShipments() {
      return (
        this.order && this.order.shipments && this.order.shipments.length > 0
      );
    },
    orderShipDate() {
      if (this.hasShipments) {
        let shipments = this.getLatestShipment();
        return shipments.shipDate;
      }
      return "";
    },
    actualShipMethod() {
      let shipMethod = this.getLatestShipment();
      if (!this.$nucleus.isEmpty(shipMethod)) {
        return shipMethod.shipMethodName;
      }
      return "";
    },
    isActualShipMethodDifferent() {
      if (this.order) {
        let orderShipmethodCode = this.order.shipMethodCode;
        let actualShipMethod = this.getLatestShipment();
        return (
          actualShipMethod &&
          actualShipMethod.shipMethodCode &&
          !actualShipMethod.shipMethodCode.includes(orderShipmethodCode)
        );
      }
      return false;
    },
    orderShippingMethod() {
      if (this.order && this.order.shipMethod) {
        return this.order.shipMethod;
      }
      return {};
    },
    orderWarnings() {
      const warnings = [];

      if (
        this.order &&
        this.order.messages &&
        this.order.orderStatus == OrderStatus.PENDING_APPROVAL.code
      ) {
        this.order.messages.forEach((message) => {
          if (message && message.parameters && message.parameters.length > 0) {
            message.parameters.forEach((p) => {
              switch (p) {
                case OrderApprovalIds.CRM_ADDRESS_USED:
                  warnings.push(
                    "<strong>CRM Address Selected:</strong> This order will require approval. You have selected a ship to address from your CRM address book."
                  );
                  break;
                case OrderApprovalIds.EXPEDITED_SHIP_METHOD:
                  warnings.push(
                    "<strong>Expedited Shipping:</strong> This order requires approval. You have selected an expedited shipping method."
                  );
                  break;
                case OrderApprovalIds.LIMIT_EXCEEDED:
                  warnings.push(
                    "<strong>Limit Exceeded:</strong> This order requires approval. You have exceeded the allowed limit for one or more product(s)."
                  );
                  break;
                case OrderApprovalIds.NOT_DEFAULT_ADDRESS:
                  warnings.push(
                    "<strong>Shipping Address:</strong> This order requires approval. You are shipping to an address other than your default shipping address."
                  );
                  break;
              }
            });
          }
        });
      }

      return warnings;
    },
    showMessage() {
        return this.order.orderStatus == OrderStatus.PENDING_APPROVAL.code;
    },
    shippingTo() {
      let shipTo = {};
      if(this.order && this.order.shipTo) {
        shipTo = Object.assign({}, this.order.shipTo);
        if(shipTo.addressee && shipTo.attention &&  shipTo.addressee != shipTo.attention){
            shipTo.companyName = shipTo.addressee;
            shipTo.addressee = shipTo.attention;
            shipTo.attention = null;
        }
        else {
            shipTo.attention = null;
        }
      }
      return shipTo;
    }
  },
  async mounted() {
    var orderId = this.$route.params.orderId;
    await this.getOrderDetails({ orderId: orderId });
    await this.getOrderHistory({ orderId: orderId });
    this.getOrderHistoryForItem();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getOrderDetails: GET_ORDER_DETAILS,
      getOrderHistory: GET_ORDER_HISTORY
    }),
    getOrderHistoryForItem(){
      let allOrderHistory = this.orderHistory;
        if(allOrderHistory)
        {          
          allOrderHistory.items.forEach(function(item){
            let productItemNumber = item.product.itemNumber;
            let fieldName = `${productItemNumber} quantity`;
            let previousValues = allOrderHistory.activity.filter(function(item) { return item.field.toLowerCase() === fieldName.toLowerCase()});
            let orderStatusToShowWarning = [OrderStatus.CANCELLED.code, OrderStatus.COMPLETED.code, OrderStatus.PENDING.code];
            let isShowWarning = orderStatusToShowWarning.indexOf(allOrderHistory.orderStatus)  > -1;

            if(previousValues.length > 0 && isShowWarning){
                previousValues = previousValues.sort((a, b) => new Date(b.created) - new Date(a.created))
                this.previousItemQuantity[fieldName.toLowerCase()] = previousValues[0].oldValue;
                this.itemQuantityChanged[fieldName.toLowerCase()] = true;
            }
          }.bind(this)); 
        }
        return '';
    },
    getPreviousShipMethodName(shipMethods){
      this.previousShipMethodName = shipMethods[0].oldValue;
    },
    getLatestShipment() {
      if (this.hasShipments) {
        let shipments = this.order.shipments.reduce((a, b) =>
          new Date(a.shipDate) > new Date(b.shipDate) ? a : b
        );
        return shipments;
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .n-page-header {
    padding-left: 15px;
    padding-bottom: 0px;
  }
}
.page-container {
  width: 100%;
  font-size: 0.85rem;
}
.n-view-orders {
  padding: 10px 15px 10px 0px;
}
.n-order-detail-subheader {
  color: $primary-dark-color;
  font-size: 1.125rem;
  font-weight: 600;
}
.n-order-shipping {
  color: $gray-3;
  font-size: 1.125rem;
}
</style>
