<template>
    <div class="n-favorite" @click="toggle">
        <font-awesome-icon v-if="isFavorite" class="n-heart-icon" :icon="['fas', 'heart']" />
        <font-awesome-icon v-else class="n-heart-icon" :icon="['far', 'heart']" />
        <div v-if="showText" class="n-favorite-text">
            <span v-if="isFavorite">{{ $t(removeFavoriteTextId) }}</span>
            <span v-else>{{ $t(addFavoriteTextId) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FavoriteIcon",
        props: {
            isFavorite: {
                type: Boolean,
                required: true
            },
            showText: {
                type: Boolean,
                required: false
            },
            addFavoriteTextId: { 
                type: String,
                required: false,
                default: "shared.favoriteIcon.addText"
            },
            removeFavoriteTextId: {
                type: String,
                required: false,
                default: "shared.favoriteIcon.removeText"
            }
        },
        methods: {
            toggle() {
                this.$emit('toggle');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .n-favorite {
        display: inline-block;
        color: $favorites-color;
        font-size: 14px;
        margin-top: 4px;
        cursor: pointer;
    }
    .n-favorite div {
        display: inline-block;
    }
    .n-favorite-text {
        vertical-align: middle;
    }
    .n-heart-icon {
        font-size: 20px;
    }
</style>