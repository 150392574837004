<template>
<div>
    <div class="row">  
        <div class="col-8 d-flex">
            <div class="col-1" v-if="showChevron">
                <button class="btn btn-transparent n-btn-link-icon"
                        type="button" aria-haspopup="true"
                        aria-expanded="false"
                        @click="toggle();">
                    <font-awesome-icon :icon="isOpen ? 'chevron-up': 'chevron-down'" />
                </button>
            </div> 
            <div class="col-7 d-flex category-item">
                        <b-form-checkbox
                            v-model="selectedCategory"
                            class="d-inline ml-3"
                            name="productCategory"
                            >{{ category.text }}</b-form-checkbox
                        >
            </div>
        </div>
        <div class="col-4">
        </div>
    </div>
   <template v-if="hasChildren && isOpen">
       <div v-for="child in category.children" :key="child.id" class="row category-sub-item">
           <div class="col-1">
           </div>
           <div class="col-11">
               <ProductCategoryItem :category="child" :level="level+1" :onSelect="onSelect" :selectedCategories="selectedCategories"></ProductCategoryItem>
           </div>
       </div>
   </template>
</div>
</template>

<script>
export default {
    name: "ProductCategoryItem",
    props : {
        category: {
            type: Object,
            required: true
        },
        level: {
            type:Number,
            required:true
        },
        onSelect: {
            type: Function,
            required:false
        },
        selectedCategories : {
            type: Array,
            required:true
        }
    },
    data() {
        return {
            isOpen: true
        }
    },
    computed: {
        hasChildren() {
            return this.category && this.category.children && this.category.children.length > 0 ;
        },
        selectedCategory: {
            get() {
                return !this.$nucleus.isEmpty(this.selectedCategories.find(c => c.id== this.category.id));
            },
            set(value) {
                this.onSelect({id:this.category.id, selected:value});
            }
        },
        showChevron() {
            return this.level==0;
        }
    },
    methods: {
        select(id, selected) {
            this.onSelect({id:id, selected:selected});
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
    },
}
</script>

<style lang="scss" scoped>
.category-item{
    padding: .40rem;
}
</style>