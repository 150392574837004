<template>
    <div>
        <div class="form-group">
            <label class="n-form-labels">Business Name
               <span class="n-form-label-notes">(optional)</span>
            </label>
         
            <input type="text"
                   class="form-control n-text-field-highlight"
                   v-model="companyName" />           
        </div>
        <div class="form-group">
            <label class="n-form-labels">Name</label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   v-model="addressee" />
        </div>
        <div class="form-group"
             :class="{ 'form-group--error': $v.addressLine1.$error }">
            <label class="n-form-labels">Address 1</label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   maxlength="30"
                   v-model="addressLine1" />

            <div v-if="$v.addressLine1 &&
            $v.addressLine1.$invalid &&
            isNotValid(addressLine1)">
                <div class="n-error" v-if="!$v.addressLine1.required">
                    Address 1 is required
                </div>
            </div>
            <div v-if="$v.addressLine1.$invalid && addressLine1 && addressLine1.length>0">
                <div class="n-error" v-if="!$v.addressLine1.address">
                    The Address 1 field format is invalid.
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="n-form-labels">
                Address 2
                <span class="n-form-label-notes">(optional)</span>
            </label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   maxlength="30"
                   v-model="addressLine2" />

            <div v-if="$v.addressLine2.$invalid && addressLine2 && addressLine2.length>0">
                <div class="n-error" v-if="!$v.addressLine2.address">
                    The Address 2 field format is invalid.
                </div>
            </div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.city.$error }">
            <label class="n-form-labels">City</label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   maxlength="23"
                   v-model="city" />
            <div v-if="$v.city.$invalid && isNotValid(city)">
                <div class="n-error" v-if="!$v.city.required">
                    City is required
                </div>
            </div>
            <div v-if="$v.city.$invalid && city && city.length > 0">
                <div class="n-error" v-if="!$v.city.alpha_spaces">
                    The City field may only contain alphabetic characters as well as
                    spaces
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="n-form-labels">State</label>
            <b-form-select class="n-text-field-highlight"
                           name="state"
                           :options="stateList"
                           v-model="state"
                           value-field="code"
                           text-field="name">
                  <template #first>
                    <b-form-select-option value="" >Select State</b-form-select-option>
                  </template>
            </b-form-select>

            <div v-if="$v.state.$invalid && isNotValid(state)">
                <div class="n-error" v-if="!$v.state.required">
                    State is required
                </div>
            </div>
        </div>
        <div class="form-group"
             :class="{ 'form-group--error': $v.postalCode.$error }">
            <label class="n-form-labels">Postal Code</label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   maxlength="10"
                   v-model="postalCode" />

            <div v-if="$v.postalCode.$invalid && isNotValid(postalCode)">
                <div class="n-error" v-if="!$v.postalCode.required">
                    Postal Code is required
                </div>
            </div>
            <div v-if="$v.postalCode.$invalid && postalCode && postalCode.length > 0">
                <div class="n-error" v-if="!$v.postalCode.minLength">
                    Postal Code must have at least
                    {{ $v.postalCode.$params.minLength.min }} letters.
                </div>
            </div>
            <div v-if="$v.postalCode.$invalid && postalCode && postalCode.length > 0">
                <div class="n-error" v-if="!$v.postalCode.maxLength">
                    Postal Code can have only
                    {{ $v.postalCode.$params.maxLength.max }} letters.
                </div>
            </div>
        </div>
        <span v-if="isUserAddressValid"></span>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { USER_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
  SET_USER_PROPERTY,
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

import { addresses } from "@/nucleus-modules/dd-nucleus-admin/mixins/addressesMixin.js";

import { StatesList } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";

    const address = helpers.regex("alpha", /^['&#.0-9a-zA-Z\s,-]+$/);
    const city = helpers.regex("alpha", /^['&.a-zA-Z\s,-]+$/);

export default {
  name: "UserAddress",
  mixins: [addresses],
  props: {
    address: { type: Object },
  },
  data() {
    return {
      addressToEdit: {
        companyName:"",
        addressee:"",
        addressLine1:null,
        addressLine2:"",
        city:null,
        state:null,
        postalCode:null
      },
      stateList: StatesList,
    };
  },
  computed: {
    ...mapGetters({
      user: USER_FOR_EDIT_GETTER,
    }),
    companyName: {
      get() {
        return this.address.companyName;
      },
      set(value) {
        this.address.companyName = value;
        this.$emit("updateAddress", this.address);
      },
    },
    addressee: {
      get() {
        return this.address.addressee;
      },
      set(value) {
        this.address.addressee = value;
          this.$emit("updateAddress", this.address);
      },
    },
     addressLine1: {
      get() {
        return this.address.addressLine1;
      },
      set(value) {
        this.address.addressLine1 = value;
          this.$emit("updateAddress", this.address);
      },
    },
    addressLine2: {
      get() {
        return this.address.addressLine2;
      },
      set(value) {
          this.address.addressLine2 = value;
          this.$emit("updateAddress", this.address);
      },
    },
    city: {
      get() {
        return this.address.city;
      },
      set(value) {
          this.address.city = value;
          this.$emit("updateAddress", this.address);
      },
    },
    state: {
      get() {
        return this.address.state;
      },
      set(value) {
          this.address.state = value;
          this.$emit("updateAddress", this.address);
      },
    },
    postalCode: {
      get() {
        return this.address.postalCode;
      },
      set(value) {
          this.address.postalCode = value;
          this.$emit("updateAddress", this.address);
      },
    },
    isUserAddressValid() {
      let result = !(
        this.$v.addressLine1.$invalid ||
        this.$v.city.$invalid ||
        this.$v.state.$invalid ||
        this.$v.postalCode.$invalid
      );
      this.updateValidity(result);
      return result;
    },
  },
  methods: {
    ...mapMutations({
      setUserProperty: SET_USER_PROPERTY,
    }),
    isNotValid(fieldName) {
      return fieldName != null && fieldName.length == 0;
    },
    updateAddressToEdit() {
        this.addressToEdit = this.address;
    },
    updateValidity(isValid) {
      this.$emit("updateValidity", isValid);
    },
  },
  mounted() {
    this.setUserProperty = this.$nucleus.debounce(this.setUserProperty, 100);    
  },  
  validations: {
      addressLine1: {
        required  ,
        address,
      },
      addressLine2: {
        address,
      },
      city: {
        required,
        city,
      },
      state: {
        required,
      },
      postalCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(10),
      },
  },
};
</script>


