// addresses
export const ADDRESS_FILTERS_GETTER = "addressFilters";
export const ADDRESS_PER_PAGE_CONFIG_GETTER = "addressPerPageConfig";
export const ADDRESS_SEARCH_RESULTS_GETTER = "addressSearchResults";
export const ADDRESS_SORTING_CONFIG_GETTER = "addressSortingConfig";

// company address
export const CRM_ADDRESS_SORTING_CONFIG_GETTER = "crmAddressSorting";
export const CRM_ADDRESS_PER_PAGE_CONFIG_GETTER = "crmAddressPerPageConfig"
export const CRM_ADDRESS_SEARCH_RESULTS_GETTER = "crmAddressSearchResults";
export const CRM_ADDRESS_FILTERS_GETTER = "crmFilters";

// get inventory quantities
export const INVENTORY_QUANTITY_GETTER = "inventoryQuantity";

// menu
export const ACTIVE_MENU_ITEMS_GETTER = "activeMenuItems";
export const USER_ANNOUNCEMENT_GETTER = "userAnnouncement";

//cart
export const CART_ADD_ON_ITEM_GETTER = "cartAddOnItem";