import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import { EXECUTE_OMNISEARCH, INIT_OMNISEARCH_RESULTS } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { OMNISEARCH_RESULTS_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { SET_OMNISEARCH_RESULTS } from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

const MODULE_ID = "omnisearch";

const initialState = {
    omniSearchResults: []
};

export const state = { ...initialState };

export const actions = {
    async [EXECUTE_OMNISEARCH]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/search?searchTerm=${payload.searchTerm}`, { showLoader: false, delay: 100 });

        if (response.succeeded) {
            commit(SET_OMNISEARCH_RESULTS, response.areas);
        }
        else {
            // TODO: add a system error
        }
    },
    async [INIT_OMNISEARCH_RESULTS]({ commit }) {
        commit(SET_OMNISEARCH_RESULTS, []);
    }
};

export const getters = {
    [OMNISEARCH_RESULTS_GETTER]: state => {
        return state.omniSearchResults;
    }
};

export const mutations = {
    [SET_OMNISEARCH_RESULTS]: (state, omniSearchResults) => {
        state.omniSearchResults = omniSearchResults;
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
