<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="d-inline">Bulk Order Created</h1>
        <a class="d-inline ml-4" @click="backToAll()">Back to All</a>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col">
        <BulkOrderDetail></BulkOrderDetail>
      </div>
    </div>
  </div>
</template>

<script>
import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";

import BulkOrderDetail from "@/components/admin/bulk-orders/BulkOrderDetail.vue";

export default {
  name: "BulkOrderPlacedConfirmation",
  mixins: [BulkOrdersMixin],
  components: {
    BulkOrderDetail,
  },
  methods: {
    backToAll() {
      this.$emit("back-to-all");
    },
  },
};
</script>

<style lang="scss" scoped></style>
