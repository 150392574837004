<template>
  <PageTemplate>
    <div class="page-container">
      <div class="row mt-2">
        <div class="col-md-3 d-none d-lg-block">
          <div class="col">
            <h2>Contact Us</h2>
          </div>

          <div class="col">
            <a href="/documents/FAQ.pdf" class="font-weight-bold n-contact" target="_blank">Frequently Asked Questions
            </a>
          </div>

        </div>
        <div class="col-md-12 col-lg-9">
          <div class="row">
            <div class="col">
              <h1>Contact Us</h1>
            </div>
          </div>
          <ContactUsForm :contactUs="contactUs" @submit="submit" v-if="!confirmPage" />
          <ContactUsConfirmation v-if="confirmPage" />
        </div>
      </div>
    </div>
  </PageTemplate>
</template>
<script>
import PageTemplate from "@/pages/templates/PageTemplate";
import ContactUsForm from "@/components/ContactUsForm";
import ContactUsConfirmation from "@/components/ContactUsConfirmation.vue";

import { mapActions } from "vuex";
import { CONTACT_US_SUBMIT } from "@/store/storefront/actions.type.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";


export default {
  name: "contact-us",
  components: {
    PageTemplate,
    ContactUsForm,
    ContactUsConfirmation
  },
  data() {
    return {
      contactUs: {
        topicName: '',
        name: null,
        email: null,
        phoneNumber: '',
        orderNumber: '',
        message: null
      },
      confirmPage: false
    };
  },
  methods: {
    ...mapActions({
      contactUsSubmit: CONTACT_US_SUBMIT
    }),


    async submit() {

      var response = await this.contactUsSubmit({ "Email": this.contactUs });

      this.confirmPage = response.succeeded;
      if (!response.succeeded) {

        ToastService.showToast(
          "Form could not be submitted. Please try again.",
          MessageType.ERROR
        );
      }
    }
  },

}

</script>
<style scoped lang="scss">
.form-control {
  width: 30%
}

.box-size {
  width: 70%
}
</style>
