<template>
    <div>
        <template v-if="category.children.length > 0">
            <div class="n-category">
                <div class="row no-gutters ">
                    <div class="col-10 n-category-item ">
                        <a class="btn btn-transparent title text-left"
                           :class="highlightCategory(category.id) ? 'n-selected-categories': ''"                           
                           href="#"
                           :style="needPadding ? navigationPadding : ''"
                           @click="navigateToProducts(category.id)">{{ category.name }}</a>
                    </div>
                    <div class="col-2 d-lg-block d-none n-category-header-item">
                        <button class="btn btn-transparent n-btn-link-icon"
                                type="button" aria-haspopup="true"
                                aria-expanded="false"
                                @click="toggle();">
                            <font-awesome-icon :icon="isOpen ? 'minus': 'plus'" />
                        </button>
                    </div>
                    <div class="col-2 d-lg-none n-category-header-item">
                        <button class="btn btn-transparent n-btn-link-icon"
                                :class="category.opened ? 'categoryOpened': ''"
                                type="button" aria-haspopup="true"
                                aria-expanded="false"
                                @click="toggle();">
                            <font-awesome-icon :icon="isOpen ? 'chevron-up': 'chevron-down'" />
                        </button>
                    </div>
                </div>
                <div class="d-lg-none"><hr/></div>
                <div v-if="isOpen" class="d-lg-block d-none n-category-dropdown n-category-sub-dropdown">
                    <CategoryItem v-for="child in category.children" :key="child.id" :category="child" :level="level + 1" :filterId="filterId" :selectedCategoryId="selectedCategoryId"/>
                </div>
                <div v-if="isOpen" class="d-lg-none n-category-dropdown n-category-sub-dropdown">
                    <CategoryItem v-for="child in category.children" :key="child.id" :category="child" :level="level + 1" :filterId="filterId" :selectedCategoryId="selectedCategoryId"/>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="n-category">
                <div class="row no-gutters">
                    <div class="col-12 n-category-header-item" :class="highlightCategory(category.id) ? 'n-selected-categories': ''">
                        <a class="btn btn-transparent title text-left"
                        :style="navigationPadding"
                        href="#"
                        @click="navigateToProducts(category.id)">{{ category.name }}</a>
                    </div>
                </div>
                <div class="d-lg-none"><hr/></div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SITE_SETUP_GETTER, ALL_CATEGORIES_ID_GETTER, ACTIVE_CATEGORIES_GETTER  } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { ADD_ACTIVE_CATEGORY, RESET_ACTIVE_CATEGORIES } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
    export default {
        name: "CategoryItem",
        props: {
            category: {
                type: Object,
                required: true
            },
            level: {
                type: Number,
                required:true
            },
            filterId: {
                type: String,
                required:true
            },
            selectedCategoryId: {
                type:String,
                required: false,
            },
            selectedParentCategory: {
                type:Array,
                required:false
            }
        },
        data() {
            return {
                categoryOpened:false,
                UiEvents:Object.freeze(UiEvents)
            }
        },
        computed: {
            ...mapGetters({
                activeCategories: ACTIVE_CATEGORIES_GETTER,
                allCategoriesId: [ALL_CATEGORIES_ID_GETTER],
                siteSetup: SITE_SETUP_GETTER,
            }),
            isOpen() {
                 if (this.activeCategories) {
                    let activeCategory = this.activeCategories.find(x => x.id === this.category.id);
                    if(activeCategory){
                        return activeCategory.isOpened;
                    }
                    return false;
                }
                return false;
         },
            navigationPadding() {
                return "padding-left:" + ((this.level) * 15 + 6) + "px";
            },
            needPadding() {
                return this.level >= 1;
            }
        },
        methods: {
            ...mapActions({
                addActiveCategory: ADD_ACTIVE_CATEGORY,
                resetActiveCategories: RESET_ACTIVE_CATEGORIES
            }),
            highlightCategory(categoryId) {
                return this.selectedCategoryId == categoryId;
            },
            toggle() {
                let isOpened = this.isOpen;
                let categoryItem = {
                    id: this.category.id,
                    isOpened: !isOpened
                };
                this.addActiveCategory(categoryItem);
            },
            async expandCategoriesOnLoad(){
                if(this.selectedParentCategory){
                    for(let i=0; i < this.selectedParentCategory.length; i++) {
                        let categoryItem = {
                                id: this.selectedParentCategory[i].id,
                                isOpened: true
                        };
                        await this.addActiveCategory(categoryItem);
                    }
                }
            },
            navigateToProducts(categoryId){
                if(categoryId == this.allCategoriesId){
                     categoryId ="";
                }
                this.$eventBus.$emit(UiEvents.CATEGORY_CHANGED, {
                    filterId: this.filterId,
                    values: [categoryId],
                });      
            },
        },
        async mounted() {
            await this.expandCategoriesOnLoad();
        },
        watch: {
            "$route.query": "expandCategoriesOnLoad"
        }
    };
</script>

<style scoped lang="scss">
    .categoryOpened {
        background-color: $tertiary-lightest-color !important;
    }
    .dropdown-toggle::after {
        font-family: "FontAwesome";
        content: "\f078";
        border: none;
        vertical-align: 0;
        color: $primary-color;
    }

    .show .dropdown-toggle::after {
        font-family: "FontAwesome";
        content: "\f077";
        border: none;
        vertical-align: 0;
        color: $primary-color;
    }
    @media (min-width: 1200px) {
        
    }    
    
    @media (max-width: 992px) {
        .n-category-sub-dropdown{
            left: 0%;
            position:relative;
        }
        .n-category hr {
            border-top: 1px solid $primary-light-color;
            margin: 2px 0 2px 0;
        }
    }    
    

    
    .n-category-header-item .btn, .n-category-item .btn {
        font-size: 0.85rem;
    }

    .n-category-item .btn, .btn.title{
        color: $primary-color;
        font-weight: normal;
        font-size: 1.25rem;
        font-family: $body-font;
        padding-left: 5px;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn.n-selected-categories, .n-selected-categories .btn {
        color: $all-categories-color !important;
        font-weight: 700;
        &:hover{
            text-decoration: underline;
        }
    }

    .n-category-header-item .n-btn-link-icon{
        color: $primary-color;
    }
    
</style>
