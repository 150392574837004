export const ACCESS_TOKEN_KEY = "access_token";
export const IMPERSONATED_USER_KEY = "sesnucusrimp";
export const SKIP_ADMIN_REDIRECT_KEY = "skip_admin_redirect";

export const MessageType = Object.freeze({
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
    SUCCESS: "success",
    NEUTRAL: "neutral",
    DANGER: "danger",
    CUSTOM: "custom"
});

export const TagType = Object.freeze({
    FAVORITE: "Favorite",
    NEW: "New"
});

export const UiEvents = Object.freeze({

    QUANTITY_INPUT_CHANGED: "quantity-input-changed",
    REMOVE_CART_ITEM_CLICKED: "remove-cart-item-clicked",
    SIGN_IN_SUCCEEDED: "sign-in-succeeded",
    PER_PAGE_CHANGED: "per-page-changed",
    SORT_VALUE_CHANGED: "sort-value-changed",
    CATEGORY_CHANGED: "category-changed",
    PRODUCT_FILTERS_CHANGED: "product-filters-changed",
    FILTER_TAG_REMOVED: "filter-tag-removed",
    ALL_FILTER_TAGS_REMOVED: "all-filter-tags-removed",
    CUSTOM_DROPDOWN_SELECTED: "custom-dropdown-selected",
    RESET_MULTI_SELECT_FILTERS: "reset-multi-select-filters",

});

export const StatesList = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "DC", name: "District Of Columbia" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "PR", name: "Puerto Rico" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" }
];

