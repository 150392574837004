<template>
<div class="n-announcement">
  <h2>{{title}}</h2>
   <p v-html="message"></p>
  </div>
</template>

<script>
export default {
  name:"Announcement",
  props: {
    title: {
      type:String,
      required:true
    },
    message: {
      type: String,
      requied:true
    }
  }
}
</script>

<style scoped lang="scss">
.n-announcement h4 {
  color: $primary-dark-color;
}
</style>