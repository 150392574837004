<template>
    <div class="reports-container" v-if="!$nucleus.isEmpty(reportList)">
        <template v-for="(reportInRow, rowIndex) in reportList">           
            <template v-if="!$nucleus.isEmpty(reportInRow)">
                <template v-for="(reportInCol, colIndex) in reportInRow">
                    <div class="report-item" v-if="!$nucleus.isEmpty(reportInCol)" :key="rowIndex+'-'+colIndex">
                        <div class="row pr-5">
                            <ReportViewTitle :title="reportTitle(reportInCol)" />
                            <div class="col-md-12" v-for="(report) in reportInCol" :key="report.id">
                                <ReportView :report="report" @deleteReport="deleteReport" />
                            </div>
                        </div>
                    </div>
                </template>
            </template>
           
        </template>
    </div>
</template>

<script>
    import ReportViewTitle from "@/components/admin/report/report-view-title";
    import ReportView from "@/components/admin/report/report-view";
    export default {
        components: {
            ReportViewTitle,
            ReportView
        },
        props: {
            list: Array
        },
        data() {
            return {
            }
        },
        computed: {
            reportList() {
                if (!this.$nucleus.isEmpty(this.list)) {
                    var reports = this.list;
                    reports = reports.sort(function (a, b) {
                        return a.groupSortOrder - b.groupSortOrder;
                    });
                    //Group by report type
                    var reportsByGroup = this.groupBy(reports, "groupName");
                    reports = this.objectToArray(reportsByGroup);
                    //chunk reports by 2
                    reports = this.chunk(reports, 2);
                    return reports;
                }
                return [];
            },
        },
        methods: {
            groupBy(list, props) {
                return list.reduce((a, b) => {
                    (a[b[props]] = a[b[props]] || []).push(b);
                    return a;
                }, {});
            },
            objectToArray(list) {
                var listArray = []
                listArray = Object.keys(list).map(k => {
                    return list[k];
                });
                return listArray;
            },
            chunk(inputArray, perChunk) {
                var result = inputArray.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / perChunk);

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new chunk
                    }
                    resultArray[chunkIndex].push(item);

                    return resultArray;
                }, []);
                return result;
            },
            reportTitle(reportArray) {
                if (!this.$nucleus.isEmpty(reportArray)) {
                    var report = reportArray[0];
                    if (!this.$nucleus.isEmpty(report) && !this.$nucleus.isEmpty(report.groupName)) {
                        return report.groupName + " Reports";
                    }
                    return "Reports";
                }
                return "Reports";
            },
            deleteReport(id) {
                this.$emit('deleteReport', id)
            }
        }
    }
</script>

<style lang="scss">
    .row-flex {
        display: flex;
        flex-wrap: wrap;
    }

    .report-row {
        column-count: 2;
        column-gap: 1rem;
    }

.reports-container{
  width:100%;
  height:auto;
  display:grid;
  grid-template-columns: auto auto;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  .report-item{
        align-items:center;
        justify-content:center;
        margin-bottom: -10px;
    }
  }

</style>