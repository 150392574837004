<template>
    <div>
        <h4 class="mb-3">Users Monthly Limit</h4>
        <div class="row justify-content-center">
            <div class="col-8">
                <div v-if="isLoading"></div>
                <div v-else>
                    <div class="row">
                        <div class="col-12">
                            <VueDropzone id="fileDropZone"
                                         ref="dropzone"
                                         :options="dropzoneOptions"
                                         :useCustomSlot="true"
                                         @vdropzone-upload-progress="(file, progress, bytesSent) => showProgress(file, progress, bytesSent, 'from_dz1')"
                                         @vdropzone-file-added="whenFileAdded"
                                         @vdropzone-success="uploadSuccess"
                                         @vdropzone-error="uploadError"
                                         @vdropzone-sending="beforeSendingFormData"
                                         @vdropzone-drop="whenFilesDropped"
                                         @vdropzone-queue-complete="whenQueueCompleted">
                                <div class="dropzone-custom-content text-center">
                                    <p class="dropzone-custom-title">Upload User Monthly Allocation File</p>
                                    <div class="btn btn-outline-primary">Select File</div>
                                </div>
                            </VueDropzone>
                            <div class="text-center pt-3">
                                <p class="n-notes">
                                    Accepted file formats are .xls and .xlsx.
                                </p>
                                <p class="n-notes">
                                {{ errorMessage}}   
                                </p>
                                <div>
                                    <a class="n-notes" download="Upload-File-Template.xlsx" href="/documents/Upload-File-Template.xlsx">
                                        Download sample file template (xlsx)
                                    </a><br />
                                </div>
                                <div>
                                    <a class="n-notes" download="Upload-File-Template.xls" href="/documents/Upload-File-Template.xls">
                                        Download sample file template (xls)
                                    </a><br />
                                </div>
                            </div>
                            </div>
                        </div>
                    <div v-if="!isDone" id="DropFileList" class="row">
                        <hr />
                        <div v-if="hasUploadedFiles" class="col-12">
                            <hr class="mt-4 mb-4" />
                            <div><p class="n-product-section-heads ml-3 mt-3">File Upload</p></div>
                            <div>
                                <div v-for="file in lastUploadedFile"
                                     :key="file.fileId"
                                     :class="['n-odd-row', 'n-media-upload','p-3',]">
                                    <div class="col-1">
                                        <font-awesome-icon :icon="['fas', 'file-excel']"
                                                           style="font-size:36px"></font-awesome-icon>
                                    </div>
                                    <div class="file-name col-5 pt-1">
                                        {{ file.name }}
                                    </div>
                                    <div class="col-4 error">
                                        <template v-if="file.status">
                                            <b-progress :value="file.progress"
                                                        show-progress
                                                        height="25px"
                                                        class="w-200 mb-2"></b-progress>
                                        </template>
                                        <template v-else>
                                            <span class="text-center pt-3">{{file.errorMessage }}</span>
                                        </template>
                                        <span class="text-center pt-3" v-if="file.uploadMessage">{{ file.uploadMessage }}</span>
                                    </div>
                                    <div class="col-2">
                                        <font-awesome-icon v-if="file.statusIcon"
                                                           :icon="['fa', file.statusIcon]"
                                                           :style="{ color: file.iconColor }"
                                                           size="2x"></font-awesome-icon>
                                    </div>
                                </div>
                            </div>

                            <div v-show="false" class="text-right mr-3 pt-2">
                                {{ totalUploadedFiles }} of {{ fileList.length }} uploaded
                            </div>
                            <div class="text-right mr-3 pt-2">
                                <button @click="isDone=true" @click.prevent="removeAllFiles()" class="btn btn-primary">
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isDone" class="row mt-4">
                        <!-- Upload Summary Section -->
                        <div v-if="showUploadSummary" class="col-12">
                            <h5>Upload Summary</h5>
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="table-header">
                                        <th class="table-header-cell">Total Records</th>
                                        <th class="table-header-cell">Records Uploaded</th>
                                        <th class="table-header-cell">Records Failed</th>
                                        <th class="table-header-cell">Records Duplicated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ apiResponse.totalRecords }}</td>
                                        <td>{{ apiResponse.recordsSuccessful }}</td>
                                        <td style="color: red;">{{ apiResponse.recordsFailed }}</td>
                                        <td style="color: red;">{{ apiResponse.recordsDuplicated }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div v-if="showBadRecords" class="col-12 mt-4">
                            <h5>Bad Records</h5>
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="table-header">
                                        <th class="table-header-cell">Row Number</th>
                                        <th class="table-header-cell">Email</th>
                                        <th class="table-header-cell">Limit Value</th>
                                        <th class="table-header-cell">Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(record, index) in badRecords" :key="index">
                                        <td>{{ record.rowNumber }}</td>
                                        <td>{{ record.email }}</td>
                                        <td>{{ record.limitValue }}</td>
                                        <td>{{ record.message }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="showFileContent" class="col-12 mt-4">
                            <h5>File Content</h5>
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="table-header">
                                        <th class="table-header-cell">User Email</th>
                                        <th class="table-header-cell">Monthly Limit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in paginatedFileContent" :key="index">
                                        <td>{{ row.EmailId }}</td>
                                        <td>{{ row.LimitValue }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="totalPages > 1" class="pagination">
                                <button @click="prevPage" :disabled="currentPage === 1">&lt;</button>
                                <button v-for="page in visiblePages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">
                                    {{ page }}
                                </button>
                                <button @click="nextPage" :disabled="currentPage === totalPages">&gt;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import { mapGetters, mapMutations } from "vuex";
    import VueDropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import {
        PRODUCT_FOR_EDIT_GETTER,
        PRODUCT_PROPERTY_GETTER,
    } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    import { MediaTypes } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
    import {
        AdminEvents,
        MediaStatus,
    } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
    import * as XLSX from 'xlsx';
    import JwtService from "@/nucleus-modules/dd-nucleus-ui/services/jwt.service.js";
    import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
    import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";



    export default {
        components: {
            VueDropzone
        },
        data() {
            return {
                isDone: false,
                isLoading: true,
                fileList: [],
                fileContent: [],
                errorMessage: null,
                apiResponse: null,
                badRecords: [],
                showUpload: false,
                currentPage: 1,
                itemsPerPage: 10,
                dropzoneOptions: {
                    url: "/ui/admin/product/user-monthly-limit",
                    addRemoveLinks: false,
                    previewsContainer: false,
                    autoProcessQueue: true, 
                    acceptedFiles: '.xls,.xlsx',
                    dictInvalidFileType: "Error uploading file: Only .xls and .xlsx files are allowed.",
                    headers: {
                        Authorization: this.bearerToken,
                    },

                }
            };
        },
        computed: {
            ...mapGetters({
                getProductProperty: PRODUCT_PROPERTY_GETTER,
                product: PRODUCT_FOR_EDIT_GETTER,
                userInfo: USER_INFO_GETTER,
            }),
            hasUploadedFiles() {
                return this.fileList && this.fileList.length > 0;
            },
            totalUploadedFiles() {
                if (this.hasUploadedFiles) {
                    return this.fileList.filter((fl) => fl.status === MediaStatus.SUCCESS)
                        .length;
                } else {
                    return 0;
                }
            },
            lastUploadedFile(){
                return this.fileList.length > 0 ? [this.fileList[this.fileList.length - 1]] : [];
            },
            showFileContent(){
                return ((!this.apiResponse || !this.apiResponse.totalRecords) || (this.badRecords && this.badRecords.length === 0)) && this.fileContent.length > 0;
            },
            showBadRecords(){
                return this.badRecords && this.badRecords.length > 0;
            },
            showUploadSummary(){
                return this.apiResponse && this.apiResponse.totalRecords;
            },
            paginatedFileContent() {
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return this.fileContent.slice(start, end);
            },
            totalPages() {
                return Math.ceil(this.fileContent.length / this.itemsPerPage);
            },
            visiblePages() {
                const pages = [];
                const total = this.totalPages;
                const current = this.currentPage;

                if (total <= 5) {
                    for (let i = 1; i <= total; i++) {
                        pages.push(i);
                    }
                } else {
                    let startPage = Math.max(1, current - 2);
                    let endPage = Math.min(total, current + 2);

                    if (current <= 3) {
                        startPage = 1;
                        endPage = 5;
                    } else if (current >= total - 2) {
                        startPage = total - 4;
                        endPage = total;
                    }

                    for (let i = startPage; i <= endPage; i++) {
                        pages.push(i);
                    }
                }

                return pages;
            }


        },
        async created() { },
        mounted() {
            this.isLoading = false;
            JwtService.getToken().then((result) => {
                this.dropzoneOptions.headers.Authorization = `Bearer ${result}`;
            });
            this.fetchUserLimits();
        },
        methods: {
            ...mapMutations({
                setProductProperty: SET_PRODUCT_PROPERTY,
            }),
            async fetchUserLimits() {

                if (!this.product || !this.product.id) {
                    return;
                }

                const token = await JwtService.getToken();
                const userLimitResponse = await ApiService.get(`/ui/admin/product/user-limits?ProductId=${this.product.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (userLimitResponse.code === 200) {
                    
                    this.fileContent = userLimitResponse.emailLimitList.map(item => ({
                        EmailId: item.email,
                        LimitValue: item.limitValue
                    }));
                }
            },

            showProgress(file, progress) {
                progress = Number(progress).toFixed(0);
                this.updateFileList(file.upload.uuid, "progress", progress);
            },
            removeAllFiles() {
                this.$refs.dropzone.removeAllFiles();
                this.fileList = [];
                this.apiResponse = null;
                this.badRecords = [];
                this.fileContent = [];
                this.currentPage = 1; 


            },
            whenFileAdded(file) {
                this.apiResponse = null;
                this.badRecords = [];
                this.fileContent = [];
                this.isDone = false;
                this.currentPage = 1; // Reset current page
                const acceptedFileTypes = ['.xls', '.xlsx'];
                const fileType = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

                if (!acceptedFileTypes.includes(fileType)) {
                    this.isDone = true;
                    file = null;
                    ToastService.showErrorToast(this.dropzoneOptions.dictInvalidFileType);
                }
                if (!this.showUpload) {
                    this.showUpload = true;
                    this.whenFilesDropped();
                }

                this.fileList.push({
                    name: file.name,
                    removeLink: file._removeLink,
                    id: file.upload.uuid,
                    progress: "0",
                    accepted: file.accepted,
                    status: file.status,
                });
                this.isDone = false;
                this.readFile(file);
                this.selectedFile = file;
                this.$emit('file-selected', file);
            },
            uploadSuccess(file, response) {
                this.apiResponse = response;
                this.badRecords = response.badRecords || [];
                if (response.succeeded) {
                    file.status = "success";
                   
                    this.updateFileStatus(
                        file,
                        response.succeeded === true
                            ? "success"
                            : "error"
                    );
                    this.populateResponse(file, response);
                } else {
                    file.status = "error";
                    let error;

                    if (response.messages && response.messages.length > 0) {
                        error = response.messages[0].debugMessage;
                    } else {
                        error = "Error Uploading File";
                    }

                    this.uploadError(file, error);
                }
            },
            populateResponse(fileProps, response) {
                if (fileProps.upload && response) {
                    const productMedia = {
                        mediaName: fileProps.upload.filename,
                        mediaSize: fileProps.size,
                        mediaType: MediaTypes.FILE,
                        uploadMessage: response,
                    };

                    this.newMediaList.push(productMedia);
                }
            },
            updateFileList(fileId, key, value, uploadMessage, err) {
                const index = this.fileList.findIndex((fl) => fl.id === fileId);
                const mediaItem = this.fileList[index];

                mediaItem[key] = value;
                if (this.apiResponse === null && mediaItem.status !== MediaStatus.ERROR ) {

                    mediaItem["statusIcon"] = "spinner";
                    mediaItem["iconColor"] = "green";
                    mediaItem.uploadMessage = "File Uploading"
                }
                if (this.apiResponse.recordsFailed > 0 ||
                    this.apiResponse.recordsDuplicated > 0 ||
                    this.apiResponse.recordsSuccessful !== this.apiResponse.totalRecords) {
                    mediaItem["statusIcon"] = "times";
                    mediaItem["iconColor"] = "red";
                    mediaItem.uploadMessage = "File Not Uploaded due to Bad Records";
                    ToastService.showErrorToast(mediaItem.uploadMessage);
                    this.$scrollTo("#DropFileList", 500, { easing: "ease-in-out" });
                }
                else if (mediaItem.status === MediaStatus.SUCCESS &&
                    this.apiResponse.recordsSuccessful === this.apiResponse.totalRecords && this.apiResponse.recordsSuccessful != 0) {
                    mediaItem["statusIcon"] = "check";
                    mediaItem["iconColor"] = "green";
                    mediaItem.uploadMessage = "File Upload Success"
                    this.errorMessage = null;
                    ToastService.showSuccessToast("File Uploaded");

                    this.$scrollTo("#DropFileList", 500, { easing: "ease-in-out" });
                }
                else if (mediaItem.status === MediaStatus.CANCELLED ||
                    mediaItem.status === MediaStatus.ERROR || this.apiResponse.recordsSuccessful === 0) {
                    mediaItem["statusIcon"] = "times";
                    mediaItem["iconColor"] = "red";
                    mediaItem.uploadMessage = "Error Uploading File";
                    mediaItem.errorMessage = "Error Uploading File";
                    ToastService.showErrorToast(err|| mediaItem.errorMessage);
                    this.$scrollTo("#DropFileList", 500, { easing: "ease-in-out" });
                }


               
                this.fileList.splice(index, 1, mediaItem);
            },
            updateFileStatus(file, uploadMessage, err) {
                this.updateFileList(
                    file.upload.uuid,
                    "status",
                    file.status,
                    uploadMessage,
                    err
                );
            },
            uploadError(file, err) {
                this.updateFileStatus(file, "", err);
            },
            beforeSendingFormData(file, xhr, formData) {
                if (!this.product || !this.product.id) {
                    return;
                }
                if (!file || !file.name) {
                    return;
                }
                formData.append("productId", this.product.id);
                formData.append("userId", this.userInfo.id);
            },
            whenFilesDropped() {
                this.isDone = false;
                this.showUpload = true;
                this.$scrollTo("#DropFileList", 500, { easing: "ease-in-out" });
            },
            whenQueueCompleted() {
                if (this.newMediaList && this.newMediaList.length > 0) {
                    this.showUpload = false;
                    this.$eventBus.$emit(AdminEvents.PRODUCT_IMAGE_UPLOADED);
                }
            },
            readFile(file) {
                this.isDone = false;
                const reader = new FileReader();

                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    this.processExcelData(jsonData);
                };

                reader.readAsArrayBuffer(file);
            },
            processExcelData(data) {
                const headers = data[0];
                const rows = data.slice(1);
                if (!headers.includes('EmailId') || !headers.includes('LimitValue')) {
                    this.$refs.dropzone.removeAllFiles();
                    this.fileList = [];
                    ToastService.showErrorToast("Error Uploading File: The file must contain EmailId and LimitValue columns.");

                    return;
                }
                this.fileContent = rows.map(row => {
                    let rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });
            },
            changePage(page) {
                if (page > 0 && page <= this.totalPages) {
                    this.currentPage = page;
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage += 1;
                }
            },
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage -= 1;
                }
            }


        }
    };
</script>
<style lang="scss" scoped>
    .vue-dropzone {
        border: 3px dashed #808080;
    }

    .vue-dropzone:hover {
        border: 3px dashed #1643f5;
    }

    .dropzone-custom-title {
        font-size: 22px;
    }

    .n-center-aligned {
        text-align: center;
    }

    .n-media-upload {
        display: flex;
    }

    .p-3 {
        padding: 1rem;
    }

    .n-notes {
        padding-top: 0px;
        margin-bottom: 6px;
    }

    .progress-bar {
        background-color: #1d2cb8;
    }

    .progress-container {
        justify-content: center;
        margin-left: 10px;
        flex-grow: 1;
        align-items: center;
    }

    .table-header {
        background-color: #015bc4;
        color: #FFF !important;
        background: #015bc4 !important;
        text-align: left !important;
        text-align: center;
        font-weight: bold;
    }

    .table-header-cell {
        padding: 10px;
        border-bottom: 2px solid #dee2e6;
    }
  

    .text-center {
        text-align: center;
    }

    .file-name {
        display: block;
        max-width: 100%;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
    }

    .pagination button {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        background-color: #fff;
        cursor: pointer;
    }

    .pagination button.active {
        background-color: #015bc4;
        color: #fff;
    }

    .pagination button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
</style>
