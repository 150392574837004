<template>
  <AdminTemplate>
    <div class="row n-all-products">
      <div class="col-12">
        <div class="row">
          <div class="col-2 mt-2">
            <h4>Items</h4>
          </div>
          <div class="col-10 text-right">
            <InventorySync />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <router-link :to="{ name: 'EditProduct' }"
              ><button class="btn btn-outline-primary btn-sm">Add Item</button>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mt-3">
            <div class="n-searchbox">
              <div class="input-group search mt-3">
                <input
                  type="text"
                  v-model="searchTerm"
                  class="form-control n-search-input"
                  placeholder="Search All Items"
                  aria-label="input-group mb-3"
                  v-on:keyup.enter="exeuteSearch"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-search"
                    type="button"
                    @click="exeuteSearch"
                  >
                    <font-awesome-icon
                      icon="search"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="hasItems" class="col-6 text-right">
            <div class="mt-2">
              Showing {{ paging.pageStartNumber }}-{{ paging.pageEndNumber }} of
              {{ paging.totalCount }}
            </div>
            <div class="mt-1">
              <div class="d-inline mr-3">
                <b-form-select
                  class="col-2"
                  v-model="paging.pageSize"
                  :options="perPageOptions"
                  @change="updatePage()"
                ></b-form-select>
                per page
              </div>
              <div class="d-inline">
                <b-pagination
                  class="float-right"
                  v-model="paging.pageNumber"
                  :total-rows="paging.totalCount"
                  :per-page="paging.pageSize"
                  @change="updatePage"
                  aria-controls="usersTable"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-5">
          <div class="n-button-filters d-inline">
            <div><strong>Item Status</strong></div>
            <ul class="quickFilters">
              <li v-for="filter in quickFilters" :key="filter.id">
                <button
                  class="btn btn-white"
                  :class="{ active: quickFiltersSelected.includes(filter) }"
                  @click="toggleQuickFilter(filter)"
                >
                  {{ filter }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <VueGoodTable
                class="table table-striped"
                :columns="columns"
                :rows="allProducts.items"
                :sort-options="sortConfig"
                @on-sort-change="onSortChange"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'actions'">
                    <div class="d-inline-block pr-1">
                      <router-link
                        :id="props.row.id"
                        :title="props.row.name"
                        :to="{
                          name: 'EditProduct',
                          query: { productId: props.row.id },
                        }"
                      >
                        <font-awesome-icon
                          class="icon pencil"
                          :icon="['fas', 'pencil']"
                        />
                      </router-link>
                      <span
                        v-if="checkStatusCode(props.row, status.ACTIVE)"
                        class="spanright"
                      >
                        <span class="badge badge-pill badge-success"
                          >Active</span
                        >
                      </span>
                      <span
                        v-if="checkStatusCode(props.row, status.INACTIVE)"
                        class="spanright"
                      >
                        <span class="badge badge-pill badge-primary"
                          >Inactive</span
                        >
                      </span>
                      <span
                        v-if="checkStatusCode(props.row, status.NEW)"
                        class="spanright"
                      >
                        <span class="badge badge-pill badge-pending">New</span>
                      </span>
                    </div>
                    <div class="d-block">
                      <span v-if="showStartDisplayDate(props)">
                        on <br />{{
                          props.row.startDisplayDate | moment(dateFormat)
                        }}</span
                      >
                    </div>
                  </span>

                  <span v-else-if="props.column.field === 'itemNumber'">
                    <router-link
                      :id="props.row.itemNumber"
                      :title="props.row.itemNumber"
                      :to="{
                        name: 'EditProduct',
                        query: { productId: props.row.id },
                      }"
                    >
                      {{ props.row.itemNumber }}</router-link
                    >
                  </span>

                  <span v-else-if="props.column.type === 'date'">
                    {{
                      props.formattedRow[props.column.field]
                        | moment(dateFormat)
                    }}
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>

                  <span v-if="isUpdatedBy(props)">
                    {{
                      props.row.attributes.ExtendedProductProperties.updatedBy
                    }}
                  </span>
                  <span v-if="isUpdated(props)">
                    {{
                      props.row.attributes.ExtendedProductProperties.updated
                        | moment(dateFormat)
                    }}
                  </span>
                  <span v-if="isCreated(props)">
                    {{
                      props.row.attributes.ExtendedProductProperties.created
                        | moment(dateFormat)
                    }}
                  </span>
                </template>
              </VueGoodTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { mapActions, mapGetters } from "vuex";
import { SEARCH_ADMIN_PRODUCTS } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import { FilterIds } from "@/constants.js";
import { ALL_PRODUCTS_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
  StatusCodes,
  ProductStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import AdminTemplate from "@/pages/templates/AdminTemplate.vue";
import InventorySync from "@/components/admin/product/InventorySync.vue";

export default {
  name: "AllProducts",
  components: {
    VueGoodTable,
    AdminTemplate,
    InventorySync,
  },
  data() {
    return {
      searchTerm: "",
      columns: [],
      dateFormat: "MM/DD/YYYY",
      isLoading: true,
      pagingConfig: {},
      searchConfig: {enabled: true, placeholder: "Search All Items"},
      sortConfig: { },
      status: StatusCodes,
      productStatus: ProductStatus,
      today: this.$moment(new Date()).format("YYYY-MM-DD"),
      paging: {
        includeTotal: true,
        pageCount: 0,
        pageNumber: 1,
        pageSize: 200,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
      },
      perPageOptions: [
        { value: 200, text: "200" },
        { value: 400, text: "400" },
        { value: 600, text: "600" },
        { value: 800, text: "800" },
        { value: 1000, text: "1000" },
      ],

      quickFilters:["all", "active", "inactive","new"],
      quickFiltersSelected: ["all"],
      sortBy: "created",
      sortDesc: true,
    };
  },
  computed: {
    ...mapGetters({
      allProducts: ALL_PRODUCTS_GETTER,
    }),
    totalPages() {
      return this.paging.totalCount / this.paging.pageSize + 1;
    },
    hasItems() {
      return this.allProducts.items && this.allProducts.items.length > 0;
    }
  },
  methods: {
    ...mapActions({
      searchProductsAction: SEARCH_ADMIN_PRODUCTS,
    }),
    async updatePage(page) {
      if (page !== undefined) {
        this.paging.pageNumber = page - 1;
      } else {
        this.paging.pageNumber = this.paging.pageNumber - 1;
      }
      await this.searchProducts();
    },
    showStartDisplayDate(props) {
      return (
        props.row.startDisplayDate &&
        props.row.status === this.status.ACTIVE &&
        props.row.startDisplayDate.length &&
        this.isPastStartDisplayDate(props.row.startDisplayDate)
      );
    },
    getStatus(value) {
      if (
        value.attributes &&
        value.attributes.ExtendedProductProperties &&
        value.attributes.ExtendedProductProperties.status
      ) {
        return value.attributes.ExtendedProductProperties.status;
      } else {
        return "";
      }
    },
    isUpdatedBy(props) {
      return (
        props.column.field === "updatedBy" &&
        props.row.attributes &&
        props.row.attributes.ExtendedProductProperties &&
        props.row.attributes.ExtendedProductProperties.updatedBy
      );
    },
    isUpdated(props) {
      return (
        props.column.field === "updated" &&
        props.row.attributes &&
        props.row.attributes.ExtendedProductProperties &&
        props.row.attributes.ExtendedProductProperties.updated
      );
    },
    isCreated(props) {
      return (
        props.column.field === "created" &&
        props.row.attributes &&
        props.row.attributes.ExtendedProductProperties &&
        props.row.attributes.ExtendedProductProperties.created
      );
    },
    checkStatusCode(value, status) {
      switch (status) {
        case this.status.ACTIVE:
          if (
            value.status === this.status.ACTIVE ||
            value.status === this.productStatus.ACTIVE ||
            this.getStatus(value) === this.status.ACTIVE
          ) {
            return true;
          }
          break;
        case this.status.INACTIVE:
          if (
            value.status === this.status.INACTIVE ||
            value.status === this.productStatus.INACTIVE ||
            this.getStatus(value) === this.status.INACTIVE
          ) {
            return true;
          }
          break;
        case this.status.NEW:
          if (
            value.status === this.status.NEW ||
            value.status === this.productStatus.NEW ||
            this.getStatus(value) === this.status.NEW
          ) {
            return true;
          }
          break;
        default:
          return false;
      }
    },
    async exeuteSearch() {
        this.paging.pageNumber = 0;
        await this.searchProducts();
    },

    async searchProducts() {
      const request = {
        paging: {
          pageNumber: this.paging.pageNumber,
          pageSize: this.paging.pageSize,
          includeTotalCount: true,
        },
        sort: this.mapSortBy(),
      };

      request.filters =  [          
           { filterId: FilterIds.ITEM_STATUS, values: this.quickFiltersSelected },
        ] 
      if(this.searchTerm) {
        request.filters.push({ filterId: FilterIds.SEARCH_TERM, values: [this.searchTerm] });
      } 
        
      await this.searchProductsAction(request);
      this.setPaging();
    },

    isPastStartDisplayDate(activeDate) {
      activeDate = this.$moment(activeDate, "YYYY-MM-DD");

      if (activeDate.diff(this.today) > 0) {
        return true;
      } else {
        return false;
      }
    },
    setColumns() {
      this.columns.push({ label: "", field: "actions", sortable: false });
      this.columns.push({
        label: "Item #",
        field: "itemNumber",
        type: "string",
      });
      this.columns.push({ label: "Name", field: "name", type: "string" });
      this.columns.push({
        label: "Created Date",
        field: "created",
        type: "date",
        formatFn: function (value) {
          return value == null ? "" : value;
        },
      });
      this.columns.push({
        label: "Modified Date",
        field: "updated",
        type: "date",
        formatFn: function (value) {
          return value == null ? "" : value;
        },
      });
      this.columns.push({
        label: "Modified By",
        field: "updatedBy",
        type: "string",
        sortable: false
      });
    },
    setPaging() {
      this.paging.pageCount = this.allProducts.count;
      this.paging.pageNumber = this.allProducts.pageNumber + 1;
      this.paging.pageSize = this.allProducts.pageSize;
      this.paging.totalCount = this.allProducts.totalCount;

      this.paging.pageStartNumber =
        this.allProducts.pageNumber === 0
          ? 1
          : this.allProducts.pageNumber * this.allProducts.pageSize + 1;
      this.paging.pageEndNumber =
        Math.floor(this.totalPages) === this.allProducts.pageNumber + 1
          ? this.allProducts.totalCount
          : this.allProducts.pageSize * (this.allProducts.pageNumber + 1);
    },

    mapSortBy() {
      let sort = this.sortBy.toString();

      if (this.sortDesc === true) {
        switch (sort) {
            case "itemNumber":
              sort = "product_no_desc";
              break;
            case "created":
              sort = "newest_to_oldest";
              break;
            case "updatedBy":
              sort = "updated_by_desc";
              break;

              default:
              sort = `${sort}_desc`;
            }
        
      } 
      else {
          switch (sort) {
            case "itemNumber":
              sort = "product_no_asc";
              break;
            case "created":
              sort = "oldest_to_newest";
              break;
            case "updatedBy":
              sort = "updated_by";
              break;
          }
      }


      return sort;
    },
    onSortChange(params) {
      this.sortBy = params[0].field;
      this.sortDesc = params[0].type === "desc" ? true : false;
      this.paging.pageNumber=this.paging.pageNumber - 1;
       this.searchProducts();
      
    },

    async toggleQuickFilter(filter) {
      this.quickFiltersSelected = [];
      
      if (!this.$nucleus.isEmpty(filter)) {
        this.quickFiltersSelected.push(filter);     
      }
      else{
        this.quickFiltersSelected.push("all");
      }
      this.paging.pageNumber = 0;
      await this.searchProducts();
    },

    clearFilters() {
      this.searchTerm = "";
      this.quickFiltersSelected = ["all"];
    },
  },

  async mounted() {
    this.paging.pageNumber = 0;
    await this.searchProducts();
    this.setColumns();
    this.isLoading = false;
    this.clearFilters();
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  background: $admin-white-color;
  font-size: 0.7rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 0.75px solid $admin-neutral-mid-light;
  padding-bottom: 7px;
}

.btn-search:active {
  border: 1px solid $admin-primary-color;
  background: $admin-white-color;
}

thead {
  background-color: $admin-primary-color;
  color: #fff;
}

.icon {
  font-size: 20px;
}

.pencil {
  color: $admin-primary-color;
}

.n-all-products .active {
  color: $admin-green;
}

.new {
  color: $admin-tertiary-light-color;
}

.inactive {
  color: $admin-gray-2;
}

.spanright {
  margin-left: 20px;
}


.n-search-input {
  border: 0.75px solid $admin-neutral-mid-light;
  border-right: 0px;
}

.btn-search {
  background: $admin-white-color;
  font-size: 0.7rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 0.75px solid $admin-neutral-mid-light;
  border-left: 0px;
  padding-bottom: 0px;
}

.btn-search:active {
  border: 1px solid $admin-primary-color;
  background: $admin-white-color;
}

.n-button-filters .btn {
  border-radius: 0;
  text-transform: capitalize;
}

.n-button-filters .active {
  background: $admin-primary-light-color;
  color: $admin-white-color;
  border: 1px solid $admin-primary-light-color;
}


a {
  color: $admin-primary-color;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

hr {
  border-top: 1px solid $gray-2;
}

</style>
