<template>
    <div>
        <button class="btn btn-sm btn-outline-primary mr-3" @click="cancel()">
            Cancel
        </button>
        <span :id="tooltipId" class="d-inline-block" tabindex="0">
            <b-button v-if="isEditable" variant="primary mr-3" class="btn-sm" :disabled="!isValid" @click="save()">
                Save
            </b-button>
            <b-button v-if="!isEditable" variant="primary" class="btn-sm" @click="edit()">
                Edit
            </b-button>
        </span>

        <b-tooltip v-if="!isValid && isEditable" :target="tooltipId">
            {{ tooltipMessage }}
        </b-tooltip>
    </div>
</template>

<script>
    export default {
        name: "ActionButtons",
        props: {
            isValid: Boolean,
            isEditable: Boolean,
            tooltipId: String,
            tooltipMessage: String
        },
        methods: {
            edit() {
                this.$emit('edit');
            },
            cancel() {
                this.$emit('cancel');
            },
            async save() {
                this.$emit('save');
            }
        },
    }
</script>