<template>
  <div>
    <h2>Select product(s) to add to this order</h2>
    <hr />
    <div class="mt-3 mb-3">
      <VueGoodTable
        class="table table-striped"
        :rows="products"
        :columns="columns"
        :responsive="false"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'product'">
            <vue-bootstrap-typeahead
              v-if="products[props.row.originalIndex].isSearchMode"
              type="text"
              :data="productSearchResults"
              v-model="products[props.row.originalIndex].searchTerm"
              :serializer="(s) => s.itemNumber + ' - ' + s.name"
              placeholder="Search for a product by name, product number, etc."
              @hit="setProduct(props.row.originalIndex, $event)"
            >
            <template slot="suggestion" slot-scope="{ data }">
                <span class="n-search-item-number">{{data.itemNumber}}: </span> <span class="n-small">{{data.name}}</span>
            </template>
            </vue-bootstrap-typeahead>
            <span v-else>
              <span class="n-search-item-number">{{ products[props.row.originalIndex].itemNumber }}: </span>
              <span class="n-small">{{ products[props.row.originalIndex].name }}</span>
            </span>
          </span>
          <span v-else-if="props.column.type === 'date'">
            {{ props.formattedRow[props.column.field] | moment(dateFormat) }}
          </span>
          <span v-else-if="props.column.field === 'unitOfMeasure'">
            <span v-if="!products[props.row.originalIndex].isSearchMode">
              {{ products[props.row.originalIndex].quantityPerUnit }} /
              {{ products[props.row.originalIndex].unitOfMeasure }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'quantityPerRecipient'">
            <input
              v-if="!products[props.row.originalIndex].isSearchMode"
              type="text"
              v-model="products[props.row.originalIndex].quantityPerRecipient"
              @keyup="updateQuantityTotal(products[props.row.originalIndex])"
              class="form-control"
            />
          </span>
          <span v-else-if="props.column.field === 'quantityTotal'">
            <input
              type="text"
              v-model="products[props.row.originalIndex].quantityTotal"
              class="form-control"
              readonly
            />
          </span>
          <span v-else-if="props.column.field === 'actions'">
            <a
              v-if="!products[props.row.originalIndex].isSearchMode"
              href="#"
              @click.prevent="deleteProduct(props.row.originalIndex)"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" />
            </a>
            <span v-else></span>
          </span>
        </template>
      </VueGoodTable>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";
import { AdminEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "SelectProducts",
  mixins: [BulkOrdersMixin],
  components: {
    VueGoodTable,
    VueBootstrapTypeahead,
  },
  data() {
    return {
      dateFormat: "MM/DD/YYYY",
      productSearchTerm: null,
      products: [],
      columns: [
        { field: "product", label: "Product", sortable: false, width: "400px" },
        {
          field: "dateOfFirstUse",
          label: "Date of First Use",
          type: "date",
          sortable: false,
          formatFn: function (value) {
            return value == null ? "" : value;
          },
        },
        {
          field: "quarantineDate",
          label: "Quarantine Date",
          type: "date",
          sortable: false,
          formatFn: function (value) {
            return value == null ? "" : value;
          },
        },
        { field: "unitOfMeasure", label: "Unit of Measure", sortable: false },
        {
          field: "quantityPerRecipient",
          label: "Quantity per Recipient",
          sortable: false,
        },
        { field: "quantityTotal", label: "Quantity Total", sortable: false },
        { field: "actions", label: "", sortable: false },
      ],
    };
  },
  computed: {
    productSearchResults() {
      return this.searchProductsResults || [];
    },
  },
  methods: {
    deleteProduct(index) {
      this.products.splice(index, 1);

      if (this.products.length === 0) {
        this.pushEmptyProduct();
      }

      this.updateProducts();
    },
    initListeners() {
      const vm = this;

      this.$eventBus.$on(AdminEvents.BULK_ORDER_CANCELLED, () => {
        vm.initProducts();
        vm.updateProductsAction({
          products: []
        });
      });
    },
    initProducts() {
      this.products = [
        {
          id: null,
          itemNumber: null,
          name: null,
          dateOfFirstUse: null,
          quarantineDate: null,
          quantityPerUnit: null,
          unitOfMeasure: null,
          quantityPerRecipient: 0,
          quantityTotal: 0,
          isSearchMode: true,
          searchTerm: "",
        },
      ];
    },
    pushEmptyProduct() {
      this.products.push({
        id: null,
        itemNumber: null,
        name: null,
        dateOfFirstUse: null,
        quarantineDate: null,
        quantityPerUnit: null,
        unitOfMeasure: null,
        quantityPerRecipient: 0,
        quantityTotal: 0,
        isSearchMode: true,
        searchTerm: "",
      });

      this.$forceUpdate();
    },
    removeListeners() {
      this.$eventBus.$off(AdminEvents.BULK_ORDER_CANCELLED);
    },
    setProduct(index, product) {
      this.products[index] = {
        id: product.id,
        itemNumber: product.itemNumber,
        name: product.name,
        dateOfFirstUse: product.startDisplayDate,
        quarantineDate: product.endOrderDate,
        quantityPerUnit: product.quantityPerUnit,
        unitOfMeasure: product.unitOfMeasure,
        quantityPerRecipient: 0,
        quantityTotal: 0,
        isSearchMode: false,
        searchTerm: "",
      };

      this.updateProducts();
      this.pushEmptyProduct();
    },
    updateProducts() {
      this.updateProductsAction({
        products: this.products.filter((p) => p.id),
      });

      this.resetBulkOrderErrors();
      this.$forceUpdate();
    },
    updateQuantityTotal(product) {
      product.quantityTotal =
        product.quantityPerRecipient *
        product.quantityPerUnit *
        this.selectedRecipients.length;

      this.updateProducts();
    },
  },
  watch: {
    products: {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          const searchTerm = newValue[newValue.length - 1].searchTerm || "";

          if (searchTerm.length > 2) {
            this.searchProducts(searchTerm);
          }
        }
      },
      deep: true,
    },
    selectedRecipients: {
      handler() {
        this.products.forEach((p) => {
          this.updateQuantityTotal(p);
        });
      },
    },
  },
  beforeDestroy() {
    this.removeListeners();
  },
  mounted() {
    this.initListeners();
    this.initProducts();
    this.searchProducts = this.$nucleus.debounce(this.searchProducts, 500);
  },
};
</script>

<style lang="scss" scoped>
.n-search-item-number {
  font-weight: 900;
}
.n-small {
  font-size: 85%
}
</style>
