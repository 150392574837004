const JWT_TOKEN_KEY = "access_token";

class JwtService {
    async getToken() {
        return new Promise((resolve) => {
            resolve(localStorage.getItem(JWT_TOKEN_KEY) || false);
        });
    }

    async getId() {
        return new Promise((resolve) => {
            const jwt = localStorage.getItem(JWT_TOKEN_KEY) || false;

            if (jwt) {
                const payload = jwt.split(".")[1];
                const converted = Buffer.from(payload, "base64");

                const id = JSON.parse(converted.toString()).sub;

                resolve(id);
            }
        });
    }
}

export default new JwtService();
