// TODO: revisit these, remove any unnecessary functions

import Vue from "vue";

export const FilterUtilities = {
    methods: {
        // Returns the number of filters in the provided search that have a value set
        getActiveFilterCount(search) {
            let count = 0;

            for (let i = 0; i < search.filters.length; i++) {
                let filter = search.filters[i];
                // needs review, category should not be part of filters array?
                if (filter.filterId !== 'category') {
                    for (let j = 0; j < filter.values.length; j++) {
                        let value = filter.values[j];
                        if (value && value.length && value.length > 0) {
                            count++;
                            break;
                        }
                    }
                }
            }

            return count;
        },
        // Returns the array of values of the specified filter ID
        getFilterValues(search, filterId) {
            for (let i = 0; i < search.filters.length; i++) {
                if (search.filters[i].filterId == filterId)
                    return search.filters[i].values;
            }

            return [];
        },
        // Returns the array of values of the specified filter ID
        getFirstFilterValue(search, filterId) {
            const values = this.getFilterValues(search, filterId);

            if (values.length > 0)
                return values[0];
            else
                return '';
        },
        // Returns a query string from the specified searchOptions object
        getQueryFromSearchOptions(searchOptions, filters) {
            const query =
            {
                sort: searchOptions.sort,
                page: searchOptions.page,
                perPage: searchOptions.perPage
            };
            let vm = this;
            for (let i = 0; i < filters.length; i++) {
                let filter = filters[i];
                let values = '';

                if (filter.values.length > 0 && filter.values.filter(f => !vm.$nucleus.isEmpty(f)).length > 0) {
                    values += filter.values[0];

                    for (let j = 1; j < filter.values.length; j++) {
                        values += '|' + filter.values[j];
                    }
                }

                if (values.length) {
                    query[filter.filterId] = values;
                }
            }

            return query;
        },
        // Returns a searchOptions object parsed from the provided query string
        getSearchOptionsFromQuery(query) {
            // NOTE: The searchOptions object consists of nothing more than a list of filter IDs with the value(s) selected. This is true for everything, including
            //       category ID and searchOptions term.
            let searchOptions = { filters: [], sort: "" };

            for (let prop in query) {
                // Look for certain predefined props. Others will be evaluated as a filter ID
                switch (prop) {
                    case "sort":
                        searchOptions.sort = query[prop];
                        break;
                    case "page":
                        searchOptions.page = Number(query[prop]);
                        break;
                    case "perPage":
                        searchOptions.perPage = Number(query[prop]);
                        break;
                    default: {
                        let filterId = prop;
                        let values = query[prop].split("|");

                        searchOptions.filters.push({
                            filterId: filterId,
                            values: values
                        });
                    }
                }
            }

            return searchOptions;
        },
        // Sets the value of the specified filter ID
        setFilterValue(searchOptions, filterId, values) {
            const filterExists = searchOptions.filters.some((f) => f.filterId === filterId);
            const filterToApply = {
                filterId: filterId,
                values: values
            };

            if (filterExists) {
                const index = searchOptions.filters.findIndex((f) => f.filterId === filterId);
                Vue.set(searchOptions.filters, index, filterToApply);
            }
            else {
                searchOptions.filters.push(filterToApply)
            }
        }
    }
}
