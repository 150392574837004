import Vue from 'vue';

export default new Vue({
    methods: {
        open(component, props = {}) {
            this.$emit('openModal', { component, props });
        },
        close() {
            this.$emit('closeModal');
        }
    }
})