<template>
<div>
    <div class="row">
        <div class="col-12">
            <b-tabs content-class="mt-3" id="contentManagementHeader">
                <b-tab active>
                    <template v-slot:title>
                        <div class="title-width">
                            <span class="mt-2">{{title}}</span>
                        </div>
                    </template>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "ContentManagementHeader",
    props: {
        title: {
            type: String,
            required:true,
        },
    },
};
</script>

<style scoped>
.title-width {
    max-width: 122px;
}
</style>
