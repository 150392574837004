<template>

  <button class="btn btn-link btnAddresses " @click="tagSelected()">
    <font-awesome-icon
      v-if="tagId == 'Favorite'"
      class="n-favorite"
      :icon="['fas', 'heart']"
    />
    {{ tagName }}
  </button>
</template>
<script>
export default {
  name: "TagFilter",
  props: {
    filterId: {
      type: String,
      required: true,
    },
    tagName: {
      type: String,
      required: false,
    },
    tagId: {
      type: String,
      required: false,
    },
  },
  methods: {
    tagSelected() {
      if (!this.tagId) {
        this.$emit("changed", {});
      } else {
        this.$emit("changed", {
          filterId: this.filterId,
          values: [this.tagId],
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.btnAddresses {
  font-size: 1rem;
  background: none;
  color: $primary-color;
  border: none;
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: normal;
}

.n-favorite {
  color: $favorites-color;
}
</style>
