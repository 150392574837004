<template>
<AdminTemplate>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-5">
                    <h4 class="n-primary">Content Management</h4>
                </div>
            </div>
        </div>
        <div>

        </div>
        <div class="col-12 n-mt-2">
            <ContentManagementHeader :title="title" />
        </div>
        <div class="col-12">
            <ContentManagementDefaultPage v-if="!hasAnnouncements" />
        </div>
        <div :class="{'no-announcents-add-button col-xl-12' : !hasAnnouncements}">
            <div>
                <div class="col-7 pl-0">
                    <div v-if="hasAnnouncements" class="add-button-alignment">
                        <h3 class="n-primary n-mt-head head-width">Homepage Announcement</h3>
                    </div>
                    <div class="add-button-alignment pl-0">
                        <router-link :to="{ name: 'AddEditAnnouncement',query: { id: null, isEditable: true } }" class="badge nav-pill badge-primary mt-3 pl-3 pr-3">Add Announcement</router-link>
                    </div>
                </div>

            </div>
            <div class="col-12" v-if="hasAnnouncements">
                <div>
                    <div class="row margin-bt">
                        <div class="col-12">
                            <div class="row">
                                <AnnouncementFilters :quickFilters="quickFilters" @toggleQuickFilter="toggleQuickFilter" @filterannouncements="filterannouncements" />
                                <div class="col-12 default-announcement mt-n3"> <span id="boot-icon" title="Default Announcement">
                                        <font-awesome-icon :icon="['fa', 'star']" class="mr-2 default-star" /></span>Default Announcement</div>
                                <div class="col-12">
                                    <VueGoodTable :columns="columns" :rows="announcements" :row-style-class='setRowBackground'>
                                        <template slot="table-row" slot-scope="props">
                                            <span v-if="props.column.field === 'actions'" class="text-nowrap">
                                                <font-awesome-icon :icon="['fa', 'star']" v-if="props.row.isDefault" class="mr-2 default-star" />
                                                <button :class="{'btn-margin-left':!props.row.isDefault}" v-if="props.row.isPublished === true" class="btn btn-outline-primary btn-sm mr-3 btn-paddings " @click="updatePublishStatus(props.row.id,false)">Unpublish </button>
                                                <button :class="{'btn-margin-left':!props.row.isDefault}" v-else class="btn btn-outline-primary btn-sm mr-3  btn-paddings publish-margin" @click="updatePublishStatus(props.row.id,true)"> Publish</button>
                                                <div class="d-inline ml-2">
                                                    <router-link :to="{ name: 'AddEditAnnouncement',  query: { id: props.row.id, isEditable: true } }">
                                                        <font-awesome-icon :icon="['fas', 'pencil']" />
                                                    </router-link>
                                                </div>
                                                <div class="d-inline ml-2">
                                                    <a href="#">
                                                        <font-awesome-icon :class="['n-image-icons', 'pointed-cursor','delete-icon']" :icon="['fas', 'trash-alt']" @click="removeAnnouncement(props.row.id)" />
                                                    </a>
                                                </div>
                                            </span>
                                            <span v-else-if="props.column.field === 'name'" class="text-nowrap">
                                                <router-link :id="props.row.id" :title="props.row.id" :to="{
                                        name: 'AddEditAnnouncement',
                                        query: { id: props.row.id, isEditable: 'readonly' },
                                        }">
                                                    {{ props.row.name }}</router-link>
                                            </span>
                                            <span v-if="props.column.field === 'startDate'">
                                                {{
                                                props.row.startDate | moment(dateFormat)
                                             }}</span>
                                            <span v-if="props.column.field === 'endDate'">
                                                {{
                                                props.row.endDate | moment(dateFormat)
                                             }}</span>
                                            <span v-else-if="props.column.field === 'userRoleIds'">
                                                {{isDefaultOrAllRoles(props.row.isDefault,props.row.userRoleIds,allRoles)}}
                                            </span>
                                        </template>
                                    </VueGoodTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</AdminTemplate>
</template>

<script>
import {
    VueGoodTable
} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import {
    mapActions,
    mapGetters
} from "vuex";

import {
    SEARCH_ANNOUNCEMENTS,
    DELETE_ANNOUNCEMENT,
    PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT,
    GET_ALL_ROLES,
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";

import {
    ANNOUNCEMENT_SEARCH_RESULTS_GETTER,
    ANNOUNCEMENT_DELETERESPONSE_GETTER,
    SAVE_ANNOUNCEMENT_ERROR_GETTER,
    ALL_ROLES_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

import AdminTemplate from "@/pages/templates/AdminTemplate.vue";
import ContentManagementHeader from "@/components/admin/content-management/ContentManagementHeader.vue";
import AnnouncementFilters from "@/components/admin/content-management/AnnouncementFilters.vue";
import ContentManagementDefaultPage from "@/components/admin/content-management/ContentManagementDefaultPage.vue";
import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import {
    MessageType
} from "@/nucleus-modules/dd-nucleus-ui/constants.js";
export default {
    name: "ContentManagement",
    components: {
        AdminTemplate,
        ContentManagementHeader,
        ContentManagementDefaultPage,
        VueGoodTable,
        AnnouncementFilters

    },
    props: {
        filterByStatusProp: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dateFormat: "MM/DD/YYYY",
            pageLoad: "",
            announcements: [],
            columns: [{
                    label: "Actions",
                    field: "actions",
                    sortable: false
                },
                {
                    label: "Announcement Title",
                    field: "name",
                    type: "string",
                    width: "11rem"
                },
                {
                    label: "Start Date",
                    field: "startDate",
                    type: "string"
                },
                {
                    label: "End Date",
                    field: "endDate",
                    type: "string"
                },
                {
                    label: "Roles",
                    field: "userRoleIds",
                    type: "string"
                },
            ],
            quickFiltersSelected: [],
            quickFilters: ['All', 'published', 'unpublished'],
            title: "Homepage Announcement",
        };
    },
    methods: {
        ...mapActions({
            searchAnnouncements: SEARCH_ANNOUNCEMENTS,
            deleteAnnouncement: DELETE_ANNOUNCEMENT,
            publishOrUnpublishAnnouncement: PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT,
            getAllRoles: GET_ALL_ROLES,
        }),

        buildRequest() {
            const request = {
                filters: [{
                    "filterId": "published",
                    "values": this.quickFiltersSelected,
                }, ],
                paging: {
                    "pageNumber": 0,
                    "pageSize": 200,
                    "includeTotalCount": true
                }
            };

            return request;
        },

        async sendRequest(request) {
            this.isBusy = true;
            if (request == undefined) {
                request = this.buildRequest();
            }

            await this.searchAnnouncements(request);
            this.announcements = this.announcmentResponse.results;
            this.isBusy = false;
        },

        async removeAnnouncement(id) {
            this.isBusy = true;
            const confirmationBoxService = new ConfirmationBoxService({
                title: "Confirm Delete",
            });

            confirmationBoxService
                .showConfirm(`Are you sure you want to delete announcement?`)
                .then(async (confirm) => {
                    if (confirm) {

                        await this.deleteAnnouncement(id);
                        console.log("error", this.deleteAnnouncmentResponse)
                        let delResponse = this.deleteAnnouncmentResponse;
                        if (delResponse.succeeded) {
                            ToastService.showToast(
                                "Announcement has been deleted successfully.",
                                MessageType.SUCCESS
                            );
                            let request = this.buildRequest();
                            this.sendRequest(request);
                        } else {
                            ToastService.showToast(
                                "The announcement is not deleted successfully..  Please try again.",
                                MessageType.ERROR
                            );
                        }
                    }
                });
            this.isBusy = false;
        },

        async updatePublishStatus(id, isPublish) {
            this.isBusy = true;
            const payload = {
                id: id,
                status: isPublish,
            };
            const confirmationBoxService = new ConfirmationBoxService({
                title: isPublish ? "Confirm Publish" : "Confirm Unpublish",
            });

            confirmationBoxService
                .showConfirm(`Are you sure you want to publish/unpublish announcement?`)
                .then(async (confirm) => {
                    if (confirm) {

                        await this.publishOrUnpublishAnnouncement(payload);

                        if (!this.$nucleus.isEmpty(this.publiStatusResponse)) {
                            ToastService.showErrorToast(
                                "The announcement is not published/Unpublished successfully..  Please try again.",
                            );
                        } else {
                            ToastService.showToast(
                                "Announcement has been published/Unpublished successfully.",
                                MessageType.SUCCESS
                            );
                            let request = this.buildRequest();
                            this.sendRequest(request);
                        }
                    }
                });
            this.isBusy = false;
        },

        setRowBackground(row) {
            return row.isPublished ? 'published' : 'unpublished';
        },
        filterannouncements() {
            this.currentPage = 1;
            var request = this.buildRequest();
            this.sendRequest(request);
        },
        toggleQuickFilter(filters) {

            if (filters[0] === 'All') {
                this.quickFiltersSelected = null;
            } else this.quickFiltersSelected = filters;
            this.currentPage = 1;
            var request = this.buildRequest();
            this.sendRequest(request);
        },
        isDefaultOrAllRoles(isDefault, userRoleIds, allRoles) {
            return isDefault || (userRoleIds.length == allRoles.length) ? 'All Roles' : userRoleIds.map(x => x.roleName).join(', ')
        },
    },

    computed: {
        ...mapGetters({
            announcmentResponse: ANNOUNCEMENT_SEARCH_RESULTS_GETTER,
            deleteAnnouncmentResponse: ANNOUNCEMENT_DELETERESPONSE_GETTER,
            publiStatusResponse: SAVE_ANNOUNCEMENT_ERROR_GETTER,
            allRoles: ALL_ROLES_GETTER,
        }),

        hasAnnouncements() {
            return (this.announcements.length > 0 || !this.$nucleus.isEmpty(this.quickFiltersSelected));
        },
    },

    async mounted() {
        await this.getAllRoles();
        await this.sendRequest({});
    },
};
</script>

<style lang="scss" scoped>
a {
    color: $admin-primary-color;
    cursor: pointer;
}

h2.n-no-users {
    color: $gray-3;
}

.dropdown-menu {
    background: $admin-neutral-lightest;
    min-width: 22rem !important;
    max-height: 25rem;
    overflow-x: auto;
    background: $admin-white-color !important;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.fa-user-friends {
    color: $primary-color;
}

.fa-user-friends:hover {
    color: $primary-dark-color;
    cursor: pointer;
}

.n-disable-user-friends-icon {
    color: $gray-2;
}

.n-disable-user-friends-icon:hover {
    color: $gray-2;
    cursor: default;
}

.n-selected-filters {
    background: $primary-color;
    color: $admin-white-color;
    font-size: 0.875rem;
    padding: 5px 10px;
}

.n-remove-filters {
    color: $admin-white-color;
}

.n-clear-all {
    padding-top: 5px;
}

hr {
    border-top: 1px solid $gray-2;
}

.n-delete-confirm-overlay {
    background: $primary-color;
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
}

.n-delete-confirm-text {
    left: 50%;
    display: block;
    position: fixed;
    top: 40%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.25rem;
}

.thead {
    background: $admin-primary-color;
}

.btn-paddings {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
}

.publish-margin {
    margin-right: 37px !important;
}

.n-mt-head {
    margin-top: -30px;
}

.head-width {
    max-width: 275px;
}

.default-announcement {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 14pt;
}

.margin-bt {
    margin-bottom: 50px;
}

.add-button-alignment {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: bottom;
}

.badge {
    color: $admin-white-color;
}

.default-star {
    font-size: 14pt;
    padding-top: 3px;
}

.no-announcents-add-button {
    display: flex;
    justify-content: center;
}

.fa-star {
    color: $secondary-color;
}

.btn-margin-left {
    margin-left: 30px !important;
}
</style>
