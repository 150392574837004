<template>
  <div class="n-ship-to" v-if="address">
    <div v-if="address.companyName">
      <strong>{{ address.companyName }}</strong>
    </div>
    <strong>
      {{ address.addressee }}
    </strong>

    <div>
        <div>{{ address.addressLine1 }}</div>
        <div>{{ address.addressLine2 }}</div>
        <div>{{ address.addressLine3 }}</div>
        <div>{{ address.addressLine4 }}</div>
        <div>{{ address.city }} {{ address.state }} {{ address.postalCode }}</div>
        <div v-if="address.phoneNumber">
            <strong>Phone Number: </strong>{{ address.phoneNumber }}
        </div>
    </div>
    <div class="n-warning" v-if="address.isDefault">
      <font-awesome-icon :icon="['fas', 'star']" class="n-warning" /> Default
      Shipping Address
    </div>
  </div>
</template>

<script>
export default {
  name: "ShipAddressView",
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
