export const PagingMixin = {
    methods: {
        setPaging(response) {
            this.paging.pageCount = response.count;
            this.paging.pageNumber = response.pageNumber + 1;
            this.paging.pageSize = response.pageSize;
            this.paging.totalCount = response.totalCount;

            const totalPages = this.paging.totalCount / this.paging.pageSize + 1;

            this.paging.pageStartNumber =
                response.pageNumber === 0
                    ? 1
                    : response.pageNumber *
                    response.pageSize +
                    1;
            this.paging.pageEndNumber =
                Math.floor(totalPages) === response.pageNumber + 1
                    ? response.totalCount
                    : response.pageSize * (response.pageNumber + 1);
        }
    }
};
