import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";
import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import {    
    CREATE_CONTACT_ADDRESS,
    GET_ORDER_BY_ID,
    GET_ORDER_DETAILS,
    GET_ORDER_HISTORY,
    GET_SHIPPING_DETAILS,
    PLACE_ORDER,
    SEARCH_ORDERS
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import {
    GET_ORDER_BY_ID_ERRORS_GETTER,
    ORDER_BY_ID_GETTER,
    ORDER_DETAILS_GETTER,
    PLACE_ORDER_ERRORS_GETTER,
    PLACE_ORDER_RESPONSE_GETTER,
    SEARCH_ORDERS_RESULTS_GETTER,
    SHIPPING_DETAILS_GETTER,
    ORDER_HISTORY_GETTER
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
    SET_GET_ORDER_BY_ID_ERRORS,
    SET_GET_ORDER_BY_ID_RESPONSE,
    SET_ORDER_DETAILS,
    SET_ORDER_SEARCH_RESULTS,
    SET_PLACE_ORDER_ERROR,
    SET_PLACE_ORDER_RESPONSE,
    SET_SHIPPING_DETAILS,
    SET_ORDER_HISTORY
} from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

const MODULE_ID = "orders";

const initialState = {
    getOrderByIdError: {},
    orderById: {},
    orderDetails: {},
    placeOrderError: {        
        messages: [],
        insufficientQuantityErrors: []
    },
    placeOrderResponse: {},
    searchOrdersResults: [],
    shippingDetails: {},
    orderHistory:{}
};

export const state = { ...initialState };

export const actions = {    
    async [CREATE_CONTACT_ADDRESS]({ rootGetters }, payload) {
        const id = rootGetters[USER_INFO_GETTER].id;

        const response = await ApiService.post(`api/v1/user/${id}/addresses`, payload);

        return response;
    },
    async [GET_ORDER_BY_ID]({ commit }, payload) {
        commit(SET_GET_ORDER_BY_ID_RESPONSE, {});
        commit(SET_GET_ORDER_BY_ID_ERRORS, {});

        const response = await ApiService.get(`api/v1/order/${payload.orderId}/detail`);

        if (response) {
            commit(SET_GET_ORDER_BY_ID_RESPONSE, response);
        } else {
            commit(SET_GET_ORDER_BY_ID_ERRORS, response);
        }
    },
    async [GET_SHIPPING_DETAILS]({ commit }, payload) {
        const urlPrefix = `api/v1/contact/${payload.id}`;

        const addressesResponse = await ApiService.get(`${urlPrefix}/shipping/addresses`);
        const emailsResponse = await ApiService.get(`${urlPrefix}/emails`);
        const phoneNumbersResponse = await ApiService.get(`${urlPrefix}/phone-numbers`);

        const result = {
            address: addressesResponse.results[0],
            email: emailsResponse.results[0],
            phoneNumber: phoneNumbersResponse.results[0]
        };

        commit(SET_SHIPPING_DETAILS, result);
    },
    async [PLACE_ORDER]({ commit }, payload) {
        commit(SET_PLACE_ORDER_ERROR, {});
        commit(SET_PLACE_ORDER_RESPONSE, {});

        payload.source = "cart";

        const response = await ApiService.post("api/v1/orders", payload);

        if (response.succeeded) {
            commit(SET_PLACE_ORDER_RESPONSE, response);
        } else {
            commit(SET_PLACE_ORDER_ERROR, response);
        }
    },
    async [SEARCH_ORDERS]({ commit }) {

        const response = await ApiService.get("api/v1/user/current/orders");

        if (response.succeeded) {
            commit(SET_ORDER_SEARCH_RESULTS, {
                items: response.results,
                pageNumber: response.pageNumber,
                perPage: response.perPage,
                count: response.count,
                totalCount: response.totalCount
            });
        }
    },
    async [GET_ORDER_DETAILS]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/order/${payload.orderId}/detail`);
        if (response && response.orderNumber) {
            commit(SET_ORDER_DETAILS, response);
        }
    },
    async [GET_ORDER_HISTORY]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/admin/order/${payload.orderId}`); 
        if (response.succeeded) {
            commit(SET_ORDER_HISTORY, response.order); 
        }
    }
};

export const getters = {
    [GET_ORDER_BY_ID_ERRORS_GETTER]: state => {
        return state.getOrderByIdError;
    },
    [ORDER_BY_ID_GETTER]: state => {
        return state.orderById;
    },
    [PLACE_ORDER_ERRORS_GETTER]: state => {
        return state.placeOrderError;
    },
    [PLACE_ORDER_RESPONSE_GETTER]: state => {
        return state.placeOrderResponse;
    },
    [SEARCH_ORDERS_RESULTS_GETTER]: state => {
        return state.searchOrdersResults;
    },
    [ORDER_DETAILS_GETTER]: state => {
        return state.orderDetails;
    },
    [SHIPPING_DETAILS_GETTER]: state => {
        return state.shippingDetails;
    },
    [ORDER_HISTORY_GETTER]: state => {
        return state.orderHistory;
    }
};

export const mutations = {
    [SET_GET_ORDER_BY_ID_ERRORS]: (state, getOrderByIdResponse) => {
        state.getOrderByIdError = getOrderByIdResponse;
    },
    [SET_GET_ORDER_BY_ID_RESPONSE]: (state, getOrderByIdResponse) => {
        state.orderById = getOrderByIdResponse;
    },
    [SET_ORDER_SEARCH_RESULTS]: (state, searchOrdersResults) => {
        state.searchOrdersResults = searchOrdersResults;
    },
    [SET_ORDER_DETAILS]: (state, orderDetails) => {
        state.orderDetails = orderDetails;
    },
    [SET_PLACE_ORDER_ERROR]: (state, placeOrderResponse) => {
        state.placeOrderError = placeOrderResponse;
    },
    [SET_PLACE_ORDER_RESPONSE]: (state, placeOrderResponse) => {
        state.placeOrderResponse = placeOrderResponse;
    },
    [SET_SHIPPING_DETAILS]: (state, shippingDetails) => {
        state.shippingDetails = shippingDetails;
    },
    [SET_ORDER_HISTORY]: (state, orderHistory) => {
        state.orderHistory = orderHistory;
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
