<template>
  <div>
    <div v-if="isLoading"></div>
    <div v-else class="row">
      <div class="col-12">
        <VueDropzone
          id="imageDropZone"
          ref="dropzone"
          :options="dropzoneMediaOptions"
          :useCustomSlot="true"
          @vdropzone-upload-progress="
            (file, progress, bytesSent) =>
              showProgress(file, progress, bytesSent, 'from_dz1')
          "
          @vdropzone-file-added="whenFileAdded"
          @vdropzone-success="(file, response) => uploadSuccess(file, response)"
          @vdropzone-error="(file, err) => uploadError(file, err)"
          @vdropzone-sending="beforeSendingFormData"
          @vdropzone-drop="whenFilesDropped"
          @vdropzone-queue-complete="whenQueueCompleted"
        >
          <div class="dropzone-custom-content">
            <div>
              <p class="dropzone-custom-title">Drop files to upload</p>
              <p>Or</p>
            </div>
            <div class="btn btn-outline-primary mr-3">Select Files</div>
          </div></VueDropzone
        >
        <div>
          <p class="n-center-aligned n-notes pt-3">
            Accepted file formats are .jpg and .png.
          </p>
          <p class="n-center-aligned n-notes">
            Recommended size is {{ imageConfig.preferredPixelWidth }} pixels
            wide.
          </p>
          <p class="n-center-aligned n-notes">
            Min width size of {{ imageConfig.minimumPixelWidth }} pixels to max
            width of {{ imageConfig.maximumPixelWidth }} pixels.
          </p>
        </div>
      </div>
    </div>
    <div v-show="false" id="imageDropFileList"  class="row">
      <hr />
      <div v-if="hasFiles" class="col-12">
        <hr class="mt-4 mb-4" />
        <div><p class="n-product-section-heads ml-3 mt-3">Media Upload</p></div>
        <div>
          <div
            v-for="(file, index) in fileList"
            :key="file.fileId"
            :class="[
              (index + 1) % 2 == 0 ? 'n-even-row' : 'n-odd-row',
              'n-media-upload',
              'p-3',
            ]"
          >
            <div class="col-1">
              <font-awesome-icon
                :icon="['fa', 'file-image']"
                size="2x"
              ></font-awesome-icon>
            </div>
            <div class="col-5 pt-1">
              {{ file.name }}
            </div>
            <div class="col-4 error">
              <template v-if="file.status && file.status != 'error'">
                <b-progress
                  :value="file.progress"
                  show-progress
                  height="25px"
                  class="w-200 mb-2"
                ></b-progress>
              </template>
              <template v-else>
                <span>{{ file.errorMessage }}</span>
              </template>
              <span v-if="file.uploadMessage">{{ file.uploadMessage }}</span>
            </div>
            <div class="col-2">
              <font-awesome-icon
                v-if="file.statusIcon"
                :icon="['fa', file.statusIcon]"
                :style="{ color: file.iconColor }"
                size="2x"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div class="text-right mr-3 pt-2">
          {{ totalUploadedFiles }} of {{ fileList.length }} uploaded
        </div>
        <div class="text-right mr-3 pt-2">
          <button @click.prevent="removeAllFiles()" class="btn btn-primary">
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import JwtService from "@/nucleus-modules/dd-nucleus-ui/services/jwt.service.js";
import {
  PRODUCT_FOR_EDIT_GETTER,
  PRODUCT_PROPERTY_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { MediaTypes } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import {
  AdminEvents,
  MediaStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "ImageDragDrop",
  components: {
    VueDropzone,
  },
  props: {
    imageConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileList: [],
      isLoading: true,
      newMediaList: [],
      showUpload: false,
      dropzoneMediaOptions: {},
    };
  },
  computed: {
    ...mapGetters({
      getProductProperty: PRODUCT_PROPERTY_GETTER,
      product: PRODUCT_FOR_EDIT_GETTER,
    }),
    hasFiles() {
      return this.fileList && this.fileList.length > 0;
    },
    totalUploadedFiles() {
      if (this.hasFiles) {
        return this.fileList.filter((fl) => fl.status === MediaStatus.SUCCESS)
          .length;
      } else {
        return 0;
      }
    },
  },
  async created() {},
  mounted() {
    JwtService.getToken().then((result) => {
      this.dropzoneMediaOptions = {
        url: "/api/v1/admin/product-media/upload",
        thumbnailWidth: this.imageConfig.thumbnailWidth,
        addRemoveLinks: false,
        previewsContainer: false,
        autoProcessQueue: true,
        acceptedFiles: ".jpeg,.jpg,.png",
        dictDefaultMessage:
          "<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p><p class='form-text'>Allowed Files: .jpg, .jpeg, .png</p>",
        dictInvalidFileType:
          "File upload has failed. File format not accepted. Acceptable file formats are .png and .jpg.",
        headers: {
          Authorization: `Bearer ${result}`,
        },
      };

      this.isLoading = false;
    });
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
    beforeSendingFormData(file, xhr, formData) {
      formData.append("productId", this.product.id);
      formData.append("fileName", file.name);
    },
    populateResponse(fileProps, response) {
      if (fileProps.upload && response) {
        const productMedia = {
          mediaName: fileProps.upload.filename,
          mediaSize: fileProps.size,
          mediaType:
            fileProps.type.indexOf("image") >= 0
              ? MediaTypes.IMAGE
              : MediaTypes.VIDEO,
          url: response.url,
          thumbnailImageUrl: response.thumbnailImageUrl,
          mediaId: response.mediaId,
          uploadMessage: response.uploadMessage,
        };

        this.newMediaList.push(productMedia);
      }
    },
    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles();
      this.fileList = [];
    },
    showProgress(file, progress) {
      progress = Number(progress).toFixed(0);
      this.updateFileList(file.upload.uuid, "progress", progress);
    },
    updateFileList(fileId, key, value, uploadMessage, err) {
      const index = this.fileList.findIndex((fl) => fl.id === fileId);
      const mediaItem = this.fileList[index];

      mediaItem[key] = value;

      if (mediaItem.status === MediaStatus.SUCCESS) {
        mediaItem["statusIcon"] = "check";
        mediaItem["iconColor"] = "green";
      } else if (
        mediaItem.status === MediaStatus.CANCELLED ||
        mediaItem.status === MediaStatus.ERROR
      ) {
        mediaItem["statusIcon"] = "times";
        mediaItem["iconColor"] = "red";
      } else {
        mediaItem["statusIcon"] = "spinner";
        mediaItem["iconColor"] = "green";
      }

      mediaItem.uploadMessage = uploadMessage;
      mediaItem.errorMessage = err;

      this.fileList.splice(index, 1, mediaItem);
    },
    updateFileStatus(file, uploadMessage, err) {
      this.updateFileList(
        file.upload.uuid,
        "status",
        file.status,
        uploadMessage,
        err
      );
    },
    uploadError(file, err) {
      this.updateFileStatus(file, null, err.messages[0].debugMessage);
    },
    uploadSuccess(file, response) {
      if (response.succeeded) {
        this.updateFileStatus(
          file,
          response.messages.length > 0
            ? response.messages[0].debugMessage
            : null
        );
        this.populateResponse(file, response);
      } else {
        file.status = "error";
        let error;

        if (response.messages && response.messages.length > 0) {
          error = response.messages[0].debugMessage;
        } else {
          error = "An unexpected error occurred during the upload.";
        }

        this.uploadError(file, error);
      }
    },
    whenFileAdded(file) {
      if (!this.showUpload) {
        this.showUpload = true;
        this.whenFilesDropped();
      }

      this.fileList.push({
        name: file.name,
        removeLink: file._removeLink,
        id: file.upload.uuid,
        progress: "0",
        accepted: file.accepted,
        status: file.status,
      });
    },
    whenFilesDropped() {
      this.showUpload = true;
      this.$scrollTo("#imageDropFileList", 500, { easing: "ease-in-out" });
    },
    async whenQueueCompleted() {
      if (this.newMediaList && this.newMediaList.length > 0) {
        this.showUpload = false;
        this.$eventBus.$emit(AdminEvents.PRODUCT_IMAGE_UPLOADED);
      }
    },
  },
};
</script>

<style lang="scss">
.vue-dropzone {
  border: 3px dashed $gray-2;
}

.vue-dropzone:hover {
  border: 3px dashed $neutral-mid;
}

.dropzone-custom-title {
  font-size: 22px;
}

.n-center-aligned {
  text-align: center;
}

.n-media-upload {
  display: flex;
}

.n-notes {
  padding-top: 0px;
  margin-bottom: 6px;
}

.progress-bar {
  background-color: $primary-color;
}
</style>
