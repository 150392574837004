<template>
  <div>
    <h5 class="n-form-section-header">Item Tags</h5>
    <div v-for="tag in tags" :key="tag.name">
      <b-form-checkbox v-model="tag.selected" :id="tag.name" :disabled="disableTags">{{
        tag.name
      }}</b-form-checkbox>
      <div v-if="tag.selected" class="mt-1 ml-2">
        <label for="expireDate" class="n-form-labels">
          {{ tag.name }} Tag Expiration Date
        </label>
        <b-form-datepicker :disabled="disableTags"
          class="mb-2"
          v-model="tag.expireDate"
          today-button
          reset-button
          locale="en"
          :date-format-options="dateFormatOptions"
          @change="onExpireDateChanged(tag)"
        ></b-form-datepicker>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { PRODUCT_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
  SET_IS_PRODUCT_EDIT_DIRTY,
  SET_PRODUCT_PROPERTY,
    } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { defaultNewTagExpDays } from "@/companyAppConfig";

export default {
  name: "ProductTags",
  props: {
    availableTags: {
      type: Array,
      required: true,
    },
    disableTags: {
      type: Boolean,
      required: false,
      default:false
    }
  },
  data() {
    return {
      firstWatch: true,
      tags: [],
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
    };
  },
  computed: {
    ...mapGetters({
      product: PRODUCT_FOR_EDIT_GETTER,
    })
  },
  methods: {
    ...mapMutations({
      setIsProductEditDirty: SET_IS_PRODUCT_EDIT_DIRTY,
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
    setTags() {
      const vm = this;
        if (this.availableTags && this.availableTags.length > 0) {
        this.availableTags.forEach((at) => {
          const existing = vm.product.tags.find((t) => t.tagName === at.name);

          vm.tags.push({
            name: at.name,
            selected: !!existing,
              expireDate: existing ? existing.expireDate : this.$moment().add(defaultNewTagExpDays, 'days').format("YYYY-MM-DD"),
          });
        });
      }
    },
    updateProductTags() {
      const allSelectedTags = this.tags.filter((t) => t.selected);
      const updatedTagArray = [];

      if (allSelectedTags && allSelectedTags.length > 0) {
        allSelectedTags.forEach((t) => {
          updatedTagArray.push({
            tagName: t.name,
            expireDate: t.expireDate === "" ? null : t.expireDate,
          });
        });
      }

      this.setProductProperty({ name: "tags", value: updatedTagArray });
    },
  },
  watch: {
    tags: {
      deep: true,
      handler: function() {
        this.updateProductTags();
        
        if (this.firstWatch) {
          this.setIsProductEditDirty(false);
          this.firstWatch = false;
        }
      },
    }
  },
  mounted() {
    this.setTags();
  },
};
</script>

<style lang="scss" scoped></style>
