<template>
  <div>
    <AuthenticationWrapper :isAuthenticated="showIconToAuthUsersOnly">
      <FavoriteIcon
        :isFavorite="isFavorite"
        :showText="showText"
        :addFavoriteTextId="addFavoriteTextId"
        :removeFavoriteTextId="removeFavoriteTextId"
        @toggle="toggle"
      />
    </AuthenticationWrapper>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  ADD_FAVORITE_ADDRESS,
  REMOVE_FAVORITE_ADDRESS,
} from "@/store/storefront/actions.type.js";
import FavoriteIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteIcon.vue";
import AuthenticationWrapper from "@/nucleus-modules/dd-nucleus-ui/components/AuthenticationWrapper.vue";
import { TagNames } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";

export default {
  name: "FavoriteAddressIcon",
  components: {
    FavoriteIcon,
    AuthenticationWrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isFavorite: {
      type: Boolean,
      required: true,
    },
    showText: {
      type: Boolean,
      required: false,
    },
    addFavoriteTextId: {
      type: String,
      required: false,
    },
    removeFavoriteTextId: {
      type: String,
      required: false,
    },
    showIconToAuthUsersOnly: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    ...mapActions({
      add: ADD_FAVORITE_ADDRESS,
      remove: REMOVE_FAVORITE_ADDRESS,
    }),

    async toggle() {
      if (this.isFavorite) {
        await this.remove({
          addressId: this.id,
          tagName: TagNames.FAVORITE,
        });
      } else {
        await this.add({
          addressId: this.id,
          tagName: TagNames.FAVORITE,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
