import addToCartError from "./data/add-to-cart-error.json";
import categories from "./data/categories.json";
import emptySuccess from "./data/empty-success.json";
import omniSearch from "./data/omni-search.json";
// import productDetail from "./data/product-detail.json";
// import products from "./data/products.json";
import searchResults from "./data/search-results.json";
import siteStartup from "./data/site-startup.json";
import adminSiteStartup from "./data/admin-site-startup.json";
import adminProducts from "./data/admin-products.json";
import adminProductDetail from "./data/admin-product-detail.json";
import adminCategories from "./data/admin-categories.json";
import adminRoles from "./data/admin-roles.json";
import productMedia from "./data/product-media.json";

const INVALID_ENDPOINT_MESSAGE = "Invalid endpoint";

const emulateFetch = async (mockData, delay = 2 * 1000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(mockData);
        }, delay);
    });
};

const emulateHttpError = async (error, delay = 2 * 1000) => {
    return new Promise((reject) => {
        setTimeout(() => {
            reject(error);
        }, delay);
    });
};

/* eslint-disable no-unused-vars */
class MockHttpService {
    async get(endpoint) {
        if (endpoint.includes("ui/new-visit")) {
            return await emulateFetch(siteStartup);
        }
        else if (endpoint.includes("store/error-test")) {
            return await emulateHttpError({ response: { statusCode: 500, statusText: "Mocked http error" } });
        }
        else if (endpoint.includes("store/user/get-categories")) {
            return await emulateFetch(categories);
        }
        else if (endpoint.includes("store/omnisearch")) {
            return await emulateFetch(omniSearch, .2);
        }
        else if (endpoint.includes("admin/site-startup")) {
            return await emulateFetch(adminSiteStartup);
        }
        else if (endpoint.includes("store/admin/productMedia/get-media-item")) {
            return await emulateFetch(productMedia);
        }
        else if (endpoint.includes("store/admin/product/detail")) {
            return await emulateFetch(adminProductDetail);
        }
        else if (endpoint.includes("store/admin/product")) {
            return await emulateFetch(adminProducts);
        }
        else if (endpoint.includes("admin/categories")) {
            return await emulateFetch(adminCategories);
        }
        else if (endpoint.includes("admin/roles")) {
            return await emulateFetch(adminRoles);
        }
        else {
            return new Promise().reject(INVALID_ENDPOINT_MESSAGE);
        }
    }

    async post(endpoint, params) {
        switch (endpoint) {
            case "search/products": {
                if (params) {
                    if (params.sort) {
                        return await emulateFetch(searchResults);
                    }
                    else if (params.page && params.perPage) {
                        let data = searchResults;

                        if (params.page !== 1) {
                            const items = searchResults.results.slice((params.page - 1) * params.perPage, params.page * params.perPage);

                            data.results = items;
                        }

                        return emulateFetch(data);
                    }
                }
                else {
                    return await emulateFetch(searchResults);
                }
            }
            break;
            case "store/cart":
                if (params) {
                    if (params.success) {
                        return emulateFetch(emptySuccess);
                    }
                    else if (params.error) {
                        return emulateFetch(addToCartError);
                    }
                }
                break;
            case "store/favorites":
                return await emulateFetch(emptySuccess);
            case "store/admin/product":
                return await emulateFetch(adminProductDetail);
            default:
                return new Promise().reject(INVALID_ENDPOINT_MESSAGE);
        }
    }
}
/* eslint-enable no-unused-vars */

export default new MockHttpService();
