<template>
  <div>
    <h4 class="mb-3">Inventory</h4>
    <div class="row">
      <div class="col-4">
        <div>
          <div class="form-group">
            <label class="n-form-labels"
              >Available Quantity
              <span class="text-muted">(optional)</span></label
            >
          </div>
          <div>
            <label class="ml-1">{{ quantityAvailable }}</label>
          </div>
        </div>
        <div class="form-group mb-3">
          <label class="n-form-labels">Backorders</label>
          <b-form-checkbox
            v-model="allowBackorders"
            name="allowBackorders"
            :disabled="true"
          >
            Allow Backorders</b-form-checkbox
          >
        </div>
        <div class="form-group">
          <label class="n-form-labels">Out of Stock Message</label>
          <textarea
            v-model.trim="$v.outOfStockMessage.$model"
            rows="3"
            :readonly="true"
            class="form-control"
          ></textarea>
        </div>
        <div v-if="$v.outOfStockMessage.$invalid" class="n-error">
          <span v-if="!$v.outOfStockMessage.required"
            >An "Out of Stock" message is required.</span
          >
          <span v-if="!$v.outOfStockMessage.minLength"
            >The "Out of Stock" message must have at least
            {{ $v.outOfStockMessage.$params.minLength.min }} characters.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import {
  PRODUCT_FOR_EDIT_GETTER,
  PRODUCT_PROPERTY_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

const ATTRIBUTE_NAME = "Inventory";

export default {
  name: "InventoryManagement",
  props: {
    defaultOutOfStockMessageText: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getProductProperty: PRODUCT_PROPERTY_GETTER,
      product: PRODUCT_FOR_EDIT_GETTER,
    }),
    allowBackorders: {
      get() {
        return this.getProductProperty("allowBackorders", ATTRIBUTE_NAME);
      },
      set(value) {
        this.setProductProperty({
          name: "allowBackorders",
          value: value,
          attribute: ATTRIBUTE_NAME,
        });
      },
    },
    outOfStockMessage: {
      get() {
        const val = this.getProductProperty(
          "outOfStockMessageTest",
          ATTRIBUTE_NAME
        );
        if (!val || val === "") {
          return this.defaultOutOfStockMessageText;
        } else {
          return this.getProductProperty(
            "outOfStockMessageText",
            ATTRIBUTE_NAME
          );
        }
      },
      set(value) {
        this.setProductProperty({
          name: "outOfStockMessageText",
          value: value,
          attribute: ATTRIBUTE_NAME,
        });
      },
    },
    quantityAvailable: {
      get() {
        return this.getProductProperty("quantityAvailable", ATTRIBUTE_NAME);
      },
    },
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
  },
  created() {
    this.outOfStockMessage = this.defaultOutOfStockMessageText;
  },
  mounted() {
    this.setProductProperty = this.$nucleus.debounce(
      this.setProductProperty,
      200
    );
  },
  validations: {
    outOfStockMessage: {
      required,
      minLength: minLength(4),
    },
  },
};
</script>

<style lang="scss" scoped></style>
