<template>
    <div class="n-order-tabs">
        <b-tabs content-class="mt-3"
                justified>
            <b-tab active>
                <template v-slot:title>
                    Products
                </template>
                <template>
                    <ProductTab :isPricingEnabled="isPricingEnabled" />
                </template>
            </b-tab>
            <b-tab>
                <template v-slot:title>
                    Shipping
                </template>
                <template>
                    <ShippingTab />
                </template>
            </b-tab>
            <b-tab>
                <template v-slot:title>
                    Activity
                </template>
                <template>
                    <ActivityTab />
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import ProductTab from "@/components/admin/order/ProductTab.vue";
    import ShippingTab from "@/components/admin/order/ShippingTab.vue";
    import ActivityTab from "@/components/admin/order/ActivityTab.vue";

    export default {
        name: 'OrderTabs',
        components: {
            ProductTab,
            ShippingTab,
            ActivityTab,
        },
        props: {
            isPricingEnabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>