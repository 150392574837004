<template>
  <div class="row">
    <div class="col-4 col-md-2">
      <router-link :to="destinationUrl">
        <img :src="thumbnailImageUrl" :name="addOnItem.name" :alt="addOnItem.name" class="img-fluid" />
      </router-link>
    </div>
    <div class="col-8 col-md-5">
      <span class="badge badge-primary add-on-badge">Add-on item</span>

      <router-link :to="destinationUrl" class="d-block n-product-name mb-2">
        {{ addOnItem.name }}</router-link>
      <div>{{ addOnItem.itemNumber }}</div>

    </div>
    <div class="col-12 col-md-2">
    </div>
    <div class="col-8 offset-4 col-md-3 offset-md-0">
      <div class="row">
        <div class="col-8 pr-1">
          <button class="btn btn-primary btn-block" @click.prevent="addItemToCart(productDetail)">
            Add to Cart
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 pr-1 font-weight-bold">
          Quantity:
        </div>

        <div class="col-12">
          <label class="mt-1">
            <span>{{addOnItemQuantity}}</span>
            <template v-if="isUnitOfMeasureAvailable(addOnItem)">
              <UoM :uom="getInventory(addOnItem).unitOfMeasure" :unitQuantity="getInventory(addOnItem).quantityPerUnit">
              </UoM>
            </template>
          </label>
        </div>
      </div>
    </div>
    <div v-if="showSeparator" class="col-12 mt-2 mb-2">
      <hr />
    </div>
  </div>
</template>

<script>

import UoM from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";
import { productMixins } from "@/mixins/productMixin.js";
import { CartMixin } from "@/nucleus-modules/dd-nucleus-storefront/mixins/CartMixin.js";

export default {
  name: "AddOnItem",
  mixins: [productMixins, CartMixin],
  components: {
    UoM,
  },
  props: {
    cartId: {
      type: String,
      required: true,
    },
    destinationUrl: {
      type: String,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
    addOnItem: {
      type: Object,
      required: true,
    },
    noImageAvailableUrl: {
      type: String,
      required: true,
    },
    showSeparator: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {

  },
  computed: {

    thumbnailImageUrl() {
      if (this.addOnItem && this.addOnItem.thumbnailImageUrl) {
        return this.addOnItem.thumbnailImageUrl;
      } else {
        return this.noImageAvailableUrl;
      }
    },
    addOnItemQuantity() {
      if (this.addOnItem && this.addOnItem.attributes && this.addOnItem.attributes.Addon) {
        return this.addOnItem.attributes.Addon.quantity;
      } return 1;
    },
  },
  methods: {
    async addItemToCart() {
      await this.addToCart(this.cartId, this.addOnItem, this.addOnItemQuantity);
    },
  },
};
</script>

<style lang="scss" scoped>
.n-product-name {
  margin-top: 10px;
}

 .btn-primary {
   margin-bottom: 15px;
 }

 @media (max-width: 576px) {
  button.btn {
    padding: 7px;
  }
}
</style>
