<template>
  <div v-if="hasCategories">
    <template  v-for="category in categories">
    <CategoryItem
      :key="category.id"
      :category="category"
      :level="0"
      :filterId="filterId"
      :selectedCategoryId="selectedCategoryId"
      :selectedParentCategory="selectedParentCategory"
    ></CategoryItem>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterUtilities } from "@/nucleus-modules/dd-nucleus-ui/mixins/FilterUtilities.js";
import { ALL_CATEGORIES_ID_GETTER, CATEGORY_ROOT_GETTER, FLATTENED_CATEGORIES_GETTER, } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import CategoryItem from "@/nucleus-modules/dd-nucleus-storefront/components/CategoryItem.vue";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
export default {
  name: "CategoryFilter",
  mixins: [FilterUtilities],
  props: {
    filterId: {
      type: String,
      required: true,
    }
  },
  components: {
    CategoryItem,
  },
  data() {
    return {
      UiEvents:Object.freeze(UiEvents)
    };
  },
  computed: {
    ...mapGetters({
       allCategoriesId: [ALL_CATEGORIES_ID_GETTER],
       allCategories: [CATEGORY_ROOT_GETTER],
       flattenedCategories: FLATTENED_CATEGORIES_GETTER,
    }),
    categories() {
      return this.getCategories()
    },
    hasCategories(){
      return this.categories && this.categories.length;
    },
    selectedCategoryId(){
      let categoryId = this.$route.query.category;
      if(categoryId){
        return categoryId;
      } 
      else {
        return this.allCategoriesId;
      }
    },
    selectedCategory(){
      let result = {
        id:"",
        parentId:"",
        level:0
      };
      if (this.flattenedCategories && this.flattenedCategories.length) {
            const selectedCategoryId = this.$route.query.category;
            const category = this.flattenedCategories.find(
                (c) => c.id === selectedCategoryId
            );
            if (category) {
                result = category;
            }
        } 
        return result;
      },
      selectedParentCategory() {
        return this.getParentCategoryIds();
      }
    },
  methods: {
    hasChildren(category) {
      return category.children && category.children.length > 0;
    },
    getCategories() {
      let categories = this.allCategories;
      
      if(categories && categories.length){
        const allCategory = {
          name: "All Products",
          id: this.allCategoriesId,
          children: []
        }
        let newCategories = [allCategory, ...categories];
          newCategories.map(c => {
          c.opened = false;
        });
        return newCategories;
      }
      return []
    },
    getParentCategoryIds() {
      let parentIds = [];
      this.getParentCategory(this.selectedCategoryId, parentIds);
      return parentIds;
    },
    getParentCategory(selectedCategoryId, parentIds) {
      if (this.flattenedCategories && this.flattenedCategories.length) {
          let category = this.flattenedCategories.find(
                (c) => c.id === selectedCategoryId
            );
            if (category) {
              if(category.parentId){
                  parentIds.push( { id: category.parentId});
                  this.getParentCategory(category.parentId, parentIds);
                }
            }
      }
    }
  },
};
</script> 

<style lang="scss" scoped></style>
