<template>
  <div>
    <h6 class="n-form-section-header">{{ displayedTitle }}</h6>
    <dl>
        <dd v-if="address.addressLine1">
            {{ address.companyName }} <br />
            {{ address.addressee }} <br />
            {{ address.addressLine1 }} <br />
            <span v-if="address.addressLine2">
                {{ address.addressLine2 }} <br />
            </span>
            {{ address.city }} {{ address.state }} {{ address.postalCode }} <br />
            {{ address.countryCode }}
        </dd>
      <dd v-else>No {{ displayedTitle }}</dd>
    </dl>
  </div>
</template>

<script>
import { AddressType } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "UserAddressViewOnly",
  props: {
    title: {
      type: String,
    },
    address: {
      type: Object,
      required: false,
    },
  },
  computed: {
    displayedTitle() {
      if (this.title && this.title !== "") return this.title;

      switch (this.address.addressType) {
        case AddressType.BILLING:
          return "Billing Address";
        case AddressType.SHIPPING:
          return "Shipping Address";
        default:
          return "Address";
      }
    },
  },
};
</script>
