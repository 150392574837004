<template>
    <PageTemplate>
        <div v-if="isLoading"></div>
        <div v-else>
            <div class="row">
                <div class="col-md-6">
                    <h3 class="n-back-link">
                        <router-link to="/shopping-cart">
                            <font-awesome-icon icon="chevron-left" /> Back to Shopping Cart
                        </router-link>
                    </h3>
                </div>
                <div class="col-md-6 text-right">
                    <h2>Checkout</h2>
                </div>
            </div>
            <div id="shippingAddressSection">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <Headline title="Shipping Address"
                                      linkText="Change"
                                      v-on:on-click="editAddress"></Headline>
                        </div>
                    </div>
                </div>

                <div class="row px-4 n-hide-on-mobile">
                    <div class="col-12 mb-3" v-show="defaultView">
                        <div>
                            <ShipAddressView :address="selectedAddress"></ShipAddressView>
                        </div>
                        <div class="mt-3" v-show="defaultView && !shipMethodView">
                            <button type="button"
                                    class="btn btn-outline-primary mr-3"
                                    @click="navigate('cancel')">
                                Back
                            </button>
                            <button id="nextButton1"
                                    type="button"
                                    class="btn btn-primary"
                                    @click="navigate('shippingMethod')">
                                Next
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row px-4 n-hide-on-desktop">
                    <div class="col-12 mb-3">
                        <div>
                            <ShipAddressView :address="selectedAddress"></ShipAddressView>
                        </div>
                        <div class="mt-3" v-show="defaultView && !shipMethodView">
                            <button type="button"
                                    class="btn btn-outline-primary mr-3"
                                    @click="navigate('cancel')">
                                Back
                            </button>
                            <button id="nextButton1"
                                    type="button"
                                    class="btn btn-primary"
                                    @click="navigate('shippingMethod')">
                                Next
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row px-4 justify-content-between n-hide-on-mobile"
                     v-show="selectAddressView">
                    <div class="col-6 mb-3">
                        <h4>
                            {{ $t("shippingInformation.selectedShippingAddressHeadline") }}
                            <a v-show="!selectedAddress.id" href="#" @click="editNewAddress">
                                <font-awesome-icon :icon="['fas', 'pencil']"></font-awesome-icon>
                            </a>
                        </h4>
                        <ShipAddressView :address="selectedAddress"></ShipAddressView>
                    </div>
                    <div class="col-6 mb-3" v-show="!isDefaultShipAddressSelected">
                        <div class="float-right">
                            <!-- TODO : Need to clean up different version of address components, its confusing-->
                            <AddressTile :addressList="defaultShipAddress"
                                         :isCheckout="true"
                                         @selected="useDefaultShipAddress"
                                         :showFavorite="false"
                                         :selectText="defaultAddressSelectText">
                            </AddressTile>
                        </div>
                    </div>
                </div>
                <div class="row n-mobile-view-parent">
                    <div class="col-12 mb-3 n-hide-on-desktop" v-show="selectAddressView">
                        <div class="float-left n-mobile-view-child"
                             v-show="!isDefaultShipAddressSelected">
                            <!-- TODO : Need to clean up different version of address components, its confusing-->
                            <AddressTile :addressList="defaultShipAddress"
                                         :isCheckout="true"
                                         @selected="useDefaultShipAddress"
                                         :showFavorite="false"
                                         :selectText="defaultShippingAddressSelectText">
                            </AddressTile>
                        </div>
                    </div>
                </div>

                <div class="row px-4">
                    <div class="col-12 mb-3"
                         v-show="selectAddressView && !shipMethodView">
                        <button type="button"
                                class="btn btn-outline-primary mr-3"
                                @click="navigate('shippingAddress')">
                            Cancel
                        </button>
                    </div>
                </div>

                <div class="row px-4 mt-4" v-show="selectAddressView">
                    <div class="col-12">
                        <label>Looking for a different address?</label>
                        <b-tabs active-tab-class="n-crm-address-tab"
                                nav-class="n-crm-address-nav">
                            <b-tab @click="tabClicked(0)" :active="currentTab === 0">
                                <template v-slot:title>
                                    CRM Addresses
                                </template>
                                <CRMAddressBook :isCheckout="true"
                                                class="pt-2"
                                                v-on:crm-address-selected="crmAddressSelected">
                                </CRMAddressBook>
                            </b-tab>
                            <b-tab @click="tabClicked(1)" :active="currentTab === 1">
                                <template v-slot:title>
                                    Add New Address
                                </template>
                                <h3 class="pt-3 pl-3">
                                    {{ $t("shippingInformation.enterShippingAddressHeadline") }}
                                </h3>
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <AddressComponent :address.sync="newAddress"
                                                          :statesList="states"
                                                          @updateValidity="updateNewAddressValidity"
                                                          class="pl-3 pt-3">
                                        </AddressComponent>
                                        <div class="form-group pl-3 pt-3">
                                            <label class="n-form-labels" for="isHCP">
                                            Is this an HCP Address?
                                            </label>
                                            <input type="checkbox" v-model="newAddress.isHCP" class="form-control n-isHCP" id="isHCP"/>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>

                <div class="row px-4">
                    <div class="col-12 mt-3"
                         v-show="selectAddressView && !shipMethodView">
                        <button type="button"
                                class="btn btn-outline-primary mr-3"
                                @click="navigate('cancelShippingAddress')">
                            Cancel
                        </button>
                        <button id="nextButton3"
                                type="button"
                                class="btn btn-primary"
                                :disabled="!isValidAddress"
                                v-show="currentTab == 1"
                                @click="navigate('saveAddress')">
                            Save
                        </button>
                    </div>
                </div>

                <div class="row px-4 mt-4" v-show="editNewAddressView">
                    <div class="col-6 mb-3">
                        <h3 class="pt-2">
                            {{ $t("shippingInformation.selectedShippingAddressHeadline") }}
                        </h3>
                        <AddressComponent :address.sync="newAddress"
                                          @updateValidity="updateNewAddressValidity"
                                          :statesList="states">
                        </AddressComponent>
                        <div class="form-group">
                            <label class="n-form-labels" for="isHCP">
                            Is this an HCP Address?
                            </label>
                            <input type="checkbox" v-model="newAddress.isHCP" class="form-control n-isHCP" id="isHCP"/>
                        </div>
                        
                    </div>
                </div>
                <div class="row px-4" v-show="editNewAddressView && !shipMethodView">
                    <div class="col-12 mt-3">
                        <button type="button"
                                class="btn btn-outline-primary mr-3"
                                @click="navigate('cancelShippingAddress')">
                            Cancel
                        </button>
                        <button id="nextButton3"
                                type="button"
                                class="btn btn-primary"
                                :disabled="!isValidAddress"
                                @click="navigate('saveAddress')">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <!-- shippingMethodSection -->
            <div>
                <div class="row mt-3" id="shippingMethodSection">
                    <div class="col-12">
                        <div>
                            <Headline title="Shipping Method"
                                      :linkText="shipMethodView ? 'Change' : ''"
                                      v-on:on-click="showChangeShippingMethod"></Headline>
                        </div>
                    </div>
                </div>

                <div class="row px-4" v-show="shipMethodView">
                    <div class="col-12">
                        <div v-if="isChangeShippingMethod" class="mb-3">
                            <ShippingMethod :selectedShippingMethodCode="selectedShippingMethod.code"
                                            :shippingMethods="shippingMethods"
                                            :showPricing="false"
                                            v-on:shipping-method-selected="shippingMethodUpdated"></ShippingMethod>
                            <div class="mt-3">
                                <button type="button"
                                        class="btn btn-outline-primary mr-3"
                                        @click="navigate('shippingMethod')">
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div v-else class="mb-3">
                            <ShippingMethodView :shippingMethod="selectedShippingMethod"
                                                :showPricing="false">
                            </ShippingMethodView>
                            <div class="mt-3" v-show="shipMethodView && !shoppingCartView">
                                <button type="button"
                                        class="btn btn-outline-primary mr-3"
                                        @click="navigate('shippingAddress')">
                                    Back
                                </button>
                                <button id="nextButton4"
                                        type="button"
                                        class="btn btn-primary"
                                        @click="navigate('shoppingCart')">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="shoppingCartSection">
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="n-checkout-section-header px-4">
                            <h2>
                                Shopping Cart<span class="n-cartItems pl-1">
                                    {{ shoppingCartItems }}
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div v-show="shoppingCartView">
                    <div v-if="hasCartErrors">
                        <b-alert variant="danger" show>
                            Please review the product(s) <span v-if="!hasMixProductTypes">highlighted</span> below. The issue(s)
                            indicated MUST be addressed before placing your order.
                            <ul class="mb-0">
                                <li v-for="(error, index) in formattedCartErrors"
                                    :key="index">
                                    <span v-html="error"></span>
                                </li>
                            </ul>
                        </b-alert>
                    </div>
                    <div v-else-if="hasCartWarnings">
                        <b-alert variant="warning" show>
                            <strong>
                                Please review the important messages below for details regarding
                                your order. Please press
                                <a href="#" @click="navToReviewOrder()">Review Order</a> to
                                continue.
                            </strong>
                            <ul class="mb-0">
                                <li v-for="(warning, index) in formattedCartWarnings"
                                    :key="index">
                                    <span v-html="warning"></span>
                                </li>
                            </ul>
                        </b-alert>
                    </div>
                    <div class="row px-4">
                        <div v-for="item in cart.items" :key="item.id" class="col-12">
                            <CartItem :cartId="cart.id"
                                      :item="item"
                                      :isPricingEnabled="isPricingEnabled"
                                      :destinationUrl="getNavigationUrl(item)"
                                      :noImageAvailableUrl="noImageAvailableUrl"
                                      :showSeparator="false"
                                      :favorites="favoriteProducts">
                                <template v-slot:limits>
                                    <div v-if="item.messages && item.messages.length > 0"
                                         class="mt-2 mb-2">
                                        <div v-for="(message, index) in item.messages" :key="index">
                                            <template v-if="message.messageType === errorMessageType">
                                                <div class="d-flex alert alert-danger">
                                                    <font-awesome-icon class="text-danger mr-3 my-1"
                                                                       icon="times-circle" />
                                                    <div v-html="getWarningByMessage(message)"></div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="d-flex alert alert-warning">
                                                    <font-awesome-icon class="text-warning mr-3 my-1"
                                                                       icon="exclamation-circle" />
                                                    <span v-html="getWarningByMessage(message)"></span>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </CartItem>
                            <div class="col-12 mt-2 mb-2">
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5 px-4">
                        <div class="col-12">
                            <button type="button"
                                    class="btn btn-outline-primary mr-3"
                                    @click="navigate('cancel')">
                                Cancel
                            </button>
                            <button type="button"
                                    class="btn btn-primary"
                                    :disabled="!canReviewOrder"
                                    @click="navigate('review')">
                                Review Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
    import AddressComponent from "@/nucleus-modules/dd-nucleus-ui/components/AddressComponent.vue";
    import Headline from "@/nucleus-modules/dd-nucleus-ui/components/Headline.vue";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";

    import {
        OrdersMixin,
        ERROR_MESSAGE_TYPE,
    } from "@/nucleus-modules/dd-nucleus-storefront/mixins/OrdersMixin.js";
    import ShipAddressView from "@/nucleus-modules/dd-nucleus-storefront/components/ShipAddressView.vue";
    import ShippingMethod from "@/nucleus-modules/dd-nucleus-storefront/components/ShippingMethod.vue";
    import ShippingMethodView from "@/nucleus-modules/dd-nucleus-storefront/components/ShippingMethodView.vue";

    import CartItem from "@/components/cart/CartItem.vue";
    import CRMAddressBook from "@/components/crm-addresses/CRMAddressBook.vue";
    import PageTemplate from "@/pages/templates/PageTemplate.vue";
    import {
        noImageAvailableUrl,
        defaultShippingPhone,
    } from "@/companyAppConfig.js";
    import AddressTile from "@/nucleus-modules/dd-nucleus-storefront/components/AddressTile.vue";
    import { formatting } from "@/nucleus-modules/dd-nucleus-ui/mixins/formattingMixin.js";

    export default {
        name: "Checkout",
        mixins: [OrdersMixin, formatting],
        components: {
            AddressComponent,
            Headline,
            CartItem,
            ShipAddressView,
            ShippingMethod,
            ShippingMethodView,
            CRMAddressBook,
            PageTemplate,
            AddressTile,
        },
        data() {
            return {
                isChangeShippingMethod: false,
                isLoading: true,
                selectedAddressView: null,
                defaultView: true,
                shipMethodView: false,
                shoppingCartView: false,
                selectAddressView: false,
                editNewAddressView: false,
                selectedEmail: null,
                selectedPhoneNumber: null,
                selectedShippingMethod: null,
                shipTo: null,
                unsubscribe: null,
                addressViews: {
                    DEFAULT: 0,
                    SELECT_ADDRESS: 1,
                    SELECT_SHIP_METHOD: 2,
                    SELECT_SHOPPING_CART: 3,
                    SELECT_NEW_ADDRESS: 3,
                },
                noImageAvailableUrl: noImageAvailableUrl,
                defaultAddressSelectText: "Use This Address",
                defaultShippingAddressSelectText: "Use Default Shipping Address",
                defaultShippingPhone: defaultShippingPhone,
                currentTab: 0,
                newAddress: {
                    id: null,
                    companyName: "",
                    addressee: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    phoneNumber: "",
                    isResidential: true,
                    isHCP: false
                },
                isNewAddressValid: true,
                errorMessageType: ERROR_MESSAGE_TYPE,
                unchangedAddress: null,
            };
        },
        computed: {
            canReviewOrder() {
                return (
                    !this.hasItemErrors &&
                    this.hasItems &&
                    this.selectedAddress &&
                    this.selectedShippingMethod &&
                    !this.hasMixProductTypes
                );
            },
            hasItems() {
                return this.cartItemsCount && this.cartItemsCount > 0;
            },
            shoppingCartTitle() {
                return `Shopping Cart (${this.cartItemsCount} items)`;
            },
            shoppingCartItems() {
                return `(${this.cartItemsCount} items)`;
            },
            isDefaultShipAddressSelected() {
                return this.selectedAddress.id == this.shippingDetails.address.id;
            },
            selectedAddress: {
                get() {
                    const newAddress = Object.assign({}, this.shipAddress);
                    newAddress.phoneNumber = this.formatPhoneNumber(
                        this.shipAddress.phoneNumber
                    );
                    const truncatedAddress = this.truncateShipAddress(newAddress);
                    return truncatedAddress;

                },
                set(val) {
                    const truncatedAddress = this.truncateShipAddress(val);
                    this.updateShipAddress(truncatedAddress);
                },
            },
            isValidAddress() {
                return this.isNewAddressValid;
            },
            isAddEditView() {
                return this.currentTab === 1;
            },
            hasCartShipToAddress() {
                if (this.cart && this.cart.shipTo) {
                    return (
                        this.cart.shipTo.addressee &&
                        this.cart.shipTo.addressLine1 &&
                        this.cart.shipTo.city &&
                        this.cart.shipTo.state &&
                        this.cart.shipTo.postalCode
                    );
                }
                return false;
            },
            defaultShipAddress() {
                return this.updateAddressFields(this.shippingDetails.address);
            },
            formattedCartErrors() {
                const errors = [];
                if (this.cartErrors) {
                    this.cartErrors.forEach(message => {
                        if (message && message.parameters && message.parameters.length > 0) {
                            message.parameters.forEach(p => {                                
                                if(p == "MixProductTypes") {
                                    errors.push("<strong>PRODUCT TYPE ERROR FOR HCP ORDER:</strong> Your cart currently includes both branded and unbranded product types. Please note that when shipping directly to a Healthcare Professional (HCP), these product types cannot be combined. To proceed with your order, please update your cart accordingly.");
                                }
                            });
                        }
                    });
                }

                return errors;
                
            },
            hasMixProductTypes() {
                var flag = false;
                this.cartErrors.forEach(message => {
                    message.parameters.forEach(p => {
                        if(p == "MixProductTypes") {
                            flag = true;
                        }
                    });                
                });            
                return flag;
            }
        },
        methods: {
            truncateShipAddress(addressObj) {
                if (addressObj.organization) {
                    addressObj.organization = addressObj.organization.substring(0, 30);
                }

                if (addressObj.addressLine1) {
                    addressObj.addressLine1 = addressObj.addressLine1.substring(0, 30);
                }

                if (addressObj.addressLine2) {
                    addressObj.addressLine2 = addressObj.addressLine2.substring(0, 30);
                }
                return addressObj;
            },
            async addNewAddress() {
                let shipTo = {
                    addressLine1: this.newAddress.addressLine1,
                    addressLine2: this.newAddress.addressLine2,
                    city: this.newAddress.city,
                    state: this.newAddress.state,
                    postalCode: this.newAddress.postalCode,
                    phoneNumber: this.newAddress.phoneNumber
                        ? this.newAddress.phoneNumber
                        : defaultShippingPhone,
                    isResidential: this.newAddress.isResidential,
                    email: this.selectedEmail.email,
                    attributes: {
                        isHCP: this.newAddress.isHCP
                    }
                };

                if (this.newAddress.companyName) {
                    shipTo.addressee = this.newAddress.companyName;
                    shipTo.attention = this.newAddress.addressee;
                } else {
                    shipTo.addressee = this.newAddress.addressee;
                }

                const response = await this.assignShippingAddress({
                    cartId: this.cart.id,
                    shipTo: shipTo,
                });

                if (!response.succeeded) {
                    ToastService.showErrorToast(response.messages[0]);
                } else {
                    await this.getCart();
                    this.selectedAddress = this.updateAddressFields(this.cart.shipTo);
                    this.unchangedAddress = this.selectedAddress;
                    this.selectAddressView = false;
                    this.defaultView = true;
                }
            },
            async crmAddressSelected(eventData) {
                const response = await this.assignCrmShippingAddress(eventData.id);

                if (!response.succeeded) {
                    ToastService.showErrorToast(response.messages[0]);
                }

                await this.getCart();
                this.selectedAddress = this.updateAddressFields(this.cart.shipTo);
                if (this.cart.shipToAddressId) {
                    this.selectedAddress.id = this.cart.shipToAddressId;
                }
                this.unchangedAddress = this.selectedAddress;
                this.navigate("shippingAddress");
            },
            async navigate(origin) {
                switch (origin) {
                    case "saveAddress":
                        // if new address is valid, update selected address and reset the tabs ,clear the add-new address form
                        if (this.isValidAddress && this.isAddEditView) {
                            if (this.newAddress.addressee) {
                                this.newAddress.firstName = this.newAddress.addressee;
                            }

                            if (this.newAddress.companyName) {
                                this.newAddress.organization = this.newAddress.companyName;
                            }
                            let newAddressLocal = Object.assign({}, this.newAddress);
                            this.selectedAddress = newAddressLocal;

                            await this.addNewAddress();

                            this.currentTab = 0;
                        }
                        this.toShipAddressSection();
                        this.$nextTick(() => {
                            this.$scrollTo("#shippingAddressSection", 500, {
                                easing: "ease-in-out",
                                offset: -120,
                            });
                        });
                        break;
                    case "shippingAddress":
                        this.toShipAddressSection();
                        this.$nextTick(() => {
                            this.$scrollTo("#shippingAddressSection", 500, {
                                easing: "ease-in-out",
                                offset: -120,
                            });
                        });
                        break;
                    case "cancelShippingAddress":
                        this.updateUnchangedAddress();
                        this.toShipAddressSection();
                        this.$nextTick(() => {
                            this.$scrollTo("#shippingAddressSection", 500, {
                                easing: "ease-in-out",
                                offset: -120,
                            });
                        });
                        break;

                    case "editAddress":
                        this.editAddress();
                        break;
                    case "shippingMethod":
                        this.isChangeShippingMethod = false;
                        this.toShipMethodSection();
                        this.$nextTick(() => {
                            this.$scrollTo("#shippingMethodSection", 500, {
                                easing: "ease-in-out",
                                offset: -120,
                            });
                        });
                        break;
                    case "shoppingCart":
                        this.toShoppingCartSection();
                        this.$nextTick(() => {
                            this.$scrollTo("#shoppingCartSection", 500, {
                                easing: "ease-in-out",
                                offset: -120,
                            });
                        });
                        break;
                    case "review":
                        this.navToReviewOrder();
                        break;
                    case "cancel":
                        this.navToShoppingCart();
                }
            },
            editNewAddress() {
                this.unchangedAddress = Object.assign({}, this.selectedAddress);
                const addressToEdit = this.selectedAddress;
                this.resetNewAddress();
                if (
                    addressToEdit.addressee &&
                    addressToEdit.attention &&
                    addressToEdit.addressee != addressToEdit.attention
                ) {
                    addressToEdit.companyName = addressToEdit.addressee;
                    addressToEdit.addressee = addressToEdit.attention;
                    addressToEdit.attention = null;
                }
                this.newAddress = addressToEdit;
                if(this.newAddress.attributes && this.newAddress.attributes.isHCP) {
                    this.newAddress.isHCP = this.newAddress.attributes.isHCP;
                }
                this.isNewAddressValid = true;
                this.editNewAddressView = true;
                this.defaultView = false;
                this.selectAddressView = false;
                this.shipMethodView = false;
                this.shoppingCartView = false;
            },

            createNewAddress() {
                this.selectedAddressView = this.addressViews.SELECT_ADDRESS;
            },
            editAddress() {
                this.defaultView = false;
                this.selectAddressView = true;
                this.shipMethodView = false;
                this.shoppingCartView = false;
                this.isNewAddressValid = true;
                this.editNewAddressView = false;
                this.currentTab = 0;
            },
            getNavigationUrl(item) {
                return `/product-detail/${item.product.id}`;
            },
            initListeners() {
                const vm = this;

                this.$eventBus.$on(UiEvents.REMOVE_CART_ITEM_CLICKED, (eventArgs) => {
                    vm.removeFromCart({
                        cartId: eventArgs.cartId,
                        itemId: eventArgs.item.id,
                    });
                });

                this.$eventBus.$on(UiEvents.QUANTITY_INPUT_CHANGED, (eventData) => {
                    this.updateCartItem({
                        itemId: eventData.item.id,
                        quantity: eventData.item.quantity,
                        showLoader: false,
                    });
                });
            },
            navToReviewOrder() {
                this.$router.push("/review-order");
            },
            navToShoppingCart() {
                this.$router.push("/shopping-cart");
            },
            removeListeners() {
                this.$eventBus.$off(UiEvents.REMOVE_CART_ITEM_CLICKED);
                this.$eventBus.$off(UiEvents.QUANTITY_INPUT_CHANGED);
            },
            async shippingAddressUpdated(shippingAddress, phoneNumber, email) {
                let shipTo = {};
                if (shippingAddress.id) {
                    shipTo.addressId = shippingAddress.id;
                } else {
                    shipTo = shippingAddress;
                }
                shipTo.phoneNumber = phoneNumber;
                shipTo.email = email;

                await this.assignShippingAddress({
                    cartId: this.cart.id,
                    shipTo: shipTo,
                });
                await this.getCart();
            },
            async shippingMethodUpdated(shippingMethod) {
                let shipMethodResponse = await this.assignShippingMethod({
                    cartId: this.cart.id,
                    shippingMethod: shippingMethod,
                });

                if (
                    shipMethodResponse &&
                    shipMethodResponse.succeeded &&
                    shipMethodResponse.cart &&
                    shipMethodResponse.cart.shipMethodCode
                ) {
                    let selectedShipMethod = shipMethodResponse.cart.shipMethod;
                    selectedShipMethod.estimatedArrival = `${selectedShipMethod.minDaysInTransit} - ${selectedShipMethod.maxDaysInTransit} Business Days Shipping`;
                    this.selectedShippingMethod = selectedShipMethod;
                    this.isChangeShippingMethod = false;
                }

                await this.getCart();
            },
            showChangeShippingMethod() {
                this.isChangeShippingMethod = true;
                this.toShipMethodSection();
            },
            toShipAddressSection() {
                this.defaultView = true;
                this.shipMethodView = false;
                this.shoppingCartView = false;
                this.selectAddressView = false;
                this.editNewAddressView = false;
            },
            toShipMethodSection() {
                this.defaultView = true;
                this.shipMethodView = true;
                this.shoppingCartView = false;
                this.selectAddressView = false;
                this.editNewAddressView = false;
            },
            toShoppingCartSection() {
                this.defaultView = true;
                this.shipMethodView = true;
                this.shoppingCartView = true;
                this.selectAddressView = false;
                this.editNewAddressView = false;
            },

            updateUnchangedAddress() {
                if (!this.$nucleus.isEmpty(this.unchangedAddress)) {
                    let newAddressLocal = Object.assign({}, this.unchangedAddress);
                    this.selectedAddress = newAddressLocal;
                }
            },
            changeSection() {
                if (this.$nucleus.getStorageItem(this.checkoutSectionKey, true)) {
                    const editArea = Number(
                        this.$nucleus.getStorageItem(this.checkoutSectionKey, true)
                    );

                    if (editArea === this.checkoutSections.SHIPPING_ADDRESS) {
                        this.navigate("editAddress");
                    } else if (editArea === this.checkoutSections.SHIPPING_METHOD) {
                        this.navigate("shippingMethod");
                        this.isChangeShippingMethod = true;
                    } else if (editArea === this.checkoutSections.SHOPPING_CART) {
                        this.navigate("shoppingCart");
                    }

                    this.$nucleus.removeStorageItem(this.checkoutSectionKey, true);
                }
            },
            async useDefaultShipAddress(eventData) {
                await this.updateShipAddress(eventData.address);
                await this.shippingAddressUpdated(
                    this.selectedAddress,
                    this.selectedPhoneNumber
                        ? this.selectedPhoneNumber.number
                        : defaultShippingPhone,
                    this.selectedEmail.email
                );
                this.unchangedAddress = this.selectedAddress;
                this.currentTab = 0;
                this.navigate("shippingAddress");
            },
            tabClicked(tabIndex) {
                this.unchangedAddress = Object.assign({}, this.selectedAddress);
                this.currentTab = tabIndex;
                if (tabIndex === 1) {
                    this.resetNewAddress();
                } else {
                    this.isNewAddressValid = true;
                }
            },
            updateNewAddressValidity(isValid) {
                this.isNewAddressValid = isValid;
            },
            resetNewAddress() {
                this.newAddress = {
                    id: null,
                    companyName: "",
                    addressee: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    isHCP: false
                };
                this.isNewAddressValid = false;
                this.currentTab = 1;
            },
            async setDefaultShipAddress(eventData) {
                await this.updateShipAddress(eventData.address);
                await this.shippingAddressUpdated(
                    this.selectedAddress,
                    this.selectedPhoneNumber
                        ? this.selectedPhoneNumber.number
                        : defaultShippingPhone,
                    this.selectedEmail.email
                );
                this.selectAddress();
            },
            //TODO: Need to modify the address object as different address components expects different set of values. Need to clean up.
            updateAddressFields(address) {
                if (
                    address.addressee &&
                    address.attention &&
                    address.addressee != address.attention
                ) {
                    address.organization = address.addressee;
                    address.firstName = address.attention;
                } else if (address.addressee && address.companyName) {
                    address.organization = address.companyName;
                    address.firstName = address.addressee;
                } else {
                    address.firstName = address.addressee;
                }
                return address;
            },
            isDefaultShippingAddress(addressId) {
                if (this.addresses && this.addresses.length) {
                    const defaultShipAddress = this.addresses.find(
                        (a) => a.id === addressId
                    );
                    return !this.$nucleus.isEmpty(defaultShipAddress);
                }
                return false;
            },
            isDifferentAddresses(address1, address2) {
                if (address1 && address2) {
                    return (
                        address1.addressee != address2.addressee ||
                        address1.addressLine1 != address2.addressLine1 ||
                        address1.addressLine2 != address2.addressLine2 ||
                        address1.city != address2.city ||
                        address1.state != address2.state ||
                        address1.postalCode != address2.postalCode
                    );
                }
                return true;
            },
        },
        beforeDestroy() {
            this.removeListeners();
        },
        updated() {
            if (!this.isLoading && this.hasItems == 0) {
                this.navToShoppingCart();
            }
            this.changeSection();
        },
        async mounted() {
            this.initListeners();

            const id = this.$route.query.id;

            // default ship address
            await this.getShippingDetails({
                id: id,
            });

            this.shippingDetails.address.isDefault = true;
            this.selectedEmail = this.shippingDetails.email;
            this.selectedPhoneNumber = this.shippingDetails.phoneNumber;
            this.shippingDetails.address.phoneNumber = this.selectedPhoneNumber ? this.formatPhoneNumber(this.selectedPhoneNumber.number) : this.formatPhoneNumber(defaultShippingPhone);
            await this.searchAddresses();

            if (!this.hasCartShipToAddress) {
                this.selectedAddress = this.shippingDetails.address;
                await this.updateShipAddress(this.selectedAddress);
                await this.shippingAddressUpdated(
                    this.selectedAddress,
                    this.selectedPhoneNumber
                        ? this.selectedPhoneNumber.number
                        : defaultShippingPhone,
                    this.selectedEmail.email
                );
            } else {
                this.selectedAddress = this.cart.shipTo;

                if (this.cart.shipToAddressId) {
                    //Just in case the address is updated via roster,
                    //profile has the latest address but cart does not have the latest shipping address
                    // Hence we need to update the latest shipping address in the cart.
                    if (this.shippingDetails.address.id == this.cart.shipToAddressId) {
                        if (
                            this.isDifferentAddresses(
                                this.selectedAddress,
                                this.shippingDetails.address
                            )
                        ) {
                            this.selectedAddress = this.shippingDetails.address;

                            const addressToUpdate = {
                                addressId: this.shippingDetails.address.id,
                                phoneNumber: this.shippingDetails.address.phoneNumber,
                                email: this.selectedEmail.email,
                            };
                            await this.assignShippingAddress({
                                cartId: this.cart.id,
                                shipTo: addressToUpdate,
                            });
                        }
                        else {
                            this.selectedAddress.id = this.cart.shipToAddressId;
                        }
                    } else {
                        this.selectedAddress.id = this.cart.shipToAddressId;
                    }
                    this.selectedAddress.isDefault = this.isDefaultShippingAddress(
                        this.cart.shipToAddressId
                    );
                }
                await this.updateShipAddress(this.selectedAddress);
            }

            this.selectedAddress = this.updateAddressFields(this.selectedAddress);
            this.selectedAddressView = this.addressViews.DEFAULT;

            if (this.cart && this.cart.shipMethodCode) {
                this.selectedShippingMethod = this.shippingMethods.find(
                    (sm) => sm.code === this.cart.shipMethodCode
                );
            }
            if (this.$nucleus.isEmpty(this.selectedShippingMethod)) {
                this.selectedShippingMethod = await this.shippingMethods[0];
            }
            await this.shippingMethodUpdated(this.selectedShippingMethod.code);
            this.isLoading = false;
        },
    };
</script>

<style lang="scss" scoped>
    .n-cartItems {
        color: $gray-3;
        font-size: 1rem;
        font-weight: 400;
    }

    .n-checkout-section-header h2 {
        margin-bottom: 0;
        font-weight: 400;
    }

    .nav-tabs .nav-item {
        border-radius: 5px 5px 0px 0px !important;
    }

    .nav-tabs {
        border-bottom: 2px solid $primary-color !important;
    }

    .n-crm-address-tab {
        background: $neutral-lightest;
    }

    .n-isHCP {
        width: 6%;
    }

    @media screen and (max-width: 576px) {
        .n-hide-on-mobile {
            display: none;
        }
    }

    @media screen and (min-width: 576px) {
        .n-hide-on-desktop {
            display: none;
        }
    }
</style>