import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import {
    ADD_ACTIVE_MENU_ITEM,
    ADD_ACTIVE_ITEM,
    ASSIGN_SSO_TOKEN,
    GET_INVENTORY_QUANTITY,
    GET_NEW_PRODUCT,
    RESET_ACTIVE_MENU_ITEMS,
    CONTACT_US_SUBMIT,
    GET_CART_ADD_ON_ITEM,
} from "@/store/storefront/actions.type.js";

import { SET_PRODUCT_FOR_EDIT } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { ACTIVE_MENU_ITEMS_GETTER, INVENTORY_QUANTITY_GETTER, CART_ADD_ON_ITEM_GETTER } from "@/store/storefront/getters.type.js";
import { SET_ACTIVE_MENU_ITEMS, SET_INVENTORY_QUANTITY, SET_CART_ADD_ON_ITEM } from "@/store/storefront/mutations.type.js";
import { ACCESS_TOKEN_KEY } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { ADJUST_INVENTORY } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

import { GET_USER_ANNOUNCEMENT } from "./actions.type.js";
import { USER_ANNOUNCEMENT_GETTER } from "./getters.type.js";
import { SET_USER_ANNOUNCEMENT } from "./mutations.type.js";

const MODULE_ID = "demo-storefront";

const initialState = {
    activeMenuItems: [],
    inventoryQuantities: [],
    userAnnouncement: {},
    addOnItem: {}
}

export const state = { ...initialState };

export const actions = {
    [ADD_ACTIVE_MENU_ITEM]({ commit }, menuItem) {
        var existingMenuItemIndex = state.activeMenuItems.findIndex(x => x.level === menuItem.level);

        if (existingMenuItemIndex === -1) {
            state.activeMenuItems.push(menuItem);
        } else {
            state.activeMenuItems[existingMenuItemIndex].id = menuItem.id;
            state.activeMenuItems[existingMenuItemIndex].isOpened = menuItem.isOpened;
            state.activeMenuItems.length = existingMenuItemIndex + 1;
        }

        commit(SET_ACTIVE_MENU_ITEMS, state.activeMenuItems);
    },
    [ADD_ACTIVE_ITEM]({ commit }, menuItem) {
        var existingItemIndex = state.activeMenuItems.findIndex(x => x.level === menuItem.level);

        if (existingItemIndex === -1) {
            state.activeMenuItems.push(menuItem);
        } else {
            state.activeMenuItems[existingItemIndex].title = menuItem.title;
            state.activeMenuItems[existingItemIndex].isOpened = menuItem.isOpened;
            state.activeMenuItems.length = existingItemIndex + 1;
        }

        commit(SET_ACTIVE_MENU_ITEMS, state.activeMenuItems);
    },

    async [ADJUST_INVENTORY](_, payload) {

        const response = await ApiService.post(`api/integration/bls/v1/inventory`, payload);

        return response;
    },

    async [ASSIGN_SSO_TOKEN](_, payload) {
        if (payload.ssoToken) {
            localStorage.setItem(ACCESS_TOKEN_KEY, payload.ssoToken);
        }
    },
    async [GET_NEW_PRODUCT]({ commit }) {
        const response = await ApiService.get("ui/admin/product/new-product");

        if (response.succeeded) {
            commit(SET_PRODUCT_FOR_EDIT, response.product);
        }
    },
    [RESET_ACTIVE_MENU_ITEMS]({ commit }) {
        commit(SET_ACTIVE_MENU_ITEMS, state.activeMenuItems = []);
    },
    async [GET_INVENTORY_QUANTITY](_, payload) {
        const response = await ApiService.post(`/ui/inventory/real-time`, {
            items: payload
        });

        return response;
    },
    async[CONTACT_US_SUBMIT]( _ , payload) {

        const response = await ApiService.post('ui/user/contact-us' ,payload);

        // Return a response so the end users knows what happened 
        return response;
    },
    async [GET_USER_ANNOUNCEMENT]({ commit }) {
        const response = await ApiService.get(`ui/announcement`);
        if (response.succeeded) {
            commit(SET_USER_ANNOUNCEMENT, response.contentManagement);
        }
    },
    async [GET_CART_ADD_ON_ITEM]({ commit }) {
        const urlPrefix = `ui/store/products`;
        const addOnItemResponse = await ApiService.get(`${urlPrefix}/add-on`);
    
        if (addOnItemResponse.succeeded) {
          if (addOnItemResponse.results && addOnItemResponse.results.length) {
            commit(SET_CART_ADD_ON_ITEM,  {
                    items: addOnItemResponse.results,
                    pageNumber: addOnItemResponse.pageNumber,
                    pageSize: addOnItemResponse.pageSize,
                    count: addOnItemResponse.count,
                    totalCount: addOnItemResponse.totalCount
                });
          }
        }
      },
}

export const getters = {
    [ACTIVE_MENU_ITEMS_GETTER]: state => {
        return state.activeMenuItems;
    },
    [INVENTORY_QUANTITY_GETTER]: state => {
        return state.inventoryQuantities;
    },
    [USER_ANNOUNCEMENT_GETTER]: state => {
        return state.userAnnouncement
    },
    [CART_ADD_ON_ITEM_GETTER]: state => {
        return state.addOnItem
    }
}

export const mutations = {
    [SET_ACTIVE_MENU_ITEMS]: (state, activeMenuItems) => {
        state.activeMenuItems = activeMenuItems;
    },
    [SET_INVENTORY_QUANTITY]: (state, inventoryQuantities) => {
        state.inventoryQuantities = inventoryQuantities;
    },
    [SET_USER_ANNOUNCEMENT]: (state, announcement) => {
        state.userAnnouncement = announcement;
    },
    [SET_CART_ADD_ON_ITEM]: (state, addOnItem) => {
        state.addOnItem = addOnItem;
    },
}

export default {
    state, actions, getters, mutations, MODULE_ID
}