import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import {
    ADD_TO_CART,
    ASSIGN_SHIPPING_ADDRESS,
    ASSIGN_SHIPPING_METHOD,
    GET_CART,
    REMOVE_FROM_CART,
    UPDATE_CART_ITEM
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import {
    ADD_TO_CART_ERROR_GETTER,
    ASSIGN_SHIPPING_ADDRESS_ERROR_GETTER,
    ASSIGN_SHIPPING_METHOD_ERROR_GETTER,
    CART_GETTER,
    IS_CART_LOADING_GETTER,
    REMOVE_FROM_CART_ERROR_GETTER,
    TOTAL_CART_ITEMS_GETTER
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
    SET_ADD_TO_CART_ERROR,
    SET_ASSIGN_SHIPPING_ADDRESS_ERROR,
    SET_ASSIGN_SHIPPING_METHOD_ERROR,
    SET_CART,
    SET_CART_ID,
    SET_GET_CART_ERROR,
    SET_IS_CART_LOADING,
    SET_LATEST_CART_UPDATE,
    SET_REMOVE_FROM_CART_ERROR
} from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

const MODULE_ID = "cart";

const initialState = {
    addToCartError: {},
    assignShippingAddressError: {},
    assignShippingMethodError: {},
    cart: {},
    getCartError: {},
    isCartLoading: false,
    latestCartUpdate: {},
    removeFromCartError: {}
};

export const state = { ...initialState };

export const actions = {
    async [ADD_TO_CART]({ commit, dispatch }, payload) {
        commit(SET_ADD_TO_CART_ERROR, {});

        const response = await ApiService.post(`api/v1/cart/${payload.cartId}/items`, payload);

        if (response.succeeded) {
            commit(SET_CART_ID, payload.cartId);
            commit(SET_LATEST_CART_UPDATE, payload);
            dispatch(GET_CART, { cartId: payload.cartId });
        }
        else {
            commit(SET_ADD_TO_CART_ERROR, response);
        }

        return response;
    },
    async[ASSIGN_SHIPPING_ADDRESS]({ commit }, payload) {
        commit(SET_ASSIGN_SHIPPING_ADDRESS_ERROR, {});

        const response = await ApiService.put(`api/v1/cart/${payload.cartId}/shipping-address`, {
            cartId: payload.cartId,
            shipTo: payload.shipTo
        });

        if (response.succeeded) {
            return response;
        } else {
            commit(SET_ASSIGN_SHIPPING_ADDRESS_ERROR, response);
        }
    },
    async [ASSIGN_SHIPPING_METHOD]({ commit }, payload) {
        commit(SET_ASSIGN_SHIPPING_METHOD_ERROR, {});

        const response = await ApiService.put(`api/v1/cart/${payload.cartId}/shipping-method`, {
            cartId: payload.cartId,
            shipMethodCode: payload.shippingMethod
        }, { showLoader: false });

        if (response.succeeded) {
            return response;
        } else {
            commit(SET_ASSIGN_SHIPPING_METHOD_ERROR, response);
        }
    },
    async [GET_CART]({ commit }, payload) {
        commit(SET_IS_CART_LOADING, true);
        commit(SET_GET_CART_ERROR, {});

        let response;

        if (payload && payload.hasOwnProperty("showLoader")) {
            response = await ApiService.post("api/v1/carts", { cartId: null }, { showLoader: payload.showLoader });
        }
        else {
            response = await ApiService.post("api/v1/carts", { cartId: null });
        }
        if (response.succeeded) {
            commit(SET_CART, response.cart);
        } else {
            commit(SET_GET_CART_ERROR, response);
        }

        commit(SET_IS_CART_LOADING, false);
    },
    async [REMOVE_FROM_CART]({ commit, dispatch }, payload) {
        const response = await ApiService.delete(`api/v1/cart/${payload.cartId}/items/${payload.itemId}`);

        if (response.succeeded) {
            commit(SET_CART_ID, payload.cartId);

            dispatch(GET_CART, { cartId: payload.cartId });
        }
        else {
            commit(SET_REMOVE_FROM_CART_ERROR, response);
        }

        return response;
    },
    async [UPDATE_CART_ITEM]({ commit, dispatch }, payload) {
        commit(SET_GET_CART_ERROR, {});

        const response = await ApiService.put(`api/v1/cart/${state.cart.id}/items/${payload.itemId}`, payload, { showLoader: false });

        if (response.succeeded) {
            dispatch(GET_CART, { cartId: state.cart.id, showLoader: false });
        }
        else {
            commit(SET_GET_CART_ERROR, response);
            commit(SET_CART, response.cart);
        }
    }
};

export const getters = {
    [ADD_TO_CART_ERROR_GETTER]: state => {
        return state.addToCartError;
    },
    [ASSIGN_SHIPPING_ADDRESS_ERROR_GETTER]: state => {
        return state.assignShippingAddressError;
    },
    [ASSIGN_SHIPPING_METHOD_ERROR_GETTER]: state => {
        return state.assignShippingMethodError;
    },
    [CART_GETTER]: state => {
        return state.cart;
    },
    [IS_CART_LOADING_GETTER]: state => {
        return state.isCartLoading;
    },
    [REMOVE_FROM_CART_ERROR_GETTER]: state => {
        return state.removeFromCartError;
    },
    [TOTAL_CART_ITEMS_GETTER]: state => {
        if (state.cart && state.cart.items) {
            return state.cart.items.reduce((n, { quantity }) => Number(n) + Number(quantity), 0);
        }

        return 0;
    }
};

export const mutations = {
    [SET_ADD_TO_CART_ERROR](state, response) {
        state.addToCartError = response;
    },
    [SET_ASSIGN_SHIPPING_ADDRESS_ERROR](state, response) {
        state.assignShippingAddressError = response;
    },
    [SET_ASSIGN_SHIPPING_METHOD_ERROR](state, response) {
        state.assignShippingMethodError = response;
    },
    [SET_CART](state, cart) {
        state.cart = cart;
    },
    [SET_CART_ID](state, cartId) {
        state.cart.id = cartId;
    },
    [SET_GET_CART_ERROR](state, response) {
        state.getCartError = response;
    },
    [SET_IS_CART_LOADING](state, isLoading) {
        state.isCartLoading = isLoading;
    },
    [SET_LATEST_CART_UPDATE](state, cartRequest) {
        state.latestCartUpdate = cartRequest;
    },
    [SET_REMOVE_FROM_CART_ERROR](state, response) {
        state.removeFromCartError = response;
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
    MODULE_ID
};
