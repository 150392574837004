<template>
    <div class="n-confirm-overlay">
        <div class="n-confirm-text">
            <div class="row">
                <div class="col text-center">
                    <slot name='message'/>
                </div>
            </div>
            
            <div class="row mt-3" >
                <div class="col text-center">
                    <slot name='subMessage'/>
                </div>
            </div>
            
            <div class="row mt-3">
                <div class="col text-center">
                    <slot name='buttons'/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'confirm-overlay',
}
</script>

<style lang="scss" scoped>
.n-confirm-overlay{
    background: $primary-color;
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
}
.n-confirm-text {
    left: 50%;
    display: block;
    position: fixed;
    top: 40%;
    transform: translate(-50%, -50%);
    color:#fff;
    font-size: 1.25rem;
}
</style>