<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-7">
        <dl>
          <dt>Announcement Name</dt>
          <dd>{{ announcement.name }}</dd>
          <dt>Start Date</dt>
          <dd>
            <span v-if="announcement.startDate">{{
              announcement.startDate | moment("MM/DD/YYYY")
            }}</span>
            <span v-else> Start Date</span>
          </dd>

          <dt>End Date</dt>
          <dd>
            <span v-if="announcement.endDate">{{
              announcement.endDate | moment("MM/DD/YYYY")
            }}</span>
            <span v-else> End Date</span>
          </dd>
          <dt>Announcement Description (Displayed on Storefront)</dt>
          <dd>
            <span v-html="announcement.description"> </span>
          </dd>
          <div class="pt-3" v-if="announcement.isDefault">
            <dd>
              <span
                id="boot-icon"
                class="bi bi-star-fill n-default-star"
              ></span>
              <label class="n-form-labels font-weight-bold pl-1"
                >Default Announcement</label
              >
            </dd>
          </div>
        </dl>
      </div>
      <div class="col-3">
        <dl>
          <dt>Date Last Modified</dt>
          <dd>
            <span v-if="announcement.updated">{{
              announcement.updated | moment("MM/DD/YYYY")
            }}</span>
          </dd>
        </dl>
        <dl>
          <dt>Created Date</dt>
          <dd>
            <span v-if="announcement.created">{{
              announcement.created | moment("MM/DD/YYYY")
            }}</span>
          </dd>
        </dl>
        <dl>
          <dt>Created By</dt>
          <dd>
            <span v-if="announcement.createdBy">{{
              announcement.createdBy
            }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AnnouncementInfoViewOnly",
  props: {
    announcement: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
dt {
  margin-top: 1.5rem !important;
}

dd {
  font-weight: normal !important;
}
.n-form-label-notes {
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.n-default-star {
  font-size: 23px;
  color: $admin-highlight-color-1;
}
</style>
