import { render, staticRenderFns } from "./OrderDetailHeader.vue?vue&type=template&id=322220dc&scoped=true&"
import script from "./OrderDetailHeader.vue?vue&type=script&lang=js&"
export * from "./OrderDetailHeader.vue?vue&type=script&lang=js&"
import style0 from "./OrderDetailHeader.vue?vue&type=style&index=0&id=322220dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322220dc",
  null
  
)

export default component.exports