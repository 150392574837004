<template>
    <div class="forgot-password my-4 n-mb-6">
        <div v-if="isRequestPasswordResetView">
            <div class="row">
                <div class="n-logo col-12 d-lg-block my-3 mx-2 flex-fill">
                    <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
                </div>
                <div class="col-12 text-center">
                    <h2>
                        {{ $t("forgotPassword.forgotPasswordHeadline") }}
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12 offset-md-4 text-center">
                    <p class="n-remember-password">{{ $t("forgotPassword.rememberYourPassword") }} <a @click="signIn">{{
                            $t("forgotPassword.signIn")
                    }}</a></p>
                    <p>{{ $t("forgotPassword.enterEmailText") }}.</p>
                    <p>{{ $t("forgotPassword.spamJunkWarning") }}.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12 offset-md-4 text-centers">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <form>
                            <ValidationProvider rules="required" name="Email" v-slot="{ errors }">
                                <div class="form-group">
                                    <div v-if="errorMessage" class="my-3 alert alert-danger n-custom-error">
                                        <div v-if="customError" class="n-custom-error">
                                            <div> {{ errorMessage }}</div>
                                            <span><a class="n-sign-in-link" @click="openIdSignIn">Sign-in as Regeneron
                                                    Employee</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="email">{{ $t("forgotPassword.emailLabel") }}</label>
                                    <input type="email" class="form-control n-text-field-highlight" id="email"
                                        v-model="email" :placeholder="$t('shared.placeholders.emailExample')"
                                        :class="{ 'is-invalid': errors && errors.length > 0 }">
                                    <div class="invalid-feedback" v-if="errors && errors.length > 0">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <div class="row">
                                <div class="col-6">
                                    <a @click="signIn" class="btn btn-outline-primary btn-block">
                                        {{ $t("shared.buttons.cancelText") }}
                                    </a>
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-primary btn-block"
                                        :disabled="isButtonDisabled || invalid" @click.prevent="sendResetPassword()">
                                        {{ $t("shared.buttons.sendText") }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <!-- end of Request Password -->
        <div class="row" v-if="isLinkSentConfirmationView">
            <div class="col-lg-6 col-12 offset-lg-3 text-center">
                <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
                <h2>{{ $t("forgotPassword.checkEmail") }}</h2>
                <p>
                    {{ $t("forgotPassword.passwordEmailSent") }}
                    <strong>{{ email }}</strong>
                </p>
            </div>
            <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <p class="text-center">
                    {{ $t("forgotPassword.ifYouDontReceiveEmail") }}
                </p>
                <div class="pb-4">
                    <a class="btn btn-primary btn-block" @click="signIn">{{ $t("forgotPassword.backToSignIn") }}</a>
                </div>
                <div class="pb-3">
                    <button class="btn btn-outline-primary btn-block" @click.prevent="resendResetPasswordRequest()"
                        :disabled="isButtonDisabled">
                        {{ $t("forgotPassword.resendEmail") }}
                    </button>
                </div>
            </div>
        </div>
        <!-- end of Sent Link to reset -->
        <div class="row mb-5 pb-5" v-if="isResetPasswordView">
            <div class="col-lg-6 col-12 offset-lg-3 text-center">
                <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
                <h2>
                    {{ $t("forgotPassword.resetPasswordFor") }} {{ email }}
                </h2>
                <p>
                    {{ $t("forgotPassword.enterNewPassword") }}
                </p>
            </div>
            <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <ValidationObserver ref="resetPasswordForm" v-slot="{ invalid }">
                    <form id="resetPasswordForm">
                        <div class="form-group">
                            <div v-if="errorMessage" class="my-3 alert alert-danger">
                                {{ errorMessage }}
                            </div>
                        </div>
                        <PasswordConfirmation :model="passwordConfirmationModel" @update="validatePassword"
                            passwordLabelText="forgotPassword.newPassword"
                            confirmPasswordLabelText="forgotPassword.confirmNewPassword" />
                        <div class="row">
                            <div class="col-6">
                                <a class="btn btn-outline-primary btn-block" @click="signIn">{{
                                        $t("forgotPassword.cancel")
                                }}</a>
                            </div>
                            <div class="col-6">
                                <button type="submit" class="btn btn-primary btn-block" :disabled="invalid"
                                    @click.prevent="savePassword()">
                                    {{ $t("forgotPassword.setPassword") }}
                                </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <!-- end of Reset Password view -->
        <div class="row mb-5 pb-5" v-if="isNewUserView">
            <div class="col-lg-6 col-12 offset-lg-3 text-center">
                <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
                <p>{{ $t("forgotPassword.createYourPasswordClickSave") }}</p>
            </div>
            <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <ValidationObserver ref="newUserPasswordForm" v-slot="{ invalid }">
                    <form id="newUserPasswordForm">
                        <div class="form-group">
                            <div v-if="errorMessage" class="my-3 alert alert-danger">
                                {{ errorMessage }}
                            </div>
                        </div>
                        <PasswordConfirmation :model="passwordConfirmationModel" @update="validatePassword"
                            passwordLabelText="forgotPassword.newPassword"
                            confirmPasswordLabelText="forgotPassword.confirmNewPassword" />
                        <div class="row">
                            <div class="col-6">
                                <router-link :to="{ name: 'Sign In' }" class="btn btn-outline-primary btn-block">{{
                                        $t("forgotPassword.cancel")
                                }}</router-link>
                            </div>
                            <div class="col-6">
                                <button type="submit" class="btn btn-primary btn-block" :disabled="invalid"
                                    @click.prevent="savePassword()">
                                    {{ $t("forgotPassword.saveButton") }}
                                </button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <!-- end of New User Password set up -->
        <div class="row" v-if="isLinkExpiredView">
            <div class="col-sm-6 col-md-6 offset-md-3 text-center">
                <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
                <h2 class="mt-3 mb-5">{{ $t("forgotPassword.welcomeToOrderingPortal") }}</h2>
                <div class="alert alert-danger text-left" role="alert">
                    <div><strong>{{ $t("forgotPassword.linkExpired") }}</strong></div>
                    <div>
                        {{ $t("forgotPassword.linkExpiredText") }}
                    </div>
                </div>
                <div class="col-sm-12 text-center mt-5">
                    <div class="pb-3">
                        <a class="btn btn-outline-primary mr-3" @click="signIn">{{ $t("forgotPassword.backToSignIn")
                        }}</a>
                        <button v-if="email" class="btn btn-primary" @click.prevent="resendResetPasswordRequest()">
                            {{ $t("forgotPassword.requestNewLink") }}
                        </button>
                        <button v-else class="btn btn-primary" @click.prevent="setViewResetPassword()">
                            {{ $t("forgotPassword.requestNewLink") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- end Password reset link expired -->
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { uiValidator } from "@/nucleus-modules/dd-nucleus-ui/mixins/uiValidator.js";
import { SEND_PASSWORD_RESET, VALIDATE_RESET_PASSWORD_LINK, VALIDATE_PASSWORD, RESET_PASSWORD } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import PasswordConfirmation from "@/nucleus-modules/dd-nucleus-ui/components/PasswordConfirmation";
import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";
import { MyPasswordMessageIDs } from "@/constants.js";

export const ViewTypes = Object.freeze({
    LINK_EXPIRED: "link-expired",
    LINK_SENT_CONFIRMATION: "link-sent",
    NEW_PASSWORD: "newpassword",
    NEW_USER: "newuser",
    RESET_PASSWORD: "reset-password",
});

export default {
    name: "ForgotPassword",
    props: {
        isModal: {
            type: Boolean,
            default: false
        },
    },
    mixins: [uiValidator],
    components: {
        PasswordConfirmation,
        HeaderBranding
    },
    data() {
        return {
            arePasswordsVisible: false,
            confirmPassword: "",
            email: "",
            errorMessages: [],
            errorMessage: null,
            isLoading: true,
            linkId: null,
            password: "",
            rules: [],
            viewType: "",
            isButtonDisabled: false,
            passwordConfirmationModel: {
                password: "",
                confirmPassword: "",
                isValid: false,
                rules: []
            },
            customError: false,
            myPasswordMessageIds: MyPasswordMessageIDs,
        };
    },
    computed: {
        hasErrors() {
            return this.errorMessages && this.errorMessages.length > 0;
        },
        isChangePasswordView() {
            return this.viewType === ViewTypes.NEW_PASSWORD;
        },
        isLinkExpiredView() {
            return this.viewType === ViewTypes.LINK_EXPIRED;
        },
        isLinkSentConfirmationView() {
            return this.viewType === ViewTypes.LINK_SENT_CONFIRMATION;
        },
        isNewUserView() {
            this.validatePassword();
            return this.viewType === ViewTypes.NEW_USER;
        },
        isRequestPasswordResetView() {
            return this.viewType === null && this.linkId === null;
        },
        isResetPasswordView() {
            this.validatePassword();
            return this.viewType === ViewTypes.RESET_PASSWORD;
        },
        passwordsMatch() {
            return this.password === this.confirmPassword;
        }
    },
    methods: {
        ...mapActions({
            sendPasswordReset: SEND_PASSWORD_RESET,
            validateResetPassword: VALIDATE_RESET_PASSWORD_LINK,
            validatePasswordAction: VALIDATE_PASSWORD,
            resetPassword: RESET_PASSWORD
        }),
        async postResetPasswordRequest() {
            this.isButtonDisabled = true;

            let payload = { "email": this.email }

            let response = await this.sendPasswordReset(payload);

            if (response.succeeded) {
                // Change the view if we successfully sent a link
                this.setView(ViewTypes.LINK_SENT_CONFIRMATION);
            } else {
                // Show an error message if something was wrong
                if (response && response.messages && response.messages.length) {
                    this.errorMessage = response.messages[0].debugMessage;
                    this.customError = true;
                    if (response.messages[0] && response.messages[0].messageId == this.myPasswordMessageIds.ACCESS_DENIED) {
                        this.errorMessage = "You have Regeneron email address and can't reset password";
                    }
                    else if (response.messages[0] && response.messages[0].messageId == this.myPasswordMessageIds.NOT_FOUND) {
                        this.errorMessage = " Email address " + this.email + " was not found";
                    }
                    else {
                        this.errorMessage = "Unable to find your account in the system. Please try again."
                    }
                }
            }


            this.isButtonDisabled = false;

        },
        async resendResetPasswordRequest() {
            await this.postResetPasswordRequest();
        },
        async sendResetPassword() {

            this.$refs.form.validate().then(async (success) => {
                if (success) {
                    await this.postResetPasswordRequest();
                } else {
                    this.errorMessage = this.$t("forgotPassword.enterValidEmail");
                }
            });

        },
        setView(viewType) {
            this.confirmPassword = "";
            this.errorMessages = [];
            this.isPasswordChangeValid = false;
            this.linkId = "";
            this.password = "";
            this.viewType = viewType || "";
        },
        setViewResetPassword() {
            this.$router.push(this.$route.path);
            this.viewType = this.linkId = null;
        },
        async savePassword() {
            const payload = {
                "linkid": this.$route.query.k,
                "newPassword": this.passwordConfirmationModel.password
            }

            const updateResponse = await this.resetPassword(payload);

            if (updateResponse.succeeded) {
                this.$router.push("sign-in?cp=true");
            } else {
                this.errorMessage = this.$t(updateResponse.messages[0].messageId);
            }
        },
        async validatePassword() {
            const response = await this.validatePasswordAction({
                "userName": this.email,
                "email": this.email,
                "password": this.passwordConfirmationModel.password,
                "confirmPassword": this.passwordConfirmationModel.confirmPassword
            });

            this.passwordConfirmationModel.rules = response.messages;
            this.passwordConfirmationModel.isValid = response.isValid;
        },
        async validateResetPasswordLink() {
            const payload = {
                "linkid": this.linkId
            };

            const validateResponse = await this.validateResetPassword(payload);

            if (validateResponse.succeeded) {
                // set the username to be reused if the link expires
                this.email = validateResponse.userName;

                await this.validatePassword();
                // Set the view if it isnt a new user
                if (this.viewType !== ViewTypes.NEW_USER) {
                    this.setView(ViewTypes.RESET_PASSWORD);
                }
            } else {
                // If the link expired show the expired view
                this.email = validateResponse.userName;
                this.setView(ViewTypes.LINK_EXPIRED);
            }
        },
        signIn() {
            if (this.isModal) {
                this.$emit('signIn');
            } else {
                this.$router.push({ name: 'Sign In' });
            }
        },
        async openIdSignIn() {
            await this.$auth.signInWithRedirect();
        }
    },
    async mounted() {
        this.validatePassword = this.$nucleus.debounce(this.validatePassword, 200);

        this.linkId = this.$route.query.k || null;
        this.viewType = this.$route.query.changePassword || null;

        if (this.viewType && (this.viewType === ViewTypes.NEW_PASSWORD || this.viewType === ViewTypes.NEW_USER)) {
            if (this.linkId === null) {
                this.$router.push("/sign-in");
            } else {
                // Validate link passed in query string
                await this.validateResetPasswordLink();
            }
        }

        this.isLoading = false;
    },
    watch: {
        viewType: {
            immediate: true,
            handler() {
                // Change the title based on which view we are using.
                let title = this.$t("forgotPassword.forgotPassword");

                switch (this.viewType) {
                    case ViewTypes.NEW_USER:
                        title = this.$t("forgotPassword.welcomeToClientName");
                        break;
                    case ViewTypes.LINK_EXPIRED:
                    case ViewTypes.LINK_SENT_CONFIRMATION:
                    case ViewTypes.NEW_PASSWORD:
                    case ViewTypes.RESET_PASSWORD:
                        title = this.$t("forgotPassword.resetPassword");
                        break;
                }

                document.title = title + " | " + this.$t("forgotPassword.clientName");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h2 {
    font-size: 1.25rem;
}

.alert-danger {
    color: $red;
    background-color: $gray-1;
    border-color: $gray-1;
}

.n-learn-more {
    color: $link-color;
}

.forgot-password {
    width: 100%;
}

.forgot-password h2 {
    text-transform: uppercase;
    color: $primary-dark-color;
}

.n-remember-password {
    color: $neutral-darker;
    font-size: 0.80rem;
}

.n-custom-error {
    font-size: 0.85rem;
    font-weight: 600;
}

::v-deep .n-password-validation-container {
    position: absolute;
    right: 20px;
    top: 10px;
}
</style>
