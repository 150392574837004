<template>
  <div>
    <div class="n-tag-panel">
      <ProductTileMobileMode
        v-if="isMobileOnly"
        :products="products"
        :isLoading="isLoading"
        :addItemToCart="addItemToCart"
        :noImageAvailableUrl="noImageAvailableUrl"
      ></ProductTileMobileMode>
      <ProductTileGridMode
        v-else
        :products="products"
        :columnWidth="columnWidth"
        :isLoading="isLoading"
        :addItemToCart="addItemToCart"
        :noImageAvailableUrl="noImageAvailableUrl"
      ></ProductTileGridMode>

      <!-- <ProductGridMode
        v-if="!isMobileOnly"
        :products="products"
        :addToCart="addToCart"
        :buttonLoading="buttonLoading"
        :columnWidth="columnWidth"
      ></ProductGridMode>
      <ProductMobileMode
        :products="products"
        :addToCart="addToCart"
        :buttonLoading="buttonLoading"
        v-if="isMobileOnly"
      ></ProductMobileMode> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isMobileOnly } from "@/nucleus-modules/dd-nucleus-ui/services/device-detect.service.js";
import { CART_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { CartMixin } from "@/nucleus-modules/dd-nucleus-storefront/mixins/CartMixin.js";
import ProductTileGridMode from "@/components/product/ProductTileGridMode.vue";
import ProductTileMobileMode from "@/components/product/ProductTileMobileMode.vue";

export default {
  name: "ProductGridPanel",
  mixins: [CartMixin],
  components: {
    ProductTileGridMode,
    ProductTileMobileMode,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      required: true,
    },
    panelThreshold: {
      type: Number,
      required: false,
    },
    noImageAvailableUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isMobileOnly: isMobileOnly,
    };
  },
  computed: {
    ...mapGetters({
      cart: CART_GETTER,
    }),
    columnWidth() {
      const threshold = this.panelThreshold ? this.panelThreshold : 3;

      return 12 / threshold;
    },
  },
  methods: {
    async addItemToCart(product) {
      await this.addToCart(this.cart.id, product, product.quantity);
    },
    // async addItemToCart(product) {
    //   const result = await this.addToCart({
    //     cartId: this.cart.id,
    //     productId: product.id,
    //     quantity: product.quantity,
    //   });

    //   if (result.succeeded) {
    //     const h = this.$createElement;
    //     const successMessage = h("span", [
    //       h("strong", `${product.itemNumber}`),
    //       " has been added to your cart",
    //     ]);
    //     ToastService.showToast([successMessage], MessageType.SUCCESS);
    //   } else {
    //     if (result.messages) {
    //       const firstMessage = result.messages.find((m) => m.length > 0);

    //       if (firstMessage) {
    //         ToastService.showToast(firstMessage.message, firstMessage.type);
    //       } else {
    //         ToastService.showToast(
    //           "Item was not added to cart.",
    //           MessageType.ERROR
    //         );
    //       }
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.n-tag-panel {
  width: 100%;
}
</style>
