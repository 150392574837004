<template>
  <div>
    <div>
      <div class="row">
        <div class="col-6">
          <PageHeaderTitle :title="title" :text="text" @cancel="cancel" />
        </div>
        <div class="col-6">
          <div class="text-right">
            <h3
              :class="[isActive ? 'n-user-status' : 'n-inactive-status']"
              v-if="!isNew"
            >
              <font-awesome-icon
                v-if="isActive"
                class="icon active"
                :icon="['fas', 'check-circle']"
              />
              <font-awesome-icon
                v-if="isInactive"
                class="icon inactive"
                :icon="['fas', 'times-circle']"
              />
              {{ userStatus }}
            </h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          <UserBadges :roles="user.roles" class="mb-2" v-if="!isNew" />
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <button
          v-if="!isNew && isActive"
            class="btn btn-sm btn-outline-primary mr-3"
            @click="impersonate()"
          >
            <font-awesome-icon :icon="['fas', 'user-friends']" /> Emulate User
          </button>
        </div>
        <div class="col-8 text-right">
          <div class="d-inline-flex">
            <ActionButtons
              :isValid="isValid"
              :isEditable="isEditable"
              :tooltipId="tooltipId"
              :tooltipMessage="tooltipMessage"
              @edit="edit"
              @cancel="cancel"
              @save="save"
            />

            <template v-if="isEditable && !isNew">
              <span class="mr-3">|</span>
              <button
                class="btn btn-success btn-sm"
                :disabled="isActive"
                v-if="isInactive"
                @click="activateUser()"
              >
                Activate User
              </button>
              <button
                class="btn btn-success btn-sm"
                :disabled="isInactive"
                v-if="isActive"
                @click="deactivateUser()"
              >
                Inactivate User
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import ActionButtons from "@/nucleus-modules/dd-nucleus-admin/components/ActionButtons";
import PageHeaderTitle from "@/nucleus-modules/dd-nucleus-admin/components/PageHeaderTitle";
import UserBadges from "@/nucleus-modules/dd-nucleus-admin/components/user/UserBadges";
import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import ImpersonateService from "@/nucleus-modules/dd-nucleus-admin/services/impersonate.service.js";

export default {
  name: "EditUserHeader",
  props: {
    user: { type: Object },
    title: { type: String },
    isNew: { type: Boolean },
    isValid: { type: Boolean },
    isEditable: { type: Boolean },
    tooltipId: { type: String },
    tooltipMessage: { type: String },
  },
  data() {
    return {
      text: "Back to All Users",
    };
  },
  components: {
    ActionButtons,
    PageHeaderTitle,
    UserBadges,
  },
  computed: {
    isActive() {
      return this.user.statusCode === StatusCodes.ACTIVE;
    },
    isInactive() {
      return this.user.statusCode === StatusCodes.INACTIVE;
    },
    userStatus() {
      if (this.isActive) {
        return "Active User";
      } else if (this.isInactive) {
        return "Inactive User";
      }
      return "Active User";
    },
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
    cancel(confirmed) {
      this.$emit("cancel", confirmed);
    },
    save(redirect) {
      this.$emit("save", redirect);
    },
    async activateUser() {
      const response = await ApiService.put(
        `api/v1/admin/user/${this.user.id}/reactivate`
      );

      if (response.succeeded) {
        this.user.statusCode = StatusCodes.ACTIVE;
      } else {
        ToastService.showErrorToast(response.messages[0]);
      }
    },
    async deactivateUser() {
      const response = await ApiService.put(
        `api/v1/admin/user/${this.user.id}/deactivate`
      );

      if (response.succeeded) {
        this.user.statusCode = StatusCodes.INACTIVE;
      } else {
        ToastService.showErrorToast(response.messages[0]);
      }
    },
    async impersonate() {
      let user = this.$route.params.user;
      let routeData = this.$router.resolve({ name: "Home" });
      await ImpersonateService.impersonate(user, routeData.href);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  padding: 0.5rem 2rem 0.5rem 2rem;
}
.n-user-status {
  color: $green;
  margin-top: 12px;
}
.n-inactive-status {
  color: $gray-2;
  margin-top: 12px;
}
.inactive {
  color: $gray-2;
}
.modal-footer {
  justify-content: center;
}
.modal-title {
  color: $tertiary-dark-color;
}
.modal-body {
  padding-top: 16px;  
}
.active {
  color: $green;
}
</style>
