<template>
  <div class="reportTitle">
      <div class="pl-3"><h5 class="n-form-section-header">{{title}}</h5></div>
      <hr class="report-hr" />
  </div>
</template>

<script>
    export default {
        name: "ReportViewTitle",
        props: {
            title: String
        },
    }
</script>

<style lang="scss" scoped>
.reportTitle {
    width:100%;
}
.n-form-section-header {
    color: $admin-primary-dark-color;
}
.report-hr {
   margin: 10px 0 5px 15px;
}

</style>