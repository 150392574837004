<template>
  <div class="card">
    <div class="card-header" v-if="title && title.length">
      {{title}}
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div class="card-footer" v-if="hasFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: "panel",
    props: ["title"],
    computed: {
      hasFooter() {
        return !!this.$slots.footer;
      }
    }
  }
</script>
<style scoped lang="scss">
  ul {
    list-style-type: none;
  }

  .card {
    margin: 10px 0;
  }

  .card-header, .card-footer {
    background: $primary-color;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 1.125rem;
  }

  .card-body {
    background: $primary-lightest-color;
  }
</style>