<template>
  <div>
    <h5 class="n-form-section-header">Product Size</h5>
    <div class="form-group mt-4">
        <label class="n-form-labels">Unit of Measure (UOM)</label>
        <select class="form-control n-text-field-highlight"
                v-model="selectedUnitOfMeasure"
                @change="setSelectedUnitOfMeasure()">
            <option v-for="uom in unitsOfMeasure"
                    :key="uom.code"
                    :value="uom.code">
                {{ uom.name }}
            </option>
        </select>
    </div>
    <div class="form-group mt-4">
        <label class="n-form-labels">Unit of Measure Quantity (Pieces Per Unit)</label>
        <input type="number"
               class="form-control n-text-field-highlight"
               :class="{ 'is-invalid': $v.quantityPerUnit.$invalid }"
               v-model.trim="quantityPerUnit"
               @input="$v.quantityPerUnit.$touch()" />
    </div>
    <div v-if="$v.quantityPerUnit.$invalid" class="n-error">
      <span v-if="!$v.quantityPerUnit.required"
        >A quantity per unit is required.</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  PRODUCT_FOR_EDIT_GETTER,
  PRODUCT_PROPERTY_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

const ATTRIBUTE_NAME = "Inventory";

export default {
  name: "ProductSize",
  props: {
    unitsOfMeasure: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedUnitOfMeasure: null,
    };
  },
  computed: {
    ...mapGetters({
      getProductProperty: PRODUCT_PROPERTY_GETTER,
      product: PRODUCT_FOR_EDIT_GETTER,
    }),
    quantityPerUnit: {
      get() {
        return this.getProductProperty("quantityPerUnit", ATTRIBUTE_NAME);
      },
      set(value) {
        this.setProductProperty({
          name: "quantityPerUnit",
          value: value,
          attribute: ATTRIBUTE_NAME,
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
    setSelectedUnitOfMeasure() {
      this.setProductProperty({
        attribute: ATTRIBUTE_NAME,
        name: "unitOfMeasure",
        value: this.selectedUnitOfMeasure,
      });
    },
  },
  created() {
    const assignedUom = this.getProductProperty(
      "unitOfMeasure",
      ATTRIBUTE_NAME
    );

    if (assignedUom) {
      this.selectedUnitOfMeasure = this.unitsOfMeasure.find(
        (uom) => uom.code === assignedUom
      ).code;
    }
  },
  mounted() {
    this.setProductProperty = this.$nucleus.debounce(
      this.setProductProperty,
      200
    );
  },
  validations: {
    quantityPerUnit: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped></style>
