<template>
    <div class="n-product-tab">
        <h4>
            Activity            
        </h4>
        <VueGoodTable :columns="columns"
                      :rows="activity"
                      :sort-options="sortConfig"
                      styleClass="vgt-table striped"
                      class="mb-5">
            <template slot="table-row" slot-scope="props">   
                <span v-if="props.column.type === 'date'">
                    {{
                      props.formattedRow[props.column.field] | moment(dateFormat)
                    }}
                </span>
            </template>
        </VueGoodTable>        
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ORDER_DETAIL_GETTER, ORDER_PROPERTY_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';


export default {
    name: 'ActivityTab',
    components: {
        VueGoodTable,
    },
    data() {
        return {
            columns: [],
            sortConfig: {},
            dateFormat: "MM/DD/YYYY h:mm a",
            isEdit: false
        }
    },
    computed: {
        ...mapGetters({
            orderDetails: ORDER_DETAIL_GETTER,
            getOrderProperty: ORDER_PROPERTY_GETTER
        }),
        activity(){
            let activities = this.getOrderProperty("activity");
            activities = activities.sort((a, b) => (new Date(b.created) > new Date(a.created) ? 1 : -1));
            return activities; 
        },       
    },
    methods: {       
        setColumns() {           
            this.columns.push({
                label: "Created Date",
                field: "created",
                type: "date",
                width: '100px',
                formatFn: function (value) {
                    return value == null ? "" : value;
                },
            });
            this.columns.push({
                label: "Type",
                field: "type",
                type: "string",
                width: '100px'                
            });
            this.columns.push({
                label: "Field",
                field: "field",
                type: "string",
                width: '100px'
            });
            this.columns.push({
                label: "Old Value",
                field: "oldValue",
                width: '100px',
                type: "string"
            });
            this.columns.push({
                label: "New Value",
                field: "newValue",               
                width: '100px',
                type: "string"
            });
            this.columns.push({
                label: "Edited By",
                field: "createdBy",
                width: '90px',                
                type: "string"
            });
        },        
        setSortConfig() {
            this.sortConfig = {
                enabled: true,
            };
        },        
    },
    mounted () {
        this.setColumns();
        this.setSortConfig();        
        },   
}
</script>

<style lang="scss">
    .n-product-tab {
        h4 span {
            color: $gray-3;
            font-family: $body-font;
            font-size: 1rem;
            line-height: 1.5;
        }
    }
</style>