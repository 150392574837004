import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

export default {
    async addReport(reportDetails) {

        const response = await ApiService.post("ui/admin/reports", reportDetails);
        if (response.succeeded) {
            return response;
        }
        return {};
    },
    async getReports(payload) {
        let reportsList = [];
        const response = await ApiService.get('ui/admin/reports', payload);

        if (response.succeeded) {
            reportsList = response.reports;
        }

        return reportsList;
    },
    async getEmbedToken(payload) {
        return await ApiService.get(`ui/admin/reports/get-external-report-token?externalId=${payload.externalId}&reportId=${payload.reportId}`);
    },
    async getReportGroups() {
        let reportGroups = [];

        const response = await ApiService.get('ui/admin/reports/groups');

        if (response.succeeded) {
            reportGroups = response.reportGroups;
        }

        return reportGroups;
    },
    async getReportDetail(id) {
        let returnResponse = {};

        const response = await ApiService.get('ui/admin/reports/detail?reportId=' + id);

        if (response.succeeded) {
            returnResponse = response;
        }

        return returnResponse;
    },

    async deleteReport(id) {
        return await ApiService.post('ui/admin/reports/remove?reportId=' + id);
    },
}
