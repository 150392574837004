<template>
  <PageTemplate>
    <a class="n-back-product-search-results" @click="$router.go(-1)">Back</a>
    <div class="n-product-detail" v-if="productDetail">
      <div v-show="isProductLoading">
        <div class="row">
          <div class="col-sm-6">
            <Skeleton width="450px" height="450px" class="d-none d-sm-none d-md-block d-lg-block d-xl-block"></Skeleton>
            <Skeleton width="100%" height="350px" class="d-block d-sm-block d-md-none d-lg-none d-xl-none"></Skeleton>
          </div>
          <div class="col-sm-6">
            <Skeleton v-for="x in 6" :key="x" :min-width="200"></Skeleton>
          </div>
        </div>
      </div>
      <div v-if="!isProductLoading">
        <div v-if="productDetail">
          <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-7 n-media-container">
              <MediaSlideShow v-if="totalMediaItems > 0" :mediaItems="productDetail.media" :mediaTypes="mediaTypes"
                :noImageAvailableUrl="noImageAvailableUrl" :showThumbNavigation="true"
                v-on:marquee-clicked="onMarqueeClicked"></MediaSlideShow>
              <div v-if="totalMediaItems == 0" class="text-center n-main-image">
                <img :src="noMainImageAvailableUrl" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5 mt-4 mt-sm-0">
              <div class="row d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <div class="col-12">
                  <div class="badges" v-for="badge in productDetail.tags" :key="badge.id">
                    <span class="badge badge-primary">{{ badge.name }}</span>
                  </div>
                  <span v-if="isAddOnItem" class="badge badge-primary add-on-badge">Add-on item</span>
                </div>
              </div>
              <div class="row d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <div class="col-12">
                  <h1 class="n-title-text">{{ productDetail.name }}</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2 class="n-product-sku">{{ productDetail.itemNumber }}</h2>
                </div>
              </div>              
              <template v-if="storeSetup.ratingsEnabled">
                <div class="row" v-if="productDetail.rating !== null">
                  <div class="col-4">
                    <!-- Docs with all parameter are here https://www.npmjs.com/package/vue-star-rating -->
                    <star-rating v-model="productDetail.rating.value" v-bind:increment="0.5" v-bind:max-rating="5"
                      v-bind:show-rating="false" v-bind:star-size="15" v-bind:border-width="2" v-bind:padding="3"
                      inactive-color="#fff" active-color="#ffb500" border-color="#ba9143">
                    </star-rating>
                  </div>
                  <div class="col-8">
                    <div class="n-total-ratings">
                      <router-link :id="productDetail.id" :title="productDetail.title"
                        :to="`/productDetail-detail/${productDetail.id}`">
                        {{ productDetail.rating.totalRatings }}
                        {{ $t("productDetail.totalRatingsTitle") }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div v-if="productDetail.rating === null" class="row">
                  <div class="col-12 n-total-ratings">
                    <router-link :id="productDetail.id" :title="productDetail.title"
                      :to="`/productDetail-detail/${productDetail.id}`">
                      {{ $t("productDetail.addRatingTitle") }}
                    </router-link>
                  </div>
                </div>
              </template>
              <div v-if="storeSetup.pricingEnabled" class="row">
                <div class="col-12">
                  <div v-if="productDetail.isOnSale" class="n-price">
                    {{ productDetail.salePrice | currency }}
                    <span class="n-price-on-sale">{{
                    $t("shared.pricing.salePriceWas")
                    }}</span>
                    <span class="n-price-on-sale">
                      {{ productDetail.unitPrice | currency }}
                    </span>
                  </div>
                  <div class="n-price" v-else>
                    {{ productDetail.unitPrice | currency }}
                  </div>
                </div>
              </div>
              <div class="row" v-if="hasMessages && isRegularItem">
                <div class="col-12 n-message-list">
                  <MessageList :messages="productDetail.messages" />
                </div>
              </div>
              <div v-if="isRegularItem">
                <div v-if="inventory.quantityAvailable > 0" class="row">
                  <div class="col-sm-9 col-md-6 col-5 col-lg-4">
                    <label class="n-quantity">
                      {{ $t("productDetail.quantityTitle") }}:
                    <QuantityInput :eventName="UiEvents.QUANTITY_INPUT_CHANGED" :item="productDetail" :min="1" :key="productDetail.id"/>
                    </label>
                  </div>
                  <div class="col-sm-3 col-md-6 col-7" v-if="inventory.unitOfMeasure">
                    <label class="n-unit-of-measure">
                      <Uom :uom="inventory.unitOfMeasure" :unitQuantity="inventory.quantityPerUnit" />
                    </label>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-12">
                    <div class="n-inventory-message">
                      {{ $t("productDetail.outOfStockText") }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isAddOnItem" class="row">
                <div class="col-sm-9 col-md-6 col-5 col-lg-4">
                  <label class="n-quantity">
                    {{ $t("productDetail.quantityTitle") }}:
                  </label>
                  <div>
                  <span>{{ productDetail.addOnQuantity }}  </span>
                  <label v-if="inventory.unitOfMeasure" >
                    <Uom :uom="inventory.unitOfMeasure" :unitQuantity="inventory.quantityPerUnit" />
                  </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button v-if="inventory.quantityAvailable > 0 && isRegularItem" :id="'button-' + productDetail.id"
                    class="btn btn-primary w-50 n-add-to-cart" :disabled="!(productDetail.quantity > 0)"
                    @click.prevent="addItemToCart(productDetail)">
                    <span v-if="addToCartLoading">
                      <b-spinner small></b-spinner>
                      {{ $t("productDetail.cartLoadingButtonText") }}...
                    </span>
                    <span v-else>
                      {{ $t("productDetail.addToCartButtonText") }}
                    </span>
                  </button>
                  <template v-if="isAddOnItem">
                    <span v-if="addOnItemInCart(productDetail.id)" class="n-item-added">
                      This item has already been added to your cart.
                    </span>
                    <button :id="'button-' + productDetail.id" class="btn btn-primary w-50 n-add-to-cart"
                      :disabled="addOnItemInCart(productDetail.id)" @click.prevent="addOnItemToCart(productDetail.addOnQuantity)">
                  
                      <span v-if="addToCartLoading">
                        <b-spinner small></b-spinner>
                        {{ $t("productDetail.cartLoadingButtonText") }}...
                      </span>
                      <span v-else>
                        {{ $t("productDetail.addToCartButtonText") }}
                      </span>
                    </button>
                  </template>
                </div>
              </div>
              <div v-if="
                productDetail.isDownloadable && productDetail.isPreviewable
              " class="row">
                <div class="col-sm-6 col-12">
                  <button v-if="productDetail.isPreviewable" class="btn btn-outline-primary btn-block">
                    {{ $t("productDetail.previewButtonText") }}
                  </button>
                </div>
                <div class="col-sm-6 col-12">
                  <button v-if="productDetail.isDownloadable" class="btn btn-outline-primary btn-block">
                    {{ $t("productDetail.downloadButtonText") }}
                  </button>
                </div>
              </div>
              <div v-if="
                productDetail.isDownloadable && !productDetail.isPreviewable
              " class="row">
                <div class="col-12">
                  <button class="btn btn-outline-primary btn-block">
                    {{ $t("productDetail.downloadButtonText") }}
                  </button>
                </div>
              </div>
              <div v-if="
                productDetail.isPreviewable && !productDetail.isDownloadable
              " class="row">
                <div class="col-12">
                  <button v-if="productDetail.isPreviewable" class="btn btn-outline-primary btn-block">
                    {{ $t("productDetail.previewButtonText") }}
                  </button>
                </div>
              </div>
              <div class="row" v-if="productDetail.description">
                <div class="col-12 mt-3 n-productDetail-description">
                  <div class="font-weight-bold">Description:</div>
                  <span v-html="productDetail.description"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <FavoriteProductIcon v-if="!isProductLoading && !isAddOnItem" :id="productDetail.id"
                    :isFavorite="isFavoriteProduct(productDetail.id)" :showText="false" :showIconToAuthUsersOnly="true"
                    addFavoriteTextId="shared.favoriteIcon.addText"
                    removeFavoriteTextId="shared.favoriteIcon.removeText" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="hasRelatedProducts" class="row">
            <div class="col-sm-12">
              <h2 class="n-related-products">
                {{ $t("productDetail.relatedProductsText") }}
              </h2>
              <hr />
              <ProductGridMode v-if="isBrowser" :products="productDetail.relatedProducts" :addToCart="addToCart"
                productsWidth="4"></ProductGridMode>
              <ProductGridMode v-if="isTablet" :products="productDetail.relatedProducts" :addToCart="addToCart"
                productsWidth="3"></ProductGridMode>
              <ProductMobileMode v-if="isMobileOnly" :products="productDetail.relatedProducts" :addToCart="addToCart">
              </ProductMobileMode>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-5 pt-5 mb-5 pb-5 n-no-availalble-text text-center">
            <span>{{ $t("productDetail.productNotAvailableText") }} </span>
            <router-link :to="'/'">{{
            $t("productDetail.returnHomeLinkText")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </PageTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MediaSlideShow from "@/nucleus-modules/dd-nucleus-ui/components/MediaSlideShow.vue";
import QuantityInput from "@/nucleus-modules/dd-nucleus-ui/components/QuantityInput.vue";
import Skeleton from "@/nucleus-modules/dd-nucleus-ui/components/Skeleton.vue";
import Uom from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";
// import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import MessageList from "@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue";
import {
  // MessageType,
  UiEvents,
} from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import {
  GET_PRODUCT_DETAIL,
  GET_FAVORITES,
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type";
import {
  CART_GETTER,
  PRODUCT_DETAIL_GETTER,
  SITE_SETUP_GETTER,
  FAVORITES_GETTER,

} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { CartMixin } from "@/nucleus-modules/dd-nucleus-storefront/mixins/CartMixin.js";

import FavoriteProductIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteProductIcon.vue";

// Modal Imports
import ModalService from "@/nucleus-modules/dd-nucleus-ui/services/modal.service.js";
import imageModal from "@/nucleus-modules/dd-nucleus-ui/components/ModalImage";

// eslint-disable-next-line no-unused-vars
import * as StorefrontConstants from "@/nucleus-modules/dd-nucleus-storefront/constants.js";

import PageTemplate from "@/pages/templates/PageTemplate.vue";

import {
  noImageAvailableUrl,
  noMainImageAvailableUrl,
} from "@/companyAppConfig.js";

export default {
  name: "ProductDetail",
  mixins: [CartMixin],
  components: {
    MediaSlideShow,
    Skeleton,
    PageTemplate,
    FavoriteProductIcon,
    MessageList,
    QuantityInput,
    Uom,
  },
  data() {
    return {
      addToCartLoading: false,
      isProductLoading: true,
      quantity: 1,
      UiEvents: Object.freeze(UiEvents),
      noImageAvailableUrl: noImageAvailableUrl,
      noMainImageAvailableUrl: noMainImageAvailableUrl,
      productDetail: null
    };
  },
  computed: {
    ...mapGetters({
      cart: CART_GETTER,
      productDetailC: PRODUCT_DETAIL_GETTER,
      storeSetup: SITE_SETUP_GETTER,
      favoriteProducts: FAVORITES_GETTER,
    }),
    badges() {
      return StorefrontConstants.Badges;
    },
    hasRelatedProducts() {
      return (
        this.productDetail &&
        this.productDetail.relatedProducts &&
        this.productDetail.relatedProducts.length > 0
      );
    },
    inventory() {
      let result = {};

      if (
        this.productDetail &&
        this.productDetail.canAddToCart &&
        this.productDetail.attributes &&
        this.productDetail.attributes.Inventory
      ) {
        result = this.productDetail.attributes.Inventory;
      }

      return result;
    },
    mediaTypes() {
      return StorefrontConstants.MediaTypes;
    },
    totalMediaItems() {
      if (this.productDetail.media && this.productDetail.media.length) {
        return this.productDetail.media.length;
      }

      return 0;
    },
    hasMessages() {
      return (
        this.productDetail &&
        this.productDetail.messages &&
        this.productDetail.messages.length
      );
    },
    queryProductId() {
      return this.$route.params.productId;
    }, 
    hasCartItem() {
      return this.cart && this.cart.items && this.cart.items.length
    },
    isRegularItem() {
      return !this.isAddOnItem;
    },
    isAddOnItem() {
      if (this.productDetail && this.productDetail.isAddOn) {
        return this.productDetail.isAddOn;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getProductDetail: GET_PRODUCT_DETAIL,
      getFavorites: GET_FAVORITES,
    }),
    async addItemToCart() {
      await this.addToCart(this.cart.id, this.productDetail, this.quantity);
    },
    async addOnItemToCart() {
      await this.addToCart(this.cart.id, this.productDetail, this.productDetail.addOnQuantity);
    },
    deregisterListeners() {
      this.$eventBus.$off(UiEvents.QUANTITY_INPUT_CHANGED);
    },
    initListeners() {
      this.$eventBus.$on(UiEvents.QUANTITY_INPUT_CHANGED, (eventData) => {
        this.quantity = eventData.item.quantity;
      });
    },
    async loadProductDetail() {
      await this.getProductDetail({
        productId: this.queryProductId,
      });
      this.productDetail = this.productDetailC;
      this.productDetail = Object.assign({}, this.productDetail, {quantity : 1});
      this.quantity = 1;
    },
    // TODO: move this to PageTemplate?
    async loadstoreSetup() {
      if (!this.storeSetup) {
        await this.getSiteStartup();
      }
    },
    onMarqueeClicked(mediaItem) {
      mediaItem.name = this.productDetail.name;
      ModalService.open(imageModal, mediaItem);
    },

    // TODO: can this logic be stored in the Favorite component?
    toggleFavorite() {
      if (this.productDetail.isFavorite) {
        // TODO: remove favorite
      } else {
        this.addFavorite({ productId: this.productDetail.id });
      }
    },
    isFavoriteProduct(id) {
      return this.favoriteProducts.some((product) => {
        return product.id == id;
      })
    },
    async loadProduct() {
      await this.loadProductDetail();
      this.isProductLoading = false;
      await this.getFavorites();
    },
    addOnItemInCart(productId) {
      if (productId && this.hasCartItem) {
        const result = this.cart.items.find((p) => p.product.id == productId);
        return result;
      }
    }
  },
  beforeDestroy() {
    this.deregisterListeners();
  },

  async mounted() {
    this.initListeners();
  },
  //Added watcher as mounted will not be called 
  //if you are in the item details page and searching different item using omni search
  // and tried to go the next item details.
  watch: {
    "$route.params.productId": {
      handler(to, from) {
        if (to != from) {
          this.loadProduct();
        }
      },
      immediate: true,
    }
  },
};
</script>

<style scoped lang="scss">
.n-modal-image img {
  width: 100%;
}

.modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.n-thumb-nails {
  display: inline-block;
  padding: 20px 10px;
}

.n-thumb-nails span {
  width: 100%;
  height: 100%;
}

.n-active-thumb {
  border: 3px solid $secondary-color;
}

.form-control.n-quantity-box {
  color: $gray-3;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 8px;
}

.btn-primary {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-outline-primary {
  margin-top: 12px;
  margin-bottom: 16px;
}

.n-related-products {
  font-size: 18px;
  margin-top: 40px;
}

.n-thumbnail-label {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 4px;
  color: $neutral-mid;
}

.n-favorite {
  color: $primary-color;
  font-size: 14px;
}

.n-favorite div {
  display: inline-block;
}

.n-left-arrow,
.n-right-arrow {
  position: absolute;
  z-index: 5;
  top: 210px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: inline-block;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  line-height: 38px;
}

.n-right-arrow {
  padding-left: 5px;
  right: 25px;
}

.n-left-arrow {
  left: 25px;
  padding-right: 5px;
}

.n-thumb-nails img {
  max-width: 100px;
  height: auto;
}

.n-unit-of-measure {
  margin-top: 34px;
}

.n-productDetail-description {
  margin-top: 8px;
  color: $neutral-darker;
}

.n-total-ratings {
  padding-top: 4px;
  display: block;
}

.n-quantity {
  margin-top: 6px;
  font-weight: bold;
}

.n-order-limits {
  font-size: 13px;
}

.badge {
  font-size: 100%;
  margin-bottom: 12px;
}

.n-was-price {
  color: $gray-3;
  font-size: 14px;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 2px;
}

.n-now-price {
  color: $red;
  font-size: 14px;
  font-weight: bold;
  padding-right: 2px;
}

.n-main-image img {
  cursor: pointer;
}

.n-no-availalble-text {
  font-size: 1.5rem;
  font-weight: 600;
}


@media (min-width: 1025px) {
  .n-main-image {
    min-height: 450px;
  }

  .n-main-image img {
    max-height: 450px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .n-left-arrow,
  .n-right-arrow {
    top: 105px;
  }

  .n-total-ratings {
    padding-left: 20px;
  }

  .n-add-to-cart {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .n-dot-nav {
    color: $gray-2;
  }

  .n-dot-nav.n-active-thumb {
    color: $neutral-darker;
    border: none;
  }

  .n-title-text-mobile {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .badge-info {
    margin-top: 20px;
  }

  .n-unit-of-measure {
    font-size: 14px;
    margin-left: 0px;
  }
}

@media (max-width: 576px) {
  .badge {
    font-size: 80%;
  }

  .n-title-text-mobile {
    font-size: 16px;
    padding-top: 0px;
  }

  .n-productDetail-sku {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  hr {
    border-top: 1px solid #ebebeb;
    margin: 10px 0 8px 0;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .n-product-detail {
    width: 90%;
    margin: 20px auto;
  }

  .n-back-product-search-results {
    margin-left: 5%;
  }
}

p {
  margin-bottom: 0 !important;
}

.n-item-added
{
  color:#a82525;
  font-weight:bold;
  font-size:18px
}

</style>
