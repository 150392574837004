<template>
    <PageTemplate :showHeader="true">
        <div class="row test-page">
            <div class="col-12 text-left">
                <h1>
                    Test Page
                </h1>
                <h2>Shipping Methods</h2>
                <b-form-select v-model="selectedShipMethod"
                               :options="shipMethods"
                               value-field="code"
                               text-field="name"
                               class="n-text-field-highlight">
                </b-form-select>
                <span v-if="selectedShipMethod">
                    Selected Ship Method : {{ selectedShipMethod}} - {{getShipMethodName(selectedShipMethod)}}  
                  </span> 
            </div>
        </div>
    </PageTemplate>
</template>

<script>
import PageTemplate from "@/pages/templates/PageTemplate";
import { mapGetters } from "vuex";
import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { defaultShipMethod } from "@/companyAppConfig.js"
export default {
  name: "TestPage",
  components: {
    PageTemplate
  },
  data() {
    return {
        selectedShipMethod: defaultShipMethod 
    }
  },
  computed: {
    ...mapGetters({
      siteSetup : SITE_SETUP_GETTER
    }),
    shipMethods() {
       if(this.siteSetup) {
         let vm = this;
         let userRoles = [] ;
         if(this.siteSetup.user && this.siteSetup.user.roles) {
           userRoles = this.siteSetup.user.roles.map(r => r.id);
         }
         let shipMethods = this.siteSetup.shipMethods;
         if(shipMethods){
           let allShipMethods = []
           let commonShipMethods = shipMethods.filter(sm => userRoles.includes(sm.roleId) || vm.$nucleus.isEmpty(sm.roleId));
           if(commonShipMethods && commonShipMethods.length) {
             allShipMethods = Array.prototype.concat.apply([], commonShipMethods.map(sm => sm.values));
           }
           return allShipMethods;
         }
         else {
           return []
         }
       }
       else {
           return []
         }
    }
  },
  methods: {
     getShipMethodName(shipCode) {
       let shipMethod =  this.shipMethods.find(sm => sm.code == shipCode)
       if(shipMethod) {
         return shipMethod.name
       }
       return ""
     }
  }
}
</script>

<style lang="scss" scoped>
    h1 {
        padding: 30px 0;
    }

    test-page {
        min-height: 300px;
    }
</style>
