<template>
    <AdminTemplate>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 pl-1 n-primary">
                        <PageHeaderTitle :title="title" :text="text" @cancel="cancel" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-8 text-right">
                        <div class="d-inline-flex">
                            <ActionButtons :isValid="isValid"
                                           :isEditable="isEditable"
                                           :tooltipMessage="
                  $t('Storefront.RequiredFieldsToSaveAnnouncement')
                "
                                           tooltipId="headerWrapper"
                                           @edit="edit"
                                           @cancel="cancel"
                                           @save="save" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 n-mt-2">
                <ContentManagementHeader :title="title" />
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-7" :class="{ 'pb-3': !editMode }">
                    <h5 class="n-form-section-header">Homepage Announcement</h5>
                </div>
                <div class="row col-3">
                    <div v-if="isEditable">
                        <label class="pt-3 pl-3 pr-3 n-form-labels display-flex">Published</label>
                        <b-form-checkbox class="custom-switch"
                                         v-model="isPublished"
                                         name="isPublished">
                        </b-form-checkbox>
                    </div>
                    <div v-else>
                        <label class="n-form-labels pl-3 pt-3">Published</label>
                        <div v-if="isPublished">
                            <label class="n-form-labels font-weight-normal pl-3">Yes</label>
                        </div>
                        <div v-else>
                            <label class="n-form-labels font-weight-normal pl-3">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="isEditable">
                <div v-if="editMode">
                    <h5 class="n-form-section-header n-warning">Edit Announcement</h5>
                </div>
                <div :class="{ 'pt-3': !editMode }">
                    <AnnouncementInfo :announcement="announcementForEdit" />
                </div>
            </template>
            <template v-else>
                <AnnouncementInfoViewOnly :announcement="announcementForEdit" />
            </template>
        </div>
        <template>
            <div>
                <div class="row">
                    <div class="col-12">
                        <b-tabs content-class="mt-3" id="announcementUserRoleTabs">
                            <template v-slot:title>
                                <span class="mt-2">Roles</span>
                            </template>
                            <template v-if="isEditable">
                                <div :class="{ 'pt-3': !editMode }">
                                    <AnnouncementUserRoles :selectedRoleIds="announcementForEdit.userRoleIds"
                                                           :roles="allRoles"
                                                           :isDefault="isDefault" />
                                </div>
                            </template>
                            <template v-else-if="!announcementForEdit.isDefault">
                                <AnnouncementUserRolesViewOnly :userRoleNames="selectedRoleNames" />
                            </template>
                        </b-tabs>
                    </div>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-4"></div>
                <div class="col-8 text-right">
                    <div class="d-inline-flex">
                        <ActionButtons :isValid="isValid"
                                       :isEditable="isEditable"
                                       :tooltipMessage="
                $t('Storefront.RequiredFieldsToSaveAnnouncement')
              "
                                       tooltipId="headerWrapper"
                                       @edit="edit"
                                       @cancel="cancel"
                                       @save="save" />
                    </div>
                </div>
            </div>
            <br />
        </template>
    </AdminTemplate>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from "vuex";
    import ActionButtons from "@/nucleus-modules/dd-nucleus-admin/components/ActionButtons";
    import PageHeaderTitle from "@/nucleus-modules/dd-nucleus-admin/components/PageHeaderTitle";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
    import AnnouncementInfo from "@/components/admin/content-management/AnnouncementInfo.vue";
    import AnnouncementUserRoles from "@/components/admin/content-management/AnnouncementUserRoles.vue";
    import AnnouncementInfoViewOnly from "@/components/admin/content-management/AnnouncementInfoViewOnly.vue";
    import AnnouncementUserRolesViewOnly from "@/components/admin/content-management/AnnouncementUserRolesViewOnly.vue";
    import AdminTemplate from "@/pages/templates/AdminTemplate";
    import ContentManagementHeader from "@/components/admin/content-management/ContentManagementHeader.vue";
    import {
        GET_ANNOUNCEMENT_FOR_EDIT,
        GET_ALL_ROLES,
        SAVE_ANNOUNCEMENT,
        PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT,
    } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
    import {
        ALL_ROLES_GETTER,
        ANNOUNCEMENT_FOR_EDIT_GETTER,
    } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import {
        SET_ANNOUNCEMENT_FOR_EDIT,
        SET_ANNOUNCEMENT_PROPERTY,
        SET_IS_ANNOUNCEMENT_EDIT_DIRTY,
    } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    import {
        SAVE_ANNOUNCEMENT_ERROR_GETTER,
        IS_ANNOUNCEMENT_EDIT_DIRTY_GETTER,
    } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

    import { CLEAR_ERRORS } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
    import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";

    export default {
        name: "AddEditAnnouncement",
        components: {
            ActionButtons,
            AnnouncementUserRoles,
            AnnouncementInfo,
            AdminTemplate,
            ContentManagementHeader,
            AnnouncementInfoViewOnly,
            AnnouncementUserRolesViewOnly,
            PageHeaderTitle,
        },
        props: {
            id: {
                type: String,
                required: false,
            },
            editable: {
                type: Boolean,
                required: false,
            },
            refresh: {
                type: Boolean,
                required: false,
            },
            new: {
                type: Boolean,
                required: false,
            },
        },
        data() {
            return {
                editorConfig: {
                    height: 200,
                    menubar: false,
                    content_style: "p { margin: 0; }",
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "lists",
                        "link",
                    ],
                    toolbar:
                        "undo redo | bold | italic | charmap | underline | p | forecolor | bullist | numlist | link",
                },
                text: "Back to All Homepage Announcement",
                announcement: null,
                isEditable: this.editable,
                editMode: true,
                initVal: this.initialAnnouncement,
                selectedRoleIds: [],
                selectedRoleNames: [],
                title: "Homepage Announcement",
                isSaved: false,
                initialAnnouncement: null
            };
        },
        computed: {
            ...mapGetters({
                allRoles: ALL_ROLES_GETTER,
                announcementForEdit: ANNOUNCEMENT_FOR_EDIT_GETTER,
                saveAnnouncementError: SAVE_ANNOUNCEMENT_ERROR_GETTER,
                isAnnouncementEditDirty: IS_ANNOUNCEMENT_EDIT_DIRTY_GETTER,
            }),

            isDefault: {
                get() {
                    return this.announcementForEdit.isDefault;
                },
                set(value) {
                    this.setAnnouncementProperty({ name: "isDefault", value: value });
                },
            },

            isPublished: {
                get() {
                    return this.announcementForEdit.isPublished;
                },
                set(value) {
                    this.setAnnouncementProperty({ name: "isPublished", value: value });
                },
            },
            isValid() {
                return this.valid();
            },
        },

        methods: {
            ...mapActions({
                getAllRoles: GET_ALL_ROLES,
                getAnnouncementForEdit: GET_ANNOUNCEMENT_FOR_EDIT,
                saveAnnouncement: SAVE_ANNOUNCEMENT,
                clearErrors: CLEAR_ERRORS,
                publishOrUnpublishAnnouncement: PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT,
            }),
            ...mapMutations({
                setAnnouncementForEdit: SET_ANNOUNCEMENT_FOR_EDIT,
                setAnnouncementProperty: SET_ANNOUNCEMENT_PROPERTY,
                setIsAnnouncementEditDirty: SET_IS_ANNOUNCEMENT_EDIT_DIRTY,
            }),
            valid() {
                if (this.announcementForEdit.isDefault && this.isFieldNullorEmpty()) {
                    return true;
                } else if (
                    !this.announcementForEdit.isDefault &&
                    !this.$nucleus.isEmpty(this.announcementForEdit.userRoleIds) &&
                    this.isFieldNullorEmpty()
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            getRoleName(roleId) {
                if (this.allRoles.some((role) => role.id == roleId)) {
                    return this.allRoles.filter((role) => role.id == roleId)[0].name;
                }
                return "";
            },

            isFieldNullorEmpty() {
                return (
                    !this.$nucleus.isEmpty(this.announcementForEdit.name) &&
                    !this.$nucleus.isEmpty(this.announcementForEdit.startDate) &&
                    !this.$nucleus.isEmpty(this.announcementForEdit.endDate) &&
                    !this.$nucleus.isEmpty(this.announcementForEdit.description)
                );
            },
            isDirty() {
                if (this.isSaved) {
                    return false;
                }
                let initialValues = this.initialAnnouncement;
                let currentValues = this.announcementForEdit;
                return JSON.stringify(currentValues) != JSON.stringify(initialValues);
            },
            initAnnouncement() {
                return {
                    id: null,
                    name: null,
                    description: null,
                    startDate: null,
                    endDate: null,
                    isPublished: false,
                    isDefault: true,
                    type: 0,
                    userRoleIds: [],
                };
            },
            initUserRoles() {
                return {
                    isDefault: false,
                    roles: [],
                };
            },
            edit() {
                this.isEditable = true;
            },
            async cancel() {
                if (this.isDirty() && this.isEditable) {
                    const cancelConfirmation = new ConfirmationBoxService({});
                    cancelConfirmation.options.size = "md";
                    cancelConfirmation.options.okTitle = "Cancel";
                    cancelConfirmation.options.cancelTitle = "Close";
                    const confirmed = await cancelConfirmation.showConfirm(
                        `The announcement has been modified. Choose Close to continue editing this announcement or Cancel to exit without saving changes?`
                    );
                    if (!confirmed) {
                        return;
                    }
                    this.navigateToContentManagement();
                } else {
                    this.navigateToContentManagement();
                }
            },
            navigateToContentManagement() {
                this.$router.push("/admin/content-management");
            },
            async save() {

                const contentManagement = {
                    id: this.announcementForEdit.id,
                    name: this.announcementForEdit.name,
                    description: this.announcementForEdit.description,
                    startDate: this.announcementForEdit.startDate,
                    endDate: this.announcementForEdit.endDate,
                    isPublished: this.announcementForEdit.isPublished,
                    isDefault: this.announcementForEdit.isDefault,
                    type: this.announcementForEdit.type,
                };

                if (this.announcementForEdit.isDefault) {
                    const roles = this.allRoles.map((x) => x.id);
                    contentManagement.userRoleIds = roles;
                } else {
                    contentManagement.userRoleIds = this.announcementForEdit.userRoleIds.map(ur => ur.roleId);
                }

                await this.saveAnnouncement(contentManagement);
                this.isSaved = true;

                //check for save announcement error
                if (!this.$nucleus.isEmpty(this.saveAnnouncementError)) {
                    let saveErrors = this.saveAnnouncementError;
                    let messages = "";
                    saveErrors.forEach((e) => {
                        messages += e.debugMessage;
                    });
                    ToastService.showErrorToast(messages);
                    this.clearErrors();
                } else {
                    this.$scrollTo("#logo", 500, { easing: "ease-in-out" });
                    ToastService.showSuccessToast("The announcement has been saved.");
                    this.announcement = this.announcementForEdit;
                    this.setAnnouncementForEdit(this.announcement);
                    this.initialAnnouncement = this.announcementForEdit;
                }
            },
        },
        created() {
            const edit = this.$route.query.isEditable;
            if (edit == "readonly") {
                this.isEditable = false;
            } else {
                this.isEditable = true;
            }
        },
        async mounted() {
            this.initUserRoles();
            await this.getAllRoles();
            const id = this.$route.query.id;
            let userRoles = [];
            if (id) {
                await this.getAnnouncementForEdit(id);
                this.announcement = this.announcementForEdit;
                this.initialAnnouncement = Object.freeze(this.announcement);
                this.selectedRoleIds = this.announcementForEdit.userRoleIds;
                this.selectedRoleNames = this.announcementForEdit.userRoleIds.map(r => r.roleName);
            } else {
                this.announcement = this.initAnnouncement();
                this.editMode = false;
                this.setAnnouncementForEdit(this.announcement);
                this.initialAnnouncement = Object.freeze(this.announcement);
                this.selectedRoleIds = userRoles;
            }
        },
    };
</script>

<style lang="scss" scoped>
    .n-ml-n5 {
        margin-left: -5rem !important;
    }

    .n-error {
        font-size: 12px;
    }

    .form-group {
        margin-bottom: 0rem;
    }

    .nav-link {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }

    .modal-footer {
        justify-content: center;
    }

    .modal-title {
        color: $tertiary-dark-color;
    }

    .modal-body {
        padding-top: 16px;
    }

    .custom-switch {
        position: absolute !important;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .custom-switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
</style>