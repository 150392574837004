<template>
  <Panel class="newproducts-panel" title="New Items">
    <template v-if="!isLoading">
      <template v-if="(products && products.length)">
        <NewProductsList 
        :links="products" 
        :isProduct="true"
        :openInNewTab="false"
        />
        <div class="text-center">
          <button class="btn btn-secondary my-3 router-link-active" v-if="showAllLink" @click="navigateToProducts()">View New Items</button>
        </div>
      </template>
      <template v-else>
        <p class="n-no-results">No items are designated as "New" currently.</p>
      </template>
    </template>
  </Panel>
</template>
<script>
  import Panel from "@/nucleus-modules/dd-nucleus-ui/components/Panel";
  import NewProductsList from "@/components/home/LinksList";
  import {StorefrontEvents, TagNames} from "@/nucleus-modules/dd-nucleus-storefront/constants.js"
  export default {
    name: "newproducts-panel",
    props: {
      products: {
        type: Array,
        required: true
      },
      isLoading: {
        type:Boolean,
        required:false
      },
      showAllLink: {
        type:Boolean,
        required:false
      }
    },
    components: {
      Panel,
      NewProductsList
    },
    methods: {
      navigateToProducts() {
        const query = { tags: TagNames.NEW }
        this.$eventBus.$emit(StorefrontEvents.NAVIGATE_TO_PRODUCTS, query);
      }
    }
  }
</script>
<style scoped lang="scss">
  .btn {
    font-size: 1rem;
  }
</style>