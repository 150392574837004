<template>
  <span class="n-order-shipdate-display">
    <strong
      ><span v-if="shipDate"
        >on {{ shipDate | moment("MMMM DD, YYYY") }}</span
      ></strong
    >
  </span>
</template>

<script>
export default {
  name: "order-shipdate",
  props: {
    shipDate: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
