<template>
    <div>
        <b-form-select v-model="selected" :options="perPageOptions" @change="onPerPageChanged" id="multiple"></b-form-select>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
    import { PER_PAGE_CONFIG_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

    export default {
         name: "PerPageList",
        data() {
            return {
                selectedPage: ""
            }
        },
        props: {
            perPage: {
                type: Number,
                required: true
            }
        },
        computed: {
            ...mapGetters({
                perPageConfig: [PER_PAGE_CONFIG_GETTER]
            }),
            perPageOptions() {
                if(this.perPageConfig && this.perPageConfig.options && this.perPageConfig.options.length){
                    return this.perPageConfig.options.map(x => {
                        return {
                            value : x.id,
                            text :  x.name
                        }
                    });
                }
                return [];
            },
            selected: {
                get() {
                    return this.selectedPage
                },
                set(value) {
                    this.selectedPage = value;
                }
                
            }
        },
        methods: {
            onPerPageChanged() {
                this.$eventBus.$emit(UiEvents.PER_PAGE_CHANGED, {selectedPerPage : this.selected});
            }
        },
        async mounted() {
            this.selectedPage = this.perPage;
        }
    }
</script>
<style scoped lang="scss">
</style>