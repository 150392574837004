<template>
<div class="n-product-tab">
    <h4>
        Activity
    </h4>
    <VueGoodTable :columns="columns" :rows="activity" :sort-options="sortConfig" styleClass="vgt-table striped" class="mb-5">
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.type === 'date'">
                {{
                      props.formattedRow[props.column.field] | moment(dateFormat)
                    }}
            </span>
        </template>
    </VueGoodTable>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import 'vue-good-table/dist/vue-good-table.css'
import {
    VueGoodTable
} from 'vue-good-table';
import {
    GET_AUDIT_FIELDS
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
    AUDIT_FIELDS_GETTER
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
export default {
    name: 'Activity',
    components: {
        VueGoodTable,
    },
    data() {
        return {
            columns: [],
            sortConfig: {},
            dateFormat: "MM/DD/YYYY h:mm a",
            isEdit: false,
        }
    },
    props: {
        productId: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            auditFields: AUDIT_FIELDS_GETTER
        }),
        activity: {
            type: Array,
            get() {
                return this.auditFields;
            }
        },
    },
    methods: {
        ...mapActions({
            getAuditFields: GET_AUDIT_FIELDS,
        }),
        setColumns() {
            this.columns.push({
                label: "Modified Date",
                field: "created",
                type: "date",
                width: '200px',
                formatFn: function (value) {
                    return value == null ? "" : value;
                },
            });
            this.columns.push({
                label: "Type",
                field: "type",
                type: "string",
                width: '70px'
            });
            this.columns.push({
                label: "Field",
                field: "field",
                type: "string",
                width: '100px'
            });
            this.columns.push({
                label: "Old Value",
                field: "oldValue",
                width: '250px',
                type: "string"

            });
            this.columns.push({
                label: "New Value",
                field: "newValue",
                width: '250px',
                type: "string"
            });
            this.columns.push({
                label: "Edited By",
                field: "createdBy",
                width: '200px',
                type: "string"
            });
        },
        setSortConfig() {
            this.sortConfig = {
                enabled: true,
            };
        },

    },
    async mounted() {
        this.setColumns();
        this.setSortConfig();
        await this.getAuditFields({
            eventId: this.productId,
            entityType: 'Product'
        });
    },
}
</script>

<style lang="scss">
.n-product-tab {
    h4 span {
        color: $gray-3;
        font-family: $body-font;
        font-size: 1rem;
        line-height: 1.5;
    }
}

.vgt-right-align {
    text-align: left !important;
}
</style>
