import { mapActions, mapGetters } from "vuex";
import { SYSTEM_ERRORS_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { CLEAR_ERRORS } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";

export const errorMixin = {
    computed: {
        ...mapGetters({
            networkError: SYSTEM_ERRORS_GETTER,
        }),
    },
    methods: {
        ...mapActions({
            clearErrors: CLEAR_ERRORS,
        }),
        async showNetworkError() {

            if (this.networkError && this.networkError.length > 0) {
                ToastService.showErrorToast(this.networkError[0]);
                this.clearErrors();
            }
        },
    },

}