export const Areas = Object.freeze({
    PRODUCTS: "Products"
});

export const Badges = Object.freeze({
    PRIMARY_BADGE: "Primary Badge",
    SECONDARY_BADGE: "Secondary Badge",
    HIGHLIGHT_BADGE: "Highlight Badge"
});

export const CheckoutReviewOrderIds = Object.freeze({
    CONTACT_INFORMATION: 'contactInformation',
    SHIPPING_SECTION: 'shippingSection',
    PAYMENT_SELECTION:'paymentSection',
    ITEMS_SECTION:'itemsSection'
});

export const CreditCardType = Object.freeze({
    AMEX: "american express",
    VISA: "visa",
    MASTER: "master",
    DISCOVER:"discover"
});

export const FavoriteIconType = Object.freeze({
    ADDRESS: "address",
    PRODUCT: "product"
});

export const MediaTypes = Object.freeze({
    IMAGE: 0,
    VIDEO: 1,
    FILE: 2,
});


export const StorefrontEvents = Object.freeze({
    ADDING_TO_CART: "adding-to-cart",
    ADDING_TO_CART_COMPLETED: "adding-to-cart-completed",
    FAVORITE_TOGGLED: "favorite-toggled",
    SIGN_OUT:"sign-out",
    NAVIGATE_TO_PRODUCTS: "navigate-products"
});

export const TagNames = Object.freeze({
    FEATURED:'Featured',
    FAVORITE:'Favorite',
    NEW:'New',
    EXCLUSIVE:'Exclusive'
});

export const OrderStatus = Object.freeze({
    NONE: {code: 0, text: 'None'},
    PENDING: {code: 1, text: 'Pending'},
    PENDING_APPROVAL: {code: 2, text: 'Pending Approval'},
    CANCELLED: {code: 99, text: 'Canceled'},
    COMPLETED: {code: 100, text: 'Completed'},
});

export const PaymentStatus = Object.freeze({
    NONE: {code: 0, text: 'None'},
    PENDING: {code: 1, text: 'Pending'},
    PARTIAL: {code: 2, text: 'Partially Billed'},
    COMPLETED: {code: 100, text: 'Billed'},
});

export const ShippingStatus = Object.freeze({
    NONE: {code: 0, text: 'None'},
    PENDING: {code: 1, text: 'Pending'},
    PARTIAL: {code: 2, text: 'Partially Shipped'},
    COMPLETED: {code: 100, text: 'Shipped'},
});

export const ItemStatus = Object.freeze({
    PENDING: {code: 0, text: 'Pending'},
    PARTIAL: {code: 4, text: 'Partially Shipped'},
    DELIVERED: {code: 1, text: 'Delivered'},
    CANCELED: {code: 2, text: 'Canceled'},
    //Delivered and shipped.
    SHIPPED: {code: 1, text: 'Shipped'}
});

export const ItemShippedText = "some item(s) have been shipped."
