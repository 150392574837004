<template>
<div class="container">
  <footer class="container-fluid  n-customer-service mt-3">
    <div class="container">
        <div class="col-12">
          <div class="row text-center">
                  <div class="col-12">
                    {{ $t("footer.contact") }} <a :href="emailUs" class="n-email-footer">{{ $t("footer.email") }}</a> {{ $t("footer.hours") }} 
                  </div>
          </div>
        </div>  
    </div>
  </footer>   
  <footer class="container-fluid">   
    <div class="container">
      <div class="col-12">
          <FooterMenu />
          <div class="mt-4">
            <FooterLogo />
          </div>
          <div class="mt-2 mb-4">
            <FooterInfo />
          </div>
      </div>
    </div>  
  </footer>
</div>
</template>

<script>
import FooterInfo from "@/components/footer/FooterInfo.vue";
import FooterLogo from "@/components/footer/FooterLogo.vue";
import FooterMenu from "@/components/footer/FooterMenu.vue";
import {emailUs} from "@/companyAppConfig"
export default {
  name: "Footer",
  components: {
    FooterInfo,
    FooterLogo,
    FooterMenu,
  },
  data() {
    return {
        emailUs: emailUs
    }
  }
};
</script>

<style scoped lang="scss">
.n-customer-service {
    color: #fff;
    background: $primary-color;
    padding: 25px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
}
.n-email-footer {
  color: #fff!important;
  text-decoration: underline;
}
</style>
