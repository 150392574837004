<template>
    <div class="n-internal-notes">
        <div class="form-group">
            <textarea class="form-control" id="internalNotes" rows="5" 
                :disabled="!isEdit" 
                v-model.trim="internalNote">
            </textarea>
        </div>
        <div class="row" v-if="isEdit">
          <div class="col-12 text-right mb-3">
            <button class="btn btn-outline-primary mr-2" @click="cancel">Cancel</button>
            <button class="btn btn-primary" @click="save">Save</button>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { ORDER_DETAIL_GETTER, ORDER_PROPERTY_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_ORDER_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { SAVE_ORDER_INTERNAL_NOTES } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

export default {
    name: 'InternalNotes',
    data() {
        return {
            isEdit: false,
        }
    },
    computed: {
        ...mapGetters({
            orderDetails: ORDER_DETAIL_GETTER,
            getOrderProperty: ORDER_PROPERTY_GETTER
        }),
        internalNote: {
            get() {
                let note = this.getOrderProperty("notes");
                if (note && note.length > 0) {
                    return note[0].text;
                }

                return "";
            },
            set(value) {
                let note = this.getOrderProperty("notes");
                if (note && note.length > 0) {
                    note[0].text = value;
                }
                else {
                    note = [{text: value}];
                }
                this.setOrderProperty({ name: "notes", value: note });
            },
        },
    },
    methods: {
        ...mapMutations({
            setOrderProperty: SET_ORDER_PROPERTY,
        }),
        ...mapActions({
            saveInternalNotes: SAVE_ORDER_INTERNAL_NOTES
        }),
        cancel(){
            this.isEdit = false;
        },
        edit(){
            this.isEdit = true;
        },
        async save(){
            var payload = { orderId: this.orderDetails.id, internalNotes: this.internalNote };
            
            var response = await this.saveInternalNotes(payload);

            if (response.succeeded) {
                ToastService.showToast(
                    "The internal note was successfully saved.",
                    MessageType.SUCCESS
                );
            }
            else {
                ToastService.showToast(
                    "The internal note could not be saved.  Please try again.",
                    MessageType.ERROR
                );
            }
            // Set the view back to view mode 
            this.isEdit = false;
        }
    },
    mounted () {
        this.$eventBus.$on('editNotes', () => {
            this.edit();
        });
    },
    beforeDestroy () {
        this.$eventBus.$off('editNotes');
    },
}
</script>
