<template>
    <div class="n-sales-information">
        <div v-if="isPricingEnabled" class="row">
            <div class="col-3 sales-information-conatiner clearfix">
                <div class="font-weight-bold">
                    Subtotal
                    <span class="float-right">
                        ${{orderDetails.subTotal}}
                    </span>
                </div>
                <div>
                    Shipping
                    <span class="float-right font-weight-bold">
                        ${{orderDetails.shipping}}
                    </span>
                </div>
                <div>
                    Tax
                    <span class="float-right font-weight-bold">
                        ${{orderDetails.tax}}
                    </span>
                </div>
                <div>
                    <hr>
                </div>
                <div class="font-weight-bold n-primary-color">
                    Order Total
                    <span class="float-right">
                        ${{orderDetails.total}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="font-weight-bold">Order #</div>
                <div>{{orderDetails.orderNumber}}</div>
            </div>
            <div class="col-6">
                <div class="font-weight-bold">Order Date</div>
                <div>{{orderDetails.orderDate | moment(dateTimeFormat)}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-6">
                <div class="font-weight-bold">Tracking Number</div>
                <div v-for="tracking in orderDetails.shipments" :key="tracking.trackingNumber">
                    <a :href="getTrackingUrl(tracking.trackingNumber, tracking.carrier)" target="_blank">{{tracking.trackingNumber}}</a> {{tracking.shipDate | moment(dateFormat)}}
                </div>
            </div>
            <div class="col-6">
                <div class="font-weight-bold">Last Modified Date</div>
                <div>{{orderDetails.updated | moment(dateTimeFormat)}}</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-6">
                <div class="font-weight-bold">Order Source</div>
                <div v-if="orderDetails.source === 'cart'">Storefront</div>
            </div>
            <div class="col-6">
                <div class="font-weight-bold">Ship Date</div>
                <div v-if="orderDetails.shipments.length > 0">{{orderDetails.shipments[0].shipDate | moment(dateFormat)}}</div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { ORDER_DETAIL_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { OrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrdersMixin.js";
    import { OrderStatus } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

    export default {
        name: 'SalesInformation',
        mixins: [OrdersMixin],
        data() {
            return {
                dateTimeFormat: "MM/DD/YYYY, hh:mm a",
                dateFormat: "MM/DD/YYYY"
            }
        },
        computed: {
            ...mapGetters({
                orderDetails: ORDER_DETAIL_GETTER
            }),
            isSentToBLS() {
                return this.orderDetails.orderStatus == OrderStatus.PENDING
            }
        },
    }
</script>

<style lang="scss" scoped>
    .n-sales-information {

        .sales-information-conatiner {
            border-radius: 5px;
            background: $gray-1;
            padding: 15px
        }

        hr {
            border-color: $gray-3;
        }
    }
</style>
