// system
export const ADD_NETWORK_ERROR = "addNetworkError";
export const REMOVE_NETWORK_ERROR = "removeNetworkError";
export const INCREMENT_LOADERS = "incrementLoaders";
export const DECREMENT_LOADERS = "decrementLoaders";
export const SET_IS_LOCALE_CHANGING = "setIsLocaleChanging";
export const SET_OVERLAY = "setIsOverlayVisible";

// auth
export const SET_USER_INFO = "setUserInfo";

// error
export const ADD_ERROR = "addError";
export const INIT_ERRORS = "initErrors";
export const POP_ERROR = "popError";

// filtering
export const SET_FILTER_TAGS = "setFilterTags";
export const SET_FILTERS = "setFilters";
