import {
    CLEAR_ERRORS,
    INSERT_ERROR,
    LOGOUT,
    REDIRECT_TO_LOGIN,
    REMOVE_LAST_ERROR
} from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { SYSTEM_ERRORS_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import {
    ADD_ERROR,
    INIT_ERRORS,
    POP_ERROR
} from "@/nucleus-modules/dd-nucleus-ui/store/mutations.type.js";

const initialState = {
    errors: []
};

export const state = { ...initialState };

export const actions = {
    [CLEAR_ERRORS]({ commit }) {
        commit(INIT_ERRORS);
    },
    [INSERT_ERROR]({ commit, dispatch }, error) {
        commit(ADD_ERROR, error);

        if (error.statusCode === 401) {
            dispatch(LOGOUT);
            dispatch(REDIRECT_TO_LOGIN);
        }
    },
    [REMOVE_LAST_ERROR]({ commit }) {
        commit(POP_ERROR);
    }
};

export const getters = {
    [SYSTEM_ERRORS_GETTER]: state => {
        return state.errors;
    }
};

export const mutations = {
    [ADD_ERROR](state, error) {
        state.errors.unshift(error);
    },
    [INIT_ERRORS](state) {
        state.errors = [];
    },
    [POP_ERROR](state) {
        state.errors.pop();
    }
};

export default {
    state,
    actions,
    mutations,
    getters,
};
