import axios from "axios";
import { INSERT_ERROR, LOGOUT, START_LOADER, STOP_LOADER } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { IMPERSONATED_USER_KEY } from "@/nucleus-modules/dd-nucleus-ui/constants";
import JwtService from "@/nucleus-modules/dd-nucleus-ui/services/jwt.service.js";
import store from "@/store/index.js";
import MockApiService from "@/mock/mock-http.service.js";

const API_BASE_URL = "/";
const AUTH_HEADER = "Authorization";
// TODO: upgrade to a better way to determine whether to use the mock-http-service
const USE_MOCKS = false;

class ApiService {

    get baseUrl() {
        return API_BASE_URL;
    }

    constructor() {
        if (USE_MOCKS) {
            this.http = MockApiService;
        }
        else {
            this.http = axios.create({
                baseURL: API_BASE_URL
            });

            this.http.interceptors.response.use(response => response, error => {
                const { status } = error.toJSON();

                if (status === 401) {
                    // no need to display the console error for 401
                    store.dispatch(LOGOUT);
                }

                return Promise.reject(error);
            });
        }
    }

    // Returns a function that calls the system loader appropriately based on an options object
    // 
    // options object structure:
    // {
    //     showLoader: true / false, defaults to true
    //     loaderDelay: x milliseconds delay before showing the loader, defaults to 0
    // }
    //
    // Example usage:
    //  - Show the full page loader immediately:
    //      await ApiService.post(endpoint, params);
    //  - Show the loader after a delay:
    //      await ApiService.post(endpoint, params, { loaderDelay: 500 });
    //  - Do not show the loader at all:
    //      await ApiService.post(endpoint, params, { showLoader: false });
    loaderCallHandler(options) {
        // define the basic loader invocation
        const base = () => {
            store.dispatch(START_LOADER);
        };

        // if no options were passed along, use the basic invocation
        if (!options) return base;

        // if the showLoader option exists and is false, return an
        // invocation that won't dispatch a START_LOADER action
        if (options.hasOwnProperty("showLoader") && !options.showLoader) {
            return () => { return; };
        }

        // if a loaderDelay > 0 was passed, wrap the basic invocation in a setTimeout
        if (options.loaderDelay && options.loaderDelay > 0) {
            return () => {
                setTimeout(() => {
                    base();
                }, options.loaderDelay)
            };
        }

        // for all other cases, return the basic invocation
        return base;
    }

    // common handler for all verbs, accepts a function to execute (get, post, put, etc.)
    // and wraps error handling and system loader invocation around it
    async apiCallHandler(apiCall, loaderOptions) {
        let response;

        try {
            this.loaderCallHandler(loaderOptions)();
            this.setAuthHeader();

            const impersonatedUser = JSON.parse(sessionStorage.getItem(IMPERSONATED_USER_KEY));
            if (impersonatedUser) {
                this.setImpersonateHeader(impersonatedUser.id);
            }

            response = await apiCall();

            return response.data;
        }
        catch (e) {          
            if (e.response) {
                // a non-2xx response was returned
                let errorResponse = {
                    succeeded: false,
                    code: e.response && e.response.data ? e.response.data.code : e.response.data.error ? e.response.data.error : 0,
                    httpStatusCode: null,
                    messages: e.response && e.response.data.messages ? e.response.data.messages : e.response.data.error_description ? e.response.data.error_description : []
                };

                switch (errorResponse.code) {
                    case 400:
                        await store.dispatch(INSERT_ERROR, "Bad Request");
                        break;
                    case 401:
                        if (errorResponse.messages && errorResponse.messages.length > 0 && errorResponse.messages[0].code == 403) {                          
                            errorResponse.messages[0].debugMessage = "User does not have permission to perform this action. " + errorResponse.messages[0].debugMessage;
                            await store.dispatch(INSERT_ERROR,  errorResponse.messages[0].debugMessage);                             
                        }
                        else {
                            await store.dispatch(LOGOUT);
                        }
                        break;
                    case 404:
                        await store.dispatch(INSERT_ERROR, "Resource Not Found");
                        break;
                    case 405:
                        await store.dispatch(INSERT_ERROR, "Method Not Allowed");
                        break;
                    case 415:
                        await store.dispatch(INSERT_ERROR, "Invalid Media Type");
                        break;
                    default:
                        await store.dispatch(INSERT_ERROR, "Unexpected Error");
                        break;
                }

                return errorResponse;
            }
            else if (e.request) {
                // no response received
                return {
                    succeeded: false,
                    code: 0,
                    httpStatusCode: null,
                    messages: ["The request did not receive a response."]
                };
            }
            else {
                // unable to execute the request
                return {
                    succeeded: false,
                    code: 0,
                    httpStatusCode: null,
                    messages: ["The request could not be completed."]
                }
            }
        }
        finally {
            store.dispatch(STOP_LOADER);
        }
    }

    async delete(endpoint, data, loaderOptions) {
        const response = this.apiCallHandler(async () => await this.http.delete(`${endpoint}`, data), loaderOptions);

        return response;
    }

    async get(endpoint, loaderOptions) {
        const response = this.apiCallHandler(async () => await this.http.get(`${endpoint}`), loaderOptions);

        return response;
    }

    // NOTE: Any loader handling will have to be manually, if desired,
    // when getting blobs via the START_LOADER/STOP_LOADER actions.
    async getBlob(endpoint) {
        const response = await axios.get(endpoint, {
            responseType: "blob",
            timeout: 30 * 1000,
        });

        return response;
    }

    async post(endpoint, params, loaderOptions) {
        const response = this.apiCallHandler(async () => await this.http.post(`${endpoint}`, params), loaderOptions);

        return response;
    }

    async put(endpoint, params, loaderOptions) {
        const response = this.apiCallHandler(async () => await this.http.put(`${endpoint}`, params), loaderOptions);

        return response;
    }

    // remove the authorization header
    removeAuthHeader() {
        delete axios.defaults.headers.common[AUTH_HEADER];
    }

    // add an interceptor to use the stored bearer token
    // on successful login
    async setAuthHeader() {
        return new Promise(() => {
            this.http.interceptors.request.use(async config => {
                const token = await JwtService.getToken();

                config.headers.authorization = `Bearer ${token}`;

                return config;
            }, error => {
                throw new Error(`SetAuthHeader error ${error}`);
            });
        });
    }

    async setImpersonateHeader(id) {
        return new Promise(() => {
            this.http.interceptors.request.use(async config => {
                config.headers["i-token"] = id;
                return config;
            }, error => {
                throw new Error(`SetImpersonateHeader error ${error}`);
            })
        });
    }
}

export default new ApiService();
