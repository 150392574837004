// bulk orders
export const SET_BULK_ORDER_DETAIL = "setBulkOrderDetail";
export const SET_BULK_ORDER_PRODUCTS = "setBulkOrderProducts";
export const SET_BULK_ORDER_SHIPPING_METHOD = "setBulkOrderShippingMethod";
export const SET_BULK_ORDER_USERS = "setBulkOrderUsers";
export const SET_GET_BULK_ORDER_DETAIL_RESPONSE = "setGetBulkOrderDetailResponse";
export const SET_PLACE_BULK_ORDER_ERRORS = "setPlaceBulkOrderErrors";
export const SET_PLACE_BULK_ORDER_RESPONSE = "setPlaceBulkOrderResponse";
export const SET_SEARCH_BULK_ORDERS_ERRORS = "setSearchBulkOrdersErrors";
export const SET_SEARCH_BULK_ORDERS_RESPONSE = "setSearchBulkOrdersResponse";
export const SET_SEARCH_RECIPIENTS_RESULTS = "setSearchBulkOrderRecipientsResults";
export const SET_SEARCH_RECIPIENTS_ERRORS = "setSearchBulkOrderRecipientsErrors";

// store config
export const SET_ADMIN_MENU = "setAdminMenu";

// products
export const SET_ALL_PRODUCTS = "setAllProducts";
export const SET_GET_ALL_PRODUCTS_ERROR = "setGetAllProductsError";
export const SET_GET_PRODUCT_FOR_EDIT_ERROR = "setGetProductForEditError";
export const SET_GET_PRODUCT_MEDIA_ERROR = "setGetProductMediaError";
export const SET_IS_PRODUCT_EDIT_DIRTY = "setIsProductEditDirty";
export const SET_PRODUCT_FOR_EDIT = "setProductForEdit";
export const SET_PRODUCT_MEDIA = "setProductMedia";
export const SET_PRODUCT_PROPERTY = "setProductProperty";
export const SET_PRODUCT_SEARCH_ERROR = "setProductSearchError";
export const SET_PRODUCT_SEARCH_RESULTS = "setProductSearchResults";
export const SET_SAVE_PRODUCT_ERROR = "setSaveProductError";

// stepper
export const SET_EDIT_PRODUCT_STEPPER = "setEditProductStepper";
export const SET_STEPPER_CURRENT_STEP = "setStepperCurrentStep";

// order 
export const SET_GET_ORDERS_ERRORS = "setGetOrdersErrors";
export const SET_ORDER_DETAIL = "SetOrderDetails";
export const SET_ORDER_PROPERTY = "setOrderProperty";
export const SET_ORDERS = "setOrders";
export const SET_SEARCH_ORDERS_ERRORS = "setSearchBulkOrdersErrors";
export const SET_SEARCH_ORDERS_RESPONSE = "setSearchApprovalOrdersResponse";

// roles
export const SET_ALL_ROLES = "setAllRoles";

// users
export const SET_IS_USER_EDIT_DIRTY = "setIsUserEditDirty";
export const SET_SAVE_USER_ERROR = "setSaveUserError";
export const SET_USER_SEARCH_RESULTS = "setUserSearchResults";
export const SET_ANNOUNCEMENT_SEARCH_RESULTS = "setAnnouncementSearchResults";
export const SET_USER_FOR_EDIT = "setUserForEdit";
export const SET_USER_ADDRESS = "setUserAddress";
export const SET_USER_ATTRIBUTE_PROPERTY = "setUserAttributeProperty";
export const SET_USER_PROPERTY = "setUserProperty";
export const SET_USER_ROLES = "setUserRoles";
export const SET_USERS = "setUsers";

//customer segments
export const SET_COMPANY_SEGMENTS = "setCompanySegments";

//inventory sync
export const SET_LAST_INV_SYNC_TIME = "setLastInvSyncTime";

export const SET_SEND_WELCOME_EMAIL = "setSendWelcomeEmail";

//announcement 
export const SET_ANNOUNCEMENT_FOR_EDIT = "setAnnouncementForEdit";
export const SET_SAVE_ANNOUNCEMENT_ERROR = "setSaveAnnouncementError";
export const SET_ANNOUNCEMENT_PROPERTY = "setAnnouncementProperty";
export const SET_DELETE_ANNOUNCEMENT_RESPONSE = "setDeleteAnnouncementError"
export const SET_IS_ANNOUNCEMENT_EDIT_DIRTY = "setIsAnnouncementEditDirty";

// audit Activity
export const SET_AUDIT_FIELDS = "setAuditFields";
export const SET_AUDIT_FIELDS_ERROR = "setAuditFieldsError";

