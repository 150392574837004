<template>
    <div class="n-primary-order-information">
        <div class="row mb-4">
            <div class="col-6">
                <div class="font-weight-bold">Customer ID</div>
                <div>{{orderDetails.user.email}}</div>
            </div>
            <div class="col-6">
                <div class="font-weight-bold">Order Placed By</div>
                <div>{{orderDetails.createdBy}}</div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-6">
                <div class="font-weight-bold">First Name</div>
                <div>{{orderDetails.user.firstName}}</div>
            </div>
            <div class="col-6">
                <div class="font-weight-bold">Order Placed Email</div>
                <div>{{orderDetails.createdByEmail}}</div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-6">
                <div class="font-weight-bold">Last Name</div>
                <div>{{orderDetails.user.lastName}}</div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-6">
                <div class="font-weight-bold">Primary Role</div>
                <div>{{orderDetails.primaryRole}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ORDER_DETAIL_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

export default {
    name: "PrimaryOrderInformation",
    computed: {
        ...mapGetters({
            orderDetails: ORDER_DETAIL_GETTER
        }),
    },
}
</script>