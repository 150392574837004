<template>
    <div class="row pl-3">
        <div class="col-4 col-md-2">
            <router-link :id="item.product.id"
                            :title="item.product.name"
                            :to="'/product-detail/'+item.product.id">
                <img class="img-fluid n-img-ht n-image-mobile-margin"
                        :src="item.product.thumbnailImageUrl" v-if="item.product.thumbnailImageUrl !== null" />
                <img v-else class="img-fluid n-img-ht n-image-mobile-margin" src="/images/no-image-available-th.png" />
            </router-link>
        </div>
        <div class="col-8 col-md-6">
            <span v-if="isAddOnItem" class="badge badge-primary add-on-badge">Add-on item</span>
            <router-link :id="item.product.id"
                         :title="item.product.name"
                         :to="'/product-detail/'+item.product.id"
                         class="n-title-link">
                <h2 class="n-title-text">{{item.product.name}}</h2>
                <h4 class="n-product-sku">{{item.product.itemNumber}}</h4>
            </router-link>
            <!--TODO: we need to revisit once the payment option is available-->
            <div v-if="isPricingEnabled">
                <div class="n-price">{{item.itemSubTotal | currency}}</div>
                <div v-if="item.unitPrice > item.salePrice">
                    (<span class="n-now-price">now</span>{{item.salePrice | currency}}<span class="n-was-price">was</span><span class="n-price-on-sale">{{item.unitPrice | currency}}</span> {{item.unitOfMeasure}})
                </div>
                <div v-else>
                    {{item.unitPrice | currency}}
                </div>
            </div>
            <template v-if="isRegularItem">
                <div v-if="item.product.messages && item.product.messages.length && !isAddOnItem">
                    <MessageList :messages="item.product.messages" />
                </div>
                <slot name="limits"></slot>
            </template>
            <div class="d-flex d-md-none row mt-3">
                <div v-if="canEditQuantity" class="col-5 n-quantity-mobile">
                    <QuantityInput :item="item" :min="0" @change="onQuantityChanged(item, true)" @key="onQuantityChanged(item, false)" />
                </div>
                <div v-else class="col-md-12 n-unit-of-measure">
                    
                    {{item.quantity}} 
                    <template v-if="isUnitOfMeasureAvailable(item.product)">
                        <Uom :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit" />
                    </template>
                </div>
                <div v-if="canEditQuantity" class="col-7 pt-2">
                    <template v-if="isUnitOfMeasureAvailable(item.product)">
                            <Uom :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit" />
                    </template>
                </div>
            </div>
            <div v-if="isShipQuantityChanged" class="n-order-history-warning">
                Original quantity was {{previousItemQuantity}} {{getInventory(item.product).unitOfMeasure}}
            </div>
            <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                <div class="row" >
                    <div v-if="canEditQuantity" class="d-none d-md-block col-5 n-quantity-mobile">
                        <div class="d-inline">
                            <QuantityInput :item="item" :min="0" @change="onQuantityChanged(item, true)" @key="onQuantityChanged(item, false)" />
                        </div>
                    </div>
                    <div v-else class="d-none d-md-block col-md-12 n-unit-of-measure">
                        {{item.quantity}} 
                        <template v-if="isUnitOfMeasureAvailable(item.product)">
                            <Uom :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit" />
                        </template>
                    </div>
                    <div v-if="canEditQuantity" class="d-none d-md-block col-4 n-unit-of-measure pt-2">
                        <template v-if="isUnitOfMeasureAvailable(item.product)">
                            <Uom :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit" />
                        </template>
                    </div>
                </div>
                <div class="row" v-if="isFavorite">
                    <div class="col-12 n-cart-item">
                        <FavoriteIcon @changed="favoriteChanged" v-if="!$nucleus.isEmpty(item.isFavorite)" :productId="item.productId" :isFavorite="item.isFavorite" :showText="false">
                            <span class="remove ml-1" v-if="canRemoveItems"> <span class="ml-1 n-separator"> | </span> <a @click="removeItemFromCart(item)">Remove</a></span>
                        </FavoriteIcon>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <a @click="removeItemFromCart(item)" v-if="canRemoveItems">Remove</a>
                    </div>
                </div>
                <div class="row" v-if="item.status">
                    <div class="col">
                        <Message :message="itemStatus" v-if="!$nucleus.isEmpty(itemStatus)"/>
                    </div>
                </div>
            </div>


        </div>
        <div class="col-12 col-md-4 n-quantity-mobile d-none d-sm-none d-md-block d-lg-block d-xl-block" >
            <div class="row">
                <div v-if="canEditQuantity" class="d-none d-md-block offset-md-0 col-lg-6 col-md-7 n-quantity-mobile">
                    <div class="d-inline">
                        <QuantityInput :item="item" :min="0" @change="onQuantityChanged(item, true)" @key="onQuantityChanged(item, false)" />
                    </div>
                </div>
                <div v-else class="d-none d-md-block col-md-12 col n-unit-of-measure">
                    {{item.quantity}} 
                    <template v-if="isUnitOfMeasureAvailable(item.product)">
                            <Uom :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit" />
                    </template>
                </div>
                <div v-if="canEditQuantity" class="d-none d-md-block col-md-5 n-unit-of-measure pt-2">
                    <template v-if="isUnitOfMeasureAvailable(item.product)">
                                <Uom :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit" />
                    </template>
                </div>
            </div>
            <!-- 2 different versions of the favorite icon to support showing the text on desktop -->
            <div class="row pt-1 d-none d-md-block d-lg-block d-xl-none n-cart-item" v-if="isFavorite">
                <div class="col-12">
                    <FavoriteIcon @changed="favoriteChanged" v-if="!$nucleus.isEmpty(item.isFavorite)" :productId="item.productId" :isFavorite="item.isFavorite" :showText="false">
                        <span class="remove ml-1" v-if="canRemoveItems"> | <a class="ml-1" @click="removeItemFromCart(item)">Remove</a></span>
                    </FavoriteIcon>
                </div>
            </div>
            <!-- 2 different versions of the favorite icon to support not showing the text on tablet -->
            <div class="row pt-1 d-none d-md-none d-lg-none d-xl-block n-cart-item" v-if="isFavorite">
                <div class="col-12">
                    <FavoriteIcon @changed="favoriteChanged" v-if="!$nucleus.isEmpty(item.isFavorite)" :productId="item.productId" :isFavorite="item.isFavorite" :showText="false">
                        <span class="remove ml-1" v-if="canRemoveItems"> | <a class="ml-1" @click="removeItemFromCart(item)">Remove</a></span>
                    </FavoriteIcon>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12">
                    <a @click="removeItemFromCart(item)" v-if="canRemoveItems">Remove</a>
                </div>
            </div>
            <div class="row" v-if="item.status">
                <div class="col">
                    <Message :message="itemStatus" :textIconColorSync="true" class="text-uppercase" v-if="!$nucleus.isEmpty(itemStatus)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import QuantityInput from "@/nucleus-modules/dd-nucleus-ui/components/QuantityInput";
    import FavoriteIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteIcon";
    import Uom from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay";
    import { ItemStatus } from "@/nucleus-modules/dd-nucleus-storefront/constants";
    import Message from "@/nucleus-modules/dd-nucleus-storefront/components/Message";
    import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants";
    import MessageList from '@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue';

    export default {
        name: 'cart-item',
        components: { FavoriteIcon, QuantityInput, Uom, Message, MessageList },
        props: {
            item: {
                type: Object,
                required: true
            },
            canEditQuantity: {
                type: Boolean,
                default: true
            },
            canRemoveItems: {
                type: Boolean,
                default: true
            },
            isPricingEnabled:{
                type:Boolean,
                default: false
            },
            isFavorite: {
                type: Boolean,
                default: false
            },
            isShipQuantityChanged:{
                type: Boolean,
                default: false
            },
            previousItemQuantity:{
                type:Number
            }
        },
        data() {
            return {
            }
        },
        computed: {
            itemStatus() {
                var status = {}
                if (this.item) {
                    var itemStatus = this.item.status;
                    status = { text: itemStatus };
                    switch (itemStatus) {
                        case ItemStatus.DELIVERED.text:
                            status.icon = "check-circle";
                            status.type = MessageType.SUCCESS;
                            status.text = ItemStatus.SHIPPED.text;
                            break;
                        case ItemStatus.CANCELED.text:
                            status.icon = "times-circle";
                            status.type = MessageType.ERROR;
                            break;
                        case ItemStatus.PENDING.text:
                            status.icon = "check-circle";
                            status.type = MessageType.CUSTOM;
                            status.customClass = "n-partial-success";
                            break;
                        default:
                            status.icon = "check-circle";
                            status.type = MessageType.NEUTRAL;
                            break;
                    }
                }
                return status;

            },
            product() {
                return this.item.product;
            },
            isRegularItem() {
                return !this.isAddOnItem;
            },
            isAddOnItem() {
                if (this.product.attributes && this.product.attributes.Addon) {
                    return this.product.attributes.Addon.isAddOn;
                }
                return false;
            },
        }, 
        methods: {
            onQuantityChanged(item, removeIfZero) {
                if (item.quantity == 0) {
                    if (removeIfZero) this.removeItemFromCart(item);
                }
                else {
                    // Record update to the quantity
                    this.updateItemQuantity(item);
                }

                this.$emit('change');
                this.$forceUpdate();
            },
            favoriteChanged(value){
                this.item.isFavorite = value;
                this.$eventBus.$emit('refreshFavorites'); 
            },
            getInventory(product) {
                let result = {};
                if (
                    product &&
                    product.canAddToCart &&
                    product.attributes &&
                    product.attributes.Inventory
                ) {
                    result = product.attributes.Inventory;
                }
                return result;
            },
            isUnitOfMeasureAvailable(product) {
             return this.getInventory(product) && this.getInventory(product).unitOfMeasure;
            }
        }
    }
</script>
<style scoped lang="scss">
    .n-change-link-in-header {
        padding: 6px 24px 0px 0px;
        text-align: right;
        font-weight: bold;
        color: $primary-color;
        font-size: 14px;
        cursor: pointer;
    }

    .n-unit-of-measure {
        color: $gray-3;
        font-weight: 600;
    }

    .remove {
        vertical-align: middle;
    }
    .n-img-ht {
        max-height:150px;
    }
    .n-title-link:hover {
        text-decoration: none;
    }

    .n-title-link:hover .n-title-text {
        color: $tertiary-color;
    }

    @media (max-width: 992px) {
        .n-unit-of-measure {
            margin-top: 0px;
            margin-left: 0px;
            font-size: 0.85rem;
        }
    }

    @media (max-width: 767px) {
        .n-unit-of-measure {
            margin-top: 0px;
            margin-left: 16px;
            font-size: 14px;
            padding-left: 0px;
        }
    }

    @media (max-width: 576px) {
        .n-cart-item-count {
            font-size: 16px;
        }

        .n-title-text {
            margin-top: 0px;
        }

        .n-unit-of-measure {
            margin-top: 0px;
            font-size: 14px;
            padding-left: 0px;
        }

        .n-favorite {
            margin-bottom: 6px;
        }

        .n-image-mobile-margin{
            margin-left: -1px;
        }
    }
    @media (min-width:767px){
        .n-image-mobile-margin{
            margin-left: 3px;
        }
    }
    @media (min-width:992px){
        .n-image-mobile-margin{
            margin-left: 3px;
        }
    }
</style>