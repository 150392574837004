<template>
  <div class="d-inline">
    <AuthenticationWrapper :isDisabled="showIconToAuthUsersOnly">
      <FavoriteIcon
        class="d-inline"
        :isFavorite="isFavorite"
        :showText="showText"
        :addFavoriteTextId="addFavoriteTextId"
        :removeFavoriteTextId="removeFavoriteTextId"
        @toggle="toggle"
      />
    </AuthenticationWrapper>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  ADD_FAVORITE,
  REMOVE_FAVORITE,
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import FavoriteIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteIcon.vue";
import AuthenticationWrapper from "@/nucleus-modules/dd-nucleus-ui/components/AuthenticationWrapper.vue";
import { TagNames } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
export default {
  name: "FavoriteProductIcon",
  components: {
    FavoriteIcon,
    AuthenticationWrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isFavorite: {
      type: Boolean,
      required: true,
    },
    showText: {
      type: Boolean,
      required: false,
    },
    addFavoriteTextId: {
      type: String,
      required: false,
    },
    removeFavoriteTextId: {
      type: String,
      required: false,
    },
    showIconToAuthUsersOnly: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    ...mapActions({
      add: ADD_FAVORITE,
      remove: REMOVE_FAVORITE,
    }),
    async toggle() {
      if (this.isFavorite) {
        await this.remove({
          productId: this.id,
          tagName: TagNames.FAVORITE,
        });
      } else {
        await this.add({
          productId: this.id,
          tagName: TagNames.FAVORITE,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
