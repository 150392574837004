<template>
    <div class="n-primary-order-information">
        <span v-for="(reason, index) in shippingApprovalReasons" :key="index">
            <div class="mt-2">
                <b-card border-variant="danger" text-variant="white" class="text-left">
                    <b-card-text>{{reason}}</b-card-text>
                </b-card>
            </div>
        </span>
        <h4 class="mt-4">
            Shipping Information
            <font-awesome-icon @click="emitEvent"
                               class="ml-2"
                               :icon="['fas', 'pencil']" />
        </h4>
        <div class="row mb-4">
            <div class="col-6">
                <div class="my-4">
                    <div class="font-weight-bold">Ship Date</div>
                    <div>{{ earliestShipDate }}</div>
                </div>
                <div class="my-4">
                    <div class="font-weight-bold">Shipping Method</div>
                    <div>
                        <span v-if="!isEdit">
                            {{ this.selectedShipMethodName }}
                        </span>
                        <span v-else>
                            <div class="ship-method">                               
                                <select v-model="orderShippedMethod"                                       
                                        class="form-control n-text-field-highlight">
                                    <option v-for="shipMethod in this.shippingMethods"
                                            :key="shipMethod.id"
                                            :value="shipMethod.code">
                                        {{ shipMethod.name }}
                                    </option>
                                </select>
                            </div>
                        </span>
                    </div>
                </div>
                <div v-if="hasShipments" class="my-4">
                    <div class="font-weight-bold">Tracking Numbers</div>
                    <div v-for="shipment in orderDetails.shipments" :key="shipment.trackingNumber">
                        <a :href="getTrackingUrl(shipment.trackingNumber, shipment.carrier)" target="_blank">{{ shipment.trackingNumber }}</a> {{ shipment.shipDate | moment(dateFormat) }}
                    </div>
                </div>
                <div class="my-4">
                    <div class="font-weight-bold">Shipping Tax Rate</div>
                    <div>No shipping tax rate</div>
                </div>
            </div>
            <div class="col-6">
                <template>
                    <div class="my-4">
                        <div class="font-weight-bold">Ship To Address</div>
                        <span v-if="!isEdit">
                            <ShipAddressView :address="orderShipToView"></ShipAddressView>
                        </span>
                        <span v-else>
                            <AddressComponent :address="orderShipToEdit" :statesList="statesList"></AddressComponent>
                        </span>
                    </div>
                </template>
            </div>
        </div>
        <div class="row" v-if="isEdit">
            <div class="col-12 text-right mb-3">
                <button class="btn btn-outline-primary mr-2" @click="cancel">Cancel</button>
                <button class="btn btn-primary" @click="save">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { ORDER_DETAIL_GETTER, STATES_LIST_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SAVE_ORDER_SHIP_TO_ADDRESS, SAVE_ORDER_SHIP_METHOD } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
    import ShipAddressView from "@/components/admin/order/ShipAddressView.vue";
    import AddressComponent from "@/components/admin/order/AddressComponent.vue";
    import { OrderApprovalsMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrderApprovalsMixin.js";
    import { OrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrdersMixin.js";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
    import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
    import { OrderApprovalReasons } from "@/nucleus-modules/dd-nucleus-admin/constants";

    export default {
        name: "ShippingTab",
        mixins: [OrderApprovalsMixin, OrdersMixin],
        components: {
            ShipAddressView,
            AddressComponent
        },
        data() {
            return {
                dateFormat: "MM/DD/YYYY",
                isEdit: false,
                OrderApprovalReasons:OrderApprovalReasons,
                orderShippedMethodCode:null,
                orderShipTo:null
            }
        },
        computed: {
            ...mapGetters({
                orderDetails: ORDER_DETAIL_GETTER,
                statesList: STATES_LIST_GETTER,
            }),
            earliestShipDate() {
                let result = "Order has not shipped";

                if (this.hasShipments) {
                    const date = new Date(Math.max(...this.orderDetails.shipments.map(s => { return new Date(s.shipDate); })));

                    result = this.$moment(date).format(this.dateFormat);
                }

                return result;
            },
            hasShipments() {
                if (this.orderDetails && this.orderDetails.shipments && this.orderDetails.shipments.length > 0) {
                    return true;
                }

                return false;
            },
            shippingApprovalReasons() {
                let reasons = [];
                if (this.orderDetails && this.orderDetails.messages && this.orderDetails.messages.length) {
                    let orderApprovalReasons = this.orderDetails.messages[0].parameters;
                    orderApprovalReasons.forEach(ar => {
                        let orderApprovalReason = OrderApprovalReasons.find(oar => oar.isOrderLevel && oar.id == ar);
                        if (orderApprovalReason) {
                            reasons.push(orderApprovalReason.detail);
                        }
                    });
                }
                return reasons;
            },
            orderShipToEdit: {
                get() {
                    return this.getOrderShipTo();
                },
                set(value) {
                    this.orderDetails.shipTo = value;
                }
            },
            orderShipToView () {
                return this.getOrderShipTo()
            },
            orderShippedMethod:{
                get() {
                    if(this.orderShippedMethodCode) {
                        return this.orderShippedMethodCode;
                    }
                    else if(this.orderDetails && this.orderDetails.shipMethodCode) {
                        return  this.orderDetails.shipMethodCode;
                    }                    
                    return "";
                },
                set(value) {                    
                    this.orderShippedMethodCode = value;
                }
            },
            selectedShipMethodName() {
                let selectedShipMethod = this.shippingMethods.find(sm => sm.code == this.orderShippedMethod);
                return selectedShipMethod ? selectedShipMethod.name : "";
            }
        },
        methods: {
            ...mapActions({
                saveShipToAddress: SAVE_ORDER_SHIP_TO_ADDRESS,
                saveShipMethod: SAVE_ORDER_SHIP_METHOD
            }),
            emitEvent() {
                this.$eventBus.$emit("editShipInfo");
            },
            cancel() {
                this.orderShipToEdit = Object.assign({}, this.orderDetails.shipTo);
                this.orderShippedMethod =  this.orderDetails.shipMethodCode;
                this.isEdit = false;
            },
            edit() {
                this.isEdit = true;
            },
            getOrderShipTo() {
                if(this.orderDetails && this.orderDetails.shipTo) {
                    let shipTo = Object.assign({}, this.orderDetails.shipTo);
                    shipTo = this.updateShipTo(shipTo);
                    return shipTo;
                }
                return {};
            },
            async save() {
                let saveResponse = {
                    succeeded: false,
                    messages: []
                };
                
                let update = false;
                this.orderShipToEdit.phoneNumber = this.orderShipToEdit.phoneNumber ? this.orderShipToEdit.phoneNumber : "9999999999";

                if(this.checkShipAddressChanged()){
                    update = true;                    
                    let payload = { orderId: this.orderDetails.id, shipTo: this.orderShipToEdit };
                    if(payload.shipTo.companyName){
                        payload.shipTo.attention = payload.shipTo.addressee;
                        payload.shipTo.addressee = payload.shipTo.companyName;
                        payload.shipTo.companyName = null;
                    }
                    else {
                        payload.shipTo.attention = null;
                    }
                    let saveShipToResponse = await this.saveShipToAddress(payload);
                    saveResponse.succeeded = saveShipToResponse.succeeded;
                    saveResponse.messages = saveShipToResponse.messages; 
                    if(!saveResponse.succeeded){
                        this.orderShipToEdit = Object.assign({}, this.orderDetails.shipTo);
                    }
                 }

                if(this.checkShipMethodChanged()) {
                    update = true;                    
                    let saveShipMethodRequest = { orderId: this.orderDetails.id, shipMethodCode: this.orderShippedMethod};
                    let saveShipMethodResponse = await this.saveShipMethod(saveShipMethodRequest);
                    saveResponse.succeeded =  saveShipMethodResponse.succeeded;
                    if(saveResponse.succeeded){
                        this.orderShippedMethod = null;
                    }
                    saveResponse.messages = saveResponse.messages.concat(saveShipMethodResponse.messages); 
                }

                if (!update) {
                    ToastService.showToast(
                        "There is no change on the shipping information. Hence nothing is saved.",
                        MessageType.INFO
                    );
                } else if (saveResponse.succeeded) {
                    ToastService.showToast(
                        "The shipping information is successfully saved.",
                        MessageType.SUCCESS
                    );
                }
                else {
                    let messages = "";
                    saveResponse.messages.forEach((i) => {
                        messages = messages + i.debugMessage;
                    });

                    ToastService.showErrorToast(messages);
                }
                // Set the view back to view mode
                this.isEdit = false;
            },
            checkShipAddressChanged(){
                let currrentShipTo = this.orderShipToEdit;
                let oldShipTo = this.orderDetails.shipTo;
                if(currrentShipTo && oldShipTo) {
                    let fldChanged = this.checkFieldChanged(currrentShipTo.addressLine1, oldShipTo.addressLine1);
                    if (currrentShipTo.companyName) {
                        fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.addressee, oldShipTo.attention);
                        fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.companyName, oldShipTo.addressee);
                    }
                    else {
                        fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.addressee, oldShipTo.addressee);
                    }
                    fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.addressLine2, oldShipTo.addressLine2);
                    fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.city, oldShipTo.city);
                    fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.postalCode, oldShipTo.postalCode);
                    fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.state, oldShipTo.state);
                    fldChanged = fldChanged || this.checkFieldChanged(currrentShipTo.phoneNumber, oldShipTo.phoneNumber);
                    return fldChanged;
                }
                return false;
            },
            checkShipMethodChanged() {
                return this.checkFieldChanged(this.orderDetails.shipMethodCode, this.orderShippedMethod);
            },
            checkFieldChanged(from, to) {
                if (this.$nucleus.isEmpty(from) && this.$nucleus.isEmpty(to)) {
                    return false;
                }
                return (this.$nucleus.isEmpty(from) && !this.$nucleus.isEmpty(to)) ||
                    (!this.$nucleus.isEmpty(from) && this.$nucleus.isEmpty(to)) ||
                    (from != to);
            },
            updateShipTo(address) {
                let shipTo = address;
                if(shipTo.addressee && shipTo.attention && shipTo.addressee != shipTo.attention){
                    shipTo.companyName = shipTo.addressee;
                    shipTo.addressee = shipTo.attention;
                } else {
                    shipTo.companyName = null;
                    shipTo.attention = null;
                }
                return shipTo;
            }
        },
        mounted() {
            this.$eventBus.$on('editShipInfo', () => {
                this.edit();
            });            
         },
        beforeDestroy() {
            this.$eventBus.$off('editShipInfo');
        },
    }
</script>
<style lang="scss" scoped>
    .ship-method {
        width: 50%;
    }

    .card-body {
        padding: 0.5rem;
        background-color: $gray-1
    }

    .card-body p {
        font-size: 1rem;
        font-weight: 700;
        color: #dc3545;
    }
</style>