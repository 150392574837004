<template>
  <AdminTemplate>
    <div class="mb-5">
      <div v-if="isLoading" class="row">
        <p>Loading...</p>
      </div>
      <div v-else>
        <div class="row mb-1">
          <div class="col-md-12">
            <EditUserHeader
              :key="editUserHeaderKey"
              :user="userForEdit"
              :title="$route.meta.title"
              :isNew="isNew"
              :isActive="isActive"
              :isValid="isValid"
              :isEditable="isEditable"
              :tooltipMessage="$t('Storefront.RequiredFieldsToSaveUser')"
              tooltipId="headerWrapper"
              @edit="edit"
              @cancel="cancel"
              @save="save"
            />
            <template v-if="isEditable">
              <UserPrimaryInfo :user="userForEdit" :isNew="isNew" />
              <div class="mt-4">
                <UserContactInfo
                  :user="userForEdit"
                  :defaultPhoneNumber="userForEdit.defaultPhoneNumber"
                />
              </div>
              <div class="mt-4">
                <UserAddressInfo :user="userForEdit"></UserAddressInfo>
              </div>
              <div class="mt-4">
                <UserProperties
                  :user="userForEdit"
                  :attributes="userAttributes"
                  :availableBusinessUnits="availableBusinessUnits"
                  :availableTerritories="availableTerritories"
                  :availableStatuses="availableStatuses"
                  :availableProducts="availableProducts"
                ></UserProperties>
              </div>
            </template>
            <template v-else>
              <UserPrimaryInfoViewOnly :user="userForEdit" />
              <div class="mt-4">
                <UserContactInfoViewOnly
                  :user="userForEdit"
                  :defaultPhoneNumber="userForEdit.defaultPhoneNumber"
                />
              </div>
              <div class="mt-4">
                <UserAddressInfoViewOnly
                  :defaultAddress="defaultAddress"
                  :shippingAddress="shippingAddress"
                />
              </div>
              <div class="mt-4">
                <UserPropertiesViewOnly
                  :attributes="user.attributes"
                ></UserPropertiesViewOnly>
              </div>
            </template>
            <UserTabs
              :user="userForEdit"
              :isEditable="isEditable"
              :roles="allRoles"
              :regions="regions"
              :districts="districts"
              :territories="territories"
            />
            <div class="text-right">
              <ActionButtons
                :isValid="isValid"
                :isEditable="isEditable"
                :tooltipMessage="$t('Storefront.RequiredFieldsToSaveUser')"
                tooltipId="footerWrapper"
                @edit="edit"
                @cancel="cancel"
                @save="save"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { formatting } from "@/nucleus-modules/dd-nucleus-ui/mixins/formattingMixin.js";
import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";
import {
  GET_ALL_ROLES,
  GET_USER_FOR_EDIT,
  GET_COMPANY_SEGMENTS,
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
  ALL_ROLES_GETTER,
  COMPANY_SEGMENTS_GETTER,
  IS_USER_EDIT_DIRTY_GETTER,
  SAVE_USER_ERROR_GETTER,
  USER_FOR_EDIT_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
  SET_USER_FOR_EDIT,
  SET_USER_ROLES,
  SET_IS_USER_EDIT_DIRTY,
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

import {
  UserEvents,
  Status,
  AddressType,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import { addresses } from "@/nucleus-modules/dd-nucleus-admin/mixins/addressesMixin.js";

import EditUserHeader from "@/nucleus-modules/dd-nucleus-admin/components/user/EditUserHeader.vue";
import UserPrimaryInfo from "@/nucleus-modules/dd-nucleus-admin/components/user/UserPrimaryInfo.vue";
import UserPrimaryInfoViewOnly from "@/nucleus-modules/dd-nucleus-admin/components/user/UserPrimaryInfoViewOnly.vue";
import UserContactInfo from "@/nucleus-modules/dd-nucleus-admin/components/user/UserContactInfo.vue";
import UserContactInfoViewOnly from "@/nucleus-modules/dd-nucleus-admin/components/user/UserContactInfoViewOnly.vue";
import UserAddressInfo from "@/nucleus-modules/dd-nucleus-admin/components/user/UserAddressInfo.vue";
import UserAddressInfoViewOnly from "@/nucleus-modules/dd-nucleus-admin/components/user/UserAddressInfoViewOnly.vue";
import UserTabs from "@/nucleus-modules/dd-nucleus-admin/components/user/UserTabs.vue";
import ActionButtons from "@/nucleus-modules/dd-nucleus-admin/components/ActionButtons.vue";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { CLEAR_ERRORS } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { REGENERON_SAVE_USER } from "@/store/admin/actions.type.js";
import UserProperties from "@/components/admin/user/UserProperties.vue";
import UserPropertiesViewOnly from "@/components/admin/user/UserPropertiesViewOnly.vue";
import { errorMixin } from "@/mixins/errorMixin.js";

import {
  AvailableBusinessUnits,
  AvailableProducts,
  AvailableStatuses,
  AvailableTerritories,
  UserSource,
} from "@/constants.js";

import AdminTemplate from "@/pages/templates/AdminTemplate";

export default {
  name: "AddEditUser",
  components: {
    AdminTemplate,
    EditUserHeader,
    UserPrimaryInfo,
    UserPrimaryInfoViewOnly,
    UserContactInfo,
    UserContactInfoViewOnly,
    UserAddressInfo,
    UserAddressInfoViewOnly,
    UserProperties,
    UserPropertiesViewOnly,
    UserTabs,
    ActionButtons,
  },
  mixins: [addresses, formatting, errorMixin],
  props: {
    id: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      availableBusinessUnits: AvailableBusinessUnits,
      availableTerritories: AvailableTerritories,
      availableProducts: AvailableProducts,
      availableStatuses: AvailableStatuses,
      editUserHeaderKey: 0,
      isAccountAddressesValid: false,
      isContactInfoValid: false,
      isEditable: this.editable,
      isLoading: true,
      isPrimaryInfoValid: false,
      isRolesValid: false,
      isSalesStructureValid: false,
      regions: [],
      districts: [],
      territories: [],
      user: null,
      userId: null,
    };
  },
  computed: {
    ...mapGetters({
      allRoles: ALL_ROLES_GETTER,
      salesSegments: COMPANY_SEGMENTS_GETTER,
      isUserEditDirty: IS_USER_EDIT_DIRTY_GETTER,
      saveUserErrors: SAVE_USER_ERROR_GETTER,
      userForEdit: USER_FOR_EDIT_GETTER,
    }),

    userAttributes() {
      if (this.userForEdit.attributes) return this.userForEdit.attributes;
      else {
        return {};
      }
    },
    defaultPhoneNumber() {
      let result = null;

      if (
        !this.$nucleus.isEmpty(this.userForEdit) &&
        this.userForEdit.phoneNumbers &&
        this.userForEdit.phoneNumbers.length > 0
      ) {
        result = this.formatPhoneNumber(
          this.userForEdit.phoneNumbers[0].number
        );
      } else {
        result = "";
      }

      return result;
    },
    defaultAddress() {
      const title = "Primary Account Address";
      let address;

      if (
        !this.$nucleus.isEmpty(this.userForEdit) &&
        !this.$nucleus.isEmpty(this.userForEdit.addresses)
      ) {
        for (const addr of this.userForEdit.addresses) {
          if (addr.addressType == AddressType.BILLING) {
            address = addr;
          }
        }
      } else {
        address = this.getEmptyAddress();
      }

      address.title = title;

      return address;
    },
    shippingAddress() {
      const title = "Default Shipping Address";
      let address;

      if (
        !this.$nucleus.isEmpty(this.userForEdit) &&
        !this.$nucleus.isEmpty(this.userForEdit.addresses)
      ) {
        for (const addr of this.userForEdit.addresses) {
          if (addr.addressType == AddressType.SHIPPING) {
            address = addr;
          }
        }
      } else {
        address = this.getEmptyAddress();
      }

      address.title = title;

      return address;
    },
    isNew() {
      if (this.userId && this.refresh) {
        this.eventsAfterSave(this.userId);
      }
      return this.userId ? false : true;
    },

    isActive() {
      return !this.isNew && this.user.status == Status.Active;
    },
    isValid() {
      return (
        this.isPrimaryInfoValid &&
        this.isContactInfoValid &&
        this.isAccountAddressesValid &&
        this.isRolesValid
      );
    },
  },
  methods: {
    ...mapActions({
      getAllRoles: GET_ALL_ROLES,
      getCompanySegments: GET_COMPANY_SEGMENTS,
      getUserForEdit: GET_USER_FOR_EDIT,
      saveUser: REGENERON_SAVE_USER,
      clearErrors: CLEAR_ERRORS,
    }),
    ...mapMutations({
      setUserForEdit: SET_USER_FOR_EDIT,
      setUserRoles: SET_USER_ROLES,
      setIsUserEditDirty: SET_IS_USER_EDIT_DIRTY,
    }),
    addValidationFlags() {
      this.isPrimaryInfoValid = false;
      this.isContactInfoValid = false;
      this.isAccountAddressesValid = false;
      this.isSalesStructureValid = false;
      this.isRolesValid = false;
    },

    isDirty() {
      let initialValues = JSON.parse(this.oldValues);
      let currentValues = this.userForEdit;
      if (!("manuallyAssign" in initialValues)) {
        initialValues["manuallyAssign"] = null;
      }
      if (!this.isNew) {
        if (JSON.stringify(initialValues) !== JSON.stringify(currentValues)) {
          return true;
        }
      } else {
        currentValues = this.userForEdit;
        delete currentValues["currentSso"];
        if (!currentValues["firstName"]) {
          return false;
        }

        if (JSON.stringify(initialValues) !== JSON.stringify(currentValues)) {
          return true;
        }
      }
      return false;
    },
    async cancel() {
      if (this.isDirty() && this.isEditable) {
        const cancelConfirmation = new ConfirmationBoxService({});
        cancelConfirmation.options.size = "md";
        if (this.isValid) {
          cancelConfirmation.options.okTitle = "Save";

          const confirmed = await cancelConfirmation.showConfirm(
            `Would you like to save your changes to user ${this.user.userName} before leaving?`
          );

          if (confirmed) {
            await this.save();
          }

          this.navigateToAllUsers();
        } else {
          cancelConfirmation.options.cancelTitle = "Cancel";
          cancelConfirmation.options.okTitle = "Close";
          let userNameValue = "";
          if (this.user.userName != null) {
            userNameValue = this.user.userName;
          }
          const confirmed = await cancelConfirmation.showConfirm(
            `The user ${userNameValue} has been modified, but is missing required information. Choose Close to continue editing this user or Cancel to exit without saving changes.`
          );

          if (confirmed) {
            // close the confirmation box and allow the user to continue editing
          } else {
            this.navigateToAllUsers();
          }
        }
      } else {
        this.$router.back();
      }
    },
    clearUserInfo() {
      this.user = null;
    },
    edit() {
      this.isEditable = true;
    },
    async eventsAfterSave() {
      var vm = this;
      vm.user = Object.assign({}, vm.user);
    },
    getAddressTitle(address) {
      switch (address.addressType) {
        case AddressType.BILLING:
          return "Billing Address";
        case AddressType.SHIPPING:
          return "Shipping Address";
        default:
          return "Address";
      }
    },
    async initUserDetails() {
      let userId = this.$route.params.id;

      if (userId) {
        await this.getUserForEdit(userId);

        await this.showNetworkError();
        
        this.user = this.userForEdit;

        this.oldValues = this.userForEdit;

        this.setDefaultValues();

        this.setUserForEdit(this.user);
      } else {
        this.user = this.initUser();
        this.oldValues = this.initUser();
      }
    },
    setDefaultValues() {
      if (!this.$nucleus.isEmpty(this.user.phoneNumbers)) {
        this.user.defaultPhoneNumber = this.formatPhoneNumber(
          this.user.phoneNumbers[0].number
        );
      } else {
        this.user.defaultPhoneNumber = "";
      }

      if (!this.$nucleus.isEmpty(this.user.addresses)) {
        let addresses = this.user.addresses;
        for (const address of addresses) {
          if (address.addressType == AddressType.BILLING) {
            this.user.defaultAddress = address;
          } else if (address.addressType == AddressType.SHIPPING) {
            this.user.shippingAddress = address;
          }
        }
      } else {
        this.user.defaultAddress = this.getEmptyAddress();
        this.user.shippingAddress = this.getEmptyAddress();
      }

      if (
        !this.$nucleus.isEmpty(this.user.attributes) &&
        !this.$nucleus.isEmpty(this.user.attributes.TerritoryInfo)
      ) {
        let territoryInfo = this.user.attributes.TerritoryInfo;
        if (territoryInfo.region) this.user.region = territoryInfo.region;
        if (territoryInfo.district) this.user.district = territoryInfo.district;
        if (territoryInfo.territory)
          this.user.territory = territoryInfo.territory;
      }
      if (
        !this.$nucleus.isEmpty(this.user.attributes) &&
        !this.$nucleus.isEmpty(this.user.attributes.IsSso)
      ) {
        this.user.IsSso = this.user.attributes.IsSso;
      }
    },
    initAddress() {
      return {
        addresseeFirstName: null,
        addresseeLastName: null,
        addressLine1: !this.isNew ? "" : null,
        addressLine2: !this.isNew ? "" : null,
        city: !this.isNew ? "" : null,
        state: !this.isNew ? "" : null,
        postalCode: !this.isNew ? "" : null,
      };
    },
    initEmail() {
      return {
        address: !this.isNew ? "" : null,
      };
    },
    initPhone() {
      return {
        number: !this.isNew ? "" : null,
      };
    },
    initUser() {
      return {
        id: null,
        userName: this.isNew ? null : "",
        firstName: this.isNew ? null : "",
        lastName: this.isNew ? null : "",
        companyName: null,
        title: null,
        password: null,
        confirmPassword: null,
        currentSso: false,
        access: false,
        email: null,
        defaultEmailAddress: this.initEmail(),
        phone: this.initPhone(),
        mobile: this.initPhone(),
        primaryAddress: this.initAddress(),
        defaultShipAddress: this.initAddress(),
        roles: [],
        addresses: [],
        phoneNumbers: [],
        emailAddresses: [],
        primaryRoleId: null,
        companySegments: [],
        region: null,
        district: null,
        territory: null,
        created: null,
        updated: null,
        comments: null,
        costcenter: null,
        organization: null,
        product: null,
        hireDate: null,
        reportingManager: null,
        userRoleIds: [],
        attributes: {
          EmployeeId: "",
          EmployeeStatus: "",
          BusinessUnit: "",
          Organization: "",
          GeoId: "",
          ReportsToManager: "",
          Product: "",
          TerritoryLevel: "",
          TerritoryName: "",
          LastModifiedDate: "",
          UserSource: "",
        },
      };
    },
    navigateToAllUsers() {
      this.$router.push("/admin/all-users");
    },
    async save() {
      const contact = {
        id: this.userForEdit.contactId,
        firstName: this.userForEdit.firstName,
        lastName: this.userForEdit.lastName,
        title: this.userForEdit.title,
        email: this.userForEdit.email,
        phoneNumber: this.userForEdit.phoneNumber,
      };

      const additionalInfo = {
        employeeId: this.userForEdit.attributes.EmployeeId,
        employeeStatus: this.userForEdit.attributes.EmployeeStatus,
        businessUnit: this.userForEdit.attributes.BusinessUnit,
        organization: this.userForEdit.attributes.Organization,
        geoId: this.userForEdit.attributes.GeoId,
        reportsToManager: this.userForEdit.attributes.ReportsToManager,
        product: this.userForEdit.attributes.Product,
        territoryLevel: this.userForEdit.attributes.TerritoryLevel,
        territoryName: this.userForEdit.attributes.TerritoryName,
        userSource: UserSource.MANUAL,
      };

      let defaultAddress = this.userForEdit.defaultAddress;
      defaultAddress.isResidential = true;
      let shippingAddress = this.userForEdit.shippingAddress;
      shippingAddress.isResidential = true;

      if (
        this.userForEdit.userRoleIds &&
        this.userForEdit.userRoleIds.length > 0 &&
        this.$nucleus.isEmpty(this.userForEdit.primaryRoleId)
      ) {
        this.userForEdit.primaryRoleId = this.userForEdit.userRoleIds[0];
      }

      await this.saveUser({        
        userId: this.userForEdit.id,
        contact: contact,
        defaultAddress: defaultAddress,
        shippingAddress: shippingAddress,
        additionalInfo: additionalInfo,
        userRoleIds: this.userForEdit.userRoleIds,
      });

      // check for save user error
      if (!this.$nucleus.isEmpty(this.saveUserErrors)) {
        let saveErrors = this.saveUserErrors;
        let messages = "";

        saveErrors.forEach((e) => {
          messages += e.debugMessage;
        });

        ToastService.showErrorToast(messages);
        this.clearErrors();
      } else {
        this.editUserHeaderKey += 1;

        this.$scrollTo("#logo", 500, { easing: "ease-in-out" });
        ToastService.showSuccessToast("The user has been saved.");
        this.user = this.userForEdit;

        this.setDefaultValues();
        this.userId = this.user.id;
        this.setUserForEdit(this.user);
      }
    },
    setImmutableData() {
      // Don't want to update an existing userName until after save
      this.user.userName = Object.freeze(this.user.userName);

      if (this.user.attributes) {
        this.user.currentSso = Object.freeze(this.user.attributes.IsSso);
      }
      this.oldValues = Object.freeze(JSON.stringify(this.oldValues));
    },
    setupListeners() {
      const vm = this;

      this.$eventBus.$on(UserEvents.USER_PRIMARY_INFO_UPDATED, (isValid) => {
        if (vm.user) {
          vm.isPrimaryInfoValid = isValid;
        }
      });

      this.$eventBus.$on(UserEvents.USER_CONTACT_INFO_UPDATED, (isValid) => {
        if (vm.user) {
          vm.isContactInfoValid = isValid;
        }
      });

      this.$eventBus.$on(
        UserEvents.USER_ACCOUNT_ADDRESSES_UPDATED,
        (isValid) => {
          if (vm.user) {
            vm.isAccountAddressesValid = isValid;
          }
        }
      );

      this.$eventBus.$on(UserEvents.USER_ROLES_UPDATED, (payload) => {
        this.setUserRoles(payload.selectedRoles);
        vm.isRolesValid = payload.isValid;
      });

      this.$eventBus.$on(UserEvents.USER_SALES_STRUCTURE_UPDATED, (isValid) => {
        if (vm.user) {
          vm.isSalesStructureValid = isValid;
        }
      });

      this.$eventBus.$on(
        UserEvents.USER_SALES_STRUCTURE_SELECTED,
        async (salesStruct) => {
          if (!this.$nucleus.isEmpty(salesStruct) && salesStruct.id) {
            if (salesStruct.name == "district") {
              await this.getDistricts(salesStruct.id);
            } else if (salesStruct.name == "territory") {
              await this.getTerritories(salesStruct.id);
            }
          }
        }
      );

      this.$eventBus.$on(UserEvents.USER_SAVED, async (userId) => {
        await this.eventsAfterSave(userId);
      });
    },
    async straightenUser() {
      if (!this.$nucleus.isEmpty(this.user.mobileNumber)) {
        this.user.mobile = {
          number: this.formatPhoneNumber(this.user.mobileNumber.number),
          id: this.user.mobileNumber.id,
        };
      } else {
        this.user.mobile = this.initPhone();
      }

      if (!this.$nucleus.isEmpty(this.user.defaultPhoneNumber)) {
        this.user.phone = {
          number: this.formatPhoneNumber(this.user.defaultPhoneNumber.number),
          id: this.user.defaultPhoneNumber.id,
        };
      } else {
        this.user.phone = this.initPhone();
      }

      if (!this.$nucleus.isEmpty(this.user.defaultEmailAddress)) {
        this.user.email = {
          address: this.user.defaultEmailAddress.address,
          id: this.user.defaultEmailAddress.id,
        };
      } else {
        this.user.email = this.initEmail();
      }

      this.user.primaryAddress = this.user.billingAddress || this.initAddress();
      this.user.defaultShipAddress =
        this.user.defaultAddress || this.initAddress();
      this.user.manuallyAssign = null;
      this.user.password = "";
      this.user.confirmPassword = "";
    },
  },
  async mounted() {
    this.userId = this.id;
    await this.getAllRoles();

    await this.initUserDetails();

    this.oldValues = this.user;

    this.setImmutableData();

    this.setupListeners();

    this.isLoading = false;
  },

  beforeDestroy() {
    this.clearUserInfo();
    const user = this.initUser();
    this.setUserForEdit(user);
    this.$eventBus.$off(UserEvents.USER_PRIMARY_INFO_UPDATED);
    this.$eventBus.$off(UserEvents.USER_CONTACT_INFO_UPDATED);
    this.$eventBus.$off(UserEvents.USER_ACCOUNT_ADDRESSES_UPDATED);
    this.$eventBus.$off(UserEvents.USER_ROLES_UPDATED);
    this.$eventBus.$off(UserEvents.USER_SALES_STRUCTURE_UPDATED);
    this.$eventBus.$off(UserEvents.USER_SAVED);
  },
};
</script>

<style lang="scss" scoped>
.n-error {
  color: $red;
  margin-left: 4px;
}

.reloading {
  pointer-events: none;
  opacity: 0.4;
}

.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
  max-width: 700px;
}
</style>
