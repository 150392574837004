<template>
  <AdminTemplate>
    <div v-if="!isDelete && !isLoading" class="row" >
      <div class="col-md-12">
          <div class="row">
              <div class="col-md-12"><h4 class="reportHeader">Reports</h4></div>
          </div> 
          <div class="row">
          <div class="col-md-12">
          <b-badge
            class="nav-pill"
            href="/admin/add-edit-report"
            variant="primary"
            >Add Report</b-badge
          >
          </div>
        </div>
          <div>
              <ReportList :list="reports" @deleteReport="deleteReport" />
          </div>
        <div v-if="!(reports && reports.length)">
             <div>
                <span class="no-reports">There are no reports set up at this time. </span>
                <router-link to="/admin/add-edit-report" class="add-report">Add a New Report</router-link>
             </div>
        </div>
      </div>
    </div>
    <div v-if="isDelete && !isLoading" class="row">
        <div class="col-12">
            <div class="n-delete-confirm-overlay">
                <div class="n-delete-confirm-text">
                    <div class="row">
                        <div class="col text-center">Are you sure you want to delete this report?</div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-center">
                            <button class="btn btn-white mr-5" @click="cancelDelete">Cancel</button>
                            <button class="btn btn-white" @click="confirmDelete">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/pages/templates/AdminTemplate";
import ReportList from "@/components/admin/report/report-list";
import reportApi from "@/api/report-api";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
export default {
  name: "All-Reports",
  components: {
    AdminTemplate,
    ReportList
  },
  data() {
    return {
      reports:null,
      isDelete: false,
      idToDelete:null,
      isLoading: true,
    }
  },
  async mounted() {
    this.reports = await reportApi.getReports();
    this.isLoading = false;
  },
  methods: {
    cancelDelete(){
      this.isDelete = false;
    },

    deleteReport(id){
      this.idToDelete = id;
      this.isDelete = true;
    },

    async confirmDelete(){
      let title, message;
      let response = await reportApi.deleteReport(this.idToDelete);

      if (response && response.succeeded){
        // Remove the report for the UI 
        for(var i = 0; i < this.reports.length; i++) {
          if(this.reports[i].id == this.idToDelete) {
              this.reports.splice(i, 1);
              break;
          }
        }
        // remove the overlay from the page 
        this.isDelete = false;
        // Message to show for successful delete 
        message = "Report has been deleted successfully.";
        ToastService.showSuccessToast(message);
      } else {
        title = "Error saving the report";
        if ( response && response.messages  && response.messages[0].debugMessage && response.messages[0].debugMessage.length ) {
            message = response.messages[0].debugMessage;
        } else {
            message = "The report was not deleted successfully please try again.";
        }
        ToastService.showErrorToast(message, title);
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.no-reports {
  font-size: 16px;
  color: $tertiary-color;
}
.add-report {
  font-size: 12px;
  text-decoration: underline;
}
.reportHeader {
    color:$primary-dark-color;
    font-weight: 600;
}
.n-delete-confirm-overlay{
    background: $primary-color;
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
}
.n-delete-confirm-text {
    left: 50%;
    display: block;
    position: fixed;
    top: 40%;
    transform: translate(-50%, -50%);
    color:#fff;
    font-size: 1.25rem;
}
</style>
