<template>
  <div class="row">
    <div class="col-4 col-md-2">
      <router-link :to="destinationUrl">
        <img :src="thumbnailImageUrl" :name="product.name" :alt="product.name" class="img-fluid" />
      </router-link>
    </div>
    <div class="col-8 col-md-5">
      <div class="badges" v-for="badge in product.tags" :key="badge.id">
        <span class="badge badge-primary">{{ badge.name }}</span>
      </div>
      <span v-if="isAddOnItem" class="badge badge-primary add-on-badge">Add-on item</span>
      <router-link :to="destinationUrl" class="d-block n-product-name mb-2">
        {{ product.name }}</router-link>
      <div>{{ product.itemNumber }}</div>
      <h4 class="n-product-type">Product Type: {{ productType }}</h4>
      <template v-if="isRegularItem">
        <template v-if="product.messages && product.messages.length">
          <div class="n-message-list">
            <MessageList :messages="product.messages" />
          </div>
        </template>
        <slot name="limits"></slot>
      </template>
    </div>
    <div class="col-12 col-md-2">
      <div v-if="isPricingEnabled">
        <div v-if="isOnSale" class="n-price-grid-view">
          {{ product.salePrice | currency }}
          <div class="n-price-on-sale">
            {{ $t("shared.pricing.salePriceWas") }}
            {{ product.unitPrice | currency }}
          </div>
        </div>
        <div v-else class="n-price">
          {{ product.unitPrice | currency }}
        </div>
        <template v-if="isUnitOfMeasureAvailable(item.product)">
          <UoM :item="item" :uom="getInventory(item.product).unitOfMeasure"
            :unitQuantity="getInventory(item.product).quantityPerUnit"></UoM>
        </template>
      </div>
    </div>
    <div class="col-8 offset-4 col-md-3 offset-md-0">
      <div class="row">
        <div class="col-sm-6 pr-1 font-weight-bold">
          Quantity:
        </div>
      </div>
      <div class="row">
        <div class="col-6 pr-1" v-if="isRegularItem">
          <QuantityInput v-if="!isViewOnly" :eventName="UiEvents.QUANTITY_INPUT_CHANGED" :item="item"
            :min="1">
          </QuantityInput>
          <span >{{ product.quantity }}</span>
        </div>
        <div class="add-on-styling" v-else>
          <span>{{ item.quantity }}</span>
        </div>
        <div class="col-6 pl-1">
          <label class="mt-1">
            <template v-if="isUnitOfMeasureAvailable(item.product)">
              <UoM :item="item" :uom="getInventory(item.product).unitOfMeasure"
                :unitQuantity="getInventory(product).quantityPerUnit"></UoM>
            </template>
          </label>
        </div>
        <div class="col-12">
          <FavoriteProductIcon  v-if="isRegularItem" :id="product.id" :isFavorite="isFavoriteProduct(product.id)" :showText="false" 
           :showIconToAuthUsersOnly="true" addFavoriteTextId="shared.favoriteIcon.addText"
            removeFavoriteTextId="shared.favoriteIcon.removeText"></FavoriteProductIcon>
          <span v-if="isRegularItem">&nbsp; &nbsp;|&nbsp; &nbsp;</span>
          <a href="#" @click.prevent="remove(item)">{{
          $t("shoppingCart.remove")
          }}</a>
        </div>
      </div>
    </div>
    <div v-if="showSeparator" class="col-12 mt-2 mb-2">
      <hr />
    </div>
  </div>
</template>

<script>
import QuantityInput from "@/nucleus-modules/dd-nucleus-ui/components/QuantityInput.vue";
import UoM from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import FavoriteProductIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteProductIcon.vue";
import MessageList from "@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue";
import { productMixins } from "@/mixins/productMixin.js";

export default {
  name: "CartItem",
  mixins: [productMixins],
  components: {
    QuantityInput,
    UoM,
    FavoriteProductIcon,
    MessageList,
  },
  props: {
    cartId: {
      type: String,
      required: true,
    },
    destinationUrl: {
      type: String,
    },
    isPricingEnabled: {
      type: Boolean,
      default: false,
    },
    isViewOnly: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    noImageAvailableUrl: {
      type: String,
      required: true,
    },
    showSeparator: {
      type: Boolean,
      required: false,
      default: true,
    },
    favorites: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      UiEvents: Object.freeze(UiEvents),
    };
  },
  computed: {
    isOnSale() {
      return (
        this.item.product.salePrice &&
        this.item.product.salePrice < this.item.product.unitPrice
      );
    },
    product() {
      return this.item.product;
    },
    thumbnailImageUrl() {
      if (this.product && this.product.thumbnailImageUrl) {
        return this.product.thumbnailImageUrl;
      } else {
        return this.noImageAvailableUrl;
      }
    },
    isRegularItem() {
      return !this.isAddOnItem;
    },
    isAddOnItem() {
      if (this.product.attributes && this.product.attributes.Addon) {
        return this.product.attributes.Addon.isAddOn;
      }
      return false;
    },
    productType() {
      if(this.product.attributes && this.product.attributes.ExtendedProductProperties && this.product.attributes.ExtendedProductProperties.productType) {
        return this.product.attributes.ExtendedProductProperties.productType;
      }
      return "";
    }
  },
  methods: {
    remove(item) {
      this.$eventBus.$emit(UiEvents.REMOVE_CART_ITEM_CLICKED, {
        cartId: this.cartId,
        item: item,
      });
    },
    isFavoriteProduct(id) {
      return this.favorites.some((product) => {
        return product.id == id;
      })
    }
  },
};
</script>

<style scoped lang="scss">
.add-on-styling
{
  padding-top:5px;
  padding-left:15px;
}

.n-product-type {
    font-size: 15px;
}
</style>


