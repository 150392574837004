<template>
  <div id="app" v-cloak :class="{ 'content-mask': isLocaleChanging }">
    <ModalRoot />
    <Loader v-if="isLoading"></Loader>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import JwtService from "@/nucleus-modules/dd-nucleus-ui/services/jwt.service.js";
import ModalRoot from "@/nucleus-modules/dd-nucleus-ui/components/modal/ModalRoot";


import {
  IS_LOADING_GETTER,
  IS_LOCALE_CHANGING_GETTER,
  SYSTEM_ERRORS_GETTER,
} from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { SET_USER_INFO } from "@/nucleus-modules/dd-nucleus-ui/store/mutations.type.js";

import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

import Loader from "@/components/Loader.vue";

import { LOGIN_PATH , INDEX_ENTRY_PATH} from "@/router/index.js";

export default {
  name: "App",
  components: {
    Loader,
    ModalRoot,
  },
  data() {
    return {
      unsubscribe: null,
      authenticated: false 
    };
  },
  computed: {
    ...mapGetters({
      networkErrors: SYSTEM_ERRORS_GETTER,
      isLoading: IS_LOADING_GETTER,
      isLocaleChanging: IS_LOCALE_CHANGING_GETTER,
      siteSetup: SITE_SETUP_GETTER,
    }),
  },
  methods: {
    ...mapMutations({
      setUserInfo: SET_USER_INFO,
    }),
    async initListeners() {
      const vm = this;

      this.$eventBus.$on(UiEvents.SIGN_IN_SUCCEEDED, async () => {
        vm.setUserInfo(vm.siteSetup.userInfo);
      });

      const tokenManager = await vm.$auth.tokenManager;
      tokenManager.on('expired', () => {
        vm.$router.push({ path: LOGIN_PATH });
      });
    },
    async redirectAuthenticatedUser() {
      if (await JwtService.getToken()) {
        await this.assignUserFromJwt();

        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ path: LOGIN_PATH });
      }
    },
    removeListeners() {
      this.$eventBus.$off(UiEvents.SIGN_IN_SUCCEEDED);
    },
  },
  beforeDestroy() {
    this.removeListeners();
    this.unsubscribe();
  },
  async mounted() {
    await this.initListeners();
    
    if(this.$route.path != "/" && this.$route.path == INDEX_ENTRY_PATH) {
      this.$router.push("/");
    }
  },
   watch: {
    $route: {
      handler: (to) => {
        if (to && to.meta.title) {
          document.title = to.meta.title + " | Regeneron";
        } else {
          document.title = "Regeneron";
        }
      },
      immediate: true,
    }
  }, 
  };
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

.content-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(2px);
}

#scrollTop {
  position: fixed;
  right: 20px;
  bottom: 200px;
  font-size: 40px;
}

#scrollTop a:hover {
  text-decoration: none;
}

#scrollTop span {
  display: block;
  text-align: center;
  font-size: 20px;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1366px !important;
  }
}
</style>
