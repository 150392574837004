<template>
  <span>
    <label :for="item.id" class="sr-only">Quantity Input</label>
    <input
      type="number"
      :min="min"
      class="form-control n-quantity-box"
      :class="{ 'is-invalid': !isValid }"
      :placeholder="placeholder"
      v-model="quantity"
      :id="item.id"
      @input="onKey()"
      @blur="onChange()"
    />
  </span>
</template>

<script>
// Provides a text box for editing quantity
// NOTE: Assumes that item has a "quantity" property, which will be updated as it changes

export default {
  name: "QuantityInput",
  props: {
    // Product or cart item
    item: {
      type: Object,
      required: true,
    },
    // Minimum quantity allowed
    min: {
      type: Number,
      required: false,
      default: 1,
    },
    // Placeholder text to use
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    // Called if the quantity changed, the user left the field, and the quantity was zero
    quantityChanged: {
      required: false,
    },
    eventName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      quantity: this.min
    };
  },
  methods: {
    broadcastChange() {
      this.$eventBus.$emit(this.eventName, {
        isValid: this.isValid,
        item: this.item,
      });
    },
    // Key pressed
    onKey() {
      this.$set(this.item, "quantity", this.quantity);
      this.validate();
      if (this.isValid) {
        this.broadcastChange();
      }
    },
    // Value changed and focus left text box
    onChange() {
      this.$set(this.item, "quantity", this.quantity);
      this.validate();
      if (this.isValid) {
        this.broadcastChange();
      }
    },
    validate() {
      this.isValid = this.item.quantity && this.item.quantity >= this.min;
      this.$emit("quantityChanged");
    },
  },
  created() {
    this.onKey = this.$nucleus.debounce(this.onKey, 500);
    this.onChange = this.$nucleus.debounce(this.onChange, 500);
    this.broadcastChange = this.$nucleus.debounce(this.broadcastChange, 500);
    this.$set(this.item, "quantity", this.item.quantity || this.min);
    this.quantity = this.item.quantity;
    this.validate();
  },
};
</script>

<style scoped lang="scss">
.n-quantity-box {
  width: 100% !important;
}
</style>
