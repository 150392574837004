import Vue from "vue";
import moment from "moment";
import App from "./App.vue";
import "../registerServiceWorker";
import router from '@/router'
import store from "../store";
import i18n from "../i18n";
import nucleus from "../nucleus.js";

import '@okta/okta-auth-js/polyfill';
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import oktaConfig from '@/ssoConfig.js' 

import "@babel/polyfill";

// scss
import '@/site/scss/main.scss';

// Font Awesome Declarations    https://github.com/FortAwesome/vue-fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faMinus, faCartArrowDown, faBoxOpen, faUserFriends, faExclamationCircle, faFileImage, faSpinner, faCircle, faShoppingCart, faSearch, faQuestionCircle, faBars, faChevronRight, faDownload, faEye, faTrashAlt, faTimes, faAngleDown, faAngleUp, faChevronDown, faChevronLeft, faChevronUp, faPlus, faHeart as fasHeart, faGripHorizontal, faThList, faInfoCircle, faArrowAltCircleUp, faFilter, faCheckCircle, faStar, faTimesCircle, faInfoCircle as fasInfoCircle, faCheck, faUser, faUsers, faSlidersH, faFileExcel, faFileCsv, faLock } from '@fortawesome/free-solid-svg-icons';
import { faPauseCircle, faPlayCircle, faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faCcVisa, faCcMastercard, faCcDiscover, faCcAmex, faCcStripe } from '@fortawesome/free-brands-svg-icons';
import { faBoxFull, faBullseye, faBrowser, faList, faInfoCircle as farInfoCircle, faAnalytics, faPlusCircle, faUserCircle, faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionSquare, faPencil, faBadgeDollar } from '@fortawesome/pro-solid-svg-icons';
import { faCartArrowDown as falCartArrowDown } from '@fortawesome/pro-light-svg-icons';

library.add(faEnvelope,faPauseCircle, faPlayCircle, faMinus, faUserFriends, faExclamationCircle, faCheck, faCircle, faShoppingCart, faSearch, faQuestionCircle, faBars, faChevronRight, faDownload, faTrashAlt, faEye, faTimes, faEllipsisV, faAngleDown, faAngleUp, faChevronDown, faChevronLeft, faPlus, fasHeart, farHeart, faGripHorizontal, faThList, faInfoCircle, faArrowAltCircleUp, faFilter, faCheckCircle, faCcVisa, faCcMastercard, faCcDiscover, faCcAmex, faCcStripe, faStar, faTimesCircle, faInfoCircle, faUser, faUsers, faSlidersH, faFileExcel, faFileCsv, faLock);
library.add(faQuestionSquare, falCartArrowDown, faCartArrowDown, faBoxOpen, faBadgeDollar, fasInfoCircle, faFileImage, faSpinner, faBrowser, faList, faCircle, faShoppingCart, faPlusCircle, farInfoCircle, faAnalytics, faBoxFull, faSearch, faQuestionCircle, faBars, faChevronRight, faTimes, faAngleDown, faAngleUp, faChevronDown, faChevronUp, faChevronLeft, fasHeart, farHeart, faGripHorizontal, faThList, faArrowAltCircleUp, faFilter, faCheckCircle, faCcStripe, faStar, faBullseye, faUserFriends, faUserCircle, faPencil, faTimesCircle, faInfoCircle, faUser);

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents);

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import Vuelidate from 'vuelidate'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));
Vue.use(require('vue-scrollto'))

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

//https://github.com/AurityLab/vue-recaptcha-v3/tree/vue-v2.x
import { RECAPTCHA_KEY } from "@/constants.js";
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_KEY, loaderOptions: { autoHideBadge: true } });

// Nucleus plugin functions
const nucleusPlugin = {
  install() {
    Vue.nucleus = nucleus;
    Vue.prototype.$nucleus = nucleus;
  }
}
Vue.use(nucleusPlugin);
// end Nucleus plugin functions

// Moment filter
Vue.filter("moment", function (value, format, emptyText = "") {
  if (value === null || value === undefined || value === "" || format === undefined) {
    return emptyText;
  }

  if (format === "from") {
    return moment(value).fromNow();
  }

  return moment(value).format(format);
});
// End Moment filter

//Okta OpenId
 const oktaAuth = new OktaAuth(oktaConfig.oidc);
Vue.use(OktaVue, { oktaAuth } ); 

// global event bus
Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
