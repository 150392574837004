export const formatting = {
    methods: {
        clearPhoneNumberFormatting(phoneNumber) {
            if (phoneNumber) {
                return phoneNumber.replace(/-/g, "");
            }
        },
        formatPhoneNumber(phoneNumber) {
            if (phoneNumber) {
                let x = phoneNumber
                    .replace(/\D/g, "")
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                return !x[2] ? x[1] : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
            }

            return phoneNumber;
        }
    }
}
