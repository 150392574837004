<template>
  <div id="createBulkOrder">
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else class="row">
      <div class="col">
        <h1 class="d-inline">Create Bulk Order</h1>
        <a class="d-inline ml-4" @click="backToHome()"> Back to All </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="text-right mb-3">
          <BulkOrderNavigation
            :on-first-tab="onFirstTab"
            :on-last-tab="onLastTab"
            :is-next-disabled="isNextDisabled"
            v-on:next-step="nextStep"
            v-on:previous-step="previousStep"
          ></BulkOrderNavigation>
        </div>
        <b-tabs v-model="stepper.currentIndex" content-class="mt-3" fill>
          <b-tab>
            <template v-slot:title>
              Select<br />Recipients
              <span v-if="selectedRecipients && selectedRecipients.length > 0"
                >({{ selectedRecipients.length }})</span
              >
            </template>
            <SelectRecipients
              :availableStatuses="availableStatuses"
              :allDistricts="allDistricts"
              :allRegions="allRegions"
              :allTerritories="allTerritories"
              :isLoading="isLoading"
              :loginAccessList="loginAccessList"
              @reset="reset"
            >
            </SelectRecipients>
          </b-tab>
          <b-tab>
            <template v-slot:title>Preview<br />Recipients</template>
            <PreviewRecipients></PreviewRecipients>
          </b-tab>
          <b-tab>
            <template v-slot:title>Select<br />Products</template>
            <SelectProducts></SelectProducts>
          </b-tab>
          <b-tab>
            <template v-slot:title>Select<br />Ship Method</template>
            <SelectShipMethod
              :isLoading="isLoading"
              :shipMethods="shipMethods"
            ></SelectShipMethod>
          </b-tab>
          <b-tab>
            <template v-slot:title>Review<br />Order</template>
            <ReviewBulkOrder
              :isLoading="isLoading"
              v-on:nav-to-products="navToProducts"
              v-on:nav-to-recipients="navToRecipients"
              v-on:nav-to-ship-method="navToShipMethod"
              v-on:cancel-bulk-order="cancelBulkOrder"
              v-on:show-confirmation="showConfirmation"
              v-on:back-to-home="backToHome"
            ></ReviewBulkOrder>
          </b-tab>
        </b-tabs>
        <div class="text-right mt-3 mb-3">
          <BulkOrderNavigation
            :on-first-tab="onFirstTab"
            :on-last-tab="onLastTab"
            :is-next-disabled="isNextDisabled"
            v-on:next-step="nextStep"
            v-on:previous-step="previousStep"
          ></BulkOrderNavigation>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";

import { AdminEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import BulkOrderNavigation from "@/components/admin/bulk-orders/BulkOrderNavigation.vue";
import SelectRecipients from "@/components/admin/bulk-orders/SelectRecipients.vue";
import PreviewRecipients from "@/components/admin/bulk-orders/PreviewRecipients.vue";
import SelectProducts from "@/components/admin/bulk-orders/SelectProducts.vue";
import SelectShipMethod from "@/components/admin/bulk-orders/SelectShipMethod.vue";
import ReviewBulkOrder from "@/components/admin/bulk-orders/ReviewBulkOrder.vue";

export default {
  name: "CreateBulkOrder",
  mixins: [BulkOrdersMixin],
  components: {
    BulkOrderNavigation,
    SelectRecipients,
    PreviewRecipients,
    SelectProducts,
    SelectShipMethod,
    ReviewBulkOrder,
  },
  props: {
    allDistricts: {
      type: Array,
      required: true,
    },
    allRegions: {
      type: Array,
      required: true,
    },
    allTerritories: {
      type: Array,
      required: true,
    },
    availableStatuses: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    loginAccessList: {
      type: Array,
      required: true,
    },
    shipMethods: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stepper: {
        currentIndex: 0,
        tabs: [
          { index: 0, code: "SELECT_RECIPIENTS" },
          { index: 1, code: "PREVIEW_RECIPIENTS" },
          { index: 2, code: "SELECT_PRODUCTS" },
          { index: 3, code: "SELECT_SHIP_METHOD" },
          { index: 4, code: "REVIEW_ORDER" },
        ],
      },
      reset:false
    };
  },
  computed: {
    onFirstTab() {
      return this.stepper.currentIndex === 0;
    },
    onLastTab() {
      return (
        this.stepper.currentIndex ===
        this.stepper.tabs[this.stepper.tabs.length - 1].index
      );
    },
    hasSearchResults() {
      return (
        !this.$nucleus.isEmpty(this.searchRecipientsResponse) &&
        !this.$nucleus.isEmpty(this.searchRecipientsResponse.results)
      );
    },
    isNextDisabled() {
      if (
        this.stepper.currentIndex ===
          this.getTabIndexByCode("SELECT_PRODUCTS") &&
        !this.hasSelectedProducts
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    initListeners() {
      const vm = this;
      this.$eventBus.$on(AdminEvents.BULK_ORDER_CANCELLED, () => {
        vm.stepper.currentIndex = 0;
      });
    },
    backToHome() {
      this.stepper.currentIndex = 0;
      this.$eventBus.$emit(AdminEvents.BULK_ORDER_CANCELLED);
      this.$emit("back-to-home");
    },
    async cancelBulkOrder() {
      await this.initBulkOrder(this.shipMethods[0]);

      this.stepper.currentIndex = this.getTabIndexByCode("SELECT_RECIPIENTS");
    },
    getTabIndexByCode(code) {
      return this.stepper.tabs.find((t) => t.code === code).index;
    },
    navToProducts() {
      this.stepper.currentIndex = this.getTabIndexByCode("SELECT_PRODUCTS");
    },
    navToRecipients() {
      this.stepper.currentIndex = this.getTabIndexByCode("SELECT_RECIPIENTS");
    },
    navToShipMethod() {
      this.stepper.currentIndex = this.getTabIndexByCode("SELECT_SHIP_METHOD");
    },
    nextStep() {
      if (this.isNextDisabled) return;
      this.stepper.currentIndex++;
    },
    previousStep() {
      this.stepper.currentIndex--;
    },
    showConfirmation(options) {
      this.$emit("show-confirmation", options);
    },
  },
  mounted() {
    this.initListeners();
  }
};
</script>

<style lang="scss" scoped></style>
