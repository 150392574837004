<template>
    <div>
        <div v-if="showFirstUseDateMessage" class="n-error">
            <p class="text-right">This product will not be displayed on the Storefront until the First Use Date: {{$moment(firstUseDate).format("L")}} </p>
        </div>
        <div v-if="showQuaratineDateMessage" class="n-error">
            <p class="text-right">This product will not be displayed on the Storefront after the Quarantine Date: {{$moment(quarantineDate).format("L")}} </p>
        </div>
        <div v-if="showDaysToQuaratineDateMessage" class="n-error">
            <p class="text-right">This product will not be displayed on the Storefront after: {{$moment(quarantineDate).subtract(this.daysToQuarantineDate, "days").format("L")}} </p>
        </div>
        <div v-if="showCategoriesMessage" class="n-error">
            <p class="text-right">This product will not be displayed on the Storefront if Category is not assigned</p>            
        </div>
        <div v-if="showRolesMessage" class="n-error">
            <p class="text-right">This product will not be displayed on the Storefront if Role is not assigned</p>
        </div>
    </div>
</template>

<script>
    const currentDate = new Date();
    export default {
        props: {
            firstUseDate: {
                type: String,
                required: false,
            },
            quarantineDate: {
                type: String,
                required: false
            },
            daysToQuarantineDate: {
                type: Number,
                required: false
            },
            isActive: {
                type: Boolean,
                required: true
            },
            isCategoriesValid: {
                type: Boolean,
                required: true
            },
            isRolesValid: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            showFirstUseDateMessage() {
                if (this.firstUseDate) {
                    return this.isActive && this.$moment(this.firstUseDate, "YYYY-MM-DD") > this.$moment(currentDate, "YYYY-MM-DD");
                }
                return false;
            },
            showQuaratineDateMessage() {
                if (this.quarantineDate) {
                    return this.isActive && !(this.daysToQuarantineDate && this.daysToQuarantineDate > 0);
                }
                return false;
            },
            showDaysToQuaratineDateMessage() {
                if (this.quarantineDate && this.daysToQuarantineDate) {
                    return this.isActive && this.daysToQuarantineDate > 0;
                }
                return false;
            },
            showCategoriesMessage() {
                return this.isActive && !this.isCategoriesValid;
            },
            showRolesMessage() {
                return this.isActive && !this.isRolesValid;                
            },
        }
    }
</script>

<style>
</style>