<template>
    <div>
        <div class="row d-block d-sm-none">
            <div class="col-12">
                <PasswordValidation ref="PasswordValidation"
                                    :rules="model.rules"
                                    :isPasswordValid="model.isValid" />
            </div>
        </div>
        <ValidationProvider rules="required" vid="password" name="Password" v-slot="{ errors }">
            <div class="form-group">
                <label class="w-100" for="password">
                    {{ $t(passwordLabelText) }}
                    <span class="d-inline float-right font-weight-normal">
                        <a @click="isVisible = !isVisible">
                            <span v-if="isVisible">{{$t("shared.password.hidePassword")}}</span>
                            <span v-else>{{$t("shared.password.showPassword")}}</span>
                        </a>
                    </span>
                </label>

                <input name="password" id="password" v-model="password" class="form-control n-text-field-highlight"
                       :class="{ 'is-invalid': errors && errors.length }"
                       :type="isVisible ? 'text' : 'password'"
                       @input="$emit('update', $event.target.value)" />

                <PasswordValidation class="n-password-validation-container d-none d-sm-inline d-md-inline d-lg-inline d-xl-inline"
                                    ref="PasswordValidation"
                                    :rules="model.rules"
                                    :isPasswordValid="model.isValid" />

                <div class="invalid-feedback" v-if="errors && errors.length">
                    {{ errors[0] }}
                </div>

                <small class="form-text">
                    <span v-b-tooltip.html
                          :title="$t('shared.password.passwordSecurityToolTip')"
                          variant="outline-success"
                          class="primary-color text-left n-learn-more mb-1">
                        {{ $t("shared.password.passwordLearnMore") }}.
                    </span>
                </small>
            </div>
        </ValidationProvider>
        <ValidationProvider rules="required|confirmed:password" name="Confirm Password" v-slot="{ errors }">
            <div class="form-group">
                <label class="w-100" for="confirmPassword">
                    {{ $t(confirmPasswordLabelText) }}
                    <span class="d-inline float-right font-weight-normal">
                        <a @click="isVisible = !isVisible">
                            <span v-if="isVisible">{{$t("shared.password.hidePassword")}}</span>
                            <span v-else>{{$t("shared.password.showPassword")}}</span>
                        </a>
                    </span>
                </label>

                <input name="password_confirmation" data-vv-as="password" id="confirmPassword" v-model="confirmPassword" class="form-control n-text-field-highlight"
                       :class="{ 'is-invalid': errors && errors.length }"
                       :type="isVisible ? 'text' : 'password'"
                       @input="$emit('update', $event.target.value)" />

                <div class="invalid-feedback" v-if="errors && errors.length">
                    {{ errors[0] }}
                </div>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
    import { uiValidator } from "@/nucleus-modules/dd-nucleus-ui/mixins/uiValidator.js";
    import PasswordValidation from "@/nucleus-modules/dd-nucleus-ui/components/PasswordValidation";
    export default {
        name: "PasswordConfirmation",
        components: {
            PasswordValidation,
        },
        mixins: [uiValidator],
        props: {
            model: {
                type: Object,
                required: true
            },
            passwordLabelText: {
                type: String,
                required: false,
                default: "shared.userInfo.password"
            },
            confirmPasswordLabelText: {
                type: String,
                required: false,
                default: "shared.userInfo.confirmPassword"
            }
        },
        computed: {
            password: {
                get() { return this.model.password; },
                set(val) { this.model.password = val; }
            },
            confirmPassword: {
                get() { return this.model.confirmPassword; },
                set(val) { this.model.confirmPassword = val; }
            }
        },
        data() {
            return {
                isVisible: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    label {
        margin-top: .25rem;
    }

    .n-learn-more {
        color: $link-color;
    }
</style>