<template>
  <ul v-if="links && links.length">
      <li v-for="(link, index) in links" :key="index">
        <router-link :class="link" v-if="!link.openInNewTab && !isProduct" to="">
          {{link.name}}
        </router-link>
        <router-link :class="link" v-if="isProduct" :to="'product-detail/'+link.id">
          {{link.name}}
        </router-link>
        <a v-if="link.openInNewTab && !isProduct" :href="link.url" target="_blank" :class="link">
          {{link.name}}
        </a>
      </li>
  </ul>
</template>
<script>
export default {
    props:{
        links: {
            type: Array,
            required: true
        },
        isProduct: {
          type: Boolean,
          required: false
        },
        openInNewTab: {
          type: Boolean,
          required: false
        }
    }
}
</script>

<style scoped lang="scss">

ul {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
  }

  ul li {
    padding-bottom: 13px;
    line-height: 1.3;
  }

a, a:hover {
  color: $primary-dark-color;
}
</style>