<template>
<div>
    <div class="row n-signin-method ">
        <div class="col-2"></div>
        <div class="col-8 n-sso-logout">
            <div class="n-logo d-lg-block mt-5 mx-2 flex-fill text-center">
                <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
            </div>
            <div class="my-4 text-center">
                <p style="font-size:1.5rem">You currently do not have access to <span style="font-weight:600"> Regeneron Promotion Ordering Portal.</span></p>
                <p>Please send email to <a :href="emailUs" class="n-sign-in-link">{{ supportEmail }}</a> for further assistance.</p>
                <p><span style="font-weight:600">{{ $t("signout.hoursOperation") }}</span> {{ $t("signout.hoursTime") }}</p>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="n-mt-6">
    <Footer />
    </div>
</div>
</template>

<script>
    import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";
    import { supportEmail , emailUs} from "@/companyAppConfig"
    import Footer from "@/components/footer/Footer.vue";

    export default {
        name: "SSOLogout",
        components: {
            HeaderBranding,
            Footer
        },
        data() {
            return {
                supportEmail: supportEmail,
                emailUs: emailUs
            }
        },
    };
</script>

<style lang="scss" scoped>
    h1 {
        padding: 30px 0;
        font-size: 1.75rem;
    }

    .n-signin-method {
        width: 100%;
        min-height: 300px;
    }

    .n-sso-logout {
        color: $primary-dark-color;
    }
</style>
