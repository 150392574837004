<template>
<div class="float-right">
    <div v-if="inProcess">
        <div class="alert alert-success alert-dismissable fade show" role="alert">
            <span v-if="syncType===veevaSyncConst">The veeva promomats Inventory Sync has been initiated and will be completed shortly.</span>
            <span v-else>The BLS Inventory Sync has been initiated and will be completed shortly.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div v-if="!$nucleus.isEmpty(errorMessage)">
        <div class="alert alert-danger alert-dismissable fade show" role="alert">
            <strong>{{ errorMessage }}</strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div :class="[styleClass,'inline']">
        <span v-if="!$nucleus.isEmpty(dateTime)" class="m-2 mr-3">
            Last Sync Completed at <span>{{dateTime | moment("MM/DD/YYYY hh:mm:ss A")}}</span>
        </span>
        <button v-if="isVeevaSyncButtonVisible" class="btn btn-outline-primary btn-sm mr-1" @click="toggleConfirmModal(veevaSyncConst)">
            Sync With Promomats
        </button>
        <button class="btn btn-outline-primary btn-sm mr-0" @click="toggleConfirmModal(blsSyncConst)">
            Sync Inventory With BLS
        </button>
    </div>
    <b-modal v-model="blsModalShow" hide-header>
        <div class="d-block">
            <span v-if="syncType===veevaSyncConst">Are you sure you want to run inventory sync with Veeva Promomats?</span>
            <span v-else>Are you sure you want to run inventory sync with BLS?</span>
        </div>
        <template v-slot:modal-footer>
            <b-button class="d-inline" variant="outline-primary" :disabled="disableButton" @click="callSynInventory(syncType)">Yes</b-button>
            <b-button class="d-inline" variant="primary" @click="toggleConfirmModal(syncType)">No</b-button>
        </template>
    </b-modal>
</div>
</template>

<script>
import {
    SITE_SETUP_GETTER
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
    UPDATE_INVENTORY,
    GET_LAST_INV_SYNC_TIME,
    UPDATE_INVENTORY_VEEVA
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
    LAST_INV_SYNC_TIME_GETTER
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    RolesArrayConst,
    VeevaSyncConst,
    BlsSyncConst
} from "@/constants.js";
export default {
    name: "InventorySync",
    props: {
        styleClass: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            blsModalShow: false,
            veevaSyncShow: false,
            disableButton: false,
            inProcess: "",
            syncType: "",
            errorMessage: "",
            userRoles: RolesArrayConst,
            veevaSyncConst: VeevaSyncConst,
            blsSyncConst: BlsSyncConst
        }
    },
    created() {
        this.getLastInvRunTime();
    },

    methods: {
        ...mapActions({
            updateInventory: UPDATE_INVENTORY,
            getLastInventorySyncTime: GET_LAST_INV_SYNC_TIME,
            updateInventoryVeeva: UPDATE_INVENTORY_VEEVA
        }),
        toggleConfirmModal(type) {
            this.blsModalShow = !this.blsModalShow;
            this.inProcess = "";
            this.errorMessage = "";
            this.syncType = type;
        },
        async getLastInvRunTime() {
            await this.getLastInventorySyncTime();
        },
        async callSynInventory(type) {
            this.disableButton = true;
            this.dateTIme = "";
            this.blsModalShow = !this.blsModalShow;
            this.inProcess = true;
            this.syncType = type;
            if (type == this.veevaSyncConst)           
                await this.updateInventoryVeeva();              
            else
                await this.updateInventory();
                         
            this.disableButton = false;
        },

        getMatchingRoles() {
            return this.siteSetup.user.roles.filter(a => this.userRoles.includes(a.id.toUpperCase()));
        },

    },
    computed: {
        ...mapGetters({
            dateTime: LAST_INV_SYNC_TIME_GETTER,
            siteSetup: SITE_SETUP_GETTER,
        }),

        isVeevaSyncButtonVisible() {
            return this.getMatchingRoles().length > 0;
        },
    }

};
</script>

<style lang="scss" scoped>
.n-error {
    color: red;
}

.inline {
    display: flex;
}
</style>
