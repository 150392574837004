<template>
  <div>
    <b-form-select
      class="sort-list"
      v-model="selected"
      :options="sortOptions"
      @change="onSortChanged"
    ></b-form-select>
  </div>
</template>
<script>
export default {
  name: "SortByList",
  props: {
    sort: {
      type: String,
      required: true,
    },
    sortOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedSort: this.sort,
    };
  },
  computed: {
    selected: {
      get() {
        return this.selectedSort;
      },
      set(value) {
        this.selectedSort = value;
      },
    },
  },
  mounted() {
    this.selectedSort = this.sort;
  },
  methods: {
    onSortChanged() {
      this.$emit("changed", {
        value: this.selected,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.sort-list {
  color: $primary-dark-color;
}
</style>
