<template>
    <div>
        <b-form-select v-model="selected" :options="sortOptions" @change="onSortChanged"></b-form-select>
    </div>   
</template>
<script>
    import { mapGetters } from 'vuex'
    import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
    import { SORTING_CONFIG_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

    export default {
        props: {
            sort: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                selectedSort: ''
            }
        },
        computed: {
            ...mapGetters({
                sortingConfig: [SORTING_CONFIG_GETTER]
            }),
            sortOptions() {
                if(this.sortingConfig && this.sortingConfig.options && this.sortingConfig.options.length){
                    return this.sortingConfig.options.map(x => {
                        return {
                            value : x.id,
                            text :  x.name
                        }
                    });
                }
                return [];
            },
            selected: {
                get() {
                    return this.selectedSort
                },
                set(value) {
                    this.selectedSort = value;
                }
                
            }
        },
        mounted() {
            this.selectedSort = this.sort;
        },
        methods: {
            onSortChanged() {
                this.$eventBus.$emit(UiEvents.SORT_VALUE_CHANGED, {selectedSort : this.selected});
            }
        }
    }
</script>