import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";
import PagingService from "@/nucleus-modules/dd-nucleus-ui/services/paging.service.js";

import { GET_PRODUCT_FOR_EDIT, GET_COMPANY_SEGMENTS, GET_USER_FOR_EDIT,UPDATE_INVENTORY_VEEVA,GET_AUDIT_FIELDS } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
    COMPANY_SEGMENTS_GETTER,
    PRODUCT_FOR_EDIT_GETTER,
    SAVE_PRODUCT_ERROR_GETTER,
    SAVE_USER_ERROR_GETTER,
    USER_FOR_EDIT_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
    SET_COMPANY_SEGMENTS,
    SET_IS_PRODUCT_EDIT_DIRTY,
    SET_IS_USER_EDIT_DIRTY,
    SET_SAVE_PRODUCT_ERROR,
    SET_SAVE_USER_ERROR,
    SET_USERS
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

import { REGENERON_GET_USERS, REGENERON_SAVE_PRODUCT, REGENERON_SAVE_USER, } from "@/store/admin/actions.type";


const MODULE_ID = "regeneron-admin";

const initialState = {
    companySegments: {}
};

export const state = { ...initialState };

export const actions = {
    async [GET_COMPANY_SEGMENTS]({ commit }) {
        const response = await ApiService.get("/ui/admin/company-segments");

        if (response.succeeded) {
            let companySegments = {
                regions: response.regions,
                districts: response.districts,
                territories: response.territories
            }
            commit(SET_COMPANY_SEGMENTS, companySegments);
        }
    },
    async [REGENERON_GET_USERS]({ commit }, payload) {
        const query = PagingService.getPagingQueryString(payload);

        const response = await ApiService.get(`ui/admin/users?${query}`);

        if (response.succeeded) {
            commit(SET_USERS, response);
        }
    },
    async [REGENERON_SAVE_PRODUCT]({ commit, dispatch, getters }, payload) {
        commit(SET_SAVE_PRODUCT_ERROR, null);

        const productForEdit = getters[PRODUCT_FOR_EDIT_GETTER];

        const productResponse = await ApiService.put(`/ui/admin/regeneron-product/${payload.productId}`, {
            product: productForEdit,
            categories: productForEdit.categories.map(c => c.id),
            roles: productForEdit.roles.map(r => r.userRoleId),
            tags: productForEdit.tags,
            blsInfo: productForEdit.attributes.BlsProperties,
            inventory: productForEdit.attributes.Inventory,
            regeneronProduct: productForEdit.attributes.ExtendedProductProperties,
            productUserRoleTags: productForEdit.attributes.FeaturedProductForUserRoles.featureItemRoles
        });

        processResponse(productResponse);

        if (getters[SAVE_PRODUCT_ERROR_GETTER] && getters[SAVE_PRODUCT_ERROR_GETTER].length > 0) {
            // do not retrieve the product again, allow the UI to show the save product errors from vuex
        }
        else {
            await dispatch(GET_PRODUCT_FOR_EDIT, payload);
            await dispatch(GET_AUDIT_FIELDS, {eventId:payload.productId,entityType:'Product'});
           commit(SET_IS_PRODUCT_EDIT_DIRTY, false);
        }

        function processResponse(response) {
            if (!response.succeeded) {
                commit(SET_SAVE_PRODUCT_ERROR, response.messages);
            }
        }
    },
    async [REGENERON_SAVE_USER]({ commit, dispatch, getters }, payload) {
        commit(SET_SAVE_USER_ERROR, null);

        const userForEdit = getters[USER_FOR_EDIT_GETTER];

        let userResponse;
        let applyDefaultAddressResponse;
        let applyShippingAddressResponse;

        if (payload.userId) {
            userResponse = await ApiService.put(`api/v1/admin/user/${payload.userId}`, { contact: payload.contact, primaryUserRoleId: userForEdit.primaryRoleId });
        }
        else {
            userResponse = await ApiService.post(`api/v1/admin/users`, {
                contact: payload.contact,
                userName: userForEdit.userName,
                createInactive: true,
                primaryUserRoleId: userForEdit.primaryRoleId
            });

            payload.userId = userResponse.userAccountId;
        }

        if (userResponse.succeeded) {

            const applyRolesResponse = await ApiService.post(`ui/admin/user/${payload.userId}/roles`, userForEdit.userRoleIds);

            if (payload.defaultAddress.id) {
                applyDefaultAddressResponse = await ApiService.put(`api/v1/contact-address/${payload.defaultAddress.id}`, { address: payload.defaultAddress });
            } else {
                applyDefaultAddressResponse = await ApiService.post(`api/v1/user/${payload.userId}/addresses`, { address: payload.defaultAddress, setAsDefault: true });
            }

            if (payload.shippingAddress.id) {
                applyShippingAddressResponse = await ApiService.put(`api/v1/contact-address/${payload.shippingAddress.id}`, { address: payload.shippingAddress });
            } else {
                applyShippingAddressResponse = await ApiService.post(`api/v1/user/${payload.userId}/addresses`, { address: payload.shippingAddress });
            }

            const applyAdditionalInfoResponse = await ApiService.put(`ui/admin/user/${payload.userId}/additional-info`, { additionalInfo: payload.additionalInfo });

            const applyLoginMethodResponse = await ApiService.put(`ui/admin/user/${payload.userId}/login-method`, { isSsoLogin: userForEdit.attributes.IsSso, password: userForEdit.password });

            const applyDefaultPhone = await ApiService.post(`ui/admin/user/${payload.userId}/phone`, { phoneNumber: userForEdit.defaultPhoneNumber });

            processResponse(applyRolesResponse);
            processResponse(applyDefaultAddressResponse);
            processResponse(applyShippingAddressResponse);
            processResponse(applyAdditionalInfoResponse);
            processResponse(applyLoginMethodResponse);
            processResponse(applyDefaultPhone);
        }
        else {
            processResponse(userResponse);
        }


        if (getters[SAVE_USER_ERROR_GETTER] && getters[SAVE_USER_ERROR_GETTER].length > 0) {
            // do not retrieve the user again, allow the UI to show the save user errors
        }
        else {
            await dispatch(GET_USER_FOR_EDIT, payload.userId);
            commit(SET_SAVE_USER_ERROR, null);
            commit(SET_IS_USER_EDIT_DIRTY, false);
        }

        function processResponse(response) {
            if (!response.succeeded) {
                commit(SET_SAVE_USER_ERROR, response.messages);
            }
        }
    },

    async [UPDATE_INVENTORY_VEEVA]() {            
        await ApiService.post("/ui/admin/promomats/process");            
    },

};


export const getters = {
    [COMPANY_SEGMENTS_GETTER]: state => {
        return state.companySegments;
    }
};

export const mutations = {
    [SET_COMPANY_SEGMENTS]: (state, companySegments) => {
        state.companySegments = companySegments;
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
