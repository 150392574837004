import router from "@/router/index.js";
import { LOGIN_PATH } from "@/router/index.js";

import {
    HIDE_OVERLAY,
    REDIRECT_TO_LOGIN,
    SHOW_OVERLAY,
    START_LOADER,
    STOP_LOADER,
    START_LOCALE_CHANGE,
    STOP_LOCALE_CHANGE
} from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { IS_LOADING_GETTER, IS_LOCALE_CHANGING_GETTER, OVERLAY_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import {
    // ADD_NETWORK_ERROR,
    INCREMENT_LOADERS,
    DECREMENT_LOADERS,
    SET_IS_LOCALE_CHANGING,
    SET_OVERLAY,
    // REMOVE_NETWORK_ERROR
} from "@/nucleus-modules/dd-nucleus-ui/store/mutations.type.js";

const initialState = {
    isLocaleChanging: false,
    loaders: 0,
    overlay: {
        allowHeader: false,
        visible: false
    },
    // networkErrors: [],
};

export const state = { ...initialState };

export const actions = {
    [HIDE_OVERLAY]: ({ commit }) => {
        commit(SET_OVERLAY, { show: false, offset: 0 });
    },
    [REDIRECT_TO_LOGIN]: () => {
        router.push(LOGIN_PATH);
    },
    [START_LOCALE_CHANGE]: ({ commit }) => {
        commit(INCREMENT_LOADERS);
        commit(SET_IS_LOCALE_CHANGING, true);
    },
    [SHOW_OVERLAY]: ({ commit }, payload) => {
        payload.visible = true;
        commit(SET_OVERLAY, payload);
    },
    [START_LOADER]: ({ commit }) => {
        commit(INCREMENT_LOADERS);
    },
    [STOP_LOADER]: ({ commit }) => {
        commit(DECREMENT_LOADERS);
    },
    [STOP_LOCALE_CHANGE]: ({ commit }) => {
        commit(DECREMENT_LOADERS);
        commit(SET_IS_LOCALE_CHANGING, false);
    }
};

export const getters = {
    // [SYSTEM_ERRORS_GETTER]: state => {
    //     return state.networkErrors;
    // },
    [IS_LOADING_GETTER]: state => {
        return state.loaders > 0;
    },
    [IS_LOCALE_CHANGING_GETTER]: state => {
        return state.isLocaleChanging;
    },
    [OVERLAY_GETTER]: state => {
        return state.overlay;
    }
};

export const mutations = {
    // [ADD_NETWORK_ERROR](state, error) {
    //     state.networkErrors.push(error);
    // },
    [DECREMENT_LOADERS](state) {
        if (state.loaders > 0) {
            state.loaders -= 1;
        }
    },
    [INCREMENT_LOADERS](state) {
        state.loaders += 1;
    },
    [SET_IS_LOCALE_CHANGING]: (state, value) => {
        state.isLocaleChanging = value;
    },
    [SET_OVERLAY]: (state, payload) => {
        state.overlay.allowHeader = payload.allowHeader;
        state.overlay.visible = payload.visible;
    }
    // [REMOVE_NETWORK_ERROR](state, error) {
    //     state.networkErrors = state.networkErrors.filter((ne) => ne !== error);
    // }
};

export default {
    state,
    actions,
    mutations,
    getters
};
