<template>
  <div>
    <div class="row input-group filter">
      <div class="n-plus-minus">
        <div class="n-filter-header">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="daterange-filter my-4">
      <div class="from-date">
        <label>{{ $t("filters.dateAddedFilterFromText") }}</label>
        <b-form-datepicker
          v-model="fromDate"
          :date-format-options="dateFormat"
          class="mb-2"
          size="sm"
          :placeholder="$t('filters.dateAddedFilterAnyPlaceholderText')"
          @input="onFromDateChanged"
        ></b-form-datepicker>
      </div>
      <div class="to-date">
        <label>{{ $t("filters.dateAddedFilterToText") }}</label>
        <b-form-datepicker
          v-model="toDate"
          :date-format-options="dateFormat"
          class="mb-2"
          size="sm"
          :placeholder="$t('filters.dateAddedFilterAnyPlaceholderText')"
          @input="onToDateChanged"
        ></b-form-datepicker>
      </div>
    </div>
    <div class="d-none d-lg-block">
      <span class="clear-all" @click="clearAll"
        >{{ $t("filters.clearAllButtonText") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateRangeFilter",
  props: {
    dateFormat: {
      type: Object,
      default: function() {
        return {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        };
      },
    },
    filterId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    filterValues: {
      type: Array,
      required: true,
    },
  },
  computed: {
    fromDate: {
      get() {
        let vm = this;
        if (
          !vm.$nucleus.isEmpty(this.filterValues) &&
          this.filterValues.length > 0
        ) {
          return this.filterValues[0];
        } else {
          return null;
        }
      },
      set(val) {
        this.filterValues[0] = val;
      },
    },
    toDate: {
      get() {
        let vm = this;
        if (
          !vm.$nucleus.isEmpty(this.filterValues) &&
          this.filterValues.length > 1
        ) {
          return this.filterValues[1];
        } else {
          return null;
        }
      },
      set(val) {
        this.filterValues[1] = val;
      },
    },
  },
  methods: {
    clearAll() {
      if (this.fromDate || this.toDate) {
        this.fromDate = null;
        this.toDate = null;
        this.updateSearch();
      }
    },
    onFromDateChanged(dateChangedEventArgs) {
      if (dateChangedEventArgs) {
        this.fromDate = dateChangedEventArgs;
        this.updateSearch();
      }
    },
    onToDateChanged(dateChangedEventArgs) {
      if (dateChangedEventArgs) {
        this.toDate = dateChangedEventArgs;
        this.updateSearch();
      }
    },
    updateSearch() {
      this.$emit("changed", {
        filterId: this.filterId,
        values: [this.filterValues[0] || "", this.filterValues[1] || ""],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.from-date, .to-date {
  font-size: 1rem;
}
</style>
