<template>
  <div class="sticky-top" v-if="userInfo">
    <ScreenSize v-if="showScreenSize">Screen Size</ScreenSize>
    <HeaderPromotion v-if="userInfo" />
    <header class="header">
      <!-- Desktop Nav -->
      <div class="container d-none d-lg-block">
        <div class="d-flex flex-row">
          <div class="d-none d-lg-block n-spacing mr-2">
            <MainMenu menu-id="main" />
          </div>
          <div class="d-none n-logo d-lg-block mt-2 mx-2 flex-fill">
            <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
          </div>
          <div class="d-none d-lg-block mx-2 flex-fill">
            <OmniSearch />
          </div>
          <div class="d-none d-lg-block mt-2 mx-2 flex-fill">
            <HeaderMenu menu-id="help">
              <template v-slot:title>
                <br />
                {{ $t(`header.nav.help`) }}
              </template>
            </HeaderMenu>
          </div>
          <div v-if="userInfo" class="d-none d-lg-block mt-2 mx-2 flex-fill">
            <HeaderMenu menu-id="account">
              <template v-slot:title>
                <template v-if="userInfo.id">
                  Hi, {{ userInfo.firstName }}
                  <br />
                  My Account
                </template>
                <template v-else>
                  <a href="#">Sign In</a>
                  <br />
                </template>
              </template>
              <template v-slot:footer>
                <div class="pl-3">
                  <AdminBadge />
                </div>
              </template>
            </HeaderMenu>
          </div>
          <div v-else class="d-none d-lg-block mt-4 mr-1">
            <HeaderLoginItem font-icon="user" />
          </div>
          <div id="cart" class="d-none d-lg-block mt-4 ml-2 flex-fill">
            <HeaderCart :totalCartItems="totalCartItems"></HeaderCart>
          </div>
        </div>
      </div>
      <!-- Mobile Nav -->
      <div class="container d-lg-none">
        <div class="row">
          <div class="col-3">
            <MainMenu menu-id="mobile-main" />
          </div>
          <div class="col-6 n-logo">
            <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
          </div>
          <div class="col-3" id="cart">
            <HeaderCart :totalCartItems="totalCartItems"></HeaderCart>
          </div>
        </div>
      </div>
       <div class="container d-lg-none">
        <div class="row my-3">         
          <div class="col-6">
             <AdminBadge />
          </div>
         <div class="col-6 text-right">
            Hi, {{ userInfo.firstName }}             
          </div>
        </div>
        <div class="row mx-2 my-3">
          <div class="col-12">
            <OmniSearch />
          </div>
        </div>
      </div>  
    </header>
    <div class="container d-md-block">
      <ImpersonationAlert />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

import {
  TOTAL_CART_ITEMS_GETTER,
  MENU_BY_ID_GETTER,
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";
import HeaderCart from "@/components/cart/HeaderCart.vue";
import HeaderLoginItem from "@/nucleus-modules/dd-nucleus-ui/components/HeaderLoginItem.vue";
import ImpersonationAlert from "@/nucleus-modules/dd-nucleus-ui/components/ImpersonationAlert";
import MainMenu from "@/components/MainMenu.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import HeaderPromotion from "@/nucleus-modules/dd-nucleus-storefront/components/HeaderPromotion.vue";

import OmniSearch from "@/nucleus-modules/dd-nucleus-storefront/components/OmniSearch.vue";
import ScreenSize from "@/nucleus-modules/dd-nucleus-ui/components/ScreenSize.vue";
import AdminBadge from "@/nucleus-modules/dd-nucleus-storefront/components/Badge.vue";

//import LocaleSwitcher from "@/nucleus-modules/dd-nucleus-ui/components/LocaleSwitcher.vue";
//import ModalLogin from "@/nucleus-modules/dd-nucleus-ui/components/ModalImage.vue";
//import ModalService from '@/nucleus-modules/dd-nucleus-ui/services/modal.service';

export default {
  name: "Header",
  components: {
    HeaderBranding,
    HeaderCart,
    HeaderLoginItem,
    ImpersonationAlert,
    MainMenu,
    HeaderMenu,
    HeaderPromotion,
    OmniSearch,
    ScreenSize,
    AdminBadge,
    //LocaleSwitcher,
  },
  computed: {
    ...mapGetters({
      totalCartItems: TOTAL_CART_ITEMS_GETTER,
      menuById: MENU_BY_ID_GETTER,
      userInfo: USER_INFO_GETTER,
    }),
    isOpen() {
      return false;
    },
    showScreenSize() {
      return this.$route.query.ss;
    },
    isSSOAuthenticatedUser() {
      //TODO: Need to find out whether SSO user or not.
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0px;
  background-color: #fff;
  z-index: 99;
  width: 100%;
}
.header .container{
  border-bottom: 1px solid $gray-2;
}
.n-spacing {
  margin-top: 36px;
}
a.dropdown-item:hover {
  background-color: #fff;
}

.dropdown-menu.megamenu.show {
  background-color: $primary-lightest-color;
}

.navbar-light .navbar-nav .nav-link {
  color: $primary-color;
}

.navbar {
  padding: 0 !important;
}

.nav-link {
  margin-left: 30px;
}

.n-subcategory {
  font-size: 16px;
  font-weight: 600;
  color: $primary-color;
  margin-bottom: 0;
}

.navbar-light .navbar-nav .navlink.active {
  font-weight: 600;
}

.megamenu {
  position: static;
}

.megamenu .dropdown-menu {
  border: none;
  width: 100%;
}

.dropdown-menu,
.navbar-toggler {
  z-index: 9999999999;
}

.no-gutters {
  width: 100%;
}

.n-logo img {
  max-width: 225px;
  max-height: auto;
}

@media only screen and (max-width: 767px) {
  .n-logo img {
    max-width: 200px;
    max-height: auto;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1515px !important;
  }
}
</style>
