<template>
  <AdminTemplate>
    <div v-if="isLoading" class="row">
      <div class="col">
        <p>Loading...</p>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-6">
          <h4>{{ pageTitle }}</h4>
        </div>
        <div v-if="hasOrders" class="col-6 text-right">
          Showing {{ paging.pageStartNumber }}-{{ paging.pageEndNumber }} of
          {{ paging.totalCount }}
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <div class="d-inline mr-3">
            <b-form-select
              class="col-1"
              v-model="paging.pageSize"
              :options="perPageOptions"
              @change="updatePage()"
            ></b-form-select>
            per page
          </div>
          <div class="d-inline">
            <b-pagination
              class="float-right"
              v-model="paging.pageNumber"
              :total-rows="paging.totalCount"
              :per-page="paging.pageSize"
              @change="updatePage"
              aria-controls="ordersTable"
            />
          </div>
        </div>
      </div>
      <div class="row n-filter-search">
        <div class="col">
          <div class="row">
            <div class="col-8">
              <div class="n-searchbox">
                <div class="input-group search">
                  <input
                    type="text"
                    v-model="searchTerm"
                    class="form-control n-search-input"
                    placeholder="Search Order Number, User ID, Email Address, Name"
                    aria-label="input-group mb-3"
                    v-on:keyup.enter="filterOrders"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-search"
                      type="button"
                      @click="filterOrders"
                    >
                      <font-awesome-icon
                        icon="search"
                        fixed-width
                      ></font-awesome-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row pb-2 pt-4 n-filter-search">
            <div>
              <div class="n-button-filters d-inline mr-4">
                <div class="mb-2"><strong>Shipping Status</strong></div>
                <ul class="quickFilters">
                  <li
                    v-for="filter in shippingStatusQuickFilters"
                    :key="filter.id"
                  >
                    <button
                      class="btn btn-white"
                      :class="{
                        active:
                          shippingStatusQuickFiltersSelected.includes(filter),
                      }"
                      @click="toggleShippingStatusQuickFilter(filter)"
                    >
                      {{ filter }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div class="n-button-filters d-inline mr-4">
                <div class="mb-2"><strong>Payment Status</strong></div>
                <ul class="quickFilters" id="paymentStatusFilters">
                  <li
                    v-for="filter in paymentStatusQuickFilters"
                    :key="filter.id"
                  >
                    <button
                      class="btn btn-white"
                      :class="{
                        active:
                          paymentStatusQuickFiltersSelected.includes(filter),
                      }"
                      @click="togglePaymentStatusQuickFilter(filter)"
                    >
                      {{ filter }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-merging mr-4">
              <div class="d-inline">
                <div class="mb-2"><strong>Order Date</strong></div>
                <b-dropdown
                  id="dropdown-form"
                  :text="selectedOrderDateValue"
                  dropdown
                  ref="dateDropdown"
                  @hide="handleHide($event)"
                  variant="white n-custom-dropdown"
                >
                  <b-dropdown-form>
                    <b-form-radio-group
                      id="userRoleFilter"
                      v-model="selectedOrderDateCriteria"
                      :options="orderDateSelections"
                      value-field="id"
                      text-field="text"
                      name="roleSearch"
                      :stacked="true"
                      @change="orderDateSelect"
                    />
                    <li v-show="isOrderDateRangeSelected">
                      <hr />
                      <div class="p-2">
                        <h5 class="mt-3">
                          <strong>Select Date Range</strong>
                        </h5>
                        <div class="form-group">
                          <label>From</label>
                          <b-form-datepicker
                            id="dateRangeStart"
                            class="mb-2"
                            v-model="dateRangeStart"
                            today-button
                            reset-button
                            locale="en"
                            :date-format-options="dateFormatOptions"
                          ></b-form-datepicker>
                        </div>
                        <div class="form-group">
                          <label>To</label>
                          <b-form-datepicker
                            id="dateRangeEnd"
                            class="mb-2"
                            v-model="dateRangeEnd"
                            today-button
                            reset-button
                            locale="en"
                            :date-format-options="dateFormatOptions"
                          ></b-form-datepicker>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary btn-block"
                          @click="setIsClosable(), filterOrders()"
                        >
                          Filter
                        </button>
                      </div>
                    </li>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </div>
            <div class="d-merging">
              <div class="d-inline">
                <div class="mb-2"><strong>Primary Role</strong></div>
                <b-dropdown
                  id="dropdown-form"
                  :text="selectedRolesText"
                  dropdown
                  ref="roleDropdown"
                  variant="white n-custom-dropdown"
                >
                  <b-dropdown-form>
                    <ul class="n-select-deselect">
                      <li class="pl-4" @click="selectAllRoles()">Select All</li>
                      <li class="pl-4" @click="deselectAllRoles()">
                        Deselect All
                      </li>
                      <li>
                        <hr />
                      </li>
                    </ul>
                    <b-form-checkbox-group
                      id="roleFilter"
                      v-model="selectedRoles"
                      :options="availablePrimaryRoles"
                      value-field="value"
                      text-field="text"
                      name="roleSearch"
                      :stacked="true"
                      @change="filterOrders()"
                    />
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="hasOrders" class="col mt-3 mb-3">
          <a @click="getAllOrders">View All Orders</a>
        </div>
        <div v-else-if="!hasOrders && !isLoading" class="col mt-5 text-center">
          <h2 class="n-no-results">
            No orders found. Try modifying your search.
          </h2>
          <a @click="getAllOrders">View All Orders</a>
        </div>
      </div>
      <div v-if="hasOrders" class="row">
        <div class="col">
          <VueGoodTable
            mode="remote"
            class="table table-striped"
            :columns="columns"
            :rows="orders"
            :totalRows="paging.totalCount"
            @on-sort-change="onSortChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'orderNumber'">
                <router-link
                  :to="{ name: 'ViewOrder', params: { id: props.row.id } }"
                  >{{ props.row.orderNumber }}</router-link
                >
              </span>
              <span v-else-if="props.column.field === 'orderStatus'">
                <span
                  v-html="buildOrderStatusBadge(props.row.orderStatus)"
                ></span>
              </span>
              <span v-else-if="props.column.field === 'shippingStatus'">
                <span
                  v-html="buildShippingStatusBadge(props.row.shippingStatus)"
                ></span>
              </span>
              <span v-else-if="props.column.field === 'paymentStatus'">
                <span
                  v-html="buildPaymentStatusBadge(props.row.paymentStatus)"
                ></span>
              </span>
              <span v-else-if="props.column.field === 'userName'">
                <router-link
                  :to="{ name: 'ViewUser', params: { id: props.row.userId } }"
                  >{{ props.row.userName }}</router-link
                >
              </span>
              <span v-else-if="props.column.type === 'date'">
                {{
                  props.formattedRow[props.column.field] | moment(dateFormat)
                }}
                <br />
                {{
                  props.formattedRow[props.column.field] | moment(timeFormat)
                }}
              </span>
            </template>
          </VueGoodTable>
        </div>
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import { OrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrdersMixin.js";
import { PagingMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/PagingMixin.js";

import AdminTemplate from "@/pages/templates/AdminTemplate.vue";

export default {
  name: "AllOrders",
  mixins: [OrdersMixin, PagingMixin],
  components: {
    VueGoodTable,
    AdminTemplate,
  },
  props: {
    filterByStatusProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateFormat: "MM/DD/YYYY",
      dateRangeStart: "",
      dateRangeEnd: "",
      filterByStatus: "",
      isCloseable: true,
      isLoading: true,
      isOrderDateSelectorVisible: false,
      isRolesSelectorVisible: false,
      pageTitle: "All Orders",
      paymentStatusQuickFiltersSelected: ["all"],
      searchTerm: "",
      selectedOrderDateValue: "Order Date",
      selectedOrderDateCriteria: [],
      selectedRoles: [],
      shippingStatusQuickFiltersSelected: ["all"],
      sortBy: "",
      sortDesc: null,
      timeFormat: "hh:mm:ss a",
      columns: [
        { label: "Order #", field: "orderNumber", type: "string" },
        { label: "Order Status", field: "orderStatus", type: "string" },
        { label: "Shipping", field: "shippingStatus", type: "string" },
        { label: "Payment", field: "paymentStatus", type: "string" },
        { label: "User ID", field: "userName", type: "string" },
        { label: "Primary Role", field: "primaryRole", type: "string" },
        { label: "First Name", field: "user.firstName", type: "string" },
        { label: "Last Name", field: "user.lastName", type: "string" },
        { label: "Email Address", field: "userName", type: "string" },
        {
          label: "Order Date (UTC)",
          field: "orderDate",
          type: "date",
          formatFn: function (value) {
            return value == null ? "" : value;
          },
        },
      ],
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      orderDateSelections: [
        { id: "week", text: "In the last week" },
        { id: "month", text: "In the last month" },
        { id: "three-months", text: "In the last 3 months" },
        { id: "year", text: "In the last year" },
        { id: "range", text: "Date Range" },
      ],
      paging: {
        includeTotal: true,
        pageCount: 0,
        pageNumber: 1,
        pageSize: 200,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
      },
      paymentStatusQuickFilters: ["all", "paid", "not paid"],
      perPageOptions: [
        { value: 200, text: "200" },
        { value: 400, text: "400" },
        { value: 600, text: "600" },
        { value: 800, text: "800" },
        { value: 1000, text: "1000" },
      ],
      shippingStatusQuickFilters: [
        "all",
        "shipped",
        "partially shipped",
        "not shipped",
      ],
    };
  },
  computed: {
    availablePrimaryRoles() {
      let result = [];

      result = result.concat(
        this.roles.map((r) => {
          return { value: r.id, text: r.name };
        })
      );

      result = result.sort((a, b) => (a.text > b.text ? 1 : -1));

      return result;
    },
    isOrderDateRangeSelected() {
      return this.selectedOrderDateCriteria === "range";
    },
    selectedRolesText() {
      let result = "All";

      if (this.selectedRoles && this.selectedRoles.length > 0) {
        if (this.selectedRoles.length === this.availablePrimaryRoles.length) {
          result = "All Primary Roles Selected";
        } else {
          result = `${this.selectedRoles.length} Roles Selected`;
        }
      }

      return result;
    },
  },
  methods: {
    closeDropdowns() {
      this.isOrderDateSelectorVisible = false;
      this.isRolesSelectorVisible = false;
    },
    deselectAllRoles() {
      this.selectedRoles = [];
      this.filterOrders();
    },
    async filterOrders() {
      const filters = [];

      if (this.searchTerm && this.searchTerm.length > 2) {
        filters.push({ id: "searchTerm", value: this.searchTerm });
      }

      if (this.selectedRoles && this.selectedRoles.length > 0) {
        filters.push({ id: "primaryRole", value: this.selectedRoles });
      }
      if (
        this.shippingStatusQuickFiltersSelected &&
        this.shippingStatusQuickFiltersSelected.length > 0
      ) {
        filters.push({
          id: "shippingStatus",
          value: this.shippingStatusQuickFiltersSelected[0],
        });
      }

      if (
        this.paymentStatusQuickFiltersSelected &&
        this.paymentStatusQuickFiltersSelected.length > 0
      ) {
        filters.push({
          id: "paymentStatus",
          value: this.paymentStatusQuickFiltersSelected[0],
        });
      }

      if (this.dateRangeStart !== "" && this.dateRangeEnd !== "") {
        filters.push({
          id: "dateRange",
          values: [this.dateRangeStart, this.dateRangeEnd],
        });
      } else if (this.dateRangeStart !== "") {
        filters.push({
          id: "dateRange",
          values: [this.dateRangeStart, null],
        });
      } else if (this.dateRangeEnd !== "") {
        filters.push({ id: "dateRange", values: [null, this.dateRangeEnd] });
      }

      if (this.filterByStatus && this.filterByStatus.length > 0) {
        filters.push({ id: "orderStatus", value: this.filterByStatus });
      }

      await this.getOrders(
        filters,
        this.mapSortBy(this.sortBy),
        this.paging.pageNumber,
        this.paging.pageSize
      );
      this.setPaging(this.ordersResponse);

      this.isOrderDateSelectorVisible = false;
      this.isRolesSelectorVisible = false;
    },
    async getAllOrders() {
      this.$eventBus.$emit("clear-orders-search-filters");
      this.paging.pageNumber = 1;
      this.searchTerm = "";
      this.sortBy = "";
      this.shippingStatusQuickFiltersSelected = ["all"];
      this.paymentStatusQuickFiltersSelected = ["all"];
      this.selectedOrderDateValue = "Order Date";
      this.selectedOrderDateCriteria = [];
      this.dateRangeStart = "";
      this.dateRangeEnd = "";
      this.filterByStatus = "";

      await this.updatePage();
    },
    handleHide(bvEvent) {
      if (!this.isCloseable) {
        bvEvent.preventDefault();
      } else {
        this.$refs.dateDropdown.hide();
      }
    },
    mapSortBy(sortBy) {
      let sort = sortBy.toString();

      switch (sort) {
        case "orderNumber":
          sort = "order_number";
          break;
        case "orderStatus":
          sort = "order_status";
          break;
        case "shippingStatus":
          sort = "shipping_status";
          break;
        case "paymentStatus":
          sort = "payment_status";
          break;
        case "userName":
          sort = "user_name";
          break;
        case "primaryRole":
          sort = "primary_role";
          break;
        case "user.firstName":
          sort = "first_name";
          break;
        case "user.lastName":
          sort = "last_name";
          break;
        case "user.email":
          sort = "email";
          break;
        case "orderDate":
          sort = "order_date";
          break;
      }

      if (this.sortDesc === true) {
        sort = `${sort}_desc`;
      }

      return sort;
    },
    async onSortChange(params) {
      this.sortBy = this.mapSortBy(params[0].field);
      this.sortDesc = params[0].type === "desc" ? true : false;

      await this.filterOrders();
    },
    async orderDateSelect(item) {
      this.selectedOrderDateCriteria = item;

      const defaultEndDate = this.$moment().format("MM/DD/YYYY");

      switch (item) {
        case "week":
          this.dateRangeStart = this.$moment()
            .subtract(1, "weeks")
            .format("MM/DD/YYYY");
          this.dateRangeEnd = defaultEndDate;
          this.selectedOrderDateValue = "Last Week";
          break;
        case "month":
          this.dateRangeStart = this.$moment()
            .subtract(1, "months")
            .format("MM/DD/YYYY");
          this.dateRangeEnd = defaultEndDate;
          this.selectedOrderDateValue = "Last Month";
          break;
        case "three-months":
          this.dateRangeStart = this.$moment()
            .subtract(3, "months")
            .format("MM/DD/YYYY");
          this.dateRangeEnd = defaultEndDate;
          this.selectedOrderDateValue = "Last Three Months";
          break;
        case "year":
          this.dateRangeStart = this.$moment()
            .subtract(1, "years")
            .format("MM/DD/YYYY");
          this.dateRangeEnd = defaultEndDate;
          this.selectedOrderDateValue = "Last Year";
          break;
        case "range":
          this.selectedOrderDateValue = "Date Range";
          this.isCloseable = false;
          break;
      }

      if (item !== "range") {
        await this.filterOrders();
      }
    },
    selectAllRoles() {
      this.selectedRoles = this.availablePrimaryRoles.map((r) => r.value);
      this.filterOrders();
    },
    selectRole(role) {
      if (this.selectedRoles.includes(role)) {
        this.selectedRoles = this.selectedRoles.filter((sr) => sr !== role);
      } else {
        this.selectedRoles.push(role);
      }
    },
    setIsClosable() {
      this.isCloseable = true;
      this.$refs.dateDropdown.visible = false;
    },
    toggleOrderDateSelector() {
      this.isOrderDateSelectorVisible = !this.isOrderDateSelectorVisible;
    },
    toggleRolesSelector() {
      this.isRolesSelectorVisible = !this.isRolesSelectorVisible;
    },
    async togglePaymentStatusQuickFilter(filter) {
      if (filter == "all") {
        this.paymentStatusQuickFiltersSelected = [];
        this.paymentStatusQuickFiltersSelected.push(filter);
      } else {
        if (this.paymentStatusQuickFiltersSelected.includes("all")) {
          this.paymentStatusQuickFiltersSelected.splice(
            this.paymentStatusQuickFiltersSelected.indexOf("all"),
            1
          );
        }

        if (filter == "paid") {
          this.paymentStatusQuickFiltersSelected.splice(
            this.paymentStatusQuickFiltersSelected.indexOf("not paid"),
            1
          );
        }

        if (filter == "not paid") {
          this.paymentStatusQuickFiltersSelected.splice(
            this.paymentStatusQuickFiltersSelected.indexOf("paid"),
            1
          );
        }

        this.paymentStatusQuickFiltersSelected.includes(filter)
          ? this.paymentStatusQuickFiltersSelected.splice(
              this.paymentStatusQuickFiltersSelected.indexOf(filter),
              1
            )
          : this.paymentStatusQuickFiltersSelected.push(filter);
      }

      if (this.$nucleus.isEmpty(this.paymentStatusQuickFiltersSelected)) {
        this.paymentStatusQuickFiltersSelected.push("all");
      }

      this.paging.pageNumber = 1;
      await this.filterOrders();
    },
    async toggleShippingStatusQuickFilter(filter) {
      if (filter === "all") {
        this.shippingStatusQuickFiltersSelected = [];
        this.shippingStatusQuickFiltersSelected.push(filter);
      } else {
        if (this.shippingStatusQuickFiltersSelected.includes("all")) {
          this.shippingStatusQuickFiltersSelected.splice(
            this.shippingStatusQuickFiltersSelected.indexOf("all"),
            1
          );
        }

        if (filter == "shipped") {
          this.shippingStatusQuickFiltersSelected.splice(
            this.shippingStatusQuickFiltersSelected.indexOf("not shipped"),
            1
          );
        }

        if (filter == "partially shipped") {
          this.shippingStatusQuickFiltersSelected.splice(
            this.shippingStatusQuickFiltersSelected.indexOf(
              "partially shipped"
            ),
            1
          );
        }

        if (filter == "not shipped") {
          this.shippingStatusQuickFiltersSelected.splice(
            this.shippingStatusQuickFiltersSelected.indexOf("shipped"),
            1
          );
        }

        this.shippingStatusQuickFiltersSelected.includes(filter)
          ? this.shippingStatusQuickFiltersSelected.splice(
              this.shippingStatusQuickFiltersSelected.indexOf(filter),
              1
            )
          : this.shippingStatusQuickFiltersSelected.push(filter);
      }

      if (this.$nucleus.isEmpty(this.shippingStatusQuickFiltersSelected)) {
        this.shippingStatusQuickFiltersSelected.push("all");
      }

      this.paging.pageNumber = 1;
      this.filterOrders();
    },
    async updatePage(page) {
      if (page !== undefined) {
        this.paging.pageNumber = page;
      }

      this.filterOrders();
    },
    updatePageTitle() {
      switch (this.filterByStatus) {
        case "completed":
          this.pageTitle = "Completed Orders";
          break;
        case "pending-approval":
          this.pageTitle = "Orders Pending Approval";
          break;
        case "pending":
          this.pageTitle = "Pending Orders";
          break;
        case "cancelled":
          this.pageTitle = "Cancelled Orders";
          break;
        default:
          this.pageTitle = "All Orders";
          break;
      }
    },
  },
  async created() {
    this.$watch(
      () => this.$route.params,
      async () => {
        if (this.$route.path === "/admin/all-orders") {
          await this.getAllOrders();
          this.updatePageTitle();
        } else if (
          this.filterByStatusProp &&
          this.filterByStatusProp.length > 0
        ) {
          this.filterByStatus = this.filterByStatusProp;
          await this.filterOrders();
        }

        this.updatePageTitle();
      }
    );
  },
  async beforeDestroy() {
    document.removeEventListener("click", this.closeDropdowns);
  },
  async mounted() {
    document.addEventListener("click", this.closeDropdowns);

    if (this.filterByStatusProp && this.filterByStatusProp.length > 0) {
      this.filterByStatus = this.filterByStatusProp;
    }

    await this.getRoles();
    await this.filterOrders();
    this.updatePageTitle();

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
::deep {
  .dropdown-menu {
    min-width: 12rem !important;
    max-height: 25rem;
    overflow-x: overlay !important;
    white-space: nowrap !important;
    background: $admin-white-color !important;
  }
  /* width */
  .dropdown-menu::-webkit-scrollbar {
    height: 0.75rem;
    width: 13px;
  }

  /* Track */
  .dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(233, 231, 231);
    border-radius: 10px;
  }

  /* Handle */
  .dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d4d3d3;
  }
  .custom-control-label {
    font-size: 0.8rem;
    line-height: 20px;
    margin-top: 16px;
  }
}

.n-filter-search {
  background: $admin-primary-lightest-color;
  padding-top: 20px;
  padding-bottom: 20px;
}

.n-search-input {
  font-size: 1rem;
}

.btn-search {
  border: 1px solid #d9d9d9;
  background: #fff;
  font-size: 0.7rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.btn-search:active {
  border: 1px solid $primary-color;
  background: #fff;
}

.n-button-filters .btn {
  border-radius: 0;
  text-transform: capitalize;
}

.custom-dropdown {
  .custom-dropdown-selector {
    border: 1px solid #bbb;
    background: #f8f8f8;
    position: relative;
    margin-left: 11px;
    z-index: 1;
  }
  .arrow {
    position: absolute;
    right: 10px;
    top: 40%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #888;
  }

  .label {
    display: block;
    padding: 12px;
    font-size: 9px;
    color: #888;
  }

  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    border: 1px solid #bbb;
    position: absolute;
    z-index: 1;
    background: #fff;
  }

  li.highlight {
    padding: 8px;
    color: #666;
    &:hover {
      color: white;
      background: $tertiary-lightest-color;
    }
  }

  .restricted {
    max-height: 400px;
    overflow-y: auto;
  }

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
}
.d-merging {
  margin-left: 4px;
}
ul.quickFilters {
  list-style-type: none;
  display: inline;
  padding: 4px;
}

ul.quickFilters li {
  list-style: none;
  display: inline;
}

ul.quickFilters li .btn {
  font-size: 0.8rem;
  padding: 10px;
}
</style>
