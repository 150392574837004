<template>
  <PageTemplate :showHeader="true">
    <div class="row">
        <div class="col-12">
            <h1>{{ $t("accessibility.title") }}</h1>
            <p>{{ $t("accessibility.text") }} <a href="tel:1-800-354-3372" title="1-800-354-3372">1-800-354-3372</a>.</p>
        </div>
    </div>
  </PageTemplate>
</template>

<script>
import PageTemplate from "@/pages/templates/PageTemplate";

export default {
  name: "Accessibility",
  components: {
    PageTemplate
  }
}
</script>

<style lang="scss" scoped>
h1{
  padding: 30px 0;
}
</style>
