<template>
  <div id="submission">
    <div class="row mt-2">
      <div class="col text-center n-contact subContent">
        We've received your submission.
      </div>
    </div>
    <div class="row">
      <div class="col text-center n-contact">
        A customer representative will be in touch with you shortly.
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <button class="btn btn-primary" @click.prevent="navToProductResults()">
          Continue Shopping
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactUsConfirmation",
  methods: {
    navToProductResults() {
      this.$router.push("/product-results");
    },
  },
  mounted() {
    this.$scrollTo("#submission", 500, {
      easing: "ease-in-out",
      offset: -300,
    });
  },
};
</script>
<style scoped lang="scss">
.subContent {
  font-size: 35px;
  color: $admin-primary-dark-color;
}

.btn {
  margin-top: 25px;
  width: 30%;
}
</style>
