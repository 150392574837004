// addresses
export const SET_ADDRESS_SEARCH_RESULTS = "setAddressSearchResults";

// company address
export const SET_CRM_ADDRESS_SEARCH_RESULTS = "setCrmAddressSearchResults"
export const SET_CRM_ADDRESS_FILTERS = "setCrmAddressFilters"
export const SET_ADDRESS_AS_FAVORITE = "setAddressAsFavorite"
export const UNSET_ADDRESS_AS_FAVORITE = "unsetAddressAsFavorite"

// menu
export const SET_ACTIVE_MENU_ITEMS = "setActiveMenuItems";

// inventory
export const SET_INVENTORY_QUANTITY = "setInventoryQuantity";

export const SET_USER_ANNOUNCEMENT = "setUserAnnouncement";

//cart
export const SET_CART_ADD_ON_ITEM = "setCartAddOnItem";