<template>
  <div class="n-product-tile-list" v-if="products && products.length">
    <div class="row" v-show="isLoading">
      <div class="col-6">
        <Skeleton width="180px" height="180px"></Skeleton>
      </div>
      <div class="col-6">
        <Skeleton v-for="y in 6" :key="y" :max-width="180"></Skeleton>
      </div>
    </div>
    <div
      class="row mt-4 px-3"
      v-show="!isLoading"
      v-for="(product, index) in products"
      :key="product.id"
    >
      <div class="col-4">
        <router-link
          :id="product.id"
          :title="product.name"
          :to="'/product-detail/' + product.id"
        >
          <img
            class="img-fluid"
            :src="getImageUrl(product.thumbnailImageUrl)"
          />
        </router-link>
        <div class="mt-2">
          <div
            class="badges"
            v-for="badge in product.tags"
            :key="badge.id"
          >
          <span
              class="badge badge-primary"
              >{{ badge.name }}
          </span>

          </div>
        </div>
        <FavoriteProductIcon
              :id="product.id"
              :isFavorite="product.isFavorite"
              :showText="false"
              :showIconToAuthUsersOnly="true"
              addFavoriteTextId="shared.favoriteIcon.addText"
              removeFavoriteTextId="shared.favoriteIcon.removeText" />
      </div>

      <div class="col-7">
        <h2>
          <router-link
            class="n-product-name"
            :id="product.id"
            :title="product.name"
            :to="'/product-detail/' + product.id"
          >
            {{ product.name }}
          </router-link>
        </h2>
        <h4 class="n-product-sku">
          <router-link
            :id="product.id"
            :title="product.name"
            :to="'/product-detail/' + product.id"
          >
            {{ product.itemNumber }}
          </router-link>
        </h4>        
        <template v-if="storeSetup.isPricingEnabled">
          <div v-if="product.isOnSale" class="n-price d-inline-block">
            {{ product.salePrice | currency }}
            <span class="n-price-on-sale">
              {{ $t("shared.pricing.salePriceWas") }}
              {{ product.unitPrice | currency }}
            </span>
          </div>
          <div class="n-price d-inline-block" v-else>
            {{ product.unitPrice | currency }}
          </div>
        </template>
        <template v-if="storeSetup.ratingsEnabled">
          <div v-if="product.rating != null">
            <star-rating
              v-model="product.rating.value"
              v-bind:increment="0.5"
              v-bind:max-rating="5"
              v-bind:star-size="15"
              v-bind:border-width="2"
              v-bind:padding="3"
              inactive-color="#fff"
              active-color="#ffb500"
              border-color="#ba9143"
            >
            </star-rating>
            <div class="n-total-ratings">
              <router-link
                :id="product.id"
                :title="product.name"
                :to="'/product-detail/' + product.id"
              >
                {{ product.rating.totalRatings }} Ratings
              </router-link>
            </div>
          </div>
          <div v-else>
            <router-link
              :id="product.id"
              :title="product.name"
              :to="'/product-detail/' + product.id"
            >
              Add the first rating
            </router-link>
          </div>
        </template>
        <template v-if="product.messages && product.messages.length">
        <div class="n-message-list">
          <MessageList :messages="product.messages" />
        </div>
        </template>
        <form class="form">
          <div class="form-group" v-if="product.canAddToCart && product.attributes.Inventory.quantityAvailable > 0">
            <div class="row">
              <div class="col-6">
                <label class="n-uom mt-2 mt-md-0" v-if="isUnitOfMeasureAvailable(product)">
                  <Uom
                    :uom="getInventory(product).unitOfMeasure"
                    :unitQuantity="getInventory(product).quantityPerUnit"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="n-inventory-message" v-else>
            {{ outOfStockMessage }} 
          </div>
          <button
            class="btn btn-primary btn-block n-add-cart"
            v-if="product.canAddToCart && product.attributes.Inventory.quantityAvailable > 0"
            :id="'button-' + product.id"
            @click.prevent="addItemToCart(product)"
          >
            <span
              v-if="
                buttonLoading && buttonLoading.length && buttonLoading[index]
              "
            >
              <b-spinner small></b-spinner>
              Loading...
            </span>
            <span v-else>
              Add to Cart
            </span>
          </button>
          <button
            class="btn btn-outline-primary btn-block"
            v-if="product.isPreviewable"
          >
            Preview
          </button>
          <button
            class="btn btn-outline-primary btn-block"
            v-if="product.isDownloadable"
          >
            Download
          </button>
          <span :id="'message-' + product.id">{{ addToCartMessage }}</span>
        </form>
      </div>
      <div class="col-1 d-flex n-arrow">
        <div class="align-self-center">
          <router-link
            :id="product.id"
            :title="product.name"
            :to="'/product-detail/' + product.id"
          >
            <font-awesome-icon icon="chevron-right" />
          </router-link>
        </div>
      </div>
      <div class="col-sm-12 n-product-row">
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Skeleton from "@/nucleus-modules/dd-nucleus-ui/components/Skeleton.vue";
import Uom from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";
import {
  FLATTENED_CATEGORIES_GETTER,
  SITE_SETUP_GETTER,
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { FavoriteIconType } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import MessageList from '@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue';

// import FavoriteIconViewer from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteIconViewer.vue";
import FavoriteProductIcon from "@/nucleus-modules/dd-nucleus-storefront/components/favorite-icon/FavoriteProductIcon.vue";

// eslint-disable-next-line no-unused-vars
import * as StorefrontConstants from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { productMixins } from "@/mixins/productMixin.js";

export default {
  name: "ProductTileMobileMode",
  mixins:[productMixins],
  props: {
    products: {
      type: Array,
      required: true,
    },
    addItemToCart: {
      type: Function,
    },
    buttonLoading: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    noImageAvailableUrl:{
      type: String,
      required: false
    }
  },
  components: {
    // FavoriteIconViewer,
    MessageList,
    Uom,
    Skeleton,
    FavoriteProductIcon
  },
  computed: {
    ...mapGetters({
      flattenedCategories: [FLATTENED_CATEGORIES_GETTER],
      storeSetup: [SITE_SETUP_GETTER],
    }),
    badges() {
      return StorefrontConstants.Badges;
    },
    favoriteIconType() {
      return FavoriteIconType;
    },
    outOfStockMessage() {
      if(this.storeSetup && this.storeSetup.constants && this.storeSetup.constants.defaultOutOfStockMessageText){
        return this.storeSetup.constants.defaultOutOfStockMessageText
      }
      return "";
    }
  },
  data() {
    return {
      errorMessage: "",
      productList: [],
      addToCartMessage: "",
      UiEvents: Object.freeze(UiEvents),
    };
  },
  beforeMount() {
    //this.setDefaultQuantities();
  },
  methods: {
    getImageUrl(url) {
      if (url) return url;
      return this.noImageAvailableUrl;
    },
    disableButton(isDisable) {
      if (isDisable == false) {
        return false;
      } else {
        return true;
      }
    },
    setDefaultQuantities() {
      var i;
      for (i = 0; i < this.products.length; i++) {
        this.products[i].quantity = 1;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.n-product-tile-list {
  .popover-body {
    background: $red !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
  }

  .svg-inline--fa {
    vertical-align: -0.2em;
  }

  .n-price {
    color: $red;
    font-size: 16px;
    font-weight: bold;
  }

  .n-price span.n-price-on-sale {
    font-size: 14px;
    color: $gray-3;
    font-weight: normal;
    text-decoration: line-through;
  }

  .n-order-limits {
    color: $gray-3;
    font-size: 13px;
    font-weight: 500;
  }

  .n-order-limits span {
    color: $red;
    font-weight: bold;
  }

  .bs-popover-auto[x-placement^="bottom"] > .arrow::after,
  .bs-popover-bottom > .arrow::after {
    border-bottom-color: $red;
  }

  .badges {
    margin-right: 5px;
    display: inline;
    font-size: 13px;
  }

  .badge {
    padding: 0.35em 0.6em;
  }

  .form-inline .form-group {
    margin-bottom: 10px;
  }

  .n-total-ratings {
    font-size: 12px;
    display: inline;
  }

  .img-fluid {
    background-color: $gray-2;
  }

  .n-favorite {
    padding-top: 6px;
  }

  .n-product-row {
    padding-top: 16px;
    padding-bottom: 16px;
  }

}
.n-arrow {
  padding-left: 0px;
}
.n-product-tile-list {
  font-size: 00.85rem;
}
.n-add-cart {
  font-size: 1rem;
  text-transform: capitalize;
}

</style>
