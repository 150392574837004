<template>
    <PageTemplate :showHeader="false">
        <div class="container d-md-block">
            <div class="row mx-1 my-5">
                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h4>Emulation ended. Please close this browser tab.</h4>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import PageTemplate from "@/pages/templates/PageTemplate";
    export default {
        name: "emulation-ended",
        components: {
            PageTemplate,
        },
    };
</script>

<style lang="scss">
    h1 {
        color: $green;
    }
</style>
