<template>
    <div>
        <h5 class="n-form-section-header">Item Details</h5>
        <div class="form-group mt-4">
            <label class="n-form-labels">Item Type</label>
            <b-form-select
              v-model.trim="itemType"
              :options="listOfItemType"
              value-field="id"
              text-field="name"
            ></b-form-select>
        </div>
        <div class="form-group mt-4">
            <label class="n-form-labels">Item Name</label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   v-model.trim="name" 
                   :class="{ 'is-invalid': $v.name.$invalid }"
                   @input="$v.name.$touch()" />
        </div>
        <div v-if="$v.name.$invalid" class="n-error">
            <span v-if="!$v.name.required">An item name is required.</span>
            <span v-if="!$v.name.minLength">
                The item name must have at least
                {{ $v.name.$params.minLength.min }} characters.
            </span>
        </div>
        <div class="form-group mt-4">
            <label class="n-form-labels">Item Number / Item ID</label>
            <input type="text"
                   class="form-control n-text-field-highlight"
                   :class="{ 'is-invalid': $v.itemNumber.$invalid }"
                   v-model.trim="itemNumber"
                   @input="$v.itemNumber.$touch()" />
        </div>
        <div v-if="$v.itemNumber.$invalid" class="n-error">
            <span v-if="!$v.itemNumber.required">An item number is required.</span>
            <span v-if="!$v.itemNumber.minLength">
                The item number must have at least
                {{ $v.itemNumber.$params.minLength.min }} characters.
            </span>
        </div>
        <div class="form-group mt-4">
            <label class="n-form-labels">Item Description (optional)</label>
            <QuillEditor :modelValue="description" @updateDescription="updateDescription" />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import { required, minLength } from "vuelidate/lib/validators";
    import { PRODUCT_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    import { ListOfItemType } from "@/constants.js";
    import  QuillEditor  from "@/nucleus-modules/dd-nucleus-admin/components/QuillEditor.vue";
    const ATTRIBUTE_NAME = "ExtendedProductProperties";

    export default { 
        name: "ItemDetails",
        components: {
            QuillEditor
        },
        data() {
            return {
                listOfItemType:ListOfItemType,
                defaultItemType: 0
            };
        },
        computed: {
            ...mapGetters({
                product: PRODUCT_FOR_EDIT_GETTER
            }),
            itemType: {
                get() {
                    if( this.product && 
                        this.product.attributes &&
                        this.product.attributes.ExtendedProductProperties && 
                        this.product.attributes.ExtendedProductProperties.type){
                            return this.product.attributes.ExtendedProductProperties.type
                        }
                        return this.defaultItemType;
                },
                set(value) {
                    if(this.$nucleus.isEmpty(value)) {
                        value = this.defaultItemType;
                    }
                   this.setProductProperty({ name: "type", value: value, attribute: ATTRIBUTE_NAME});
                },
            },
            name: {
                get() {
                    return this.product["name"];
                },
                set(value) {
                    this.setProductProperty({ name: "name", value: value });                    
                },
            },
            itemNumber: {
                get() {
                    return this.product["itemNumber"];
                },
                set(value) {
                    this.setProductProperty({ name: "itemNumber", value: value });
                },
            },
            description: {
                get() {
                    return this.product["description"];
                },
                set(value) {
                    this.setProductProperty({ name: "description", value: value });
                },
            },
        },
        methods: {
            ...mapMutations({
                setProductProperty: SET_PRODUCT_PROPERTY,
            }),
            updateDescription(value) {
                this.description = value;
            },
        },
        validations: {
            name: {
                required,
                minLength: minLength(4),
            },
            itemNumber: {
                required,
                minLength: minLength(4),
            },
        },
        mounted() {
            this.setProductProperty = this.$nucleus.debounce(
                this.setProductProperty,
                500
            );
        },
    };
</script>

<style lang="scss" scoped>
    .n-error {
        font-size: 12px;
    }
    .form-group{
        margin-bottom: 0rem;
    }
</style>
