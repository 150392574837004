<template>
  <AdminTemplate>
    <div class="row">
        <div class="col-md-12 mb-3">
            <h3>
                {{pageTitle}}
                <span>
                    <router-link to="all-reports">Back to All Reports</router-link>
                </span>
            </h3>
        </div>
    </div> 
    <div class="row mb-2">
      <div class="col-12 text-right">
        <router-link to="all-reports" class="btn btn-outline-primary mr-2">Cancel</router-link>
        <button class="btn btn-primary" @click="saveReport">Save</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ValidationObserver ref="form">
          <ValidationProvider rules="required" name="Report Name" v-slot="{ errors }">
            <div class="form-group">
              <label>Report Name</label>
              <input type="text" class="form-control n-text-field-highlight" id="reportName" name="reportName"
                v-model="reportName" placeholder="Report Name"
                :class="{ 'is-invalid':  errors && errors.length}">
              <div v-if="errors && errors.length" class="invalid-feedback">{{errors[0]}}</div>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="max:300" name="report description" v-slot="{ errors }">
            <div class="form-group">
              <label>Description <span>(optional)</span></label>
              <textarea class="form-control n-text-field-highlight" id="reportDescription" name="reportDescription" rows="4"
                  placeholder="Brief description describing the purpose of the report" v-model="reportDescription"
                  :class="{ 'is-invalid':  errors && errors.length}">
              </textarea>
              <div v-if="errors && errors.length" class="invalid-feedback">{{errors[0]}}</div>
              <div v-else>
                <small class="form-text text-muted" v-if="reportDescription.length == 0">300 character Limit</small>
                <small class="form-text text-muted" v-else>Characters Remaining {{remainingCharacters}}</small>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required" name="Report Type" v-slot="{ errors }">
            <div>
              <label>Type</label>
            </div>
            <b-form-select v-model="reportType" class="n-text-field-highlight mb-3"
              :options="reportTypeOptions" 
              :class="{ 'is-invalid': errors && errors.length}"
              value-field="id"
              text-field="name"
              >
              <template #first>
                <b-form-select-option :value="null" disabled>Select Report Type</b-form-select-option>
              </template>
            </b-form-select>
            <div v-if="errors && errors.length" class="invalid-feedback">{{errors[0]}}</div>
          </ValidationProvider>
          <ValidationProvider rules="required" name="URL Link" v-slot="{ errors }">
            <div class="form-group">
              <label>URL Link</label>
              <input type="text" class="form-control n-text-field-highlight" id="urlLink" name="urlLink"
                v-model="reportUrl" placeholder="Report URL Link"
                :class="{ 'is-invalid':  errors && errors.length}">
              <div v-if="errors && errors.length" class="invalid-feedback">{{errors[0]}}</div>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <div>
              <label>Permissions</label>
            </div>
            <b-form-group>
              <b-form-radio v-model="isPublic" name="publicOrPrivate" value="true" @input="changeOption"><font-awesome-icon class="n-image-icons" :icon="['fas','users']" /> Make report public (available for others to use)</b-form-radio>
                <b-form-group class="ml-5" v-if="isReportPublic">
                  <b-form-radio v-model="reportRoles" name="reportRoles" value="all">Make visible for all roles</b-form-radio>
                  <b-form-radio v-model="reportRoles" name="reportRoles" value="specific">Make visible to specific role(s)</b-form-radio>
                  <div class="mt-2" v-if="isReportAllRoles">
                    <label>Select all roles that can view items associated to this new report.</label>
                  </div>
                  <b-form-group v-if="isReportAllRoles">
                      <ValidationProvider rules="required" name="At least one role" v-slot="{ errors }">
                          <b-form-checkbox-group id="checkbox-group-1"
                                                 v-model="selectedRoles"
                                                 :options="roleOptions"
                                                 value-field="id"
                                                 text-field="name"
                                                 name="specificRoles"
                                                 :class="{ 'is-invalid': errors && errors.length}"></b-form-checkbox-group>
                          <div v-if="errors && errors.length" class="mt-2 invalid-feedback">{{errors[0]}}</div>
                      </ValidationProvider>
                  </b-form-group>
                </b-form-group>
              <b-form-radio v-model="isPublic" name="publicOrPrivate" value=false><font-awesome-icon class="n-image-icons" :icon="['fas','user-alt']" /> Make report private (only available for your use)</b-form-radio>
            </b-form-group>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 text-right">
        <router-link to="all-reports" class="btn btn-outline-primary mr-2">Cancel</router-link>
        <button class="btn btn-primary" @click="saveReport">Save</button>
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/pages/templates/AdminTemplate";
import {uiValidator} from "@/nucleus-modules/dd-nucleus-ui/mixins/uiValidator";
import reportApi from "@/api/report-api";
import { mapGetters } from 'vuex';
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { adminRoles } from "@/companyAppConfig.js"

export default {
  name: "Add-New-Report",
  components: {
    AdminTemplate
  },
  mixins: [uiValidator],
  data() {
    return {
      reportName: null,
      reportDescription:'',
      reportType: null,
      reportUrl:null,
      isPublic:'true',
      reportRoles: 'all',
      selectedRoles: [], // Must be an array
      reportId: this.$route.query.id || '',
      reportTypeOptions: [],
      allRoles:[],
      roleIdsOnly: [],
      adminRoles: Object.freeze(adminRoles),
    }
  },
  computed: {
    ...mapGetters({
      siteSetup: SITE_SETUP_GETTER,  
    }),
    availableRoles() {
        if (this.siteSetup && this.siteSetup.roles){
            return this.siteSetup.roles;
        }
        return [];
    },
    roleOptions() {
      if(this.availableRoles && this.availableRoles.length){
      let adminRoles = this.adminRoles;
      return this.availableRoles.filter(ar => adminRoles.includes(ar.id));
      }
      return []
    },
    isReportPublic() {
      if (this.isPublic == "true" || this.isPublic == true) {
         return true;
      }
      return false;  
    },
    isReportAllRoles(){
      if (this.reportRoles == 'specific'){
        return true;
      } else {
        return false;
      }
    },
    isEdit(){
      return this.reportId == null || this.reportId.length != 0
    },
    pageTitle(){
      if (this.isEdit) {
        return 'Edit Report'
      } else {
        return 'Add New Report'
      }
    },
    remainingCharacters(){
      return 300 - this.reportDescription.length
    }
  },
  async mounted() {
    const promises = [this.findReportTypes()];
    const vm = this;
    return await Promise.all(promises).then(() => {
      if(vm.isEdit){
        vm.editReport();
      }
    });
  },
  methods: {
    changeOption() {
      if(this.isPublic){
        if(this.$nucleus.isEmpty(this.selectedRoles)) {
          this.reportRoles = "all";
        } else {
          this.reportRoles = "specific";
        }
      }
    },
    async findReportTypes(){
      // this will hydrate the Report Type select box 
       this.reportTypeOptions  = await reportApi.getReportGroups();
    },

    async editReport(){
      return await reportApi.getReportDetail(this.reportId).then((response) => {
        if (response && response.succeeded){

          // Set Local vars from the API response 
          this.reportId = response.report.id;
          this.reportName = response.report.name;
          this.reportDescription = response.report.description || '';
          this.reportType = response.report.groupId;
          this.reportUrl = response.report.externalUrl;
          this.selectedRoles = response.report.userRoles; 

          // set isPublic based on API 
          if (this.$nucleus.isEmpty(response.report.userId)){
            this.isPublic = true;
          } else {
            this.isPublic = false;
          }
          this.roleIdsOnly = this.roleOptions.map(ar => ar.id);
          // See if the roles that are available match what are set for this report 
          var match = this.doTheRolesMatch(this.roleIdsOnly,this.selectedRoles);
          if (match){
            this.reportRoles = 'all';
            this.selectedRoles = [];
          } else {
            this.reportRoles = 'specific';
          }
        } else {
          let message = "The API call to edit this report failed please go back and try again.";
            let title = "API Error";
            ToastService.showErrorToast(message, title);
        }
      });
    },

    saveReport(){
      let message, title, request;
      this.$refs.form.validate().then(async success => {
        if (success) {

          // set all the roles for the API call 
          if (this.reportRoles == 'all' && this.isReportPublic){
            this.selectedRoles = [];
            for (var i = 0; i < this.roleOptions.length; i++) {
              this.selectedRoles.push(this.roleOptions[i].id);
            }
          } else if (!this.isReportPublic){
            this.selectedRoles = [];
          }

          // build the request for saving the report
          request = {
            "reportName": this.reportName,
            "reportDescription": this.reportDescription,
            "reportGroupId": this.reportType,
            "externalUrl": this.reportUrl,
            "roles": this.selectedRoles,
            "isPublic": this.isReportPublic
          }

          // Add the report ID for edit 
          if (this.isEdit){
            request.reportId = this.reportId;
          }
          
          var response = await reportApi.addReport(request);

          if (response.succeeded) {
            // Set the reportId for the newly created report 
            this.reportId = response.id;
            message = "Report has been saved successfully.";
            ToastService.showSuccessToast(message);
          } else {
            title = "Error saving the report";
            if ( response && response.messages  && response.messages[0].debugMessage && response.messages[0].debugMessage.length ) {
                message = response.messages[0].debugMessage;
            } else {
                message = "The API called failed to save your Report please try again.";
            }
            ToastService.showErrorToast(message, title);
          }
        } else {
          message = "Please fill in all of the required fields and submit the form again.";
          title = "Form is Not Valid";
          ToastService.showErrorToast(message, title);
        }
      });
    },

    doTheRolesMatch(a,b){
      // little method to see if the selected roles from the api match all the roles we have available 
      if (a.length !== b.length) return false;
      const uniqueValues = new Set([...a, ...b]);
      for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
      }
      return true;
    }
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 6px;
  font-size: 0.85rem;
}
h3 span{
  font-size: .875rem;
}
h3 span a{
  color: $primary-color;
}
h3{
  color: $primary-dark-color;
  font-size: 1.5rem;
}
.form-control, .custom-select{width:50%}
/*label, legend{font-family: $font-family-bold;}*/
label span {font-family: $body-font;}
.invalid-feedback{margin-top: -.5rem;}
</style>
