<template>
    <button type="button" class="close" @click="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</template>

<script>
import ModalService from '@/nucleus-modules/dd-nucleus-ui/services/modal.service';

export default {
    name: "ModalClose",
    methods: {
        closeModal() {
            ModalService.close();
        }
    },
}
</script>