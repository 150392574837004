<template>
  <div class="input-group">
    <input
      type="text"
      class="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block form-control n-search-input "
      :placeholder="placeholderText"
      :value="filterText"
      @change="textChanged"
      @keyup.enter="inputChanged($event)"
    />

    <input
      type="text"
      class="d-block d-sm-block d-md-none d-lg-none d-xl-none form-control n-search-input "
      :placeholder="placeholderTextMobileView"
      :value="filterText"
      @change="textChanged"
      @keyup.enter="inputChanged($event)"
    />

    <div v-if="showSearchIcon" class="input-group-append">
      <button
        :value="filterText"
        class="searchIcon btn btn-outline-primary pb-0 pt-0 searchIcon "
        type="button"
        @click="inputChanged($event)"
      >
        <font-awesome-icon icon="search" fixed-width></font-awesome-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextBoxFilter",
  props: {
    debounceWait: {
      type: Number,
      required: false,
      default: 500,
    },
    placeholderText: {
      type: String,
      required: false,
    },
    placeholderTextMobileView: {
      type: String,
      required: false,
    },
    filterId: {
      type: String,
      required: true,
    },
    filterValues: {
      type: Array,
      required: false,
    },
    showSearchIcon: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    filterText() {
      let vm = this;
      if (this.filterValues && !vm.$nucleus.isEmpty(this.filterValues[0]))
        return this.filterValues[0];
      else return "";
    },
  },
  methods: {
    inputChanged() {
      this.$emit("changed", {
        filterId: this.filterId,
        values: [this.searchTerm],
      });
    },
    textChanged($event) {
      this.searchTerm = $event.target.value;
    },
  },
  mounted() {
    this.inputChanged = this.$nucleus.debounce(
      this.inputChanged,
      this.debounceWait
    );
  },
};
</script>
<style scoped lang="scss">
.input-group-append button:hover {
  color: #ffffff;
}
.searchIcon {
  border-left: none;
  border-color: #ced4da !important;
}

.input-group ::placeholder {
  color: $primary-dark-color;
}
</style>
