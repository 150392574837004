<template>
  <div class="n-address-search">
    <div class="row ml-0 mr-0">
      <div class="d-none d-md-block d-lg-block pl-0 w-100">
        <AlphabetBar
          :title="$t('crmAddresses.filters.alphabetBarTitle')"
          filterId="first-letter-search"
          :filterValues="getFilterValuesByFilterId('first-letter-search')"
          @changed="filtersChanged($event)"
          :showTitle="true"
        ></AlphabetBar>
      </div>
    </div>

    <div class="row mt-3 ml-0 mr-0">
      <div class="col-12 col-md-8 pl-0 pr-md-2 pr-0">
        <TextBoxFilter
          :debounceWait="500"
          filterId="search-term"
          :filterValues="getFilterValuesByFilterId('search-term')"
          :placeholderText="$t('crmAddresses.textbox.placeholderText')"
          :placeholderTextMobileView="
            $t('crmAddresses.textbox.placeholderTextMobileView')
          "
          :showSearchIcon="showSearchIcon"
          @changed="filtersChanged($event)"
        >
        </TextBoxFilter>
      </div>
      <div class="col-md-4 mt-3 mt-md-0 pr-0 pl-0">
        <SortByList
          :sort="sort"
          :sortOptions="sortOptions"
          @changed="sortOptionChanged($event)"
        ></SortByList>
      </div>
    </div>
  </div>
</template>
<script>
import TextBoxFilter from "@/nucleus-modules/dd-nucleus-ui/components/address-book/TextBoxFilter.vue";
import AlphabetBar from "@/nucleus-modules/dd-nucleus-ui/components/address-book/AlphabetBar.vue";
import SortByList from "@/nucleus-modules/dd-nucleus-ui/components/address-book/SortByList.vue";
import { mapGetters } from "vuex";
import {
  CRM_ADDRESS_SORTING_CONFIG_GETTER,
  CRM_ADDRESS_FILTERS_GETTER,
} from "@/store/storefront/getters.type.js";

export default {
  name: "CRMAddressBookFilters",
  components: {
    SortByList,
    TextBoxFilter,
    AlphabetBar,
  },
  props: {
    isCheckout: {
      type: Boolean,
      required: false,
    },
    searchOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showSearchIcon: true,
    };
  },
  computed: {
    ...mapGetters({
      sortingConfig: [CRM_ADDRESS_SORTING_CONFIG_GETTER],
      filters: CRM_ADDRESS_FILTERS_GETTER,
    }),
    sort() {
      if (this.searchOptions.sort) return this.searchOptions.sort;
      else return this.sortingConfig.defaultSort;
    },

    sortOptions() {
      if (
        this.sortingConfig &&
        this.sortingConfig.options &&
        this.sortingConfig.options.length
      ) {
        return this.sortingConfig.options.map((x) => {
          return {
            value: x.id,
            text: x.name,
          };
        });
      }
      return [];
    },
  },
  methods: {
    filtersChanged(eventData) {
      this.$emit("changed", eventData);
    },
    sortOptionChanged(eventData) {
      this.$emit("sortOptionChanged", eventData);
    },
    getFilterValuesByFilterId(filterId) {
      const filterIndex = this.filters.findIndex(
        (f) => f.filterId === filterId
      );
      if (filterIndex !== -1 && this.filters[filterIndex].values.length > 0) {
        return this.filters[filterIndex].values;
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.n-address-search {  
  padding: 0 1rem 1rem 1rem;
}
</style>
