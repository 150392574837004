<template>
  <div class="n-checkout-section-header px-4">
    <h2 :id="id">
      {{ title }}
      <span class="n-sub-headling" v-if="subHeading">({{ subHeading }})</span>
      <span v-if="linkText" class="n-change-link" v-on:click="onClick">{{
        linkText
      }}</span>
    </h2>
  </div>
</template>

<script>
/* Headline props:
 *  title - the text for the H2 tag
 *  linkText (optional) - If the headline has a link in the box the text for the link
 */
export default {
  name: "Headline",
  props: {
    title: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: false,
    },
    linkText: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("on-click");
    },
  },
};
</script>

<style lang="scss" scoped>
h2{
  margin-bottom: 0;
  font-weight: 400;
}
.n-sub-headling {
  color: $gray-3;
  font-size: 0.75rem;
}
.n-change-link {
  padding-top: 2px;
  font-size: 14px;
  padding-left: 20px;
}

@media (max-width: 576px) {
  .n-checkout-section-header {
    margin-top: 0px;
  }
  .n-change-link {
    padding-right: 22px;
  }
}
</style>
