<template>
    <div>
        <div>
            <strong>{{shipMethod.name}}</strong>
        </div>
        <!-- TODO:-->
        <div v-if="isPricingEnabled">
        {{ $t("reviewOrder.shippingCost") }}:
            <strong>{{shipMethod.cost | currency}}</strong>
        </div>
        <div v-if="shipMethod.estimatedArrival">
        {{ $t("reviewOrder.estimatedArrival") }}:
            <strong>{{shipMethod.estimatedArrival}}</strong>
        </div>
        <p class="n-notes">{{shippingNotes}}</p>
    </div>
</template>
<script>

export default {
    name: 'ShippingMethodView',
    props: {
        shipMethod: {
            type: Object,
            required: true
        },
        defaultShippingNotes: {
            type: String,
            required: false
        },
        isPricingEnabled: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        shippingNotes() {
            if(this.method && this.method.notes && this.method.notes.length) {
                return this.method.notes;
            }
            return this.defaultShippingNotes;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>