<template>
  <div class="overflow-auto">
    <b-pagination
      v-model="selectedPage"
      :total-rows="totalResults"
      :per-page="perPage"
      first-number
      last-number
      align="right"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "PagingControls",
  props: {
    currentPageNumber: {
      type: Number,
      required: true,
    },
    totalResults: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
      selectedPage: {
        get() {
            return this.currentPageNumber
        },
        set(value) {
          this.$emit('pageNumberChanged', {selectedPage : value});
        }
      }
  },
};
</script>

<style lang="scss">
.page-item.active .page-link {
  background-color: $primary-color !important;
  border-color: $primary-dark-color !important;
  color: $primary-lightest-color !important;
}
.page-link {
  color: $primary-color !important;
}
.page-link:focus {
  box-shadow: none;
  outline: 0;
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  color: $pagination-icon-color !important;
}
.pagination {
  font-size:0.85rem;
}
</style>
