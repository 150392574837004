<template>
  <div>
    <div class="n-choose-method">
    <b-form-group>
      <template v-for="(shippingMethod, index) in shippingMethods">
        <b-form-radio v-model="currentShippingMethodCode" class="mb-4" name="shippingMethod" :value="shippingMethod.code" :key="index" @change="shippingMethodSelected">
          <div>
            <strong>
              {{ shippingMethod.estimatedArrival }} <span v-if="showPricing"> - {{ shippingMethod.cost | currency }}</span>
            </strong>
          </div>
          <div class="mt-1">
            {{ shippingMethod.name }}
          </div>
        </b-form-radio>
      </template>
    </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShippingMethod",
  props: {
    selectedShippingMethodCode: {
      type: String,
      required: false,
    },
    shippingMethods: {
      type: Array,
      required: true,
    },
    showPricing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        currentShippingMethodCode:null
    }
  },
  methods: {
    shippingMethodSelected() {
      this.$emit("shipping-method-selected", this.currentShippingMethodCode);
    }
  },
  mounted() {
    this.currentShippingMethodCode = this.selectedShippingMethodCode;
  },
};
</script>

<style lang="scss" scoped>

@media (max-width: 576px) {

  .n-button-spacer {
    margin-left: 2px;
  }
}
</style>
