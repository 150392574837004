import { mapGetters } from "vuex";
import { MENU_BY_ID_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

export default {
    props: {
        menuId: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            menuById: [MENU_BY_ID_GETTER],
        }),
        menu() {
            return this.populateMenu();
        },
    },
    methods: {
        populateMenu() {
            let newMenus = [];
            if(this.menuId){
                const menu = this.menuById(this.menuId);
                if(menu && menu.children) {
                    newMenus = [...menu.children];
                }
            }
            return newMenus;
        },
        onMenuClicked(payload) {
            if (this.menu) {
               this.menu.forEach(element => {
                   element.opened = false;
               });
               
               if(payload){
                this.menu.find(c => c.id == payload.id).opened = payload.isOpened;
               }
           } 
       },        
       onAdminMenuClicked(payload) {
        if (this.items) {
           this.items.forEach(element => {
               element.opened = false;
           });
           
           if(payload){
            this.items.find(c => c.title == payload.title).opened = payload.isOpened;
           }
       } 
   },        
    }

}

