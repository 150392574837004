import { mapGetters } from "vuex";
import { SITE_SETUP_GETTER, ALL_CATEGORIES_ID_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

export default {
    computed: {
        ...mapGetters({
            siteSetup: SITE_SETUP_GETTER,
            allCategoriesId: [ALL_CATEGORIES_ID_GETTER],
        })
    },
    methods: {
        isMenuItemVisible(menu) {
            if (menu && this.$nucleus.isEmpty(menu.scopes)) {
                return true;
            }
            const user = this.siteSetup.user;
            if (user && user.roles && menu) {
                let userRoleIds = user.roles.map(r => r.id);
                if(user.isImpersonating){
                    userRoleIds = user.roles.filter(r => !r.forImpersonator).map(r => r.id)
                }
                return menu.scopes.some(s => userRoleIds.includes(s.userRoleId)) || menu.id == this.allCategoriesId;
            }
            return false; 
        },
    }

}

