import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";
import PagingService from "@/nucleus-modules/dd-nucleus-ui/services/paging.service.js";
import {
    ADD_BULK_ORDER_RECIPIENT,
    APPROVE_ORDER,
    DELETE_ANNOUNCEMENT,
    GET_ADMIN_MENU,
    GET_ALL_PRODUCTS,
    GET_ALL_ROLES,
    GET_BULK_ORDER_DETAIL,
    GET_PRODUCT_FOR_EDIT,
    GET_AUDIT_FIELDS,
    GET_PRODUCT_MEDIA,
    GET_ORDER_DETAILS,
    GET_ORDERS,
    GET_USER_FOR_EDIT,
    GET_ANNOUNCEMENT_FOR_EDIT,
    GET_USERS,
    INIT_BULK_ORDER,
    MASS_APPROVE_ORDERS,
    MASS_REJECT_ORDERS,
    PLACE_BULK_ORDER,
    PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT,
    REJECT_ORDER,
    REMOVE_BULK_ORDER_RECIPIENT,
    REMOVE_PRODUCT_MEDIA,
    SAVE_ORDER_INTERNAL_NOTES,
    SAVE_ORDER_PRODUCT_QUANTITIES,
    SAVE_ORDER_SHIP_TO_ADDRESS,
    SAVE_ORDER_SHIP_METHOD,
    SAVE_PRODUCT,
    SAVE_ANNOUNCEMENT,
    SEARCH_BULK_ORDER_RECIPIENTS,
    SEARCH_BULK_ORDERS,
    SEARCH_ORDERS,
    SEARCH_PRODUCTS,
    SEARCH_ADMIN_PRODUCTS,
    SEARCH_USERS,
    SEARCH_ANNOUNCEMENTS,
    SEND_WELCOME_EMAIL,
    UPDATE_BULK_ORDER_PRODUCTS,
    UPDATE_BULK_ORDER_SHIPPING_METHOD,
    UPDATE_PRODUCT_MEDIA_SORT,
    RESET_BULK_ORDER_ERRORS,
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
    ADMIN_MENU_GETTER,
    ALL_PRODUCTS_GETTER,
    AUDIT_FIELDS_GETTER,
    ALL_ROLES_GETTER,
    BULK_ORDER_DETAIL_GETTER,
    BULK_ORDER_USERS_GETTER,
    GET_BULK_ORDER_DETAIL_RESPONSE_GETTER,
    GET_ORDERS_ERRORS_GETTER,
    EDIT_PRODUCT_STEPPER_GETTER,
    IS_PRODUCT_EDIT_DIRTY_GETTER,
    IS_USER_EDIT_DIRTY_GETTER,
    IS_ANNOUNCEMENT_EDIT_DIRTY_GETTER,
    ORDER_DETAIL_GETTER,
    ORDER_PROPERTY_GETTER,
    ORDERS_GETTER,
    PLACE_BULK_ORDER_ERRORS_GETTER,
    PLACE_BULK_ORDER_RESPONSE_GETTER,
    PRODUCT_FOR_EDIT_GETTER,
    PRODUCT_PROPERTY_GETTER,
    RECIPIENTS_SEARCH_RESPONSE_GETTER,
    SAVE_PRODUCT_ERROR_GETTER,
    SAVE_USER_ERROR_GETTER,
    SAVE_ANNOUNCEMENT_ERROR_GETTER,
    SEARCH_BULK_ORDERS_RESPONSE_GETTER,
    SEARCH_ORDERS_RESPONSE_GETTER,
    SEARCH_PRODUCTS_RESULTS_GETTER,
    STATES_LIST_GETTER,
    USER_FOR_EDIT_GETTER,
    ANNOUNCEMENT_FOR_EDIT_GETTER,
    USER_PROPERTY_GETTER,
    USER_SEARCH_RESULTS_GETTER,
    ANNOUNCEMENT_SEARCH_RESULTS_GETTER,
    ANNOUNCEMENT_DELETERESPONSE_GETTER,
    USERS_GETTER,
    LAST_INV_SYNC_TIME_GETTER
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
    SET_ADMIN_MENU,
    SET_ALL_PRODUCTS,
    SET_ALL_ROLES,
    SET_BULK_ORDER_DETAIL,
    SET_BULK_ORDER_PRODUCTS,
    SET_BULK_ORDER_SHIPPING_METHOD,
    SET_BULK_ORDER_USERS,
    SET_EDIT_PRODUCT_STEPPER,
    SET_GET_ALL_PRODUCTS_ERROR,
    SET_GET_BULK_ORDER_DETAIL_RESPONSE,
    SET_GET_PRODUCT_FOR_EDIT_ERROR,
    SET_GET_PRODUCT_MEDIA_ERROR,
    SET_GET_ORDERS_ERRORS,
    SET_IS_PRODUCT_EDIT_DIRTY,
    SET_IS_USER_EDIT_DIRTY,
    SET_IS_ANNOUNCEMENT_EDIT_DIRTY,
    SET_ORDER_DETAIL,
    SET_ORDER_PROPERTY,
    SET_ORDERS,
    SET_PLACE_BULK_ORDER_ERRORS,
    SET_PLACE_BULK_ORDER_RESPONSE,
    SET_PRODUCT_FOR_EDIT,
    SET_AUDIT_FIELDS_ERROR,
    SET_AUDIT_FIELDS,
    SET_PRODUCT_MEDIA,
    SET_PRODUCT_PROPERTY,
    SET_PRODUCT_SEARCH_ERROR,
    SET_PRODUCT_SEARCH_RESULTS,
    SET_SAVE_PRODUCT_ERROR,
    SET_SAVE_USER_ERROR,
    SET_SAVE_ANNOUNCEMENT_ERROR,
    SET_DELETE_ANNOUNCEMENT_RESPONSE,
    SET_SEARCH_BULK_ORDERS_ERRORS,
    SET_SEARCH_BULK_ORDERS_RESPONSE,
    SET_SEARCH_ORDERS_ERRORS,
    SET_SEARCH_ORDERS_RESPONSE,
    SET_SEARCH_RECIPIENTS_ERRORS,
    SET_SEARCH_RECIPIENTS_RESULTS,
    SET_SEND_WELCOME_EMAIL,
    SET_STEPPER_CURRENT_STEP,
    SET_USER_ADDRESS,
    SET_USER_ATTRIBUTE_PROPERTY,
    SET_USER_FOR_EDIT,
    SET_ANNOUNCEMENT_FOR_EDIT,
    SET_USER_PROPERTY,
    SET_ANNOUNCEMENT_PROPERTY,
    SET_USER_ROLES,
    SET_USER_SEARCH_RESULTS,
    SET_ANNOUNCEMENT_SEARCH_RESULTS,
    SET_USERS,
    SET_LAST_INV_SYNC_TIME
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

// TODO: remove the hardcoded menu, retrieve from API
import { AdminMenu } from "./admin-menu.js";
import { UPDATE_INVENTORY, GET_LAST_INV_SYNC_TIME} from "./actions.type.js";

const MODULE_ID = "admin";

const getStatesList = () => {
    return [
        { code: "AL", name: "Alabama" },
        { code: "AK", name: "Alaska" },
        { code: "AZ", name: "Arizona" },
        { code: "AR", name: "Arkansas" },
        { code: "CA", name: "California" },
        { code: "CO", name: "Colorado" },
        { code: "CT", name: "Connecticut" },
        { code: "DE", name: "Delaware" },
        { code: "DC", name: "District Of Columbia" },
        { code: "FL", name: "Florida" },
        { code: "GA", name: "Georgia" },
        { code: "HI", name: "Hawaii" },
        { code: "ID", name: "Idaho" },
        { code: "IL", name: "Illinois" },
        { code: "IN", name: "Indiana" },
        { code: "IA", name: "Iowa" },
        { code: "KS", name: "Kansas" },
        { code: "KY", name: "Kentucky" },
        { code: "LA", name: "Louisiana" },
        { code: "ME", name: "Maine" },
        { code: "MD", name: "Maryland" },
        { code: "MA", name: "Massachusetts" },
        { code: "MI", name: "Michigan" },
        { code: "MN", name: "Minnesota" },
        { code: "MS", name: "Mississippi" },
        { code: "MO", name: "Missouri" },
        { code: "MT", name: "Montana" },
        { code: "NE", name: "Nebraska" },
        { code: "NV", name: "Nevada" },
        { code: "NH", name: "New Hampshire" },
        { code: "NJ", name: "New Jersey" },
        { code: "NM", name: "New Mexico" },
        { code: "NY", name: "New York" },
        { code: "NC", name: "North Carolina" },
        { code: "ND", name: "North Dakota" },
        { code: "OH", name: "Ohio" },
        { code: "OK", name: "Oklahoma" },
        { code: "OR", name: "Oregon" },
        { code: "PA", name: "Pennsylvania" },
        { code: "PR", name: "Puerto Rico" },
        { code: "RI", name: "Rhode Island" },
        { code: "SC", name: "South Carolina" },
        { code: "SD", name: "South Dakota" },
        { code: "TN", name: "Tennessee" },
        { code: "TX", name: "Texas" },
        { code: "UT", name: "Utah" },
        { code: "VT", name: "Vermont" },
        { code: "VA", name: "Virginia" },
        { code: "WA", name: "Washington" },
        { code: "WV", name: "West Virginia" },
        { code: "WI", name: "Wisconsin" },
        { code: "WY", name: "Wyoming" }
    ];
}

const initialState = {
    auditFields:[],
    allProducts: [],
    allRoles: [],
    bulkOrderRecipientsSearchResult: [],
    editProductStepper: {},
    getAllProductsError: [],
    getBulkOrderDetailResponse: {},
    getOrderDetailResponse: {},
    getOrdersErrors: [],
    isProductEditDirty: false,
    isUserEditDirty: false,
    orderDetails: {},
    orders: [],
    saveOrderProductQuantitiesResponse: {},
    placeBulkOrderErrors: [],
    placeBulkOrderResponse: {},
    saveProductError: [],
    saveUserError: [],
    saveAnnouncementError: [],
    searchBulkOrdersResponse: {},
    searchBulkOrdersErrors: [],
    searchOrdersResponse: {},
    searchOrdersErrors: [],
    searchRecipientsError: [],
    searchProductsErrors: [],
    searchProductsResults: [],
    statesList: getStatesList(),
    bulkOrderDetail: {
        products: [],
        shippingMethod: null,
        users: []
    },
    productForEdit: {
        id: null
    },
    userForEdit: {
        id: null,
        addresses: [],
        defaultAddress: {},
        shippingAddress: {},
        userRoleIds: [],
        attributes: {},
        manuallyAssign: null
    },
    announcementForEdit: {
        id: null,
        name: null,
        startDate: null,
        endDate: null,
        description: null,
        userRoleIds: []
    },
    userSearchResults: [],
    announcementSearchResults: [],
    users: [],
    lastInvSyncTime: null
};

export const state = { ...initialState };

export const actions = {
    async [ADD_BULK_ORDER_RECIPIENT]({ commit }, payload) {
        if (payload && payload.recipientId && !state.bulkOrderDetail.users.some(u => u.recipientId === payload.recipientId)) {
            const updatedList = state.bulkOrderDetail.users.concat(payload);

            commit(SET_BULK_ORDER_USERS, updatedList);
        }
    },
    async [APPROVE_ORDER]({ dispatch }, payload) {
        const response = await ApiService.post("api/v1/admin/order/" + payload + '/approve/');

        if (response.succeeded) {
            dispatch(GET_ORDER_DETAILS, payload);
        }

        // Return a response so the end users knows what happened 
        return response

    },
    async [REJECT_ORDER]({ dispatch }, payload) {
        const response = await ApiService.post("api/v1/admin/order/" + payload + '/reject/');

        if (response.succeeded) {
            dispatch(GET_ORDER_DETAILS, payload);
        }

        // Return a response so the end users knows what happened 
        return response

    },
    async [GET_ADMIN_MENU]({ commit }) {
        // TODO: pull from the backend
        const menu = AdminMenu;

        commit(SET_ADMIN_MENU, menu);
    },
    async [GET_ALL_PRODUCTS]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/admin/products?page-size=${payload.pageSize}&page=${payload.pageNumber}&include-total=${payload.includeTotal}`);

        if (response.succeeded) {
            commit(SET_ALL_PRODUCTS, {
                items: response.results,
                pageNumber: response.pageNumber,
                pageSize: response.pageSize,
                count: response.count,
                totalCount: response.totalCount
            });
        }
        else {
            commit(SET_GET_ALL_PRODUCTS_ERROR, response.messages);
        }
    },
    async [GET_ALL_ROLES]({ commit }) {
        const response = await ApiService.get("api/v1/admin/user-roles");

        if (response.succeeded) {
            commit(SET_ALL_ROLES, response.results);
        }
    },
    async [GET_BULK_ORDER_DETAIL]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/admin/bulk-order/${payload.id}`);

        if (response.succeeded) {
            commit(SET_GET_BULK_ORDER_DETAIL_RESPONSE, response);
        }
    },
    async [GET_USER_FOR_EDIT]({ commit }, id) {
        const response = await ApiService.get(`api/v1/admin/user/${id}`);

        if (response.succeeded) {
            commit(SET_USER_FOR_EDIT, response.user);
        }
    },
    async [GET_ANNOUNCEMENT_FOR_EDIT]({ commit }, id) {
        const response = await ApiService.get(`ui/admin/content-management/${id}`);

        if (response.succeeded) {
            commit(SET_ANNOUNCEMENT_FOR_EDIT, response.contentManagement);
        }
    },
    async [GET_PRODUCT_MEDIA]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/admin/product-media/${payload.productId}`);

        if (response.succeeded) {
            commit(SET_PRODUCT_MEDIA, response.media);
        }
        else {
            commit(SET_GET_PRODUCT_MEDIA_ERROR, response.messages);
        }
    },
    async [GET_ORDERS]({ commit }, payload) {
        const response = await ApiService.post("ui/store/orders/search", payload);

        if (response.succeeded) {
            commit(SET_ORDERS, response);
        } else {
            commit(SET_GET_ORDERS_ERRORS, response.messages);
        }
    },
    async [GET_ORDER_DETAILS]({ commit }, orderId) {

        const response = await ApiService.get('api/v1/admin/order/' + orderId);

        if (response.succeeded) {
            commit(SET_ORDER_DETAIL, response.order);
        }
        else {
            // TODO: add a system error?
        }
    },
    async [GET_PRODUCT_FOR_EDIT]({ commit }, payload) {
        const response = await ApiService.get(`api/v1/admin/product/${payload.productId}`);

        if (response.succeeded) {
            commit(SET_PRODUCT_FOR_EDIT, response.product);
        }
        else {
            commit(SET_GET_PRODUCT_FOR_EDIT_ERROR, response.messages);
        }
    },
    async [GET_AUDIT_FIELDS]({ commit }, payload) {
        const response = await ApiService.get(`/ui/admin/audits/${payload.entityType}/${payload.eventId}/activity`);

        if (response.succeeded) {
            commit(SET_AUDIT_FIELDS, response.activity);
        }
        else {
            commit(SET_AUDIT_FIELDS_ERROR, response.messages);
        }
    },
    async [GET_USERS]({ commit }, payload) {
        const query = PagingService.getPagingQueryString(payload);

        const response = await ApiService.get(`api/v1/admin/users?${query}`);

        if (response.succeeded) {
            commit(SET_USERS, response);
        }
    },
    async[INIT_BULK_ORDER]({ commit }) {
        commit(SET_SEARCH_RECIPIENTS_ERRORS, []);
        commit(SET_SEARCH_RECIPIENTS_RESULTS, []);

        commit(SET_BULK_ORDER_DETAIL, {
            products: [],
            shippingMethod: {},
            users: []
        });
    },
    async [MASS_APPROVE_ORDERS]({ dispatch }, payload) {
        const response = await ApiService.post("api/v1/admin/orders/approve", payload.massOrderList);
        if (response.succeeded) {
            dispatch(SEARCH_ORDERS, payload.filters[0]);
        }
        //Return a response so the end users knows what happened 
        return response;
    },
    async [MASS_REJECT_ORDERS]({ dispatch }, payload) {
        const response = await ApiService.post("api/v1/admin/orders/reject", payload.massOrderList);
        if (response.succeeded) {
            dispatch(SEARCH_ORDERS, payload.filters[0]);
        }
        // Return a response so the end users knows what happened 
        return response;

    },
    async [PLACE_BULK_ORDER]({ commit }, payload) {
        const response = await ApiService.post("ui/admin/bulk-orders", payload);

        commit(SET_PLACE_BULK_ORDER_RESPONSE, response);

        if (response.messages) {
            commit(SET_PLACE_BULK_ORDER_ERRORS, response.messages);
        }
    },
    async[REMOVE_PRODUCT_MEDIA]({ dispatch }, payload) {
        const response = await ApiService.delete(`api/v1/admin/product-media/${payload.productId}/${payload.mediaId}`);

        if (response.succeeded) {
            dispatch(GET_PRODUCT_MEDIA, { productId: payload.productId });
        }
    },
    async[REMOVE_BULK_ORDER_RECIPIENT]({ commit }, payload) {
        if (payload && payload.recipientId && state.bulkOrderDetail.users.some(u => u.recipientId === payload.recipientId)) {
            const updatedList = state.bulkOrderDetail.users.filter(u => u.recipientId !== payload.recipientId);

            commit(SET_BULK_ORDER_USERS, updatedList);
        }
    },
    async[SAVE_ORDER_INTERNAL_NOTES]({ commit }, payload) {
        const request = { "note": payload.internalNotes }

        const response = await ApiService.post('api/v1/admin/order/' + payload.orderId + '/note/', request);

        if (response.succeeded) {
            let notes = propertyGetter(state, "orderDetails", "notes");
            if (notes && notes.length > 0) {
                notes[0] = response.note;
            }
            else {
                notes = [response.note];
            }
            commit(SET_ORDER_PROPERTY, { name: "notes", value: notes });

        }
        // Return a response so the end users knows what happened 
        return response
    },
    async[SAVE_ORDER_PRODUCT_QUANTITIES]({ dispatch }, payload) {

        const response = await ApiService.put('api/v1/admin/order/' + payload.orderId + '/items/quantities/', payload);

        if (response.succeeded) {
            dispatch(GET_ORDER_DETAILS, payload.orderId);
        }

        // Return a response so the end users knows what happened 
        return response
    },
    async[SAVE_ORDER_SHIP_TO_ADDRESS]({ dispatch }, payload) {

        const response = await ApiService.put('api/v1/admin/order/' + payload.orderId + '/shipping-address/', payload);
        if (response.succeeded) {
            dispatch(GET_ORDER_DETAILS, payload.orderId);
        }
        // Return a response so the end users knows what happened 
        return response
    },
    async[SAVE_ORDER_SHIP_METHOD]({ dispatch }, payload) {

        const response = await ApiService.put('api/v1/admin/order/' + payload.orderId + '/shipping-method/', payload);

        if (response.succeeded) {
            dispatch(GET_ORDER_DETAILS, payload.orderId);
        }
        // Return a response so the end users knows what happened 
        return response
    },
    async[SAVE_PRODUCT]({ commit, dispatch, state }, payload) {
        let response = null;

        commit(SET_SAVE_PRODUCT_ERROR, null);

        if (payload.productId) {
            response = await ApiService.put(`api/v1/admin/product/${payload.productId}`, { product: state.productForEdit });
        }
        else {
            response = await ApiService.post(`api/v1/admin/products`, { product: state.productForEdit });
        }

        if (response.succeeded) {
            payload.productId = payload.productId ? payload.productId : response.id;
            await dispatch(GET_PRODUCT_FOR_EDIT, payload);
            commit(SET_IS_PRODUCT_EDIT_DIRTY, false);
        } else if (response.messages && response.messages.length > 0) {
            commit(SET_SAVE_PRODUCT_ERROR, response.messages);
        } else if (response.technicalDetail && response.technicalDetail.messages && response.technicalDetail.messages.length > 0) {
            commit(SET_SAVE_PRODUCT_ERROR, response.technicalDetail.messages);
        }
    },
    async[SEARCH_BULK_ORDER_RECIPIENTS]({ commit, state }, payload) {
        commit(SET_SEARCH_RECIPIENTS_RESULTS, null);

        const response = await ApiService.post("ui/admin/bulk-orders/recipients/search", payload);

        if (response.succeeded) {
            const existingSelectedRecipientIds = state.bulkOrderDetail.users.map(u => u.recipientId);

            response.results = response.results.map(r => ({ ...r, selected: existingSelectedRecipientIds.some(e => e === r.recipientId) }));

            commit(SET_SEARCH_RECIPIENTS_RESULTS, response);
        }
        else {
            commit(SET_SEARCH_RECIPIENTS_ERRORS, response.messages);
        }
    },
    async[SEARCH_BULK_ORDERS]({ commit }, payload) {
        commit(SET_SEARCH_BULK_ORDERS_RESPONSE, null);

        const response = await ApiService.post("api/v1/admin/bulk-orders/search", payload);

        if (response.succeeded) {
            commit(SET_SEARCH_BULK_ORDERS_RESPONSE, response);
        }
        else {
            commit(SET_SEARCH_BULK_ORDERS_ERRORS, response.messages);
        }
    },
    async[SEARCH_ORDERS]({ commit }, payload) {
        commit(SET_SEARCH_ORDERS_RESPONSE, null);

        const response = await ApiService.post("api/v1/admin/orders/search", payload);

        if (response.succeeded) {
            commit(SET_SEARCH_ORDERS_RESPONSE, response);
        }
        else {
            commit(SET_SEARCH_ORDERS_ERRORS, response.messages);
        }
    },
    async[SEARCH_PRODUCTS]({ commit }, payload) {
        payload.searchByUser = false;

        const response = await ApiService.post("ui/store/products/search", payload);

        if (response.succeeded) {
            commit(SET_PRODUCT_SEARCH_RESULTS, response.results);            
        }
        else {
            commit(SET_PRODUCT_SEARCH_ERROR, response.messages);
        }
    },

    async [SEARCH_ADMIN_PRODUCTS]({ commit }, payload) {
        payload.searchByUser = false;
        const response = await ApiService.post("ui/store/products/search", payload);

        if (response.succeeded) {
            commit(SET_ALL_PRODUCTS, {
                items: response.results,
                pageNumber: response.pageNumber,
                pageSize: response.pageSize,
                count: response.count,
                totalCount: response.totalCount
            });
        }
        else {
            commit(SET_PRODUCT_SEARCH_ERROR, response.messages);
        }
    },

    async [SEARCH_USERS]({ commit }, payload) {

        let url = "api/v1/admin/users/search";
        if (Array.isArray(payload)) {
            url = payload[1];
            payload = payload[0];
        }

        const response = await ApiService.post(url, payload);

        if (response.succeeded) {
            commit(SET_USER_SEARCH_RESULTS, response);
        }
    },

    async [SEARCH_ANNOUNCEMENTS]({ commit }, payload) {

        const response = await ApiService.post('ui/admin/content-managements/search', payload);
        if (response.succeeded) {
            commit(SET_ANNOUNCEMENT_SEARCH_RESULTS, response);
        }
    },

    async[UPDATE_BULK_ORDER_PRODUCTS]({ commit }, payload) {
        commit(SET_BULK_ORDER_PRODUCTS, payload.products);
    },
    async[UPDATE_BULK_ORDER_SHIPPING_METHOD]({ commit }, payload) {
        commit(SET_BULK_ORDER_SHIPPING_METHOD, payload.shippingMethod);
    },
    async[UPDATE_PRODUCT_MEDIA_SORT]({ dispatch }, payload) {
        const response = await ApiService.put("api/v1/admin/product-media", { productId: payload.productId, mediaItemIds: payload.mediaItemIds });

        if (response.succeeded) {
            dispatch(GET_PRODUCT_MEDIA, { productId: payload.productId });
        }
    },
    async [UPDATE_INVENTORY]() {   
        await ApiService.put("/api/integration/bls/v1/inventory");
    },
   
    async [GET_LAST_INV_SYNC_TIME]({ commit }) {
        const response = await ApiService.get("/api/integration/bls/v1/inventory");

        if (response) {
            commit(SET_LAST_INV_SYNC_TIME, response);
        }
    },
    async [SEND_WELCOME_EMAIL]({ commit }, payload) {

        const response = await ApiService.post("ui/account/send-welcome-email", { emails: payload });

        if (response.succeeded) {
            commit(SET_SEND_WELCOME_EMAIL, response);
        }
    },
    async [RESET_BULK_ORDER_ERRORS]({ commit }) {
        commit(SET_PLACE_BULK_ORDER_RESPONSE, {});
    },


    async [SAVE_ANNOUNCEMENT]({ commit, dispatch }, payload) {
        let announcementResponse;
        const request = {
            contentManagement: payload
        }
        if (payload.id) {
            announcementResponse = await ApiService.put(`ui/admin/content-management/${payload.id}`, request);
        }
        else {
            delete request.contentManagement['id'];
            announcementResponse = await ApiService.post(`ui/admin/content-managements`, request);
        }
        if (announcementResponse.succeeded) {
            commit(SET_SAVE_ANNOUNCEMENT_ERROR, null);
            await dispatch(GET_ANNOUNCEMENT_FOR_EDIT, announcementResponse.contentManagementId);
        }
        else {
            commit(SET_SAVE_ANNOUNCEMENT_ERROR, announcementResponse.messages);
        }
    },

    async [PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT]({ commit }, payload) {
        const request = {
            contentManagementId: payload.id,
            isPublished: payload.status
        }
        const response = await ApiService.put(`ui/admin/content-management/${payload.id}/publish`, request);
        if (!response.succeeded) {
            if (response.messages.length > 0) {
                commit(SET_SAVE_ANNOUNCEMENT_ERROR, response.messages);
            }
            else {
                commit(SET_SAVE_ANNOUNCEMENT_ERROR, "There was an error changing publish status");
            }
            commit(SET_IS_ANNOUNCEMENT_EDIT_DIRTY, false);
        }
    },

    async [DELETE_ANNOUNCEMENT]({ commit }, id) {
        const response = await ApiService.delete(`/ui/admin/content-management/${id}`);

        if (response.succeeded) {
            commit(SET_DELETE_ANNOUNCEMENT_RESPONSE, response);
        }
    },

};

export const getters = {
    [ADMIN_MENU_GETTER]: state => {
        return state.menu;
    },
    [ALL_PRODUCTS_GETTER]: state => {
        return state.allProducts;
    },
    [AUDIT_FIELDS_GETTER]: state => {
        return state.auditFields;
    },
    
    [ALL_ROLES_GETTER]: state => {
        return state.allRoles;
    },
    [BULK_ORDER_DETAIL_GETTER]: (state) => {
        return state.bulkOrderDetail;
    },
    [BULK_ORDER_USERS_GETTER]: (state) => {
        return state.bulkOrderDetail.users;
    },
    [EDIT_PRODUCT_STEPPER_GETTER]: (state) => {
        return state.editProductStepper;
    },
    [GET_BULK_ORDER_DETAIL_RESPONSE_GETTER]: (state) => {
        return state.getBulkOrderDetailResponse;
    },
    [IS_PRODUCT_EDIT_DIRTY_GETTER]: (state) => {
        return state.isProductEditDirty;
    },
    [IS_USER_EDIT_DIRTY_GETTER]: (state) => {
        return state.isUserEditDirty;
    },
    [IS_ANNOUNCEMENT_EDIT_DIRTY_GETTER]: (state) => {
        return state.isAnnouncementEditDirty;
    },
    [ORDER_DETAIL_GETTER]: state => {
        return state.orderDetails;
    },
    [ORDER_PROPERTY_GETTER]: (state) => (propName, attributeName) => {
        return propertyGetter(state, "orderDetails", propName, attributeName);
    },
    [ORDERS_GETTER]: (state) => {
        return state.orders;
    },
    [GET_ORDERS_ERRORS_GETTER]: (state) => {
        return state.getOrdersErrors;
    },
    [PLACE_BULK_ORDER_ERRORS_GETTER]: (state) => {
        return state.placeBulkOrderErrors;
    },
    [PLACE_BULK_ORDER_RESPONSE_GETTER]: (state) => {
        return state.placeBulkOrderResponse;
    },
    [PRODUCT_PROPERTY_GETTER]: (state) => (propName, attributeName) => {
        return propertyGetter(state, "productForEdit", propName, attributeName);
    },
    [PRODUCT_FOR_EDIT_GETTER]: state => {
        return state.productForEdit;
    },
    [RECIPIENTS_SEARCH_RESPONSE_GETTER]: state => {
        return state.bulkOrderRecipientsSearchResult;
    },
    [SAVE_PRODUCT_ERROR_GETTER]: (state) => {
        return state.saveProductError;
    },
    [SAVE_USER_ERROR_GETTER]: (state) => {
        return state.saveUserError;
    },
    [SAVE_ANNOUNCEMENT_ERROR_GETTER]: (state) => {
        return state.saveAnnouncementError;
    },
    [SEARCH_BULK_ORDERS_RESPONSE_GETTER]: (state) => {
        return state.searchBulkOrdersResponse;
    },
    [SEARCH_ORDERS_RESPONSE_GETTER]: (state) => {
        return state.searchOrdersResponse;
    },
    [SEARCH_PRODUCTS_RESULTS_GETTER]: (state) => {
        return state.searchProductsResults;
    },
    [STATES_LIST_GETTER]: state => {
        return state.statesList;
    },
    [USER_FOR_EDIT_GETTER]: state => {
        return state.userForEdit;
    },
    [ANNOUNCEMENT_FOR_EDIT_GETTER]: state => {
        return state.announcementForEdit;
    },    
    [USER_PROPERTY_GETTER]: (state) => (propName, attributeName) => {
        return propertyGetter(state, "userForEdit", propName, attributeName);
    },
    [USER_SEARCH_RESULTS_GETTER]: state => {
        return state.userSearchResults;
    },
    [ANNOUNCEMENT_SEARCH_RESULTS_GETTER]: state => {
        return state.announcementSearchResults;
    },
    [ANNOUNCEMENT_DELETERESPONSE_GETTER]: state => {
        return state.deleteAnnouncementResponse;
    },

    [USERS_GETTER]: state => {
        return state.users;
    },
    [LAST_INV_SYNC_TIME_GETTER]: state => {
        return state.lastInvSyncTime;
    },
        
};

export const mutations = {
    [SET_ADMIN_MENU]: (state, menu) => {
        state.menu = menu;
    },
    [SET_ALL_PRODUCTS]: (state, allProducts) => {
        state.allProducts = allProducts;
    },
    [SET_ALL_ROLES]: (state, allRoles) => {
        state.allRoles = allRoles;
    },
    [SET_BULK_ORDER_DETAIL]: (state, detail) => {
        state.bulkOrderDetail = detail;
    },
    [SET_BULK_ORDER_PRODUCTS]: (state, products) => {
        state.bulkOrderDetail.products = products;
        // Vue.set(state.bulkOrderDetail, 'products', products);
    },
    [SET_BULK_ORDER_SHIPPING_METHOD]: (state, shippingMethod) => {
        state.bulkOrderDetail.shippingMethod = shippingMethod;
    },
    [SET_BULK_ORDER_USERS]: (state, users) => {
        state.bulkOrderDetail.users = users;
    },
    [SET_EDIT_PRODUCT_STEPPER]: (state, stepper) => {
        state.editProductStepper = stepper;
    },
    [SET_GET_BULK_ORDER_DETAIL_RESPONSE]: (state, response) => {
        state.getBulkOrderDetailResponse = response;
    },
    [SET_GET_ORDERS_ERRORS]: (state, response) => {
        state.getOrdersErrors = response;
    },
    [SET_IS_PRODUCT_EDIT_DIRTY]: (state, value) => {
        state.isProductEditDirty = value;
    },
    [SET_IS_USER_EDIT_DIRTY]: (state, value) => {
        state.isUserEditDirty = value;
    },
    [SET_IS_ANNOUNCEMENT_EDIT_DIRTY]: (state, value) => {
        state.isAnnouncementEditDirty = value;
    },
    [SET_SAVE_PRODUCT_ERROR]: (state, value) => {
        propertySetter(state, "saveProductError", value);
    },
    [SET_ORDER_DETAIL]: (state, order) => {
        state.orderDetails = order;
    },
    [SET_ORDER_PROPERTY]: (state, payload) => {
        propertySetter(state, "orderDetails", payload);
    },
    [SET_ORDERS]: (state, payload) => {
        state.orders = payload;
    },
    [SET_PLACE_BULK_ORDER_ERRORS]: (state, value) => {
        state.placeBulkOrderErrors = value;
    },
    [SET_PLACE_BULK_ORDER_RESPONSE]: (state, value) => {
        state.placeBulkOrderResponse = value;
    },
    [SET_PRODUCT_FOR_EDIT]: (state, product) => {
        state.productForEdit = product;
    },
    [SET_AUDIT_FIELDS]: (state, value) => {
        state.auditFields = value;
    },
    [SET_PRODUCT_MEDIA]: (state, media) => {
        state.productForEdit.media = media;
    },
    [SET_PRODUCT_PROPERTY]: (state, payload) => {
        state.isProductEditDirty = true;
        propertySetter(state, "productForEdit", payload);
    },
    [SET_PRODUCT_SEARCH_ERROR]: (state, value) => {
        state.searchProductsErrors = value;
    },
    [SET_PRODUCT_SEARCH_RESULTS]: (state, value) => {
        state.searchProductsResults = value;
    },
    [SET_SAVE_USER_ERROR]: (state, value) => {
        state.saveUserError = value;
    },
    [SET_SAVE_ANNOUNCEMENT_ERROR]: (state, value) => {
        state.saveAnnouncementError = value;
    },
    [SET_DELETE_ANNOUNCEMENT_RESPONSE]: (state, value) => {
        state.deleteAnnouncementResponse = value;
    },
    [SET_SEARCH_BULK_ORDERS_ERRORS]: (state, messages) => {
        state.searchBulkOrdersErrors = messages;
    },
    [SET_SEARCH_BULK_ORDERS_RESPONSE]: (state, response) => {
        state.searchBulkOrdersResponse = response;
    },
    [SET_SEARCH_ORDERS_ERRORS]: (state, messages) => {
        state.searchOrdersErrors = messages;
    },
    [SET_SEARCH_ORDERS_RESPONSE]: (state, response) => {
        state.searchOrdersResponse = response;
    },
    [SET_SEARCH_RECIPIENTS_ERRORS]: (state, payload) => {
        state.searchRecipientsError = payload;
    },
    [SET_SEARCH_RECIPIENTS_RESULTS]: (state, payload) => {
        state.bulkOrderRecipientsSearchResult = payload;
    },
    [SET_STEPPER_CURRENT_STEP]: (state, payload) => {
        state.editProductStepper.currentStep = payload;
    },
    [SET_USER_ADDRESS]: (state, payload) => {
        state.isUserEditDirty = true;

        if (state.userForEdit.addresses && state.userForEdit.addresses.length > 0) {
            const index = state.userForEdit.addresses.findIndex(a => a.id === payload.id);

            state.userForEdit.addresses.splice(index, 1, payload);
        }
        else {
            state.userForEdit.addresses.splice(0, 0, payload);
        }
    },
    [SET_USER_ATTRIBUTE_PROPERTY]: (state, payload) => {
        state.isUserEditDirty = true;
        payload.attribute = ".";
        propertySetter(state, "userForEdit", payload);
    },
    [SET_USER_FOR_EDIT]: (state, userForEdit) => {
        state.userForEdit = userForEdit;
    },
    [SET_ANNOUNCEMENT_FOR_EDIT]: (state, announcementForEdit) => {
        state.announcementForEdit = announcementForEdit;
    },
    [SET_USER_PROPERTY]: (state, payload) => {
        state.isUserEditDirty = true;
        propertySetter(state, "userForEdit", payload);
    },
    [SET_ANNOUNCEMENT_PROPERTY]: (state, payload) => {
        state.isAnnouncementEditDirty = true;
        propertySetter(state, "announcementForEdit", payload);
    },
    [SET_USER_ROLES]: (state, payload) => {
        state.isUserEditDirty = true;
        state.userForEdit.userRoleIds = payload;
    },
    [SET_USER_SEARCH_RESULTS]: (state, userSearchResults) => {
        state.userSearchResults = userSearchResults;
    },
    [SET_ANNOUNCEMENT_SEARCH_RESULTS]: (state, announcementSearchResults) => {
        state.announcementSearchResults = announcementSearchResults;
    },
    [SET_USERS]: (state, users) => {
        state.users = users;
    },
    [SET_LAST_INV_SYNC_TIME]: (state, lastInvSyncTime) => {
        state.lastInvSyncTime = lastInvSyncTime;
    }
};

function propertyGetter(state, stateObjectName, propName, attributeName) {

    if (attributeName) {
        if (Array.isArray(state[stateObjectName][attributeName])) {
            return state[stateObjectName].attributes[attributeName][0][propName];
        }
        else {
            if (state[stateObjectName].attributes[attributeName]) {
                return state[stateObjectName].attributes[attributeName][propName];
            } else {
                return "";
            }
        }
    }
    else if (Object.keys(state[stateObjectName]).includes(propName)) {
        return state[stateObjectName][propName];
    }
    else {
        const entries = Object.entries(state[stateObjectName]);

        for (const [key, val] of entries) {
            let set = null;

            if (Array.isArray(val)) {
                set = Object.getOwnPropertyNames(state[stateObjectName][key][0]);

                if (set.includes(propName)) {
                    return state[stateObjectName][key][0][propName];
                }
            }
            else {
                if (state[stateObjectName] && state[stateObjectName][key]) {
                    set = Object.getOwnPropertyNames(state[stateObjectName][key]);

                    if (set.includes(propName)) {
                        return state[stateObjectName].attributes[key][propName];
                    }
                } else {
                    return "";
                }
            }
        }
    }
}

function propertySetter(state, stateObjectName, payload) {
    if (!payload) { state[stateObjectName] = payload; }
    else {
        if (payload.attribute && payload.attribute.length > 0) {
            if (payload.attribute === ".") {
                state[stateObjectName].attributes[payload.name] = payload.value;
            }
            else if (state[stateObjectName].attributes[payload.attribute]) {
                if (Array.isArray(state[stateObjectName].attributes[payload.attribute])) {
                    state[stateObjectName].attributes[payload.attribute][0][payload.name] = payload.value;
                }
                else {
                    state[stateObjectName].attributes[payload.attribute][payload.name] = payload.value;
                }
            } else {
                var attrs = {};
                attrs[payload.name] = payload.value;
                state[stateObjectName].attributes[payload.attribute] = attrs;
            }
        }
        else {
            if (payload.name) {
                state[stateObjectName][payload.name] = payload.value;
            }
            else { state[stateObjectName] = payload; }
        }
    }
    state[stateObjectName] = Object.assign({}, state[stateObjectName]);
}

export default {
    state,
    actions,
    getters,
    MODULE_ID,
    mutations
};
