<template>
    <div class="n-order-detail-header">
        <div class="row">
            <div class="col-12">
                <PageHeaderTitle title="Order Detail" text="Back" @cancel="cancel" />
                <span class="mx-2">
                    <h2>
                        Order # {{orderDetails.orderNumber}}
                        <span class="badge badge-pill" :class="orderStatus.class">{{orderStatus.status}}</span>
                        <span class="badge badge-pill" :class="isShipped.class">{{isShipped.status}}</span>
                    </h2>
                </span>
            </div>
            <div class="col-12" v-if="hasHoldReasons">
                Reason for Hold
                <span v-for="reason in getReasonsForHold(orderDetails.messages)" :key="reason">
                    <span class="mx-1">|</span>
                    <span class="reason-text">
                        {{ reason }}
                    </span>
                </span>
            </div>
            <div class="col-12 text-right" v-if="isOrderPendingApproval">
                <div class="actions">
                    <button class="btn btn-outline-success mr-3" @click="rejectOrder()">
                        Reject Order
                    </button>
                    <button class="btn btn-success" @click="approveOrder()">
                        Approve Order
                    </button>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { ORDER_DETAIL_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";  
    import PageHeaderTitle from "@/nucleus-modules/dd-nucleus-admin/components/PageHeaderTitle.vue";
    import { AdminEvents, OrderStatus, PaymentStatus } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
    import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";
    import { OrderApprovalsMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrderApprovalsMixin.js";
    import { OrderApprovalReasons } from "@/nucleus-modules/dd-nucleus-admin/constants";
    export default {
        name: 'OrderDetailHeader',
        mixins: [OrderApprovalsMixin],      
        components: {           
            PageHeaderTitle
        },
        data() {
            return {
               OrderApprovalReasons:OrderApprovalReasons
            }
        },
        computed: {
            ...mapGetters({
                orderDetails: ORDER_DETAIL_GETTER
            }),
            isPaid() {
                return this.orderDetails.paymentStatus == PaymentStatus.COMPLETED ? { "class": "badge-paid", "status": "Paid" } : { "class": "badge-not-paid", "status": "Not Paid" };
            },
            isShipped() {
                if (this.orderDetails.shipments && this.orderDetails.shipments.length > 0 && this.orderDetails.shipments[0].shipDate.length > 0) {
                    return { "class": "badge-shipped", "status": "Shipped" };
                }
                else {
                    return { "class": "badge-not-shipped", "status": "Not Shipped" };
                }
            },
            isOrderPendingApproval() {
                return this.orderDetails.orderStatus == OrderStatus.PENDING_APPROVAL;
            },
            hasHoldReasons() {
                return this.orderDetails.messages 
                && this.orderDetails.messages.length > 0 
                && this.orderDetails.messages[0].parameters
                && this.orderDetails.messages[0].parameters.length > 0;
            },
            orderStatus() {
                switch (this.orderDetails.orderStatus) {
                    case OrderStatus.NONE:
                        return { "class": "badge-pending", "status": "None" };
                    case OrderStatus.PENDING:                        
                        return { "class": "badge-pending", "status": "Pending" };
                    case OrderStatus.PENDING_APPROVAL:
                        return { "class": "badge-pending-approval", "status": "Pending Approval" };
                    case OrderStatus.COMPLETED:
                        return { "class": "badge-complete", "status": "Completed" };
                    case OrderStatus.CANCELLED:
                        return { "class": "badge-cancelled", "status": "Cancelled" };
                    default:
                        return { "class": "badge-cancelled", "status": "UNKNOWN" };
                }
            }
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            getReasonsForHold(messages) {
                if (messages && messages.length > 0) {
                    let reasons =  messages[0].parameters;
                    if(reasons && reasons.length) {
                        reasons = reasons.map(r => {
                            let orderApprovalReason =  OrderApprovalReasons.find(oar => oar.id == r);
                            if(orderApprovalReason){
                                return orderApprovalReason.name;
                            }
                            return "";
                        });

                        return reasons;
                    }
                }
                else {
                    return [];
                }
            },
            async rejectOrder() {
                const confirm = new ConfirmationBoxService({
                    title: "Reject Order",
                    cancelTitle: "Cancel",
                    cancelVariant: "primary",
                    okTitle: "Reject",
                    okVariant: "danger",
                    size: "md",
                });

                const confirmed = await confirm.showConfirm(
                    "Are you sure you want to reject this order?"
                );

                if (confirmed) {
                    this.$emit(AdminEvents.REJECT_ORDER, this.orderDetails.id);
                }
            },
            async approveOrder() {
                const confirm = new ConfirmationBoxService({
                    title: "Approve Order",
                    cancelTitle: "Cancel",
                    cancelVariant: "primary",
                    okTitle: "Approve",
                    okVariant: "danger",
                    size: "md",
                });

                const confirmed = await confirm.showConfirm(
                    "Are you sure you want to approve this order?"
                );

                if (confirmed) {
                    this.$emit(AdminEvents.APPROVE_ORDER, this.orderDetails.id);
                }
            },           
        },
    }
</script>

<style lang="scss" scoped>
    .n-order-detail-header {
        h1 {
            font-size: 1.25rem;
            color: $primary-color;
        }

        h1 a {
            font-size: 1rem;
            color: $primary-light-color;
            text-decoration: underline;
        }

        h2 {
            font-size: 1rem;
            color: $green;
        }
    }

    .reason-text {
        font-size: 1rem;
        color: $red;
    }
</style>