<template>
  <div class="row">
    <div class="col-12">
      <HeaderBranding
        image-url="/images/logo.png"
        alt="Regeneron"
        class="text-center n-footer-logo"
      />
    </div>
  </div>
</template>

<script>
import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";

export default {
  name: "FooterLogo",
  components: {
    HeaderBranding,
  },
};
</script>

<style scoped lang="scss">
.n-footer-logo{
  max-width: 200px;
  margin: 0 auto;
}
</style>
