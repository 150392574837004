<template>
  <div class="row n-all-order-approvals">
    <div class="col-12">
      <div class="row">
        <div class="col-5">
          <h4>Order Approvals</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <select class="form-control" v-model="selectedOrderStatus">
            <option value="">Select Action</option>
            <option v-for="status in OrderApprovalStatus" :key="status.code">
              {{ status.text }}
            </option>
          </select>
        </div>
        <div class="col-5">
          <router-link to="/admin/all-orders">
            View All Order Approvals
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <VueGoodTable
              class="table table-striped"
              @on-selected-rows-change="selectionChanged"
              :columns="columns"
              :rows="orders"
              :search-options="searchConfig"
              :sort-options="sortConfig"
              :pagination-options="pagingConfig"
              :select-options="{ enabled: true }"
            >
              <div slot="selected-row-actions">
                <div class="actions">
                  <button
                    class="btn btn-outline-success mr-3"
                    @click="rejectOrders()"
                  >
                    Reject Selected
                  </button>
                  <button class="btn btn-success" @click="approveOrders()">
                    Approve Selected
                  </button>
                </div>
              </div>
              <div slot="emptystate">There are no orders to approve.</div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'reasonsForHold'">
                  <span
                    v-for="reason in getReasonsForHold(
                      props.row.reasonsForHold
                    )"
                    :key="reason"
                    ><div>{{ reason }}</div></span
                  >
                </span>
                <span v-else-if="props.column.type === 'date'">
                  {{
                    props.formattedRow[props.column.field] | moment(dateFormat)
                  }}
                  <br />
                  {{
                    props.formattedRow[props.column.field] | moment(timeFormat)
                  }}
                </span>
                <span v-else>
                  <span v-if="props.column.field === 'orderNumber'">
                    <router-link :to="`order/${props.row.id}`">
                      {{ props.row.orderNumber }}
                    </router-link>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </span>
              </template>
            </VueGoodTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { OrderApprovalsMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/OrderApprovalsMixin.js";
import {
  StatusCodes,
  OrderFilters,
  OrderApprovalStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";
import {
  MASS_APPROVE_ORDERS,
  MASS_REJECT_ORDERS,
} from "../../../nucleus-modules/dd-nucleus-admin/store/actions.type";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { OrderApprovalReasons } from "@/nucleus-modules/dd-nucleus-admin/constants";
export default {
  name: "AllOrderApprovals",
  mixins: [OrderApprovalsMixin],
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [],
      dateFormat: "MM/DD/YYYY",
      timeFormat: "LT",
      isLoading: true,
      pagingConfig: {},
      searchConfig: {},
      sortConfig: {},
      statusCodes: StatusCodes,
      OrderApprovalStatus: OrderApprovalStatus,
      selectedOrderStatus: "",
      massOrderList: [],
      OrderApprovalReasons: OrderApprovalReasons,
    };
  },
  computed: {
    orders() {
      if (this.ordersSearchResponse && this.ordersSearchResponse.results) {
        return this.ordersSearchResponse.results;
      } else {
        return [];
      }
    },
    hasOrders() {
      return this.orders.length > 0;
    },
  },
  methods: {
    ...mapActions({
      massApproveOrders: MASS_APPROVE_ORDERS,
      massRejectOrders: MASS_REJECT_ORDERS,
    }),
    setColumns() {
      this.columns.push({
        label: "Order #",
        field: "orderNumber",
        type: "string",
      });
      this.columns.push({
        label: "Reason for Hold",
        field: "reasonsForHold",
        type: "string",
        sortable: false,
      });
      this.columns.push({
        label: "User ID",
        field: "userName",
        type: "string",
      });
      this.columns.push({
        label: "Primary Role",
        field: "primaryRole",
        type: "string",
      });
      this.columns.push({
        label: "First Name",
        field: "user.firstName",
        type: "string",
      });
      this.columns.push({
        label: "Last Name",
        field: "user.lastName",
        type: "string",
      });
      this.columns.push({
        label: "Order Date",
        field: "orderDate",
        type: "date",
        formatFn: function (value) {
          return value == null ? "" : value;
        },
      });
    },
    setPagingConfig() {
      this.pagingConfig = {
        enabled: true,
        mode: "records",
        perPage: 200,
        position: "top",
        perPageDropdown: [25, 50, 75, 100, 200, 1000],
        dropdownAllowAll: false,
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
      };
    },
    setSearchConfig() {
      this.searchConfig = {
        enabled: true,
        placeholder: "Search Order Number, User ID, Email Address, Name",
      };
    },
    setSortConfig() {
      this.sortConfig = {
        enabled: true,
      };
    },
    selectionChanged(payload) {
      if (payload && payload.selectedRows && payload.selectedRows.length > 0) {
        payload.selectedRows.forEach((r) => {
          if (!this.massOrderList.includes(r.id)) {
            this.massOrderList.push(r.id);
          }
        });
      } else {
        this.massOrderList = [];
      }
    },
    getReasonsForHold(reasons) {
      if (reasons) {
        let reasonsArray = reasons.split(",");
        reasonsArray = reasonsArray.sort();
        reasonsArray = reasonsArray.map((r) => {
          let orderApprovalReason = OrderApprovalReasons.find(
            (oar) => oar.id == r
          );
          if (orderApprovalReason) {
            return orderApprovalReason.name;
          }
          return "";
        });
        return reasonsArray;
      } else {
        return [];
      }
    },
    async rejectOrders() {
      const confirm = new ConfirmationBoxService({
        title: "Reject Orders",
        cancelTitle: "Cancel",
        cancelVariant: "primary",
        okTitle: "Reject",
        okVariant: "danger",
        size: "md",
      });

      const confirmed = await confirm.showConfirm(
        "Are you sure you want to reject these orders?"
      );
      const payload = { filters: [], massOrderList: [] };
      this.massOrderList.forEach(function (item) {
        payload.massOrderList.push(item);
      });
      payload.filters.push({
        filters: [
          { filterId: OrderFilters.STATUS, values: ["pending-approval"] },
        ],
      });

      if (confirmed) {
        var rejectOrdersResponse = await this.massRejectOrders(payload);

        if (rejectOrdersResponse.succeeded) {
          ToastService.showToast(
            "The orders are mass rejected successfully.",
            MessageType.SUCCESS
          );
        } else {
          ToastService.showToast(
            "The orders are not rejected successfully..  Please try again.",
            MessageType.ERROR
          );
        }
      }
    },
    async approveOrders() {
      const confirm = new ConfirmationBoxService({
        title: "Approve Orders",
        cancelTitle: "Cancel",
        cancelVariant: "primary",
        okTitle: "Approve",
        okVariant: "danger",
        size: "md",
      });

      const confirmed = await confirm.showConfirm(
        "Are you sure you want to approve these orders?"
      );
      const payload = { filters: [], massOrderList: [] };
      this.massOrderList.forEach(function (item) {
        payload.massOrderList.push(item);
      });
      payload.filters.push({
        filters: [
          { filterId: OrderFilters.STATUS, values: ["pending-approval"] },
        ],
      });

      if (confirmed) {
        var approveOrdersResponse = await this.massApproveOrders(payload);

        if (approveOrdersResponse.succeeded) {
          ToastService.showToast(
            "The orders are mass approved successfully.",
            MessageType.SUCCESS
          );
        } else {
          ToastService.showToast(
            "The orders are not approved successfully..  Please try again.",
            MessageType.ERROR
          );
        }
      }
    },
  },
  mounted() {
    this.setColumns();
    this.setSearchConfig();
    this.setSortConfig();

    this.isLoading = false;
  },
  created(){
    this.setPagingConfig();
  },
};
</script>

<style lang="scss" scoped>
thead {
  background-color: $primary-color;
  color: $admin-white-color;
}

.icon {
  font-size: 20px;
}

.pencil {
  color: $primary-color;
}

.n-all-order-approvals .active {
  color: $green;
}

.new {
  color: $tertiary-light-color;
}

.inactive {
  color: $gray-2;
}



   ::v-deep .vgt-selection-info-row {
        background: $admin-lightest-green;
        color: $admin-neutral-darker;
        font-size:17px;
    }

    ::v-deep input[type="checkbox"] {
        height: 20px !important;
        width: 40px !important;
    }

    ::v-deep .vgt-checkbox-col {
        padding: 1em !important;
    }
</style>
