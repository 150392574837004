<template>
    <div class="n-product-tab">
        <span class="mt-2" v-if="!$nucleus.isEmpty(rejectMessage)">
            <b-card border-variant="danger" text-variant="white" class="text-left">
                <b-card-text>{{ rejectMessage }}</b-card-text>
            </b-card>
        </span>
        <h4 class="mt-4">
            Products
            <span>
                Total: {{ numberOfProducts }} products
                <font-awesome-icon @click="emitEvent" class="ml-2" :icon="['fas', 'pencil']" />
            </span>
        </h4>
        <VueGoodTable :columns="columns" :rows="orderItems" :sort-options="sortConfig" styleClass="vgt-table striped"
                      class="mb-5">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'quantity'">
                    <span v-if="!isEdit">
                        {{ props.row.quantity }}
                    </span>
                    <span v-else>
                        <b-form-input v-model="props.row[props.column.field]" type="text"
                                      @change="updateQuantity(props.row[props.column.field], props.column.field, props.row.originalIndex)">
                        </b-form-input>

                    </span>
                </span>
                <span v-else-if="isPricingEnabled && props.column.field === 'unitPrice'">
                    ${{ props.row.unitPrice }}
                </span>
                <span v-else-if="props.column.field === 'product.salePrice'">
                    <span v-if="!$nucleus.isEmpty(props.row.product.salePrice)">$</span>{{ props.row.product.salePrice }}
                </span>
                <span v-else-if="props.column.field === 'subTotal'">
                    ${{ props.row.subtotal }}
                </span>
                <span v-else-if="props.column.field === 'taxRate'">
                    <!--
                        TODO: We have not built the taxable vs non taxable yet for the line item so couldn't implement this
                        {{props.row.taxRate}}%
                    -->
                </span>
                <span v-else-if="props.column.field === 'status'">
                    <span v-html="orderStatus(props.row.status)"></span>
                </span>
                <span v-else-if="props.column.field === 'lastShippedDate'">
                    {{ props.row.lastShippedDate | moment(dateFormat) }}
                </span>
                <span v-else-if="props.column.field === 'messages'">
                    <span class="n-product-approval-reason">{{ productApprovalReason(props.row.messages) }}</span>
                </span>
                <span v-else>
                    <span v-if="props.column.field === 'product.itemNumber'">
                        <router-link :id="props.row.product.id" :title="props.row.product.itemNumber"
                                     :to="`/admin/edit-product?productId=${props.row.product.id}`" target="_blank">
                            {{ props.row.product.itemNumber }}
                        </router-link>
                    </span>
                    <span v-else-if="props.column.field === 'product.name'">
                        {{ props.row.product.name }}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </span>
            </template>
        </VueGoodTable>
        <div class="row" v-if="isEdit">
            <div class="col-12 text-right mb-3">
                <button class="btn btn-outline-primary mr-2" @click="cancel">Cancel</button>
                <button class="btn btn-primary" @click="save">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { ORDER_DETAIL_GETTER, ORDER_PROPERTY_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SAVE_ORDER_PRODUCT_QUANTITIES } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
    import { ItemStatus } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
    import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table';
    import { OrderApprovalReasons } from "@/nucleus-modules/dd-nucleus-admin/constants";

    export default {
        name: 'ProductTab',
        components: {
            VueGoodTable,
        },
        props: {
            isPricingEnabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                columns: [],
                sortConfig: {},
                dateFormat: "MM/DD/YYYY",
                isEdit: false,
                OrderApprovalReasons: OrderApprovalReasons,
                rejectMessage: ""
            }
        },
        computed: {
            ...mapGetters({
                orderDetails: ORDER_DETAIL_GETTER,
                getOrderProperty: ORDER_PROPERTY_GETTER
            }),
            orderItems() {
                return this.getOrderProperty("items");
            },
            numberOfProducts() {
                return this.orderItems.length;
            }
        },
        methods: {
            ...mapActions({
                saveProductQuantities: SAVE_ORDER_PRODUCT_QUANTITIES
            }),
            setColumns() {
                this.columns.push({
                    label: 'Product #',
                    field: 'product.itemNumber',
                    type: "string",
                    width: '190px',
                });
                this.columns.push({
                    label: "Product Name",
                    field: "product.name",
                    type: "string",
                    width: '300px',
                });
                this.columns.push({
                    label: "Reason for Approval",
                    field: "messages",
                    type: "string",
                    width: '250px',
                });
                this.columns.push({
                    label: "Ordered Quantity",
                    field: "quantity",
                    type: "number",
                    width: '100px',
                    tdClass: 'text-center'
                });
                this.columns.push({
                    label: "Unit of Measure",
                    field: "unitOfMeasure",
                    type: "string",
                    width: '100px',
                });
                this.columns.push({
                    label: "Pieces Per Unit",
                    field: "quantityPerUnit",
                    width: '100px',
                    type: "number",
                    tdClass: 'text-left'
                });
                this.columns.push({
                    label: "Unit Price",
                    field: "unitPrice",
                    width: '90px',
                    type: "number",
                    tdClass: 'text-left',
                    hidden: !this.isPricingEnabled
                });
                this.columns.push({
                    label: "Sale Price",
                    field: "product.salePrice",
                    width: '90px',
                    type: "number",
                    tdClass: 'text-left',
                    hidden: !this.isPricingEnabled
                });
                this.columns.push({
                    label: "Total Amount",
                    field: "subTotal",
                    width: '90px',
                    type: "number",
                    tdClass: 'text-left',
                    hidden: !this.isPricingEnabled
                });
                this.columns.push({
                    label: "Tax Rate",
                    field: "taxRate",
                    width: '80px',
                    type: "number",
                    tdClass: 'text-left',
                    hidden: !this.isPricingEnabled
                });
                this.columns.push({
                    label: "Shipped Quantity",
                    field: "shipped",
                    width: '100px',
                    type: "number",
                    tdClass: 'text-left'
                });
                this.columns.push({
                    label: "Item Status",
                    field: "status",
                    width: '110px',
                });
                this.columns.push({
                    label: "Ship Date",
                    field: "lastShippedDate",
                    type: "string",
                    width: '110px',
                    tdClass: 'text-left'
                });
            },
            updateQuantity(changedData, column, row) {
                this.orderItems[row][column] = changedData
            },
            emitEvent() {
                this.$eventBus.$emit("editQuantity");
            },
            cancel() {
                this.isEdit = false;
            },
            edit() {
                this.isEdit = true;
            },
            setSortConfig() {
                this.sortConfig = {
                    enabled: true,
                };
            },
            async save() {

                if (this.orderItems && this.orderItems.length == 1 && this.orderItems[0].quantity == 0) {
                    this.rejectMessage = "You are trying to cancel an item from an order with only one line item.  Reject the order if you would like to proceed. ";
                }
                else {
                    this.rejectMessage = "";

                    let orderProductQuantities = [];

                    this.orderItems.forEach((i) => {
                        orderProductQuantities.push({
                            productId: i.product.id,
                            quantity: i.quantity
                        });
                    });

                    var payload = { orderId: this.orderDetails.id, productQuantities: orderProductQuantities };

                    var response = await this.saveProductQuantities(payload);

                    if (response.succeeded) {
                        ToastService.showToast(
                            "The product quantities are successfully saved.",
                            MessageType.SUCCESS
                        );
                    }
                    else {
                        ToastService.showToast(
                            "The product quantities could not be saved.  Please try again.",
                            MessageType.ERROR
                        );
                    }
                    // Set the view back to view mode
                    this.isEdit = false;
                }
            },
            orderStatus(shipStatus) {
                switch (shipStatus) {
                    case ItemStatus.PENDING:
                        return '<span class="badge badge-pill badge-pending" >Pending</span>';
                    case ItemStatus.CANCELED:
                        return '<span class="badge badge-pill badge-cancelled">Canceled</span>';
                    case ItemStatus.DELIVERED:
                        return '<span class="badge badge-pill badge-complete">Completed</span>';
                    case ItemStatus.REMOVED:
                        return '<span class="badge badge-pill badge-cancelled">Removed</span>';
                    default:
                        return '<span class="badge badge-pill badge-cancelled">UNKNOWN</span>';
                }
            },
            productApprovalReason(messages) {
                if (messages && messages.length) {
                    let message = messages[0].messageId;
                    let orderApprovalReason = OrderApprovalReasons.find(oar => oar.id == message && !oar.isOrderLevel);
                    if (orderApprovalReason) {
                        return orderApprovalReason.name;
                    }
                    return ""
                }
                return ""
            }
        },
        mounted() {
            this.setColumns();
            this.setSortConfig();
            this.$eventBus.$on('editQuantity', () => {
                this.edit();
            });
        },
        beforeDestroy() {
            this.$eventBus.$off('editQuantity');
        },
    }
</script>

<style lang="scss" scoped>
    .n-product-tab {
        font-size: 1rem !important;
    }

    .n-product-tab {
        h4 span {
            color: $gray-3;
            font-family: $body-font;
            font-size: 1rem;
            line-height: 1.5;
        }
    }

    .n-product-approval-reason {
        color: #b63f29;
        font-family: 700 !important;
    }

    table.vgt-table {
        font-size: 1rem;
    }

    .card-body {
        padding: 0.5rem;
        background-color: $gray-1
    }

    .card-body p {
        font-size: 1rem;
        font-weight: 700;
        color: #dc3545;
    }
</style>