<template>
  <Panel class="favorites-panel" title="My Favorites">
    <template v-if="!isLoading">
      <template v-if="(products && products.length)">
        <FavoritesList 
        :links="products" 
        :isProduct="true"
        :openInNewTab="true"
        />
        <div class="text-center">
          <button class="btn btn-secondary my-3 router-link-active" v-if="showAllLink" @click="navigateToProducts()">View My Favorites</button>
        </div>
      </template>
      <template v-else>
        <p class="n-no-results n-align">No items have been added to your Favorites.</p>
        <p class="n-no-results n-align">
            <span class="n-favorite">
              <font-awesome-icon class="n-heart-icon" :icon="['far', 'heart']" ></font-awesome-icon>
            </span>
        </p>
        <p class="n-no-results">To add items to "My Favorites" click the favorite icon located next to each item.</p>
      </template>
    </template>
  </Panel>
</template>
<script>
  import Panel from "@/nucleus-modules/dd-nucleus-ui/components/Panel";
  import FavoritesList from "@/components/home/LinksList";
  import {StorefrontEvents, TagNames} from "@/nucleus-modules/dd-nucleus-storefront/constants.js"
  export default {
    name: "favorites-panel",
    props: {
      products: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: false
      },
      showAllLink: {
        type:Boolean,
        required:false
      }
    },
    components: {
      Panel,
      FavoritesList
    },
    methods: {
      navigateToProducts() {
        const query = { tags: TagNames.FAVORITE }
        this.$eventBus.$emit(StorefrontEvents.NAVIGATE_TO_PRODUCTS, query);
      },
    }
  }
</script>
<style scoped lang="scss">
.btn {
    font-size: 1rem;
  }  
  .n-favorite {
      display: inline-block;
      color: $secondary-color;
      font-size: 20px;
      margin-top: 4px;
    }
    .n-align {
      margin-bottom: 0.25rem;
    }
</style>