<template>
  <PageTemplate :showHeader="false">
    <ForgotPassword/>
  </PageTemplate>
</template>

<script>
import PageTemplate from "@/pages/templates/PageTemplate";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";

export default {
  name: "ForgotPasswordPage",
  components: {
    PageTemplate,
    ForgotPassword
  } 
}
</script>
