<template>
  <div id="bulkOrdersHome">
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <h1>Bulk Orders</h1>
            <div class="shaded">
              <div class="row">
                <div class="col-8">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="createBulkOrder()"
                  >
                    Create Bulk Order
                  </button>
                  <!-- Hiding this for now, functionality to come later -->
                  <!-- <button
                type="button"
                class="btn btn-outline-primary"
                @click="manageQueryLists()"
              >
                Manage Query Lists
              </button> -->
                </div>
                <div class="col-4 text-right">
                  <div class="input-group input-group-sm search">
                    <input
                      type="text"
                      v-model="searchTerm"
                      class="form-control n-search-input"
                      placeholder="Search Bulk Order Number"
                      v-on:keyup="searchTermChanged"
                    />
                    <div class="input-group-append">
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="searchBulkOrders"
                      >
                        <font-awesome-icon
                          icon="search"
                          fixed-width
                        ></font-awesome-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col d-flex justify-content-end">
                  <b-pagination
                    v-model="pagerPageNumber"
                    :total-rows="paging.totalCount"
                    :per-page="paging.pageSize"
                    aria-controls="bulkOrdersTable"
                    @change="updatePage"
                  ></b-pagination>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <VueGoodTable
                    mode="remote"
                    class="table table-striped"
                    :columns="columns"
                    :rows="bulkOrders"
                  >
                    <div slot="emptystate">
                      There are no bulk orders that match the supplied criteria.
                    </div>
                    
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.type === 'date'">
                        {{
                          props.formattedRow[props.column.field]
                            | moment(dateFormat)
                        }}
                        <br />
                        {{
                          props.formattedRow[props.column.field]
                            | moment(timeFormat)
                        }}
                      </span>
                      <span v-else-if="props.column.field === 'orderNumber'">
                        <a href="#" @click="showDetail(props.row.id)">{{
                          props.row.orderNumber
                        }}</a>
                      </span>
                      <span v-else-if="props.column.field === 'orderStatus'">
                        <span
                          class="badge badge-pill"
                          :class="
                            getOrderStatusBadgeInfo(props.row.orderStatus)
                              .className
                          "
                        >
                          {{
                            getOrderStatusBadgeInfo(props.row.orderStatus)
                              .statusName
                          }}
                        </span>
                      </span>
                      <span v-else-if="props.column.field === 'shippingStatus'">
                        <span
                          class="badge badge-pill"
                          :class="
                            getShippingStatusBadgeInfo(props.row.shippingStatus)
                              .className
                          "
                        >
                          {{
                            getShippingStatusBadgeInfo(props.row.shippingStatus)
                              .statusName
                          }}
                        </span>
                      </span>
                    </template>
                  </VueGoodTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="bulk-order-detail">
          <div class="col">
            <b-collapse
              id="detailCollapse"
              v-model="isDetailShowing"
              class="mt-2 mb-2"
            >
              <div class="n-bulk-order-detail">
                <div class="d-flex justify-content-between">
                  <h3>Bulk Order Information</h3>
                  <button
                    type="button"
                    class="close"
                    @click="isDetailShowing = false"
                  >
                    &times;
                  </button>
                </div>
                <div class="mt-5">
                  <BulkOrderDetail></BulkOrderDetail>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";
import { PagingMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/PagingMixin.js";
import BulkOrderDetail from "@/components/admin/bulk-orders/BulkOrderDetail.vue";

import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "BulkOrdersHome",
  mixins: [BulkOrdersMixin, PagingMixin],
  components: {
    VueGoodTable,
    BulkOrderDetail,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentPage: 1,
      dateFormat: "MM/DD/YYYY",
      isDetailShowing: false,
      pagerPageNumber:1,
      perPage: 7,
      searchTerm: "",
      sortBy: "orderNumber",
      sortDesc: false,
      statusCodes: StatusCodes,
      timeFormat: "hh:mm:ss a",
      columns: [
        { label: "Bulk Order #", field: "orderNumber", type: "string", width: '150px' },
        { label: "Order Status", field: "orderStatus", type: "string" },
        { label: "Shipping", field: "shippingStatus", type: "string" },
        {
          label: "Ordered By",
          field: "createdBy",
          type: "string",
        },
        {
          label: "# of Recipients",
          field: "numberOfRecipients",
          type: "number",
          width: '150px'
        },
        {
          label: "Create Date",
          field: "created",
          type: "date",
          formatFn(value) {
            return value == null ? "" : value;
          },
        },
      ],
      paging: {
        includeTotalCount: true,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 5,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
      },
    };
  },
  computed: {
    bulkOrders() {
      if (
        this.bulkOrdersSearchResponse &&
        this.bulkOrdersSearchResponse.results
      ) {
        this.setPaging(this.bulkOrdersSearchResponse);
        return this.bulkOrdersSearchResponse.results;

      } else {
        return [];
      }
    },
    hasBulkOrders() {
      return this.bulkOrders.length > 0;
    },
    hasSearchTerm() {
      return this.searchTerm && this.searchTerm.length > 0;
    },
  },
  methods: {
    clearResults() {
      this.$emit("clear-bulk-order-search-results");
    },
    closeDetail() {
      this.isDetailShowing = false;
    },
    createBulkOrder() {
      this.$emit("create-bulk-order");
    },
    async searchTermChanged() {
      this.isDetailShowing = false;
      await this.searchBulkOrders(this.searchTerm);
      this.setPaging(this.bulkOrdersSearchResponse);
    },
    async showDetail(orderNumber) {
      await this.getBulkOrderDetail({ id: orderNumber });
      //window.scrollTo(0, 0);
      this.$scrollTo("#bulk-order-detail", 500, {
                          easing: "ease-in-out",
                          offset: -120,
              });
      this.isDetailShowing = true;
    },
    async updatePage(page) {
      this.paging.pageNumber = page - 1;
      this.pagerPageNumber = page;
      await this.searchBulkOrders(this.searchTerm);
      this.setPaging(this.bulkOrdersSearchResponse);
    },
  },
  async mounted() {
    this.searchTermChanged = this.$nucleus.debounce(
      this.searchTermChanged,
      200
    );
  },
};
</script>

<style lang="scss" scoped>
.n-bulk-order-detail {
  margin-top: 30px;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 10px;
}
</style>
