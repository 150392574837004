<template>
    <AdminTemplate>
        <div class="row">
            <div id="container" style="width:100%;height:800px;" v-show="showReport"></div>
            <div v-if="errorMessage && errorMessage.length > 0">
          <div
            class="alert alert-danger alert-dismissable fade show mb-0"
            role="alert"
          >
            <strong>{{ errorMessage }}</strong>
            <button type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        </div>
    </AdminTemplate>
</template>

<script>
    import AdminTemplate from "@/pages/templates/AdminTemplate";
    import * as pbi from 'powerbi-client';
    import reportApi from "@/api/report-api";
    export default {
        name: 'Reports',
        components: {
            AdminTemplate
        },
        data() {
            return {
                showReport: false,
                url: "",
                errorMessage:[]
            }
        },
        mounted() {
            var reportId = this.$route.query.reportId;
            var externalId = this.$route.query.externalId;
            var dashboardId = this.$route.query.dashboardId
            if (externalId != null) {
                this.getReportDetails(externalId, reportId);
            }
            else if (dashboardId != null) {
                this.getReportDetails(dashboardId, null, true);
            }
        },
        methods: {
            async getReportDetails(externalId, reportId, isDashboard) {
                var queryString = {};
                if (!isDashboard) {
                    queryString.externalId = externalId;
                    queryString.reportId = reportId;
                }
                else {
                    queryString.externalId = reportId;
                    queryString.isDashboard = true;
                }
                var embedTokenResponse = await reportApi.getEmbedToken(queryString);
                if(embedTokenResponse && embedTokenResponse.embeddedParams) {
                    this.showReport = true;
                    this.embedReport(embedTokenResponse.embeddedParams, isDashboard);
                } else {
                    this.showReport = false;
                    if(embedTokenResponse && embedTokenResponse.messages && embedTokenResponse.messages.length){
                        this.errorMessage.push(embedTokenResponse.messages[0].debugMessage);
                    }
                    
                }
            },

            embedReport(embedData, isDashboard) {
                var config = {
                    type: isDashboard ? 'dashboard': 'report',
                    tokenType: pbi.models.TokenType.Embed,
                    accessToken: embedData.embedToken.token,
                    embedUrl: embedData.embedReport[0].embedUrl,
                    permissions: pbi.models.Permissions.All,
                    settings: {
                        background: pbi.models.BackgroundType.Transparent,
                        filterPaneEnabled: true,
                        navContentPaneEnabled: true
                    }
                };

                // Get a reference to the embedded report HTML element
                var embedContainer = document.getElementById('container');

                // Embed the report and display it within the div container.
                let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
                var report = powerbi.embed(embedContainer, config);

                // Report.off removes a given event handler if it exists.
                report.off("loaded");

                // Report.on will add an event handler which prints to Log window.
                report.on("loaded", function () {
                    //Add any logic if is required
                });

                // Report.off removes a given event handler if it exists.
                report.off("rendered");

                // Report.on will add an event handler which prints to Log window.
                report.on("rendered", function () {
                    //Add any logic if is required
                });

                report.on("error", function () {
                    report.off("error");
                });
            }
        },

    }
</script>

<style lang="scss" scoped>
    .table-sm th {
        padding: .5rem;
    }

    .table-sm td {
        padding: .5rem;
    }

    .rpt {
        cursor: pointer
    }
</style>