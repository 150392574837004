<template>
  <div class="n-order-additional-message flex">
    <div v-if="messages && messages.length">
      <div v-for="message in messages" :key="message.type">
        <font-awesome-icon :class="iconClass(message.type)" icon="check-circle" />
        <strong class="n-order-add-text p-1">{{message.text}}</strong>
        <span>
        <template v-if="!$nucleus.isEmpty(message.link)">
          <template v-if="!$nucleus.isEmpty(message.link.internal)" class="ml-1 mt-1">
            <router-link :to="message.link.to">
              {{ message.link.text }}
            </router-link>
          </template>
          <template v-else>
            <a href="message.link.to" target="_blank">
              {{ message.link.text }}
            </a>
          </template>
        </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
//import Message from "@/nucleus-modules/dd-nucleus-storefront/components/Message.vue";

export default {
  name: "order-additional-messages",
  components: {
  //  Message,
  },
  props: {
    messages: {
      type: Array,
      required: false,
    },
  },
  methods: {
    iconClass(variant) {
        return "text-" + variant;
    },
  }
};
</script>

<style lang="scss" scoped>
.n-order-add-text {
  color:$gray-3
}
</style>
