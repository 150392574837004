// system modules
export const IS_LOADING_GETTER = "isHttpFetching";
export const IS_LOCALE_CHANGING_GETTER = "isLocaleChanging";
export const OVERLAY_GETTER = "overlay";

// error module
export const SYSTEM_ERRORS_GETTER = "systemErrors";

// auth module
export const USER_INFO_GETTER = "userInfo";

// filtering module
export const FILTER_TAGS_GETTER = "filterTags";
export const FILTERING_CONFIG_GETTER = "filteringConfig";
export const FILTERS_GETTER = "filters";
export const SORTING_CONFIG_GETTER = "sorting";
export const PER_PAGE_CONFIG_GETTER = "perPageConfig";
