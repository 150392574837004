export const addresses = {
    methods: {
        getEmptyAddress() {
            return {
                id: null,
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                postalCode: null,
            };
        }
    }
}
