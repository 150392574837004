export const productMixins = {
  methods: {
    getInventory(product) {
      let result = {};
      if (
        product &&
        product.canAddToCart &&
        product.attributes &&
        product.attributes.Inventory
      ) {
        result = product.attributes.Inventory;
      }
      return result;
    },
    isUnitOfMeasureAvailable(product) {
      return this.getInventory(product) && this.getInventory(product).unitOfMeasure;
    }
  },
  
}