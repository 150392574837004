<template>
	<div>
		<h4 class="menu-title pt-2">{{ title }}</h4>
		<MenuItem v-for="item in accountMenu" :key="item.id" :item="item" :level="0" :opened="item.opened"
			:leftNavigation="true" v-on:onMenuClicked="onMenuClicked" />
	</div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
import MenuValidation from "@/mixins/MenuValidation.js";
import MenuFunctions from "@/mixins/MenuFunctions.js";
export default {
	name: "AccountMenu",
	mixins: [MenuValidation, MenuFunctions],
	components: {
		MenuItem,
	},
	props: {
		title: {
			type: String,
			required: false,
		},
	},
	computed: {
		accountMenu() {
			let accountMenu = {};
			if (this.menu) {
				accountMenu = this.menu.filter(function (item) {
					return item.id !== "sign-out";
				});
			}
			return accountMenu;
		},
	},
};
</script>

<style lang="scss" scoped>
.menu-title {
	color: $primary-dark-color;
	text-transform: none;
	font-weight: 700;
	font-size: 1.25rem;
	font-family: $body-font;
}
</style>
