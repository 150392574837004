<template>
  <div>
    <h4 class="d-inline">{{ title }}</h4>
    <a class="ml-4 d-inline" @click="cancel()">
      {{ text }}
    </a>
  </div>
</template>

<script>
export default {
  name: "PageHeaderTitle",
  props: {
    title: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>
