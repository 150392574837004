<template>
    <div>
        <div class="row">
            <div class="col">
                <h4 class="mb-3">Orderable Dates</h4>
                <p class="n-form-labels">
                    Select Orderable Date Range
                    <a @click="clearDates()"><span class="pl-3">Clear All</span></a>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-3">
                <label for="startOrderDate" class="mb-4 n-form-labels">
                    Date of First Use
                    <span class="n-form-label-notes">(optional)</span>
                </label>
                <b-form-datepicker id="startOrderDate"
                                   class="mb-2"
                                   v-model="startOrderDate"
                                   today-button
                                   reset-button
                                   locale="en"
                                   :date-format-options="dateFormatOptions"></b-form-datepicker>
            </div>
            <div class="form-group col-3">
                <label for="endOrderDate" class="mb-4 n-form-labels">
                    Quarantine Date
                    <span class="n-form-label-notes">(optional)</span>
                </label>
                <b-form-datepicker id="endOrderDate"
                                   class="mb-2"
                                   v-model="endOrderDate"
                                   today-button
                                   reset-button
                                   locale="en"                                  
                                   :date-format-options="dateFormatOptions"></b-form-datepicker> 
                <small class="d-block n-note">(Required for automated removal)</small>
            </div>
            <div class="form-group col-4">
                <label class="mb-4 n-form-labels">
                    Days to Quarantine Date
                    <span class="n-form-label-notes">(optional)</span>
                    <span v-b-tooltip.hover
                          class="primary-color text-left n-learn-more mb-1"
                          title="This item will not be orderable for the number of days entered below prior to the Quarantine Date selected">
                        <font-awesome-icon icon="question-circle" />
                    </span>
                </label>
                <input type="number"
                       class="form-control"
                       v-model.trim="daysToQuarantineDate"
                       :class="{ 'is-invalid': $v.daysToQuarantineDate.$invalid }"
                       @input="$v.daysToQuarantineDate.$touch()" 
                       @keypress="validateNumber"
                       />
                <div v-if="$v.daysToQuarantineDate.$invalid" class="n-error">
                    <span v-if="!$v.daysToQuarantineDate.mustHaveQuarantineDate">Quarantine date is required to select days to quarantine</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import { } from "vuelidate/lib/validators";
    import {
        PRODUCT_FOR_EDIT_GETTER,
        PRODUCT_PROPERTY_GETTER,
    } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

    const ATTRIBUTE_NAME = "ExtendedProductProperties";

    const mustHaveQuarantineDate = (value, vm) => {
        if (vm.$nucleus.isEmpty(value) || value == 0) {
            return true;
        } else {
            return !vm.$nucleus.isEmpty(vm.endOrderDate);
        }
    }
    export default {
        name: "ProductAvailability",
        data() {
            return {
                dateFormatOptions: {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                },
            };
        },
        computed: {
            ...mapGetters({
                getProductProperty: PRODUCT_PROPERTY_GETTER,
                product: PRODUCT_FOR_EDIT_GETTER,
            }),
            startOrderDate: {
                get() {
                    return this.getProductProperty("startOrderDate");
                },
                set(value) {
                    if (this.$nucleus.isEmpty(value)) value = null;
                    this.setProductProperty({ name: "startOrderDate", value: value });
                },
            },
            endOrderDate: {
                get() {
                    return this.getProductProperty("endOrderDate");
                },
                set(value) {
                    if (this.$nucleus.isEmpty(value)) value = null;
                    this.setProductProperty({ name: "endOrderDate", value: value });
                },
            },
            daysToQuarantineDate: {
                get() {
                    return this.getProductProperty("daysToQuarantineDate", ATTRIBUTE_NAME);
                },
                set(value) {
                    if (this.$nucleus.isEmpty(value)) value = null;
                    this.setProductProperty({ name: "daysToQuarantineDate", value: value, attribute: ATTRIBUTE_NAME, });
                },
            },
        },
        methods: {
            ...mapMutations({
                setProductProperty: SET_PRODUCT_PROPERTY,
            }),
            clearDates() {
                this.startOrderDate = this.endOrderDate = this.daysToQuarantineDate = null;
            },
            validateNumber: (event) => {
                let keyCode = event.keyCode;
                if (keyCode < 48 || keyCode > 57) {
                    event.preventDefault();
                }
            },
        },
        mounted() {
            this.setProductProperty = this.$nucleus.debounce(
                this.setProductProperty,
                500
            );
        },
        validations: {
            daysToQuarantineDate: {
                mustHaveQuarantineDate
            },
        },
    };
</script>

<style lang="scss" scoped>
    .n-note {
        color: $red;
    }
    .b-calendar .b-calendar-grid {   
    height: 310px !important;
    }
</style>
