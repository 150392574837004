<template>
    <div class="n-header-menu">
        <a class="dropdown-toggle" id="hamburgerToggle" href="#" data-display="static" @click='onNameClicked()'>
            <template v-if="open">
                <font-awesome-icon class="n-hamburger-icon" icon="times" />
            </template>
            <template v-else>
                <font-awesome-icon class="n-hamburger-icon" icon="bars" />
            </template>
        </a>
        <div v-if="open" class="mt-3 n-header-dropdown">
            <template v-for="(item) in items">
                <template v-if="item">
                    <AdminMenuItem 
                        :key="item.title"
                        :item="item"
                        :level="0"
                        :opened="false"
                        v-on:onAdminMenuClicked="onAdminMenuClicked"
                        v-on:closeMenu="closeMenu" />
                </template>
            </template>                
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import { RESET_ACTIVE_MENU_ITEMS } from "@/store/storefront/actions.type.js";
    import AdminMenuItem from "@/components/admin/AdminMenuItem.vue";
    import MenuValidation from '@/mixins/MenuValidation.js';
    import MenuFunctions from '@/mixins/MenuFunctions.js';
    export default {
        name: "AdminMenu",
        mixins: [MenuValidation,MenuFunctions],
        components: {
            AdminMenuItem,
        },
        data() {
            return {
                open: false,
                selectedMenus: []
            };
        },
        props: {
            items: Array
        },
        methods: {
            ...mapActions({
                resetActiveMenuItems: RESET_ACTIVE_MENU_ITEMS,
            }),
            onNameClicked() {
                this.open = !this.open;
                this.resetActiveMenuItems();
            },            
            closeMenu() {
                this.open = false;
                this.onAdminMenuClicked()
            }
        }
    }
</script>

<style scoped lang="scss">

.dropdown-toggle::after {
    content: "";
}

.show .dropdown-toggle::after {
    content: "";
}

.dropdown-toggle::after {
    display: none !important;
}

.n-hamburger-icon {
    font-size: 36px;
    color: $primary-color;
    padding: 0;
    cursor: pointer;
}

.dropdown-menu {
    width: 400px;
}


@media (max-width: 767px){
    .n-header-dropdown {
        width: 100vw;
    }
}

@media (min-width: 1024px){
    .n-hamburger-icon{
        margin-top: -4px;
    }
}
</style>
