<template>
  <div>
    <div v-if="!hasAssociatedMedia">
      No media items have been associated to this item.
    </div>
    <div v-else>
      <hr class="mt-4 mb-4" />
      <div class="n-associated-media">
        <div class="col-9">
          <p>
            Media associated to this product
          </p>
        </div>
        <div class="col-3 text-right pt-2 mt-2">
          <p>
            <font-awesome-icon :icon="['fa', 'star']"></font-awesome-icon>
            <span class="pl-1">Primary</span>
          </p>
        </div>
      </div>
      <div v-if="errorMessage && errorMessage.length > 0" class="row">
        <div class="col">
          {{ errorMessage }}
        </div>
      </div>
      <table class="table table-bordered mb-4">
        <thead class="thead-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col" v-for="column in headers" :key="column.field">
              {{ column.label }}
            </th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <draggable
          tag="tbody"
          v-model="product.media"
          v-bind="dragOptions"
          :move="onMove"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <tr
            v-for="(item, index) in product.media"
            :key="item.order"
            :class="[
              item.isPrimary
                ? 'n-primary'
                : (index + 1) % 2 == 0
                ? 'n-evenRow'
                : 'n-oddRow',
            ]"
          >
            <td scope="row" class="td-group-item text-center">
              <i
                :class="
                  item.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                "
                @click="item.fixed = !item.fixed"
                aria-hidden="true"
              ></i>
              <font-awesome-icon :icon="['far', 'ellipsis-v']" />
              <font-awesome-icon :icon="['far', 'ellipsis-v']" />
              <font-awesome-icon :icon="['far', 'ellipsis-v']" />
            </td>
            <td
              scope="row"
              v-for="column in headers"
              :key="column.field"
              class="td-group-item"
              :class="
                column.alignmentClass !== undefined ? column.alignmentClass : ''
              "
            >
              <template v-if="column.field !== 'mediaItemId'">
                <template v-if="column.field === 'thumbnailImageUrl'">
                  <img :src="item[column.field]" />
                </template>
                <template v-else-if="column.field === 'name'">
                  <font-awesome-icon
                    :icon="['fa', 'star']"
                    v-if="item.isPrimary"
                  />
                  {{ item[column.field] }}
                </template>
                <template v-else-if="column.field === 'fileSize'">
                  {{ formatFileSize(item[column.field]) }}
                </template>
                <template v-else-if="column.field === 'updated'">
                  {{ item[column.field] | moment("MM/DD/YYYY hh:mm a") }}
                </template>
                <template v-else>
                  {{ item[column.field] }}
                </template>
              </template>
            </td>
            <td scope="row" class="action-cursor n-actions-icons">
              <font-awesome-icon
                class="mr-3"
                :icon="['fa', 'star']"
                v-if="!item.isPrimary"
                @click="setPrimary(item)"
              />              
              <font-awesome-icon
                class="mr-3 n-image-icons"
                :icon="['fa', 'eye']"
                @click="showFullImage(item)"
              />
              <font-awesome-icon
                class="mr-3 n-image-icons"
                :icon="['fa', 'download']"
                @click="downloadImage(item)"
              />
              <font-awesome-icon
                class="n-image-icons"
                :icon="['fa', 'trash-alt']"
                @click="removeProductMedia(item)"
              />
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import draggable from "vuedraggable";
import {
  PRODUCT_FOR_EDIT_GETTER,
  PRODUCT_PROPERTY_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";
import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";
import ModalService from "@/nucleus-modules/dd-nucleus-ui/services/modal.service.js";
import FullImageModal from "@/nucleus-modules/dd-nucleus-ui/components/ModalImage.vue";
import { AdminEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "AssociatedMedia",
  components: {
    draggable,
  },
  data() {
    return {
      delayedDragging: false,
      errorMessage: null,
      headers: [],
      isDragging: false
      // mediaTypes: MediaTypes,
    };
  },
  computed: {
    ...mapGetters({
      getProductProperty: PRODUCT_PROPERTY_GETTER,
      product: PRODUCT_FOR_EDIT_GETTER,
    }),
    dragOptions() {
      return {
        animation: 10,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    hasAssociatedMedia() {
      return this.product.media && this.product.media.length > 0;
    },
  },
  async mounted() {
    this.setHeaders();
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
    async downloadImage(mediaItem) {
      await ApiService.getBlob(mediaItem.url).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", mediaItem.name);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    formatFileSize(size) {
      size = size || 0;

      size = (Number(size) / 1024000).toFixed(2);
      size = `${size} Mb`;

      return size;
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    removeProductMedia(mediaItem) {
      const confirmationBoxService = new ConfirmationBoxService({
        title: "Confirm Delete",
      });

      confirmationBoxService
        .showConfirm(`Are you sure you want to delete ${mediaItem.name}?`)
        .then(async (confirm) => {
          if (confirm) {
            this.$eventBus.$emit(AdminEvents.PRODUCT_IMAGE_REMOVED, {
              id: mediaItem.id,
            });
          }
        });
    },
    setHeaders() {
      this.headers = [
        {
          label: "Thumbnail",
          field: "thumbnailImageUrl",
          alignmentClass: "text-center",
        },
        {
          label: "File Name",
          field: "name",
        },
        {
          label: "Size",
          field: "fileSize",
        },
        {
          label: "Last Modified",
          field: "updated",
        },
        {
          label: "Type",
          field: "mediaType",
        },
      ];
    },
    setPrimary(mediaItem) {
      let ranked = this.product.media.filter((m) => m.id !== mediaItem.id);
      let index = 1;

      ranked.splice(0, 0, mediaItem);

      ranked.forEach((r) => {
        r.rank = index;
        index++;
      });

      this.setProductProperty({ name: "media", value: ranked });

      this.$eventBus.$emit(AdminEvents.PRODUCT_IMAGES_RERANKED);
    },
    showFullImage(mediaItem) {
      ModalService.open(FullImageModal, mediaItem);
    },
    updateMediaRank() {
      let ranked = this.product.media.map((item, index) => {
        item.rank = index + 1;
        item.isPrimary = index === 0;

        return item;
      });

      this.setProductProperty({ name: "media", value: ranked });

      this.$eventBus.$emit(AdminEvents.PRODUCT_IMAGES_RERANKED);
    },
    updateProductMedia() {},
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      } else {
        this.$nextTick(() => {
          this.delayedDragging = false;
          this.updateMediaRank();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
  width: 100%;
}

.td-group-item {
  cursor: move;
}

.action-cursor {
  cursor: pointer;
}

.n-associated-media {
  display: flex;
}

.fa-star {
  color: $secondary-color;
}

.table .thead-dark th {
  background-color: $primary-color;
  border-color: #fff;
}

.table {
  color: $gray-3;
}

.n-image-icons {
  color: $primary-color;
}

.n-actions-icons {
  text-align: right;
}

.n-primary {
  background-color: $text-field-highlight;
}

.n-evenRow {
  background-color: white;
}

.n-oddRow {
  background-color: $gray-1;
}

.table-bordered td {
  border: none;
}

.table-bordered {
  border: none;
}

.table td,
.table th {
  vertical-align: middle;
}
</style>
