<template>
  <div>
    <PageSubHeader title="Assign Roles" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-xs-12 col-sm-5">
        <dl v-for="role in userRoleNames" :key="role">
          <dt>
            {{ role }}
          </dt>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

export default {
  name: "AnnouncementUserRolesViewOnly",
  props: {
    userRoleNames: {
      type: Array,
      required: true,
    },
  },
  components: {
    PageSubHeader,
  },
};
</script>

<style lang="scss" scoped>
.n-primary-role {
  font-size: 14px !important;
  color: $primary-color;
}
</style>
