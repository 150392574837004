<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="hasMultipleMediaItems">
          <div class="arrows d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <div class="n-left-arrow">
              <font-awesome-icon @click="previousHandler()" icon="chevron-left"/>
            </div>
            <div class="n-right-arrow">
              <font-awesome-icon icon="chevron-right" @click="nextHandler()" />
            </div>
          </div>
        </div>
        <div
          v-touch:swipe.left="previousHandler"
          v-touch:swipe.right="nextHandler"
          class="n-marquee-image text-center"
        >
          <transition name="fade" mode="out-in">
            <router-link v-if="currentMarquee.linkUrl" :to="currentMarquee.linkUrl">
              <img
                :key="currentMarquee.mediaId"
                :alt="currentMarquee.mediaName"
                :src="currentMarquee.url"
                @click="onMarqueeClicked"
                class="img-fluid"
              />
            </router-link>
            <img
                v-else
                :key="currentMarquee.mediaId"
                :alt="currentMarquee.mediaName"
                :src="currentMarquee.url"
                @click="onMarqueeClicked"
                class="img-fluid"
              />
          </transition>
        </div>
      </div>
    </div>
    <div v-if="hasMultipleMediaItems" class="row">
      <div class="col-sm-12 text-center">
        <!-- Thumbnail navigation for larger viewports -->
        <div v-if="showThumbNavigation" class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
          <div
            v-for="(mediaItem, index) in mediaItems"
            :key="index"
            class="n-thumbnails"
          >
            <div>
              <img
                :src="getThumbnailUrl(mediaItem.thumbnailImageUrl)"
                @click="onThumbnailClicked(index)"
                :alt="mediaItem.mediaName"
                :class="{ 'n-active-thumbnail': index === currentIndex }"
              />
            </div>
          </div>
        </div>
        <!-- Dot navigation for all Device Sizes -->
        <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none n-dot-container" :class="{'n-show-dot-nav' : showDotNavigation}">
          <span v-for="(mediaItem, index) in mediaItems" :key="index" class="n-thumbnails">
            <font-awesome-icon
              icon="circle"
              class="n-dot-nav"
              :class="{ 'n-active-thumbnail': index === currentIndex }"
              @click="onThumbnailClicked(index)"
            />
          </span>
          <span v-if="showControls">
            <a @click="pause()" v-if="isAutoPlayRunning">
              <font-awesome-icon class="n-control-icons" :icon="['far', 'pause-circle']"/>
            </a>
            <a @click="play()" v-else>
              <font-awesome-icon class="n-control-icons" :icon="['far', 'play-circle']"/>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sliderChangeDelay } from "@/companyAppConfig";

export default {
  name: "MediaSlideShow",
  props: {
    mediaItems: {
      type: Array,
      required: true,
    },
    mediaTypes: {
      type: Object,
      required: true,
    },
    noImageAvailableUrl: {
      type: String,
      required: true,
    },
    // When true this will scroll through the images automatically 
    autoPlay:{
      type: Boolean,
      default: false
    },
    // This will override the hiding of the dot navigation on desktop 
    showDotNavigation:{
      type: Boolean,
      default: false
    },
    // Show the thumbnail navigation when we have thumbnails and multipe images 
    showThumbNavigation:{
      type: Boolean,
      default: false
    },
    // This will show or hide the play / pause buttons in the dot navigation 
    showControls:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentMarquee() {
      return this.mediaItems[
        Math.abs(this.currentIndex) % this.mediaItems.length
      ];
    },
    hasMultipleMediaItems() {
      return this.mediaItems.length > 1;
    },
    isAutoPlayRunning(){
      return (this.timer != 0) ? true : false;
    }
  },
  data() {
    return {
      currentIndex: 0,
      timer:0
    };
  },
  methods: {
    getThumbnailUrl(url) {
      if (url && url.length > 0) return url;

      return this.noImageAvailableUrl;
    },
    isImageMediaType(mediaType) {
      return mediaType === this.mediaTypes.IMAGE;
    },
    nextHandler() {
      if (this.currentIndex === this.mediaItems.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    onMarqueeClicked() {
      this.$emit("marquee-clicked", this.currentMarquee);
    },
    onThumbnailClicked(index) {
      this.currentIndex = index;
    },
    pause(){
      clearInterval(this.timer);
      this.timer = 0;
    },
    play(){
      this.timer = setInterval(() => this.nextHandler(), sliderChangeDelay); // sliderChangeDelay is set in @/companyAppConfig
    },
    previousHandler() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.mediaItems.length;
      } else {
        this.currentIndex--;
      }
    }
  },
  mounted () {
    // Check to see if it should auto play based on the autoPlay prop
    if(this.autoPlay){
      this.play();
    }
  },
};
</script>

<style lang="scss" scoped>
.n-control-icons{
  font-size: 35px;
  padding-top: 15px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.n-left-arrow,
.n-right-arrow {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: -50px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 30px;
  line-height: 38px;
  text-indent: -2px;
  text-align: center;
  background-color: #fff;
  z-index: 5;
  padding-top: 2px;
}

.n-right-arrow {
  right: 0;
  margin-left: auto;
  margin-right: 25px;
  text-indent: 2px;
  padding-left: 3px;
}

.n-left-arrow{
  padding-right: 3px;
}

.n-thumbnails {
  display: inline-block;
  padding: 20px 10px;
}

.n-thumbnails span {
  width: 100%;
  height: 100%;
}

.n-active-thumbnail {
  border: 3px solid $secondary-color;
}


.n-thumbnail-label {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 4px;
  color: $neutral-mid;
}

.n-thumbnails img {
  max-width: 100px;
  height: auto;
}

.n-marquee-image img {
  cursor: pointer;
}

.n-dot-container .n-dot-nav {
  color: $gray-2;
}

.n-dot-container .n-dot-nav.n-active-thumbnail {
  color: $neutral-darker;
  border: none;
}

.n-show-dot-nav{
  display: block !important;
}
</style>
