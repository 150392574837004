<template>
  <div>
    <div v-if="isLoading">
      <p>Loading...</p>
    </div>
    <div v-else>
      <h2>Search for Recipients</h2>
      <div class="mt-3 p-3 n-filter-area">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Search Term</label>
              <div class="input-group input-group-sm search">
                <input
                  type="text"
                  v-model="searchTerm"
                  class="form-control n-search-input"
                  placeholder="Search Business Name, Full Name, Address"
                  aria-label="input-group mb-3"
                  v-on:keyup.enter="search()"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="search"
                  >
                    <font-awesome-icon
                      icon="search"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label
                >GEO ID(s)
                <span class="small">(separate multiple by commas)</span></label
              >
              <div class="input-group input-group-sm search">
                <input
                  type="text"
                  v-model="selectedGeoIds"
                  class="form-control"
                  placeholder="GEO ID(s)"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="search"
                  >
                    <font-awesome-icon
                      icon="search"
                      fixed-width
                    ></font-awesome-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label>User Status</label>
              <select class="form-control" v-model="selectedStatus">
                <option v-for="status in availableStatuses" :key="status.id">
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label>Login Access</label>
              <select class="form-control" v-model="selectedLogin">
                <option
                  v-for="accessType in loginAccessList"
                  :value="accessType.id"
                  :key="accessType.id"
                >
                  {{ accessType.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Address Type</label>
              <div class="d-flex">
                <div class="mr-2">
                  <input
                    id="primaryAddressType"
                    type="radio"
                    class="mr-2"
                    name="addressType"
                    :value="addressTypes.BILLING"
                    v-model="selectedAddressType"
                  />
                  <label for="primaryAddressType">Primary Address</label>
                </div>
                <div>
                  <input
                    id="shippingAddressType"
                    type="radio"
                    class="mr-2"
                    name="addressType"
                    :value="addressTypes.SHIPPING"
                    v-model="selectedAddressType"
                  />
                  <label for="shippingAddressType"
                    >Default Shipping Address</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="mb-2"><strong>Primary Role</strong></div>
            <b-dropdown
              id="primaryRoleDropdown"
              :text="primaryRolesLabel"
              dropdown
              ref="primaryRoleDropdown"
              variant="white n-custom-dropdown"
              class="n-custom-dropdown-display"
            >
              <b-dropdown-form>
                <ul class="n-select-deselect">
                  <li class="pl-4 n-checkbox-group" @click="selectAllRoles()">Select All</li>
                  <li class="pl-4 n-checkbox-group" @click="deselectAllRoles()">Deselect All</li>
                  <li><hr /></li>
                </ul>
                <b-form-checkbox-group
                  id="primaryRoleFilter"
                  v-model="selectedRoles"
                  :options="availablePrimaryRoles"
                  value-field="value"
                  text-field="text"
                  name="primaryRoles"
                  :stacked="true"
                  class="n-checkbox-group"
                ></b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="col-3">
            <div class="mb-2"><strong>States</strong></div>
            <b-dropdown
              id="statesDropdown"
              :text="statesLabel"
              dropdown
              ref="statesDropdown"
              variant="white n-custom-dropdown"
              class="n-custom-dropdown-display restricted"
            >
              <b-dropdown-form>
                <ul class="n-select-deselect">
                  <li class="pl-4 n-checkbox-group" @click="selectAllStates()">Select All</li>
                  <li class="pl-4 n-checkbox-group" @click="deselectAllStates()">
                    Deselect All
                  </li>
                </ul>
                <b-form-checkbox-group
                  id="statesFilter"
                  v-model="selectedStates"
                  :options="availableStatesList"
                  value-field="value"
                  text-field="text"
                  name="states"
                  :stacked="true"
                ></b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label
                >Postal Codes
                <small>(separate multiple by commas)</small></label
              >
              <input
                type="text"
                v-model="selectedPostalCodes"
                class="form-control"
                placeholder="Postal Code(s)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="mb-2"><strong>Regions</strong></div>
            <b-dropdown
              id="regionDropdown"
              :text="regionLabel"
              dropdown
              ref="regionDropdown"
              variant="white n-custom-dropdown"
              class="n-custom-dropdown-display"
            >
              <b-dropdown-form>
                <ul class="n-select-deselect">
                  <li class="pl-4 n-checkbox-group" @click="selectAllRegions()">Select All</li>
                  <li class="pl-4 n-checkbox-group" @click="deselectAllRegions()">
                    Deselect All
                  </li>
                </ul>
                <b-form-checkbox-group
                  id="regionsFilter"
                  v-model="selectedRegions"
                  :options="regionsList"
                  value-field="value"
                  text-field="text"
                  name="regions"
                  :stacked="true"
                ></b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="col-3">
            <div class="mb-2"><strong>Districts</strong></div>
            <b-dropdown
              id="districtDropdown"
              :text="districtLabel"
              dropdown
              ref="districtDropdown"
              variant="white n-custom-dropdown"
              class="n-custom-dropdown-display"
            >
              <b-dropdown-form>
                <ul class="n-select-deselect">
                  <li class="pl-4 n-checkbox-group" @click="selectAllDistricts()">Select All</li>
                  <li class="pl-4 n-checkbox-group" @click="deselectAllDistricts()">
                    Deselect All
                  </li>
                </ul>
                <b-form-checkbox-group
                  id="districtsFilter"
                  v-model="selectedDistricts"
                  :options="districtsList"
                  value-field="value"
                  text-field="text"
                  name="districts"
                  :stacked="true"
                ></b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="col-3">
            <div class="mb-2"><strong>Territories</strong></div>
            <b-dropdown
              id="territoriesDropdown"
              :text="territoryLabel"
              dropdown
              ref="territoryDropdown"
              variant="white n-custom-dropdown"
              class="n-custom-dropdown-display"
            >
              <b-dropdown-form>
                <ul class="n-select-deselect">
                  <li class="pl-4 n-checkbox-group" @click="selectAllTerritories()">
                    Select All
                  </li>
                  <li class="pl-4 n-checkbox-group" @click="deselectAllTerritories()">
                    Deselect All
                  </li>
                </ul>
                <b-form-checkbox-group
                  id="territoriesFilter"
                  v-model="selectedTerritories"
                  :options="territoriesList"
                  value-field="value"
                  text-field="text"
                  name="territories"
                  :stacked="true"
                ></b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-outline-primary mr-2"
              @click="clearFilters()"
            >
              Clear All
            </button>
            <button type="button" class="btn btn-primary" @click="search()">
              Search
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="row mb-3">
          <div class="col-6">
            <div class="d-flex align-items-end">
              <h2>Query Results</h2>
                <strong class="p-2 text-danger">({{ paging.totalCount }})</strong>
            </div>
          </div>
        </div>
        <div v-if="hasFilterTags" class="row mb-3">
          <div class="col">
            <FilterCriteriaItem
              v-for="filterTag in filterTags"
              :key="filterTag.id"
              :filterTag="filterTag"
              :showClose=filterTag.showClose
              v-on:remove-filter="removeFilter"
            ></FilterCriteriaItem>
            <a href="#" class="ml-3" @click.prevent="clearFilters(false)">Clear All</a>
          </div>
        </div>
        <div class="row" v-if="hasSearchResults">
          <div class="col d-flex justify-content-end">
            Showing {{ paging.pageStartNumber }} - {{ paging.pageEndNumber }} of
            {{ paging.totalCount }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <h4>Select Recipients to Receive Bulk Order</h4>
          </div>
          <div v-if="hasSearchResults" class="col-6">
            <div class="d-flex justify-content-end align-items-baseline">
              <div class="mr-2">
                <b-form-select
                  v-model="paging.pageSize"
                  :options="paging.perPageOptions"
                  @change="updatePage()"
                >
                </b-form-select>
              </div>
              <span class="small mr-2">per page</span>
              <div>
                <b-pagination
                  v-model="pagerPageNumber"
                  :total-rows="paging.totalCount"
                  :per-page="paging.pageSize"
                  aria-controls="recipientsTable"
                  @change="updatePage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <VueGoodTable
                class="table table-striped"
                :rows="rows"
                :columns="searchResultFields"
                :sort-options="sortConfig"
                max-height="370px"
              >
                <div slot="emptystate">
                  <p>The search did not return any results.</p>
                </div>
                <div slot="table-column" slot-scope="props">         
                 <span v-if="props.column.field === 'selection'">
                    <div class="form-group form-check d-inline text-center">
                      <label>
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="allUsersSelected"
                          @change="toggleSelectAllUsers()"
                        />
                        <span class="mt-2">({{ selectedRecipients.length }})</span></label
                      >
                    </div>
                  </span>
                  <span v-else>
                    {{ props.column.label }}
                  </span>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'addressTypeName'">
                    <span v-if="props.row.addressType == addressTypes.BILLING">
                      Primary
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </span>
                  <span v-else-if="props.column.field === 'selection'">
                    <div class="form-group form-check d-inline text-center">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="props.row.selected"
                        @change="toggleSelectedUser(props.row)"
                      />
                    </div>
                  </span>
                  <span v-else-if="props.column.field === 'userName'">
                    <div class="row">
                      <div class="col">
                        {{ props.formattedRow[props.column.field] }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <UserBadges
                          :roles="props.row.roles"
                          :showActive="
                            props.row.status === statusCodes.ACTIVE ||
                            props.row.status === statusCodes.LOCKED
                              ? true
                              : false
                          "
                          class="mb-2"
                        ></UserBadges>
                      </div>
                    </div>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </VueGoodTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import {
  AddressType,
  BulkOrderFilters,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";
import { PagingMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/PagingMixin.js";
import UserBadges from "@/nucleus-modules/dd-nucleus-admin/components/user/UserBadges.vue";

import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { AdminEvents } from "@/nucleus-modules/dd-nucleus-admin/constants";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import FilterCriteriaItem from "@/components/admin/bulk-orders/FilterCriteriaItem.vue";
import { adminRoles } from "@/companyAppConfig.js"

export default {
  name: "SelectRecipients",
  mixins: [BulkOrdersMixin, PagingMixin],
  components: {
    UserBadges,
    VueGoodTable,
    FilterCriteriaItem,
  },
  props: {
    allDistricts: {
      type: Array,
      required: true,
    },
    allRegions: {
      type: Array,
      required: true,
    },
    allTerritories: {
      type: Array,
      required: true,
    },
    availableStatuses: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    loginAccessList: {
      type: Array,
      required: true,
    },
    reset: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      addressTypes: AddressType,
      allUsersSelected: false,
      currentSearchCriteria: {},
      filterTags: [],
      pagerPageNumber: 1,
      searchTerm: "",
      selectedAddressType: null,
      selectedDistricts: [],
      selectedGeoIds: "",
      selectedPostalCodes: "",
      selectedRegions: [],
      selectedRoles: [],
      selectedStates: [],
      selectedStatus: null,
      selectedTerritories: [],
      selectedUsers: [],
      statusCodes: StatusCodes,
      paging: {
        includeTotalCount: true,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 200,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
        perPageOptions: [
          { value: 200, text: "200" },
          { value: 400, text: "400" },
          { value: 600, text: "600" },
          { value: 800, text: "800" },
          { value: 1000, text: "1000" },
        ],
      },
      searchResultFields: [
        {
          field: "selection",
          label: "",
          sortable: false,
           width: '100px',
        },
        {
          field: "userName",
          label: "User ID",
          sortable: true,
          width: '250px',
        },
        {
          field: "companyName",
          label: "Business Name",
          sortable: true,
          width: '200px',
        },
        {
          field: "firstName",
          label: "First Name",
          sortable: true,
          width: '150px',
        },
        {
          field: "lastName",
          label: "Last Name",
          sortable: true,
          width: '150px',
        },
        {
          field: "title",
          label: "Title",
          sortable: true,
          width: '180px',
        },
        {
          field: "primaryUserRoleName",
          label: "Primary Role",
          sortable: true,
          width: '230px',
        },
        {
          field: "addressTypeName",
          label: "Address Type",
          sortable: true,
          width: '130px',
        },
        {
          field: "addressee",
          label: "Addressee",
          sortable: true,
          width: '200px',
        },
        {
          field: "addressLine1",
          label: "Address 1",
          sortable: true,
          width: '220px',
        },
        {
          field: "addressLine2",
          label: "Address 2",
          sortable: true,
          width: '150px',
        },
        {
          field: "city",
          label: "City",
          sortable: true,
          width: '230px',
        },
        {
          field: "state",
          label: "State",
          sortable: true,
          width: '100px',
        },
        {
          field: "postalCode",
          label: "Postal Code",
          sortable: true,
          width: '150px',
        },
        {
          field: "countryCode",
          label: "Country",
          sortable: true,
          hidden:true
        },
        {
          field: "geoId",
          label: "Geo ID",
          sortable: true,
          width: '150px',
        },
        {
          field: "territoryName",
          label: "Territory",
          sortable: true,
          width: '180px',
        },
      ],
      sortConfig: {},
      selectConfig: {},
      selectedLogin: null,
      isRolesSelectorVisible: false,
      isStateSelectorVisible: false,
      adminRoles: Object.freeze(adminRoles),
      DefaultLoginAccessId:'storefront'

    };
  },
  computed: {
    availablePrimaryRoles() {
      let result = [];

      result = result.concat(
        this.allRoles.map((r) => {
          return { value: r.id, text: r.name };
        })
      );
      result = result.filter(r => !this.adminRoles.includes(r.value))

      result = result.sort((a, b) => (a.text > b.text ? 1 : -1));

      return result;
    },
    availableStatesList() {
      let result = [];

      result = result.concat(
        this.statesList.map((r) => {
          return { value: r.code, text: r.name };
        })
      );

      result = result.sort((a, b) => (a.text > b.text ? 1 : -1));

      return result;
    },
    districtLabel() {
      const defaultText = "Districts";

      return this.getLabelText(
        this.allDistricts,
        this.selectedDistricts,
        defaultText
      );
    },
    districtsList() {
      return this.allDistricts.map((d) => ({ text: d.name, value: d.id }));
    },
    hasFilterTags() {
      return this.filterTags && this.filterTags.length > 0;
    },
    hasSearchResults() {
      return (
        !this.$nucleus.isEmpty(this.searchRecipientsResponse) &&
        !this.$nucleus.isEmpty(this.searchRecipientsResponse.results)
      );
    },
    primaryRolesLabel() {
      const defaultText = "Primary Roles";

      return this.getLabelText(this.allRoles, this.selectedRoles, defaultText);
    },
    regionLabel() {
      const defaultText = "Regions";

      return this.getLabelText(
        this.allRegions,
        this.selectedRegions,
        defaultText
      );
    },
    regionsList() {
      return this.allRegions.map((d) => ({ text: d.name, value: d.id }));
    },
    rows() {
      if (
        this.searchRecipientsResponse &&
        this.searchRecipientsResponse.results &&
        this.searchRecipientsResponse.results.length > 0
      ) {
        return this.searchRecipientsResponse.results;
      }

      return [];
    },
    selectedRolesText() {
      let result = "Primary Roles";

      if (this.selectedRoles && this.selectedRoles.length > 0) {
        if (this.selectedRoles.length === 1) {
          result = this.selectedRoles[0].text;
        } else if (
          this.selectedRoles.length === this.availablePrimaryRoles.length
        ) {
          result = "All Primary Roles";
        } else {
          result = `${this.selectedRoles.length} Roles Selected`;
        }
      }

      return result;
    },
    selectedStatesText() {
      let result = "States";

      if (this.selectedStates && this.selectedStates.length > 0) {
        if (this.selectedStates.length === 1) {
          result = this.selectedStates[0].text;
        } else if (
          this.selectedStates.length === this.availableStatesList.length
        ) {
          result = "All States";
        } else {
          result = `${this.selectedStates.length} Roles Selected`;
        }
      }

      return result;
    },
    statesLabel() {
      const defaultText = "States";

      return this.getLabelText(
        this.statesList,
        this.selectedStates,
        defaultText,
        "code",
        "name"
      );
    },
    territoryLabel() {
      const defaultText = "Territories";

      return this.getLabelText(
        this.allTerritories,
        this.selectedTerritories,
        defaultText
      );
    },
    territoriesList() {
      return this.allTerritories.map((d) => ({ text: d.name, value: d.id }));
    },
    totalPages() {
      return this.paging.totalCount / this.paging.pageSize + 1;
    },
    totalUsersSelected() {
      if (
        !this.$nucleus.isEmpty(this.bulkOrderDetail) &&
        !this.$nucleus.isEmpty(this.bulkOrderDetail.users)
      ) {
        return this.bulkOrderDetail.users.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    addToOrder() {
      if (this.selectedUsers.length === 0) return;

      const usersAdded = this.addUsersToBulkOrder(this.selectedUsers);

      ToastService.showSuccessToast(
        `Added ${usersAdded} user(s) for a total of ${this.bulkOrderDetail.users.length} recipients.`
      );
    },
    async clearFilters(skipSearch) {
      this.searchTerm = "";
      this.selectedStatus = this.availableStatuses[0].name;
      this.selectedAddressType = AddressType.BILLING;
      this.selectedPostalCodes = "";
      this.selectedStates = [];
      this.selectedRoles = [];
      this.selectedRegions = [];
      this.selectedDistricts = [];
      this.selectedTerritories = [];
      this.selectedGeoIds = "";
      this.selectedLogin = this.DefaultLoginAccessId;
      this.currentSearchCriteria = null;
      this.filterTags = this.filterTags.filter(ft => ft.hideClose);
      this.$eventBus.$emit(UiEvents.RESET_MULTI_SELECT_FILTERS);

      this.paging.pageSize = this.paging.perPageOptions[0].value;
      
      if (!skipSearch) {
        await this.search();
      }
      skipSearch = false;
    },
    closeDropdowns() {
      this.isOrderDateSelectorVisible = false;
      this.isRolesSelectorVisible = false;
      this.isStateSelectorVisible = false;
    },
    deselectAllRoles() {
      this.selectedRoles = [];
    },
    deselectAllStates() {
      this.selectedStates = [];
    },
    deselectAllTerritories() {
      this.selectedTerritories = [];
    },
    deselectAllRegions() {
      this.selectedRegions = [];
    },
    deselectAllDistricts() {
      this.selectedDistricts = [];
    },
    getLabelText(
      list,
      selected,
      defaultText,
      keyField = "id",
      nameField = "name"
    ) {
      let result = defaultText;

      if (!this.$nucleus.isEmpty(list) && !this.$nucleus.isEmpty(selected)) {
        if (selected.length === 1) {
          result = list.find((x) => x[keyField] === selected[0])[nameField];
        } else if (list.length === selected.length) {
          result = `All ${defaultText}`;
        } else {
          result = `${selected.length} Selected`;
        }
      }

      return result;
    },
    initListeners() {
      const vm = this;
      this.$eventBus.$on(UiEvents.CUSTOM_DROPDOWN_SELECTED, (eventData) => {
        vm.updateSelectedFilters(eventData);
      });

      this.$eventBus.$on(AdminEvents.BULK_ORDER_CANCELLED, () => {
        vm.clearFilters(true);
        vm.paging.includeTotalCount = true,
        vm.paging.pageCount = 0,
        vm.paging.pageNumber = 0,
        vm.paging.pageSize = 200,
        vm.paging.pageStartNumber = 0,
        vm.paging.pageEndNumber = 0,
        vm.paging.totalCount = 0
      });
    },
    async removeFilter(filter) {
      switch (filter.id) {
        case BulkOrderFilters.SALES_REP_PRIMARY_ROLE.id:
          this.selectedRoles = [];
          break;
        case BulkOrderFilters.SALES_REP_GEO_ID.id:
          this.selectedGeoIds = "";
          break;
        case BulkOrderFilters.SALES_REP_REGION_ID.id:
          this.selectedRegions = [];
          break;
        case BulkOrderFilters.SALES_REP_DISTRICT_ID.id:
          this.selectedDistricts = [];
          break;
        case BulkOrderFilters.SALES_REP_TERRITORY_ID.id:
          this.selectedTerritories = [];
          break;
        case BulkOrderFilters.SALES_REP_SEARCH_TERM.id:
        case BulkOrderFilters.SEARCH_TERM.id:
          this.searchTerm = "";
          break;
        case BulkOrderFilters.SALES_REP_POSTAL_CODE.id:
          this.selectedPostalCodes = "";
          break;
        case BulkOrderFilters.SALES_REP_STATE.id:
          this.selectedStates = [];
          break;
      }

      await this.search();
    },
    removeListeners() {
      this.$eventBus.$off(AdminEvents.BULK_ORDER_CANCELLED);
    },
    resetPaging() {
      this.paging = {};
    },
    resetPage() {
      this.paging.pageNumber = 0;
      this.pagerPageNumber = 1;
    },
    async search() {
      this.filterTags = [];
      this.selectedUsers = [];
      this.allUsersSelected = false;

      const request = {
        filters: [],
        addressType: null,
        paging: this.paging,
      };

      this.resetPage();

            let selectedStatus = 'All';
      if (this.selectedStatus.toLowerCase() !== "all") {
        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_STATUS_CODE,
          values: [this.selectedStatus],
        });
        selectedStatus = this.selectedStatus;
      }
        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_STATUS_CODE.id,
          name: BulkOrderFilters.SALES_REP_STATUS_CODE.name,
          values: [selectedStatus],
          hideClose: true
        });

      let selectedLogin = 'All';

      if (this.selectedLogin.toLowerCase() !== "all") {
        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_LOGIN_ACCESS_CODE,
          values: [this.selectedLogin],
        });
        selectedLogin = this.selectedLogin;
      }
      this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_LOGIN_ACCESS_CODE.id,
          name: BulkOrderFilters.SALES_REP_LOGIN_ACCESS_CODE.name,
          values: [selectedLogin],
          hideClose:true
      });

      if (this.selectedAddressType) {
        request.addressType = this.selectedAddressType;
        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_ADDRESS_TYPE.id,
          name: BulkOrderFilters.SALES_REP_ADDRESS_TYPE.name,
          values: [this.selectedAddressType == AddressType.BILLING ? "Primary Address" : "Default Shipping Address"],
          hideClose: true
        });
      }


      if (this.searchTerm && this.searchTerm !== "") {
        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_SEARCH_TERM,
          values: [this.searchTerm],
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_SEARCH_TERM.id,
          name: BulkOrderFilters.SALES_REP_SEARCH_TERM.name,
          values: [this.searchTerm],
        });
      } else {
        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_SEARCH_TERM,
          values: [],
        });
      }


      if (
        !this.$nucleus.isEmpty(this.selectedRoles) &&
        this.selectedRoles.length !== this.availablePrimaryRoles.length
      ) {
        const tmpRoles = this.selectedRoles.map((s) => s);
        const selectedRoleNames = this.allRoles
          .filter((ar) => tmpRoles.includes(ar.id))
          .map((sr) => sr.name);

        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_PRIMARY_ROLE,
          values: tmpRoles,
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_PRIMARY_ROLE.id,
          name: BulkOrderFilters.SALES_REP_PRIMARY_ROLE.name,
          values: selectedRoleNames,
        });
      }

      if (
        !this.$nucleus.isEmpty(this.selectedStates) &&
        this.selectedStates.length !== this.availableStatesList.length
      ) {
        const tmpStates = this.selectedStates.map((s) => s);

        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_STATE,
          values: tmpStates,
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_STATE.id,
          name: BulkOrderFilters.SALES_REP_STATE.name,
          values: tmpStates,
        });
      }

      if (!this.$nucleus.isEmpty(this.selectedPostalCodes)) {
        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_POSTAL_CODE,
          values: this.selectedPostalCodes.split(","),
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_POSTAL_CODE.id,
          name: BulkOrderFilters.SALES_REP_POSTAL_CODE.name,
          values: this.selectedPostalCodes.split(","),
        });
      }

      if (!this.$nucleus.isEmpty(this.selectedGeoIds)) {
        const geoFilter = getGeoIdFilter();
        const splitValues = this.selectedGeoIds.split(",");

        geoFilter.values.push(...splitValues);

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_GEO_ID.id,
          name: BulkOrderFilters.SALES_REP_GEO_ID.name,
          values: splitValues,
        });
      }

      if (!this.$nucleus.isEmpty(this.selectedRegions)) {
       
        let tmpRegions = this.selectedRegions.map((s) => s);

        if(this.selectedRegions.length == this.regionsList.length) {
          tmpRegions = ['all'];
        }

        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_REGION_ID,
          values: tmpRegions,
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_REGION_ID.id,
          name: "Regions",
          values: tmpRegions,
        });
      }

      if (!this.$nucleus.isEmpty(this.selectedDistricts)) {
        let tmpDistricts = this.selectedDistricts.map((s) => s);

        if(this.selectedDistricts.length == this.districtsList.length) {
          tmpDistricts = ['all'];
        }

        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_DISTRICT_ID,
          values: tmpDistricts,
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_DISTRICT_ID.id,
          name: "Districts",
          values: tmpDistricts,
        });
      }

      if (!this.$nucleus.isEmpty(this.selectedTerritories)) {
        
        let tmpTerritories = this.selectedTerritories.map((s) => s);

        if(this.selectedTerritories.length == this.territoriesList.length){
          tmpTerritories = ['all'];
        }

        request.filters.push({
          filter: BulkOrderFilters.SALES_REP_TERRITORY_ID,
          values: tmpTerritories,
        });

        this.filterTags.push({
          id: BulkOrderFilters.SALES_REP_TERRITORY_ID.id,
          name: "Territories",
          values: tmpTerritories,
        });
      }

      this.currentSearchCriteria = Object.freeze(request);

      await this.searchRecipients(request);

      if (
        this.$nucleus.isEmpty(this.searchRecipientsResponse) ||
        this.$nucleus.isEmpty(this.searchRecipientsResponse.results)
      ) {
        ToastService.showToast(
          "The search did not return any results.",
          MessageType.WARNING
        );
      }
      else {
          this.setPaging(this.searchRecipientsResponse);
      }

      this.allUsersSelected = false;
      this.isRolesSelectorVisible = false;
      this.isStateSelectorVisible = false;

      function getGeoIdFilter() {
        if (
          !request.filters.some(
            (f) => f.filter === BulkOrderFilters.SALES_REP_GEO_ID
          )
        ) {
          const filter = {
            filter: BulkOrderFilters.SALES_REP_GEO_ID,
            values: [],
          };

          request.filters.push(filter);
        }

        return request.filters.find(
          (f) => f.filter === BulkOrderFilters.SALES_REP_GEO_ID
        );
      }
    },
    selectAllRoles() {
      this.selectedRoles = this.allRoles.map((ar) => ar.id);
    },
    selectAllStates() {
      this.selectedStates = this.statesList.map((s) => s.code);
    },
    selectAllRegions() {
      this.selectedRegions = this.regionsList.map((s) => s.value);
    },
    selectAllTerritories() {
      this.selectedTerritories = this.territoriesList.map((s) => s.value);
    },
    selectAllDistricts() {
      this.selectedDistricts = this.districtsList.map((s) => s.value);
    },
    selectRole(role) {
      if (this.selectedRoles.includes(role)) {
        this.selectedRoles = this.selectedRoles.filter((sr) => sr !== role);
      } else {
        this.selectedRoles.push(role);
      }
    },
    selectState(state) {
      if (this.selectedStates.includes(state)) {
        this.selectedStates = this.selectedStates.filter((sr) => sr !== state);
      } else {
        this.selectedStates.push(state);
      }
    },
    setSelectConfig() {
      this.selectConfig = {
        enabled: true,
      };
    },
    setSortConfig() {
      this.sortConfig = {
        enabled: true,
      };
    },
    toggleSelectAllUsers() {
      if (this.allUsersSelected) {
        const users = this.searchRecipientsResponse.results.map((r) => ({
          recipientId: r.recipientId,
          userName: r.userName,
          companyName: r.companyName,
          firstName: r.firstName,
          lastName: r.lastName,
          title: r.title,
          primaryUserRoleId: r.primaryUserRoleId,
          primaryUserRoleName: r.primaryUserRoleName,
          addressType: r.addressType,
          addressTypeName: r.addressTypeName,
          addressee: r.addressee,
          addressLine1: r.addressLine1,
          addressLine2: r.addressLine2,
          city: r.city,
          state: r.state,
          postalCode: r.postalCode,
          country: r.country,
          geoId: r.geoId,
          territoryName: r.territoryName,
          status: r.status,
          isUser: r.isUser,
          selected: true,
        }));

        const usersAdded = this.addUsersToBulkOrder(users);

        this.searchRecipientsResponse.results =
          this.searchRecipientsResponse.results.map((sr) => ({
            ...sr,
            selected: true,
          }));

        ToastService.showSuccessToast(
          `Added ${usersAdded} user(s) for a total of ${this.bulkOrderDetail.users.length} recipients.`
        );
      } else {
        const idsToRemove = this.searchRecipientsResponse.results.map(
          (r) => r.recipientId
        );

        this.removeUsersFromBulkOrder(idsToRemove);

        this.searchRecipientsResponse.results =
          this.searchRecipientsResponse.results.map((sr) => ({
            ...sr,
            selected: false,
          }));

        ToastService.showSuccessToast(
          `Removed ${idsToRemove.length} user(s) for a total of ${this.bulkOrderDetail.users.length} recipients.`
        );
      }
    },
    toggleSelectedUser(recipient) {
      const selectedRecipients = this.selectedRecipients.some((sr) => sr.recipientId === recipient.recipientId);
       if (selectedRecipients) {
        this.removeRecipient(recipient);
      } else {
        
        this.addRecipient(recipient);
      }
     },
    updateSelectedFilters(eventData) {
      switch (eventData.filter) {
        case BulkOrderFilters.SALES_REP_PRIMARY_ROLE:
          this.selectedRoles = eventData.data;
          break;
        case BulkOrderFilters.SALES_REP_STATE:
          this.selectedStates = eventData.data;
          break;
        case BulkOrderFilters.SALES_REP_REGION_ID:
          this.selectedRegions = eventData.data;
          break;
        case BulkOrderFilters.SALES_REP_DISTRICT_ID:
          this.selectedDistricts = eventData.data;
          break;
        case BulkOrderFilters.SALES_REP_TERRITORY_ID:
          this.selectedTerritories = eventData.data;
          break;
      }
    },
    toggleRolesSelector() {
      this.isRolesSelectorVisible = !this.isRolesSelectorVisible;
    },
    toggleStatesSelector() {
      this.isStateSelectorVisible = !this.isStateSelectorVisible;
    },
    async updatePage(page) {
      this.paging.pageNumber = page - 1;
      this.pagerPageNumber = page;

      await this.searchRecipients(this.currentSearchCriteria);

      this.selectedUsers = this.bulkOrderDetail.users;
      this.setPaging(this.searchRecipientsResponse);
    },
  },
  beforeDestroy() {
    this.removeListeners();
    document.removeEventListener("click", this.closeDropdowns);
    this.$eventBus.$off(UiEvents.CUSTOM_DROPDOWN_SELECTED);
  },
  async mounted() {
    this.initListeners();
    document.addEventListener("click", this.closeDropdowns);
    this.selectedStatus = this.availableStatuses[0].name;
    this.selectedAddressType = AddressType.BILLING;

    this.setSortConfig();
    this.setSelectConfig();
    this.selectedLogin = this.loginAccessList[0].id;
  },
};
</script>

<style lang="scss" scoped>

.n-custom-dropdown-display {
  display: flex;
}


.n-filter-area {
  background: $primary-lightest-color;
}

.search {
  margin-top: 0;
}
.table td,
.table th {
  vertical-align: middle;
}
.vgt-table th {
  vertical-align: middle;
}

.custom-dropdown {
  .custom-dropdown-selector {
    border: 1px solid #bbb;
    background: #f8f8f8;
    position: relative;
    z-index: 0.9;
  }

  .arrow {
    position: absolute;
    right: 10px;
    top: 40%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid #888;
  }

  .label {
    display: block;
    padding: 12px;
    font-size: 16px;
    color: #888;
  }

  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    border: 1px solid #bbb;
    position: absolute;
    z-index: 1;
    background: #fff;
  }

  li.highlight {
    padding: 8px;
    color: #666;

    &:hover {
      color: white;
      background: $tertiary-lightest-color;
    }
  }

  .dropdown-menu ul {
    max-height: 400px;
    overflow-y: auto;
  }

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
  .n-checkbox-group {
    font-size: 0.875rem;
    cursor: pointer;
  }
}
</style>
