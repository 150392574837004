<template>
    <div>
        <div v-if="showImpersonationAlert" class="alert alert-warning alert-emulation">
            <div class="row">
                <div class="col-auto align-self-start n-emulation-icon-container">
                    <font-awesome-icon class="fa-2x" :icon="['fas', 'user-friends']" />
                </div>
                <div class="col-auto n-emulation-message-container">
                    You are in an Emulation Session for <strong class="mr-2">{{user.firstName}} {{user.lastName}}</strong>

                </div>
                <div class="col-auto n-btn-emulation-container">
                    <button type="button" @click.prevent="endImpersonation()" class="btn btn-primary">End Emulation</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImpersonationService from "@/nucleus-modules/dd-nucleus-ui/services/impersonate.service.js";

    export default {
        name: "ImpersonationAlert",
        data() {
            return {
                showImpersonationAlert: false,
                user: {
                    id: '',
                    firstName: '',
                    lastName: ''
                }
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.user = ImpersonationService.getImpersonatedUser();
                if (this.user) {
                    this.showImpersonationAlert = true;
                }
            },
            endImpersonation() {
                ImpersonationService.endImpersonation();
                this.$router.push('/emulation-ended');
            },
        },
    };
</script>


<style scoped lang="scss">
    .alert-emulation {
        border-color: $warning-light-border;
        border-width: 3px;
    }

    .n-emulation-message-container {
        line-height: 2.0;
    }

    .n-emulation-icon-container {
    }

    @media (max-width: 769px) {
        .n-emulation-icon-container {
            display: none;
        }

        .n-emulation-message-container {
            text-align: center;
        }

        .n-emulation-message-container strong {
            text-align: center;
            display: inline-block;
        }

        .n-btn-emulation-container {
            text-align: center;
            margin: 0 auto;
            display: block;
        }
    }
</style>