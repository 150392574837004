<template>
    <Modal :size="'modal-xl'">
        <ModalHeader>
            <h3 class="d-inline float-left n-title-text">
                {{name}}
            </h3>
            <ModalClose/>
        </ModalHeader>
        <ModalBody>
            <div class="text-center">
                <img :src="mainMedia" :alt="name" class="img-fluid"/>
            </div>
        </ModalBody>
        <ModalFooter>
            <button class="btn btn-primary" @click="close()">Close</button>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from '@/nucleus-modules/dd-nucleus-ui/components/modal/Modal';
import ModalHeader from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalHeader';
import ModalBody from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalBody';
import ModalFooter from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalFooter';
import ModalMixin from '@/nucleus-modules/dd-nucleus-ui/mixins/ModalMixin';
import ModalClose from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalClose';
import ModalService from '@/nucleus-modules/dd-nucleus-ui/services/modal.service';

export default {
    name: "ModalImage",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        ModalClose
    },
    data() {
        return {
            'mainMedia': this.$attrs.url,
            'name': this.$attrs.name
        }
    },
    mixins: [ModalMixin],
    methods: {
        closeModal() {
            ModalService.close();
        }
    },
}
</script>