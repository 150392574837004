<template>
  <div class="n-message">
    <template v-if="large">
      <div class="alert" :class="'alert-' + variant">
        {{ message.text }}
      </div>
    </template>
    <template v-else>
      <div class="d-flex">
        <div class="flex-shrink-1 pr-1">
          <font-awesome-icon :icon="icon" :class="[colorClass]" />
        </div>
        <div class="w-100">
          <span :class="[colorClass, fontWeight]"
            >{{ message.text }}</span
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants";

/* message object:
 *  type - One of the values in MessageType
 *  icon - If supplied, the FontAwesome icon to use
 *  text - Text for the message
 */

export default {
  name: "message",
  props: {
    message: {
      type: Object,
      required: true,
    },
    // If set, this will use larger presentation (e.g. Bootstrap alert boxes). Otherwise, just an icon and text.
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    bold: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    // Compute the icon to be used. It can be supplied in the icon property, or if one is not set, it will use the message type.
    icon() {
      if (this.message.icon && this.message.icon.length > 0)
        return this.message.icon;
      else {
        switch (this.message.type) {
          case MessageType.ERROR:
            return "times-circle";
          case MessageType.WARNING:
            return "exclamation-circle";
          case MessageType.INFO:
            return "info-circle";
          case MessageType.SUCCESS:
            return "check-circle";
          default:
            return "";
        }
      }
    },

    // Compute the CSS class to use based on the message type
    colorClass() {
      if (this.message.type != MessageType.CUSTOM) {
        return "text-" + this.variant;
      } else {
        return this.variant;
      }
    },

    // Compute the CSS class to use based on the message type
    variant() {
      switch (this.message.type) {
        case MessageType.ERROR:
          return "danger";
        case MessageType.WARNING:
          return "warning";
        case MessageType.INFO:
          return "info";
        case MessageType.SUCCESS:
          return "success";
        case MessageType.NEUTRAL:
          return "muted";
        case MessageType.CUSTOM:
          return this.message.customClass;
        default:
          console.warn(
            "<Message> does not handle message type " + this.message.type
          );
          return "";
      }
    },

    fontWeight() {
      if (this.bold) {
        return "font-weight-bold";
      } else {
        return "font-weight-normal";
      }
    },
  },
};
</script>
