// auth
export const ASSIGN_USER_FROM_JWT = "assignUserFromJwt";

// cart
export const ADD_TO_CART = "addToCart"
export const ASSIGN_SHIPPING_ADDRESS = "assignShippingAddress";
export const ASSIGN_SHIPPING_METHOD = "assignShippingMethod";
export const GET_CART = "getCart";
export const REMOVE_FROM_CART = "removeFromCart";
export const UPDATE_CART_ITEM = "updateCartItem";

// category
export const GET_CATEGORY_ROOT = "getCategoryRoot";

// help
export const GET_HELP_MENU = "getHelpMenu";

// omnisearch
export const INIT_OMNISEARCH_RESULTS = "initOmniSearchResults";
export const EXECUTE_OMNISEARCH = "executeOmniSearch";

// orders
export const CREATE_CONTACT_ADDRESS = "createContactAddress";
export const GET_ORDER_BY_ID = "getOrderById";
export const GET_SHIPPING_DETAILS = "getShippingDetails";
export const PLACE_ORDER = "placeOrder";
export const SEARCH_ORDERS = "searchOrders";
export const GET_ORDER_DETAILS = "getOrderDetail";
export const GET_ORDER_HISTORY = "getOrderHistory";
export const ADJUST_INVENTORY = "adjustInventory";

// product
export const ADD_FAVORITE = "addProductAsFavorite";
export const REMOVE_FAVORITE = "removeProductAsFavorite";
export const GET_FAVORITES = "getFavoriteProducts";
export const GET_PRODUCT_DETAIL = "getProductDetail";
export const SEARCH_PRODUCTS = "searchProducts";
export const GET_FEATURED_PRODUCTS = "getFeaturedProducts";

// store config
export const GET_SITE_SETUP = "getSiteSetup";
export const ADD_ACTIVE_CATEGORY = "addActiveCategory";
export const RESET_ACTIVE_CATEGORIES = "resetActiveCategories";

//user
export const GET_USER_CONTACT = "getUserContact"
export const GET_USER_ADDRESSES = "getUserAddresses"

// shipAddress
export const UPDATE_SHIP_ADDRESS = "updateShipAddress"

