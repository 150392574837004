<template>
    <PageTemplate :showHeader="true">
        <div class="row test-page">
            <div class="col-12 text-left">
                <h1>
                    Test Page
                </h1>
                <h2>Test Get Inventory</h2>
                <button class="btn btn-outline-primary mr-3"
                        @click="getInventory()">
                    Get Inventory
                </button>
					<ul>
						<li v-for="inventory in inventoryQuantities" :key="inventory.itemNumber">{{inventory.itemNumber}} : {{inventory.qunatitiyAvailable}}</li>
					</ul>                
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import PageTemplate from "@/pages/templates/PageTemplate";
    import { mapActions, mapGetters } from "vuex";
    import {GET_INVENTORY_QUANTITY } from "@/store/storefront/actions.type.js";    
    import { INVENTORY_QUANTITY_GETTER } from "@/store/storefront/getters.type.js";

    export default {
        name: "TestPage",
        components: {
            PageTemplate
        },
        data() {
            return {
                items: [{ "itemNumber": "DUP.20.01.0136" }, { "itemNumber": "PRA.21.03.0026" }, { "itemNumber": "EVK.20.11.0040" }]
            }
        },
        computed: {
            ...mapGetters({
                inventoryQuantities: INVENTORY_QUANTITY_GETTER
            }),
        },
        methods: {
            ...mapActions({
                getInventoryQuantities: GET_INVENTORY_QUANTITY,
            }),
            async getInventory() {
                await this.getInventoryQuantities(this.items);
            }
        }
    }
</script>

<style lang="scss" scoped>
    h1 {
        padding: 30px 0;
    }

    test-page {
        min-height: 300px;
    }
</style>
