import { mapActions } from "vuex";

import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import {
    ADD_TO_CART
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

export const CartMixin = {
    methods: {
        ...mapActions({
            addToCartAction: ADD_TO_CART
        }),
        async addToCart(cartId, product, quantity) {
            const result = await this.addToCartAction({
                cartId: cartId,
                productId: product.id,
                quantity: quantity
            });

            if (result.succeeded) {
                const h = this.$createElement;
                
                const messagesByProductId = result.cart.items.find(i => i.product.id === product.id).messages;

                if (messagesByProductId.length > 0) {
                    const warningMessage = h("span", [
                        h("strong", `${product.itemNumber}`),
                        " has been added to your cart. Review important message before placing your order.",
                    ]);
                
                    const msg = h("span", [
                        h("span", [warningMessage]),
                        h("a", { attrs: { href: "/shopping-cart" } }, "View Cart"),
                    ]);

                        ToastService.showWarningToast(msg);
                } else {
                    const successMessage = h("span", [
                        h("strong", `${product.itemNumber}`),
                        " has been added to your cart",
                    ]);

                    ToastService.showToast([successMessage], MessageType.SUCCESS);
                }
            } else {
                if (result.messages) {
                    const firstMessage = result.messages.find((m) => m.length > 0);

                    if (firstMessage) {
                        ToastService.showToast(firstMessage.message, firstMessage.type);
                    }
                    else if (result.messages[0]?.debugMessage) {
                        ToastService.showToast(
                            result.messages[0]?.debugMessage,
                            MessageType.ERROR
                        );
                    }
                    else {
                        ToastService.showToast(
                            "Item was not added to cart.",
                            MessageType.ERROR
                        );
                    }
                }
            }
        }
    }
}