// addresses
export const SEARCH_ADDRESSES = "searchAddresses";

//crm address
export const ADD_FAVORITE_ADDRESS = "addAddressAsFavorite";
export const ASSIGN_CRM_SHIPPING_ADDRESS = "assignCrmShippingAddress";
export const CRM_ADDRESS_ADD_FILTER = "crmAddressAddFilter";
export const CRM_ADDRESS_RESET_ALL_FILTERS = "crmAddressResetAllFilters";
export const CRM_ADDRESS_UPDATE_FILTER = "crmAddressUpdateFilter";
export const REMOVE_FAVORITE_ADDRESS = "removeAddressAsFavorite";
export const SEARCH_CRM_ADDRESSES = "searchCRMAddresses";

//get inventory quantities
export const GET_INVENTORY_QUANTITY = "getInventoryQuantity";

// menu
export const ADD_ACTIVE_MENU_ITEM = "addActiveMenuItem";
export const ADD_ACTIVE_ITEM = "addActiveItem";
export const RESET_ACTIVE_MENU_ITEMS = "resetActiveMenuItems";

// product
export const GET_NEW_PRODUCT = "getNewProduct";

// SSO
export const ASSIGN_SSO_TOKEN = "assignSSOToken";


export const CONTACT_US_SUBMIT = "contactUsSubmit";

export const GET_USER_ANNOUNCEMENT = "getUserAnnouncement";

//cart
export const GET_CART_ADD_ON_ITEM = "getCartAddOnItem";