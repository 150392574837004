import { mapActions, mapGetters, mapMutations } from "vuex";

import {    
    GET_ALL_ROLES,
    SEARCH_ORDERS
    
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import {
    ALL_ROLES_GETTER,      
    SEARCH_ORDERS_RESPONSE_GETTER,
    SITE_SETUP_GETTER,
    STATES_LIST_GETTER
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
    ShippingStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import { OrderFilters } from "../constants";


export const OrderApprovalsMixin = {
    computed: {
        ...mapGetters({
            allRoles: ALL_ROLES_GETTER,           
            ordersSearchResponse: SEARCH_ORDERS_RESPONSE_GETTER,
            siteSetup: SITE_SETUP_GETTER,
            statesList: STATES_LIST_GETTER
        }),
        shippingMethods() {
            let result = [];

            this.siteSetup.shipMethods[0].values.forEach(sm => {
                result.push({
                    code: sm.code,
                    name: sm.name,
                    minDaysInTransit: sm.minDaysInTransit,
                    maxDaysInTransit: sm.maxDaysInTransit,
                    cost: null,
                    estimatedArrival: `${sm.minDaysInTransit} - ${sm.maxDaysInTransit} Business Days Shipping`
                });
            });

            result.sort((a, b) => a.minDaysInTransit < b.minDaysInTransit ? 1 : -1);

            return result;
        }
    },
    methods: {
        ...mapActions({            
            getAllRoles: GET_ALL_ROLES,            
            searchOrdersAction: SEARCH_ORDERS
        }),
        ...mapMutations({
        }),
        getShippingStatusBadgeInfo(shippingStatus) {
            let result;

            switch (shippingStatus) {
                case ShippingStatus.NONE:
                case ShippingStatus.PENDING:
                case ShippingStatus.PARTIAL:
                    result = { className: "badge-pending", statusName: "Pending" };
                    break;
                case ShippingStatus.COMPLETED:
                    result = { className: "badge-complete", statusName: "Completed" };
                    break;
                default:
                    result = { className: "badge-cancelled", statusName: "Unknown" };
                    break;
            }

            return result;
        },  
        async searchOrders() {
            const request = { filters: [] };
            request.filters.push({ filterId: OrderFilters.STATUS, values: ["pending-approval"] });
            await this.searchOrdersAction(request);
        },        
        async updateShippingMethod(shippingMethod) {
            this.updateShippingMethodAction({ shippingMethod: shippingMethod });
        }        
    },
};
