// cart
export const SET_ADD_TO_CART_ERROR = "setAddToCartError";
export const SET_ASSIGN_SHIPPING_ADDRESS_ERROR = "setAssignShippingAddressError";
export const SET_ASSIGN_SHIPPING_METHOD_ERROR = "setAssignShippingMethodError";
export const SET_CART = "setCart";
export const SET_CART_ID = "setCartId";
export const SET_GET_CART_ERROR = "setGetCartError";
export const SET_IS_CART_LOADING = "setIsCartLoading";
export const SET_LATEST_CART_UPDATE = "setLatestCartUpdate";
export const SET_REMOVE_FROM_CART_ERROR = "setRemoveFromCartError";

// category
export const SET_CATEGORY_ROOT = "setCategoryRoot";

// help
export const SET_HELP_MENU = "setHelpMenu";

// omnisearch
export const SET_OMNISEARCH_RESULTS = "setOmniSearchResults";

// orders
export const SET_GET_ORDER_BY_ID_RESPONSE = "setGetOrderByIdResponse";
export const SET_GET_ORDER_BY_ID_ERRORS = "setGetOrderByIdError";
export const SET_ORDER_SEARCH_RESULTS = "setOrderSearchResults";
export const SET_ORDER_DETAILS = "setOrderDetail";
export const SET_PLACE_ORDER_ERROR = "setPlaceOrderError";
export const SET_PLACE_ORDER_RESPONSE = "setPlaceOrderResponse";
export const SET_SHIPPING_DETAILS = "setShippingDetails";
export const SET_ORDER_HISTORY = "setOrderHistory";


// product
export const SET_PRODUCT_AS_FAVORITE = "setProductAsFavorite";
export const UNSET_PRODUCT_AS_FAVORITE = "unsetProductAsFavorite";
export const SET_PRODUCT_DETAIL = "setProductDetail";
export const SET_PRODUCTS_SEARCH_RESULTS = "setProductsSearchResults";
export const SET_FAVORITES = "setFavoriteProducts";
export const SET_FEATURED_PRODUCTS = "setFeaturedProducts";
export const SET_FEATURED_PRODUCTS_ERRORS = "setFeaturedProductErrors";

// store config
export const SET_SITE_SETUP = "setSiteSetup";
export const SET_ACTIVE_CATEGORIES = "setActiveCategories";

// checkout
export const SET_SHIP_ADDRESS = "setShipAddress"

// user
export const SET_USER_CONTACT = "setUserContact"
export const SET_USER_ADDRESSES = "setUserAddresses"

