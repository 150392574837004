<template>
    <div>
        <button class="btn btn-transparent dropdown-toggle n-header-nav d-none d-lg-block"
                type="button"
                id="dropdownHelpMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
            <slot name="title"></slot>
        </button>
        <div class="dropdown-menu n-header-menu p-0 ml-3" aria-labelledby="dropdownHelpMenuButton">
            <template v-for="(item) in menu">
                <template v-if="isMenuItemVisible(item)">
                    <MenuItem :key="item.id"
                              :item="item"
                              :level="0"
                              :opened="item.opened"
                              v-on:onMenuClicked="onMenuClicked" />
                </template>
            </template>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
    import MenuItem from "@/components/MenuItem.vue";
    import MenuValidation from '@/mixins/MenuValidation.js';
    import MenuFunctions from '@/mixins/MenuFunctions.js';
    export default {
        name: "HeaderMenu",
        mixins: [MenuValidation,MenuFunctions],
        components: {
            MenuItem,
        },
    }
</script>

<style scoped lang="scss">
.btn {
    color:$primary-dark-color;
    letter-spacing: 1px;
}
.n-header-nav {
    text-align: left;
    font-size:1rem;

}
</style>
