<template>
  <div class="my-4" v-if="products && products.length">
    <div class="n-fp-heading">
      <span>
        <h4>{{ title }}
          <a href="#" class="n-view-all mx-3" @click="navigateToProducts" v-if="showAllLink">View All</a>
        </h4>
      </span>
    </div>
    <hr />
    <div v-if="products.length > 0">
      <ProductGridPanel
        :isLoading="isLoading"
        :products="products"
        :panelThreshold="defaultShowPanelThreshold"
        :noImageAvailableUrl="noImageAvailableUrl"
      ></ProductGridPanel>
    </div>
    <div v-else>
      <p>No results found.</p>
    </div>
  </div>
</template>

<script>
import ProductGridPanel from "@/components/product/ProductGridPanel.vue";
import { defaultShowPanelThreshold } from "@/companyAppConfig";
import {StorefrontEvents, TagNames} from "@/nucleus-modules/dd-nucleus-storefront/constants.js"
export default {
  name: "ProductHomePanel",
  components: {
    ProductGridPanel,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true
    },
    isLoading :{
      type: Boolean,
      required:false
    },
    showAllLink: {
      type: Boolean,
      required:false
    },
    noImageAvailableUrl: {
      type:String,
      required:false
    }
  },
  data() {
    return {
      defaultShowPanelThreshold : defaultShowPanelThreshold,
    };
  },
  computed: {
    showPanel() {
      return this.products && this.products.length > 0;
    },
  },
  methods: {
    navigateToProducts() {
        const query = { tags: TagNames.FEATURED }
        this.$eventBus.$emit(StorefrontEvents.NAVIGATE_TO_PRODUCTS, query);
    },
  },
  async created() {
  },
};
</script>

<style lang="scss" scoped>
hr {
  margin: 10px 0 10px 0;
}

.n-view-all {
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  color: $primary-color;
}

@media (max-width: 576px) {
  .n-view-all {
    margin-left: 0px !important;
  }
}
h4 {
  color: $primary-dark-color;
}
</style>
