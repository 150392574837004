<template>
  <PageTemplate>
    <div class="page-container">
      <div class="row mt-2">
        <div class="col-md-3 d-none d-lg-block">
          <AccountMenu :title="title" menu-id="account"></AccountMenu>
        </div>
        <div class="col-md-12 col-lg-9">
          <div class="row">
            <div class="col">
              <h3 class="n-page-header">My Profile</h3>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <Headline title="Account Information" />
            </div>
          </div>
          <div class="row mb-4" v-if="!this.$nucleus.isEmpty(userInfo)">
            <div class="col-md-6 col-sm-3">
              <AccountTile>
                <template v-slot:accountInfo>
                  <strong class="card-title"
                    >{{ userInfo.firstName }} {{ userInfo.lastName }}</strong
                  >
                  <div v-if="userContact.email" class="card-subtitle">
                    {{ userContact.email }}
                  </div>
                </template>
                <template v-slot:message>
                  <Message
                    class="mt-1 accountMessage"
                    :textIconColorSync="false"
                    :message="accountInformationMessage"
                  />
                </template>
              </AccountTile>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <Headline title="Shipping Address" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-3 mb-4">
              <AccountTile v-if="isShippingAddressExists">
                <template v-slot:accountInfo>
                  <div class="float-right">
                    <font-awesome-icon disabled :icon="['fas', 'lock']" />
                  </div>
                  <AddressDetails :address="defaultShippingAddress" />
                </template>
                <template v-slot:message>
                  <div class="d-flex n-warning font-weight-bold">
                    <div class="flex-shrink-1 pr-1">
                      <font-awesome-icon
                        mr-2
                        class="n-warning"
                        :icon="['fas', 'star']"
                      />
                    </div>
                    <div class="w-100">
                      <span>Default Shipping Address</span>
                    </div>
                  </div>
                  <Message
                    class="mt-1 accountMessage"
                    :textIconColorSync="false"
                    :message="shippingAddressMessage"
                  />
                </template>
              </AccountTile>
            </div>
            <div class="col-md-6 col-sm-3 mb-4">
              <AccountTile v-if="isAccountAddressExists">
                <template v-slot:accountInfo>
                  <div class="float-right">
                    <font-awesome-icon disabled :icon="['fas', 'lock']" />
                  </div>
                  <AddressDetails :address="defaultAccountAddress" />
                </template>
                <template v-slot:message>
                  <div class="d-flex n-warning font-weight-bold">
                    <div class="flex-shrink-1 pr-1">
                      <font-awesome-icon
                        mr-2
                        class="n-warning"
                        :icon="['fas', 'star']"
                      />
                    </div>
                    <div class="w-100">
                      <span>Default Account Address</span>
                    </div>
                  </div>
                  <Message
                    class="mt-1 accountMessage"
                    :textIconColorSync="false"
                    :message="accountAddressMessage"
                  />
                </template>
              </AccountTile>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageTemplate>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  GET_USER_CONTACT,
  GET_USER_ADDRESSES,
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import {
  USER_CONTACT_GETTER,
  USER_ADDRESSES_GETTER,
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

import PageTemplate from "@/pages/templates/PageTemplate";
import AccountMenu from "@/components/AccountMenu.vue";
import {
  AccountInformationMessage,
  AccountAddressMessage,
  ShippingAddressMessage,
} from "@/companyAppConfig";

import Headline from "@/nucleus-modules/dd-nucleus-ui/components/Headline";
import AccountTile from "@/nucleus-modules/dd-nucleus-ui/components/AccountTile";
import AddressDetails from "@/nucleus-modules/dd-nucleus-ui/components/address-book/AddressDetails.vue";
import Message from "@/nucleus-modules/dd-nucleus-ui/components/Message";

import { AddressType } from "@/constants";

import { formatting } from "@/nucleus-modules/dd-nucleus-ui/mixins/formattingMixin.js";

export default {
  name: "MyProfile",
  mixins: [formatting],
  components: {
    PageTemplate,
    AccountMenu,
    Headline,
    AccountTile,
    AddressDetails,
    Message,
  },
  data() {
    return {
      title: "My Account",
      accountInformationMessage: AccountInformationMessage,
      accountAddressMessage: AccountAddressMessage,
      shippingAddressMessage: ShippingAddressMessage,
    };
  },
  computed: {
    ...mapGetters({
      userContact: USER_CONTACT_GETTER,
      userAddresses: USER_ADDRESSES_GETTER,
      userInfo: USER_INFO_GETTER,
    }),
    defaultShippingAddress() {
      return this.getAddress(AddressType.SHIPPING);
    },
    defaultAccountAddress() {
      return this.getAddress(AddressType.ACCOUNT);
    },

    isShippingAddressExists() {
      if (this.defaultShippingAddress && this.defaultShippingAddress.id)
        return true;
      else return false;
    },

    isAccountAddressExists() {
      if (this.defaultAccountAddress && this.defaultAccountAddress.id)
        return true;
      else return false;
    },
  },
  methods: {
    ...mapActions({
      getUserAddresses: GET_USER_ADDRESSES,
      getUserContact: GET_USER_CONTACT,
    }),
    async setUserDetails(contactId) {
      await this.getUserContact(contactId);
      await this.getUserAddresses(contactId);
    },

    getAddress(addressType) {
      if (this.userAddresses && this.userAddresses.length > 0) {
        let address = this.userAddresses.find(
          (a) => a.addressType == addressType
        );
        if (this.userContact.phoneNumber)
          address.phoneNumber = this.formatPhoneNumber(this.userContact.phoneNumber);

        address.organization = address.companyName;
        if (address.addressee) {
          address.firstName = address.addressee;
        }

        const newAddress = Object.assign({}, address);
        return newAddress;
      } else {
        return {};
      }
    },
  },
  watch: {
    "userInfo.contactId": {
      handler: function (val, oldVal) {
        if (oldVal != val) {
          //call two methods..
          this.getUserContact(this.userInfo.contactId);
          this.getUserAddresses(this.userInfo.contactId);
        }
      },
      immediate: true,
    },
  }
};
</script>
<style scoped lang="scss">
.card-subtitle {
  color: $neutral-darker !important;
}

.card-title {
  color: $neutral-darker !important;
}

.n-warning {
  font-size: 0.875rem !important;
}

.accountMessage {
  font-size: 0.875rem !important;
  color: $neutral-darker !important;
}

.page-container {
  width: 100%;
  font-size: 0.85rem;
}

/deep/ h2 {
  margin-bottom: 0rem !important;
}
</style>
