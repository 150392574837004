<template>
    <div class="row n-signin-method align-items-center">
      <div class="n-logo d-lg-block mt-3 mx-2 flex-fill">
        <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
      </div>
      <div class="col-12 text-center n-sso-logout">
         You have successfully logged in using SSO. However the site is still under maintenance. Please come back later.
         <span v-if="userInfo">
         <p>User Information</p>
         <ul>
            <li>Firstname: {{userInfo.given_name}}</li>
            <li>Lastname: {{userInfo.family_name}}</li>
            <li>Email: {{userInfo.preferred_username}}</li>
         </ul>
         </span>
      </div>
    </div>
</template>

<script>
import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";
export default {
  name: "SiteMaintenance",
  components: {
      HeaderBranding
  },
  data() {
    return {
      userInfo: {}
    }
  },
  async mounted() {

    if(await this.$auth.isAuthenticated()){
      this.userInfo = await this.$auth.token.getUserInfo();
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 30px 0;
  font-size: 1.75rem;
}
.n-signin-method {
  width:100%;
  min-height: 300px;
}
.n-sso-logout {
  color:$primary-dark-color;
  font-weight: 600;
}
</style>
