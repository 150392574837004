<template>
  <div class="row">
    <div class="col-12">
      <h3 class="n-form-section-header">
        {{ title }}
        <font-awesome-icon
          v-if="editable"
          @click="emitEvent"
          class="ml-2"
          :icon="['fas', 'pencil']"
        />
      </h3>
      <hr v-if="hasThematicBreak" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageSubHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    hasThematicBreak: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    eventName: {
      type: String,
    },
  },
  methods: {
    emitEvent() {
      this.$eventBus.$emit(this.eventName);
    },
  },
};
</script>

<style lang="scss"></style>
