// product
export const ADD_PRODUCT_MEDIA = "addProductMedia";
export const DELETE_PRODUCT_MEDIA = "deleteProductMedia";
export const DOWNLOAD_IMAGE = "downloadImage";
export const GET_ADMIN_PRODUCT_DETAIL = "getAdminProductDetail";
export const GET_ALL_PRODUCTS = "getAllProducts";
export const GET_MEDIA_BY_PRODUCT = "getMediaByProduct";
export const GET_UPLOAD_PRODUCT_MEDIA_URL = "getUploadProductMedialUrl";
export const REGENERON_SAVE_PRODUCT = "regeneronSaveProduct";
export const UPDATE_PRODUCT_MEDIA = "updateProductMedia";

//menu
export const GET_ADMIN_MENU = "getAdminMenu";

//categories
export const GET_ALL_CATEGORIES = "getAllCategories";
export const GET_CATEGORIES_BY_PRODUCT = "getCategoriesByProduct";

//roles
export const GET_ALL_ROLES = "getAllRoles";
export const GET_ROLES_BY_PRODUCT = "getRolesByProduct";
export const SAVE_ROLES_FOR_PRODUCT = "saveRolesForProduct";

// users
export const REGENERON_GET_USERS = "regeneronGetUsers";
export const REGENERON_SAVE_USER = "regeneronSaveUser";



