<template>
  <div>
    <div class="mt-3">
      <div class="row mb-3">
        <div class="col-6 offset-6">
          <div class="input-group input-group-sm search">
            <input
              type="text"
              v-model="searchTerm"
              class="form-control n-search-input"
              placeholder="Search Business Name, Full Name or Address"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="search"
              >
                <font-awesome-icon
                  icon="search"
                  fixed-width
                ></font-awesome-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col d-flex justify-content-end">
          Showing {{ pageStartNumber }} - {{ pageEndNumber }} of
          {{ totalRecipients }}
        </div>
      </div>
      <div class="row">
        <div class="col-6 d-inline-flex">
          <h4>Preview Recipients</h4>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-end align-items-baseline">
            <VueExcelExport
              class="mr-2 n-action-button"
              :data="selectedRecipientsForExport"
              :columns="excelColumns"
              :file-name="'recipients'"
              :file-type="'xlsx'"
              :sheet-name="'Recipients'"
            >
              <font-awesome-icon
                :icon="['fa', 'file-excel']"
                class="fa-2x n-excel-export-button"
              ></font-awesome-icon>
            </VueExcelExport>
            <VueJsonToCsv
              class="mr-2 n-action-button"
              :json-data="selectedRecipientsForExport"
              csv-title="recipients"
              :labels="{
                userName: { title: 'User ID' },
                companyName: { title: 'Business Name' },
                firstName: { title: 'First Name' },
                lastName: { title: 'Last Name' },
                title: { title: 'Title' },
                primaryUserRoleName: { title: 'Primary Role' },
                addressTypeName: { title: 'Address Type' },
                addressee: { title: 'Addressee' },
                addressLine1: { title: 'Address 1' },
                addressLine2: { title: 'Address 2' },
                city: { title: 'City' },
                state: { title: 'State' },
                postalCode: { title: 'Postal Code' },
                geoId: { title: 'GEO ID' },
                territoryName: { title: 'Territory Name' },
              }"
            >
              <font-awesome-icon
                :icon="['fa', 'file-csv']"
                class="fa-2x n-csv-export-button"
              ></font-awesome-icon>
            </VueJsonToCsv>
            <div class="mr-2">
              <b-form-select
                v-model="paging.pageSize"
                :options="paging.perPageOptions"
              ></b-form-select>
            </div>
            <span class="small mr-2">per page</span>
            <div>
              <b-pagination
                v-model="pagerPageNumber"
                :total-rows="selectedRecipients.length"
                :per-page="paging.pageSize"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="table-responsive">
            <VueGoodTable
              class="table table-striped"
              :rows="recipients"
              :columns="columns"
            >
              <div slot="emptystate">
                <p>There are no recipients selected.</p>
              </div>
              <div slot="table-column" slot-scope="props">
                <span v-if="props.column.field === 'selection'">
                  <div class="form-group form-check d-inline text-center">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="allUsersSelected"
                      @change="toggleSelectAllUsers()"
                    />({{ selectedRecipients.length }})
                  </div>
                </span>
                <span v-else>
                  {{ props.column.label }}
                </span>
              </div>
              <div slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'addressTypeName'">
                  <span v-if="props.row.addressType === addressTypes.BILLING">
                    Primary
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </span>
                <span v-else-if="props.column.field === 'selection'">
                  <div class="form-group form-check d-inline text-center">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="props.row.selected"
                      @change="toggleSelectedUser(props.row)"
                    />
                  </div>
                </span>
                <span v-else-if="props.column.field === 'userName'">
                  <div class="row">
                    <div class="col">
                      {{ props.formattedRow[props.column.field] }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <UserBadges
                        :roles="props.row.roles"
                        :showActive="
                          props.row.status === statusCodes.ACTIVE ||
                          props.row.status === statusCodes.LOCKED
                            ? true
                            : false
                        "
                        class="mb-2"
                      ></UserBadges>
                    </div>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </div>
            </VueGoodTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueExcelExport from "@/nucleus-modules/dd-nucleus-ui/components/VueExcelExport.vue";
import VueJsonToCsv from "vue-json-to-csv";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";
import { PagingMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/PagingMixin.js";
import UserBadges from "@/nucleus-modules/dd-nucleus-admin/components/user/UserBadges.vue";
import {
  AddressType,
  StatusCodes,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "PreviewRecipients",
  mixins: [BulkOrdersMixin, PagingMixin],
  components: {
    VueGoodTable,
    VueExcelExport,
    VueJsonToCsv,
    UserBadges,
  },
  data() {
    return {
      addressTypes: AddressType,
      allUsersSelected: true,
      debouncedSearchTerm: null,
      pagerPageNumber: 1,
      statusCodes: StatusCodes,
      timeout: null,
      excelColumns: [
        { label: "User ID", field: "userName" },
        { label: "Business Name", field: "companyName" },
        { label: "First Name", field: "firstName" },
        { label: "Last Name", field: "lastName" },
        { label: "Title", field: "title" },
        { label: "Primary Role", field: "primaryUserRoleName" },
        { label: "Address Type", field: "addressTypeName" },
        { label: "Addressee", field: "addressee" },
        { label: "Address 1", field: "addressLine1" },
        { label: "Address 2", field: "addressLine2" },
        { label: "City", field: "city" },
        { label: "State", field: "state" },
        { label: "Postal Code", field: "postalCode" },
        { label: "Geo Id", field: "geoId" },
        { label: "Territory Name", field: "territoryName" },
      ],
      paging: {
        includeTotalCount: true,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 200,
        pageStartNumber: 0,
        pageEndNumber: 0,
        totalCount: 0,
        perPageOptions: [
          { value: 200, text: "200" },
          { value: 400, text: "400" },
          { value: 600, text: "600" },
          { value: 800, text: "800" },
          { value: 1000, text: "1000" },
        ],
      },
      columns: [
        { field: "selection", label: "", sortable: false, width: '100px'  },
        { field: "userName", label: "User ID", sortable: true,  width: '250px' },
        { field: "companyName", label: "Business Name", sortable: true,  width: '200px' },
        { field: "firstName", label: "First Name", sortable: true, width: '150px' },
        { field: "lastName", label: "Last Name", sortable: true, width: '150px'  },
        { field: "title", label: "Title", sortable: true, width: '180px'  },
        { field: "primaryUserRoleName", label: "Primary Role", sortable: true, width: '230px'  },
        { field: "addressTypeName", label: "Address Type", sortable: true, width: '130px'  },
        { field: "addressee", label: "Addressee", sortable: true, width: '200px'  },
        { field: "addressLine1", label: "Address 1", sortable: true, width: '220px'  },
        { field: "addressLine2", label: "Address 2", sortable: true, width: '150px'  },
        { field: "city", label: "City", sortable: true, width: '230px'  },
        { field: "state", label: "State", sortable: true, width: '100px'  },
        { field: "postalCode", label: "Postal Code", sortable: true, width: '150px'  },
        { field: "countryCode", label: "Country", sortable: true, hidden:true },
        { field: "geoId", label: "GEO ID", sortable: true, width: '150px'  },
        { field: "territoryName", label: "Territory", sortable: true, width: '180px'  },
      ],
    };
  },
  computed: {
    hasRecipients() {
      return this.recipients.length > 0;
    },
    pageStartNumber() {
      return this.pagerPageNumber - 1 === 0
        ? 1
        : (this.pagerPageNumber - 1) * this.paging.pageSize + 1;
    },
    pageEndNumber() {
      const totalPages = this.totalRecipients / this.paging.pageSize + 1;

      return Math.floor(totalPages) === this.pagerPageNumber
        ? this.totalRecipients
        : this.paging.pageSize * this.pagerPageNumber;
    },
    recipients() {
      if (!this.selectedRecipients) return [];

      let result = this.selectedRecipients;

      if (this.debouncedSearchTerm && this.debouncedSearchTerm.length > 2) {
        result = this.selectedRecipients.filter(
          (sr) =>
            sr.firstName.includes(this.debouncedSearchTerm) ||
            sr.lastName.includes(this.debouncedSearchTerm) ||
            sr.userName.includes(this.debouncedSearchTerm) ||
            sr.companyName.includes(this.debouncedSearchTerm) ||
            sr.addressLine1.includes(this.debouncedSearchTerm) ||
            sr.addressLine2.includes(this.debouncedSearchTerm) ||
            sr.title.includes(this.debouncedSearchTerm)
        );
      }

      return result;
    },
    selectedRecipientsForCsv() {
      if (!this.selectedRecipients) return [];

      const result = [];

      this.selectedRecipients.forEach((sr) => {
        if (sr.addressTypeName === AddressType.BILLING) {
          sr.addressTypeName = "Primary";
        }

        result.push(sr);
      });

      return result;
    },
    searchTerm: {
      get() {
        return this.debouncedSearchTerm;
      },
      set(value) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedSearchTerm = value;
        }, 200);
      },
    },
    totalRecipients() {
      return this.selectedRecipients.length;
    },
  },
  methods: {
    search() {
      this.debouncedSearchTerm = this.searchTerm;
    },
    toggleSelectAllUsers() {
      if (this.allUsersSelected) {
        const unselected = this.recipients.filter((r) => !r.selected);
        const updated = unselected.map((r) => ({ ...r, selected: true }));

        this.addUsersToBulkOrder(updated);
      } else {
        const toRemove = this.recipients.map((r) => r.recipientId);

        this.removeUsersFromBulkOrder(toRemove);
      }
    },
    toggleSelectedUser(recipient) {
      if (
        this.selectedRecipients.some(
          (sr) => sr.recipientId === recipient.recipientId
        )
      ) {
        this.removeRecipient(recipient);
      } else {
        this.addRecipient(recipient);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
