var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"n-searchbox"},[_c('div',{staticClass:"input-group search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],ref:"omniSearch",staticClass:"form-control n-search-input",attrs:{"type":"text","placeholder":_vm.$t('header.omnisearch.placeholderText'),"aria-label":"input-group mb-3"},domProps:{"value":(_vm.searchTerm)},on:{"focus":function($event){return _vm.setSearchResultsVisibility(true)},"blur":function($event){return _vm.setSearchResultsVisibility(false)},"keyup":_vm.handleKeyUp,"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-outline-secondary n-btn-search",attrs:{"type":"submit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.searchTerm && _vm.handleNavigation()}}},[_c('font-awesome-icon',{attrs:{"icon":"search","fixed-width":""}}),_c('span',{staticClass:"sr-only"},[_vm._v("Search")])],1)])]),_c('div',{staticClass:"n-searchbox-results"},[_c('b-list-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.omniSearchResults.length > 0 && _vm.showSearchBox),expression:"omniSearchResults.length > 0 && showSearchBox"}],style:(_vm.calculatedSearchResultsStyle)},[_vm._l((_vm.omniSearchResults),function(searchResult){return [_c('b-list-group-item',{key:searchResult.areaId,staticClass:"item-header"},[_c('div',{staticClass:"row n-show-results-header"},[(
                _vm.getTotalSearchResultsByArea(searchResult.areaName) >
                _vm.totalLinks
              )?[_c('div',{staticClass:"col-12"},[_c('h6',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(searchResult.areaName)+" "),(
                      _vm.getTotalSearchResultsByArea(searchResult.areaName) > 0
                    )?_c('span',[_vm._v(" ("+_vm._s(_vm.getTotalSearchResultsByArea(searchResult.areaName))+") ")]):_vm._e()]),_c('router-link',{staticClass:"n-see-all-results ml-2 float-right d-inline",attrs:{"to":("/product-results?search-term=" + _vm.searchTerm)}},[_vm._v(_vm._s(_vm.$t("header.omnisearch.allResultsLink")))])],1)]:[_c('div',{staticClass:"col-12"},[_c('h6',[_vm._v(" "+_vm._s(searchResult.areaName)+" "),(
                      _vm.getTotalSearchResultsByArea(searchResult.areaName) > 0
                    )?_c('span',[_vm._v(" ("+_vm._s(_vm.getTotalSearchResultsByArea(searchResult.areaName))+") ")]):_vm._e()])])]],2)]),(_vm.getTotalSearchResultsByArea(searchResult.areaName) > 0)?_vm._l((searchResult.results.slice(0, _vm.totalLinks)),function(child){return _c('b-list-group-item',{key:child.id,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showSearchBox = false}}},[(_vm.isProductsArea(searchResult.areaName))?_c('router-link',{attrs:{"to":{
                name: 'product-detail',
                params: { productId: child.id },
              }}},[(_vm.showProductItemNumber)?_c('strong',[_vm._v(_vm._s(child.description))]):_vm._e(),_vm._v(" "+_vm._s(child.name)+" ")]):_c('router-link',{attrs:{"to":("/search/" + (child.name))}},[_vm._v(" "+_vm._s(child.name)+" ")])],1)}):_c('b-list-group-item',{key:searchResult.id + '-no-results'},[_vm._v("No results were found")])]}),_vm._l((_vm.omniSearchResults),function(searchResult,index){return _c('b-list-group-item',{key:index},[(
            _vm.getTotalSearchResultsByArea(searchResult.areaName) > _vm.totalLinks
          )?[_c('div',{staticClass:"col-12 pl-0"},[_c('router-link',{staticClass:"n-see-all-results float-left d-inline",attrs:{"to":("/product-results?search-term=" + _vm.searchTerm)}},[_vm._v(_vm._s(_vm.$t("header.omnisearch.allResultsLink")))])],1)]:_vm._e()],2)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }