<template>
  <PageTemplate :showHeader="false">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center pt-5">
        <SignIn logo="/images/logo.png"/>
          <hr class="my-3"/>
          <div class="n-not-regeneron-text my-2 n-mb-6">
              Are you a Regeneron Employee?<a class="n-sign-in-link" @click="openIdSignIn">Sign In</a>
          </div>
      </div>
    </div>
  </PageTemplate>
</template>

<script>
import PageTemplate from "@/pages/templates/PageTemplate";
import SignIn from "@/components/auth/SignIn";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";

export default {
  name: "Sign-In",
  components: {
    PageTemplate,
    SignIn,
  },
  methods: {
    async openIdSignIn() {
      await this.$auth.signInWithRedirect();
      
    }
  },
  mounted() {
    if (this.$route.query.cp && this.$route.query.cp.length > 0)
      ToastService.showSuccessToast(
        this.$t("signIn.passwordUpdatedSuccessfully")
      );
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding: 30px 0;
  font-size: 1.75rem;
}
.n-not-regeneron-text {
  font-size: 0.875rem;
}
</style>
