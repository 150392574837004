<template>
    <div>
        <SignInOut 
        title="You have been logged out of the Regeneron Promotional Ordering Portal." 
        styleClass="n-signout-h"
        />
    </div>
</template>

<script>
    import SignInOut from "@/components/auth/SignInOut.vue"
    export default {
        name: "SignInMethod",
        components: {
            SignInOut
        },
    };
</script>

<style lang="scss" scoped>
</style>
