import { GET_HELP_MENU } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { HELP_MENU_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { SET_HELP_MENU } from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

const MODULE_ID = "help";

const initialState = {
    helpMenu: {}
};

export const state = { ...initialState };

export const actions = {
    async [GET_HELP_MENU]({ commit }) {
        const response = {
            "id": "help",
            "text": "Help",
            "helpText": null,
            "iconUrl": null,
            "customEventId": null,
            "scopes": null,
            "children": [
                {
                    "url": "/documents/FAQs.pdf",
                    "inNewWindow": true,
                    "isSeparator": false,
                    "id": "faq",
                    "text": "FAQ",
                    "helpText": "Answers to common questions",
                    "iconUrl": null,
                    "customEventId": null,
                    "scopes": null,
                    "children": []
                },
                {
                    "url": "/documents/UserGuide.pdf",
                    "inNewWindow": true,
                    "isSeparator": false,
                    "id": "guide",
                    "text": "User Guide",
                    "helpText": "Answers to common questions",
                    "iconUrl": null,
                    "customEventId": null,
                    "scopes": null,
                    "children": []
                }
            ]
        };

        commit(SET_HELP_MENU, response);
    }
};

export const getters = {
    [HELP_MENU_GETTER]: state => {
        return state.helpMenu;
    }
};

export const mutations = {
    [SET_HELP_MENU]: (state, helpMenu) => {
        state.helpMenu = helpMenu;
    }
}

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
