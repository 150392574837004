// cart
export const ADD_TO_CART_ERROR_GETTER = "addToCartError";
export const ASSIGN_SHIPPING_ADDRESS_ERROR_GETTER = "assignShippingAddressError";
export const ASSIGN_SHIPPING_METHOD_ERROR_GETTER = "assignShippingMethodError";
export const CART_GETTER = "cart";
export const IS_CART_LOADING_GETTER = "isCartLoading";
export const REMOVE_FROM_CART_ERROR_GETTER = "removeFromCartError";
export const TOTAL_CART_ITEMS_GETTER = "totalCartItems";

// category
export const ALL_CATEGORIES_ID_GETTER = "allCategoriesId";
export const CATEGORY_ROOT_GETTER = "categoryRoot";
export const FLATTENED_CATEGORIES_GETTER = "flattenedCategories";

// help
export const HELP_MENU_GETTER = "helpMenu";

// menu
export const MENUS_GETTER = "menus";
export const MENU_BY_ID_GETTER = "menuById";

// omnisearch
export const OMNISEARCH_RESULTS_GETTER = "omnisearchResults";

// orders
export const GET_ORDER_BY_ID_ERRORS_GETTER = "getOrderErrors";
export const ORDER_BY_ID_GETTER = "order";
export const PLACE_ORDER_ERRORS_GETTER = "placeOrderErrors";
export const PLACE_ORDER_RESPONSE_GETTER = "placeOrderResponse";
export const SEARCH_ORDERS_RESULTS_GETTER = "searchOrdersResults";
export const ORDER_DETAILS_GETTER = "orderDetail";
export const SHIPPING_DETAILS_GETTER = "shippingDetails";
export const ORDER_HISTORY_GETTER = "orderHistory";

// product
export const PRODUCT_DETAIL_GETTER = "productDetail";
export const SEARCH_PRODUCTS_RESULTS_GETTER = "searchProductsResults";
export const FAVORITES_GETTER = "favoriteProducts"
export const FEATURED_PRODUCTS_GETTER = "featuredProducts"
export const FEATURED_PRODUCTS_ERRORS_GETTER = "featuredProductErrors"

// store set
export const MONTHS_GETTER = "months";
export const SITE_SETUP_GETTER = "siteSetup";
export const STATES_LIST_GETTER = "statesList";
export const YEARS_GETTER = "years";
export const ACTIVE_CATEGORIES_GETTER = "activeCategories";

export const USER_CONTACT_GETTER = "userContact"
export const USER_ADDRESSES_GETTER = "userAddresses";

//export
export const SHIP_ADDRESS_GETTER = 'shipAddress';
