<template>
  <div class="badge badge-primary" :title="tooltip">
    <div class="d-flex justify-content-between">
      <div>
        <div>{{ title }}</div>
        <div v-if="value.length > 0">{{ value }}</div>
      </div>
      <div v-if="showClose">
        <button type="button" class="close" @click="removeFilter">
          <span>&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterCriteriaItem",
  props: {
    filterTag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasValues() {
      return this.filterTag && this.filterTag.values;
    },
    title() {
      return this.filterTag.name;
    },
    tooltip() {
      if (this.hasValues) {
        return this.filterTag.values.join(",");
      }

      return "";
    },
    showClose() {
        return !this.filterTag.hideClose;
    },
    value() {
      if (this.hasValues) {
        if (this.filterTag.values.length > 1) {
          return `${this.filterTag.values.length} Selected`;
        } else {
          return this.filterTag.values[0];
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    removeFilter() {
      this.$emit("remove-filter", { id: this.filterTag.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  .close {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: -5px;
    font-size: inherit;
    color: inherit;
    text-shadow: none;
  }
}
</style>
