import { render, staticRenderFns } from "./BulkOrdersHome.vue?vue&type=template&id=683c74df&scoped=true&"
import script from "./BulkOrdersHome.vue?vue&type=script&lang=js&"
export * from "./BulkOrdersHome.vue?vue&type=script&lang=js&"
import style0 from "./BulkOrdersHome.vue?vue&type=style&index=0&id=683c74df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683c74df",
  null
  
)

export default component.exports