<template>
  <div>
    <PageSubHeader title="Roles" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-xs-12 col-sm-5">
        <dl v-for="roleId in user.userRoleIds" :key="roleId">
          <dt>
            {{ getRoleName(roleId) }}
            <font-awesome-icon
              v-if="user.primaryRoleId == roleId"
              :icon="['fa', 'star']"
              class="n-primary-role"
            />
          </dt>
        </dl>
      </div>
      <div class="col-xs-12 col-sm-7">
        <span class="n-primary-role float-md-right"
          ><font-awesome-icon :icon="['fa', 'star']" class="n-primary-role" />
          Primary Role</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

export default {
  name: "UserRolesViewOnly",
  props: {
    user: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  components: {
    PageSubHeader,
  },
  created: function() {
    this.linkPrimaryRole();
  },
  methods: {
    linkPrimaryRole() {
      // primary role may not exist in the user roles, check if it exists and add if needed.
      if (
        this.user.primaryRoleId &&
        this.user.userRoleIds.indexOf(this.user.primaryRoleId) == -1
      ) {
        this.user.userRoleIds.unshift(this.user.primaryRoleId);
      }
    },
    getRoleName(roleId) {
      return this.roles.filter((role) => role.id == roleId)[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
.n-primary-role {
  font-size: 14px !important;
  color: $primary-color;
}
</style>
