<template>
  <div></div>
</template>

<script>
export default {
  name: "FooterMenu",
};
</script>

<style scoped lang="scss">
.n-footer-menu a,
.n-footer-menu a:hover,
.n-footer-menu a:visited,
.n-footer-menu a:active {
  color: $header-menu-text-color;
  text-decoration: none !important;
}

.n-footer-menu .title {
  font: $header-menu-text-font;
  text-decoration: none !important;
  padding-left: 0px;
  padding-right: 20px;
  line-height: 48px;
}
</style>
