<template>
<div>
    <h4 class="mb-3">Custom Information</h4>
    <div class="row">
        <div class="col">
            <h5 class="n-form-section-header">Item Details</h5>
            <form name="addlInfoForm">
                <div class="row">
                    <div class="col-5">

                        <div class="form-group mt-4">
                            <label class="n-form-labels">Product</label>
                            <b-dropdown class="test" id="dropdown-form" :text="primaryBrandLabel" ref="dropdown" variant="white n-custom-dropdown">
                                <b-dropdown-form>
                                    <b-dropdown-item @click="selectAllBrand()" class="">Select All</b-dropdown-item>
                                    <b-dropdown-item @click="deSelectAllBrand()" class="">Deselect All</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-form-checkbox-group ref="element"  id="brand" v-model="brand" :options="getAttribueValueById(attributeValueBrand)" value-field="code" text-field="name" name="brandSearch" :stacked="true" @change="brandSearch" />
                                </b-dropdown-form>
                            </b-dropdown>
                        </div>

                        <div class="form-group mt-4">
                            <label class="n-form-labels">Team Name</label>
                            <b-form-select v-model="subCategoryType" :options="getAttribueValueById(attributeValueTeamName)" value-field="code" text-field="name" class="n-text-field-highlight">
                                <template #first>
                                    <b-form-select-option value="">Select Team Name</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Owner</label>
                            <input type="text" class="form-control n-text-field-highlight" v-model.trim="owner" />
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Spend Product/Therapeutic Area</label>
                            <b-form-select v-model="spendProductTheraputicArea" :options="availableSpendProductArea" value-field="id" text-field="name" class="n-text-field-highlight">
                                <template #first>
                                    <b-form-select-option :value="null">Select Product/Therapeutic Area</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Spend Purpose</label>
                            <b-form-select v-model="spendPurpose" :options="availableSpendPurpose" value-field="id" text-field="name" class="n-text-field-highlight">
                                <template #first>
                                    <b-form-select-option :value="null">Select Spend Purpose</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>

                        <div class="form-group mt-4">
                            <label class="n-form-labels">Intended Use</label>
                            <b-dropdown class="test" id="dropdown-form" :text="primaryIntendedUseLabel" ref="dropdown" variant="white n-custom-dropdown">
                                <b-dropdown-form>
                                    <b-dropdown-item @click="selectAllIntendedUse()" class="">Select All</b-dropdown-item>
                                    <b-dropdown-item @click="deSelectAllIntendedUse()" class="">Deselect All</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-form-checkbox-group ref="element" id="intendedUse" v-model="intendedUse" :options="getAttribueValueById(attributeValueIntendedUse)" value-field="code" text-field="name" name="intendedUseSerach" :stacked="true" @change="intendedUseSerach" />
                                </b-dropdown-form>
                            </b-dropdown>
                        </div>

                        <div class="form-group mt-4">
                            <label class="n-form-labels">Brand Team</label>
                            <b-form-select v-model="brandTeam" :options="getAttribueValueById(attributeValueBrandTeam)" value-field="code" text-field="name" class="n-text-field-highlight">
                                <template #first>
                                    <b-form-select-option :value="null">Select Brand Team</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">PI Code</label>
                            <input class="form-control n-check-item-value" type="checkbox" v-model="piCode">
                        </div>
                    </div>
                    <div class="col-5 offset-2">
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Product Type</label>
                            <b-form-select v-model="productType" :options="availableProductTypes" value-field="id" text-field="name" class="n-text-field-highlight">
                                <template #first>
                                    <b-form-select-option value="">Select Product Type</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Purchase Price($)</label>
                            <input type="number" step="0.01" min="0" class="form-control n-text-field-highlight" v-model.trim="purchasePrice" />
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Reorder Point</label>
                            <input type="text" class="form-control n-text-field-highlight" v-model.trim="reorderPoint" />
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Item of Value</label>
                            <input class="form-control n-check-item-value" type="checkbox" v-model="itemValue">
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Spend Nature/Type</label>
                            <b-form-select v-model="spendNatureType" :options="availableSpendNature" value-field="id" text-field="name" class="n-text-field-highlight">
                                <template #first>
                                    <b-form-select-option :value="null">Select Spend Nature/Type</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>

                        <div class="form-group mt-4">
                            <label class="n-form-labels">Indication</label>
                            <b-dropdown class="test" id="dropdown-form" :text="primaryIndicationLabel" ref="dropdown" variant="white n-custom-dropdown">
                                <b-dropdown-form>
                                    <b-dropdown-item @click="selectAllIndications()" class="">Select All</b-dropdown-item>
                                    <b-dropdown-item @click="deSelectAllIndications()" class="">Deselect All</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-form-checkbox-group ref="element" id="indication" v-model="indication" :options="getAttribueValueById(attributeValueIndication)" value-field="code" text-field="name" name="indicationSerach" :stacked="true" @change="indicationSerach" />
                                </b-dropdown-form>
                            </b-dropdown>
                        </div>
                        <div class="form-group mt-4">
                            <label class="n-form-labels">Alliance Partner</label>
                            <input type="text" class="form-control n-text-field-highlight" v-model.trim="alliancePartner" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters,
    mapMutations
} from "vuex";
import {
    PRODUCT_PROPERTY_GETTER
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
    SITE_SETUP_GETTER
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
    SET_PRODUCT_PROPERTY
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import {
    AvailableBrands,
    AvailableProductTypes,
    AvailableSpendProductArea,
    AvailableSpendPurpose,
    AvailableSpendNature,
    AttributeValueIndication,
    AttributeValueIntendedUse,
    AttributeValueBrand,
    AttributeValueBrandTeam,
    AttributeValueTeamName

} from "@/constants.js";

const ATTRIBUTE_NAME = "ExtendedProductProperties";

export default {
    name: "AdditionalInformation",
    props: {

    },
    data() {
        return {
            availableBrands: AvailableBrands,
            availableProductTypes: AvailableProductTypes,
            availableSpendProductArea: AvailableSpendProductArea,
            availableSpendPurpose: AvailableSpendPurpose,
            availableSpendNature: AvailableSpendNature,
            attributeValueIndication: AttributeValueIndication,
            attributeValueIntendedUse: AttributeValueIntendedUse,
            attributeValueBrand: AttributeValueBrand,
            attributeValueBrandTeam: AttributeValueBrandTeam,
            attributeValueTeamName: AttributeValueTeamName,
            primaryIndicationLabel: "",
            primaryIntendedUseLabel: "",
            primaryBrandLabel: "",
            selectedBrand: {
                type: Array,
            },
            selectedIntendedUse: {
                type: Array,
            },

            selectedIndication: {
                type: Array,
            },
        };
    },
    validations: {
        purchasePrice: {},
    },
    computed: {
        ...mapGetters({
            getProductProperty: PRODUCT_PROPERTY_GETTER,
            siteSetup: SITE_SETUP_GETTER
        }),

        attributeValues() {
            return this.ConvertAarrayOjectPropertiesToUpper(this.siteSetup.attributeValues);
        },
        brand: {
            type: Array,
            get() {
                return this.getProductPropertyValue("brand", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "brand",
                    value: !this.$refs.element ?   value : this.selectedBrand,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        indication: {
            type: Array,
            get() {
                return this.getProductPropertyValue("indication", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "indication",
                    value: !this.$refs.element ?   value : this.selectedIndication,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },

        intendedUse: {
            type: Array,
            get() {
                return this.getProductPropertyValue("intendedUse", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "intendedUse",
                    value: !this.$refs.element ?   value : this.selectedIntendedUse,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        productType: {
            get() {
                return this.getProductProperty("productType", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "productType",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        subCategoryType: {
            get() {
                return this.getProductPropertyValue("subCategoryType", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "subCategoryType",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        purchasePrice: {
            get() {
                return this.getProductProperty("purchasePrice", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "purchasePrice",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        owner: {
            get() {
                return this.getProductProperty("owner", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "owner",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        reorderPoint: {
            get() {
                return this.getProductProperty("reorderPoint", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "reorderPoint",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        itemValue: {
            get() {
                return this.getProductProperty("regulatoryFlag", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "regulatoryFlag",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        spendProductTheraputicArea: {
            get() {
                return this.getProductProperty("spendProductTheraputicArea", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "spendProductTheraputicArea",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        spendPurpose: {
            get() {
                return this.getProductProperty("spendPurpose", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "spendPurpose",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },
        spendNatureType: {
            get() {
                return this.getProductProperty("spendNatureType", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "spendNatureType",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },

        alliancePartner: {
            get() {
                return this.getProductProperty("alliancePartner", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "alliancePartner",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },

        brandTeam: {
            get() {
                return this.getProductPropertyValue("brandTeam", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "brandTeam",
                    value: (value) ? value.toUpperCase() : '',
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },

        piCode: {
            get() {
                return this.getProductProperty("piCode", ATTRIBUTE_NAME);
            },
            set(value) {
                if (this.$nucleus.isEmpty(value)) value = null;
                this.setProductProperty({
                    name: "piCode",
                    value: value,
                    attribute: ATTRIBUTE_NAME,
                });
            },
        },

    },
    methods: {
        ...mapMutations({
            setProductProperty: SET_PRODUCT_PROPERTY,
        }),
        clearDates() {
            this.startOrderDate = this.endOrderDate = this.daysToQuarantineDate = null;
        },
        validateNumber: (event) => {
            let keyCode = event.keyCode;
            if (keyCode < 48 || keyCode > 57) {
                event.preventDefault();
            }
        },

        getProductPropertyValue(fieldName, attributeName) {
            if (fieldName && attributeName) {
                let value = this.getProductProperty(fieldName, attributeName);
                if (value) {
                    if (Array.isArray(value)) {
                        return value.map(v => v.toUpperCase())
                    } else {
                        return value.toUpperCase();
                    }
                }
            }
            return null;
        },

        getAttribueValueById(attributeId) {
            return this.attributeValues.filter(item => {
                return item.attributeId == attributeId
            });
        },

        selectAllIndications() {
            this.selectedIndication = this.getAttribueValueById(this.attributeValueIndication).map((ar) => ar.code.toUpperCase());
            this.indication = this.selectedIndication;
            this.primaryIndicationLabel = this.setIndecationDdlLabel();
        },
        deSelectAllIndications() {
            this.selectedIndication = [];
            this.indication = [];
            this.primaryIndicationLabel = this.setIndecationDdlLabel();
        },

        indicationSerach(indications) {
            this.selectedIndication = Array.isArray(indications) ? indications : [indications]
            this.indication = this.selectedIndication;
            this.primaryIndicationLabel = this.setIndecationDdlLabel();
        },

        setIndecationDdlLabel() {
            if (this.selectedIndication && this.selectedIndication.length == 1) {
                return this.getAttribueValueById(this.attributeValueIndication).find((x) => x.code === this.selectedIndication[0]).name;
            }

            if (this.selectedIndication && this.selectedIndication.length > 1) {
                return this.selectedIndication.length + " Selected";
            }
            if (!this.$refs.element) {
                this.brand= this.selectedIndication=[];
            }

            return "Select Indication";
        },

        //Start of methods related to intended use multidelect
        selectAllIntendedUse() {
            this.selectedIntendedUse = this.getAttribueValueById(this.attributeValueIntendedUse).map((ar) => ar.code.toUpperCase());
            this.intendedUse = this.selectedIntendedUse;
            this.primaryIntendedUseLabel = this.setIntendedUseDdlLabel();
        },
        deSelectAllIntendedUse() {
            this.selectedIntendedUse = [];
            this.intendedUse = [];
            this.primaryIntendedUseLabel = this.setIntendedUseDdlLabel();
        },

        intendedUseSerach(intendedUses) {
            this.selectedIntendedUse = Array.isArray(intendedUses) ? intendedUses : [intendedUses]
            this.intendedUse = this.selectedIntendedUse
            this.primaryIntendedUseLabel = this.setIntendedUseDdlLabel();
        },

        setIntendedUseDdlLabel() {
            if (this.selectedIntendedUse && this.selectedIntendedUse.length == 1) {
                return this.getAttribueValueById(this.attributeValueIntendedUse).find((x) => x.code === this.selectedIntendedUse[0]).name;
            }

            if (this.selectedIntendedUse && this.selectedIntendedUse.length > 1) {
                return this.selectedIntendedUse.length + " Selected";
            }
            if (!this.$refs.element) {
                this.brand= this.selectAllIntendedUse=[];
            }

            return "Select Intended Use";
        },
        // End of methods related to intended use multidelect

        // Start of methods related to brand multidelect 
        selectAllBrand() {
            this.selectedBrand = this.getAttribueValueById(this.attributeValueBrand).map((ar) => ar.code.toUpperCase());
            this.brand = this.selectedBrand;
            this.primaryBrandLabel = this.setBrandDdlLabel();
        },
        deSelectAllBrand() {
            this.selectedBrand = [];
            this.brand = [];
            this.primaryBrandLabel = this.setBrandDdlLabel();
        },

        brandSearch(brands) {
            this.selectedBrand = Array.isArray(brands) ? brands : [brands]
            this.brand = this.selectedBrand;
            this.primaryBrandLabel = this.setBrandDdlLabel();
        },

        setBrandDdlLabel() {
            if (this.selectedBrand && this.selectedBrand.length == 1) {
                return this.getAttribueValueById(this.attributeValueBrand).find((x) => x.code === this.selectedBrand[0]).name;
            }

            if (this.selectedBrand && this.selectedBrand.length > 1) {
                return this.selectedBrand.length + " Selected";
            }

            if (!this.$refs.element) {
                this.brand= this.selectedBrand=[];
            }

            return "Select Product";
        },

        // End of methods related to brand multidelect 
        ConvertArrayElemetsToUpper(array) {
            let list = []
            if (array) {
                array.forEach((value) => {

                    list.push(value.toUpperCase())
                });
            }
            return list;
        },

        ConvertAarrayOjectPropertiesToUpper(array) {
            let list = []
            array.forEach((value) => {
                value.code = value.code.toUpperCase();
                list.push(value)
            });

            return list;
        },

    },
    mounted() {
        this.setProductProperty = this.$nucleus.debounce(
            this.setProductProperty,
            500
        );

        this.selectedBrand = this.brand;
        this.selectedIndication = this.indication;
        this.selectedIntendedUse = this.intendedUse;

        this.primaryIndicationLabel = this.setIndecationDdlLabel();
        this.primaryBrandLabel = this.setBrandDdlLabel();
        this.primaryIntendedUseLabel = this.setIntendedUseDdlLabel();
    },

    created() {
        this.selectedBrand = this.brand;
        this.selectedIndication = this.indication;
        this.selectedIntendedUse = this.intendedUse;
    },

};
</script>

<style lang="scss" scoped>
.n-check-item-value {
    height: calc(1em + 2px);
    width: 5%
}

.test {
    min-width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
}

::v-deep {
    .dropdown-menu {
        min-width: 22rem !important;
        max-width: 25rem !important;
        max-height: 25rem;
        overflow-x: overlay !important;
        overflow-y: auto !important;
        /*white-space: nowrap !important*/
        ;
        background: $admin-white-color !important;
    }

    /* width */
    .dropdown-menu::-webkit-scrollbar {
        height: .75rem;
        width: 15px;
    }

    /* Track */
    .dropdown-menu::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(233, 231, 231);
        border-radius: 10px;
    }

    /* Handle */
    .dropdown-menu::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #d4d3d3;
    }
}

.icon-height {
    height: 1.15rem;
    margin-bottom: 0.063rem;
    margin-top: -0.313rem;
}

.b-dropdown-form {
    padding: 0.25rem 0rem 0rem 1.75rem;
}
</style>
