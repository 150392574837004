// system
export const REDIRECT_TO_LOGIN = "redirectToLogin";
export const HIDE_OVERLAY = "hideOverlay";
export const SHOW_OVERLAY = "showOverlay";
export const START_LOADER = "startHttpFetch";
export const STOP_LOADER = "stopHttpFetch";
export const START_LOCALE_CHANGE = "startLocaleChange";
export const STOP_LOCALE_CHANGE = "stopLocaleChange";

// auth
export const AUTHENTICATE_USER = "authenticateUser";
export const CREATE_USER = "createUser";
export const LOGOUT = "logout";
export const RESET_PASSWORD = "resetPassword";
export const SEND_PASSWORD_RESET = "sendPasswordReset";
export const UPDATE_PASSWORD = "updatePassword";
export const VALIDATE_PASSWORD = "validatePassword";
export const VALIDATE_RESET_PASSWORD_LINK = "validateResetPasswordLink";

// error
export const CLEAR_ERRORS = "clearErrors";
export const INSERT_ERROR = "insertError";
export const REMOVE_LAST_ERROR = "removeLastError";

// filters
export const ADD_FILTER = "addFilter";
export const ADD_FILTER_TAG = "addFilterTags";
export const REMOVE_ALL_FILTER_TAGS = "removeAllFilterTags";
export const REMOVE_FILTER_BY_ID = "removeFilterById";
export const REMOVE_FILTER_TAG_BY_ID = "removeFilterTagById";
export const RESET_CLEAR_ALL_FILTERS_TAG = "resetClearAllFiltersTag";
export const UPDATE_FILTER = "updateFilter";
export const RESET_ALL_FILTERS = "resetAllFilters";
