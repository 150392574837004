import { mapActions, mapGetters, mapMutations } from "vuex";

import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

import {
    ASSIGN_SHIPPING_ADDRESS,
    ASSIGN_SHIPPING_METHOD,
    CREATE_CONTACT_ADDRESS,
    GET_CART,
    GET_ORDER_BY_ID,
    GET_SHIPPING_DETAILS,
    GET_SITE_SETUP,
    PLACE_ORDER,
    REMOVE_FROM_CART,
    UPDATE_CART_ITEM,
    UPDATE_SHIP_ADDRESS,
    GET_FAVORITES,
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import {
    CART_GETTER,
    PLACE_ORDER_ERRORS_GETTER,
    PLACE_ORDER_RESPONSE_GETTER,
    ORDER_BY_ID_GETTER,
    SITE_SETUP_GETTER,
    SHIP_ADDRESS_GETTER,
    SHIPPING_DETAILS_GETTER,
    STATES_LIST_GETTER,
    TOTAL_CART_ITEMS_GETTER,
    FAVORITES_GETTER
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
    SET_PLACE_ORDER_ERROR,
    SET_PLACE_ORDER_RESPONSE,
} from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

import {
    ASSIGN_CRM_SHIPPING_ADDRESS,
    SEARCH_ADDRESSES
} from "@/store/storefront/actions.type.js";
import {
    ADDRESS_PER_PAGE_CONFIG_GETTER,
    ADDRESS_SEARCH_RESULTS_GETTER,
    ADDRESS_SORTING_CONFIG_GETTER
} from "@/store/storefront/getters.type.js";


export const SHIPPING_ADDRESS_TYPE = 2;
export const WARNING_MESSAGE_TYPE = 1;
export const ERROR_MESSAGE_TYPE = 2;
export const VALIDATION_FAILED = 400;

export const OrderApprovalIds = Object.freeze({
    CRM_ADDRESS_USED: "CrmAddress",
    EXPEDITED_SHIP_METHOD: "ShipMethod",
    LIMIT_EXCEEDED: "LimitExceeded",
    NOT_DEFAULT_ADDRESS: "NotDefaultAddress",
    OUT_OF_STOCK: "OutOfStock"
});

export const OrdersMixin = {
    data() {
        return {
            checkoutSectionKey: "checkoutSection",
            isAddressSearchResultsLoading: false,
            checkoutSections: {
                SHIPPING_ADDRESS: 0,
                SHIPPING_METHOD: 1,
                SHOPPING_CART: 2,
            },
        };
    },
    computed: {
        ...mapGetters({
            addresses: ADDRESS_SEARCH_RESULTS_GETTER,
            cart: CART_GETTER,
            cartItemsCount: TOTAL_CART_ITEMS_GETTER,
            order: ORDER_BY_ID_GETTER,
            perPageConfig: ADDRESS_PER_PAGE_CONFIG_GETTER,
            placeOrderError: PLACE_ORDER_ERRORS_GETTER,
            placeOrderResponse: PLACE_ORDER_RESPONSE_GETTER,
            siteSetup: SITE_SETUP_GETTER,
            shipAddress: SHIP_ADDRESS_GETTER,
            shippingDetails: SHIPPING_DETAILS_GETTER,
            sortingConfig: ADDRESS_SORTING_CONFIG_GETTER,
            states: STATES_LIST_GETTER,
            userInfo: USER_INFO_GETTER,
            favoriteProducts: FAVORITES_GETTER
        }),
        cartErrors() {
            if (this.cart && this.cart.messages && this.cart.messages.length > 0) {
                return this.cart.messages.filter(m => m.messageType === ERROR_MESSAGE_TYPE || m.code === VALIDATION_FAILED);
            } else {
                return [];
            }
        },
        cartWarnings() {
            if (this.cart && this.cart.messages && this.cart.messages.length > 0) {
                return this.cart.messages.filter(m => m.messageType === WARNING_MESSAGE_TYPE && m.parameters.length > 0);
            } else {
                return [];
            }
        },
        formattedCartWarnings() {
            const warnings = [];
            if (this.cartWarnings) {
                this.cartWarnings.forEach(message => {
                    if (message && message.parameters && message.parameters.length > 0) {
                        message.parameters.forEach(p => {
                            switch (p) {
                                case OrderApprovalIds.CRM_ADDRESS_USED:
                                    warnings.push("<strong>CRM Address Selected:</strong> This order will require approval.  You have selected a ship to address from your CRM address book.");
                                    break;
                                case OrderApprovalIds.EXPEDITED_SHIP_METHOD:
                                    warnings.push("<strong>Expedited Shipping:</strong> This order requires approval. You have selected an expedited shipping method.");
                                    break;
                                case OrderApprovalIds.LIMIT_EXCEEDED:
                                    warnings.push("<strong>Limit Exceeded:</strong> This order requires approval. You have exceeded the allowed limit for one or more product(s).");
                                    break;
                                case OrderApprovalIds.NOT_DEFAULT_ADDRESS:
                                    warnings.push("<strong>Shipping Address:</strong> This order requires approval. You are shipping to an address other than your default shipping address.");
                                    break;
                            }
                        });
                    }
                });
            }

            return warnings;
        },
        hasCartErrors() {
            return this.cartErrors?.length > 0;
        },
        hasCartWarnings() {
            return this.cartWarnings?.length > 0;
        },
        hasItemErrors() {
            for (const item of this.cart.items) {
                for (const message of item?.messages) {
                    if (message.messageType === ERROR_MESSAGE_TYPE) { return true;}
                }
            }
            return false;
        },
        hasItemWarnings() {
            for (const item of this.cart.items) {
                for (const message of item?.messages) {
                    if (message.messageType === WARNING_MESSAGE_TYPE) return true;
                }
            }

            return false;
        },
        hasOrderWarnings() {
            if(this.order && this.order.messages && this.order.messages.length > 0 ){
                for (const message of this.order.messages) {
                    if (message.messageType === WARNING_MESSAGE_TYPE) return true;
                }
                return false;
            }
            return false;
        },
        isPricingEnabled() {
            return !!this.siteSetup.isPricingEnabled;
        },
        shippingMethods() {
            let result = [];
            if (this.siteSetup && this.siteSetup.shipMethods && this.siteSetup.shipMethods.length) {
                this.siteSetup.shipMethods[0].values.forEach(sm => {
                    result.push({
                        code: sm.code,
                        name: sm.name,
                        minDaysInTransit: sm.minDaysInTransit,
                        maxDaysInTransit: sm.maxDaysInTransit,
                        cost: null,
                        estimatedArrival: `${sm.minDaysInTransit} - ${sm.maxDaysInTransit} Business Days Shipping`
                    });
                });
            }

            return result;
        }
    },
    methods: {
        ...mapActions({
            assignCrmShippingAddressAction: ASSIGN_CRM_SHIPPING_ADDRESS,
            assignShippingAddress: ASSIGN_SHIPPING_ADDRESS,
            assignShippingMethod: ASSIGN_SHIPPING_METHOD,
            createContactAddress: CREATE_CONTACT_ADDRESS,
            getCart: GET_CART,
            getOrderAction: GET_ORDER_BY_ID,
            getShippingDetails: GET_SHIPPING_DETAILS,
            getSiteSetup: GET_SITE_SETUP,
            placeOrderAction: PLACE_ORDER,
            removeFromCart: REMOVE_FROM_CART,
            searchAddressesAction: SEARCH_ADDRESSES,
            updateCartItem: UPDATE_CART_ITEM,
            updateShipAddress: UPDATE_SHIP_ADDRESS,
            getFavorites: GET_FAVORITES,
        }),
        ...mapMutations({
            setPlaceOrderError: SET_PLACE_ORDER_ERROR,
            setPlaceOrderResponse: SET_PLACE_ORDER_RESPONSE,
        }),
        async assignCrmShippingAddress(crmContactId) {
            const response = await this.assignCrmShippingAddressAction({
                cartId: this.cart.id,
                crmContactId: crmContactId
            });

            return response;
        },
        async getOrder(orderId) {
            await this.getOrderAction({ orderId: orderId });
        },
        getWarningByMessage(message) {
            let result = "";

            switch (message.messageId) {
                case OrderApprovalIds.OUT_OF_STOCK:
                    result = `<strong>Out of Stock:</strong> ${message.parameters[0]} unit(s) available. Remove or change order quantity.`;
                    break;
                case OrderApprovalIds.LIMIT_EXCEEDED:
                    result = message.messageType == ERROR_MESSAGE_TYPE ?
                        `<strong>Limit Exceeded:</strong> Item quantity cannot be updated. You have exceeded the ${message.parameters[0].toLowerCase()} allowed limit by ${message.parameters[2]}.`
                        : `<strong>Limit Exceeded:</strong> Approval will be required. You have exceeded the ${message.parameters[0].toLowerCase()} allowed limit by ${message.parameters[2]}.`;
                    break;
            }

            return result;
        },
        initializePlaceOrder() {
            this.setPlaceOrderError({});
            this.setPlaceOrderResponse({});
        },
        async searchAddresses() {
            this.isAddressSearchResultsLoading = true;
            await this.searchAddressesAction({ addressType: SHIPPING_ADDRESS_TYPE });
            this.isAddressSearchResultsLoading = false;
        }
    },
    async mounted() {
        if (this.$nucleus.isEmpty(this.siteSetup)) {
            await this.getSiteSetup();
        }

        if (this.$nucleus.isEmpty(this.cart)) {
            await this.getCart();
        }
        this.getFavorites();
    }
};
