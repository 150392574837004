<template>
  <div class="n-searchbox">
    <div class="input-group search">
      <input
        type="text"
        v-model="searchTerm"
        ref="omniSearch"
        class="form-control n-search-input"
        :placeholder="$t('header.omnisearch.placeholderText')"
        aria-label="input-group mb-3"
        @focus="setSearchResultsVisibility(true)"
        @blur="setSearchResultsVisibility(false)"
        v-on:keyup="handleKeyUp"
      />
      <div class="input-group-append">
        <button
          type="submit"
          class="btn btn-outline-secondary n-btn-search"
          @click.stop.prevent="searchTerm && handleNavigation()"
        >
          <font-awesome-icon icon="search" fixed-width></font-awesome-icon>
          <span class="sr-only">Search</span>
        </button>
      </div>
    </div>
    <div class="n-searchbox-results">
      <b-list-group
        v-show="omniSearchResults.length > 0 && showSearchBox"
        :style="calculatedSearchResultsStyle"
      >
        <template v-for="searchResult in omniSearchResults">
          <b-list-group-item :key="searchResult.areaId" class="item-header">
            <div class="row n-show-results-header">
              <template
                v-if="
                  getTotalSearchResultsByArea(searchResult.areaName) >
                  totalLinks
                "
              >
                <div class="col-12">
                  <h6 class="d-inline">
                    {{ searchResult.areaName }}
                    <span
                      v-if="
                        getTotalSearchResultsByArea(searchResult.areaName) > 0
                      "
                    >
                      ({{ getTotalSearchResultsByArea(searchResult.areaName) }})
                    </span>
                  </h6>
                  <router-link
                    :to="`/product-results?search-term=${searchTerm}`"
                    class="n-see-all-results ml-2 float-right d-inline"
                    >{{ $t("header.omnisearch.allResultsLink") }}</router-link
                  >
                </div>
              </template>
              <template v-else>
                <div class="col-12">
                  <h6>
                    {{ searchResult.areaName }}
                    <span
                      v-if="
                        getTotalSearchResultsByArea(searchResult.areaName) > 0
                      "
                    >
                      ({{ getTotalSearchResultsByArea(searchResult.areaName) }})
                    </span>
                  </h6>
                </div>
              </template>
            </div>
          </b-list-group-item>
          <template
            v-if="getTotalSearchResultsByArea(searchResult.areaName) > 0"
          >
            <b-list-group-item
              v-for="child in searchResult.results.slice(0, totalLinks)"
              :key="child.id"
              @click.stop.prevent="showSearchBox = false"
            >
              <router-link
                v-if="isProductsArea(searchResult.areaName)"
                :to="{
                  name: 'product-detail',
                  params: { productId: child.id },
                }"
              >
                <strong v-if="showProductItemNumber">{{
                  child.description
                }}</strong>
                {{ child.name }}
              </router-link>
              <router-link v-else :to="`/search/${child.name}`">
                {{ child.name }}
              </router-link>
            </b-list-group-item>
          </template>
          <b-list-group-item :key="searchResult.id + '-no-results'" v-else
            >No results were found</b-list-group-item
          >
        </template>
        <b-list-group-item
          v-for="(searchResult, index) in omniSearchResults"
          :key="index"
        >
          <template
            v-if="
              getTotalSearchResultsByArea(searchResult.areaName) > totalLinks
            "
          >
            <div class="col-12 pl-0">
              <router-link
                :to="`/product-results?search-term=${searchTerm}`"
                class="n-see-all-results float-left d-inline"
                >{{ $t("header.omnisearch.allResultsLink") }}</router-link
              >
            </div>
          </template>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  EXECUTE_OMNISEARCH,
  INIT_OMNISEARCH_RESULTS,
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { OMNISEARCH_RESULTS_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import * as StorefrontConstants from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import {
  omniSearchShowProductItemNumber,
  omniSearchTotalLinksToShow,
} from "@/companyAppConfig";

export default {
  mounted() {
    this.setSearchResultsVisibility = this.$nucleus.debounce(
      this.setSearchResultsVisibility,
      300
    );

    this.getOmniSearch();
  },
  name: "OmniSearch",
  computed: {
    ...mapGetters({
      omniSearchResults: OMNISEARCH_RESULTS_GETTER,
    }),
    calculatedSearchResultsStyle() {
      if (
        this.$refs &&
        this.$refs.omniSearch &&
        this.$refs.omniSearch.offsetWidth
      ) {
        return `width: ${this.$refs.omniSearch.offsetWidth}px;`;
      }

      return "";
    },
  },
  data() {
    return {
      searchTerm: localStorage.getItem("searchKey"),
      showSearchBox: false,
      showProductItemNumber: omniSearchShowProductItemNumber,
      totalLinks: omniSearchTotalLinksToShow,
    };
  },
  methods: {
    ...mapActions({
      executeOmniSearch: EXECUTE_OMNISEARCH,
      initOmniSearchResults: INIT_OMNISEARCH_RESULTS,
    }),
    clearResults() {
      this.initOmniSearchResults();
    },

    getTotalSearchResultsByArea(areaName) {
      const area = this.omniSearchResults.find((r) => r.areaName === areaName);
      let result = 0;

      if (area) {
        result = area.results.length;
      }

      return result;
    },

    async handleKeyUp(event) {
      if (event.key === "Enter") {
        this.handleNavigation();
      } else {
        if (this.searchTerm && this.searchTerm.length > 2) {
          this.setOmniSearch(this.searchTerm);
          await this.executeOmniSearch({ searchTerm: this.searchTerm });
          this.setSearchResultsVisibility(true);
        } else {
          this.clearResults();
        }
      }
    },
    setOmniSearch(search) {
      let currentURL = this.$router.history.current.name;
      if (currentURL == "ProductSearchResults") {
        localStorage.setItem("searchKey", search);
      } else {
        localStorage.removeItem("searchKey");
      }
    },
    getOmniSearch() {
      let currentUrlName = this.$router.history.current.name;
      if (currentUrlName == "ProductSearchResults") {
        return;
      } else if (currentUrlName == "product-detail") {
        this.searchTerm = "";
        return;
      } else {
        localStorage.removeItem("searchKey");
        this.searchTerm = "";
      }
    },
    handleNavigation() {
      if (this.searchTerm) {
        // TODO: handle other potential areas appropriately
        const url = `/product-results?search-term=${this.searchTerm}`;

        this.clearResults();
        this.searchTerm = "";
        this.$router.push(url);
      }
    },
    isProductsArea(areaName) {
      return areaName === StorefrontConstants.Areas.PRODUCTS;
    },
    setSearchResultsVisibility(value) {
        this.showSearchBox =
          value && this.searchTerm && this.searchTerm.length > 2;
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  margin-top: 25px;
}

.form-control {
  color: $gray-3;
  font-size: 18px;
  border-color: $primary-light-color;
}

.input-group-append button:hover {
  background: $primary-color;
  color: #ffffff;
}

.n-high-light {
  font-weight: 700;
}

.n-searchbox {
  width: 100%;
}

.n-search-input {
  &:focus {
    border-right: none;
  }
  &::placeholder {
    color: $primary-color;
    opacity: 1;
    font-style: italic;
  }
}

.n-btn-search {
  border-left: none;

  .fa-search {
    color: $primary-color;
    &:hover {
      color: #fff;
    }
  }
  &:hover {
    color: #fff;

    .fa-search {
      color: #fff;
    }
  }
}

.list-group {
  position: absolute;
  z-index: 999999999;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}

.list-group-item {
  padding: 0.5rem 1.25rem;
  background-color: #ffffff;
  border-bottom-color: #ffffff;
}

.list-group-item:hover {
  background-color: $primary-lightest-color;
}

.list-group-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); /* TODO: Find the appropriate variable/class for this */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

a,
a:hover {
  color: $gray-3;
}

hr.solid {
  border-top: 1px solid $neutral-mid-light;
  padding: 0;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  border-width: 0;
}

h6 {
  color: $primary-color;
  font-size: 1rem;
  font-family: 0;
  font-weight: bold;
}

.input-group-append {
  z-index: 1;
}

.btn-outline-secondary {
  border-color: $primary-light-color;
  background-color: #ffffff;
  font-size: 15px;
  color: $neutral-mid;
  padding: 7px 15px;
}

.btn-outline-secondary:hover {
  border-color: $primary-light-color;
  background-color: #ffffff;
  color: $neutral-mid;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #ffffff;
  border-color: $tertiary-lightest-color;
  border-left-color: #ffffff;
}

.n-show-results-header {
  display: flex;
}

.n-see-all-results {
  color: $primary-color;
  font-size: 16px;
}

.n-see-all-results:hover {
  color: $primary-color;
  font-size: 16px;
}

.n-searchbox-results {
  position: relative;
  width: 100%;
}

/* xs */
@media (max-width: 576px) {
  .n-search-input {
    font-size: 18px;
  }

  .search {
    margin-top: 0px;
  }

  .n-searchbox {
    padding-right: 10px;
  }
  .list-group {
    max-height: 275px;
  }
}

/* sm */
@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px) {
  .n-search-input {
    font-size: 20px;
    height: 50px;
  }
}

/* xl */
@media (min-width: 1200px) {
}
</style>
