<template>
  <div>
      <form name="Address-Form">
          <slot name="top"></slot>
          <div class="form-group">
              <label class="n-form-labels" :for="'company-' + randomId">
                  {{
          $t("shared.address.businessName")
                  }}
                  <span class="font-weight-normal">({{ $t("shared.optionalFields") }})</span>
              </label>
              <input type="text"
                     v-model="address.companyName"
                     :id="'company-' + randomId"
                     class="form-control n-text-field-highlight" />
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'addressee-' + randomId">
                  {{
          $t("shared.address.addressee")
                  }}
              </label>
              <input type="text"
                     v-model="address.addressee"
                     :id="'addressee-' + randomId"
                     class="form-control n-text-field-highlight" />
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'streetAddress-' + randomId">
                  {{
          $t("shared.address.streetAddress")
                  }}
              </label>
              <input type="text"
                     v-model="address.addressLine1"
                     :id="'streetAddress-' + randomId"
                     class="form-control n-text-field-highlight" />
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'streetAddress2-' + randomId">
                  {{ $t("shared.address.streetAddress2") }}
                  <span class="font-weight-normal">({{ $t("shared.optionalFields") }})</span>
              </label>
              <input type="text"
                     v-model="address.addressLine2"
                     :id="'streetAddress2-' + randomId"
                     class="form-control n-text-field-highlight" />
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'city-' + randomId">
                  {{
          $t("shared.address.city")
                  }}
              </label>
              <input type="text"
                     v-model="address.city"
                     :id="'city-' + randomId"
                     class="form-control n-text-field-highlight" />
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'state-' + randomId">
                  {{
          $t("shared.address.state")
                  }}
              </label>
              <select class="form-control n-text-field-highlight"
                      :id="'state-' + randomId"
                      v-model="address.state">
                  <option v-for="state in statesList"
                          :key="state.code"
                          :value="state.code">
                      {{ state.name }}
                  </option>
              </select>
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'postalCode-' + randomId">
                  {{
          $t("shared.address.postalCode")
                  }}
              </label>
              <input type="text"
                     v-model="address.postalCode"
                     class="form-control n-text-field-highlight"
                     :id="'postalCode-' + randomId" />
          </div>
          <div class="form-group">
              <label class="n-form-labels" :for="'phoneNumber-' + randomId">
                  {{
          $t("shared.address.phoneNumber")
                  }}
              </label>
              <input type="text"
                     v-model="address.phoneNumber"
                     class="form-control n-text-field-highlight"
                     :id="'phoneNumber-' + randomId" />
          </div>
          <slot name="bottom"></slot>
      </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

export default {
  name: "AddressComponent",
  props: {
    address: {
      type: Object,
    },
    statesList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      siteStartup: SITE_SETUP_GETTER,
    }),
    randomId() {
      return Math.floor(Math.random() * 100);
    },
  },
};
</script>

<style lang="scss" scoped></style>
