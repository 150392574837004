<template>
  <PageTemplate>
    <div class="product-results">
      <div v-if="isLoadingSearchResults">
        <div class="row d-none d-sm-none d-md-block d-lg-block d-xl-block">
          <div class="col-3 d-none">
            <div v-for="x in 4" :key="x" class="skeleton-container mt-4">
              <Skeleton width="200px" height="100px" class="mb-4"></Skeleton>
            </div>
          </div>
          <div class="col-9">
            <div class="row mt-4" v-for="y in 4" :key="y">
              <div class="col-sm-3">
                <Skeleton width="200px" height="200px" class="mb-4"></Skeleton>
              </div>
              <div class="col-sm-4">
                <Skeleton v-for="y in 6" :key="y" :min-width="250"></Skeleton>
              </div>
              <div class="col-sm-2">
                <Skeleton v-for="y in 2" :key="y" :min-width="100"></Skeleton>
              </div>
              <div class="col-sm-3">
                <Skeleton width="200px" height="100px" class="mb-2"></Skeleton>
                <Skeleton v-for="y in 2" :key="y" :min-width="250"></Skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-4 d-block d-sm-block d-md-none d-lg-none d-xl-none">
          <div class="col">
            <div class="row my-3" v-for="y in 6" :key="y">
              <div class="col-4">
                <Skeleton width="125px" height="100px" class="mb-2"></Skeleton>
              </div>
              <div class="col-8">
                <Skeleton v-for="y in 6" :key="y" :min-width="200"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="n-search-results-sidebar">
        <div v-if="!isLoadingSearchResults" class="row">
          <div class="col-md-3 d-none d-lg-block pl-0 n-left-nav ">
            <SearchResultsSidebar
              :isLoading="isLoadingSearchResults"
              @changed="changed($event)"
              @removeAll="removeAll()"
            ></SearchResultsSidebar>
          </div>
          <!-- Display in mobile/small screen size view -->
          <div class="d-lg-none border-danger n-left-side-bar">
            <!-- TODO: wrap b-sidebar up in a Nucleus component -->
            <b-sidebar
              id="filter-sidebar"
              :title="$t('productResults.sidebarTitle')"
              bg-variant="light"
              text-variant="dark"
              backdrop
              shadow
              :no-close-on-route-change="true"
            >
              <hr />
              <div class="px-3 py-2">
                <TagFilter
                  :totalResultsCount="searchResults.totalCount"
                ></TagFilter>
                <SearchResultsSidebar
                  :searchOptions="searchOptions"
                  @changed="changed($event)"
                  @removeAll="removeAll()"
                ></SearchResultsSidebar>
              </div>
            </b-sidebar>
          </div>
          <div class="col-md-12 col-lg-9 products">
            <div class="d-flex flex-row flex-sm-row align-items-end header-row">
              <div class="pt-3">
                <h2>
                 <span class="d-none">{{pageTitle}}</span>
                 <div class="row">
                    <b-breadcrumb class = "n-font-category">
                      <b-breadcrumb-item v-for="(crumb, ci) in categoryBreadcrumbs" :key="ci">{{crumb}}</b-breadcrumb-item>
                    </b-breadcrumb>

                    <span
                      v-if="searchResults.totalCount > 0"
                      class="align-self-end n-results-details"
                    >
                      ({{ startRecord }}-{{ endRecord }}) of
                      {{ searchResults.totalCount }}
                      {{ $t("productResults.resultsText") }}
                    </span>
                  </div>
                </h2>
                <div class="n-product-list mt-2">
                  <Message
                    v-if="isFilteredOnFavorites"
                    :message="favoriteMessageConfig"
                    :bold="false"
                  ></Message>
                </div>
              </div>
            </div>
            <div class="d-none d-lg-block">
              <TagFilter></TagFilter>
            </div>
            <div class="row n-results-crumb">
              <template v-if="searchResults.totalCount > 0">
                <div class="col-6 col-sm-5 col-md-3 col-lg-3 sort-by">
                  <SortByList :sort="searchOptions.sort"></SortByList>
                </div>
                <div class="d-none d-lg-block col-lg-3 per-page">
                  <PerPageList :perPage="searchOptions.perPage"></PerPageList>
                </div>
              </template>
              <!-- Display Filter Button in Mobile View / Smaller screen size-->
              <div class="d-lg-none col-6 col-sm-3 col-md-3 n-filters">
                <b-button
                  :class="filterButtonClasses"
                  v-b-toggle.filter-sidebar
                >
                  <div class="filter-icon" v-if="this.activeFiltersCount > 0">
                    <font-awesome-icon icon="sliders-h" />
                  </div>
                  <div class="filter-icon" v-else>
                    <font-awesome-icon icon="filter" />
                  </div>

                  <div class="filter-icon pl-2 pr-2 text-capitalize">
                    Filter
                  </div>
                  <div class="filter-icon" v-if="activeFiltersCount > 0">
                    ({{ activeFiltersCount }})
                  </div>
                </b-button>
              </div>
              <div
                class="col-6 col-sm-3 col-md-2 col-lg-2 d-none d-lg-block"
                v-if="searchResults.totalCount > 0"
              >
                <div
                  class="n-mode-view mr-2"
                  :class="gridActive ? 'gridActive' : ''"
                  @click="mode('grid')"
                >
                  <font-awesome-icon
                    icon="grip-horizontal"
                    class="n-mode-icon"
                  />
                </div>
                <div
                  class="n-mode-view"
                  :class="listActive ? 'listActive' : ''"
                  @click="mode('list')"
                >
                  <font-awesome-icon icon="th-list" class="n-mode-icon" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 d-none d-lg-block d-md-block">
                <div class="n-top-pagination mr-3">
                  <div class="pagination" v-if="showPagingControls">
                    <PagingControls
                      :currentPageNumber="currentPageNumber"
                      :totalResults="searchResults.totalCount"
                      :perPage="searchOptions.perPage"
                      @pageNumberChanged="pageNumberChanged"
                    >
                    </PagingControls>
                  </div>
                  <div v-else class="my-5"></div>
                </div>
              </div>
            </div>
            <div>
              <hr v-if="searchResults.totalCount > 0" class="n-hr-first" />
            </div>
            <div>
              <!-- Display product results in desktop and tablet size screen -->
              <div class="product-results d-none d-lg-block d-md-block" v-if="hasSearchResults">
                <ProductListMode
                  :products="searchResults.items"
                  :addItemToCart="addItemToCart"
                  :buttonLoading="buttonLoading"
                  :noImageAvailableUrl="noImageAvailableUrl"
                  @quantityChanged="refreshProducts"
                  v-if="listActive"
                ></ProductListMode>
                <ProductTileGridMode
                  :products="searchResults.items"
                  :addItemToCart="addItemToCart"
                  :buttonLoading="buttonLoading"
                  v-if="gridActive"
                  :columnWidth="4"
                  :noImageAvailableUrl="noImageAvailableUrl"
                  @quantityChanged="refreshProducts"
                ></ProductTileGridMode>                
              </div>             

              <!-- Display product results in mobile/smaller size screen-->
              <div class="product-results d-sm-block d-lg-none d-md-none" v-if="hasSearchResults">
                <ProductTileMobileMode
                  :products="searchResults.items"
                  :addItemToCart="addItemToCart"
                  :buttonLoading="buttonLoading"
                  :noImageAvailableUrl="noImageAvailableUrl"
                ></ProductTileMobileMode>                
              </div>
              
              <div v-else>
                <div class="pt-5 mb-5" v-if="!isFilteredOnFavorites">
                  No products found. Try modifying your search.
                </div>
                <div class="pt-5 text-center" v-else>
                  <div class="n-fav-headline">
                    No items have been added to your Favorites.
                  </div>
                  <div class="n-fav-icon mt-2">
                    <font-awesome-icon
                      class="n-heart-icon"
                      :icon="['far', 'heart']"
                    />
                  </div>
                  <div class="n-fav-message mt-2">
                    To add items to "My Favorites" click the favorites icon
                    located next to each item.
                  </div>
                </div>
              </div>
            </div>
            <div class="row bottom-pagination">
              <div class="pagination d-none d-lg-block d-md-block">
                <PagingControls
                  v-if="showPagingControls"
                  :currentPageNumber="currentPageNumber"
                  :totalResults="searchResults.totalCount"
                  :perPage="searchOptions.perPage"
                  @pageNumberChanged="pageNumberChanged"
                >
                </PagingControls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { isMobileOnly } from "@/nucleus-modules/dd-nucleus-ui/services/device-detect.service.js";
import {
  ADD_FILTER,
  UPDATE_FILTER,
  ADD_FILTER_TAG,
  RESET_ALL_FILTERS,
} from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import {
  FILTERS_GETTER,
  SORTING_CONFIG_GETTER,
} from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { FilterUtilities } from "@/nucleus-modules/dd-nucleus-ui/mixins/FilterUtilities.js";
import {
  MessageType,
  TagType,
} from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import TagFilter from "@/nucleus-modules/dd-nucleus-ui/components/TagFilter.vue";
import Message from "@/nucleus-modules/dd-nucleus-ui/components/Message.vue";
import Skeleton from "@/nucleus-modules/dd-nucleus-ui/components/Skeleton.vue";
import PagingControls from "@/nucleus-modules/dd-nucleus-ui/components/PagingControls.vue";
import PerPageList from "@/nucleus-modules/dd-nucleus-ui/components/PerPageList.vue";
import SortByList from "@/nucleus-modules/dd-nucleus-ui/components/SortByList.vue";

// import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";

import { SEARCH_PRODUCTS } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import {
  CART_GETTER,
  FLATTENED_CATEGORIES_GETTER,
  SEARCH_PRODUCTS_RESULTS_GETTER,
} from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

import { CartMixin } from "@/nucleus-modules/dd-nucleus-storefront/mixins/CartMixin.js";

import PageTemplate from "@/pages/templates/PageTemplate.vue";
import ProductListMode from "@/components/product/ProductListMode.vue";
import ProductTileGridMode from "@/components/product/ProductTileGridMode.vue";
import ProductTileMobileMode from "@/components/product/ProductTileMobileMode.vue";
import SearchResultsSidebar from "@/components/product/SearchResultsSidebar.vue";
import { FilterIds } from "@/constants.js";
import { noImageAvailableUrl, tagsFilter } from "@/companyAppConfig.js";

export default {
  // TODO: what's the justification for:
  // showSidebar
  name: "ProductSearchResults",
  mixins: [CartMixin, FilterUtilities],
  components: {
    TagFilter,
    Message,
    Skeleton,
    PagingControls,
    PageTemplate,
    ProductListMode,
    ProductTileMobileMode,
    ProductTileGridMode,
    SearchResultsSidebar,
    PerPageList,
    SortByList,
  },
  data() {
    return {
      currentPageNumber: 1,
      favoriteBannerText: this.$t("productResults.favoriteBannerText"),
      isLoadingSearchResults: true,
      isMobileOnly: isMobileOnly,
      // TODO: handle the different view types better
      resultsView: "list",
      searchOptions: {
        sort: "",
        page: 1,
        perPage: 90,
      },
      showSidebar: true,
      gridActive: false,
      listActive: true,
      noImageAvailableUrl: noImageAvailableUrl,
      initializeFiltersOnLoad: false,
       categoryBreadcrumbs: [this.$t("productResults.allProductsText")]
    };
  },
  computed: {
    ...mapGetters({
      cart: CART_GETTER,
      filters: FILTERS_GETTER,
      flattenedCategories: FLATTENED_CATEGORIES_GETTER,
      searchResults: SEARCH_PRODUCTS_RESULTS_GETTER,
      sortingConfig: SORTING_CONFIG_GETTER,
    }),
    activeFiltersCount() {
      return this.getActiveFilterCount(this.searchOptions);
    },
    startRecord() {
      if (this.hasSearchResults) {
        return (
          Number(this.searchResults.pageNumber) *
            Number(this.searchResults.pageSize) +
          1
        );
      }
      return 0;
    },
    endRecord() {
      if (this.hasSearchResults) {
        return (
          Number(this.searchResults.pageNumber) *
            Number(this.searchResults.pageSize) +
          Number(this.searchResults.count)
        );
      }
      return this.searchResults.totalCount;
    },
    favoriteMessageConfig() {
      return {
        text: this.favoriteBannerText,
        type: MessageType.WARNING,
      };
    },
    filterButtonClasses() {
      let result = "";
      if (this.activeFiltersCount > 0) {
        result = "btn btn-outline-primary btn-block n-filter-button";
      } else {
        result = "btn btn-outline-primary btn-block n-default-filter-button";
      }

      return result;
    },
    hasSearchResults() {
      return this.searchResults.items && this.searchResults.items.length > 0;
    },
    isFilteredOnFavorites() {
      const tagsParamName = "tags";
      if (
        this.searchOptions &&
        this.searchOptions.filters &&
        this.searchOptions.filters[tagsParamName] &&
        this.searchOptions.filters[tagsParamName].includes(
          TagType.FAVORITE.toLowerCase()
        )
      ) {
        return true;
      }

      return false;
    },
    pageTitle() {
      let result = this.$t("productResults.allProductsText");
      let selectedCategoryId = this.selectedCategoryId;
      if (selectedCategoryId) {
        result = selectedCategoryId;
      } else if (!this.$nucleus.isEmpty(this.getTagName)) {
        if (this.isFilteredOnFavorites) {
          result = this.$t("favoritesPageTitleText");
        } else {
          result = this.getTagName;
        }
      }

      return result;
    },
    selectedCategoryId() {
      let result = "";
      if (this.flattenedCategories && this.flattenedCategories.length) {
        const selectedCategoryId = this.$route.query.category;
        result = this.getCategoryName(selectedCategoryId);
      }
      return result;
    },
    showPagingControls() {
      return (
        this.searchOptions.perPage > 0 &&
        this.searchResults.totalCount > this.searchOptions.perPage
      );
    },
    isSmallerScreen() {
      return window.innerWidth <= 767
    }
  },
  methods: {
    ...mapActions({
      addFilter: ADD_FILTER,
      addFilterTag: ADD_FILTER_TAG,
      searchProducts: SEARCH_PRODUCTS,
      updateFilter: UPDATE_FILTER,
      resetAllFilters: RESET_ALL_FILTERS,
    }),
    changed(eventData) {
      this.resetPageNumber();
      this.refreshFilters(eventData);
    },
    removeAll() {
      this.resetAllFilters();
      this.reloadSearch();
    },
    async applyFilter(filterId, filters) {
      let filter = filters.find((f) => f.filterId === filterId);
      if (filter) {
        await this.reloadFilters({
          filterId: filter.filterId,
          values: filter.values,
        });
      } else {
        await this.reloadFilters({ filterId: filterId, values: [] });
      }
    },
    async addItemToCart(product) {
      await this.addToCart(this.cart.id, product, product.quantity);
    },
    buttonLoading() {
      // TODO: is this even necessary anymore?
      console.log("buttonLoading invoked");
    },
    async executeSearch() {
      this.searchOptions.paging = {
        includeTotalCount: true,
        pageNumber: this.searchOptions.page
          ? Number(this.searchOptions.page) - 1
          : 0
      };
       if(this.isSmallerScreen){
         this.searchOptions.paging.pageNumber = 0;
      } else {
        this.searchOptions.paging.pageSize = this.searchOptions.perPage
      }
 
      await this.searchProducts(this.searchOptions);
      if (this.hasSearchResults) {
        this.searchResults.items = this.searchResults.items.map((item) => {
          item.quantity = 1;
          return item;
        });
      }

      this.currentPageNumber = this.searchOptions.page;
      this.isLoadingSearchResults = false;
    },
    filterExists(filter) {
      return (
        this.filters.findIndex((f) => f.filterId === filter.filterId) !== -1
      );
    },
    pageNumberChanged(eventData) {
      let vm = this;
      if (vm.currentPageNumber != eventData.selectedPage) {
        vm.searchOptions.page = eventData.selectedPage;
        vm.currentPageNumber = this.searchOptions.page;
        vm.reloadSearch();
      }
    },
    initListeners() {
      let vm = this;

      this.$eventBus.$on(UiEvents.CATEGORY_CHANGED, (eventData) => {
        this.resetPageNumber();
        this.refreshFilters(eventData);
      });
      this.$eventBus.$on(UiEvents.PER_PAGE_CHANGED, (eventData) => {
        if (vm.searchOptions.perPage != eventData.selectedPerPage) {
          this.resetPageNumber();
          vm.searchOptions.perPage = eventData.selectedPerPage;
          vm.reloadSearch();
        }
      });
      this.$eventBus.$on(UiEvents.SORT_VALUE_CHANGED, (eventData) => {
        if (vm.searchOptions.sort != eventData.selectedSort) {
          this.resetPageNumber();
          vm.searchOptions.sort = eventData.selectedSort;
          vm.reloadSearch();
        }
      });

      this.$eventBus.$on(UiEvents.FILTER_TAG_REMOVED, (eventData) => {
        this.refreshFilters(eventData);
      });
      this.$eventBus.$on(UiEvents.ALL_FILTER_TAGS_REMOVED, () => {
        this.resetAllFilters();
        this.reloadSearch();
      });
    },
    initSearchOptions() {
      let shouldReload = false;

      // Call FilterUtilities to retrieve the search options
      this.searchOptions = this.getSearchOptionsFromQuery(this.$route.query);

      // Set the default sort if not supplied in the query string
      if (!this.searchOptions.sort) {
        this.searchOptions.sort = this.sortingConfig.defaultSort;
        shouldReload = true;
      }

      // Set pagination options
      if (!this.searchOptions.page) {
        this.searchOptions.page = 1;
        shouldReload = true;
      }

      if (this.$nucleus.isEmpty(this.searchOptions.perPage)) {
        this.searchOptions.perPage = this.sortingConfig.defaultPerPage;
        shouldReload = true;
      }

      this.applyFilters();

      return shouldReload;
    },
    async loadSearchResults() {
      await this.executeSearch();
    },
    applyFilters() {
      if (
        this.searchOptions &&
        this.searchOptions.filters &&
        this.searchOptions.filters.length
      ) {
        this.applyFilter(FilterIds.SEARCH_TERM, this.searchOptions.filters);
        this.applyFilter(FilterIds.CATEGORY, this.searchOptions.filters);
        this.applyFilter(FilterIds.DATE_ADDED, this.searchOptions.filters);
        this.applyFilter(FilterIds.TAGS, this.searchOptions.filters);
      } else {
        this.applyFilter(FilterIds.SEARCH_TERM, []);
        this.applyFilter(FilterIds.CATEGORY, []);
        this.applyFilter(FilterIds.DATE_ADDED, []);
        this.applyFilter(FilterIds.TAGS, []);
      }
    },
    async refreshFilters(filter) {
      await this.reloadFilters(filter);
      this.reloadSearch();
    },
    async reloadFilters(filter) {
      if (this.filterExists(filter)) {
        await this.updateFilter(filter);
      } else {
        // if the filter doesn't already exist, add it
        await this.addFilter(filter);
      }
    },
    async refreshFilterTags() {
      let vm = this;
      for (let i = 0; i < vm.filters.length; i++) {
        let filter = vm.filters[i];
        if (filter) {
          let filterTag = {
            id: filter.filterId,
            values: vm.formatFilterTagValues(filter.filterId, filter.values),
          };
          await this.addFilterTag(filterTag);
        }
      }
    },
    formatFilterTagValues(filterId, values) {
      if (values && values.length) {
        let vm = this;
        let newValues = [];
        switch (filterId) {
          case FilterIds.DATE_ADDED:
            newValues = vm.formatDateAddedFilterTag(values);
            break;
          case FilterIds.CATEGORY:
            newValues = vm.formatCategoryFilterTag(values);
            break;
          case FilterIds.TAGS:
            newValues = vm.formatTagFilterTag(values);
            break;
          case FilterIds.SEARCH_TERM:
            newValues = vm.formatSearchTermFilterTag(values);
            break;
          default:
            newValues = [...values];
        }
        return newValues;
      }
      return values;
    },
    formatSearchTermFilterTag(values) {
      let newValues = values.map((v) => {
        return {
          id: v,
          name: v,
          show: true,
        };
      });
      return newValues;
    },
    formatDateAddedFilterTag(values) {
      let newValues = [];
      let vm = this;
      if (values.length == 2) {
        newValues = values.map((v) => {
          return {
            id: v,
            name: "",
            show: true,
          };
        });
        if (values[0] && values[1]) {
          newValues[0].name =
            vm.$moment(values[0]).format("L") +
            "-" +
            vm.$moment(values[1]).format("L");
          newValues[1].show = false;
        } else if (values[0] && !values[1]) {
          newValues[0].name = vm.$moment(values[0]).format("L");
          newValues[1].show = false;
        } else if (!values[0] && values[1]) {
          newValues[1].name = vm.$moment(values[1]).format("L");
          newValues[0].show = false;
        }
      }
      return newValues;
    },
    formatCategoryFilterTag(values) {
      let vm = this;
      let newValues = values.map((v) => {
        let name = v;
        let show = true;
        if (!vm.$nucleus.isEmpty(v)) {
          name = v;
          show = false;
        }
        return {
          id: v,
          name: name,
          show: show,
        };
      });
      return newValues;
    },
    formatTagFilterTag(values) {
      let vm = this;
      let newValues = values.map((v) => {
        let name = v;
        let show = true;
        if (!vm.$nucleus.isEmpty(v)) {
          let tagFilter = tagsFilter.find((tf) => tf.id == v);
          name = tagFilter ? tagFilter.name : v;
        }
        return {
          id: v,
          name: name,
          show: show,
        };
      });
      return newValues;
    },
    getCategoryName(categoryId) {
      let result = "";
      this.categoryBreadcrumbs = [];

      const category = this.flattenedCategories.find(
        (c) => c.id === categoryId
      );

      if(category && category.level != 0)
      {
        this.getCategoryName(category.parentId);
      }

      if (category) {
        result = category.name;
      }

      if(result.trim() != '' )
      {
        this.categoryBreadcrumbs.push(result);
      }
      else
      {
        this.categoryBreadcrumbs.push(this.$t("productResults.allProductsText"));
      }
      
      return result;
    },
    reloadSearch() {
      const query = this.getQueryFromSearchOptions(
        this.searchOptions,
        this.filters
      );

      this.$router.push({ name: "ProductSearchResults", query: query });
    },
    resetPageNumber() {
      this.searchOptions.page = 1;
    },
    removeListeners() {
      this.$eventBus.$off(UiEvents.PER_PAGE_CHANGED);
      this.$eventBus.$off(UiEvents.SORT_VALUE_CHANGED);
      this.$eventBus.$off(UiEvents.CATEGORY_CHANGED);
    },
    mode(type) {
      if (type == "grid") {
        this.gridActive = true;
        this.listActive = false;
      } else if (type == "list") {
        this.listActive = true;
        this.gridActive = false;
      }
    },
    refreshProducts() {
      this.searchResults.items = this.searchResults.items.slice(0);
    }
  },
  async mounted() {
    this.initListeners(); 
  },
  async updated() {
    await this.refreshFilterTags();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal != newVal) {
          const forceReload = this.initSearchOptions();
          if (forceReload) {
            this.reloadSearch();
          } else {
            this.loadSearchResults();
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">

li.breadcrumb-item a{
    color: $neutral-dark;
    cursor: default;
    pointer-events: none;        
    text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
}

.n-font-category{
  font-family: Roboto;
  font-size: 1.125rem;
}

.breadcrumb {
  background-color: unset;
}

.breadcrumb-item:nth-last-child(1)
{
  margin: -0.4rem 0rem 0rem 0rem;
}
 
.breadcrumb-item:nth-last-child(1) a{
  font-size: 1.5rem;
  color: $secondary-color !important;
}

.product-results {
  width: 100%;
}

.n-mode-view {
  cursor: pointer;
}

.bottom-pagination,
.n-top-pagination {
  float: right;
}

.svg-inline--fa {
  font-size: 25px;
  margin-top: 8px;
}

.n-mode-view {
  color: #b8b8b8;
  display: inline;
  padding-right: 5px;
}

.listActive,
.gridActive {
  cursor: default;
  color: $primary-color;
}

.header-row {
  margin-bottom: 20px;
}

h2 {
  color: $tertiary-color;
  margin-bottom: 0;
}

.n-results-details {
  color: $gray-3;
  font-size: 14px;
  font-weight: 400;
  text-transform: lowercase !important;
  margin: 0rem 0rem 2rem 0rem;
}

.n-search-results-sidebar .row {
  margin: 0px;
}

.btn .fa-filter {
  font-size: 14px;
}

.n-hr-first {
  margin: 6px 0 30px 0;
}

.n-default-filter-button {
  background-color: #ffffff !important;
  border-color: $secondary-color !important;
  color: $secondary-color !important;
}

.n-filter-button {
  background-color: $secondary-color !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.filter-icon {
  display: inline-block;
}

.right {
  text-align: right;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  width: 400px;
}

.svg-inline--fa {
  font-size: 15px;
  margin-top: 5px;
}

.n-fav-headline {
  font-size: 18px;
}

.n-fav-message {
  font-size: 14px;
  color: $primary-color;
}

.sort-by {
  width: 200px;
}

.n-fav-icon {
  font-size: 25px;
  color: $primary-color;
}
/*
@media (max-width: 1080px) {
  .n-top-pagination {
    position: absolute;
  }
}
*/
@media (max-width: 768px) {
  h2 {
    font-size: 20px;
  }

  .products {
    padding-left: 0px;
    padding-right: 0px;
  }

  .filter-icon {
    padding-bottom: 4px;
  }

}

.n-filters {
  padding-left: 0px;
}

.n-filters .btn {
  font-size: 0.75rem;
  border-width: thin;
}

.n-mode-icon {
  font-size: 25px;
}

.n-results-crumb .sort-by {
  padding-left: 0px !important;
}

@media (max-width: 576px) {
  .n-results-details {
    padding-left: 6px;
  }
}

</style>
