<template>
    <div>
        <PageSubHeader title="Primary Information" :hasThematicBreak="true" />
        <form name="add-user">
            <div class="row">
                <div class="col-5">
                    <div v-if="isNew"
                         class="form-group"
                         :class="{ 'User-group--error': $v.userName.$error }">
                        <label class="n-form-labels">User ID</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model="$v.userName.$model" />
                        <div v-if="$v.userName.$invalid && isNotValid(user.userName)">
                            <div class="n-error" v-if="!$v.userName.required">
                                User ID is required
                            </div>
                        </div>
                        <div v-if="!isNotValid(user.userName)">
                            <div class="n-error" v-if="!$v.userName.email">
                                User ID must be a valid email address
                            </div>
                        </div>
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.firstName.$error }">
                        <label class="n-form-labels">First Name</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               maxlength="30"
                               v-model.trim="$v.firstName.$model" />
                        <div v-if="$v.firstName.$invalid && isNotValid(user.firstName)">
                            <div class="n-error" v-if="!$v.firstName.required">
                                First Name is required
                            </div>
                        </div>
                        <div v-if="!isNotValid(user.firstName)">
                            <div class="n-error" v-if="!$v.firstName.alpha">
                                Please remove any special characters or numbers.
                            </div>
                        </div>
                    </div>
                    <div class="form-group"
                         :class="{ 'form-group--error': $v.lastName.$error }">
                        <label class="n-form-labels">Last Name</label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               maxlength="30"
                               v-model.trim="$v.lastName.$model" />
                        <div v-if="$v.lastName.$invalid && isNotValid(user.lastName)">
                            <div class="n-error" v-if="!$v.lastName.required">
                                Last Name is required
                            </div>
                        </div>
                        <div v-if="!isNotValid(user.lastName)">
                            <div class="n-error" v-if="!$v.lastName.alpha">
                                Please remove any special characters or numbers.
                            </div>
                        </div>
                    </div>                    
                    <div class="form-group">
                        <label class="n-form-labels">
                            Job Title
                            <span class="n-form-label-notes">(optional)</span>
                        </label>
                        <input type="text"
                               class="form-control n-text-field-highlight"
                               v-model.trim="title" />
                    </div>
                    <template v-if="!isNew">
                        <div class="form-group">
                            <label class="n-form-labels">Date Created</label>
                            <div>{{ user.created | moment("MM/DD/YYYY hh:mm a") }}</div>
                        </div>                        
                    </template>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <template v-if="!isNew">
                        <div class="form-group"
                             :class="{ 'form-group--error': $v.userName.$error }">
                            <label class="n-form-labels">User ID</label>
                            <input type="text"
                                   class="form-control n-text-field-highlight"
                                   disabled
                                   v-model.trim="$v.userName.$model" />
                            <div v-if="$v.userName.$invalid && isNotValid(user.userName)">
                                <div class="n-error" v-if="!$v.userName.required">
                                    User ID is required
                                </div>
                            </div>
                            <div v-if="!isNotValid(user.userName)">
                                <div class="n-error" v-if="!$v.userName.email">
                                    User ID must be a valid email address
                                </div>
                            </div>
                        </div>
                    </template>
                    <template>
                        <div class="form-group"
                             :class="{ 'form-group--error': $v.isSso }">
                            <b-form-checkbox v-model="isSso"
                                             name="isSso"
                                             :disabled="!isNew">
                                <label class="n-form-labels">Is SSO</label>
                            </b-form-checkbox>
                        </div>
                    </template>
                    <template v-if="!isNew && !isSso">
                        <div class="form-group">
                            <b-button class="d-inline"
                                      variant="primary"
                                      @click="sendEmail()"
                                      :disabled="!!user.currentSso || isInactive">
                                <template v-if="!$nucleus.isEmpty(user.welcomeEmailSentDate)">
                                    Resend Welcome Email
                                </template>
                                <template v-else>
                                    Welcome Email
                                </template>
                            </b-button>
                        </div>
                        <div class="form-group">
                            <label>
                                <input type="checkbox"
                                       v-model="manuallyAssign"
                                       name="manualAssign" />
                                <span class="n-role-name ml-3">Manually assign or change password</span>
                            </label>                            
                        </div>                                                
                        <template v-if="user.manuallyAssign">
                            <div class="form-group">
                                <label class="n-form-labels">Password</label>
                                <input type="password"
                                       class="form-control n-text-field-highlight"
                                       v-model="user.password"
                                       :disabled="disablePassword"
                                       @keyup="validatePassword" />
                                <div v-if="$v.user.password.$invalid && isNotValid(user.password)">
                                    <div class="n-error" v-if="!$v.user.password.required">
                                        Password is required
                                    </div>
                                </div>

                                <!-- Start PW Box -->
                                <div v-if="rules && rules.length && !disablePassword">
                                    <div
                                         class="n-password-requirements-1 n-arrow-password-require"
                                         :class="
                      isPasswordChangeValid
                        ? 'n-success-border n-success-arrow'
                        : 'n-error-border n-error-arrow'
                    ">
                                        <div :class="isPasswordChangeValid ? 'n-success' : 'n-error'">
                                            <strong>Password Requirements</strong>
                                        </div>
                                        <div v-for="(rules, index) in rules" :key="index">
                                            <div class="col-1 float-left n-password-requirement-icon">
                                                <template v-if="rules.code === 0">
                                                    <font-awesome-icon icon="check-circle"
                                                                       class="n-success" />
                                                </template>
                                                <template v-else>
                                                    <font-awesome-icon icon="times-circle"
                                                                       class="n-error" />
                                                </template>
                                            </div>
                                            <div class="col-11 n-password-requirements-bullets">
                                                {{ rules.debugMessage }}
                                            </div>
                                        </div>
                                    </div>                               
                                    </div>
    <!-- END password box -->
                                    </div>
                            <div class="form-group">
                                <label class="n-form-labels">Confirm Password</label>
                                <input type="password"
                                       class="form-control n-text-field-highlight"
                                       v-model="user.confirmPassword"
                                       :disabled="disablePassword"
                                       @keyup="validatePassword" />
                                <div v-if="
                    $v.user.confirmPassword.$invalid &&
                      isNotValid(user.confirmPassword)
                  ">
                                    <div class="n-error" v-if="!$v.user.confirmPassword.required">
                                        Confirm Password is required
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <span v-if="isPrimaryInfoValid"></span>
        </form>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from "vuex";
    import { required, email, requiredIf, helpers } from "vuelidate/lib/validators";

    import { USER_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SET_USER_PROPERTY, SET_USER_ATTRIBUTE_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    import { SEND_WELCOME_EMAIL } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type";

    import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
    import { UserEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

    import { VALIDATE_PASSWORD } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
    import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

    const alpha = helpers.regex(
        "alpha",
        new RegExp("^[a-zA-Z]+(([\\'\\,\\.\\- ][a-zA-Z ])?[a-zA-Z]*)*$")
    );

    export default {
        name: "UserPrimaryInfo",
        props: {
            isNew: { type: Boolean },
        },
        components: {
            PageSubHeader,
        },
        data() {
            return {
                disablePassword: true,
                showPassword: false,
                isPasswordChangeValid: false,
                rules: []
            };
        },
        computed: {
            ...mapGetters({
                user: USER_FOR_EDIT_GETTER,
            }),
            isActive() {
                return this.user.statusCode === StatusCodes.ACTIVE;
            },
            isInactive() {
                return this.user.statusCode === StatusCodes.INACTIVE;
            },
            firstName: {
                get() {
                    return this.user.firstName;
                },
                set(value) {
                    this.setUserProperty({ name: "firstName", value: value });
                },
            },
            lastName: {
                get() {
                    return this.user.lastName;
                },
                set(value) {
                    this.setUserProperty({ name: "lastName", value: value });
                },
            },           
            title: {
                get() {
                    return this.user.title;
                },
                set(value) {
                    this.setUserProperty({ name: "title", value: value });
                },
            },
            userName: {
                get() {
                    return this.user.userName;
                },
                set(value) {
                    this.setUserProperty({ name: "userName", value: value });
                },
            },
            isSso: {
                get() {
                    if (this.user.attributes) {
                        return this.user.attributes["IsSso"];
                    }
                    else {
                        return "";
                    }
                },
                set(value) {
                    this.setUserAttributeProperty({ name: "IsSso", value: value });
                },
            },

            isPrimaryInfoValid() {
                let result = false;
                if (this.$v.userName.$invalid) {                    
                    result = false;
                } else if (this.$v.firstName.$invalid) {                    
                    result = false;
                } else if (this.$v.lastName.$invalid) {                    
                    result = false;
                } else if (this.$v.user.password.$invalid) {                    
                    result = false;
                } else if (this.$v.user.confirmPassword.$invalid) {                    
                    result = false;
                } else if (this.user.manuallyAssign && !this.isPasswordChangeValid) {
                    result = false;
                } else {
                    result = true;
                }                
                this.toggleAccess();
                this.togglePassword();
                this.updateValidity(result);
                return result;
            },
            manuallyAssign: {
                get() {                    
                    return this.user.manuallyAssign;
                },
                set(value) {
                    this.setUserProperty({ name: "manuallyAssign", value: value });
                },
            },
        },
        methods: {
            ...mapActions({
                sendWelcomeEmail: SEND_WELCOME_EMAIL,
                validate: VALIDATE_PASSWORD
            }),
            ...mapMutations({
                setUserProperty: SET_USER_PROPERTY,                
                setUserAttributeProperty: SET_USER_ATTRIBUTE_PROPERTY               
            }),
            isNotValid(fieldName) {
                return fieldName != null && fieldName.length == 0;
            },
            toggleAccess() {
                if (this.user.access) {
                    this.showPassword = true;
                } else {
                    this.showPassword = false;
                }
            },
            togglePassword() {                
                if (this.user.manuallyAssign) {                    
                    this.disablePassword = false;
                    this.validatePassword();
                } else {                    
                    this.user.manuallyAssign = null;
                    this.disablePassword = true;
                }                
            },
            updateValidity(isValid) {                
                this.$eventBus.$emit(UserEvents.USER_PRIMARY_INFO_UPDATED, isValid);
            },
            async validatePassword() {
                const response = await this.validate({
                    "userName": this.user.userName,
                    "email": this.user.userName,
                    "password": this.user.password,
                    "confirmPassword": this.user.confirmPassword
                });

                this.rules = response.messages;
                this.isPasswordChangeValid = response.succeeded && response.isValid ? true : false;                
            },
            async sendEmail() {
                let emails = [this.user.email];
                this.sendWelcomeEmail(emails);
            },
        },
        mounted() {
            this.setUserProperty = this.$nucleus.debounce(this.setUserProperty, 100);
        },
        validations: {
            userName: {
                required,
                email,
            },
            firstName: {
                required,
                alpha,
            },
            lastName: {
                required,
                alpha,
            },            
            user: { 
                password: {
                    required: requiredIf(function () {                    
                        if (this.manuallyAssign) {                            
                            return true;
                        } else {                            
                            return false;
                        }                    
                    }),
                },
                confirmPassword: {
                    required: requiredIf(function () {
                        if (this.manuallyAssign) {
                            return true;
                        } else {
                            return false;
                        }                    
                    }),
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    .n-form-label-notes {
    color: $admin-primary-lightest-color;
    }
    
    ul {
        margin: 0px;
        padding-left: 0px;
        list-style-type: none;
    }

    li {
        margin-left: 0px;
    }

    button[disabled],
    button:disabled {
        cursor: not-allowed;
    }

    .n-password-requirements-1 {
        position: absolute;
        top: 230px; 
        left: -190px;
        border: 1px solid;
        border-radius: 3px;
        padding: 10px;
        width: 200px;
        background: #fff;
        z-index: 99999;
    }

    .n-password-requirements-confirm {
        position: absolute;
        top: 250px;
        left: -190px;
        border: 1px solid;
        border-radius: 3px;
        padding: 10px;
        width: 200px;
        background: #fff;
        z-index: 99999;
    }

    .n-password-requirements-bullets {
        margin-left: 20px;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 10px;
        line-height: 1.25;
    }

    .n-password-requirement-icon {
        padding-left: 0px;
    }

    .n-success {
        color: $green;
    }

    .n-error {
        color: $red;
    }

    .n-success-border {
        border-color: $green !important;
    }

    .n-success-arrow:after {
        border-left-color: $green !important;
    }

    .n-success-arrow:before {
        border-left-color: $green !important;
    }

    .n-error-border {
        border-color: $red !important;
    }

    .n-error-arrow {
        border-left-color: $red !important;
    }

    .n-error-arrow:before {
        border-left-color: $red !important;
    }

    a:not([href]):hover {
        color: $primary-color;
        text-decoration: underline;
    }

    .n-show-hide-password {
        font-size: 12px;
        padding-top: 2px;
    }

    .n-arrow-password-require {
        position: absolute;
        background: #fff;
        border: 1px solid;
        border-radius: 3px;
        padding: 10px;
        width: 200px;
        z-index: 99999;
    }

    .n-arrow-password-require:after,
    .n-arrow-password-require:before {
        left: 100%;
        top: 123px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .n-arrow-password-require:after {
        border-width: 7px;
        margin-top: -110px;
    }

    .n-arrow-password-require:before {
        border-width: 7px;
        margin-top: -110px;
    }

    .n-arrow-password-confirm {
        position: absolute;
        background: #fff;
        border: 1px solid;
        border-radius: 3px;
        padding: 10px;
        width: 200px;
        z-index: 99999;
    }

    .n-arrow-password-confirm:after,
    .n-arrow-password-confirm:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .n-arrow-password-confirm:after {
        border-width: 7px;
        margin-top: -34px;
    }

    .n-arrow-password-confirm:before {
        border-width: 7px;
        margin-top: -34px;
    }

    @media (max-width: 767px) {
        .n-password-requirements-1 {
            right: -190px;
        }

        .n-password-requirements-confirm {
            right: -190px;
            top: 93px;
        }

        .n-request-password-reset {
            padding: 0 15px;
        }

        .n-password-requirements-1 {
            right: 0px;
        }

        .n-password-requirements-confirm {
            right: 0px;
            top: 93px;
        }

        .n-mobile-requirements {
            border: 1px solid;
            padding: 10px;
            border-radius: 3px;
        }
    }
</style>
