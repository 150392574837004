<template>
  <div>
    <PageSubHeader title="Sales Structure" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-12">
        <dl>
          <dt>Region (Optional)</dt>
          <dd>
            <span v-if="user.region">{{ user.region }}</span>
            <span v-else>No Region</span>
          </dd>
          <dt>District (Optional)</dt>
          <dd>
            <span v-if="user.district">{{ user.district }}</span>
            <span v-else>No District</span>
          </dd>
          <dt>Territory (Optional)</dt>
          <dd>
            <span v-if="user.territory">{{ user.territory }}</span>
            <span v-else>No Territory</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

export default {
  name: "UserSalesStructureViewOnly",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    PageSubHeader,
  },
};
</script>
