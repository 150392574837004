<template>
    <div v-if="rules && rules.length">
        <div class="n-password-requirements-1 n-arrow-password-require" :class="isPasswordValid ? 'n-success-border n-success-arrow' : 'n-error-border n-error-arrow'">
            <div :class="isPasswordValid ? 'n-success' : 'n-error'">
                <strong>Password Requirements</strong>
            </div>
            <div v-for="(rules, index) in rules" :key="index">
                <div class="col-1 float-left n-password-requirement-icon">
                <template v-if="rules.code === 0">
                    <font-awesome-icon icon="check-circle" class="n-success"/>
                </template>
                <template v-else>
                    <font-awesome-icon icon="times-circle" class="n-error" />
                </template>
                </div>
                <div class="col-11 n-password-requirements-bullets">
                    {{ rules.debugMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PasswordValidation",
    props: {
        rules: {
            type: Array
        },
        isPasswordValid: {
            type: Boolean
        }
    }
}
</script>

<style lang="scss" scoped>

</style>