<template>
    <div>
        <PageSubHeader title="Roles" :hasThematicBreak="true" />
        <div class="row">
            <div class="col-12 mb-2">
                Select all roles that apply to this user account:
            </div>
        </div>
        <div class="row">
            <div class="col-4" v-for="role in roles" :key="role.id">
                <div class="form-group">
                    <label>
                        <input type="checkbox"
                               v-model="user.userRoleIds"
                               name="roles"
                               :value="role.id" 
                               @change="roleSelected"/>
                        <span class="n-role-name ml-3">{{ role.name }}</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div v-if="$v.user.userRoleIds.$invalid && this.roleEverSelected">
                    <div class="n-error" v-if="!$v.user.userRoleIds.required">
                        Role is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3 mt-4">
                <div class="form-group">
                    <label class="n-form-labels">Primary Role</label>
                    <b-form-select v-model="user.primaryRoleId"
                                   :options="primaryRoles"
                                   value-field="id"
                                   text-field="name"
                                   class="n-text-field-highlight">
                        <template #first>
                            <b-form-select-option :value="null">Select Primary Role</b-form-select-option>
                        </template>
                    </b-form-select>

                    <div v-if="$v.user.primaryRoleId.$invalid">
                        <div class="n-error" v-if="!$v.user.primaryRoleId.required">
                            Primary Role is required
                        </div>
                    </div>
                </div>
                <span v-if="isRolesValid"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from "vuex";
    import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
    import { required, requiredIf } from "vuelidate/lib/validators";
    import { UserEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
    import {  
        SET_USER_PROPERTY,
    } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

    export default {
        name: "UserRoles",
        props: {
            user: { type: Object },
            roles: { type: Array },
        },
        components: {
            PageSubHeader,
        },
        computed: {
            isRolesValid() {
                let result = false;
                if (this.$v.user.userRoleIds.$invalid) {
                    result = false;
                } else if (this.$v.user.primaryRoleId.$invalid) {
                    result = false;
                } else {
                    result = true;
                }
                this.updateValidity(result);
                return result;
            },
            primaryRoles() {
                if (
                    !this.$nucleus.isEmpty(this.user) &&
                    !this.$nucleus.isEmpty(this.roles) &&
                    !this.$nucleus.isEmpty(this.user.userRoleIds)
                ) {
                    return this.roles.filter((r) => this.user.userRoleIds.includes(r.id));
                }
                return [];
            },
            selectedRoles: {
                get() {
                    return this.user.userRoleIds;
                },
                set(value) {
                    this.currentRoles = value;                   
                },
            }
        },

        data() {
            return {
                roleEverSelected: false,
                currentRoles: []
            };

        },
        methods: {
            ...mapMutations({                
                setUserProperty: SET_USER_PROPERTY,
            }),
            updateValidity(isValid) {
                this.$eventBus.$emit(UserEvents.USER_ROLES_UPDATED, { isValid: isValid, selectedRoles: this.user.userRoleIds });
            },
            isNotValid(fieldName) {
                return fieldName != null && fieldName.length == 0;
            },
            roleSelected() {
                this.roleEverSelected = true;
                this.user.primaryRoleId = null;
            },
        },
        validations: {
            user: {
                userRoleIds: {
                    required,
                },
                primaryRoleId: {
                    required: requiredIf(function () {
                        if (
                            !this.$nucleus.isEmpty(this.user) &&
                            !this.$nucleus.isEmpty(this.user.userRoleIds) &&
                            this.user.userRoleIds.length > 1
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                },
            },
        },
    };
</script>

<style lang="scss">
    .nav-link {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }

    .n-product-status {
        color: $green;
        margin-top: 12px;
    }

    .modal-footer {
        justify-content: center;
    }

    .modal-title {
        color: $tertiary-dark-color;
    }

    .modal-body {
        padding-top: 16px;
    }

    #confirmExit {
        left: unset !important;
    }
</style>
