<template>
  <div>
    <template v-if="large">
      <div class="alert" :class="'alert-' + variant">{{ message.text }}</div>
    </template>
    <template v-else>
      <div v-if="icon">
        <div class="d-flex">
          <div class="flex-shrink-1 pr-1">
            <font-awesome-icon :icon="icon" :class="[iconClass]" />
          </div>
          <div class="w-100">
            <span :class="[fontWeight, fontColor]">{{ message.text }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex">
          <div class="w-100">
            <span :class="[fontWeight, fontColor]" v-html="this.splitMessage(message.text)"></span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { MessageType } from "@/nucleus-modules/dd-nucleus-ui/constants";

/* message object:
 *  type - One of the values in MessageType
 *  icon - If supplied, the FontAwesome icon to use
 *  text - Text for the message
 */

export default {
  name: "Message",
  props: {
    message: {
      type: Object,
      required: true,
    },
    // If set, this will apply the same color for the message text as the message type.
    // E.G. If the message type is an Error, the text for this message will include the same CSS text color (danger) as the icon that's used
    textIconColorSync: {
      type: Boolean,
      required: false,
      default: false,
    },
    // If set, this will use larger presentation (e.g. Bootstrap alert boxes). Otherwise, just an icon and text.
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    bold: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    // Compute the icon to be used. It can be supplied in the icon property, or if one is not set, it will use the message type.
    icon() {
      if (this.message.icon && this.message.icon.length > 0)
        return this.message.icon;
      else {
        switch (this.message.type) {
          case MessageType.ERROR:
            return "times-circle";
          case MessageType.WARNING:
            return "exclamation-circle";
          case MessageType.INFO:
            return "info-circle";
          default:
            return "";
        }
      }
    },

    // Compute the CSS class to use based on the message type
    iconClass() {
      if (this.message.type != MessageType.CUSTOM) {
        return "text-" + this.variant;
      } else {
        return this.variant;
      }
    },

    // Compute the CSS class to use based on the message type
    variant() {
      switch (this.message.type) {
        case MessageType.ERROR:
          return "danger";
        case MessageType.WARNING:
          return "warning";
        case MessageType.INFO:
          return "info";
        case MessageType.SUCCESS:
          return "success";
        case MessageType.NEUTRAL:
          return "muted";
        case MessageType.CUSTOM:
          return this.message.customClass;
        default:
          return "";
      }
    },

    fontWeight() {
      if (this.bold) {
        return "font-weight-bold";
      } else {
        return "font-weight-normal";
      }
    },

    fontColor() {
      if (this.textIconColorSync) {
        return this.iconClass;
      }

      return "";
    },
  },
  methods: {
    splitMessage(resultStr) {
      if (resultStr == null || this.$nucleus.isEmpty(resultStr)) {
        return resultStr;
      } else {
        let splitChars = ':';
        if (resultStr.indexOf(splitChars) >= 0) {
          let dtlStr = resultStr.split(splitChars);
          return '<strong class="n-main-text-color n-limit-text">' + dtlStr[0] + ': </strong> ' + dtlStr[1];
        } else {
          return resultStr;
        }
      }
    }
  }

};
</script>
<style lang="scss" scoped>
</style>