export const GET_ADMIN_MENU = "getAdminMenu";

// bulk orders
export const ADD_BULK_ORDER_RECIPIENT = "addBulkOrderRecipient";
export const GET_BULK_ORDER_DETAIL = "getBulkOrderDetail";
export const INIT_BULK_ORDER = "initBulkOrder";
export const PLACE_BULK_ORDER = "placeBulkOrder";
export const REMOVE_BULK_ORDER_RECIPIENT = "removeBulkOrderRecipient";
export const SEARCH_BULK_ORDER_RECIPIENTS = "searchBulkOrderRecipients";
export const SEARCH_BULK_ORDERS = "searchBulkOrders";
export const UPDATE_BULK_ORDER_PRODUCTS = "updateBulkOrderProducts";
export const UPDATE_BULK_ORDER_SHIPPING_METHOD = "updateBulkOrderShippingMethod";
export const RESET_BULK_ORDER_ERRORS = "resetBulkOrderErrors";

// products
export const GET_ALL_PRODUCTS = "getAllProducts";
export const GET_PRODUCT_MEDIA = "getProductMedia";
export const GET_PRODUCT_FOR_EDIT = "getProductForEdit";
export const REMOVE_PRODUCT_MEDIA = "removeProductMedia";
export const SAVE_PRODUCT = "saveProduct";
export const SEARCH_PRODUCTS = "searchProducts-admin";
export const UPDATE_PRODUCT_MEDIA_SORT = "updateProductMediaSort";
export const SEARCH_ADMIN_PRODUCTS = "searchAdminProducts"

// orders
export const APPROVE_ORDER = "approveOrder";
export const GET_ORDER_DETAILS = "getOrderDetails";
export const GET_ORDERS = "getOrders";
export const MASS_APPROVE_ORDERS = "massApproveOrders";
export const MASS_REJECT_ORDERS = "massRejectOrders";
export const REJECT_ORDER = "rejectOrder";
export const SAVE_ORDER_INTERNAL_NOTES = "saveOrderInternalNotes";
export const SAVE_ORDER_PRODUCT_QUANTITIES = "saveProductQuantities";
export const SAVE_ORDER_SHIP_TO_ADDRESS = "saveShipToAddress";
export const SAVE_ORDER_SHIP_METHOD = "saveOrderShipMethod";
export const SEARCH_ORDERS = "searchApprovalOrders";

// roles
export const GET_ALL_ROLES = "getAllRoles";

// users
export const GET_USERS = "getUsers";
export const GET_USER_FOR_EDIT = "getUserForEdit";
export const SEARCH_USERS = "searchUsers";

//customer segments
export const GET_COMPANY_SEGMENTS = "getCompanySegments";

//inventory sync
export const UPDATE_INVENTORY = "updateInventory";
export const GET_LAST_INV_SYNC_TIME = "getLastInvSyncTime";
export const UPDATE_INVENTORY_VEEVA = "updateInventoryVeeva";

export const SEND_WELCOME_EMAIL = "sendWelcomeEmail";

//announcement
export const GET_ANNOUNCEMENT_FOR_EDIT = "getAnnouncementForEdit";
export const SEARCH_ANNOUNCEMENTS = "searchAnnouncements";
export const DELETE_ANNOUNCEMENT = "deleteAnnouncement";
export const SAVE_ANNOUNCEMENT = "saveAnnouncement";
export const PUBLISH_OR_UNPUBLISH_ANNOUNCEMENT = "publishOrUnpublishAnnouncement";

//audit activity
export const GET_AUDIT_FIELDS = "getAuditFields";


