<template>
  <div class="row" v-if="hasAddOnItems">
    <div class="col-sm-12">
      <h1>
        You may be interested in ...
      </h1>
      <div v-if="hasItems" class="n-background">
        <div v-for="item in cartAddOnItem.items" :key="item.id">
          <template v-if="!itemInCart(item.id)">
            <AddOnItem :cartId="cart.id" :addOnItem="item" :destinationUrl="getNavigationUrl(item)"
              :noImageAvailableUrl="noImageAvailableUrl" :showSeparator="false">
            </AddOnItem>
            <div class="col-12 mt-2 mb-2">
              <hr />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import {
  REMOVE_FROM_CART
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

import { GET_CART_ADD_ON_ITEM } from "@/store/storefront/actions.type.js";
import { CART_ADD_ON_ITEM_GETTER } from "@/store/storefront/getters.type.js";

import AddOnItem from "@/components/cart/AddOnItem.vue";
import { noImageAvailableUrl } from "@/companyAppConfig.js";

export default {
  name: "ShoppingCartAddOn",
  // mixins: [OrdersMixin],
  components: {
    AddOnItem,
  },
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      noImageAvailableUrl: noImageAvailableUrl
    };
  },
  computed: {
    ...mapGetters({
      cartAddOnItem: CART_ADD_ON_ITEM_GETTER
    }),
    hasItems() {
      return this.cartAddOnItem && this.cartAddOnItem.items && this.cartAddOnItem.items.length > 0;
    },
    hasCartItem() {
      return this.cart && this.cart.items && this.cart.items.length
    },
    hasAddOnItems() {

      if (this.hasCartItem && this.hasItems)
      {
        const addOnItems = this.cartAddOnItem.items;
        const cartProductIds = this.cart.items.map(ci=>ci.product.id)

        const result = addOnItems.filter(ai=>!cartProductIds.includes(ai.id));
        return result && result.length>0
      }
      return false;
    }
  },
  methods: {
    ...mapActions({
      removeFromCart: REMOVE_FROM_CART,
      getCartAddOnItem: GET_CART_ADD_ON_ITEM

    }),
    getNavigationUrl(item) {
      return `/product-detail/${item.id}`;
    },
    initListeners() {
      this.$eventBus.$on(UiEvents.REMOVE_CART_ITEM_CLICKED, (eventData) => {
        this.removeFromCart({
          cartId: eventData.cartId,
          itemId: eventData.item.id,
        });
      });
    },
    removeListeners() {
      this.$eventBus.$off(UiEvents.REMOVE_CART_ITEM_CLICKED);
    },
    itemInCart(productId) {
      if (productId && this.hasCartItem) {
        const result = this.cart.items.find((p) => p.product.id == productId);
        return result;
      }
    }
  },
  async mounted() {
    this.initListeners();
    await this.getCartAddOnItem();
  },
  beforeDestroy() {
    this.removeListeners();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  padding-bottom: 30px;
  font-size: 1.5rem;
}

h1 .n-total-items {
  color: $gray-3;
  font-size: 1rem;
  font-family: $body-font;
}

.n-background {
  background: $admin-primary-lightest-color;
  padding-top: 20px;
  padding-left: 20px;
}
</style>
