<template>
  <div>
    <button
      type="button"
      class="btn btn-outline-primary mr-2 mr-1"
      :disabled="onFirstTab"
      @click="previousStep"
      v-if="!onFirstTab"
    >
      Previous
    </button>
    <button
      type="button"
      class="btn btn-primary"
      :disabled="isNextDisabled || onLastTab"
      @click="nextStep"
      v-if="!onLastTab"
    >
      Continue
    </button>
  </div>
</template>

<script>
export default {
  name: "BulkOrderNavigation",
  props: {
    onFirstTab: {
      type: Boolean,
    },
    onLastTab: {
      type: Boolean,
    },
    isNextDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    nextStep() {
      this.$emit("next-step");
    },
    previousStep() {
      this.$emit("previous-step");
    },
  },
};
</script>

<style lang="scss" scoped></style>
