<template>
    <div>
        <div class="row">
            <div class="col">
                <h4>Assign Categories</h4>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="accordion">
                    <div v-for="category in flattenedCategories"
                         :key="category.id"
                         class="card">
                        <ProductCategoryItem :category="category" :level=0 :onSelect="onSelect" :selectedCategories="selectedCategories"></ProductCategoryItem>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import { PRODUCT_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

    import {
        SET_IS_PRODUCT_EDIT_DIRTY,
        SET_PRODUCT_PROPERTY,
    } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    import ProductCategoryItem from "@/components/admin/product/edit-product/ProductCategoryItem.vue"
    export default {
        name: "ProductCategories",
        props: {
            categories: {
                type: Array,
                required: true,
            },
        },
        components: {
            ProductCategoryItem
        },
        data() {
            return {
                firstWatch: true,
                flattenedCategories: [],
                selectedCategories: [],
            };
        },
        computed: {
            ...mapGetters({
                product: PRODUCT_FOR_EDIT_GETTER,
            }),
        },
        methods: {
            ...mapMutations({
                setIsProductEditDirty: SET_IS_PRODUCT_EDIT_DIRTY,
                setProductProperty: SET_PRODUCT_PROPERTY,
            }),
            setSelectedCategories(categories, existingSelectedCategories) {
               categories.forEach((fc) => {
                    const existing = existingSelectedCategories.find((c) => c.id === fc.id);
                    fc.selected = !!existing;
                    if(fc.children && fc.children.length > 0){
                      this.setSelectedCategories(fc.children, existingSelectedCategories);
                    }
                });
            },

            getSelectedCategories(categories) {
                if (categories && categories.length) {
                    let selectedIds = [];
                    categories.forEach(fc => {
                        let parentSelected = false;
                        if (this.selectedCategories.find(c => c.id == fc.id)) {
                            selectedIds.push(fc);
                            parentSelected = true;
                        }
                        if (fc.children && fc.children.length) {
                            let selectedChildCategoryIds = this.getSelectedCategories(fc.children)
                            if(selectedChildCategoryIds && selectedChildCategoryIds.length){
                              if(!parentSelected){
                                selectedIds.push(fc);
                              }
                              selectedIds = selectedIds.concat(selectedChildCategoryIds);
                            }
                        }
                    });
                    return selectedIds;
                }
                else {
                    return [];
                }
            },
            onSelect(data) {
                if (data) {
                    if(data.selected && data.id) {
                        this.selectedCategories.push({id:data.id});
                    } 
                    let allSelectedCategories = this.getSelectedCategories(this.flattenedCategories);
                    //removing parent category if its intentionally unselected
                    if(!data.selected && data.id) {
                       allSelectedCategories = allSelectedCategories.filter(ac => ac.id!== data.id);
                    } 
                    this.selectedCategories = allSelectedCategories.map(c => { return {id : c.id} });
                    this.setProductProperty({
                        name: "categories",
                        value: this.selectedCategories,
                    });
                    this.flattenedCategories = this.flattenedCategories.splice(0);
                }
            },
        },
        mounted() {
            //this.flattenedCategories = this.categories.flat();
            this.flattenedCategories = [...this.categories];
            this.setSelectedCategories(this.flattenedCategories, this.product.categories);
            this.flattenedCategories = this.flattenedCategories.splice(0);
            this.selectedCategories = this.product.categories;
        },
 /*        watch: {
            flattenedCategories: {
                deep: true,
                handler: function () {
                    this.onSelect();

                    if (this.firstWatch) {
                        this.setIsProductEditDirty(false);
                        this.firstWatch = false;
                    }
                },
            },
        },*/
     };
</script>

<style lang="scss" scoped></style>
