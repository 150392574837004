<template>
  <div>
    <div class="text-right n-order-text-year my-3">{{ totalOrders }} {{ message }}</div>
    <div v-for="order in orders" :key="order.orderNumber">
      <OrderHistoryItem :order="order" class="mb-3" />
    </div>
  </div>
</template>

<script>
import OrderHistoryItem from "@/nucleus-modules/dd-nucleus-storefront/components/OrderHistoryItem.vue";

export default {
  name: "Order-History-Item",
  components: {
    OrderHistoryItem,
  },
  props: ["orders", "message"],
  computed: {
    totalOrders() {
      if(this.orders) {
      return this.orders.length;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.sortOrders();
  },
  methods: {
    sortOrders() {
      this.orders.sort((a, b) => (a.date > b.date ? 1 : -1));
      this.orders.reverse();
    },
  },
};
</script>

<style lang="scss" scoped></style>
