<template>
<div>
    <div class="n-filter-search p-1 ml-3" >

        <div class="row pb-0 pt-0 mt-3">
            <div class="col-5 display-style" >
                <div class="n-button-filters d-inline mb-3">
                    <ul class="quickFilters quickFilters-margin">
                        <li v-for="filter in quickFilters" :key="filter.id">
                            <button class="btn btn-white" :class="{ active: quickFiltersSelected.includes(filter) }" @click="toggleQuickFilter(filter)">
                                {{ filter }}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-4">
                <div class="d-inline">

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "AnnouncementFilters",
    data() {
        return {
            quickFiltersSelected: ['All'],
        };
    },
    props: {
        quickFilters: {
            type: Array,
        },
    },
    methods: {
        clearFilters() {
            this.quickFiltersSelected = ["All"];
        },
        filterannouncements() {
            this.$emit("filterannouncements");
        },

        toggleQuickFilter(filter) {
            this.quickFiltersSelected = [];
            this.quickFiltersSelected.push(filter);

            if (this.$nucleus.isEmpty(this.quickFiltersSelected)) {
                this.quickFiltersSelected = [];
            }

            this.$emit("toggleQuickFilter", this.quickFiltersSelected);
        },
    },
    created() {
        this.clearFilters();
    },
};
</script>

<style lang="scss" scoped>
.n-filter-search {
    background: $admin-primary-lightest-color;
}

.n-button-filters .btn {
    border-radius: 0;
    text-transform: capitalize;
}

.n-button-filters .active {
    background: $admin-primary-light-color;
    color: $admin-white-color;
    border: 1px solid $admin-primary-light-color;
}
.quickFilters-margin{
    margin-left:30px;
}
.display-style
{
    display:contents;
}
</style>
