<template>
  <PageTemplate>
    <div v-if="isLoading"></div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">
          <h3 class="n-back-link">
            <router-link to="/product-results">
              <font-awesome-icon icon="chevron-left" />
              {{ $t("shoppingCart.continueShopping") }}</router-link
            >
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <div v-if="hasItemWarnings">
            <h2>Your order is on hold pending approval.</h2>
            <h1>Order #{{ order.orderNumber }}</h1>
          </div>
          <div v-else>
            <h2>We've received your order</h2>
            <h1>Order #{{ order.orderNumber }}</h1>
          </div>
          <div>
            <p class="mb-3">
              An email confirmation containing order details has been sent to
              {{ order.shipTo.email }}.
            </p>
            <strong>
              You may review your
              <a href="#" @click="navToOrderHistory()">order status</a> at any
              time.
            </strong>
          </div>
        </div>
      </div>
      <div v-if="hasOrderWarnings" class="row mt-5">
        <div class="col">
          <b-alert variant="warning" show>
            <strong
              >Please review the important messages below for details regarding
              your order. You will receive a notification via email once your
              order has been reviewed.</strong
            >
            <ul class="mb-0">
              <li
                v-for="(warning, index) in formattedOrderWarnings"
                :key="index"
              >
                <span v-html="warning"></span>
              </li>
            </ul>
          </b-alert>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-8">
          <div class="row">
            <div class="col">
              <Headline
                title="Shipping Address"
                class="n-no-top-margin"
              ></Headline>
            </div>
          </div>
          <div class="row px-4 mb-3">
            <div class="col">
              <ShipAddressView :address="shippingAddress"></ShipAddressView>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <Headline title="Shipping Method"></Headline>
            </div>
          </div>
          <div class="row px-4 mb-3">
            <div class="col">
              <ShippingMethodView
                :shippingMethod="shippingMethod"
                :showPricing="false"
              ></ShippingMethodView>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <Headline title="Order Summary"></Headline>
            </div>
          </div>
          <div class="mb-3">
            <div v-for="item in items" :key="item.id">
              <div class="row mb-3 px-4">
                <div class="col-4 col-md-2">
                  <img
                    :src="
                      item.product.thumbnailImageUrl
                        ? item.product.thumbnailImageUrl
                        : noImageAvailableUrl
                    "
                    :name="item.product.name"
                    :alt="item.product.name"
                    class="img-fluid"
                  />
                </div>
                <div class="col-8">
                  <div
                    class="badges"
                    v-for="badge in item.product.tags"
                    :key="badge.id"
                  >
                    <span class="badge badge-primary">{{ badge.name }}</span>
                    
                  </div>
                  <span class="badge badge-primary add-on-badge" v-if="isAddOnItem(item)">Add-on item</span>
                  <router-link
                    :to="`/product-detail/${item.product.id}`"
                    class="d-block n-product-name mb-2"
                    >{{ item.product.name }}</router-link
                  >
                  <div>{{ item.product.itemNumber }}</div>
                  <template v-if="isRegularItem(item)">
                  <template v-if="item.product.messages && item.product.messages.length">
                    <div class="n-message-list">
                      <MessageList :messages="item.product.messages" />
                    </div>
                  </template>
                  <div v-for="(message, index) in item.messages" :key="index" class="mt-2"> 
                    <font-awesome-icon
                      v-if="message.messageType === errorMessageType"
                      class="text-danger mr-2"
                      icon="times-circle"
                    />
                    <font-awesome-icon
                      v-else
                      class="text-warning mr-2"
                      icon="exclamation-circle"
                    />
                    <span v-html="getWarningByMessage(message)"></span>
                  </div>
                </template>
                </div>
                <div class="col-8 offset-4 offset-sm-0 col-md-2">
                  <div class="row">
                    <div class="col">
                      <div><strong>Quantity:</strong></div>
                      <span class="mr-3">{{ item.quantity }}</span>
                      <label>
                        <template v-if="isUnitOfMeasureAvailable(item.product)">
                          <UoM :item="item.product" :uom="getInventory(item.product).unitOfMeasure" :unitQuantity="getInventory(item.product).quantityPerUnit"></UoM>
                        </template>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col text-center">
              <div class="n-order-details">
                <h2>Your Order</h2>
                <hr />
                <h4 class="mt-3">Estimated Arrival</h4>
                <h3 class="font-weight-normal">
                  {{ shippingMethod.minDaysInTransit }} -
                  {{ shippingMethod.maxDaysInTransit }} business days
                </h3>
              </div>
            </div>
          </div>
          <div class="row mt-5 mb-3">
            <div class="col text-center">
              <button
                type="button"
                class="btn btn-primary"
                @click="navToProductResults"
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageTemplate>
</template>

<script>
import Headline from "@/nucleus-modules/dd-nucleus-ui/components/Headline.vue";
import UoM from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";

import {
  OrderApprovalIds,
  OrdersMixin,
  ERROR_MESSAGE_TYPE
} from "@/nucleus-modules/dd-nucleus-storefront/mixins/OrdersMixin.js";
import ShipAddressView from "@/nucleus-modules/dd-nucleus-storefront/components/ShipAddressView.vue";
import ShippingMethodView from "@/nucleus-modules/dd-nucleus-storefront/components/ShippingMethodView.vue";

import PageTemplate from "@/pages/templates/PageTemplate.vue";
import { noImageAvailableUrl } from "@/companyAppConfig.js";
import MessageList from "@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue";
import { productMixins } from "@/mixins/productMixin.js";

export default {
  name: "OrderConfirmation",
  mixins: [OrdersMixin, productMixins],
  components: {
    Headline,
    UoM,
    ShipAddressView,
    ShippingMethodView,
    PageTemplate,
    MessageList,
  },
  data() {
    return {
      isLoading: true,
      shippingAddress: null,
      shippingMethod: null,
      noImageAvailableUrl: noImageAvailableUrl,
      errorMessageType: ERROR_MESSAGE_TYPE
    };
  },
  computed: {
    formattedOrderWarnings() {
      const warnings = [];

      if (this.order && this.order.messages && this.order.messages.length > 0) {
        this.order.messages.forEach((message) => {
          if (message && message.parameters && message.parameters.length > 0) {
            message.parameters.forEach((p) => {
              switch (p) {
                case OrderApprovalIds.CRM_ADDRESS_USED:
                  warnings.push(
                    "<strong>CRM Address Selected:</strong> This order will require approval.  You have selected a ship to address from your CRM address book."
                  );
                  break;
                case OrderApprovalIds.EXPEDITED_SHIP_METHOD:
                  warnings.push(
                    "<strong>Expedited Shipping:</strong> This order requires approval. You have selected an expedited shipping method."
                  );
                  break;
                case OrderApprovalIds.LIMIT_EXCEEDED:
                  warnings.push(
                    "<strong>Limit Exceeded:</strong> This order requires approval. You have exceeded the allowed limit for one or more product(s)."
                  );
                  break;
                case OrderApprovalIds.NOT_DEFAULT_ADDRESS:
                  warnings.push(
                    "<strong>Shipping Address:</strong> This order requires approval. You are shipping to an address other than your default shipping address."
                  );
                  break;
              }
            });
          }
        });
      }

      return warnings;
    },
    hasItemWarnings() {
      let result = false;

      this.order.items.forEach((i) => {
        if (i.messages && i.messages.length > 0) {
          result = true;
        }
      });

      return result;
    },
    itemWarnings() {
      let messageIds = [];
      const result = [];

      this.order.items.forEach((i) => {
        if (i.messages && i.messages.length > 0) {
          i.messages.forEach((m) => {
            messageIds.push(m.messageId);
          });
        }
      });

      messageIds = Array.from(new Set(messageIds));

      // TODO: add other message ids
      messageIds.forEach((m) => {
        switch (m) {
          case OrderApprovalIds.LIMIT_EXCEEDED:
            result.push({
              title: "Monthly Limit Exceeded",
              text:
                "This order requires approval. You have exceeded the monthly limit for one or more product(s).",
            });
            break;
        }
      });

      return result;
    },
    items() {
      if (
        this.$nucleus.isEmpty(this.order) ||
        this.$nucleus.isEmpty(this.order.items)
      ) {
        return [];
      } else {
        return this.order.items;
      }
    },
  },
  methods: {
    navToOrderHistory() {
      this.$router.push("/orders");
    },
    navToProductResults() {
      this.$router.push("/product-results");
    },
    isAddOnItem(item) {
      if (item && item.product && item.product.attributes && item.product.attributes.Addon) {
        return item.product.attributes.Addon.isAddOn;
      }
      return false;
    },
    isRegularItem(item) {
      return !this.isAddOnItem(item);
    }
  },
  async mounted() {
    if (this.$nucleus.isEmpty(this.placeOrderResponse)) {
      this.$router.push("/shopping-cart");
    }

    this.shippingAddress = this.order.shipTo;
    if(this.shippingAddress){
      if(this.shippingAddress.attention 
          && this.shippingAddress.addressee 
          && this.shippingAddress.attention != this.shippingAddress.addressee) {
          this.shippingAddress.organization = this.shippingAddress.addressee;
          this.shippingAddress.firstName = this.shippingAddress.attention;
      }
      else {
        this.shippingAddress.firstName = this.shippingAddress.addressee;
      }
    }

    if (this.shippingAddress) {
      let address = this.addresses.find(
        (a) => a.id === this.order.shipToAddressId
      );
      this.shippingAddress.isDefault = address && address.isDefault;
    }

    this.shippingMethod = this.shippingMethods.find(
      (sm) => sm.code === this.order.shipMethodCode
    );

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.n-no-top-margin {
  margin-top: 0;
}
</style>
