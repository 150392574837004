import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import { SET_USER_INFO } from "@/nucleus-modules/dd-nucleus-ui/store/mutations.type.js";

import { ASSIGN_USER_FROM_JWT, GET_SITE_SETUP, UPDATE_SHIP_ADDRESS, GET_USER_CONTACT, GET_USER_ADDRESSES } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { MONTHS_GETTER, SITE_SETUP_GETTER, MENUS_GETTER, MENU_BY_ID_GETTER, STATES_LIST_GETTER, YEARS_GETTER, SHIP_ADDRESS_GETTER, USER_CONTACT_GETTER, USER_ADDRESSES_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { SET_SITE_SETUP, SET_SHIP_ADDRESS, SET_USER_CONTACT, SET_USER_ADDRESSES } from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";


const MODULE_ID = "storefront";

const getStatesList = () => {
    return [
        { code: "AL", name: "Alabama" },
        { code: "AK", name: "Alaska" },
        { code: "AZ", name: "Arizona" },
        { code: "AR", name: "Arkansas" },
        { code: "CA", name: "California" },
        { code: "CO", name: "Colorado" },
        { code: "CT", name: "Connecticut" },
        { code: "DE", name: "Delaware" },
        { code: "DC", name: "District Of Columbia" },
        { code: "FL", name: "Florida" },
        { code: "GA", name: "Georgia" },
        { code: "HI", name: "Hawaii" },
        { code: "ID", name: "Idaho" },
        { code: "IL", name: "Illinois" },
        { code: "IN", name: "Indiana" },
        { code: "IA", name: "Iowa" },
        { code: "KS", name: "Kansas" },
        { code: "KY", name: "Kentucky" },
        { code: "LA", name: "Louisiana" },
        { code: "ME", name: "Maine" },
        { code: "MD", name: "Maryland" },
        { code: "MA", name: "Massachusetts" },
        { code: "MI", name: "Michigan" },
        { code: "MN", name: "Minnesota" },
        { code: "MS", name: "Mississippi" },
        { code: "MO", name: "Missouri" },
        { code: "MT", name: "Montana" },
        { code: "NE", name: "Nebraska" },
        { code: "NV", name: "Nevada" },
        { code: "NH", name: "New Hampshire" },
        { code: "NJ", name: "New Jersey" },
        { code: "NM", name: "New Mexico" },
        { code: "NY", name: "New York" },
        { code: "NC", name: "North Carolina" },
        { code: "ND", name: "North Dakota" },
        { code: "OH", name: "Ohio" },
        { code: "OK", name: "Oklahoma" },
        { code: "OR", name: "Oregon" },
        { code: "PA", name: "Pennsylvania" },
        { code: "PR", name: "Puerto Rico" },
        { code: "RI", name: "Rhode Island" },
        { code: "SC", name: "South Carolina" },
        { code: "SD", name: "South Dakota" },
        { code: "TN", name: "Tennessee" },
        { code: "TX", name: "Texas" },
        { code: "UT", name: "Utah" },
        { code: "VT", name: "Vermont" },
        { code: "VA", name: "Virginia" },
        { code: "WA", name: "Washington" },
        { code: "WV", name: "West Virginia" },
        { code: "WI", name: "Wisconsin" },
        { code: "WY", name: "Wyoming" }
    ];
}

const initialState = {
    months: [],
    setup: {},
    // TODO: retrieve the list of statesList from backend
    statesList: getStatesList(),
    years: [],
    shipAddress: {},
    userContact: {},
    userAddresses: []

};

export const state = { ...initialState };

export const actions = {
  async [ASSIGN_USER_FROM_JWT]({ dispatch }) {
    ApiService.setAuthHeader();

    await dispatch(GET_SITE_SETUP);
  },
  async [GET_SITE_SETUP]({ commit }) {
    const response = await ApiService.get("ui/new-visit");
    if (response.succeeded) {
      commit(SET_SITE_SETUP, response.data);
      commit(SET_USER_INFO, response.data.userInfo);
    }
  },
  async [UPDATE_SHIP_ADDRESS]({ commit }, payload) {
    commit(SET_SHIP_ADDRESS, payload);
  },
  async [GET_USER_ADDRESSES]({ commit }, id) {
    const response = await ApiService.get(`api/v1/contact/${id}/addresses`);
    if (response.succeeded) {
      commit(SET_USER_ADDRESSES, response.results);
    }
  },
  async [GET_USER_CONTACT]({ commit }, id) {
    const urlPrefix = `api/v1/contact/${id}`;
    const emailResponse = await ApiService.get(`${urlPrefix}/emails`);
    const phoneNumberResponse = await ApiService.get(
      `${urlPrefix}/phone-numbers`
    );

    if (emailResponse.succeeded && phoneNumberResponse.succeeded) {
      const result = {};
      if (emailResponse.results && emailResponse.results.length) {
        result.email = emailResponse.results[0].email;
      }
      if (phoneNumberResponse.results && phoneNumberResponse.results.length) {
        result.phoneNumber = phoneNumberResponse.results[0].number;
      }

      commit(SET_USER_CONTACT, result);
    }
  },
};

export const getters = {
    [SITE_SETUP_GETTER]: state => {
        return state.setup;
    },
    [MENUS_GETTER]: state => {
        return state.setup.site.menus;
    },
    [MENU_BY_ID_GETTER]: (state) => (id) => {
        if (state.setup != null && state.setup.site != null)
            return state.setup.site.menus.find(menu => menu.id === id);
        else
            return {};
    },
    [MONTHS_GETTER]: state => {
        return state.months;
    },
    [STATES_LIST_GETTER]: state => {
        return state.statesList;
    },
    [YEARS_GETTER]: state => {
        return state.years;
    },
    [SHIP_ADDRESS_GETTER]: state => {
        return state.shipAddress
    },
    [USER_ADDRESSES_GETTER]: state => {
        return state.userAddresses
    },
    [USER_CONTACT_GETTER]: state => {
        return state.userContact
    }
};

export const mutations = {
    [SET_SITE_SETUP]: (state, setup) => {
        state.setup = setup;
    },
    [SET_SHIP_ADDRESS]: (state, shipAddress) => {
        state.shipAddress = shipAddress;
    },
    [SET_USER_ADDRESSES]: (state, addresses) => {
        state.userAddresses = addresses;
    },
    [SET_USER_CONTACT]: (state, contact) => {
        state.userContact = contact;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
    MODULE_ID
};
