export const AccountAddressCheckoutMessage = Object.freeze({
    "type": "warning",
    "text": "<strong>Your default account address can’t be modified.</strong>  Please contact <a href='mailto:evofemsupport@qualfon.com'>evofemsupport@qualfon.com</a> if you would like to update this address."
});

export const AccountInformationMessage = Object.freeze({
    "type": "warning",
    "text": "Your profile details are provided by Regeneron.  To update any data, you must send an inquiry to Regeneron’s HR system."
});

export const AccountShippingAddressMessage = Object.freeze({
    "type": "warning",
    "text": ""
});

export const AccountAddressMessage = Object.freeze({
    "type": "warning",
    "text": "Your primary account address is provided by Regeneron. To update this address you must send a request to Regeneron's HR System."
});

export const ShippingAddressMessage = Object.freeze({
    "type": "warning",
    "text": "Your default shipping address is provided by Regeneron. To update this address you must send a request to Regeneron's HR System."
});
export const defaultShippingAddressSort = 'lastName';

// Delay to hide toasts or other messaging in milliseconds 
export const autoHideDelay = 5000;

// Milliseconds before the slider changes 
export const sliderChangeDelay = 5000;

export const minLengthProductName = 4;

export const maxLengthProductName = 80;


export const storefrontRoles = [
    "DUPIXENT_AD_DM",
    "DUPIXENT_AD_MS",
    "DUPIXENT_AD_TLL",
    "DUPIXENT_ALLERGY_DM",
    "DUPIXENT_ALLERGY_ENT_MS",
    "DUPIXENT_ALLERGY_MS",
    "DUPIXENT_RESP_TLL",
    "EYLEA_DMS",
    "EYLEA_MS",
    "EYLEA_RBM",
    "EYLEA_OPH_KEY_ACCOUNTS",
    "DUPIXENT_FRM",
    "LIBTAYO_OAS",
    "LIBTAYO_ORM",
    "LIBTAYO_OKAL",
    "PRALUENT_EVKEEZA_MS",
    "PRALUENT_EVKEEZA_RBM",
    "ONCOLOGY_STRATEGIC_CUSTOMER_ENGAGEMENT",
    "DUPIXENT_ENT_TLL",
    "DUPIXENT_GASTRO_MS",
    "DUPIXENT_GASTRO_DM",
    "DUPIXENT_GASTRO_TLL",
    "DUPIXENT_PULM_MS",
    "DUPIXENT_PULM_DM",
    "DUPIXENT_PULM_TLL",
    "TRADE",
    "EVK_CONSULTANT_DIAGNOSTIC_SPECIALIST",
    "EVK_CONSULTANT_NATIONAL_PROGRAM_DIRECTOR",
    "CONSULTANT_BIOPHARMACEUTICAL_SPECIALIST",
    "CONSULTANT_BIOPHARMACEUTICAL_DISTRICT_MANAGER",
    "DUPIXENT_DERM_MARKETING",
    "DUPIXENT_GASTRO_MARKETING",
    "DUPIXENT_PULM_MARKETING",
    "DUPIXENT_ALLERGY_MARKETING",
    "EYLEA_MARKETING",
    "LIBTAYO_MARKETING",
    "PRALUENT_EVKEEZA_MARKETING",
    "EYLEA_KAR",
    "EVK_CONSULTANT_PATIENT_NAVIGATOR",
    "LIBTAYO_LGES",
    "EVK_RKAL"
];

export const adminRoles = ["AppDev", "CallCenterAgent", "CallCenterMgr", "ClientAdmin", "CSDAdmin", "SA"];

export const omniSearchShowProductItemNumber = true;

export const omniSearchTotalLinksToShow = 10;

export const noImageAvailableUrl = "/images/no-image-available-th.png";
export const noMainImageAvailableUrl = "/images/no-image-available.png";

export const totalFeatureProductsToShow = 6;
export const totalFavoriteProductsToShow = 5;
export const totalNewProductsToShow = 5;
export const defaultShowPanelThreshold = 3;
export const defaultNewTagExpDays = 30;

//Links in Footer
export const companyWebsite = "https://www.regeneron.com/";
export const privacyPolicyLink = "https://www.regeneron.com/privacy-policy";
export const termsOfUseLink = "https://www.regeneron.com/terms";
export const supportEmail = "regeneronteam@qualfon.com;promotional.materials@regeneron.com";
export const emailUs = "mailto:" + supportEmail;


export const allProductsMenuId = 'all-products';
export const notAvailableMenusForSSOUser = ['address-book', 'password'];
export const tagsFilter = [{ id: "Featured", name: "Featured Items" }, { id: "Favorite", name: "My Favorites" }, { id: "New", name: "New Items" }];
export const defaultShipMethod = "U11";

export const oktaStorageKeys = ['okta-cache-storage', 'okta-original-uri-storage', 'okta-shared-transaction-storage', 'okta-token-storage'];

export const subCategoryLevels = 2;
export const defaultShippingPhone = "999-999-9999";
export const editorApiKey = "ljhqr9mx0nns5kuij9ikgb39gq6h01rng13yyzoy0no494jv";