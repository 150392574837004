<template>
    <div v-if="!$nucleus.isEmpty(user)">
        <PageSubHeader title="Account Addresses" :hasThematicBreak="true" />
        <form name="add-user">
            <div class="row">
                <div class="col-5">
                    <h6 class="n-form-section-header">Primary Account Address</h6>                   
                    <UserAddress :address="primaryAddress"
                                 @updateAddress="updatePrimaryAddress"
                                 @updateValidity="updatePrimaryAddressValidity" />
                    <div class="form-group">
                        <label>
                            <input type="checkbox"
                                   v-model="isDefaultShip"
                                   name="isDefaultShip"
                                   @change="setPrimaryAddressToDefaultShip()" />
                            <span class="n-role-name ml-3">Use as Default Shipping Address</span>
                        </label>
                    </div>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <h6 class="n-form-section-header">Default Ship Address</h6>                   
                    <UserAddress :address="shippingAddress"
                                 @updateAddress="updateShippingAddress"
                                 @updateValidity="updateDefaultAddressValidity" />
                </div>
            </div>
            <span v-if="isAccountAddressInfoValid"></span>
        </form>
    </div>
</template>

<script>
    import {  mapMutations } from "vuex";
    
    import {
        SET_USER_ADDRESS,
        SET_USER_PROPERTY,
    } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

    import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
    import UserAddress from "@/nucleus-modules/dd-nucleus-admin/components/user/UserAddress.vue";
    import { addresses } from "@/nucleus-modules/dd-nucleus-admin/mixins/addressesMixin.js";
    import { UserEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
    import { AddressType } from "../../constants";

    export default {
        name: "UserAddressInfo",
        mixins: [addresses],
        components: {
            UserAddress,
            PageSubHeader,
        },
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isPrimaryAddressValid: false,
                isDefaultAddressValid: false,
                isDefaultShip: false,
            };
        },
        computed: {
            isAccountAddressInfoValid() {
                let result = this.isDefaultAddressValid && this.isPrimaryAddressValid;
                this.updateValidity(result);
                return result;
            },
            primaryAddress: {
                get() {
                    if (this.user.defaultAddress) {                        
                        return this.user.defaultAddress;
                    } else {
                       return {
                            id: null,
                            addressLine1: null,
                            addressLine2: null,
                            city: null,
                            state: null,
                            postalCode: null,
                        };
                    }
                },
                set(value) {
                    value.addressType = AddressType.BILLING;
                    this.setUserProperty({ name: "defaultAddress", value: value });
                }
            },
            shippingAddress: {
                get() {
                    if (this.user.shippingAddress) {
                        return this.user.shippingAddress;
                    } else {
                        return this.getEmptyAddress();
                    }
                },
                set(value) {
                    value.addressType = AddressType.SHIPPING;
                    this.setUserProperty({ name: "shippingAddress", value: value });
                },
            },            
        },
        methods: {
            ...mapMutations({
                setUserAddress: SET_USER_ADDRESS,
                setUserProperty: SET_USER_PROPERTY,
            }),
            updateValidity(isValid) {
                this.$eventBus.$emit(UserEvents.USER_ACCOUNT_ADDRESSES_UPDATED, isValid);
            },
            updatePrimaryAddressValidity(isValid) {
                this.isPrimaryAddressValid = isValid;
            },
            updateDefaultAddressValidity(isValid) {
                this.isDefaultAddressValid = isValid;
            },
            isNotValid(fieldName) {
                return fieldName != null && fieldName.length == 0;
            },
            updatePrimaryAddress(address) {
                this.primaryAddress = Object.assign({},address);
            },
            updateShippingAddress(address) {
                this.shippingAddress = Object.assign({}, address);
            },
            setPrimaryAddressToDefaultShip() {
                if (this.isDefaultShip) {
                    let id = null;
                    let shippingAddress = this.user.addresses.find(a => a.addressType == AddressType.SHIPPING);                   

                    if (shippingAddress) {
                        id = shippingAddress.id;
                    }
                    const setShipTo = {
                        id:id,
                        addressLine1: this.primaryAddress.addressLine1,
                        addressLine2: this.primaryAddress.addressLine2,
                        city: this.primaryAddress.city,
                        state: this.primaryAddress.state,
                        postalCode: this.primaryAddress.postalCode,
                        companyName: this.primaryAddress.companyName,
                        addressee: this.primaryAddress.addressee
                    };
                    this.shippingAddress = setShipTo;
                }
            },
        },
        mounted() {
            this.setUserAddress = this.$nucleus.debounce(this.setUserAddress, 100);
        },
    };
</script>
