<template>
  <div>
    <PageSubHeader title="Additional Information:" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-5">
        <dl>
          <dt>Employee ID:</dt>
          <dd>
            <span v-if="attributes.EmployeeId">
              {{ attributes.EmployeeId }}</span
            >
            <span v-else>No Employee ID assigned</span>
          </dd>
          <dt>Employee Status:</dt>
          <dd>
            <span v-if="attributes.EmployeeStatus">
              {{ attributes.EmployeeStatus }}</span
            >
            <span v-else>No Employee Status assigned</span>
          </dd>
          <dt>Business Unit:</dt>
          <dd>
            <span v-if="attributes.BusinessUnit">
              {{ attributes.BusinessUnit }}</span
            >
            <span v-else>No Business Unit assigned</span>
          </dd>
          <dt>Organization:</dt>
          <dd>
            <span v-if="attributes.Organization">
              {{ attributes.Organization }}</span
            >
            <span v-else>No Organization assigned</span>
          </dd>
          <dt>Last Modified Date:</dt>
          <dd>
            <span v-if="attributes.LastModifiedDate">
              {{ attributes.LastModifiedDate | moment("MM/DD/YYYY hh:mm a") }}
            </span>
            <span v-else>No Last Modified Date assigned</span>
          </dd>
          <dt>Source:</dt>
          <dd>
            <span v-if="attributes.UserSource">
              {{ attributes.UserSource }}
            </span>
            <span v-else> No User Source assigned</span>
          </dd>
        </dl>
      </div>
      <div class="col-5 offset-2">
          <dl>
              <dt>GEO ID:</dt>
              <dd>
                  <span v-if="attributes.GeoId">
                      {{ attributes.GeoId }}
                  </span>
                  <span v-else>No GEO ID assigned</span>
              </dd>
              <dt>Reports To:</dt>
              <dd>
                  <span v-if="attributes.ReportsToManager">
                      {{ attributes.ReportsToManager }}
                  </span>
                  <span v-else>No Manager assigned</span>
              </dd>
              <dt>Product:</dt>
              <dd>
                  <span v-if="attributes.Product"> {{ attributes.Product }}</span>
                  <span v-else>No Product assigned</span>
              </dd>
              <dt>Territory Level:</dt>
              <dd>
                  <span v-if="attributes.TerritoryLevel">
                      {{ attributes.TerritoryLevel }}
                  </span>
                  <span v-else>No Territory Level assigned</span>
              </dd>
              <dt>Territory Name:</dt>
              <dd>
                  <span v-if="attributes.TerritoryName">
                      {{ attributes.TerritoryName }}
                  </span><span v-else>No Territory Name assigned</span>
              </dd>             
          </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

export default {
  name: "UserPropertiesViewOnly",
  components: {
    PageSubHeader,
  },
  props: {
    attributes: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
dt {
  margin-top: 1.5rem !important;
}
</style>
