<template>
    <div class="row">
        <div class="col-6 form-group">
        <b-form-checkbox v-model="isAddOnItem">
            Add-On Item
        </b-form-checkbox>
        <div class="mt-2 n-fixed-quantity" v-show="isAddOnItem">
            <b>Fixed Order Quantity</b>
            <b-form-input v-model.number="addOnQuantity"
                          :class="{ 'is-invalid': $v.addOnQuantity.$invalid }"
                          class="n-width n-text-field-highlight mt-2" type="number"
                          @input="$v.addOnQuantity.$touch()" ></b-form-input>
        </div>
        <div v-if="$v.addOnQuantity.$invalid" class="n-error">
            <span v-if="!$v.addOnQuantity.required">Minimum order quantity should be 1.</span>
        </div>
      </div>
      </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import { PRODUCT_PROPERTY_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    const ATTRIBUTE_NAME = "ExtendedProductProperties";
    import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    import { requiredIf, integer } from "vuelidate/lib/validators";

    export default {
        name: "AddonInformation",
        data(){
            return {
                defaultAddOnQuantity:1
            }
        },
        computed: {
            ...mapGetters({
                getProductProperty: PRODUCT_PROPERTY_GETTER
            }),
            isAddOnItem: {
                get() {
                    let isAddon = this.getProductProperty("isAddOn", ATTRIBUTE_NAME);
                    this.$emit('changed', isAddon);
                    return isAddon;
                },
                set(value) {
                    this.setProductProperty({ name: "isAddOn", value: value, attribute: ATTRIBUTE_NAME });
                },
            },
            addOnQuantity: {
                get() {
                    return this.getProductProperty("addOnQuantity", ATTRIBUTE_NAME);
                },
                set(value) {
                  if(this.$nucleus.isEmpty(value)){
                    value = null;
                  }
                  this.setProductProperty({ name: "addOnQuantity", value: value, attribute: ATTRIBUTE_NAME });
                },
            },
        },
        mounted(){
            let addonQty = this.getProductProperty("addOnQuantity", ATTRIBUTE_NAME);

            if (this.isAddOnItem && this.$nucleus.isEmpty(addonQty)) {
                    this.addOnQuantity = this.defaultAddOnQuantity;
                  }
        },
        methods: {
            ...mapMutations({
                setProductProperty: SET_PRODUCT_PROPERTY,
            })
        },
        validations: {
            addOnQuantity: {
              required: requiredIf('isAddOnItem'),
              integer
            }
        }
    }
</script>

<style lang="scss" scoped>
    .n-fixed-quantity {
      font-size:1rem;
    }
    .n-error {
        font-size: 12px;
    }
    .form-group{
        margin-bottom: 0rem;
    }
    .n-width
    {
        width: 11rem !important;
    }
</style>