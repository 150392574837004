<template>
    <Modal :size="'modal-xl'">
        <ModalHeader>
            <ModalClose/>
        </ModalHeader>
        <ModalBody>
            <div v-if="isSignIn">
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <h1 class="font-weight-normal">
                            {{ $t("signIn.signInPageHeadline") }}!
                        </h1>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-sm-6">
                        <SignIn :isModal="true" v-on:closeModal="closeModal()" v-on:forgotPassword="forgotPassword"/>
                    </div>
                </div>
            </div>
            <div v-if="isForgotPassword">
                <ForgotPassword :isModal="true" v-on:signIn="signIn()"/>
            </div>
        </ModalBody>
    </Modal>
</template>

<script>
import Modal from '@/nucleus-modules/dd-nucleus-ui/components/modal/Modal';
import ModalHeader from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalHeader';
import ModalBody from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalBody';
import ModalMixin from '@/nucleus-modules/dd-nucleus-ui/mixins/ModalMixin';
import ModalClose from '@/nucleus-modules/dd-nucleus-ui/components/modal/ModalClose';
import ModalService from '@/nucleus-modules/dd-nucleus-ui/services/modal.service';

import SignIn from "@/components/auth/SignIn";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";

export const ViewTypes = Object.freeze({
  SIGN_IN: "sign-in",
  FORGOT_PASSWORD: "forgot-password",
});

export default {
    name: "SignInModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalClose,
        SignIn,
        ForgotPassword
    },
    data() {
        return {
            viewType: ""
        }
    },
    mixins: [ModalMixin],
    computed: {
        isSignIn() {
            return this.viewType === ViewTypes.SIGN_IN;
        },
        isForgotPassword() {
            return this.viewType === ViewTypes.FORGOT_PASSWORD;
        },
    },
    methods: {
        closeModal() {
            ModalService.close();
        },
        setView(viewType) {
            this.viewType = viewType || "";
        },
        forgotPassword(){
            this.setView(ViewTypes.FORGOT_PASSWORD);
        },
        signIn(){
            this.setView(ViewTypes.SIGN_IN);
        },
        createAccount(){
            this.setView(ViewTypes.CREATE_ACCOUNT);
        }
    },
    mounted () {
        this.setView(ViewTypes.SIGN_IN);
    },
}
</script>