<template>
  <div>
    <div class="row mt-2">
      <div class="col n-contact">
        If you are unable to find the information you need after visiting
        <a href="/documents/FAQ.pdf" class="font-weight-bold n-contact" target="_blank">Frequently Asked Questions</a>.
        Please use the contact us form below.
      </div>
    </div>
    <div class="form-group mt-4">
      <label class="n-form-labels">Choose a topic</label>

      <select class="form-control n-text-field-highlight" v-model.trim="contactUs.topicName">
        <option value="" selected>Select a topic</option>
        <option v-for="lot in listOfTopics" :key="lot" :value="lot">
          {{ lot }}
        </option>
      </select>
    </div>
    <div class="form-group mt-4">
      <label class="n-form-labels">Name</label>
      <input type="text" v-model="contactUs.name" class="form-control n-text-field-highlight"
        :class="{ 'is-invalid': $v.contactUs.name.$error }" @input="$v.contactUs.name.$touch()" />

      <div v-if="$v.contactUs.name.$invalid" class="n-error">
        <span v-if="!$v.contactUs.name.required && contactUs.name != null">Name is required.</span>
      </div>
    </div>
    <div class="form-group mt-4">
      <label class="n-form-labels">Email</label>
      <input type="email" v-model="contactUs.email" class="form-control n-text-field-highlight"
        :class="{ 'is-invalid': $v.contactUs.email.$error }" @input="$v.contactUs.email.$touch()" />

      <div v-if="$v.contactUs.email.$invalid" class="n-error">
        <span v-if="!$v.contactUs.email.required && contactUs.email != null">Email is required.</span>
      </div>
    </div>
    <div class="form-group mt-4">
      <label class="n-form-labels">Phone Number</label><span> (optional)</span>
      <input type="text" v-model="contactUs.phoneNumber" class="form-control n-text-field-highlight"
        @input="formatPhone(contactUs.phoneNumber)" />

      <div v-if="$v.contactUs.phoneNumber.$invalid" class="n-error">
        <span v-if="!$v.contactUs.phoneNumber.maxLength">
          The phone number must have
          {{ $v.contactUs.phoneNumber.$params.maxLength.max }} characters.
        </span>
      </div>
    </div>
    <div class="form-group mt-4" v-if="isVisible">
      <label class="n-form-labels">Order Number</label><span> (optional)</span>
      <input type="text" v-model="contactUs.orderNumber" class="form-control n-text-field-highlight" />
    </div>
    <div class="form-group mt-4">
      <label>Message</label>
      <textarea rows="10" v-model="contactUs.message" class="form-control n-text-field-highlight box-size"
        :class="{ 'is-invalid': $v.contactUs.message.$error }" @input="$v.contactUs.message.$touch()"></textarea>
    </div>

    <div v-if="$v.contactUs.message.$invalid" class="n-error">
      <span v-if="!$v.contactUs.message.required && contactUs.message != null">Message is required.</span>
      <span v-if="!$v.contactUs.message.maxLength">
        The Message must have maximum of
        {{ $v.contactUs.message.$params.maxLength.max }} characters.
      </span>
    </div>

    <div class="form-group mt-4">
      <div class="col-12 mb-3">
        <button class="btn btn-outline-primary mr-2" @click="$router.go(-1)">
          Cancel
        </button>
        <button class="btn btn-primary" :disabled="$v.contactUs.$invalid" @click.prevent="submit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import { ListOfTopics } from "@/constants.js";
import { formatting } from "@/nucleus-modules/dd-nucleus-ui/mixins/formattingMixin.js";
import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { GET_USER_CONTACT } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { USER_CONTACT_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

export default {
  name: "ContactUsForm",
  mixins: [formatting],

  props: {
    contactUs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      listOfTopics: ListOfTopics,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: USER_INFO_GETTER,
      userContact: USER_CONTACT_GETTER,
    }),
    isVisible() {
      return this.contactUs.topicName == 'Order Status/Tracking';
    },
  },
  methods: {
    ...mapActions({
      getUserContact: GET_USER_CONTACT,
    }),

    async submit() {
      this.$emit('submit');
    },

    async setUserDetails(contactId) {
      await this.getUserContact(contactId);

      if (this.userContact && this.userContact.phoneNumber) {
        this.formatPhone(
          this.userContact.phoneNumber
        );
      }
    },

    formatPhone(phone) {
      this.contactUs.phoneNumber = this.formatPhoneNumber(phone);
    },
  },

  watch: {
    userInfo: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.userInfo.firstName && this.userInfo.lastName) {
          this.contactUs.name = this.userInfo.firstName + ' ' + this.userInfo.lastName;
          this.contactUs.email = this.userInfo.userName;
          this.setUserDetails(this.userInfo.contactId);
        }
      },
    },
  },
  validations: {
    contactUs: {
      topicName: {
        required,
      },
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phoneNumber: {
        minLength: minLength(12),
        maxLength: maxLength(12),
      },
      message: {
        required,
        maxLength: maxLength(2000),
      },
    },
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 576px) {
  .form-control {
    width: 30%;
  }

  .box-size {
    width: 70%;
  }

  .col-12 {
    margin-left: 48%;
  }
}

@media (max-width: 576px) {
  .col-12 {
    margin-left: 39%;
  }
}
</style>
