<template>
  <div class="n-crm-address-book-background">
    <div class="row">
      <div v-if="isCheckout" class="col-12 col-md-4 d-inline-flex title">
        <h3 class="pt-2 pl-2 title">{{ $t("crmAddresses.titleForCheckout") }}</h3>
      </div>
      <div v-else class="col-12 col-md-4 d-inline-flex ">
        <h3 class="n-page-header">{{ $t("crmAddresses.title") }}</h3>
        <span
          v-b-tooltip.hover
          class=" primary-color n-learn-more ml-2"
          :title="$t('crmAddresses.messages.info')"
        >
          <font-awesome-icon icon="question-circle" />
        </span>
      </div>

      <div
        class="col-12 col-md-8 text-md-right text-sm-center"
        v-if="!showNoAddressAssignedMessage"
      >
        <TagFilter
          class="mr-0 pt-0 n-line-height-40"
          filterId="tags"
          :tagName="$t('crmAddresses.buttons.viewAllAddresses')"
          @changed="resetAll"
        >
        </TagFilter>
        <span
          class="d-none d-sm-inline-block d-md-inline-block d-lg-inline-block position-absolute n-line-height-40"
          >|</span
        >
        <TagFilter
          class="mr-0 pt-0 n-line-height-40"
          filterId="tags"
          tagId="Favorite"
          :tagName="$t('crmAddresses.buttons.viewMyFavoriteAddresses')"
          @changed="filtersChanged"
        >
        </TagFilter>
      </div>
    </div>

    <div v-if="showNoAddressAssignedMessage && !isSearchResultsLoading">
      <div class="row pt-4">
        <div class="col text-center">
          <p class="n-no-address-found">
            {{ $t("crmAddresses.messages.emptyAddresses") }}
          </p>
        </div>
      </div>
    </div>

    <div v-else>
      <CRMAddressBookFilters
        @changed="filtersChanged"
        @sortOptionChanged="sortOptionChanged"
        :isCheckout="isCheckout"
        :searchOptions="searchOptions"
      ></CRMAddressBookFilters>

      <CRMAddressBookSearchResults
        v-if="!isSearchResultsLoading"
        :resultsTitle="resultsTitle"
        @perPageChanged="perPageChanged"
        @pageNumberChanged="pageNumberChanged"
        :isCheckout="isCheckout"
        :searchOptions="searchOptions"
        v-on:crm-address-selected="crmAddressSelected"
        :class="!isCheckout ? 'n-crm-address-pagelist-container' : ''"
      ></CRMAddressBookSearchResults>

      <div
        class="row pt-4"
        v-if="showEmptyFavoritesMessage && !isSearchResultsLoading"
      >
        <div class="col text-center n-no-address-found">
          <div>
            No Addresses have been added to your Favorites.
          </div>
          <font-awesome-icon
            class="m-3 n-favorite-address-icon"
            :icon="['far', 'heart']"
          />
          <div class="addFavoriteText ">
            To add an address to your "Favorites" click the
          </div>
          <div class="addFavoriteText">
            favorites icon located by each address.
          </div>
          <div>
            <TagFilter
              filterId="tags"
              :tagName="$t('crmAddresses.buttons.viewAllAddresses')"
              @changed="resetAll"
            >
            </TagFilter>
          </div>
        </div>
      </div>

      <div
        class="row pt-4"
        v-if="showEmptyRecordsMessage && !isSearchResultsLoading"
      >
        <div class="col text-center">
          <div class="n-no-address-found">
            No Addresses found. Please change the filter criteria
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CRMAddressBookFilters from "@/components/crm-addresses/CRMAddressBookFilters.vue";
import CRMAddressBookSearchResults from "@/components/crm-addresses/CRMAddressBookSearchResults.vue";
import { TagNames } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import { mapActions, mapGetters } from "vuex";
import {
  SEARCH_CRM_ADDRESSES,
  CRM_ADDRESS_ADD_FILTER,
  CRM_ADDRESS_UPDATE_FILTER,
  CRM_ADDRESS_RESET_ALL_FILTERS,
} from "@/store/storefront/actions.type.js";

import TagFilter from "@/nucleus-modules/dd-nucleus-ui/components/address-book/TagFilter.vue";
import {
  CRM_ADDRESS_FILTERS_GETTER,
  CRM_ADDRESS_SEARCH_RESULTS_GETTER,
} from "@/store/storefront/getters.type.js";
export default {
  name: "CRMAddressBook",
  components: {
    CRMAddressBookFilters,
    CRMAddressBookSearchResults,
    TagFilter,
  },
  props: {
    isCheckout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFavorites: false,
      showAllAddresses: false,
      resultsTitle: this.isCheckout ? "Select a Shipping Address" : "",
      searchOptions: {
        filters: [],
        IsCompanyWideSearch: true,
        page: 1,
        sort: "sort_by_businessname",
        perPage: 25,
      },
      isSearchResultsLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      filters: CRM_ADDRESS_FILTERS_GETTER,
      crmAddresses: [CRM_ADDRESS_SEARCH_RESULTS_GETTER],
    }),

    totalNumberOfAddresses() {
      if (this.crmAddresses && this.crmAddresses.totalCount > 0) {
        return this.crmAddresses.totalCount;
      }
      return 0;
    },
    showEmptyRecordsMessage() {
      return !this.showFavorites && this.totalNumberOfAddresses < 1;
    },
    showEmptyFavoritesMessage() {
      return this.showFavorites && this.totalNumberOfAddresses < 1;
    },
    showNoAddressAssignedMessage() {
      return (
        !this.showFavorites &&
        !this.showAllAddresses &&
        this.totalNumberOfAddresses < 1 &&
        this.filters.length < 1
      );
    },
  },
  methods: {
    ...mapActions({
      crmAddressSearchResults: SEARCH_CRM_ADDRESSES,
      addFilter: CRM_ADDRESS_ADD_FILTER,
      updateFilter: CRM_ADDRESS_UPDATE_FILTER,
      resetAllFilters: CRM_ADDRESS_RESET_ALL_FILTERS,
    }),
    crmAddressSelected(eventData) {
      this.$emit("crm-address-selected", eventData);
    },
    filtersChanged(eventData) {
      this.isSearchResultsLoading = true;
      this.updateResultsTitle(eventData);
      this.resetPageNumber();
      this.refreshFilters(eventData);
    },

    updateResultsTitle(eventData) {
      if (eventData.values[0] == TagNames.FAVORITE) {
        this.resultsTitle = "My Favorite Addresses";
        this.showFavorites = true;
        this.showAllAddresses = false;
        this.resetAllFilters();
      }
    },

    perPageChanged(eventData) {
      if (this.searchOptions.perPage != eventData.value) {
        this.resetPageNumber();
        this.searchOptions.perPage = eventData.value;
        this.reloadSearch();
      }
    },

    sortOptionChanged(eventData) {
      this.searchOptions.sort = eventData.value;
      this.resetPageNumber();
      this.reloadSearch();
    },

    pageNumberChanged(eventData) {
      if (this.searchOptions.page != eventData.value) {
        this.searchOptions.page = eventData.value;
        this.reloadSearch();
      }
    },

    resetAll() {
      this.isSearchResultsLoading = true;
      this.resultsTitle = "";
      this.showFavorites = false;
      this.showAllAddresses = true;
      this.resetAllFilters();
      this.resetPageNumber();
      this.reloadSearch();
    },

    async refreshFilters(filter) {
      if (this.filterExists(filter)) {
        await this.updateFilter(filter);
      } else {
        await this.addFilter(filter);
      }
      this.reloadSearch();
    },

    reloadSearch() {
      this.searchOptions.filters = this.filters;
      this.searchOptions.paging = {
        includeTotalCount: true,
        pageNumber: this.searchOptions.page
          ? Number(this.searchOptions.page) - 1
          : 0,
        pageSize: this.searchOptions.perPage,
      };

      this.searchAddresses();
    },
    async searchAddresses() {
      await this.crmAddressSearchResults(this.searchOptions);
      this.isSearchResultsLoading = false;
    },

    resetPageNumber() {
      this.searchOptions.page = 1;
    },
    filterExists(filter) {
      return (
        this.filters.findIndex((f) => f.filterId === filter.filterId) !== -1
      );
    },
  },
  mounted() {
    this.reloadSearch();
  },
  beforeDestroy() {
    this.resetAllFilters();
  },
};
</script>
<style scoped lang="scss">
.title {
  text-transform: capitalize;
  letter-spacing: normal;
}
.n-no-address-found {
  color: $neutral-darker;
  font-size: 1.5rem;
  .addFavoriteText {
    font-size: 1rem;
    font-weight: 700;
  }
}
.n-learn-more {
  font-size: 1rem;
  padding-top: 0.9rem;
  margin-bottom: 0px;
}
.n-favorite-address-icon {
  height: 2em;
  width: 2em;
  color: $favorites-color;
}

.n-line-height-40 {
  line-height: 40px;
}

.n-crm-address-book-background {
  background: $neutral-lightest;
}

.n-page-header {
  padding-left: 0.5rem;
}


@media (max-width: 767px) {
  .n-no-address-found {
    font-size: 1rem;
  }
  .n-button-spacer {
    margin-left: 2px;
  }
}
</style>
