<template>
  <div>
    <form name="address-form">
      <slot name="top"></slot>
      <div class="form-group">
        <b-form-group>
          <b-form-radio-group class="n-address-type" v-model="isResidential" :options="addressOptions">
          </b-form-radio-group>
        </b-form-group>
      </div>
      <div class="form-group">
        <label class="n-form-labels" :for="'businessName-' + randomId">
          {{ $t("shared.address.businessName") }}
          <span class="font-weight-normal">({{ $t("shared.optionalFields") }})</span>
        </label>
        <input type="text" v-model="companyName" :id="'businessName-' + randomId"
          class="form-control n-text-field-highlight" placeholder="Business Name" maxlength="30" />
      </div>

      <div class="form-group" :class="{ 'User-group--error': $v.addressee.$error }">
        <label class="n-form-labels" for="shipTo">
          {{ $t("shared.address.shipTo") }}
        </label>
        <input type="text" id="shipTo" v-model="$v.addressee.$model" class="form-control n-text-field-highlight"
          placeholder="First and Last Name" maxlength="30" />
        <div v-if="$v.addressee.$dirty && $v.addressee.$invalid && isNotValid(addressee)">
          <div class="n-error" v-if="!$v.addressee.required">
            Ship To is required
          </div>
        </div>
      </div>

      <div class="form-group" :class="{ 'form-group--error': $v.addressLine1.$error }">
        <label class="n-form-labels" :for="'addressLine1-' + randomId">
          {{ $t("shared.address.streetAddress") }}
        </label>
        <input type="text" v-model="$v.addressLine1.$model" :id="'addressLine1-' + randomId"
          class="form-control n-text-field-highlight" maxlength="30" />
        <div v-if="$v.addressLine1.$dirty">
          <div v-if="$v.addressLine1.$invalid && isNotValid(addressLine1)">
            <div class="n-error" v-if="!$v.addressLine1.required">
              Street address is required
            </div>
          </div>
          <div v-if="
            $v.addressLine1.$invalid && addressLine1 && addressLine1.length > 0
          ">
            <div class="n-error" v-if="!$v.addressLine1.address">
              The Street address format is invalid.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="n-form-labels" :for="'streetAddress2-' + randomId">
          {{ $t("shared.address.streetAddress2") }}
          <span class="font-weight-normal">({{ $t("shared.optionalFields") }})</span>
        </label>
        <input type="text" v-model="address.addressLine2" :id="'streetAddress2-' + randomId"
          class="form-control n-text-field-highlight" maxlength="30" />
      </div>
      <div class="form-group" :class="{ 'form-group--error': $v.city.$error }">
        <label class="n-form-labels" :for="'city-' + randomId">
          {{ $t("shared.address.city") }}
        </label>
        <input type="text" :id="'city-' + randomId" class="form-control n-text-field-highlight" maxlength="23"
          v-model="$v.city.$model" />
      </div>
      <div v-if="$v.city.$dirty && $v.city.$invalid && isNotValid(city)" class="n-error">
        <span v-if="!$v.city.required">City is required.</span>
        <span v-if="!$v.city.alpha_spaces">
          The City field may only contain alphabetic characters as well as
          spaces
        </span>
      </div>

      <div class="form-group">
        <label class="n-form-labels">State</label>
        <b-form-select class="n-text-field-highlight" name="state" :options="statesList" v-model="$v.state.$model"
          value-field="code" text-field="name">
          <template v-slot:first>
            <b-form-select-option :value="null" selected>Select State</b-form-select-option>
          </template>
        </b-form-select>

        <div v-if="$v.state.$dirty && ($v.state.$invalid && state !== '' && isNotValid(state)) || state === null">
          <div class="n-error" v-if="!$v.state.required">State is required</div>
        </div>
      </div>
      <div class="form-group" :class="{ 'form-group--error': $v.postalCode.$error }">
        <label class="n-form-labels" :for="'postalCode-' + randomId">
          {{ $t("shared.address.postalCode") }}
        </label>
        <input type="text" v-model="$v.postalCode.$model" class="form-control n-text-field-highlight"
          :id="'postalCode-' + randomId" />
        <div v-if="$v.postalCode.$dirty">
          <div v-if="$v.postalCode.$invalid && isNotValid(postalCode)">
            <div class="n-error" v-if="!$v.postalCode.required">
              Postal Code is required
            </div>
          </div>
          <div v-if="$v.postalCode.$invalid && postalCode && postalCode.length > 0">
            <div class="n-error" v-if="!$v.postalCode.minLength">
              Postal Code must have at least
              {{ $v.postalCode.$params.minLength.min }} digits.
            </div>
          </div>
          <div v-if="$v.postalCode.$invalid && postalCode && postalCode.length > 0">
            <div class="n-error" v-if="!$v.postalCode.maxLength">
              Postal Code can have only
              {{ $v.postalCode.$params.maxLength.max }} digits.
            </div>
          </div>
          <div v-if="$v.postalCode.$invalid && postalCode && postalCode.length > 0">
            <div class="n-error" v-if="!$v.postalCode.numeric">
              Postal Code can have only numerical digits
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="n-form-labels" :for="'phoneNumber-' + randomId">
          {{ $t("shared.address.phoneNumber") }}
          <span class="font-weight-normal">({{ $t("shared.optionalFields") }})</span>
        </label>
        <input type="text" v-model="address.phoneNumber" @input="formatPhoneNumber(address.phoneNumber)"
          class="form-control n-text-field-highlight" :id="'phoneNumber-' + randomId" />
      </div>
      <span v-if="isShipAddressValid"></span>

      <slot name="bottom"></slot>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric
} from "vuelidate/lib/validators";

const address = helpers.regex("alpha", /^[#.0-9a-zA-Z\s,-]+$/);
const alpha_spaces = helpers.regex("alpha", /^[a-zA-Z ]*$/);

export default {
  name: "AddressComponent",
  props: {
    address: {
      type: Object,
    },
    statesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addressToEdit: {},
      addressOptions: [
        { text: "Residential Address", value: true },
        { text: "Commercial Address", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      siteStartup: SITE_SETUP_GETTER,
    }),
    randomId() {
      return Math.floor(Math.random() * 100);
    },
    isResidential: {
      get() {
        if (this.addressToEdit.isResidential !== undefined)
          return this.addressToEdit.isResidential;
        else return true;
      },
      set(value) {
        this.addressToEdit.isResidential = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    companyName: {
      get() {
        return this.addressToEdit.companyName;
      },
      set(value) {
        this.addressToEdit.companyName = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    addressee: {
      get() {
        return this.addressToEdit.addressee;
      },
      set(value) {
        this.addressToEdit.addressee = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    addressLine1: {
      get() {
        return this.addressToEdit.addressLine1;
      },
      set(value) {
        this.addressToEdit.addressLine1 = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    addressLine2: {
      get() {
        return this.addressToEdit.addressLine2;
      },
      set(value) {
        this.addressToEdit.addressLine2 = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    city: {
      get() {
        return this.addressToEdit.city;
      },
      set(value) {
        this.addressToEdit.city = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    state: {
      get() {
        return this.addressToEdit.state;
      },
      set(value) {
        this.addressToEdit.state = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    postalCode: {
      get() {
        return this.addressToEdit.postalCode;
      },
      set(value) {
        this.addressToEdit.postalCode = value;
        this.$emit("update:address", this.addressToEdit);
      },
    },
    isShipAddressValid() {
      let result = !(
        this.$v.addressee.$invalid ||
        this.$v.addressLine1.$invalid ||
        this.$v.city.$invalid ||
        this.$v.state.$invalid ||
        this.$v.postalCode.$invalid
      );
      this.updateValidity(result);
      return result;
    },
  },
  methods: {
    isNotValid(fieldName) {
      return fieldName != null && fieldName.length == 0;
    },
    updateAddressToEdit() {
      this.addressToEdit = this.address;
    },
    updateValidity(isValid) {
      this.$emit("updateValidity", isValid);
      if (isValid) {
        this.$v.$reset();
      }

    },
    formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, "");
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) this.address.phoneNumber = phoneNumber;
      else if (phoneNumberLength < 7) {
        this.address.phoneNumber = `(${phoneNumber.slice(
          0,
          3
        )}) ${phoneNumber.slice(3)}`;
      } else {
        this.address.phoneNumber = `(${phoneNumber.slice(
          0,
          3
        )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
      }
    },
  },
  mounted() {
    this.updateAddressToEdit();
  },
  watch: {
    address() {
      this.updateAddressToEdit();
    },
  },

  validations: {
    addressee: {
      required,
    },
    addressLine1: {
      required,
      address,
    },
    addressLine2: {
      address,
    },
    city: {
      required,
      alpha_spaces,
    },
    state: {
      required,
    },
    postalCode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(10),
      numeric
    },
    isResidential: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
  .n-address-type {
    padding-left: 0px;
    display: flex;
    vertical-align: baseline;    
  }    
</style>