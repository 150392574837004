import qs from "qs";
import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import {
    AUTHENTICATE_USER,
    CLEAR_ERRORS,
    CREATE_USER,
    INSERT_ERROR,
    LOGOUT,
    RESET_PASSWORD,
    SEND_PASSWORD_RESET,
    UPDATE_PASSWORD,
    VALIDATE_PASSWORD,
    VALIDATE_RESET_PASSWORD_LINK,
} from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { SET_USER_INFO } from "@/nucleus-modules/dd-nucleus-ui/store/mutations.type.js";
import { ACCESS_TOKEN_KEY, SKIP_ADMIN_REDIRECT_KEY } from "@/nucleus-modules/dd-nucleus-ui/constants.js";

const initialState = {
    userInfo: {
        id: null,
        contactId: null,
        userName: null,
        firstName: null,
        lastName: null
    }
};

export const state = { ...initialState };

export const actions = {
    async [AUTHENTICATE_USER]({ dispatch }, payload) {
        const data = qs.stringify({
            grant_type: "password",
            username: payload.username,
            password: payload.password
        });

        const options = {
            headers: { "content-type": "application/x-www-form-urlencoded;charset=utf-8" }
        };

        const response = await ApiService.post("ui/account/login", data, options);

        if (typeof (response.succeeded) != "undefined" && !response.succeeded) {
            return { succeeded: false, messageCode: response.messages };
        }
        else {
            dispatch(CLEAR_ERRORS);

            if (response.access_token) {
                localStorage.setItem(ACCESS_TOKEN_KEY, response.access_token);

                ApiService.setAuthHeader(response.access_token);

                return { succeeded: true };
            }
            else {
                dispatch(INSERT_ERROR, "The sign in token is not valid.");
            }
        }
    },
    async [CREATE_USER]({ dispatch }, payload) {
        const response = await ApiService.post("ui/account/register", payload);

        if (response.succeeded) {
            // Build request to log the new account in 
            const authData = ({
                username: payload.username,
                password: payload.password
            });
            // Log the newly created account in
            dispatch(AUTHENTICATE_USER, authData);

            return response;
        } else {
            return response;
        }
    },
    async [LOGOUT]({ commit, dispatch }) {
        dispatch(CLEAR_ERRORS);

        commit(SET_USER_INFO, null);

        localStorage.removeItem(ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(SKIP_ADMIN_REDIRECT_KEY);

        ApiService.removeAuthHeader();
    },
    async [RESET_PASSWORD](_, payload) {
        const response = await ApiService.post("ui/account/reset-password", payload);
        return response;
    },
    async [SEND_PASSWORD_RESET](_, payload) {
        const response = await ApiService.post("ui/account/send-reset-password", payload);
        return response;
    },
    async [UPDATE_PASSWORD](_, payload) {
        const response = await ApiService.post("ui/account/update-password", payload);
        return response;
    },
    async [VALIDATE_PASSWORD](_, payload) {
        const response = await ApiService.post("ui/account/validate-password", payload, { showLoader: false });
        return response;
    },
    async [VALIDATE_RESET_PASSWORD_LINK](_, payload) {
        const response = await ApiService.post("ui/account/validate-reset-password", payload, { showLoader: false });
        return response;
    }
};

export const getters = {
    [USER_INFO_GETTER]: state => {        
        return state.userInfo;
    }
};

export const mutations = {
    [SET_USER_INFO]: (state, value) => {
        state.userInfo = value;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
