<template>
  <div class="modal">
    <div class="modal-dialog" :class="size">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/****** 
 * Available options for the size prop to change the size of the modal
 * modal-xl
 * modal-lg 
 * modal-md (default and no need to pass prop)
 * modal-sm
*/
export default {
  props: {
    size: {
      type: String
    },
  },
}
</script>