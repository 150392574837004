<template>
  <div v-if="userInfo" >
    <header class="main-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-4 mt-3 d-block d-sm-none mb-1">
            <AdminMenu menu-id="mobile-main" :items="items" ></AdminMenu>
         </div>
          <div class="col-4 col-md-6">
            <h1 class="logo">
              <router-link to="/admin">
                <img :src="logoUrl" v-bind:alt="logoText" class="img-fluid"/>
              </router-link>
            </h1>
          </div>           
          <div class="col-4 col-md-6 text-right">
          
            <nav class="main-nav">
              <ul class="main-nav-list">
                <li>
                  <User :userInfo="userInfo"></User>
                </li>
              </ul>
            </nav>
          </div>
        </div>
       
      </div>
    </header>
  </div>
</template>

<script>
import User from "@/components/admin/User.vue";
import AdminMenu from "@/components/admin/AdminMenu.vue";

export default {
  name: "Header",
  components: {
    User,
    AdminMenu
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },   
    items: Array      
  },
  data() {
    return {
      logoText: "Storefront Demo",
      logoUrl: "/images/logo.png",
    };
  },
};
</script>

<style lang="scss" scoped>
.main-nav ul {
  margin: 1em 0 0.5em;
  text-align: center;
}

.main-nav li {
  display: inline;
}

.main-nav a {
  display: inline-block;
  padding: 0.5em 1.5em;
}
</style>
