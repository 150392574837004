<template>
  <div v-if="showFilter">
    <div class="row input-group filter" v-if="title && title.length">
      <div class="n-plus-minus">
        <div class="n-filter-header">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="checkbox-filter mt-4 mb-2">
      <b-form-checkbox-group
        v-model="currentValues"
        :options="checkboxOptions"
        stacked
      ></b-form-checkbox-group>
    </div>
    <div class="d-none d-lg-block" v-if="showClearAll">
      <span class="clear-all" @click="clearAll"
        >{{ $t("filters.clearAllButtonText") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBoxFilter",
  props: {
    filterId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
    filterValues: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentValues: {
      get() {
        if (this.filterValues.length > 0) {
          return this.filterValues;
        } else return [];
      },
      set(val) {
        if (!this.arrayEquals(val, this.filterValues)) {
          this.$emit("changed", {
            filterId: this.filterId,
            values: val,
          });
        }
      },
    },
    showFilter() {
      return this.list && this.list.length;
    },
    checkboxOptions() {
      if (this.list && this.list.length) {
        return this.list.map((x) => {
          return {
            text: x.name,
            value: x.id,
          };
        });
      }
      return [];
    },
    showClearAll() {
      return this.list && this.list.length && this.list.length > 1;
    },
  },
  methods: {
    clearAll() {
      if (this.currentValues && this.currentValues.length) {
        this.currentValues = [];
      }
    },
    arrayEquals(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox-filter {
  font-size: 0.85rem !important;
}
</style>
