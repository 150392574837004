<template>
  <div>
    <Overlay
      v-if="overlay.visible"
      :allowHeader="overlay.allowHeader"
    ></Overlay>
    <Header v-if="showHeader" />
    <div id="page-template" class="container">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { LOGOUT } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { StorefrontEvents } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import JwtService from "@/nucleus-modules/dd-nucleus-ui/services/jwt.service.js";

import {
  OVERLAY_GETTER,
  USER_INFO_GETTER,
} from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

import {
  UiEvents,
  SKIP_ADMIN_REDIRECT_KEY,
  ACCESS_TOKEN_KEY,
} from "@/nucleus-modules/dd-nucleus-ui/constants.js";

import {
  ADD_FAVORITE,
  ASSIGN_USER_FROM_JWT,
  GET_CART,
  GET_CATEGORY_ROOT,
} from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

import { ASSIGN_SSO_TOKEN } from "@/store/storefront/actions.type.js";

import { CART_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import Overlay from "@/nucleus-modules/dd-nucleus-ui/components/Overlay.vue";

import Header from "@/components/Header.vue";
import Footer from "@/components/footer/Footer.vue";

import { oktaStorageKeys } from "@/companyAppConfig.js";

import {
  LOGIN_PATH,
  SSO_LOGOUT_PATH,
  DIRECT_LOGIN_PATH,
  SSO_ERROR_PATH,
  ADMIN_ENTRY_PATH,
} from "@/router/index.js";

export default {
  name: "PageTemplate",
  components: {
    Header,
    Footer,
    Overlay,
  },
  props: {
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      cart: CART_GETTER,
      overlay: OVERLAY_GETTER,
      userInfo: USER_INFO_GETTER,
    }),
  },
  methods: {
    ...mapActions({
      assignUserFromJwt: ASSIGN_USER_FROM_JWT,
      addFavorite: ADD_FAVORITE,
      getCategoryRoot: GET_CATEGORY_ROOT,
      getCart: GET_CART,
      logout: LOGOUT,
      assignSSOToken: ASSIGN_SSO_TOKEN,
    }),
    async retrieveCart() {
      await this.getCart();
    },
    async signout() {
      await this.logout();
      const isOktaAuthenticated = await this.$auth.isAuthenticated();
      if (isOktaAuthenticated) {
        for (let i = 0; i < oktaStorageKeys.length; i++) {
          this.$nucleus.removeStorageItem(oktaStorageKeys[i]);
          this.$nucleus.removeStorageItem(oktaStorageKeys[i], true);
        }
        this.$router.push({ path: SSO_LOGOUT_PATH });
        return;
      } else {
        this.$router.push({ path: LOGIN_PATH });
      }
    },
    initListeners() {
      let vm = this;
      vm.removeListeners();
      this.$eventBus.$on(StorefrontEvents.SIGN_OUT, () => {
        vm.signout();
      });
    },
    removeListeners() {
      this.$eventBus.$off(StorefrontEvents.SIGN_OUT);
    },
    isLoginRequired() {
      return (
        this.$route.path != DIRECT_LOGIN_PATH &&
        this.$route.path != "/forgot-password"
      );
    },
    async checkAuthentication() {
      let oktaAuthenticated = null;

      const directLoginToken = await JwtService.getToken();

      try {
        oktaAuthenticated = await this.$auth.isAuthenticated();

        if (oktaAuthenticated) {
          const oktaAccessToken = this.$auth.getAccessToken();
          if (oktaAccessToken) {
            await this.assignSSOToken({ ssoToken: oktaAccessToken });
          }
        } else {
          //Workaround, when the application link is called from OKTA idp, we need to take them to login page.
          //Need to check whether this can be moved to App.vue.
          let issuer = this.$route.query.iss;
          if (issuer) {
            issuer = issuer + "/oauth2/default";
            if (this.$auth.options.issuer == issuer) {
              await this.$auth.signInWithRedirect();
            }
          }
        }
      } catch {
        // ignore OKTA exceptions from isAuthenticated/assignSSOToken calls
      }

      if (oktaAuthenticated || directLoginToken) {
        await this.assignUserFromJwt();

        if (!this.$nucleus.isEmpty(this.userInfo) && !this.userInfo.validUser) {
          //Removing the tokens before redirecting to error page.
          this.$nucleus.removeStorageItem(ACCESS_TOKEN_KEY);
          this.$router.push(SSO_ERROR_PATH);
          return;
        } else if (
          this.$nucleus.isEmpty(this.userInfo) ||
          this.$nucleus.isEmpty(this.userInfo.id)
        ) {
          this.$nucleus.removeStorageItem(ACCESS_TOKEN_KEY);
          this.$router.push(LOGIN_PATH);
          return;
        }

        this.$eventBus.$emit(UiEvents.SIGN_IN_SUCCEEDED);

        const skipAdminRedirect = !!this.$nucleus.getStorageItem(
          SKIP_ADMIN_REDIRECT_KEY,
          true
        );

        if (
          this.userInfo &&
          this.userInfo.hasAdminAccess &&
          !skipAdminRedirect
        ) {
          this.$nucleus.removeStorageItem(SKIP_ADMIN_REDIRECT_KEY);
          window.location.href= ADMIN_ENTRY_PATH;
        }
        await this.retrieveCart();
      } else {
        if (this.isLoginRequired()) {
          this.$router.push(LOGIN_PATH);
          return;
        }
      }
    },
  },
  beforeDestroy(){
    this.removeListeners();
  },
  async mounted() {
    this.initListeners();
    await this.checkAuthentication();
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 993px) {
  #page-template {
    min-height: 700px;
  }
}
@media (max-width: 1024px) {
  #page-template {
    max-width: 100%;
  }
}

</style>
