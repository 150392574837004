<template>
  <div>
    <div v-if="isLoading"></div>
    <div v-else class="container-fluid">
      <div class="row">
        <nav
          id="sidebar-menu"
          class="col-md-3 col-lg-2 d-md-block sidebar collapse"
        >
          <Sidebar :menu="menu"></Sidebar>        
        </nav>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
          <Header :userInfo="userInfo" :items="menu"></Header>
          <hr />
          <div class="container-fluid pt-3">
            <slot></slot>
          </div>
        </main>        
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import JwtService from "@/nucleus-modules/dd-nucleus-ui/services/jwt.service.js";
import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { UiEvents } from "@/nucleus-modules/dd-nucleus-ui/constants.js";
import { GET_ADMIN_MENU } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import { ADMIN_MENU_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

import { ASSIGN_USER_FROM_JWT } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";

import Sidebar from "@/components/admin/Sidebar.vue";
import Header from "@/components/admin/Header.vue"; 
import { LOGOUT } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { StorefrontEvents } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";
import { oktaStorageKeys } from "@/companyAppConfig.js";
import {
  LOGIN_PATH,
   
} from "@/router/index.js";

export default {
  name: "AdminTemplate",
  components: {
    Header,
    Sidebar    
  },
  data() {
    return {
      isLoading: true,
    };
  }, 
  computed: {
    ...mapGetters({
      menu: ADMIN_MENU_GETTER,     
      siteSetup: SITE_SETUP_GETTER,
      userInfo: USER_INFO_GETTER,
    }),
  },
  methods: {
    ...mapActions({
      assignUserFromJwt: ASSIGN_USER_FROM_JWT,
      getMenu: GET_ADMIN_MENU,
      logout: LOGOUT,
    }),

     async signout() {
      await this.logout();      
        for (let i = 0; i < oktaStorageKeys.length; i++) {
          this.$nucleus.removeStorageItem(oktaStorageKeys[i]);
          this.$nucleus.removeStorageItem(oktaStorageKeys[i], true);
        }
        this.$router.push({ path: LOGIN_PATH });     
        return;
     
    },
    initListeners() {
      let vm = this;
     
      vm.removeListeners();
      this.$eventBus.$on(StorefrontEvents.SIGN_OUT, () => {
        vm.signout();
      });
    },
    removeListeners() {
      this.$eventBus.$off(StorefrontEvents.SIGN_OUT);
    },
    
  },
  async mounted() {
    this.initListeners();   
    const token = await JwtService.getToken();

    if (token) {
      await this.assignUserFromJwt();

      this.$eventBus.$emit(UiEvents.SIGN_IN_SUCCEEDED);

      if (!this.userInfo) {
        this.$router.push(LOGIN_PATH);
      }

      if (this.userInfo && !this.userInfo.hasAdminAccess) {
        this.$router.push("/");
      }
    }

    await this.getMenu();  
    this.isLoading = false;
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  background: $admin-primary-lightest-color;
  padding-left: 0px;
  padding-right: 0px;
}

hr {
  margin: 0;
}

@media (min-width: 1024px) {
  #sidebar-menu {
    min-height: 100vh;
  }
}

@media (max-width: 767px) {
  #sidebar-menu {
    position: absolute;
    top: 70px;
    z-index: 99999;
  }
}
</style>
