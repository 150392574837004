<template>
    <PageTemplate>
        <div v-if="isLoading"></div>
        <div v-else>
            <div class="row">
                <div class="col-md-6">
                    <h3 class="n-back-link">
                        <router-link to="/shopping-cart">
                            <font-awesome-icon icon="chevron-left" /> Back to Shopping Cart
                        </router-link>
                    </h3>
                </div>
                <div class="col-md-6 text-right">
                    <h2>Review Order</h2>
                </div>
            </div>
            <div v-if="hasCartErrors" id="errorSection" class="mt-2 mb-2">
                <div v-for="(error, index) in placeOrderError.insufficientQuantityErrors" :key="index" class="row">
                    <div class="col-12">
                        <div class="alert alert-danger" role="alert">
                            The request quantity of
                            <strong>{{ error.quantityRequested }}</strong> for
                            <strong>{{ error.name }}</strong> is more than the quantity
                            available <strong>({{ error.quantityAvailable }})</strong>.
                        </div>
                    </div>
                </div>

                <div v-for="(error, index) in placeOrderError.messages" :key="index" class="row">
                    <div class="col-12">
                        <div class="alert alert-danger" role="alert">
                            {{ error.debugMessage }}
                        </div>
                    </div>
                </div>
            </div>

            <div id="shippingAddressSection" class="mb-3">
                <div class="row">
                    <div class="col-12">
                        <Headline title="Shipping Address" linkText="Change" v-on:on-click="editAddress"></Headline>
                    </div>
                </div>
                <div class="row px-4">
                    <div class="col-12">
                        <ShipAddressView :address="selectedAddress"></ShipAddressView>
                    </div>
                </div>
            </div>

            <div id="shippingMethodSection" class="mb-3">
                <div class="row">
                    <div class="col-12">
                        <Headline title="Shipping Method" linkText="Change" v-on:on-click="editShippingMethod"></Headline>
                    </div>
                </div>
                <div class="row px-4" v-if="selectedShippingMethod">
                    <div class="col-12">
                        <ShippingMethodView :shippingMethod="selectedShippingMethod" :showPricing="false"></ShippingMethodView>
                    </div>
                </div>
            </div>

            <div id="shoppingCartSection" class="mb-3">
                <div class="row">
                    <div class="col-12">
                        <div class="n-checkout-section-header px-4">
                            <h2>
                                Shopping Cart<span class="n-cartItems pl-1">
                                    {{
                  shoppingCartItems
                                    }}
                                </span>
                                <span class="n-change-link" @click="editShoppingCart">Change</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div v-if="hasCartErrors">
                    <b-alert variant="danger" show>
                        Please review the product(s) highlighted below. The issue(s)
                        indicated MUST be addressed before placing your order.
                    </b-alert>
                </div>
                <div v-else-if="hasCartWarnings">
                    <b-alert variant="warning" show>
                        <strong>
                            Please review the important messages below for details regarding
                            your order. Please press
                            <a href="#" @click="navToReviewOrder()">Review Order</a> to
                            continue.
                        </strong>
                        <ul class="mb-0">
                            <li v-for="(warning, index) in formattedCartWarnings" :key="index">
                                <span v-html="warning"></span>
                            </li>
                        </ul>
                    </b-alert>
                </div>
                <div v-for="item in cart.items" :key="item.id">
                    <div class="row px-4">
                        <div class="col-4 col-md-2">
                            <img :src="
                item.product.thumbnailImageUrl
                  ? item.product.thumbnailImageUrl
                  : noImageAvailableUrl
              " :name="item.product.name" :alt="item.product.name" class="img-fluid" />
                        </div>
                        <div class="col-8 col-md-8">
                            <div class="badges" v-for="badge in item.product.tags" :key="badge.id">
                                <span class="badge badge-primary">{{ badge.name }}</span>
                            </div>
                            <span class="badge badge-primary add-on-badge" v-if="isAddOnItem(item)">Add-on item</span>
                            <router-link :to="`/product-detail/${item.product.id}`" class="d-block n-product-name mb-2">
                                {{ item.product.name }}
                            </router-link>
                            <div>{{ item.product.itemNumber }}</div>
                            <h4 class="n-product-type">Product Type: {{ getCheckProductType(item) }}</h4>
                            <template v-if="item.product.messages && item.product.messages.length && !isAddOnItem(item)">
                                <div class="n-message-list">
                                    <MessageList :messages="item.product.messages" />
                                </div>
                            </template>

                            <div v-if="item.messages && item.messages.length > 0 && !isAddOnItem(item)" class="mt-2 mb-2">
                                <div v-for="(message, index) in item.messages" :key="index">
                                    <font-awesome-icon v-if="message.messageType === errorMessageType" class="text-danger mr-2"
                                                       icon="times-circle" />
                                    <font-awesome-icon v-else class="text-warning mr-2" icon="exclamation-circle" />
                                    <span v-html="getWarningByMessage(message)"></span>
                                </div>
                            </div>

                        </div>
                        <div class="col-8 offset-4 col-md-2 offset-md-0">
                            <div class="row">
                                <div class="col">
                                    <div><strong>Quantity:</strong></div>
                                    <span class="mr-3">{{ item.quantity }}</span>
                                    <label>
                                        <template v-if="getInventory(item.product) && getInventory(item.product).unitOfMeasure">
                                            <UoM :item="item.product" :uom="getInventory(item.product).unitOfMeasure"
                                                 :unitQuantity="getInventory(item.product).quantityPerUnit"></UoM>
                                        </template>
                                    </label>
                                    <div>
                                        <a href="#" @click="removeItem(item)">Remove</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row px-4">
                        <div class="col">
                            <hr />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row px-4 mb-3">
                <div class="col-12">
                    Order confirmation and shipping information will be sent to
                    {{ userInfo.userName }}
                </div>
            </div>

            <div class="row mb-5 px-4">
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-lg" :disabled="!canPlaceOrder" @click="placeOrder">
                        Place Order
                    </button>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
    import Headline from "@/nucleus-modules/dd-nucleus-ui/components/Headline.vue";
    import UoM from "@/nucleus-modules/dd-nucleus-ui/components/UnitOfMeasureDisplay.vue";

    import ShipAddressView from "@/nucleus-modules/dd-nucleus-storefront/components/ShipAddressView.vue";
    import ShippingMethodView from "@/nucleus-modules/dd-nucleus-storefront/components/ShippingMethodView.vue";

    import { OrdersMixin, ERROR_MESSAGE_TYPE } from "@/nucleus-modules/dd-nucleus-storefront/mixins/OrdersMixin.js";

    import PageTemplate from "@/pages/templates/PageTemplate.vue";
    import { noImageAvailableUrl } from "@/companyAppConfig.js";
    import MessageList from "@/nucleus-modules/dd-nucleus-ui/components/MessageList.vue";
    import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
    import { GET_INVENTORY_QUANTITY } from "@/store/storefront/actions.type.js";
    import { ADJUST_INVENTORY } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
    import { productMixins } from "@/mixins/productMixin.js";
    export default {
        name: "ReviewOrder",
        mixins: [OrdersMixin, productMixins],
        components: {
            Headline,
            UoM,
            ShipAddressView,
            ShippingMethodView,
            PageTemplate,
            MessageList,
        },
        data() {
            return {
                isLoading: true,
                noImageAvailableUrl: noImageAvailableUrl,
                selectedAddress: {},
                selectedShippingMethod: {},
                errorMessageType: ERROR_MESSAGE_TYPE
            };
        },
        computed: {
            ...mapGetters({
                userInfo: USER_INFO_GETTER,
            }),
            canPlaceOrder() {
                return (
                    !this.hasCartErrors &&
                    !this.hasItemErrors &&
                    this.cart.items.length > 0 &&
                    this.cart.shipMethodCode.length > 0 &&
                    (this.cart.shipToAddressId && this.cart.shipToAddressId.length > 0
                        || this.$nucleus.isEmpty(this.cart.shipToAddressId) && this.isValidAddress(this.cart.shipTo))
                );
            },
            shoppingCartTitle() {
                return `Shopping Cart (${this.cartItemsCount})`;
            },
            shoppingCartItems() {
                return `(${this.cartItemsCount} items)`;
            },
            hasCartItem() {
                return this.cart && this.cart.items && this.cart.items.length
            },
        },
        methods: {
            ...mapActions({
                getInventoryQuantities: GET_INVENTORY_QUANTITY,
                adjustInventory: ADJUST_INVENTORY,
            }),
            editAddress() {
                this.redirectToCheckout(this.checkoutSections.SHIPPING_ADDRESS);
            },
            editShippingMethod() {
                this.redirectToCheckout(this.checkoutSections.SHIPPING_METHOD);
            },
            editShoppingCart() {
                this.redirectToCheckout(this.checkoutSections.SHOPPING_CART);
            },
            isAddOnItem(item) {
                if (item && item.product && item.product.attributes && item.product.attributes.Addon) {
                    return item.product.attributes.Addon.isAddOn;
                }
                return false;
            },

            async placeOrder() {
                if (!this.canPlaceOrder) {
                    ToastService.showErrorToast("The issue(s) MUST be addressed before placing your order.");
                    return;
                }
                let placeOrder = true;

                const cartItems = this.cart.items
                    .filter(i => !(i.product.attributes && i.product.attributes.Addon))
                    .map(i => { return { itemNumber: i.product.itemNumber } });

                if (!this.$nucleus.isEmpty(cartItems)) {
                  // get real time inventory quantities

                  var getInvQtyResponse = await this.getInventoryQuantities(cartItems);

                  if (getInvQtyResponse.succeeded) {

                    const stockItems = [];
                    getInvQtyResponse.itemQuantities.forEach((i) => {
                      stockItems.push({ ItemNumber: i.itemNumber, QuantityAvailable: i.quantityAvailable });
                    });

                    // update inventory quantities in the database
                    await this.adjustInventory({ StockItems: stockItems, IsFullSync: false });

                  } else {
                    placeOrder = false;
                    let messages = ""
                    getInvQtyResponse.messages.forEach((e) => {
                      messages += e.debugMessage;
                    });
                    ToastService.showErrorToast(messages);
                  }
                } 
                
                if (placeOrder) {
                    await this.placeOrderAction({ cartId: this.cart.id });
                    // if the place order completes successfully, redirect to confirmation
                    if (this.placeOrderResponse && this.placeOrderResponse.succeeded) {
                        await this.getOrder(this.placeOrderResponse.orderId);
                        this.$router.push("/order-confirmed");
                    }
                    // otherwise, scroll to the top and ensure error messages are visible
                    else if (!this.$nucleus.isEmpty(this.placeOrderError)) {
                        this.$scrollTo("body", 500, { easing: "ease-in-out" });

                        // if there is not enough inventory, redirect to shopping cart
                        if (this.placeOrderError && this.placeOrderError.insufficientQuantityErrors && this.placeOrderError.insufficientQuantityErrors.length > 0) {
                            this.$router.push("/shopping-cart");
                        }
                        else {
                            if (this.placeOrderError && this.placeOrderError.messages && this.placeOrderError.messages.length > 0) {
                                let messages = ""
                                this.placeOrderError.messages.forEach((e) => {
                                    messages += e.debugMessage;
                                });
                                ToastService.showErrorToast(messages);
                            } else {
                                ToastService.showErrorToast("There was an error trying to process your order. Please try again, or contact support if the issue persists.");
                            }
                        }
                    }
                }
            },
            redirectToCheckout(checkoutSection) {
                this.$nucleus.removeStorageItem(this.checkoutSectionKey, true);

                if (checkoutSection === 0 || checkoutSection) {
                    this.$nucleus.setStorageItem(
                        this.checkoutSectionKey,
                        checkoutSection,
                        true
                    );
                }

                this.$router.push(`/checkout?id=${this.userInfo.contactId}`);
            },
            async removeItem(item) {
                await this.removeFromCart({
                    cartId: this.cart.id,
                    itemId: item.id,
                });

                this.initializePlaceOrder();
                await this.getCart();
                if (
                    this.$nucleus.isEmpty(this.cart) ||
                    this.$nucleus.isEmpty(this.cart.items)
                ) {
                    this.$router.push("/shopping-cart");
                }
            },
            isValidAddress(address) {

                return !this.$nucleus.isEmpty(address.addressee)
                    && !this.$nucleus.isEmpty(address.addressLine1)
                    && !this.$nucleus.isEmpty(address.city)
                    && !this.$nucleus.isEmpty(address.state)
                    && !this.$nucleus.isEmpty(address.postalCode)
                    && !this.$nucleus.isEmpty(address.phoneNumber);

            },
            getCheckProductType(item) {
                if(item.product.attributes && item.product.attributes.ExtendedProductProperties && item.product.attributes.ExtendedProductProperties.productType) {
                    return item.product.attributes.ExtendedProductProperties.productType;
                }
                return "";
            }
        },
        async mounted() {
            this.initializePlaceOrder();
            await this.getCart();

            this.selectedAddress = this.cart.shipTo;
            if (this.selectedAddress) {
                if (this.selectedAddress.attention
                    && this.selectedAddress.addressee
                    && this.selectedAddress.attention != this.selectedAddress.addressee) {
                    this.selectedAddress.organization = this.selectedAddress.addressee;
                    this.selectedAddress.firstName = this.selectedAddress.attention;
                }
                else {
                    this.selectedAddress.firstName = this.selectedAddress.addressee;
                }
            }
            if (this.selectedAddress && this.cart.shipToAddressId) {
                let address = this.addresses.find(
                    (a) => a.id === this.cart.shipToAddressId
                );
                this.selectedAddress.isDefault = address && address.isDefault;
            }
            this.selectedShippingMethod = this.shippingMethods.find(
                (sm) => sm.code === this.cart.shipMethodCode
            );

            this.isLoading = false;
        },
    };
</script>

<style lang="scss" scoped>
    .btn-lg {
        padding: 0.5rem 1rem !important;
        font-size: 1.25rem !important;
        line-height: 1.5 !important;
        border-radius: 0.3rem !important;
    }

    .n-cartItems {
        color: $gray-3;
        font-size: 1rem;
        font-weight: 400;
    }

    .n-checkout-section-header h2 {
        margin-bottom: 0;
        font-weight: 400;
    }

    .n-change-link {
        padding-top: 2px;
        font-size: 14px;
        padding-left: 20px;
    }

    .n-product-type {
        font-size: 15px;
    }
</style>
