export const PRODUCT_TYPE = "product-type";

export const AdminEvents = Object.freeze({
    BULK_ORDER_CANCELLED: "bulk-order-cancelled",
    CANCEL_PRODUCT_EDIT: "cancel-product-edit",
    PRODUCT_CATEGORIES_VALIDITY_UPDATED: "product-categories-validity-updated",
    PRODUCT_DETAIL_VALIDITY_UPDATED: "product-detail-validity-updated",
    PRODUCT_DIMENSIONS_VALIDITY_UPDATED: "product-dimensions-validity-updated",
    PRODUCT_IMAGE_REMOVED: "product-image-removed",
    PRODUCT_IMAGE_UPLOADED: "product-image-uploaded",
    PRODUCT_IMAGES_RERANKED: "product-images-reranked",
    PRODUCT_ROLES_VALIDITY_UPDATED: "product-roles-validity-updated",
    PRODUCT_SAVED: "product-saved",
    SAVE_PRODUCT: "save-product",
    STEPPER_STEP_NEXT: "stepper-step-next",
    STEPPER_STEP_PREVIOUS: "stepper-step-previous",
    APPROVE_ORDER: "approve-order",
    REJECT_ORDER: "reject-order",
});

export const AddressType = Object.freeze({
    NONE: 0,
    BILLING: 1,
    SHIPPING: 2
});

export const BulkOrderFilters = Object.freeze({
    BULK_ORDER_NUMBER: { id: "bulk-order-number", name: "Bulk Order Number" },
    SALES_REP_GEO_ID: { id: "geo-id", name: "GEO ID" },
    SALES_REP_POSTAL_CODE: { id: "sales-rep-postal-codes", name: "Postal Code" },
    SALES_REP_PRIMARY_ROLE: { id: "primary-role", name: "Roles" },
    SALES_REP_SEARCH_TERM: { id: "sales-rep-search-term", name: "Search Terms" },
    SALES_REP_STATE: { id: "sales-rep-address-state", name: "States" },
    SALES_REP_STATUS_CODE: { id: "status-code", name: "User Status" },
    SEARCH_TERM: { id: "search-term", name: "Search Terms" },
    SALES_REP_LOGIN_ACCESS_CODE: { id: "user-access", name: "Login Access" },
    SALES_REP_REGION_ID: { id: "region-id", name: "Regions" },
    SALES_REP_DISTRICT_ID: { id: "district-id", name: "Districts" },
    SALES_REP_TERRITORY_ID: { id: "territory-id", name: "Territories" },
    SALES_REP_ADDRESS_TYPE: { id: "addrerss-type", name: "Address Type"}
});

export const OrderFilters = Object.freeze({
    STATUS: "order-status"
});

export const EmailAddressType = Object.freeze({
    NONE: 0,
    HOME: 1,
    Other: 2,
    Work: 3
});

export const ItemStatus = Object.freeze({
    PENDING: "Pending",
    DELIVERED: "Delivered",
    CANCELED: "Canceled",
    REMOVED: "Removed"
});

export const LimitNames = Object.freeze({
    DAILY: "Daily",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
});

export const MediaStatus = Object.freeze({
    CANCELLED: "cancelled",
    ERROR: "error",
    SUCCESS: "success",
});

export const OrderStatus = Object.freeze({
    NONE: 0,
    PENDING: 1,
    PENDING_APPROVAL: 2,
    CANCELLED: 99,
    COMPLETED: 100
});

export const PaymentStatus = Object.freeze({
    None: 0,
    PENDING: 1,
    PARTIAL: 2,
    COMPLETED: 100
});

export const PhoneNumberType = Object.freeze({
    NONE: 0,
    HOME: 1,
    MOBILE: 2,
    Other: 3,
    Work: 4
});

export const ProductAvailability = Object.freeze({
    ALL: 0,
    LOGGED_IN: 1,
    ROLE_BASED: 2
});

export const ProductStatus = Object.freeze({
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    NEW: "New"
});

export const ShippingStatus = Object.freeze({
    NONE: 0,
    PENDING: 1,
    PARTIAL: 2,
    COMPLETED: 100
});

export const Status = Object.freeze({
    ACTIVE: "Active"
});

export const StatusCodes = Object.freeze({
    NONE: 0,
    NEW: 1,
    ACTIVE: 2,
    INACTIVE: 3,
    DELETED: 4,
    LOCKED: 5
});

export const TagTypes = Object.freeze({
    INTERNAL: 0,
    USER: 1,
    VISIBLE: 2
});

export const UserEvents = Object.freeze({
    USER_PRIMARY_INFO_UPDATED: "user_primary_info_updated",
    USER_CONTACT_INFO_UPDATED: "user_contact_info_updated",
    USER_ADDITIONAL_INFO_UPDATED: "user_additional_info_updated",
    USER_ACCOUNT_ADDRESSES_UPDATED: "user_account_addresses_updated",
    USER_ROLES_UPDATED: "user_roles_updated",
    USER_SALES_STRUCTURE_UPDATED: "user_sales_structure_updated",
    USER_SALES_STRUCTURE_SELECTED: "user_sales_structure_selected",
    USER_SAVED: "user-saved"
});

export const OrderApprovalReasons = Object.freeze([
    {id: "LimitExceeded", name: "Limit Exceeded", detail:"", isOrderLevel: false },
    {id: "ShipMethod", name: "Expedite Shipping", isOrderLevel: true, detail:"Expedite Ship Method has been selected and needs approval"},
    {id: "NotDefaultAddress", name: "Not Default Address", isOrderLevel: true, detail:"Non Default Address has been selected and needs approval"},
    {id: "CrmAddress", name:"CRM Address Selected", isOrderLevel: true, detail:"CRM address has been selected and needs approval"}
]);

export const LoginAccess = Object.freeze([
    { id: "all", name: "All" },
    { id: "storefront", name: "Storefront" },
    { id: "admin", name: "Admin" },
]);

export const LoginAccessType = Object.freeze({
    STOREFRONT: "storefront",
    ADMIN: "admin",
    ALL: "all"
});

export const OrderApprovalStatus = Object.freeze([
     { code: 0, text: 'Approve Order(s)' },
     { code: 1, text: 'Reject Order(s)' },
]);