<template>
  <div class="screen-size">
    <div class="d-block d-sm-none">xs</div>
    <div class="d-none d-sm-block d-md-none">sm</div>
    <div class="d-none d-md-block d-lg-none">md</div>
    <div class="d-none d-lg-block d-xl-none">lg</div>
    <div class="d-none d-xl-block">xl</div>
  </div>
</template>
<script>
// Used for testing. Displays the Bootstrap size breakpoint being viewed in the corner.

export default {
  name: "ScreenSize",
};
</script>
<style scoped lang="scss">
.screen-size {
  position: absolute;
  z-index: 10000;
  padding: 4px;
  left: 5px;
  top: 5px;
  background-color: blue;
  color: white;
}
</style>
