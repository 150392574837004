<template>
  <div>
    <h4 class="text-success">Order #{{ bulkOrderDetail.orderNumber }}</h4>
    <div class="form-group">
      <label>Order Status</label>
      <span
        class="badge badge-pill ml-3"
        :class="getOrderStatusBadgeInfo(bulkOrderDetail.orderStatus).className"
        >{{
          getOrderStatusBadgeInfo(bulkOrderDetail.orderStatus).statusName
        }}</span
      >
    </div>
    <hr />
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Number of Recipients</label>
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            :value="bulkOrderDetail.numberOfRecipients"
          />
        </div>
        <div class="form-group">
          <label>Number Shipped</label>
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            :value="shippedOrders"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Created Date</label>
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            :value="createdDate()"
          />
        </div>
        <div class="form-group">
          <label>Order Placed By</label>
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            :value="bulkOrderDetail.createdBy"
          />
        </div>
        <div class="form-group">
          <label>Order Placed By Email</label>
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            :value="bulkOrderDetail.createdByEmail"
          />
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-3">
      <div class="col">
        <h3 class="d-inline mr-2">
          Product<span v-if="products.length > 1">s</span>
        </h3>
        <strong>({{ products.length }})</strong>
        <VueGoodTable
          class="table table-striped"
          :rows="products"
          :columns="productColumns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.type === 'date'">
              {{ props.formattedRow[props.column.field] | moment(dateFormat) }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </VueGoodTable>
      </div>
    </div>
    <hr />
    <div class="row mt-3">
      <div class="col">
        <h3>Ship Method</h3>
        <span>{{ bulkOrderDetail.shipMethodName }}</span>
      </div>
    </div>
    <hr />
    <div v-if="hasInternalNotes">
      <div class="row mt-3">
        <div class="col">
          <h3>Internal Notes</h3>
          <p>{{ bulkOrderDetail.internalNotes }}</p>
        </div>
      </div>
      <hr />
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <h3 class="d-inline">Recipient List</h3>
        <strong class="text-danger">
          ({{ bulkOrderDetail.numberOfRecipients }})
        </strong>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-end align-items-baseline">
          <VueExcelExport
            class="mr-2 n-action-button"
            :data="selectedRecipientsForExport"
            :columns="excelColumns"
            :file-name="'recipients'"
            :file-type="'xlsx'"
            :sheet-name="'Recipients'"
          >
            <font-awesome-icon
              :icon="['fa', 'file-excel']"
              class="fa-2x n-excel-export-button"
            ></font-awesome-icon>
          </VueExcelExport>
          <VueJsonToCsv
            class="mr-2 n-action-button"
            csv-title="recipients"
            :json-data="selectedRecipientsForExport"
            :labels="csvColumns"
          >
            <font-awesome-icon
              :icon="['fa', 'file-csv']"
              class="fa-2x n-csv-export-button"
            ></font-awesome-icon>
          </VueJsonToCsv>
          <div class="mr-2">
            <b-form-select
              v-model="paging.pageSize"
              :options="paging.perPageOptions"
            ></b-form-select>
          </div>
          <span class="small mr-2">per page</span>
          <div>
            <b-pagination
              v-model="pagerPageNumber"
              :total-rows="totalRecipients"
              :per-page="paging.pageSize"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <VueGoodTable
          class="table table-striped"
          :rows="recipients"
          :columns="reviewColumns"
        >
          <div slot="emptystate">
            <p>There are no recipients selected.</p>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'userName'">
              <div class="row">
                <div class="col">
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <UserBadges
                    :showActive="
                      props.row.status === statusCodes.ACTIVE ||
                      props.row.status === statusCodes.LOCKED
                        ? true
                        : false
                    "
                    class="mb-2"
                  ></UserBadges>
                </div>
              </div>
            </span>
            <span v-else-if="props.column.field === 'orderDate'">
              <div class="row">
                <div class="col">
                  {{createdDate(dateFormat)}}
                </div>  
              </div>
            </span>
            <span v-else-if="props.column.field === 'orderStatus'">
              <div class="row">
                <div class="col">
                    <span class="badge badge-pill ml-3"
                    :class="getOrderStatusBadgeInfo(props.formattedRow[props.column.field]).className">
                    {{ getOrderStatusBadgeInfo( props.formattedRow[props.column.field]).statusName}}
                    </span>
                  </div>  
              </div>
            </span>
            <span v-else-if="props.column.field === 'shippingStatus'">
              <div class="row">
                <div class="col">
                    <span class="badge badge-pill ml-3"
                    :class="getShippingStatusBadgeInfo(props.formattedRow[props.column.field]).className">
                    {{ getShippingStatusBadgeInfo(props.formattedRow[props.column.field]).statusName}}
                    </span>
                  </div>  
              </div>
            </span>
            
            <span v-else>
              <div class="row">
                <div class="col">
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </div>
            </span>
          </template>
        </VueGoodTable>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueExcelExport from "@/nucleus-modules/dd-nucleus-ui/components/VueExcelExport.vue";
import VueJsonToCsv from "vue-json-to-csv";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";

import UserBadges from "@/nucleus-modules/dd-nucleus-admin/components/user/UserBadges.vue";
import { StatusCodes } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import { ShippingStatus } from "@/nucleus-modules/dd-nucleus-admin/constants.js";


export default {
  name: "BulkOrderDetail",
  mixins: [BulkOrdersMixin],
  components: {
    VueGoodTable,
    VueExcelExport,
    VueJsonToCsv,
    UserBadges,
  },
  data() {
    return {
      dateFormat: "MM/DD/YYYY",
      pagerPageNumber: 1,
      statusCodes: StatusCodes,
      csvColumns: {
          orderNumber: { title: 'Nucleus Order #' },
          orderDate: { title: 'Order Date' },
          orderStatus: { title: 'Order Status' },
          shippingStatus: { title: 'Shipping Status' },
          trackingNumbers: { title: 'Tracking' },
          userName: { title: 'User ID' },
          companyName: { title: 'Business Name' },
          firstName: { title: 'First Name' },
          lastName: { title: 'Last Name' },
          title: { title: 'Title' },
          primaryUserRoleName: { title: 'Primary Role' },
          addressTypeName: { title: 'Address Type' },
          addressee: { title: 'Addressee' },
          addressLine1: { title: 'Address 1' },
          addressLine2: { title: 'Address 2' },
          city: { title: 'City' },
          state: { title: 'State' },
          postalCode: { title: 'Postal Code' },
          geoId: { title: 'GEO ID' },
          territoryName: { title: 'Territory Name' }
      },
      excelColumns: [
        { label: "Nucleus Order #", field: "orderNumber" },
        { label: "Order Date", field: "orderDate" },
        { label: "Order Status", field: "orderStatus" },
        { label: "Shipping Status", field: "shippingStatus" },
        { label: "Tracking", field: "trackingNumbers" },
        { label: "User ID", field: "userName" },
        { label: "Business Name", field: "companyName" },
        { label: "First Name", field: "firstName" },
        { label: "Last Name", field: "lastName" },
        { label: "Title", field: "title" },
        { label: "Primary Role", field: "primaryUserRoleName" },
        { label: "Address Type", field: "addressTypeName" },
        { label: "Addressee", field: "addressee" },
        { label: "Address 1", field: "addressLine1" },
        { label: "Address 2", field: "addressLine2" },
        { label: "City", field: "city" },
        { label: "State", field: "state" },
        { label: "Postal Code", field: "postalCode" },
        { label: "Geo Id", field: "geoId" },
        { label: "Territory Name", field: "territoryName" },
      ],
      productColumns: [
        {
          field: "itemNumber",
          label: "Item Number",
          sortable: false,
          type: "string",
        },
        {
          field: "name",
          label: "Product Name",
          sortable: false,
          type: "string",
        },
        {
          field: "dateOfFirstUse",
          label: "Date of First Use",
          sortable: false,
          type: "date",
          formatFn: function (value) {
            return value == null ? "" : value;
          },
        },
        {
          field: "unitOfMeasure",
          label: "Unit of Measure",
          sortable: false,
          type: "string",
        },
        {
          field: "quantityPerRecipient",
          label: "Quantity per Recipient",
          sortable: false,
          type: "number",
        },
        {
          field: "quantityTotal",
          label: "Quantity Total",
          sortable: false,
          type: "number",
        },
      ],
      reviewColumns: [
        {
          field: "orderNumber",
          label: "Nucleus Order #",
          sortable: true,
          width: '150px',
        },
        {
          field: "orderDate",
          label: "Order Date",
          sortable: true,
          width: '120px',
        },
        {
          field: "orderStatus",
          label: "Order Status",
          sortable: true,
          width: '120px',
        },
        {
          field: "shippingStatus",
          label: "Shipping Status",
          sortable: true,
          width: '140px',
        },
        {
          field: "trackingNumbers",
          label: "Tracking",
          sortable: true,
          width: '180px',
        },
        {
          field: "userName",
          label: "User ID",
          sortable: true,
          width: '250px'
        },
        {
          field: "companyName",
          label: "Business Name",
          sortable: true,
          width: '180px',
        },
        {
          field: "firstName",
          label: "First Name",
          sortable: true,
          width: '150px',
        },
        {
          field: "lastName",
          label: "Last Name",
          sortable: true,
          width: '150px',
        },
        {
          field: "attributes.regen-detail.title",
          label: "Title",
          sortable: true,
          width: '180px',
        },
        {
          field: "primaryUserRoleName",
          label: "Primary Role",
          sortable: true,
          width: '230px',
        },
        {
          field: "addressTypeName",
          label: "Address Type",
          sortable: true,
          width: '130px',
        },
        {
          field: "addressee",
          label: "Addressee",
          sortable: true,
          width: '200px',
        },
        {
          field: "addressLine1",
          label: "Address 1",
          sortable: true,
          width: '220px',
        },
        {
          field: "addressLine2",
          label: "Address 2",
          sortable: true,
          width: '150px',
        },
        {
          field: "city",
          label: "City",
          sortable: true,
          width: '230px',
        },
        {
          field: "state",
          label: "State",
          sortable: true,
          width: '100px',
        },
        {
          field: "postalCode",
          label: "Postal Code",
          sortable: true,
          width: '150px',
        },
        {
          field: "countryCode",
          label: "Country",
          sortable: true,
          hidden: true
        },
        {
          field: "attributes.regen-detail.geoId",
          label: "Geo ID",
          sortable: true,
          width: '150px',
        },
        {
          field: "attributes.regen-detail.territoryName",
          label: "Territory",
          sortable: true,
          width: '180px',
        }
      ],
      paging: {
        pageSize: 200,
        perPageOptions: [
          { value: 200, text: "200" },
          { value: 400, text: "400" },
          { value: 600, text: "600" },
          { value: 800, text: "800" },
          { value: 1000, text: "1000" },
        ],
      },
    };
  },
  computed: {
    bulkOrderDetail() {
      if (
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse) ||
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse.bulkOrder)
      ) {
        return {};
      } else {
        return this.getBulkOrderDetailResponse.bulkOrder;
      }
    },
    hasInternalNotes() {
      if (
        !this.bulkOrderDetail.internalNotes ||
        this.bulkOrderDetail.internalNotes.length === 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    products() {
      const result = [];
      const items = [];

      if (
        !this.$nucleus.isEmpty(this.getBulkOrderDetailResponse) &&
        !this.$nucleus.isEmpty(this.getBulkOrderDetailResponse.bulkOrder) &&
        !this.$nucleus.isEmpty(
          this.getBulkOrderDetailResponse.bulkOrder.recipients
        )
      ) {
        this.getBulkOrderDetailResponse.bulkOrder.recipients.forEach((r) => {
          r.products.forEach((p) => {
            items.push(p);
          });
        });

        items.forEach((i) => {
          const p = result.find((r) => r.id === i.id);

          if (p) {
            p.quantityTotal += i.quantity * i.quantityPerUnit;
          } else {
            const extendedProps = i.attributes.ExtendedProductProperties;

            result.push({
              id: i.id,
              itemNumber: i.itemNumber,
              name: i.name,
              dateOfFirstUse: extendedProps.firstUseDate,
              quarantineDate: extendedProps.quarantineDate,
              unitOfMeasure: i.unitOfMeasure,
              quantityPerRecipient: i.quantity,
              quantityTotal: i.quantity * i.quantityPerUnit,
            });
          }
        });
      }

      return result;
    },
    recipients() {
      if (
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse) ||
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse.bulkOrder) ||
        this.$nucleus.isEmpty(
          this.getBulkOrderDetailResponse.bulkOrder.recipients
        )
      ) {
        return [];
      } else {
        return this.getBulkOrderDetailResponse.bulkOrder.recipients.map(
          (item) => {
            if (item.addressTypeName === "Billing") {
              let newItem = { ...item, addressTypeName: "Primary" };
              return newItem;
            } else {
              return item;
            }
          }
        );
      }
    },
    selectedRecipientsForExport() {
      let altered = this.recipients.map((item) => {
        const keys = Object.keys(item);
        keys.forEach(key => {
          if(!item[key]){
            item[key] = ""
          }
        });

        let newItem = { ...item };
        newItem.orderDate = this.createdDate(this.dateFormat);
        const orderStatus = this.getOrderStatusBadgeInfo(item.orderStatus);
        if(orderStatus) {
          newItem.orderStatus = orderStatus.statusName;
        }

        const shippingStatus = this.getShippingStatusBadgeInfo(item.shippingStatus);
        if(shippingStatus) {
          newItem.shippingStatus = shippingStatus.statusName;
        }

        if (item.addressTypeName === "Billing") {
          newItem.addressTypeName =  "Primary" ;
        }
         return newItem;
      });

      let i;
      for (i = 0; i < altered.length; i++) {
        altered[i].title = altered[i]["attributes"]["regen-detail"]["title"];
        altered[i].geoId = altered[i]["attributes"]["regen-detail"]["geoId"];
        altered[i].territoryLevel =
          altered[i]["attributes"]["regen-detail"]["territoryLevel"];
        altered[i].territoryName =
          altered[i]["attributes"]["regen-detail"]["territoryName"];
      }

      return altered;
    },
    totalRecipients() {
      if (
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse) ||
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse.bulkOrder)
      ) {
        return 0;
      } else {
        return this.getBulkOrderDetailResponse.bulkOrder.numberOfRecipients;
      }
    },
    shippedOrders() {
      const shippedOrders = this.recipients.filter(r => r.shippingStatus == ShippingStatus.COMPLETED)
      if(shippedOrders && shippedOrders.length > 0) {
        return shippedOrders.length;
      }
      return 0;
    }
  },
  methods: {
    backToHome() {
      this.$emit("back-to-home");
    },
    hide() {
      this.$emit("hide-bulk-order-detail");
    },
    createdDate(dateFormat) {
      dateFormat = dateFormat ? dateFormat : "MM/DD/YYYY hh:mm a";
      if (
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse) ||
        this.$nucleus.isEmpty(this.getBulkOrderDetailResponse.bulkOrder)
      ) {
        return "";
      } else {
        return this.$options.filters.moment(
          this.getBulkOrderDetailResponse.bulkOrder.created, dateFormat
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-plaintext {
  outline: none;
}
</style>
