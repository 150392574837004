<template>
  <div>
    <PageSubHeader title="Contact Information" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-xs-12 col-sm-5">
        <dl>
          <dt>Email Address:</dt>
          <dd>
            <span v-if="user.email"> {{ user.email }}</span>
            <span v-else>No Email Address assigned</span>
          </dd>
          <dt>Phone:</dt>
          <dd>
            <span v-if="defaultPhoneNumber"> {{ defaultPhoneNumber }}</span>
            <span v-else>No Phone Number assigned</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

export default {
  name: "UserContactInfoViewOnly",
  props: {
    user: { type: Object },
    defaultPhoneNumber: {
      type: String
    }
  },
  components: {
    PageSubHeader,
  },
};
</script>

<style></style>
