<template>
    <div v-if="isAdminUser && !isImpersonatingUser">
        <b-badge class="nav-pill"
                 href="/admin.html"
                 variant="primary"
                 >
            Go to Admin Center
        </b-badge>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { SITE_SETUP_GETTER  } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
    import { USER_INFO_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";

    export default {
        name: "Badge",
        computed: {
            ...mapGetters({
                siteSetup: SITE_SETUP_GETTER,
                userInfo: USER_INFO_GETTER,
            }),
            user() {
                if (this.siteSetup && this.siteSetup.user) {
                    return this.siteSetup.user
                }
                return {}
            },
            isAdminUser() {
                return this.userInfo && this.userInfo.hasAdminAccess;
            },
            isImpersonatingUser() {
                return this.user && this.user.isImpersonating;
            },
        }
    }
</script>

<style>
</style>