		<template>
  <AdminTemplate>
    <div v-show="currentView === viewTypes.HOME" class="row">
      <div class="col">
        <BulkOrdersHome
          :isLoading="isLoading"
          v-on:create-bulk-order="showCreateBulkOrder"
        ></BulkOrdersHome>
      </div>
    </div>
    <div v-show="currentView === viewTypes.CREATE" class="row">
      <div class="col">
        <CreateBulkOrder
          :availableStatuses="availableStatuses"
          :allDistricts="allDistricts"
          :allRoles="allRoles"
          :allRegions="allRegions"
          :allTerritories="allTerritories"
          :isLoading="isLoading"
          :shipMethods="shippingMethods"
          :statesList="statesList"
          v-on:back-to-home="showBulkOrdersHome"
          v-on:show-confirmation="showConfirmation"
          :loginAccessList="loginAccessList"
        >
        </CreateBulkOrder>
      </div>
    </div>
    <div v-show="currentView === viewTypes.CONFIRMATION" class="row">
      <div class="col">
        <BulkOrderPlacedConfirmation
          v-on:back-to-all="showBulkOrdersHome()"
        ></BulkOrderPlacedConfirmation>
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import { mapActions } from "vuex";

import { GET_SITE_SETUP } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";

import BulkOrdersHome from "@/components/admin/bulk-orders/BulkOrdersHome.vue";
import CreateBulkOrder from "@/components/admin/bulk-orders/CreateBulkOrder.vue";
import BulkOrderPlacedConfirmation from "@/components/admin/bulk-orders/BulkOrderPlacedConfirmation.vue";

import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";


import {
  StatusCodes,
  LoginAccess,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";

import AdminTemplate from "@/pages/templates/AdminTemplate.vue";
import { AdminEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "BulkOrders",
  mixins: [BulkOrdersMixin],
  components: {
    BulkOrdersHome,
    CreateBulkOrder,
    BulkOrderPlacedConfirmation,
    AdminTemplate,
  },
  data() {
    return {
      availableStatuses: [
        { id: -1, name: "All" },
        { id: StatusCodes.ACTIVE, name: "Active" },
        { id: StatusCodes.INACTIVE, name: "Inactive" },
      ],
      loginAccessList: LoginAccess,
      currentView: null,
      isLoading: true,
      viewTypes: {
        HOME: "Home",
        CREATE: "Create",
        CONFIRMATION: "Confirmation",
      },
    };
  },
  computed: {
    allDistricts() {
      if (this.companySegments.districts) {
        return this.companySegments.districts.map((d) => ({
          id: d,
          name:d
        }));
      } else {
        return [];
      }
    },
    allRegions() {
      if (this.companySegments.regions) {
        return this.companySegments.regions.map((r) => ({
          id: r,
          name: r,
        }));
      } else {
        return [];
      }
    },
    allTerritories() {
      if (this.companySegments.territories) {
        return this.companySegments.territories.map((t) => ({
          id: t,
          name: t,
        }));
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getSiteSetup: GET_SITE_SETUP,
    }),
    async showBulkOrdersHome() {
      await this.searchBulkOrders();
      this.$eventBus.$emit(AdminEvents.BULK_ORDER_CANCELLED);
      this.currentView = this.viewTypes.HOME;
    },
    showConfirmation() {
      this.currentView = this.viewTypes.CONFIRMATION;
    },
    showCreateBulkOrder() {
      this.initBulkOrder(this.shippingMethods[0]);
      this.currentView = this.viewTypes.CREATE;
    },
  },
  async mounted() {
    await this.getSiteSetup();
    await this.getAllRoles();
    await this.getCompanySegments();
    await this.searchBulkOrders();
    
    this.currentView = this.viewTypes.HOME;
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped></style>
