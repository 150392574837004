class PagingService {
    getPagingQueryString(request, offsetPageNumber = true) {
        let result = "";

        if (request.paging) {
            if (request.paging.hasOwnProperty("pageNumber")) {
                const pageNumber = offsetPageNumber ? request.paging.pageNumber - 1 : request.paging.pageNumber;

                result += `page=${pageNumber}`;
            }

            if (request.paging.hasOwnProperty("pageSize")) {
                const prefix = this.getPagingOptionPrefix(result);

                result += `${prefix}page-size=${request.paging.pageSize}`;
            }

            if (request.paging.hasOwnProperty("includeTotal")) {
                const prefix = this.getPagingOptionPrefix(result);
                const includeTotal = request.paging.includeTotal || true;

                result += `${prefix}include-total=${includeTotal}`;
            }
        }

        return result;
    }

    getPagingOptionPrefix(request) {
        return request.length > 0 ? "&" : "";
    }
}

export default new PagingService();
