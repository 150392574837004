<template>
  <div>
    <span v-if="showActive" class="badge badge-pill badge-success">Active</span>
    <span class="badge badge-pill badge-primary" v-if="hasAdminAccess"
      >Admin</span
    >
    <span class="badge badge-pill badge-warning" v-if="hasStorefrontAccess"
      >Storefront</span
    >
  </div>
</template>

<script>
import { storefrontRoles, adminRoles } from "@/companyAppConfig";

export default {
  name: "UserBadges",
  props: {
    roles: {
      type: Array,
    },
    showActive: {
      type: Boolean,
    },
  },
  computed: {
    hasAdminAccess() {
      if (this.roles) {
        return this.roles.some((r) => adminRoles.includes(r));
      }
      return false;
    },
    hasStorefrontAccess() {
      if (this.roles) {
        return this.roles.some((r) => storefrontRoles.includes(r));
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.badge-primary {
  color: $admin-primary-color;
}
</style>
