<template>
  <div>
    <PageSubHeader title="Assign Roles" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-12 mb-4">
        Select all roles that apply to this homepage announcement:
        <a class="display-inline ml-4" @click="selectAll"> Select All </a>
        <span>|</span>
        <a class="display-inline" @click="deSelectAll"> Deselect All </a>
      </div>
    </div>
    <div class="row">
      <div class="col-4" v-for="role in roles" :key="role.id">
        <div class="form-group">
          <label>
            <input
              type="checkbox"
              v-model="selectedUserRoleIds"
              name="roles"
              :value="role.id"
              :disabled="isDefault"
            />
            <span class="n-role-name ml-3">{{ role.name }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";
import { SET_ANNOUNCEMENT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

export default {
  name: "AnnouncementUserRoles",
  props: {
    selectedRoleIds: { type: Array, required: true },
    roles: { type: Array, required: true },
    isDefault: { type: Boolean, required: false },
  },
  components: {
    PageSubHeader,
  },
  computed: {
    selectedUserRoleIds: {
      get() {
        if (this.selectedRoleIds && this.selectedRoleIds.length > 0) {
          return this.selectedRoleIds.map((x) => x.roleId);
        }
        return [];
      },
      set(value) {
        var selectedRoleIds = this.roles.filter(sr => value.includes(sr.id));
        selectedRoleIds = selectedRoleIds.map(sr =>  {
          return { roleId : sr.id, roleName : sr.name } ;
        }) ;
        this.setAnnouncementProperty({ name: "userRoleIds", value: selectedRoleIds });
      },
    },
  },
  methods: {
    ...mapMutations({
      setAnnouncementProperty: SET_ANNOUNCEMENT_PROPERTY,
    }),
    selectAll() {
      this.selectedUserRoleIds = this.roles.map((x) => x.id);
    },
    deSelectAll() {
      this.selectedUserRoleIds = [];
    },
  },
};
</script>

<style lang="scss">
.nav-link {
  padding: 0.5rem 2rem 0.5rem 2rem;
}

.n-product-status {
  color: $green;
  margin-top: 12px;
}

.modal-footer {
  justify-content: center;
}

.modal-title {
  color: $tertiary-dark-color;
}

.modal-body {
  padding-top: 16px;
}

#confirmExit {
  left: unset !important;
}

.n-role-name {
  font-weight: normal;
}
</style>
