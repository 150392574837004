import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import { GET_CATEGORY_ROOT, ADD_ACTIVE_CATEGORY, RESET_ACTIVE_CATEGORIES } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { ALL_CATEGORIES_ID_GETTER, CATEGORY_ROOT_GETTER, FLATTENED_CATEGORIES_GETTER, ACTIVE_CATEGORIES_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { SET_CATEGORY_ROOT, SET_ACTIVE_CATEGORIES } from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

const MODULE_ID = "categories";
const ALL_CATEGORIES_ID = "all-categories";
const INCLUDE_LEVELS_SUBCATEGORIES = 2;

const initialState = {
    categoryRoot: {},
    activeCategories:[]
};

export const state = { ...initialState };

export const actions = {
    async [GET_CATEGORY_ROOT]({ commit }, payload) {
        const includeSubCategoryLevels = payload && payload.includeSubCategoryLevels ? payload.includeSubCategoryLevels : INCLUDE_LEVELS_SUBCATEGORIES;
        const response = await ApiService.get(`ui/user/categories?includeLevels=${includeSubCategoryLevels}`);

        if (response.succeeded) {
            commit(SET_CATEGORY_ROOT, response.categories);
        }
        else {
            // TODO: add a system error
        }
    },
    [ADD_ACTIVE_CATEGORY]({ commit }, category) {
        var existingCategoryIndex = state.activeCategories.findIndex(x => x.id === category.id);

        if (existingCategoryIndex === -1) {
            state.activeCategories.push(category);
        } else {
            state.activeCategories[existingCategoryIndex].id = category.id;
            state.activeCategories[existingCategoryIndex].isOpened = category.isOpened;
            state.activeCategories.length = existingCategoryIndex + 1;
        }

        commit(SET_ACTIVE_CATEGORIES, state.activeCategories);
    },
    [RESET_ACTIVE_CATEGORIES]({ commit }) {
        commit(SET_ACTIVE_CATEGORIES, state.activeCategories = []);
    }
};

export const getters = {
    [ALL_CATEGORIES_ID_GETTER]: () => {
        return ALL_CATEGORIES_ID;
    },
    [CATEGORY_ROOT_GETTER]: state => {
        return state.categoryRoot;
    },
    [FLATTENED_CATEGORIES_GETTER]: state => {
        if (state.categoryRoot && state.categoryRoot.length) {
            return flatDeep(state.categoryRoot);
        }
        return [];
    },
    [ACTIVE_CATEGORIES_GETTER]: state => {
        return state.activeCategories;
    }
};

export const mutations = {
    [SET_CATEGORY_ROOT]: (state, categoryRoot) => {
        state.categoryRoot = categoryRoot;
    },
    [SET_ACTIVE_CATEGORIES]: (state, activeCategories) => {
        state.activeCategories = activeCategories;
    }
};

function flatDeep (arr, parentId, level) {
    let flattenArr = [];
    level = level ? level : 0;
    arr.forEach(el => {
        flattenArr.push({
            id: el.id,
            name:el.name,
            parentId: parentId,
            level: level
        });
        if(el.children && el.children.length){
            const result = flatDeep(el.children, el.id, level+1);
            result.forEach(el => flattenArr.push(el));
        } 
  });
  return flattenArr;
}

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID,
};
