export const AdminMenu = [
    {
        header: false,
        title: "Main Nav",
        hidden: true,
        hiddenCollapse: true,
    },
    {
        href: "",
        title: "Items",
        icon: {
            element: "span",
            class: "bi bi-boxes",
        },
        child: [
            {
                href: "/admin/all-products",
                title: "All Items",
            },
            {
                href: "/admin/edit-product",
                title: "Add New Item",
                exactPath: true,
            },
        ]
    },
    {
        href: "",
        title: "Users",
        icon: "bi bi-people",
        child: [
            {
                href: "/admin/all-users",
                title: "View All Users",
            },
            {
                href: "/admin/add-user",
                title: "Add New User"
            }
        ],
    },
    {
        href: "",
        title: "Storefront",
        icon: "bi bi-window-fullscreen",
        child: [
            {
                href: "/admin/content-management",
                title: "Content Management"
            }
        ]
    },
    {
        href: "",
        title: "Orders",
        icon: "fa fa-list-ul",
        child: [
            {
                href: "/admin/all-orders",
                title: "All Orders"
            },
            {
                href: "/admin/completed-orders",
                title: "Completed Orders"
            },
            {
                href: "/admin/pending-orders",
                title: "Pending Orders"
            },
            {
                href: "/admin/pending-approval-orders",
                title: "Pending Approval"
            },
            {
                href: "/admin/cancelled-orders",
                title: "Cancelled Orders"
            },
            {
                href: { path: "/admin/bulk-orders" },
                title: "Bulk Orders",
            }
        ],
    },
    {
        href: "",
        title: "Order Approvals",
        icon: "bi bi-patch-check",
        child: [
            {
                href: "/admin/order-approvals",
                title: "All Approvals"
            }
        ]
    },
    {
        href: "",
        title: "Reports",
        icon: {
            element: "span",
            class: "bi bi-graph-up-arrow",
        },
        child: [
            {
                href: "/admin/all-reports",
                title: "All Reports",
            },
            {
                href: "/admin/add-edit-report",
                title: "Add a Report",
                exactPath: true,
            },
        ]
    }
];
