import { mapActions, mapGetters } from "vuex";

import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { GET_ALL_ROLES, GET_ORDERS } from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
import { ALL_ROLES_GETTER, ORDERS_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";

import { OrderStatus, PaymentStatus, ShippingStatus } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export const OrdersMixin = {
    computed: {
        ...mapGetters({
            ordersResponse: ORDERS_GETTER,
            roles: ALL_ROLES_GETTER,
            storeSetup: SITE_SETUP_GETTER
        }),
        hasOrders() {
            return this.ordersResponse && this.ordersResponse.results && this.ordersResponse.results.length > 0;
        },
        isPricingEnabled() {
            return this.storeSetup && this.storeSetup.isPricingEnabled;
        },
        orders() {
            if (this.hasOrders) {
                return this.ordersResponse.results;
            }
            else {
                return [];
            }
        }
    },
    methods: {
        ...mapActions({
            getRoles: GET_ALL_ROLES,
            getOrdersAction: GET_ORDERS
        }),
        buildOrderStatusBadge(orderStatus) {
            const markup = "<span class='badge badge-{0}'>{1}</span>";
            let result;

            switch (orderStatus) {
                case OrderStatus.PENDING:
                    result = markup.replace("{0}", "pending").replace("{1}", "Pending");
                    break;
                case OrderStatus.PENDING_APPROVAL:
                    result = markup.replace("{0}", "pending-approval").replace("{1}", "Pending Approval");
                    break;
                case OrderStatus.CANCELLED:
                    result = markup.replace("{0}", "cancelled").replace("{1}", "Cancelled");
                    break;
                case OrderStatus.COMPLETED:
                    result = markup.replace("{0}", "complete").replace("{1}", "Completed");
                    break;
                default:
                    result = markup.replace("{0}", "dark").replace("{1}", "None");
                    break;
            }

            return result;
        },
        buildPaymentStatusBadge(paymentStatus) {
            const markup = "<span class='badge badge-{0}'>{1}</span>";
            let result;

            switch (paymentStatus) {
                case PaymentStatus.PENDING:
                case PaymentStatus.PARTIAL:
                    result = markup.replace("{0}", "not-paid").replace("{1}", "Not Paid");
                    break;
                case PaymentStatus.COMPLETED:
                    result = markup.replace("{0}", "paid").replace("{1}", "Paid");
                    break;
                default:
                    result = markup.replace("{0}", "dark").replace("{1}", "None");
                    break;
            }

            return result;
        },
        buildShippingStatusBadge(shippingStatus) {
            const markup = "<span class='badge badge-{0}'>{1}</span>";
            let result;

            switch (shippingStatus) {
                case ShippingStatus.PARTIAL:
                    result = markup.replace("{0}", "pending").replace("{1}", "Partially Shipped");
                    break;
                case ShippingStatus.PENDING:
                    result = markup.replace("{0}", "not-shipped").replace("{1}", "Not Shipped");
                    break;
                case ShippingStatus.COMPLETED:
                    result = markup.replace("{0}", "shipped").replace("{1}", "Shipped");
                    break;
                default:
                    result = markup.replace("{0}", "dark").replace("{1}", "None");
                    break;
            }

            return result;
        },
        async getOrders(filters, sort, pageNumber, pageSize) {
            const searchTermFilterId = "search-term";
            const orderDateFilterId = "order-date";
            const primaryRoleFilterId = "primary-role";
            const shippingStatusFilterId = "shipping-status";
            const paymentStatusFilterId = "payment-status";
            const orderStatusFilterId = "order-status";

            const request = {
                filters: [],
                sort: sort,
                paging: {
                    pageNumber: pageNumber - 1,
                    pageSize: pageSize,
                    includeTotalCount: true
                },
            };

            const searchTermFilter = filters.find(f => f.id === "searchTerm");
            const primaryRoleFilter = filters.find(f => f.id === "primaryRole");
            const shippingStatusFilter = filters.find(f => f.id === "shippingStatus");
            const paymentStatusFilter = filters.find(f => f.id === "paymentStatus");
            const dateRangeFilter = filters.find(f => f.id === "dateRange");
            const orderStatusFilter = filters.find(f => f.id === "orderStatus");
            
            if (searchTermFilter) {
                request.filters.push({ filterId: searchTermFilterId, values: [searchTermFilter.value] });
            }

            if (primaryRoleFilter) {
                request.filters.push({ filterId: primaryRoleFilterId, values: primaryRoleFilter.value });
            }

            if (shippingStatusFilter) {
                request.filters.push({ filterId: shippingStatusFilterId, values: [shippingStatusFilter.value] });
            }

            if (paymentStatusFilter) {
                request.filters.push({ filterId: paymentStatusFilterId, values: [paymentStatusFilter.value] });
            }

            if (dateRangeFilter) {
                request.filters.push({ filterId: orderDateFilterId, values: [dateRangeFilter.values[0], dateRangeFilter.values[1]] });
            }

            if (orderStatusFilter) {
                request.filters.push({ filterId: orderStatusFilterId, values: [orderStatusFilter.value] });
            }

            if (filters.primaryRole) {
                request.filters.push({ primaryRoleFilterId, values: [filters.primaryRole] });
            }

            await this.getOrdersAction(request);
        },
        getTrackingUrl(tracking, carrier) {
            switch (carrier.toLowerCase()) {
                case "ups":
                    return "http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=" + tracking;
                case "fedex":
                    return "https://www.fedex.com/fedextrack/?trknbr=" + tracking;
                case "usps":
                    return "https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=" + tracking;
            }
        }
    }
}
