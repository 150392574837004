<template>
  <div>
    <div>
      <strong>
        {{ shippingMethod.estimatedArrival }}
        <span v-if="showPricing"> - {{ shippingMethod.cost | currency }} </span>
      </strong>
      <div>{{ shippingMethod.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShippingMethodView",
  props: {
    shippingMethod: {
      type: Object,
      required: true,
    },
    showPricing: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
