<template>
  <div>
    <div v-if="showTitle" class="d-none d-md-block d-md-block pl-0">
      <p class="pt-2 mb-0 pb-2">{{ title }}</p>
    </div>
    <ul class="alphabet">
      <li v-for="item in letters" :key="item.id">
        <button
          :class="{ active: item.id === activeId && selectedLetter !== '' }"
          class="btn btn-link p-0 btnSelect"
          @click="alphabetSelected(item.id, item.value)"
        >
          {{ item.value }}
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "AlphabetBar",
  props: {
    filterId: {
      type: String,
      required: true,
    },
    filterValues: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeId: 0,
      letters: [
        { id: 1, value: "A" },
        { id: 2, value: "B" },
        { id: 3, value: "C" },
        { id: 4, value: "D" },
        { id: 5, value: "E" },
        { id: 6, value: "F" },
        { id: 7, value: "G" },
        { id: 8, value: "H" },
        { id: 9, value: "I" },
        { id: 10, value: "J" },
        { id: 11, value: "K" },
        { id: 12, value: "L" },
        { id: 13, value: "M" },
        { id: 14, value: "N" },
        { id: 15, value: "O" },
        { id: 16, value: "P" },
        { id: 17, value: "Q" },
        { id: 18, value: "R" },
        { id: 19, value: "S" },
        { id: 20, value: "T" },
        { id: 21, value: "U" },
        { id: 22, value: "V" },
        { id: 23, value: "W" },
        { id: 24, value: "X" },
        { id: 25, value: "Y" },
        { id: 26, value: "Z" },
      ],
    };
  },
  computed: {
    selectedLetter() {
      let vm = this;
      if (this.filterValues && !vm.$nucleus.isEmpty(this.filterValues[0]))
        return this.filterValues[0];
      else return "";
    },
  },
  methods: {
    alphabetSelected(id, value) {
      if (this.activeId == id) {
        if (this.filterValues[0] !== "") {
          this.activeId = 0; // activeID == 0 for deselect
        }
        this.$emit("changed", {
          filterId: this.filterId,
          values: [""],
        });
      } else {
        this.activeId = id;
        this.$emit("changed", {
          filterId: this.filterId,
          values: [value],
        });
      }
    },
  },
};
</script>
<style scoped>
.alphabet li {
  width: 3.84%;
  margin: 0;
  padding: 0;
  border: 1px solid darkgrey;
  font-size: 13px;
  -moz-box-sizing: border-box;
  color: black;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.alphabet {
  list-style-type: none;
  margin: auto 0;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}
.active {
  background: #065baa;
  color: #ffffff !important;
  text-decoration: none;
}

.btnSelect {
  width: 100%;
  cursor: pointer;
  color: #065baa;
  text-decoration: none;
  border-radius: 0;
  font-size: 1rem;
}
.btnSelect:hover {
  background: #065baa;
  color: #ffffff !important;
  text-decoration: none;
  border-radius: 0;
}
</style>
