<template>
  <div v-if="isMenuItemVisible(item) && !item.hidden">
    <template v-if="hasChildren">
      <div>
        <div class="row no-gutters">
          <div class="col-10 n-header-menu-item test">
            <template v-if="item.href">
              <a
                v-if="item"
                class="btn btn-transparent title text-left"             
                :href="item.href"
                target="_blank"
                :style="needPadding ? navigationPadding: ''"
                tag="button"
                >{{ $t(`${item.title}`) }}</a
              >
              <router-link
                v-else
               
                class="btn btn-transparent title text-left"               
                :title="item.title"
                :to="item.href"
                tag="button"
                :style="needPadding ? navigationPadding: ''"
                @click.native="navigate(item.href)"
              >
                {{ item.title }}
              </router-link>
            </template>
            <template v-else>
              <a
                class="btn btn-transparent title text-left"
                :class="item.opened ? 'menuOpened' : ''"
                href="#"
                @click="broadcast(item.eventId)"
                >{{ item.title }}</a
              >
            </template>
          </div>
          <div class="col-2 d-lg-block d-none n-header-menu-item">
            <button
              class="btn btn-transparent n-btn-link-icon"
              :class="item.opened ? 'menuOpened' : ''"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              @click="onNameClicked()"
            >
              <font-awesome-icon
                :icon="item.opened ? 'chevron-up' : 'chevron-down'"
              />
            </button>
          </div>
          <div class="col-2 d-lg-none n-header-menu-item">
            <button
              class="btn btn-transparent n-btn-link-icon"
          
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              @click="onNameClicked()"
            >
              <font-awesome-icon
                :icon="item.opened ? 'chevron-up' : 'chevron-down'"
              />
            </button>
          </div>
        </div>
        <div
          v-if="isOpen"
          class="d-lg-block d-none n-header-dropdown n-header-sub-dropdown"
        >
          <AdminMenuItem
            v-for="child in item.child"
            :key="child.title"
            :item="child"
            :level="level + 1"
            v-on:navigate="navigate(item.href)"
            v-on:closeMenu="closeMenu"
          />
        </div>
        <div
          v-if="isOpen"
          class="d-lg-none n-header-dropdown n-header-sub-dropdown"
        >
          <AdminMenuItem
            v-for="child in item.child"
            :key="child.title"
            :item="child"
            :level="level + 1"
            v-on:navigate="navigate(item.href)"
            v-on:closeMenu="closeMenu"
          />
        </div>
      </div>
    </template>
   <template v-else>
      <div class="row no-gutters">
        <template v-if="item.href">
          <div class="col-12 n-left-menu-item n-header-menu-item">
            <a
              v-if="item.inNewWindow"
              class="btn btn-transparent title text-left n-external-link"
              :href="item.href"
              :style="needPadding ? navigationPadding :'padding-left:6px;'"
              target="_blank"
              tag="button"
              >{{ $t(`helpMenuItems.${item.title}`) }} </a
            >
            <router-link
              v-else             
              class="btn btn-transparent title text-left"
              :style="needPadding ? navigationPadding :'padding-left:6px;'"
              :title="item.title"
              :to="item.href"
              tag="button"
               @click.native="navigate(item.href)"
            >
              {{ item.title }}
            </router-link>
          </div>
        </template>
        <template v-else>
          <div class="col-12 n-header-menu-item">
            <a
              class="btn btn-transparent title text-left"
              href="#"
              @click="broadcast(item.title)"
              >{{ item.title }}</a
            >
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ACTIVE_MENU_ITEMS_GETTER } from "@/store/storefront/getters.type.js";
import { ADD_ACTIVE_ITEM } from "@/store/storefront/actions.type.js";
import MenuValidation from "@/mixins/MenuValidation.js";

export default {
  name: "AdminMenuItem",
  extends: MenuValidation,
  props: {
    item: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    opened: {
      type: Boolean,
      required: false,
    },
    onAdminMenuClicked: {
      type: Function,
      required: false,
    },
    leftNavigattion: {},
  },
  data() {
    return {
      menuOpened: false,
      selectedMenus: [],
    };
  },
  computed: {
    ...mapGetters({
      activeMenuItems: ACTIVE_MENU_ITEMS_GETTER,
    }),
    isOpen() {
      if (this.activeMenuItems.length>0) {
        var activeMenuItem = this.activeMenuItems.find(
          (x) => x.level === this.level && x.title === this.item.title
        );
        return activeMenuItem && activeMenuItem.isOpened;
      }
      return false;
    },
    isMenuOpened() {
      return this.selectedMenus.findIndex((x) => x.title == this.item.title) > -1;
    },
    navigationPadding() {
      let innerWidth = window.innerWidth;
      if(innerWidth<1024){
        return "padding-left:" + (this.level * 15 + 6) + "px";
      }
       else {
         return "padding-left:" + (1 * 15 + 6) + "px";
       }
    },
    hasChildren() {
      return this.item && this.item.child && this.item.child.length > 0;
    },
     needPadding() {
      return this.level >= 1;
    }

  },
  methods: {
    ...mapActions({
      addActiveMenuItem: ADD_ACTIVE_ITEM,
    }),
    onNameClicked() {
      var isOpened = true;
      if (this.activeMenuItems.length>0) {        
        var activeMenuItem = this.activeMenuItems.find(          
          (x) => x.level === this.level && x.title === this.item.title
        );
        if(activeMenuItem!=undefined){
        isOpened = !(activeMenuItem && activeMenuItem.isOpened);
        }
        else{
           this.$emit("onNameClicked")
        }
      }
      this.$emit("onAdminMenuClicked", { title: this.item.title, isOpened: isOpened });

      var menuItem = {
        title: this.item.title,
        level: this.level,
        isOpened: isOpened,
      };
      this.addActiveMenuItem(menuItem);
    },
    broadcast(customEventId) {
      this.$eventBus.$emit(customEventId, {});
    },
    navigate(url) {
      if(!this.$nucleus.isEmpty(url)){
        this.$emit("closeMenu");
      }
    },
    closeMenu() {
      this.$emit("closeMenu");
    }
  },
};
</script>

<style scoped lang="scss">
.menuOpened {
  background-color: $primary-lightest-color !important;
}
.dropdown-toggle::after {
  font-family: "FontAwesome";
  content: "\f078";
  border: none;
  vertical-align: 0;
  color: $primary-color;
}

.router-link-exact-active {
  font-weight: bold !important;
  color: $secondary-color !important;
}
.router-link-exact-active span {
  font-weight: bold !important;
  text-decoration: underline;
}

.show .dropdown-toggle::after {
  font-family: "FontAwesome";
  content: "\f077";
  border: none;
  vertical-align: 0;
  color: $primary-color;
}
@media (min-width: 1200px) {
  .n-header-sub-dropdown {
    margin-top: -47px;
    position: absolute;
  }
}

@media (max-width: 992px) {
  .n-header-sub-dropdown {
    left: 0%;
    position: relative;
  }
}
.n-leftnav-label-wrapper {
  padding: 4px 4px 4px 0;
  font-size: 16px;
}
.n-left-menu-item .btn {
  letter-spacing: normal;
  text-transform: none;
}
.title.n-external-link{
  padding: 10px 20px 7px 20px;
}
</style>
