<template>
  <div>
    <div class="n-signin-method text-center col-12">
      <div class="n-logo d-lg-block my-5 mx-2 flex-fill">
        <HeaderBranding image-url="/images/logo.png" alt="Regeneron" />
      </div>
      <div class="my-4 n-signin-out-title-text">
        <h5 :class="styleClass">{{ title }}</h5>
      </div>
      <div>
        <button type="button" class="btn btn-primary" @click="openIdSignIn">
          Regeneron Employee Sign In
        </button>
      </div>
      <hr class="my-4" />
      <div class="n-not-regeneron-text">
        Not a Regeneron Employee?<a
          class="n-sign-in-link"
          @click="signInCredentials"
          >Sign In</a
        >
      </div>
    </div>
    <div class="n-mt-6 col-12">
      <Footer />
    </div>
  </div>
</template>

<script>
import { DIRECT_LOGIN_PATH } from "@/router/index.js";
import HeaderBranding from "@/nucleus-modules/dd-nucleus-ui/components/HeaderBranding.vue";
import Footer from "@/components/footer/Footer.vue";
export default {
  name: "SignInOut",
  components: {
    HeaderBranding,
    Footer,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    styleClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    async openIdSignIn() {
      try {
        await this.$auth.signInWithRedirect();
      } catch {
        // ignore console errors from OKTA
      }
    },
    signInCredentials() {
      this.$router.push(DIRECT_LOGIN_PATH);
    },
    async openIdSignOut() {
      try {
        await this.$auth.signOut();
      } catch {
        // ignore console errors from OKTA
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.n-not-regeneron-text {
  font-size: 0.875rem;
}
.n-signin-method {
  width: 100%;
  min-height: 300px;
}

.n-signin-method-text {
  color: $primary-dark-color;
  font-weight: 600;
}

.n-signin-out-title-text {
  font-size: 1rem;
}
</style>
