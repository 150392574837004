<template>
    <div>
        <h5 class="n-form-section-header">
            Search Keywords
            <span v-b-tooltip.hover
                  class="primary-color text-left n-learn-more mb-1"
                  title="Enter alternative search keywords that a customer might use to find this item using the eStores omni search.">
                <font-awesome-icon class="n-question-icon" icon="question-circle" />
            </span>
        </h5>
        <div class="row">
            <div class="col">
                <p>Add new keyword or keyword phrase</p>
            </div>
        </div>
        <div class="row">
        <div class="col col-md-4">
            <div class="form-group">
                <input type="text"
                       class="form-control"
                       :class="{ 'is-invalid': $v.newKeyword.$dirty && $v.newKeyword.$invalid }"
                       v-model.trim="newKeyword"
                       v-on:keyup.enter="add(newKeyword)"
                       placeholder="Keyword(s)" />
            </div>
            <div v-if="$v.newKeyword.$dirty && $v.newKeyword.$invalid" class="n-error">
                <span v-if="!$v.newKeyword.required">A keyword is required.</span>
                <span v-if="!$v.newKeyword.minLength">
                    The keyword must have at least
                    {{ $v.newKeyword.$params.minLength.min }} characters.
                </span>
            </div>
        </div>
        <div class="col col-md-2">
            <button class="btn-sm btn-primary" v-on:click="validate(newKeyword)">Add</button>
        </div>
    </div>
        <div class="row mt-2">
            <div class="col">
                <span v-for="keyword in keywords" :key="keyword" class="badge badge-lg badge-pill badge-warning mb-1">
                    {{keyword}} <span class="btn-remove pl-3" v-on:click="remove(keyword)">X</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import { required, minLength } from "vuelidate/lib/validators";
    import { PRODUCT_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
    import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
    export default {
        name: "ProductKeywords",
        data() {
            return {
                newKeyword: '',
                keywords: []
            }
        },
        created: function () {
            this.keywords = (this.product["keywords"] && this.product["keywords"].length)
              ? this.product["keywords"].split(",").sort()
              : []
        },
        computed: {
            ...mapGetters({
                product: PRODUCT_FOR_EDIT_GETTER
            })
        },
        methods: {
            ...mapMutations({
                setProductProperty: SET_PRODUCT_PROPERTY
            }),
            validate(keyword) {
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.add(keyword);
                }
            },
            add(keyword) {
                this.keywords.push(keyword);
                this.resortAndSetKeywords();

                this.newKeyword = '';
                this.$v.newKeyword.$reset();
            },
            remove(keyword) {
                this.keywords = this.keywords.filter(k => k !== keyword);
                this.resortAndSetKeywords();
            },
            resortAndSetKeywords() {
                this.keywords.sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });
                this.setProductProperty({ name: "keywords", value: this.keywords.join(",") });
            }
        },
        validations: {
            newKeyword: {
                required,
                minLength: minLength(3)
            }
        },
    };
</script>

<style lang="scss" scoped>
    .badge-lg {
        font-size: 16px;
    }

    .btn-remove {
        cursor: pointer;
    }
</style>
