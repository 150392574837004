<template>
  <div class="n-ship-to" v-if="address">
    <AddressDetails :address="address"></AddressDetails>

    <div v-if="address.isDefault" class="d-flex n-warning font-weight-bold">
      <div class="flex-shrink-1 pr-1">
        <font-awesome-icon mr-2 class="n-warning" :icon="['fas', 'star']" />
      </div>
      <div class="w-100">
        <span>Default Shipping Address</span>
      </div>
    </div>
  </div>
</template>

<script>

import AddressDetails from "@/nucleus-modules/dd-nucleus-ui/components/address-book/AddressDetails.vue";

export default {
  name: "ShipAddressView",
  components: {
    AddressDetails
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.n-default-address {
  color: $warning;
  font-weight: 700;
  font-size: .875rem;
}
</style>
