const { VUE_APP_OKTA_CLIENT_ID, VUE_APP_OKTA_ISSUER} = process.env
const REDIRECT_URI = `${window.location.origin}/login/callback`;
export default {
    oidc: {
      clientId: VUE_APP_OKTA_CLIENT_ID,
      issuer: VUE_APP_OKTA_ISSUER,
      redirectUri: REDIRECT_URI,
      scopes: ['openid', 'profile', 'email'],
      tokenManager: {
        storage: 'localStorage'
      }
    }
}
