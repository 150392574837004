<template>
    <div>
        <h4 class="mb-3">General Information</h4>
        <div class="row">
            <div class="col">
                <ProductDetails></ProductDetails>
                <hr />
                <div class="mt-4">
                    <ProductSize :unitsOfMeasure="unitsOfMeasure"></ProductSize>
                </div>
            </div>
            <div class="col">
                <ProjectDetails :defaultBlsProjectNumber="defaultBlsProjectNumber"></ProjectDetails>
                <hr />
                <div class="mt-4 n-product-general-information">
                    <ProductTags :availableTags="availableTags" :disableTags="disableTags"></ProductTags>
                    <b-form-checkbox :disabled="true" :checked="isFeaturedItem">
                        Featured Item
                    </b-form-checkbox>
                    <AddonInformation @changed="changed"></AddonInformation>
                    <b-form-checkbox v-model="isLimitRestricted">
                        Limit Exceed Restricted
                    </b-form-checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from "vuex";
    import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
    import ProductDetails from "@/components/admin/product/edit-product/ProductDetails.vue";
    import ProductSize from "@/components/admin/product/edit-product/ProductSize.vue";
    import ProjectDetails from "@/components/admin/product/edit-product/ProjectDetails.vue";
    import ProductTags from "@/components/admin/product/edit-product/ProductTags.vue";
    import AddonInformation from "@/components/admin/product/edit-product/AddonInformation.vue";
    import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";

    const ATTRIBUTE_NAME = "ExtendedProductProperties";

    export default {
        name: "GeneralInformation",
        components: {
            ProductDetails,
            ProductSize,
            ProjectDetails,
            ProductTags,
            AddonInformation
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                disableTags: false,
                isLimitExceedRestricted: false
            }
        },
        computed: {
            ...mapGetters({
                siteSetup: SITE_SETUP_GETTER
            }),
            availableTags() {
                return this.siteSetup.availableTags;
            },

            defaultBlsProjectNumber() {
                return this.siteSetup.constants.defaultBlsProjectNumber;
            },
            unitsOfMeasure() {
                return this.siteSetup.unitsOfMeasure;
            },
            isFeaturedItem() {
                let vm = this;
                return vm.product
                    && vm.product.attributes
                    && vm.product.attributes.FeaturedProductForUserRoles
                    && vm.product.attributes.FeaturedProductForUserRoles.featuredProductUserRoleIds
                    && vm.product.attributes.FeaturedProductForUserRoles.featuredProductUserRoleIds.length > 0;
            },

            isLimitRestricted: {
                get() {
                    return this.product?.attributes?.ExtendedProductProperties?.isLimitRestricted;
                },
                set(value) {
                    if (this.$nucleus.isEmpty(value)) {
                        value = this.isLimitExceedRestricted;
                    }
                    console.warn('value', value);
                    this.setProductProperty({ name: "isLimitRestricted", value: value, attribute: ATTRIBUTE_NAME });
                },
            }
        },
        methods: {
            ...mapMutations({
                setProductProperty: SET_PRODUCT_PROPERTY,
            }),

            changed(value) {
                this.disableTags = value;
                this.$emit("changed", value);
            }
        }
    };
</script>
<style scoped>
    ::deep .custom-control-label {
        font-size: 1rem;
    }
</style>
