<template>
    <PageTemplate>
        <div class="page-container">
            <div class="row mt-2">
                <div class="col-lg-3 d-none d-lg-block">
                    <AccountMenu :title="title" menu-id="account"> </AccountMenu>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12">
                    <h3 class="n-page-header">My Orders</h3>
                    <div v-if="!this.isLoading && $nucleus.isEmpty(mostRecentOrder)">
                        There is no order history available for this account.
                    </div>
                    <Headline title="Most Recent Order" />
                    <OrderHistoryItem :order="mostRecentOrder"
                                      v-if="!$nucleus.isEmpty(mostRecentOrder)" />
                    <Headline title="Previous Orders" />
                    <template v-if="hasOrders">
                        <div v-for="(group, index) in groupedOrders"
                             :key="index"
                             class="pb-4">
                            <OrderHistoryPanel :orders="group.orders"
                                               :message="group.message" />
                        </div>
                    </template>
                </div>
                <div class="col-lg-1 col-md-0 col-sm-0"></div>
            </div>
        </div>
    </PageTemplate>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import Headline from "@/nucleus-modules/dd-nucleus-ui/components/Headline.vue";

    import { SEARCH_ORDERS } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
    import { SEARCH_ORDERS_RESULTS_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
    import OrderHistoryItem from "@/nucleus-modules/dd-nucleus-storefront/components/OrderHistoryItem.vue";
    import OrderHistoryPanel from "@/nucleus-modules/dd-nucleus-storefront/components/OrderHistoryPanel.vue";
    import AccountMenu from "@/components/AccountMenu.vue";
    import PageTemplate from "@/pages/templates/PageTemplate.vue";

    export default {
        name: "OrderHistory",
        components: {
            Headline,
            OrderHistoryItem,
            OrderHistoryPanel,
            PageTemplate,
            AccountMenu,
        },
        data() {
            return {
                title: "My Account",
                isLoading: true,
                groupedOrders: [],
            };
        },
        computed: {
            ...mapGetters({
                orders: SEARCH_ORDERS_RESULTS_GETTER,
            }),
            mostRecentOrder() {
                if (this.hasOrders) {
                    return this.sortedOrders[0];
                } else {
                    return {};
                }
            },
            hasOrders() {
                return this.orders && this.orders.items && this.orders.items.length > 0;
            },
            sortedOrders() {
                if (this.hasOrders) {
                    let orders = this.orders.items;
                    orders = orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                    return orders;
                }
                return [];
            }
        },
        methods: {
            ...mapActions({
                searchOrders: SEARCH_ORDERS,
            }),
            getOrdersByYear() {
                if (this.hasOrders) {
                    // This method is looking though the the order data to find all the distinct years in the data
                    let distinctYears = [
                        ...new Set(
                            this.sortedOrders.map((event) =>
                                new Date(event.orderDate).getFullYear()
                            )
                        ),
                    ];
                    // After all of the distinct years are found set up the data for rendering the page is called
                    this.setupDataForPage(distinctYears);
                }
            },
            setupDataForPage(distinctYears) {
                let tempOrders = {};
                // First loop through and get all the order data per year
                for (var i = 0; i < distinctYears.length; i++) {
                    let tempYear = distinctYears[i];
                    let tempOrderData = this.sortedOrders.filter(
                        (order) => new Date(order.orderDate).getFullYear() === tempYear
                    );
                    tempOrderData = tempOrderData.sort(function (a, b) {
                        return (
                            new Date(a.orderDate).getTime() - new Date(b.orderDate).getTime()
                        );
                    });

                    // Determine if we should use the plural on orders
                    var ordersText = tempOrderData.length > 1 ? "orders" : "order";
                    // Set the data for each year as it loops through

                    tempOrders[tempYear] = {
                        message: ordersText + " placed in " + tempYear,
                        orders: tempOrderData,
                    };
                }

                var tempKey = Object.keys(tempOrders)
                    .sort()
                    .reverse();
                tempKey.forEach((key) => {
                    this.groupedOrders.push(tempOrders[key]);
                });
            },
        },
        async mounted() {
            await this.searchOrders();
            this.getOrdersByYear();
            this.isLoading = false;
        },
    };
</script>

<style lang="scss" scoped>
    .page-container {
        width: 100%;
        font-size: 0.85rem;
    }
</style>
