<template>
  <div id="nucleusOverlay" class="n-overlay-backdrop"></div>
</template>

<script>
export default {
  name: "Overlay",
  props: {
    allowHeader: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.allowHeader) {
      const overlay = document.querySelector("#nucleusOverlay");
      const header = document.querySelector("#fullHeader");

      if (!overlay || !header) return;

      const headerZIndex = window.getComputedStyle(header).zIndex;

      overlay.style.zIndex = headerZIndex - 1 <= 0 ? 1000 : headerZIndex - 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.n-overlay-backdrop {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
</style>
