<template>
  <div>
    <div v-if="address.organization">
      <strong class="card-title">{{ address.organization }}</strong>
    </div>
    <div>
      <strong class="card-title">{{ address.firstName }} {{ address.lastName }}</strong>
    </div>
    <div class="card-subtitle">{{ address.addressLine1 }}</div>
    <div class="card-subtitle">{{ address.addressLine2 }}</div>
    <div class="card-subtitle">{{ address.city }} {{ address.state }} {{ address.postalCode }}</div>
    <div class="card-subtitle mb-2">{{ address.phoneNumber }}</div>
  </div>
</template>

<script>
export default {
  name: "AddressDetails",

  props: {
    address: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.card-subtitle {
  line-height: 1.8;
  color: $neutral-darker;
}

.card-title {
  color: $neutral-darker !important;
  text-transform: none;
}
</style>
