import store from "./store";
import cookieHandler from "vue-cookies";

// global functions and helpers
export default {
    compare(obj1, obj2) {
        if (typeof (obj1) !== typeof (obj2)) return false;
        //Loop through properties in object 1
        for (var p in obj1) {
            //Check property exists on both objects
            if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                return false;
            }

            if (obj1[p] === null && obj2[p] !== null) {
                return false;
            }

            if (obj2[p] === null && obj1[p] !== null) {
                return false
            }

            switch (typeof (obj1[p])) {
                //Deep compare objects
                case 'object':
                    if (!this.compare(obj1[p], obj2[p])) {
                        return false
                    }
                    break;
                //Compare function code
                case 'function':
                    if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) {
                        return false
                    }
                    break;
                //Compare values
                default:
                    if (obj1[p] != obj2[p]) {
                        return false
                    }
            }
        }

        //Check object 2 for any extra properties
        for (var q in obj2) {
            if (typeof (obj1[q]) == 'undefined') {
                return false;
            }
        }
        return true;
    },
    createRange(start, stop, step) {
        return Array.from({ length: (stop - start) / step + 1 }, (_, index) => start + (index * step));
    },
    debounce(fn, delay) {
        var timeoutID = null;

        return function () {
            clearTimeout(timeoutID);

            var args = arguments;
            var that = this;

            timeoutID = setTimeout(function () {
                fn.apply(that, args);
            }, delay);
        };
    },
    getCookie(key) {
        return cookieHandler.get(key);
    },
    getStorageItem(key, isSession) {
        return isSession ? sessionStorage.getItem(key) : localStorage.getItem(key);
    },
    isEmpty(value) {
        return value === undefined || value === null ||
            (typeof value === 'object' && Object.keys(value).length === 0) ||
            (typeof value === 'string' && value.trim().length === 0) ||
            (Array.isArray(value) && value.length === 0)
    },
    registerStoreModule(moduleId, module) {
        if (!store.hasModule(moduleId)) {
            store.registerModule(moduleId, module);
        }
    },
    deregisterStoreModule(moduleId) {
        if (store.hasModule(moduleId)) {
            store.unregisterModule(moduleId);
        }
    },
    removeCookie(key) {
        cookieHandler.remove(key);
    },
    removeAllCookies() {
        let cookies = cookieHandler.keys();
        if(cookies && cookies.length){
            cookies.forEach(key => {
                cookieHandler.remove(key);
            })
        }
    },
    removeAllStorageItems() {
        localStorage.clear();
        sessionStorage.clear();
    },
    removeStorageItem(key, isSession) {
        if (isSession) {
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    },
    setCookie(key, value, expiresTime) {
        cookieHandler.set(key, value, expiresTime);
    },
    setStorageItem(key, value, isSession) {
        if (isSession) {
            sessionStorage.setItem(key, value);
        } else {
            localStorage.setItem(key, value);
        }
    }
}
