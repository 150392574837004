<template>
    <div>
        <div v-if="address.companyName">
            <strong>{{ address.companyName }}</strong>
        </div>
        <div v-if="!hideName && address.addressee">
            <strong>{{ address.addressee }}</strong>
        </div>
        <strong v-if="address.attention">
            {{ address.attention }}
        </strong>
        <div>{{ address.addressLine1 }}</div>
        <div>{{ address.addressLine2 }}</div>
        <div>{{ address.city }}, {{ address.state }} {{ address.postalCode }}</div>
    </div>
</template>

<script>
    export default {
        name: "AddressView",
        props: {
            address: {
                type: Object,
                required: true
            },
            hideName: {
                type: Boolean,
                required:false
            }
        },
        computed: {
            hasName() {
                return this.address && this.address.firstName && this.address.lastName;
            }
        }
    };
</script>