import configuredLocales from "@/locales/configuredLocales.js";

export function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...options };

    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

    if (!navigatorLocale) return undefined;

    const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();

    return trimmedLocale;
}

export function getSupportedLocales() {
    const annotatedLocales = [];

    for (const code of Object.keys(configuredLocales)) {
        annotatedLocales.push({ code, name: configuredLocales[code].text, flagCode: configuredLocales[code].flagCode });
    }

    return annotatedLocales;
}

export function isLocaleSupported(locale) {
    return Object.keys(configuredLocales).includes(locale);
}
