<template>
  <div :class="sizeClass">
    <div class="loader text-center">
      <b-spinner variant="dark" label="Loading"></b-spinner>
      <div>{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    message: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    sizeClass() {
      if (this.size === "panel") {
        return "loader-panel";
      }
      return "loader-full";
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-full {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.loader-panel {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9998;
  top: 0;
  left: 0;
}
.loader {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
}
</style>
