<template>
    <div :class="{ 'modal-root': modal.component }">
        <transition name="modal">
            <component 
                v-if="modal"
                :is="modal.component"
                :close="modal.close"
                :dismiss="modal.dismiss"
                v-bind="modal.props"
                :class="{ 'd-block': modal.component }"
            />
        </transition>
    </div>
</template>

<script>
import ModalService from '@/nucleus-modules/dd-nucleus-ui/services/modal.service';
import Modal from '@/nucleus-modules/dd-nucleus-ui/components/modal/Modal';

export default {
    components: {
        Modal
    },
    data() {
        return {
            modal: {}
        }
    },
    created() {
        ModalService.$on('openModal', ({ component, props}) => {
            this.modal = {
                component,
                props,
                close: () => {
                    this.modal = {};
                },
                dismiss: () => {
                    this.modal = {};
                }
            };
        });
        ModalService.$on('closeModal', () => {
            this.modal = {};
        });
    }
}
</script>

<style scoped>
.modal-root {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}
.modal-enter-active{
  transition: all 0.25s ease-in;
}
.modal-leave-active {
  transition: all 0.25s ease-out;
}
.modal-enter,
.modal-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>