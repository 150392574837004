var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminTemplate',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('h3',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('span',[_c('router-link',{attrs:{"to":"all-reports"}},[_vm._v("Back to All Reports")])],1)])])]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 text-right"},[_c('router-link',{staticClass:"btn btn-outline-primary mr-2",attrs:{"to":"all-reports"}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.saveReport}},[_vm._v("Save")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('hr')])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Report Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Report Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reportName),expression:"reportName"}],staticClass:"form-control n-text-field-highlight",class:{ 'is-invalid':  errors && errors.length},attrs:{"type":"text","id":"reportName","name":"reportName","placeholder":"Report Name"},domProps:{"value":(_vm.reportName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reportName=$event.target.value}}}),(errors && errors.length)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"rules":"max:300","name":"report description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description "),_c('span',[_vm._v("(optional)")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reportDescription),expression:"reportDescription"}],staticClass:"form-control n-text-field-highlight",class:{ 'is-invalid':  errors && errors.length},attrs:{"id":"reportDescription","name":"reportDescription","rows":"4","placeholder":"Brief description describing the purpose of the report"},domProps:{"value":(_vm.reportDescription)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reportDescription=$event.target.value}}}),(errors && errors.length)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_c('div',[(_vm.reportDescription.length == 0)?_c('small',{staticClass:"form-text text-muted"},[_vm._v("300 character Limit")]):_c('small',{staticClass:"form-text text-muted"},[_vm._v("Characters Remaining "+_vm._s(_vm.remainingCharacters))])])])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Report Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('label',[_vm._v("Type")])]),_c('b-form-select',{staticClass:"n-text-field-highlight mb-3",class:{ 'is-invalid': errors && errors.length},attrs:{"options":_vm.reportTypeOptions,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select Report Type")])]},proxy:true}],null,true),model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}}),(errors && errors.length)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","name":"URL Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("URL Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reportUrl),expression:"reportUrl"}],staticClass:"form-control n-text-field-highlight",class:{ 'is-invalid':  errors && errors.length},attrs:{"type":"text","id":"urlLink","name":"urlLink","placeholder":"Report URL Link"},domProps:{"value":(_vm.reportUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.reportUrl=$event.target.value}}}),(errors && errors.length)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('div',{staticClass:"form-group"},[_c('div',[_c('label',[_vm._v("Permissions")])]),_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"publicOrPrivate","value":"true"},on:{"input":_vm.changeOption},model:{value:(_vm.isPublic),callback:function ($$v) {_vm.isPublic=$$v},expression:"isPublic"}},[_c('font-awesome-icon',{staticClass:"n-image-icons",attrs:{"icon":['fas','users']}}),_vm._v(" Make report public (available for others to use)")],1),(_vm.isReportPublic)?_c('b-form-group',{staticClass:"ml-5"},[_c('b-form-radio',{attrs:{"name":"reportRoles","value":"all"},model:{value:(_vm.reportRoles),callback:function ($$v) {_vm.reportRoles=$$v},expression:"reportRoles"}},[_vm._v("Make visible for all roles")]),_c('b-form-radio',{attrs:{"name":"reportRoles","value":"specific"},model:{value:(_vm.reportRoles),callback:function ($$v) {_vm.reportRoles=$$v},expression:"reportRoles"}},[_vm._v("Make visible to specific role(s)")]),(_vm.isReportAllRoles)?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Select all roles that can view items associated to this new report.")])]):_vm._e(),(_vm.isReportAllRoles)?_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required","name":"At least one role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{class:{ 'is-invalid': errors && errors.length},attrs:{"id":"checkbox-group-1","options":_vm.roleOptions,"value-field":"id","text-field":"name","name":"specificRoles"},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}}),(errors && errors.length)?_c('div',{staticClass:"mt-2 invalid-feedback"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,2674886385)})],1):_vm._e()],1):_vm._e(),_c('b-form-radio',{attrs:{"name":"publicOrPrivate","value":"false"},model:{value:(_vm.isPublic),callback:function ($$v) {_vm.isPublic=$$v},expression:"isPublic"}},[_c('font-awesome-icon',{staticClass:"n-image-icons",attrs:{"icon":['fas','user-alt']}}),_vm._v(" Make report private (only available for your use)")],1)],1)],1)],1)],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 text-right"},[_c('router-link',{staticClass:"btn btn-outline-primary mr-2",attrs:{"to":"all-reports"}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.saveReport}},[_vm._v("Save")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }