<template>
  <div>
    <h2>Select Ship Method</h2>
    <hr />
    <div class="row mt-3 mb-3">
      <div class="col-4">
        <div class="form-group">
          <label>Shipping Method</label>
          <select
            v-model="selectedShipMethod"
            @change="update"
            class="form-control n-text-field-highlight"
          >
            <option
              v-for="shipMethod in shipMethods"
              :key="shipMethod.code"
              :value="shipMethod"
            >
              {{ shipMethod.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BulkOrdersMixin } from "@/nucleus-modules/dd-nucleus-admin/mixins/BulkOrdersMixin.js";

export default {
  name: "SelectShipMethod",
  mixins: [BulkOrdersMixin],
  props: {
    shipMethods: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedShipMethod: null,
    };
  },
  methods: {
    update() {
      this.updateShippingMethod(this.selectedShipMethod);
      this.resetBulkOrderErrors();
    },
  },
  mounted() {
    this.selectedShipMethod = this.shipMethods[0];

    this.update();
  },
};
</script>

<style lang="scss" scoped></style>
