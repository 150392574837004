export const ADMIN_MENU_GETTER = "adminMenu";

// bulk orders
export const BULK_ORDER_DETAIL_GETTER = "bulkOrderDetail";
export const BULK_ORDER_USERS_GETTER = "bulkOrderUsers";
export const GET_BULK_ORDER_DETAIL_RESPONSE_GETTER = "getBulkOrderDetail";
export const PLACE_BULK_ORDER_ERRORS_GETTER = "placeBulkOrderErrors";
export const PLACE_BULK_ORDER_RESPONSE_GETTER = "placeBulkOrderResponse";
export const RECIPIENTS_SEARCH_RESPONSE_GETTER = "bulkOrderRecipientsSearchResults";
export const SEARCH_BULK_ORDERS_RESPONSE_GETTER = "bulkOrdersSearchResponse";

// products
export const ALL_PRODUCTS_GETTER = "allProducts";
export const IS_PRODUCT_EDIT_DIRTY_GETTER = "isProductEditDirty";
export const PRODUCT_FOR_EDIT_GETTER = "productForEdit";
export const PRODUCT_PROPERTY_GETTER = "productProperty";
export const SAVE_PRODUCT_ERROR_GETTER = "saveProductError";
export const SEARCH_PRODUCTS_RESULTS_GETTER = "searchProductsResults-admin";

// stepper
export const EDIT_PRODUCT_STEPPER_GETTER = "editProductStepper";

// order 
export const GET_ORDERS_ERRORS_GETTER = "getOrdersErrors";
export const ORDER_DETAIL_GETTER = "orderDetails";
export const ORDER_PROPERTY_GETTER = "orderProperty";
export const ORDERS_GETTER = "orders";
export const ORDERS_ERRORS_GETTER = "ordersErrors";
export const SEARCH_ORDERS_RESPONSE_GETTER = "ordersSearchResponse";
export const GET_ORDER_DETAIL_RESPONSE_GETTER = "getOrderDetail";

// roles
export const ALL_ROLES_GETTER = "allRoles";

// users
export const IS_USER_EDIT_DIRTY_GETTER = "isUserEditDirty";
export const SAVE_USER_ERROR_GETTER = "saveUserError";
export const USER_FOR_EDIT_GETTER = "userForEdit";
export const USER_PROPERTY_GETTER = "userProperty";
export const USER_SEARCH_RESULTS_GETTER = "userSearchResults";
export const USERS_GETTER = "users";

//customer segments
export const COMPANY_SEGMENTS_GETTER = "companySegmentsGetter";

//inventory sync
export const LAST_INV_SYNC_TIME_GETTER = "lastInvLastSyncTimeGetter";

// site setup
export const STATES_LIST_GETTER = "adminStatesList";
export const SITE_SETUP_GETTER = "siteSetup";

// announcement
export const ANNOUNCEMENT_FOR_EDIT_GETTER = "announcementForEdit";
export const ANNOUNCEMENT_SEARCH_RESULTS_GETTER = "announcementSearchResults";
export const ANNOUNCEMENT_DELETERESPONSE_GETTER = "deleteAnnouncementResponse";
export const SAVE_ANNOUNCEMENT_ERROR_GETTER = "saveAnnouncementError";
export const DELETE_ANNOUNCEMENT_ERROR_GETTER = "deleteAnnouncementError";
export const IS_ANNOUNCEMENT_EDIT_DIRTY_GETTER = "isAnnouncementEditDirty";

export const AUDIT_FIELDS_GETTER = "auditFileds";


