<template>
  <span class="n-uom">
    {{ label }}
  </span>
</template>

<script>
// Provides a display of UOM that takes into account the unit quantity
// Examples:
//    Pack/100
//    Each              <- No unit quantity shown as it is 1

export default {
  name: "UnitOfMeasureDisplay",
  props: {
    // Unit of measure name
    uom: {
      type: String,
      required: true,
    },
    // Qty per unit of measure
    unitQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    label() {
      if ((this.unitQuantity || 0) <= 1) return this.uom;
      else return this.uom + "/" + this.unitQuantity;
    },
  },
};
</script>

<style scoped lang="scss">
</style>