<template>
  <div class="card n-is-default h-100">
    <div class="card-body">
      <slot name="accountInfo"></slot>
    </div>
    <div class="card-footer">
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "accountTile",
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 0.5rem;
  border: 1px solid $neutral-mid-light;
}
.card-body {
  padding: 0.75rem 1.25rem;
}
.n-is-default {
  border: 2px solid $primary-color;
}
.card-footer {
  padding: 0rem 1.25rem 0.75rem 1.25rem;
  border-top: none;
  background: none;
}
</style>
