<template>
  <div class="row">
    <div class="col-12 text-center n-footer-info">
      {{ $t("footer.contactAddress") }} <br />
      {{ $t("footer.rights", {year: currentYear}) }} 
      | <a :href="companyWebsite" target="_blank">{{ $t("footer.website") }}</a> 
      | <a :href="termsOfUseLink" target="_blank">{{ $t("footer.terms") }}</a> 
      | <a :href="privacyPolicyLink" target="_blank">{{ $t("footer.privacy") }}</a>
    </div>
  </div>
</template>

<script>
import {companyWebsite, privacyPolicyLink, termsOfUseLink} from "@/companyAppConfig"
export default {
  name: "FooterInfo",
  data(){
    return {
      companyWebsite: companyWebsite,
      privacyPolicyLink: privacyPolicyLink,
      termsOfUseLink: termsOfUseLink,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style scoped lang="scss">
.n-footer-info {
  color: $neutral-darker;
  font-size: .875rem;
}

</style>
