// Client-specific constants
export const AvailableBusinessUnits = [
    "Ophthalmology",
    "Market Access",
    "Immunology",
    "Oncology",
    "Cardiometabolic"
];

export const AvailableProducts = [
    "Dupixent",
    "Evkeeza",
    "Eylea",
    "Libtayo"
];

export const AvailableStatuses = [
    "Active",
    "Inactive",
    "Leave of Absence"
];

export const AvailableTerritories = [
    "Area",
    "District",
    "Nation",
    "Region",
    "Territory"
];

export const AvailableBrands = [
    "Corporate",
    "Dupixent",
    "Dupixent AD",
    "Dupixent Asthma",
    "Evkeeza",
    "Eylea",
    "Eylea CRM",
    "Eylea OD FF Program",
    "Libtayo",
    "Praluent"
];

export const AvailableSubCategoryTypes = [
    "Access Services",
    "Consumer",
    "Corporate",
    "Corporate Communications",
    "CRM",
    "HCP",
    "Market Access",
    "Training",
    "Branded",
    "Unbranded"
];

export const AvailableProductTypes = [
    "Branded",
    "Unbranded"
];

export const AvailableSpendProductArea = [
    "Dupixent (DUPILUMAB)",
    "Evkeeza",
    "Eylea (AFLIBERCEPT) Injection",
    "Libtayo (CEMIPLIMAB-RWLC)",
    "Praluent (ALIROCUMAB) Injection",
    "REGN1500",
    "REGN2810",
    "Zaltrap (ZIV-AFLIBERCEPT) Injection for Intravenous Infusion"
];

export const AvailableSpendPurpose = [
    "Anatomical Model",
    "Demonstration Unit",
    "Reprint"
]

export const AvailableSpendNature = [
    "Educational Item",
    "Demonstration Unit"
]



export const UserSource = Object.freeze({
    MANUAL: "Manual"
});

export const Cookies = Object.freeze({
    CART_ID_KEY: "cart-id",
    CONSENT_ID_KEY: "consent-id"
});

export const Events = Object.freeze({
    SEARCH_OPTIONS_CHANGED: "search-options-changed",
});

export const FilterIds = Object.freeze({
    CATEGORY: "category",
    DATE_ADDED: "added",
    SEARCH_TERM: "search-term",
    TAGS: "tags",
    ITEM_STATUS:'item-status',
});

export const RECAPTCHA_KEY = "6LdRmg0bAAAAABQWk656OGLYCd6_w_xgEcoFfbOg";
export const COOKIE_CONSENT_VALID_FOR_NUMBER_OF_DAYS = 365;

export const AddressType = Object.freeze({
    NONE: 0,
    ACCOUNT: 1,
    SHIPPING: 2
});


export const MyPasswordMessageIDs = Object.freeze({
    UPDATE_PASSWORD_FAILED: "Regeneron.Storefront.UpdatePasswordFailed",
    ACCESS_DENIED: "DD.Nucleus.Base.AccessDenied",
    NOT_FOUND:"DD.Nucleus.Base.InvalidValue",
});

export const ListOfTopics = [
    "Account Issue",
    "Feedback",
    "Order Status/Tracking",
    "Placing an Order",
    "Other"
];
export const ListOfItemType = Object.freeze([
    { id: 0, name: "Physical" },
    { id: 1, name: "POD (Print on Demand)" },
]);

export const DefaultAddOnItemQuantity =  1000000;

export const AttributeValueIndication =  'Indication';
export const AttributeValueIntendedUse =  'IntendedUse';
export const AttributeValueBrand = 'ProductThearaputicArea';
export const AttributeValueBrandTeam = 'BrandTeam';
export const AttributeValueTeamName = 'TeamName';

export const RolesArrayConst = ['APPDEV','SA','CSDADMIN']; 
export const VeevaSyncConst = 'veeva';
export const BlsSyncConst = 'bls';