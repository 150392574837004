import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";
import {
    CRM_ADDRESS_SORTING_CONFIG_GETTER,
    CRM_ADDRESS_PER_PAGE_CONFIG_GETTER,
    CRM_ADDRESS_SEARCH_RESULTS_GETTER,
    CRM_ADDRESS_FILTERS_GETTER,
} from "@/store/storefront/getters.type.js";
import {
    ADD_FAVORITE_ADDRESS,
    ASSIGN_CRM_SHIPPING_ADDRESS,
    CRM_ADDRESS_ADD_FILTER,
    CRM_ADDRESS_RESET_ALL_FILTERS,
    CRM_ADDRESS_UPDATE_FILTER,
    REMOVE_FAVORITE_ADDRESS,
    SEARCH_CRM_ADDRESSES,
} from "@/store/storefront/actions.type.js";

import { SET_CRM_ADDRESS_SEARCH_RESULTS, SET_CRM_ADDRESS_FILTERS, SET_ADDRESS_AS_FAVORITE, UNSET_ADDRESS_AS_FAVORITE } from "@/store/storefront/mutations.type.js";

const MODULE_ID = "crm-address";

const initialState = {
    filters: [],
    crmAddressSearchResults: {},
    sortingConfig: {
        defaultSort: "sort_by_businessname",
        options: [
            {
                id: 'sort_by_businessname',
                name: 'Sort By Business Name',
            },
            {
                id: 'sort_by_lastname',
                name: 'Sort By Last Name',
            },
            {
                id: 'sort_by_firstname',
                name: 'Sort By First Name',
            },
            {
                id: 'sort_by_city',
                name: 'Sort By City',
            },
            {
                id: 'sort_by_state',
                name: 'Sort By State',
            },
            {
                id: 'sort_by_postalcode',
                name: 'Sort By Postal Code',
            },
        ]
    },
    perPageConfig: {
        defaultPerPage: 25,
        options: [
            {
                id: 25,
                name: "25 per page"
            },
            {
                id: 50,
                name: "50 per page"
            },
            {
                id: 75,
                name: "75 per page"
            },
            {
                id: 100,
                name: "100 per page"
            },
            /*{
                id: 500,
                name: "500 per page"
            },
            {
                id: 1000,
                name: "1000 per page"
            },*/

        ]
    }


};

export const state = { ...initialState };

export const getters = {

    [CRM_ADDRESS_SORTING_CONFIG_GETTER]: state => {
        return state.sortingConfig;
    },
    [CRM_ADDRESS_PER_PAGE_CONFIG_GETTER]: state => {
        return state.perPageConfig;
    },
    [CRM_ADDRESS_SEARCH_RESULTS_GETTER]: state => {
        return state.crmAddressSearchResults;
    },
    [CRM_ADDRESS_FILTERS_GETTER]: state => {
        return state.filters;
    },

};

export const actions = {
    async [ASSIGN_CRM_SHIPPING_ADDRESS](_, payload) {
        const response = await ApiService.put(`ui/cart/${payload.cartId}/crm-shipping-address/${payload.crmContactId}`);

        return response;
    },
    async [SEARCH_CRM_ADDRESSES]({ commit }, payload) {
        const response = await ApiService.post("ui/crm-contacts/regeneron/search", payload);
        if (response.succeeded) {
            commit(SET_CRM_ADDRESS_SEARCH_RESULTS, {
                items: response.results,
                pageNumber: response.pageNumber,
                pageSize: response.pageSize,
                count: response.count,
                totalCount: response.totalCount
            });
        }
        else {
            // TODO: add a system error?
        }
    },
    async [CRM_ADDRESS_ADD_FILTER]({ commit }, payload) {
        if (!state.filters.find((f) => f.filterId === payload.filterId)) {
            const newFilters = state.filters.concat(payload);
            commit(SET_CRM_ADDRESS_FILTERS, newFilters);
        }
    },
    async [CRM_ADDRESS_UPDATE_FILTER]({ commit }, payload) {
        const filterIndex = state.filters.findIndex((f) => f.filterId === payload.filterId);

        if (filterIndex !== -1) {
            const updatedFilters = [...state.filters];
            updatedFilters[filterIndex] = { filterId: payload.filterId, values: payload.values };

            commit(SET_CRM_ADDRESS_FILTERS, updatedFilters);
        }
    },
    async [CRM_ADDRESS_RESET_ALL_FILTERS]({ commit }) {
        state.filters.forEach(filter => {
            filter.values = []
        });
        commit(SET_CRM_ADDRESS_FILTERS, state.filters);
    },
    async [ADD_FAVORITE_ADDRESS]({ commit }, payload) {
        const response = await ApiService.post(`/ui/crm-contact/${payload.addressId}/tags`, { crmContactTag: { tagName: payload.tagName } }, { showLoader: false });

        if (response.succeeded) {
            commit(SET_ADDRESS_AS_FAVORITE, payload.addressId);
        }
        else {
            // TODO: give feedback to user?
        }
    },
    async [REMOVE_FAVORITE_ADDRESS]({ commit }, payload) {
        const response = await ApiService.delete(`/ui/crm-contact/${payload.addressId}/tag/${payload.tagName}`, { data: payload }, { showLoader: false });

        if (response.succeeded) {
            commit(UNSET_ADDRESS_AS_FAVORITE, payload.addressId);
        }
        else {
            // TODO: give feedback to user?
        }
    },

}
export const mutations = {
    [SET_CRM_ADDRESS_SEARCH_RESULTS]: (state, crmAddressSearchResults) => {
        state.crmAddressSearchResults = crmAddressSearchResults;
    },
    [SET_CRM_ADDRESS_FILTERS]: (state, filters) => {
        state.filters = filters;
    },
    [SET_ADDRESS_AS_FAVORITE]: (state, addressId) => {
        const address = state.crmAddressSearchResults.items.find((p) => p.id === addressId);

        if (address) {
            address.isFavorite = true;
            state.crmAddressSearchResults = Object.assign({}, state.crmAddressSearchResults);
        }

    },
    [UNSET_ADDRESS_AS_FAVORITE]: (state, addressId) => {
        const address = state.crmAddressSearchResults.items.find((p) => p.id === addressId);

        if (address) {
            address.isFavorite = false;
        }
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
