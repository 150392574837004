import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
// import { localize } from 'vee-validate';
//import { required, regex } from 'vee-validate/dist/rules';
import * as rules from 'vee-validate/dist/rules';
import { formValidationMessages } from "@/locales/en.json";
 let ruleObj = {}

 Object.keys(rules).forEach(rule => {
     ruleObj[rule] = formValidationMessages[rule]
     extend(rule, {
         ...rules[rule], // copies rule configuration
         message: formValidationMessages[rule] // assign message
     });
 });
const zipCodeRegExp = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
extend('zipcode', {
    message: field => `${field} must have at least five (5) numbers`,
    validate(value) {
        return zipCodeRegExp.test(value)
    }
});

const nameRegExp = new RegExp("^[a-zA-Z]+(([\\'\\,\\.\\- ][a-zA-Z ])?[a-zA-Z]*)*$");

extend('nameValidation', {
    message: 'Please remove any special characters or numbers.',
    validate(value) {
        return nameRegExp.test(value)
    }
});

extend('one_of', (value, values) => {
    return values.indexOf(value) !== -1;
});

extend('minmax', {
    validate(value, { min, max }) {
        return value.length >= min && value.length <= max;
    },
    params: ['min', 'max'],
    message: 'The {_field_} field must be between {min} and {max} characters'
});

extend('min', {
    validate(value, { length }) {
        return value.length >= length;
    },
    params: ['length'],
    message: 'The {_field_} field must have at least {length} characters'
});

/* TODO - Its not working.. Need to check */
/*  localize({
  en: {
    messages: en.messages ,
    names: {
      zip: 'ZIPCODE',
    },
    fields: {
      zip:{
        required:'ZIPCODE is mandatory field'
      },
    }
  }
})
 */

export const uiValidator = {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    mounted() {

    }
}
