<template>
    <img :src="image" class="img-fluid"/>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: true
        }
    }
}
</script>

<style>

</style>