<template>
    <div>
        <vue-editor v-model="model" :editor-toolbar="customToolbar" :style="style" />
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";

    export default {
        name: "QuillEditor",
        components: {
            VueEditor
        },
        props: {
            modelValue: {
                type: String,
                default: '',
            },
            maxHeight: {
                type: String,
                default: '350px',
            },
            maxWidth: {
                type: String,
                default: '530px',
            }
        },
        watch: {
            model(currentValue) {
                this.$emit("updateDescription", currentValue);
            }
        },
        computed: {
            style() {
                return 'max-height: ' + this.maxHeight + '; overflow-x: auto;' + 'max-width: ' + this.maxWidth + ';';
            }
        },
        data() {
            return {
                model: this.modelValue,
                customToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                    ],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ["link"]
                ],
            };
        }
    }
</script>
<style scoped>
</style>