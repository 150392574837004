import {
    ADD_FILTER,
    ADD_FILTER_TAG,
    REMOVE_FILTER_BY_ID,
    REMOVE_FILTER_TAG_BY_ID,
    UPDATE_FILTER,
    RESET_ALL_FILTERS
} from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import {
    FILTERS_GETTER,
    FILTER_TAGS_GETTER,
    FILTERING_CONFIG_GETTER,
    SORTING_CONFIG_GETTER,
    PER_PAGE_CONFIG_GETTER,
} from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import {
    SET_FILTER_TAGS,
    SET_FILTERS
} from "@/nucleus-modules/dd-nucleus-ui/store/mutations.type";

const MODULE_ID = "filtering";

const initialState = {
    filteringConfig: {
        debounceMilliseconds: 700,
        filterTags: [],
        showMoreThreshold: 5
    },
    filters: [],
    filterTags: [],
    sortingConfig: {
        defaultPerPage: 90,
        defaultSort: "product_no_desc",
        filters: [],
        // TODO: these names need to be localized
        options: [
            {
                id: 'product_no_desc',
                name: 'Product No. (Z-A)',
            },
            {
                id: 'product_no_asc',
                name: 'Product No. (A-Z)',
            },
            {
                id: 'newest_to_oldest',
                name: 'Newest to Oldest',
            },
            {
                id: 'oldest_to_newest',
                name: 'Oldest to Newest',
            }
        ]
    },
    perPageConfig: {
        defaultPerPage: 90,
        options: [
            {
                id: 0,
                name: "View All"
            },
            {
                id: 12,
                name: "12 per Page"
            },
            {
                id: 24,
                name: "24 per Page"
            },
            {
                id: 36,
                name: "36 per Page"
            },
            {
                id: 48,
                name: "48 per Page"
            },
            {
                id: 60,
                name: "60 per Page"
            },
            {
                id: 72,
                name: "72 per Page"
            },
            {
                id: 84,
                name: "84 per Page"
            },

            {
                id: 90,
                name: "90 per Page"
            },

        ]
    }
};

export const state = { ...initialState };

export const actions = {
    async [ADD_FILTER]({ commit }, payload) {
        if (!state.filters.find((f) => f.filterId === payload.filterId)) {
            const newFilters = state.filters.concat(payload);
            commit(SET_FILTERS, newFilters);
        }
    },
    async [ADD_FILTER_TAG]({ commit }, payload) {
        let filterTags = [...state.filterTags];
        let filterTag = filterTags.find(f => f.id == payload.id);
        if (filterTag) {
            filterTag.values = payload.values;
        } else {
            filterTags = filterTags.concat(payload);
        }
        commit(SET_FILTER_TAGS, filterTags);
    },
    async [REMOVE_FILTER_BY_ID]({ commit }, payload) {
        const updatedFilters = state.filters.filter((cf) => cf.id !== payload);

        commit(SET_FILTERS, updatedFilters);
    },
    async [REMOVE_FILTER_TAG_BY_ID]({ commit }, payload) {

        commit(SET_FILTER_TAGS, state.filterTags.filter((ft) => ft.id !== payload.id));
    },
    async [UPDATE_FILTER]({ commit }, payload) {
        const filterIndex = state.filters.findIndex((f) => f.filterId === payload.filterId);

        if (filterIndex !== -1) {
            const updatedFilters = [...state.filters];
            updatedFilters[filterIndex] = { filterId: payload.filterId, values: payload.values };
            commit(SET_FILTERS, updatedFilters);
        }
    },
    async [RESET_ALL_FILTERS]({ commit }) {
        state.filters.forEach(filter => {
            // needs review, category should not be part of filters array? handle from backend?
            if (filter.filterId !== "category")
                filter.values = []
        });
        commit(SET_FILTERS, state.filters);
    }
};

export const getters = {
    [FILTER_TAGS_GETTER]: state => {
        return state.filterTags;
    },
    [FILTERING_CONFIG_GETTER]: state => {
        return state.filteringConfig;
    },
    [FILTERS_GETTER]: state => {
        return state.filters;
    },
    [SORTING_CONFIG_GETTER]: state => {
        return state.sortingConfig;
    },
    [PER_PAGE_CONFIG_GETTER]: state => {
        return state.perPageConfig;
    }

};

export const mutations = {
    [SET_FILTER_TAGS]: (state, filterTags) => {
        state.filterTags = filterTags;
    },
    [SET_FILTERS]: (state, filters) => {
        state.filters = filters;
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID,
};
