<template>
  <div>
    <!-- TODO: Reintroduce this post launch. Ref D-02098 -->
    <!--<div class="n-filter-padding" style="visibility: hidden;">
      <TextBoxFilter
        :debounceWait="filteringConfig.debounceMilliseconds"
        filterId="search-term"
        :filterValues="getFilterValuesByFilterId('search-term')"
        :placeholderText="$t('dd-nucleus-ui.filterTextBox.placeholderText')"
        @changed="changed($event)"
      ></TextBoxFilter>
    </div>-->

    <CategoryFilter filterId="category"></CategoryFilter>
    <hr />
    <div class="my-3 n-filter-padding">
      <DateRangeFilter
        filterId="added"
        :filterValues="getFilterValuesByFilterId('added')"
        :title="$t('dd-nucleus-ui.dateAddedFilter.title')"
        @changed="changed($event)"
      ></DateRangeFilter>
    </div>
    <hr />
    <div class="my-3 n-filter-padding">
      <CheckBoxFilter
        filterId="tags"
        :filterValues="getFilterValuesByFilterId('tags')"
        :list="tagsFilter"
        title="Explore"
        @changed="changed($event)"
      >
      </CheckBoxFilter>
    </div>
    <div class="d-lg-none d-flex text-center">
      <b-button
        @click="clearAll()"
        v-if="showClearAll"
        class="n-btn-clear-all mr-2"
      >
        Clear All
      </b-button>
      <b-button @click="closeFilter()" class="n-view-results"
        >View results</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { GET_CATEGORY_ROOT } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import {
  FILTERING_CONFIG_GETTER,
  FILTERS_GETTER,
} from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import { FILTER_TAGS_GETTER } from "@/nucleus-modules/dd-nucleus-ui/store/getters.type.js";
import DateRangeFilter from "@/nucleus-modules/dd-nucleus-ui/components/DateRangeFilter.vue";
//import TextBoxFilter from "@/nucleus-modules/dd-nucleus-ui/components/TextBoxFilter.vue";
import CheckBoxFilter from "@/nucleus-modules/dd-nucleus-ui/components/CheckBoxFilter.vue";
import CategoryFilter from "@/nucleus-modules/dd-nucleus-storefront/components/CategoryFilter.vue";
import { tagsFilter, subCategoryLevels } from "@/companyAppConfig.js";


export default {
  name: "SearchResultsSidebar",
  components: {
    DateRangeFilter,
    CheckBoxFilter,
    CategoryFilter,
    //TextBoxFilter,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      tagsFilter: Object.freeze(tagsFilter),
      subCategoryLevels: subCategoryLevels
    };
  },
  computed: {
    ...mapGetters({
      filters: FILTERS_GETTER,
      filteringConfig: FILTERING_CONFIG_GETTER,
      filterTags: [FILTER_TAGS_GETTER],
    }),
  },
  methods: {
    ...mapActions({
      getCategoryRoot: GET_CATEGORY_ROOT,
    }),
    getFilterValuesByFilterId(filterId) {
      const filterIndex = this.filters.findIndex(
        (f) => f.filterId === filterId
      );
      if (filterIndex !== -1 && this.filters[filterIndex].values.length > 0) {
        return this.filters[filterIndex].values;
      } else {
        return [];
      }
    },
    changed(filter) {
      this.$emit("changed", filter);
    },
    closeFilter() {
      this.$root.$emit("bv::toggle::collapse", "filter-sidebar");
    },
    clearAll() {
      this.$emit("removeAll");
    },
    showClearAll() {
      let alltags = this.filterTags;
      let vm = this;
      for (var i in alltags) {
        if (
          alltags[i] &&
          alltags[i].values &&
          alltags[i].values.filter((f) => !vm.$nucleus.isEmpty(f.id) && f.show)
            .length
        ) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    await this.getCategoryRoot({includeSubCategoryLevels: subCategoryLevels});
  },
};
</script>

<style lang="scss" scoped>
.n-view-results {
  background-color: $primary-color;
  color: #ffffff;
  border-radius: 6px;
  font-size: 00.75rem;
}

.n-btn-clear-all {
  background-color: #ffffff !important;
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
}

.n-filter-padding{
  padding-left: 6px;
}
</style>
