<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-5">
                    <h3 class="n-primary">Homepage Announcement</h3>
                </div>
            </div>
            <div class="row n-mt-6">
                <div class="col-12">
                    <h4 class="n-text-center">
                        Your team hasn't created any Announcement yet.
                    </h4>
                    <p class="n-text-center">
                        An announcement will display on the homepage. You will be able to
                        specify which roles can view the announcement.
                    </p>
                </div>
            </div>
        </div>
        
    </div>

</div>
</template>

<script>
export default {
    name: "ContentManagementDefaultPage",    
};
</script>

