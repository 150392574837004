import ApiService from "@/nucleus-modules/dd-nucleus-ui/services/api.service.js";

import { ADD_FAVORITE, REMOVE_FAVORITE, GET_FAVORITES, GET_FEATURED_PRODUCTS, GET_PRODUCT_DETAIL, SEARCH_PRODUCTS } from "@/nucleus-modules/dd-nucleus-storefront/store/actions.type.js";
import { PRODUCT_DETAIL_GETTER, SEARCH_PRODUCTS_RESULTS_GETTER, FAVORITES_GETTER, FEATURED_PRODUCTS_GETTER, FEATURED_PRODUCTS_ERRORS_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import { SET_PRODUCT_AS_FAVORITE, UNSET_PRODUCT_AS_FAVORITE, SET_PRODUCT_DETAIL, SET_PRODUCTS_SEARCH_RESULTS, SET_FAVORITES, SET_FEATURED_PRODUCTS, SET_FEATURED_PRODUCTS_ERRORS } from "@/nucleus-modules/dd-nucleus-storefront/store/mutations.type.js";

const MODULE_ID = "product";

const initialState = {
    productDetail: {
        productId: null,
        isFavorite: false,
    },
    searchProductsResults: {},
    favoriteProducts:[],
    featuredProducts:[],
    featuredProductsErrors:[]
};

export const state = { ...initialState };

export const actions = {
    async [ADD_FAVORITE]({ commit }, payload) {
        const response = await ApiService.post(`ui/store/favorites/${payload.productId}`, { productId: payload.productId, userId: payload.userId, tagName: payload.tagName }, { showLoader: false });

        if (response.succeeded) {
            commit(SET_PRODUCT_AS_FAVORITE, payload.productId);
            commit(SET_FAVORITES, response.results);
        }
        else {
            // TODO: give feedback to user?
        }
    },  
    async [REMOVE_FAVORITE]({ commit }, payload) {
        const response = await ApiService.delete(`ui/store/favorites/${payload.productId}`, { data: payload }, { showLoader: false });

        if (response.succeeded) {
            commit(UNSET_PRODUCT_AS_FAVORITE, payload.productId);
            commit(SET_FAVORITES, response.results);
        }
        else {
            // TODO: give feedback to user?
        }
    },
    async [GET_PRODUCT_DETAIL]({ commit }, payload) {
        const response = await ApiService.get(`ui/store/product/${payload.productId}/detail`);

        if (response.succeeded) {
            commit(SET_PRODUCT_DETAIL, response.product);
        }
        else {
            commit(SET_PRODUCT_DETAIL, null);
            // TODO: add a system error
        }
    },
    async [GET_FAVORITES]({ commit }) {
        const response = await ApiService.get(`ui/store/favorites`);

        if (response.succeeded) {
            commit(SET_FAVORITES, response.results);
        }
        else {
            // TODO: add a system error
        }
    },
    async [GET_FEATURED_PRODUCTS]({ commit }, payload) {
        const response = await ApiService.post("ui/store/featured", payload);

        if (response.succeeded) {
            commit(SET_FEATURED_PRODUCTS, response.results);
        }
        else {
            commit(SET_FEATURED_PRODUCTS_ERRORS, response);
        }
    },
    async [SEARCH_PRODUCTS]({ commit }, payload) {

        const response = await ApiService.post("ui/store/products/search", payload);

        if (response.succeeded) {
            commit(SET_PRODUCTS_SEARCH_RESULTS, {
                items: response.results,
                pageNumber: response.pageNumber,
                pageSize: response.pageSize,
                count: response.count,
                totalCount: response.totalCount
            });
        }
        else {
            // TODO: add a system error?
        }
    }
};

export const getters = {
    [PRODUCT_DETAIL_GETTER]: state => {
        return state.productDetail;
    },
    [SEARCH_PRODUCTS_RESULTS_GETTER]: state => {
        return state.searchProductsResults;
    },
    [FAVORITES_GETTER]: state => {
        return state.favoriteProducts;
    },
    [FEATURED_PRODUCTS_GETTER]: state => {
        return state.featuredProducts;
    },
    [FEATURED_PRODUCTS_ERRORS_GETTER]: state => {
        return state.featuredProductsErrors;
    },
};

export const mutations = {
    [SET_PRODUCT_AS_FAVORITE]: (state, productId) => {
        const product = state.searchProductsResults.items.find((p) => p.id === productId);
        const productDetail = state.productDetail.productId === productId ? state.productDetail : null;

        if (product) {
            product.isFavorite = true;
            state.searchProductsResults =  Object.assign({},state.searchProductsResults);
        }

        if (productDetail) {
            productDetail.isFavorite = true;
            state.productDetail =  Object.assign({}, state.productDetail);
        }
    },
    [UNSET_PRODUCT_AS_FAVORITE]: (state, productId) => {
        const product = state.searchProductsResults.items.find((p) => p.id === productId);
        const productDetail = state.productDetail.productId === productId ? state.productDetail : null;

        if (product) {
            product.isFavorite = false;
        }

        if (productDetail) {
            productDetail.isFavorite = false;
        }
    },
    [SET_PRODUCT_DETAIL]: (state, productDetail) => {
        state.productDetail = productDetail;
    },
    [SET_PRODUCTS_SEARCH_RESULTS]: (state, searchProductsResults) => {
        state.searchProductsResults = searchProductsResults;
    },
    [SET_FAVORITES]: (state, favorites) => {
        state.favoriteProducts = favorites;
    },
    [SET_FEATURED_PRODUCTS]: (state, featuredProducts) => {
        state.featuredProducts = featuredProducts;
    },
    [SET_FEATURED_PRODUCTS_ERRORS]: (state, featuredProductErrors) => {
        state.featuredProductsErrors = featuredProductErrors;
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    MODULE_ID
};
