<template>
    <div class="n-header-menu-icon">
        <button id="dropdown-menu-button"
                class="btn btn-primary-inverse--medium d-none d-sm-block "
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
            <span class="d-none d-sm-block">{{ $t("header.nav.account") }}</span>
        </button>
        <a href="#" id="dropdown-menu-icon" class="n-header-nav d-sm-none" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <font-awesome-icon class="n-icon" :icon="fontIcon" :title='$t("header.nav.account")' />
        </a>
        <div class="dropdown-menu p-0" aria-labelledby="dropdown-menu-button dropdown-menu-icon">
            <div class="n-account-wrapper">
                <a class="dropdown-item n-my-account-menu" @click="openSignInModal">Sign In</a>
            </div>
        </div>
    </div>
</template>

<script>
    // Sign In Modal Imports
    import ModalService from "@/nucleus-modules/dd-nucleus-ui/services/modal.service.js";
    import SignInModal from "@/components/auth/SignInModal";

    export default {
        name: "HeaderLoginItem",
        props: {
            fontIcon: {
                type: String,
                required: false
            }
        },
        methods: {
            openSignInModal() {
                ModalService.open(SignInModal);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .dropdown-toggle::after {
        font-family: "FontAwesome";
        content: "\f078";
        border: none;
        vertical-align: 0;
        color: $primary-color;
    }

    .show .dropdown-toggle::after {
        font-family: "FontAwesome";
        content: "\f077";
        border: none;
        vertical-align: 0;
        color: $primary-color;
    }

    .n-icon {
        font-size: 25px;
    }

    .n-account-wrapper {
        border-bottom: 1px solid $gray-2;
    }
</style>
